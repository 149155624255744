import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import { BalanceResponse, TUser, TUserAccountSettings, TUserInfo } from './type';

const initialState: TUser = {
  info: null,
  balance: '',
  avBalance: '',
  operatorBalance: '',
  operatorCurrency: '',
  error: null,
  loading: false,
  balanceLoading: false,
  isUserInfoLoaded: false,
  stringifiedBalances: ''
};

const slice = createSlice({
  name: SLICES_NAMES.USER,
  initialState,
  reducers: {
    fetchUserInfo: (state, _: PayloadAction<boolean | undefined>) => {
      state.loading = true;
    },
    successFetchUserInfo: (state, { payload }: PayloadAction<TUserInfo>) => {
      state.info = payload;
      state.loading = false;
      state.isUserInfoLoaded = true;
    },
    failureFetchUserInfo: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    fetchBalance: state => {
      state.balanceLoading = true;
    },
    successFetchBalance: (
      state,
      { payload }: PayloadAction<{ balances: BalanceResponse; isWebSocketResponse: boolean }>
    ) => {
      state.balanceLoading = false;

      if (payload.isWebSocketResponse) {
        state.balance = payload.balances.balance || '';
        state.avBalance = payload.balances.avBalance || '';
      } else {
        const stringifiedBalancesFromResponse = JSON.stringify(payload.balances);

        if (state.stringifiedBalances !== stringifiedBalancesFromResponse) {
          state.balance = payload.balances.balance || '';
          state.avBalance = payload.balances.avBalance || '';
          state.stringifiedBalances = stringifiedBalancesFromResponse;
        }
      }
    },
    setOperatorBalance: (state, { payload }: PayloadAction<string>) => {
      state.operatorBalance = payload;
    },
    failureFetchBalance: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.balanceLoading = false;
    },
    setSettings: (state, _: PayloadAction<Partial<TUserAccountSettings>>) => {
      state.loading = true;
    },
    successSetSettings: state => {
      state.loading = false;
    },
    failureSetSettings: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.loading = false;
      state.error = payload;
    },
    setOperatorCurrency: (state, { payload }: PayloadAction<string>) => {
      state.operatorCurrency = payload;
    },
    resetOperatorCurrency: state => {
      state.operatorCurrency = '';
    },
    resetUserState: () => initialState
  }
});

export const {
  fetchUserInfo,
  failureFetchUserInfo,
  successFetchUserInfo,
  successFetchBalance,
  setOperatorBalance,
  failureFetchBalance,
  fetchBalance,
  resetUserState,
  resetOperatorCurrency,
  setOperatorCurrency,
  successSetSettings,
  setSettings,
  failureSetSettings
} = slice.actions;

export default slice.reducer;
