import { isString, toNumber } from 'lodash';

import { MARKET_TYPES } from 'constants/marketTypes';
import { EXPOSURE_LIMIT_ERROR_CODE, LOSS_LIMIT_ERROR_CODE } from 'constants/placement';
import { TSelectedBet } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { MarketType } from 'redux/modules/games/type';
import { TMarketResponse } from 'redux/modules/market/type';
import { TPlacementError } from 'redux/modules/placement/type';
import { PageBlocks, PlacementPage } from 'types';
import { BetTypes, THandicap } from 'types/bets';
import { TBetslipMarketPrices } from 'types/betslip';
import { BettingType, IMarket, RunnersStatuses, TMarketPrice } from 'types/markets';

export const valueToNumber = (value: string) => {
  return value
    .trim()
    .replace(/[^0-9.]/g, '')
    .replace(/\.\./g, '.');
};

export const isCombinedTotalMarket = (market: IMarket) => market.description.marketType === MARKET_TYPES.combinedTotal;

export const isLineBettingType = (bettingType: string) => bettingType === BettingType.LINE;

export const isHandicapBettingType = (bettingType: string) => {
  return (
    bettingType === BettingType.ASIAN_HANDICAP_DOUBLE_LINE || bettingType === BettingType.ASIAN_HANDICAP_SINGLE_LINE
  );
};

export const isCombinedTotalMarketType = (marketType: string) => marketType === MARKET_TYPES.combinedTotal;

export const isTotalPointsLine = (marketType: string) => marketType === MARKET_TYPES.totalPointsLine;

export const isEachWayMarketType = (marketType: string) => marketType === MARKET_TYPES.eachWay;

export const isGameMarketType = (marketType: string) => {
  return (
    marketType === MarketType.ANY_NUMBER_OF_WINNERS ||
    marketType === MarketType.WIN_ONLY ||
    marketType === MarketType.SINGLE_WINNER_OR_TIE ||
    marketType === MarketType.VARIABLE_HANDICAP
  );
};

export const getBestPrices = ({
  marketPrices,
  selectionId,
  handicap,
  type
}: {
  marketPrices?: TBetslipMarketPrices | null;
  selectionId: number | undefined;
  handicap?: number | string | null;
  type: BetTypes;
}) => {
  const selectionPrices =
    marketPrices?.rc?.find(({ id, hc }: { id: number; hc?: number | string | null }) => {
      return id === selectionId && +(hc || 0) === +(handicap || 0);
    }) ?? null;

  return selectionPrices?.[type === BetTypes.BACK ? 'bdatb' : 'bdatl']?.[0] ?? null;
};

/**
 * Parse current bet fields to number values.
 *
 * @param bet
 */
export const parseCurrentBet = (bet: TCurrentBet) => {
  return {
    ...bet,
    ...{
      ...[
        'alternativeBackOdds',
        'alternativeBackOddsRounded',
        'averagePrice',
        'averagePriceRounded',
        'liability',
        'pastTotalLiability',
        'potentialProfit',
        'price',
        'profit',
        'profitNet',
        'size',
        'sizeCancelled',
        'sizeLapsed',
        'sizeMatched',
        'sizePlaced',
        'sizeRemaining',
        'sizeVoided',
        'totalWinnings'
      ].reduce((result, item) => {
        return { ...result, ...{ [item]: +(bet[item as keyof TCurrentBet] || 0) } };
      }, {})
    }
  };
};

export const getBestPriceFromMarketPrices = (
  marketPrice: TMarketPrice | null | undefined,
  marketType: string,
  bettingType: string
) => {
  return marketPrice && marketPrice.odds && isLineBettingType(bettingType) && !isTotalPointsLine(marketType)
    ? Math.round(marketPrice.odds || 0)
    : marketPrice?.odds ?? 0;
};

export const parseHandicap = (handicap?: THandicap | null) => toNumber(handicap || 0);

export const isResponsibleGamblingError = (error: TPlacementError | string) =>
  !isString(error) && [EXPOSURE_LIMIT_ERROR_CODE, LOSS_LIMIT_ERROR_CODE].includes(error?.response?.data?.id ?? '');

/**
 * Set bets for all runners by type (BACK OR LAY)
 * @param market
 * @param marketPrices
 * @param type
 * @param pageBlock
 * @param page
 */
export const getAllBetsFromMarketByType = ({
  market,
  marketPrices,
  type,
  pageBlock,
  page
}: {
  market: IMarket | TMarketResponse;
  marketPrices: TBetslipMarketPrices;
  type: BetTypes;
  pageBlock: PageBlocks;
  page: PlacementPage;
}) => {
  return market.runners.reduce((res: TSelectedBet[], { selectionId, handicap }) => {
    const marketPricesRunner = marketPrices?.marketDefinition?.runners?.find(
      marketPrice => (marketPrice.handicap ? marketPrice.handicap === handicap : true) && marketPrice.id === selectionId
    );

    const rc = marketPrices?.rc?.find(
      runner => (runner.hc ? runner.hc === handicap : true) && runner.id === selectionId
    );

    return marketPricesRunner?.status === RunnersStatuses.ACTIVE && !rc?.locked
      ? [
          ...res,
          {
            marketId: market.marketId,
            sportId: market.eventType.id,
            marketType: market.description.marketType,
            bettingType: market.description.bettingType,
            numberOfWinners: market.numberOfWinners,
            currency: marketPrices?.currency ?? '',
            price: getBestPriceFromMarketPrices(
              getBestPrices({ marketPrices, selectionId, handicap, type }),
              market.description.marketType,
              market.description.bettingType
            ),
            selectionId,
            handicap,
            type,
            pageBlock,
            isBetAll: true,
            page
          }
        ]
      : res;
  }, []);
};

export const isPartiallyMatched = ({ sizeMatched, sizeRemaining }: TCurrentBet) =>
  toNumber(sizeMatched) > 0 && toNumber(sizeRemaining) > 0;

export const isCancelled = ({ offerState, sizeRemaining, sizeCancelled }: TCurrentBet) =>
  offerState === BetsStatusesTypes.CANCELLED ||
  (offerState === BetsStatusesTypes.MATCHED && +sizeRemaining === 0 && +sizeCancelled > 0);
