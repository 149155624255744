import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import api from 'redux/api/methods';
import { fetchUserInfo } from 'redux/modules/user';
import { TFailureActionPayload } from 'types';
import { ResponsibleBettingDisplayedContent } from 'types/responsibleBetting';

import { ResponsibleBettingSettings, TConfirmLossLimit, TResponsibleBettingState, TUpdateToolsSettings } from './type';

const initialState: TResponsibleBettingState = {
  settings: null,
  error: null,
  loading: false,
  loadingByType: {
    [ResponsibleBettingDisplayedContent.ExposureLimit]: false,
    [ResponsibleBettingDisplayedContent.LossLimit]: false,
    [ResponsibleBettingDisplayedContent.SelfExclusion]: false,
    [ResponsibleBettingDisplayedContent.Reminder]: false,
    [ResponsibleBettingDisplayedContent.DayLimit]: false
  }
};

const slice = createSlice({
  name: SLICES_NAMES.RESPONSIBLE_BETTING,
  initialState,
  reducers: {
    fetchResponsibleBettingSettings: state => {
      state.loading = true;
    },
    updateResponsibleBettingSettings: (state, { payload }: PayloadAction<TUpdateToolsSettings>) => {
      state.loading = true;
      state.loadingByType[payload.type] = true;
    },
    confirmResponsibleBettingLossLimit: (state, { payload }: PayloadAction<TConfirmLossLimit>) => {
      state.loading = true;
      state.loadingByType[payload.type] = true;
    },
    successFetchResponsibleBettingSettings: (state, { payload }: PayloadAction<ResponsibleBettingSettings>) => {
      state.settings = payload;
      state.loading = false;
    },
    failureFetchResponsibleBettingSettings: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    setResponsibleBettingLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setResponsibleBettingLoadingByType: (
      state,
      { payload }: PayloadAction<{ type: ResponsibleBettingDisplayedContent; value: boolean }>
    ) => {
      state.loadingByType[payload.type] = payload.value;
    }
  }
});

export const updateResponsibleBettingReminderLastDate = createAsyncThunk(
  '@responsibleBetting/UPDATE_RESPONSIBLE_BETTING_REMINDER_LAST_DATE',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await api.responsibleBetting.updateReminderLastDate();
      dispatch(fetchUserInfo());
    } catch (err: any) {
      rejectWithValue(err);
    }
  }
);

export const updateResponsibleBettingDayLimitReminder = createAsyncThunk(
  '@responsibleBetting/UPDATE_RESPONSIBLE_BETTING_DAY_LIMIT_REMINDER',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await api.responsibleBetting.updateDayLimitReminder();
      dispatch(fetchUserInfo());
    } catch (err: any) {
      rejectWithValue(err);
    }
  }
);

export const {
  fetchResponsibleBettingSettings,
  successFetchResponsibleBettingSettings,
  updateResponsibleBettingSettings,
  confirmResponsibleBettingLossLimit,
  setResponsibleBettingLoading,
  failureFetchResponsibleBettingSettings,
  setResponsibleBettingLoadingByType
} = slice.actions;

export default slice.reducer;
