import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ResponsibleBettingAlertTypes } from 'types/responsibleBetting';

import ResponsibleBettingAlert from '../ResponsibleBettingAlert';

import styles from './styles.module.scss';

interface ResponsibleBettingInputValidationProps {
  error: { message: string; value: number } | null;
}

const ResponsibleBettingInputValidation = ({ error }: ResponsibleBettingInputValidationProps) => {
  const { t } = useTranslation();
  return error ? (
    <div className={styles.container}>
      <ResponsibleBettingAlert type={ResponsibleBettingAlertTypes.Error}>
        <span className={styles.validationMessage}>
          {t(error.message)}{' '}
          <span className={classNames(styles.validationMessage, styles.validationMessage__value)}>{error.value}</span>
        </span>
      </ResponsibleBettingAlert>
    </div>
  ) : null;
};

export default ResponsibleBettingInputValidation;
