import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { fetchUserInfo } from 'redux/modules/user';
import { fetchUserInfoWorker } from 'redux/modules/user/saga';

import {
  confirmResponsibleBettingLossLimit,
  failureFetchResponsibleBettingSettings,
  fetchResponsibleBettingSettings,
  setResponsibleBettingLoading,
  setResponsibleBettingLoadingByType,
  successFetchResponsibleBettingSettings,
  updateResponsibleBettingSettings
} from './index';
import { ResponsibleBettingSettings } from './type';

function* getResponsibleBettingSettingsWorker() {
  try {
    const response: ResponsibleBettingSettings = yield call(api.responsibleBetting.getSettings);
    yield put(successFetchResponsibleBettingSettings(response));
  } catch (error: any) {
    yield put(failureFetchResponsibleBettingSettings(error));
  }
}

function* updateResponsibleBettingSettingsWorker(action: ReturnType<typeof updateResponsibleBettingSettings>) {
  try {
    yield call(api.responsibleBetting.updateSettings, action.payload.rgTools);
    yield call(getResponsibleBettingSettingsWorker);
    yield put(setResponsibleBettingLoading(false));
    yield put(setResponsibleBettingLoadingByType({ type: action.payload.type, value: false }));

    if (action.payload.updateUserInfo) {
      yield call(fetchUserInfoWorker, { type: fetchUserInfo.type, payload: false });
    }
  } catch (error: any) {
    yield put(failureFetchResponsibleBettingSettings(error));
    yield put(setResponsibleBettingLoadingByType({ type: action.payload.type, value: false }));
  }
}

function* confirmResponsibleBettingLossLimitWorker(action: ReturnType<typeof confirmResponsibleBettingLossLimit>) {
  try {
    yield call(api.responsibleBetting.confirmLimit, action.payload);
    yield call(getResponsibleBettingSettingsWorker);
    yield put(setResponsibleBettingLoading(false));
    yield put(setResponsibleBettingLoadingByType({ type: action.payload.type, value: false }));
  } catch (error: any) {
    yield put(failureFetchResponsibleBettingSettings(error));
    yield put(setResponsibleBettingLoadingByType({ type: action.payload.type, value: false }));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchResponsibleBettingSettings.type, getResponsibleBettingSettingsWorker),
    takeLatest(updateResponsibleBettingSettings.type, updateResponsibleBettingSettingsWorker),
    takeLatest(confirmResponsibleBettingLossLimit.type, confirmResponsibleBettingLossLimitWorker)
  ]);
}
