import { Tooltip as ReactTooltip } from 'react-tooltip';

import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

export const Tooltip = () => {
  const { isMobile } = useDevice();

  return (
    <ReactTooltip
      id="tooltip"
      place="top"
      className={styles.tooltip}
      classNameArrow={styles.arrow}
      openOnClick={isMobile}
      delayShow={200}
    />
  );
};

export default Tooltip;
