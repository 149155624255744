import { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import PricesRow from 'components/GameMarketTable/partials/PricesRow';
import StatusRow from 'components/GameMarketTable/partials/StatusRow';
import MobileOpenBets from 'components/MobileOpenBets';
import MobilePlacement from 'components/MobilePlacement';
import PlacementMessage from 'components/MobilePlacement/PlacementMessage';
import WhatIfGames from 'components/WhatIfGames';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getCurrentGameMarket } from 'redux/modules/games/selectors';
import { ESelectionStatuses, TSelection } from 'redux/modules/games/type';
import { getInlineSelectedBetBySelection } from 'redux/modules/inlinePlacement/selectors';
import { getMessageBetBySelection } from 'redux/modules/placementMessage/selectors';
import { PageBlocks, PlacementPage } from 'types';
import { Actions } from 'types/inlinePlacement';

import styles from '../../styles.module.scss';

interface IGameMarketRow {
  isFirstSelection: boolean;
  selection: TSelection;
  hide?: boolean;
}

const GameMarketRow = ({
  selection: { id, name, bestAvailableToBackPrices, bestAvailableToLayPrices, status },
  isFirstSelection,
  hide = false
}: IGameMarketRow) => {
  const gameMarket = useSelector(getCurrentGameMarket);
  const device = useSelector(getAppDevice);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const inlineSelectedBet = useSelector(
    getInlineSelectedBetBySelection(PageBlocks.GAME, String(gameMarket?.id), id, null)
  );
  const messageBet = useSelector(getMessageBetBySelection(String(gameMarket?.id), id, 0));

  const [isBetslipClosing, setIsBetslipClosing] = useState<boolean>(false);

  const isMobile = device === Devices.MOBILE;
  const isMobileOpenBetsVisible =
    isBetslipClosing || !inlineSelectedBet || (!!inlineSelectedBet.action && inlineSelectedBet.action !== Actions.EDIT);
  const dontShowWhatIf = ESelectionStatuses.LOSER === status || ESelectionStatuses.WINNER === status;
  const tieSelectionId =
    gameMarket?.marketType === 'SINGLE_WINNER_OR_TIE' ? gameMarket?.selections.at(-1)?.id : undefined;

  return (
    <div className={classNames({ hidden: hide })}>
      <div className={styles.marketRow}>
        <div className={styles.name}>
          <div>{name}</div>
          {!dontShowWhatIf && gameMarket && (
            <WhatIfGames
              selectionId={id}
              marketId={gameMarket.id.toString()}
              commissionRate={gameMarket.commissionRate}
              currency={gameMarket.currency}
              marketType={gameMarket?.description?.marketType}
              tieSelectionId={tieSelectionId}
            />
          )}
        </div>
        <div className={styles.rowData}>
          {status === ESelectionStatuses.IN_PLAY ? (
            <PricesRow
              selectionId={id}
              name={name}
              isFirstSelection={isFirstSelection}
              bestAvailableToBackPrices={bestAvailableToBackPrices}
              bestAvailableToLayPrices={bestAvailableToLayPrices}
            />
          ) : (
            <StatusRow status={status} />
          )}
        </div>
      </div>
      {isMobile && messageBet && <PlacementMessage bet={messageBet} />}
      {inlineSelectedBet && isMobile && (
        <MobilePlacement
          bet={inlineSelectedBet}
          pageBlock={PageBlocks.GAME}
          onClosingBetslip={setIsBetslipClosing}
          page={PlacementPage.GAME}
        />
      )}
      {isMobile && !!gameMarket?.id && isLoggedIn && (
        <MobileOpenBets
          isVisible={isMobileOpenBetsVisible}
          marketId={gameMarket?.id.toString()}
          selectionId={id}
          handicap={0}
          pageBlock={PageBlocks.GAME}
          page={PlacementPage.GAME}
        />
      )}
    </div>
  );
};

export default GameMarketRow;
