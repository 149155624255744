import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { SPORT_BASE_URL } from 'constants/locations';
import {
  BetStatuses,
  BetTypes,
  BetTypeValue,
  ExchangeTypes,
  EXPORT_FILENAME,
  mapMyBetsPageToBetsType,
  MyBetsLocations,
  MyBetsPage,
  Period,
  PLBetType,
  plDefaultSportTypeValues,
  PLTimeRanges,
  SEARCH_CURRENCY_KEY,
  statementFilterValues
} from 'constants/myBets';
import { urls } from 'redux/api/urls';
import {
  getCurrency,
  getDisplayCustomerCommissionRange,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getPNCEnabledSetting,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  fetchCharges,
  fetchMyBets,
  fetchPL,
  fetchPLTotal,
  fetchPLTotalForEvent,
  fetchProfitGames,
  fetchProfitSports,
  fetchStatement,
  hideMobileBetDetails,
  resetPageData,
  resetPLTotalForEvent,
  setEndDate,
  setIsCustomPeriod,
  setPageNumber,
  setPageSize,
  setStartDate,
  setTimeRange
} from 'redux/modules/myBets';
import {
  cancelledBet,
  getAccountProductsByBetsType,
  getCancelAllUnmatchedBetsActionId,
  getEndDate,
  getMyBetsMultiCurrencyValue,
  getPageNumber,
  getPageSize,
  getStartDate,
  getTimeRange,
  isBetUpdated,
  isShowMobileBetDetails
} from 'redux/modules/myBets/selectors';
import { TGetBetPayload } from 'redux/modules/myBets/type';
import { CookieNames, TimeFormats, TPeriods } from 'types';
import {
  SelectDropdownValue,
  TGetBetAndPageDataParams,
  TGetPLData,
  TGetStatementData,
  TOnFetchDataProfitLossTimeRangeChangeParams,
  TProfitLossTimeRangeChangeParams
} from 'types/myBets';
import {
  addDays,
  applyTimezone,
  getBettingDayTimezone,
  getDaysDifference,
  getEndDateByPeriod,
  getSevenDaysAgo,
  getStartDateByPeriod,
  getThirtyDaysAgo,
  getThreeMonthsAgo,
  getTodayDate,
  getTodayEndDate,
  getTodayStartDate,
  getYesterdayDate,
  setHoursMinutesToZero,
  subtractSelectedTimezoneToDate
} from 'utils/date';
import { getMultiCurrencyCode, getMultiCurrencyCodeFromArg } from 'utils/myBetsValues';

import useAccountUrlParams from './useAccountUrlParams';
import useDevice from './useDevice';
import useMultiCurrencySupporting from './useMultiCurrencySupporting';

export const useMyBetsPagesNavigation = (pageType: MyBetsPage, activePLTab?: PLBetType) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { page = '', betType: betTypeParam = '' } = useParams<{ page?: string; betType?: string }>();
  const [searchParams] = useSearchParams();
  const { isMobile } = useDevice();

  const [cookies, setCookie] = useCookies([
    CookieNames.TIME_FORMAT,
    CookieNames.EXCHANGE_TYPE,
    CookieNames.ACCOUNT_STATEMENT_DATETO,
    CookieNames.ACCOUNT_STATEMENT_DATEFROM,
    CookieNames.PROFITANDLOSS_BETTYPE,
    CookieNames.PROFITANDLOSS_TIMERANGE,
    CookieNames.PROFITANDLOSS_SPORTID,
    CookieNames.PROFITANDLOSS_SPORTNAME,
    CookieNames.PROFITANDLOSS_SPORTTRANSLATION
  ]);

  const startDate = useSelector(getStartDate);
  const endDate = useSelector(getEndDate);
  const pageNumber = useSelector(getPageNumber);
  const pageSize = useSelector(getPageSize);
  const timeRange = useSelector(getTimeRange);
  const betUpdate = useSelector(isBetUpdated);
  const isDetailsOpen = useSelector(isShowMobileBetDetails);
  const cancelledBetData = useSelector(cancelledBet);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const displayCustomerCommissionRange = useSelector(getDisplayCustomerCommissionRange);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const currentCurrency = useSelector(getCurrency);
  const multiCurrencyValue = useSelector(getMyBetsMultiCurrencyValue);
  const { multiCurrencySupported, isMultiCurrencySupported } = useMultiCurrencySupporting();
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const accountProducts = useSelector(getAccountProductsByBetsType(mapMyBetsPageToBetsType[pageType]));
  const cancelAllUnmatchedBetsActionId = useSelector(getCancelAllUnmatchedBetsActionId);
  const { exchangePageType, activeStatusTab } = useAccountUrlParams();

  const isAsianViewEnabled = asianViewEnabled || !!accountProducts?.asianView;
  const isSportsEnabled = isExchangeSportsEnabled || !!accountProducts?.sports;
  const isGamesEnabled = isExchangeGamesEnabled || !!accountProducts?.games;
  const isSportsTabSelected =
    isSportsEnabled &&
    (activePLTab === PLBetType.Sports || (activePLTab == PLBetType.AsianView && !accountProducts?.asianView));

  const [eventType, setEventType] = useState<SelectDropdownValue>(
    isSportsTabSelected ? plDefaultSportTypeValues.sports : plDefaultSportTypeValues.games
  );
  const [betTypeValue, setBetTypeValue] = useState<BetTypeValue>(
    activePLTab === PLBetType.Games ? BetTypeValue.Games : BetTypeValue.Sports
  );

  const [activePeriodTab, setActivePeriodTab] = useState<Period>();
  const [statementFilterValue, setStatementFilterValue] = useState<string>(statementFilterValues.all.value);

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);
  const urlWithoutPageIndex = location.pathname
    .substring(0, location.pathname.lastIndexOf('/'))
    .replace(exchangePageType, '');

  const isActiveAsianView =
    pageType === MyBetsPage.ProfitLoss
      ? cookies.PROFITANDLOSS_BETTYPE === PLBetType.AsianView
      : cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView;

  const asianView = isAsianViewEnabled && isActiveAsianView;
  const bettingDay = asianView && cookies.BIAB_TIME_FORMAT === TimeFormats.BETTING_DAY;
  const additionalParams = asianView
    ? {
        asianView: isActiveAsianView,
        bettingDay
      }
    : {};

  const timezoneOffset = bettingDay ? getBettingDayTimezone() : timezone;
  const todayDate = getTodayEndDate(timezoneOffset, bettingDay || timezoneCookieEnabled);
  const dayDifference = getDaysDifference(getTodayDate(), todayDate);

  const getStatementData = ({
    refreshedStartDate,
    refreshedEndDate,
    size,
    number,
    filterValue,
    currency
  }: TGetStatementData) => {
    if (displayCustomerCommissionRange && !isPNCEnabled) {
      dispatch(fetchCharges());
    }

    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(refreshedStartDate || startDate, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    );

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(refreshedEndDate || endDate, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    );

    dispatch(
      fetchStatement({
        startDate: startDateAdjusted.getTime(),
        endDate: endDateAdjusted.getTime(),
        page: number ?? pageNumber,
        size: size || pageSize,
        currency: currencyCode,
        accountStatementOrigin: filterValue || statementFilterValue,
        ...additionalParams
      })
    );
  };

  const getPLData = ({
    refreshedStartDate,
    refreshedEndDate,
    size,
    number,
    betType,
    eventTypeId,
    currency
  }: TGetPLData) => {
    const endDatePL = refreshedEndDate || endDate;
    const startDatePL = refreshedStartDate || startDate;
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(startDatePL, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    ).getTime();

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(endDatePL, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    ).getTime();

    const profitsPayload = {
      startDate: startDateAdjusted,
      endDate: endDateAdjusted,
      page: number ?? pageNumber,
      size: size || pageSize,
      eventTypeId: eventTypeId ?? eventType.id,
      betType: betType || betTypeValue,
      currency: currencyCode
    };
    const profitTotalPayload = {
      startDate: startDateAdjusted,
      endDate: endDateAdjusted,
      betType: betType || betTypeValue,
      currency: currencyCode
    };
    const sportTotalPayload = {
      startDate: startDateAdjusted,
      endDate: endDateAdjusted,
      eventTypeId: eventTypeId ?? eventType.id,
      betType: betType || betTypeValue,
      currency: currencyCode
    };

    dispatch(fetchPL({ ...profitsPayload, ...additionalParams }));
    dispatch(fetchPLTotal({ ...profitTotalPayload, ...additionalParams }));

    if (eventTypeId || eventType.id) {
      dispatch(fetchPLTotalForEvent({ ...sportTotalPayload, ...additionalParams }));
    }
  };

  const getBetData = ({
    refreshedStartDate,
    refreshedEndDate,
    number,
    size,
    currency,
    betsType
  }: TGetBetAndPageDataParams) => {
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(refreshedStartDate || startDate, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    );

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(refreshedEndDate || endDate, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    );

    const payload: Omit<TGetBetPayload, 'betsType'> = {
      startDate: startDateAdjusted.getTime(),
      endDate: endDateAdjusted.getTime(),
      page: number ?? pageNumber,
      size: size || pageSize,
      currency: currencyCode
    };

    const currentBetsType = getMyBetsBetsType(betsType);

    if (currentBetsType) {
      dispatch(fetchMyBets({ ...payload, betsType: currentBetsType, ...additionalParams }));
    }
  };

  const getMyBetsBetsType = (betsType?: BetTypes | undefined) => {
    if (betsType) {
      return betsType;
    } else {
      const betTypesMap: Record<string, BetTypes> = {
        [MyBetsLocations.Matched]: BetTypes.Matched,
        [MyBetsLocations.Unmatched]: BetTypes.Unmatched,
        [MyBetsLocations.Cancelled]: BetTypes.Cancelled,
        [MyBetsLocations.Settled]: BetTypes.Settled,
        [MyBetsLocations.Lapsed]: isAsianViewEnabled && isActiveAsianView ? BetTypes.Settled : BetTypes.Lapsed,
        [MyBetsLocations.Voided]: BetTypes.Voided
      };

      const mappedBetsType = betTypesMap[urlWithoutPageIndex];

      return mappedBetsType;
    }
  };

  const handleChangeStatementFilter = (value: string) => {
    setStatementFilterValue(value);
    getStatementData({ filterValue: value });
  };

  const getExportParams = (currency?: string) => {
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    const startDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(startDate, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    );

    const endDateAdjusted = subtractSelectedTimezoneToDate(
      addDays(endDate, dayDifference),
      timezoneOffset || timezone,
      !!timezoneCookieEnabled || !!bettingDay
    );

    const baseParams = {
      startDate: startDateAdjusted.getTime(),
      endDate: endDateAdjusted.getTime(),
      currency: currencyCode,
      ...additionalParams
    };

    switch (pageType) {
      case MyBetsPage.ExchangeBets:
        const betsType = getMyBetsBetsType();
        return {
          filename: EXPORT_FILENAME.ExchangeBets,
          url: urls.myBets.getMyBetsDownload(),
          params: {
            betsType,
            ...baseParams
          }
        };
      case MyBetsPage.AccountStatement:
        return {
          filename: EXPORT_FILENAME.AccountStatement,
          url: urls.myBets.getAccountStatementDownload(),
          params: {
            accountStatementOrigin: statementFilterValue,
            ...baseParams
          }
        };
      case MyBetsPage.ProfitLoss:
        return {
          filename: EXPORT_FILENAME.ProfitLoss,
          url: urls.myBets.getProfitsDownload(),
          params: {
            eventTypeId: eventType?.id || eventType?.channelId,
            betType: betTypeValue,
            ...baseParams
          }
        };
    }
  };

  const getMyBetsPagesBaseUrl = () => {
    const myBetsDefaultTab = isPNCEnabled ? 'matched' : 'unmatched';
    switch (pageType) {
      case MyBetsPage.ExchangeBets:
        return `${exchangePageType}${MyBetsLocations.MyBets}/${activeStatusTab?.toLowerCase() || myBetsDefaultTab}`;
      case MyBetsPage.AccountStatement:
        return `${exchangePageType}${MyBetsLocations.Statement}`;
      case MyBetsPage.ProfitLoss:
        return `${exchangePageType}${MyBetsLocations.ProfitAndLoss}`;
    }
  };

  const getPageData = ({ refreshedStartDate, refreshedEndDate, size, number, currency }: TGetBetAndPageDataParams) => {
    switch (pageType) {
      case MyBetsPage.ExchangeBets:
        return getBetData({ refreshedStartDate, refreshedEndDate, size, number, currency });
      case MyBetsPage.AccountStatement:
        return getStatementData({ refreshedStartDate, refreshedEndDate, size, number, currency });
      case MyBetsPage.ProfitLoss:
        return getPLData({
          refreshedStartDate,
          refreshedEndDate,
          size,
          number,
          eventTypeId: eventType?.id || eventType?.channelId,
          currency
        });
    }
  };

  const refreshData = () => {
    const url = `${getMyBetsPagesBaseUrl()}/1`;
    dispatch(setPageNumber(0));
    if (pageType === MyBetsPage.ExchangeBets) {
      getBetData({ number: 0 });
    }
    if (pageType === MyBetsPage.ProfitLoss) {
      getPLData({ number: 0, eventTypeId: eventType?.id || eventType?.channelId });
    }
    if (pageType === MyBetsPage.AccountStatement) {
      getStatementData({ number: 0 });
    }

    if (url !== location.pathname) {
      navigate(url, { replace: true });
    }
  };

  const handleSetStartDate = (date: Date) => {
    const newStartDate = new Date(date);
    newStartDate.setHours(0);
    const startOfTheDay = applyTimezone(newStartDate, timezone, false);
    const todayWithTimezone = applyTimezone(getTodayDate(), timezone, false);
    const startDateExtendsEndDate = startOfTheDay.getTime() > endDate.getTime();

    if (pageType === MyBetsPage.AccountStatement) {
      setCookie(CookieNames.ACCOUNT_STATEMENT_DATEFROM, new Date(startOfTheDay));
      if (startDateExtendsEndDate) {
        setCookie(CookieNames.ACCOUNT_STATEMENT_DATETO, new Date(todayWithTimezone));
      }
    }

    dispatch(setPageNumber(0));
    dispatch(setStartDate(startOfTheDay));
    dispatch(setIsCustomPeriod(false));

    if (startDateExtendsEndDate) {
      dispatch(setEndDate(todayWithTimezone));
    }

    getPageData({
      refreshedStartDate: startOfTheDay,
      refreshedEndDate: startDateExtendsEndDate ? todayWithTimezone : undefined,
      number: 0
    });

    if (pageNumber !== 0) {
      navigate(`${getMyBetsPagesBaseUrl()}/1`);
    }
  };

  const handleSetEndDate = (date: Date) => {
    const endDateWithTimezone = applyTimezone(date, timezone, false);

    if (pageType === MyBetsPage.AccountStatement) {
      setCookie(CookieNames.ACCOUNT_STATEMENT_DATETO, new Date(endDateWithTimezone));
    }

    dispatch(setPageNumber(0));
    dispatch(setEndDate(endDateWithTimezone));
    dispatch(setIsCustomPeriod(false));

    getPageData({ refreshedStartDate: startDate, refreshedEndDate: endDateWithTimezone, number: 0 });

    if (pageNumber !== 0) {
      navigate(`${getMyBetsPagesBaseUrl()}/1`);
    }
  };

  const handlePeriodDropdown = (period: TPeriods) => {
    const startOfTheDay = new Date(getStartDateByPeriod(period, startDate, dayDifference));
    const endWithTimezone = getEndDateByPeriod(period, endDate, dayDifference);

    if (pageType === MyBetsPage.AccountStatement) {
      setCookie(CookieNames.ACCOUNT_STATEMENT_DATEFROM, new Date(startOfTheDay));
      setCookie(CookieNames.ACCOUNT_STATEMENT_DATETO, new Date(endWithTimezone));
    }

    dispatch(setPageNumber(0));
    dispatch(setPageSize(10));
    dispatch(setStartDate(startOfTheDay));
    dispatch(setEndDate(endWithTimezone));

    getPageData({ refreshedStartDate: startOfTheDay, refreshedEndDate: endWithTimezone, number: 0, size: 10 });
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleResetDates = () => {
    const startOfTheDay = applyTimezone(new Date(getTodayStartDate()), timezone, false);
    const todayWithTimezone = applyTimezone(getTodayDate(), timezone, false);

    if (pageType === MyBetsPage.AccountStatement) {
      setCookie(CookieNames.ACCOUNT_STATEMENT_DATEFROM, new Date(startOfTheDay));
      setCookie(CookieNames.ACCOUNT_STATEMENT_DATETO, new Date(todayWithTimezone));
    }

    dispatch(setPageNumber(0));
    dispatch(setPageSize(10));
    dispatch(setStartDate(startOfTheDay));
    dispatch(setEndDate(todayWithTimezone));

    getPageData({ refreshedStartDate: startOfTheDay, refreshedEndDate: todayWithTimezone, number: 0, size: 10 });
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleSetPageNumber = (number: number) => {
    dispatch(setPageNumber(number));
    getPageData({ number });
    navigate(`${getMyBetsPagesBaseUrl()}/${number + 1}`);
  };

  const handleSetPageSize = (size: number) => {
    dispatch(setPageSize(size));
    getPageData({ size });
  };

  const handleSetPageSizeAndNumber = (number: number, size: number) => {
    dispatch(setPageNumber(number));
    dispatch(setPageSize(size));
    getPageData({ number, size });
    navigate(`${getMyBetsPagesBaseUrl()}/${number + 1}`);
  };

  const getTabByLocation = () => {
    switch (urlWithoutPageIndex) {
      case MyBetsLocations.Statement:
        break;
      case MyBetsLocations.MyBets:
        setActivePeriodTab(Period.Current);
        handleActiveStatusTab(BetStatuses.Unmatched);
        break;
      case MyBetsLocations.Matched:
        setActivePeriodTab(Period.Current);
        break;
      case MyBetsLocations.Unmatched:
        setActivePeriodTab(Period.Current);
        break;
      case MyBetsLocations.Cancelled:
        setActivePeriodTab(Period.Past);
        break;
      case MyBetsLocations.Settled:
        setActivePeriodTab(Period.Past);
        break;
      case MyBetsLocations.Lapsed:
        setActivePeriodTab(Period.Past);
        break;
      case MyBetsLocations.Voided:
        setActivePeriodTab(Period.Past);
        break;
    }
  };

  const handleActiveStatusTab = (tab: BetStatuses) => {
    switch (tab) {
      case BetStatuses.Unmatched:
        navigate(`${exchangePageType}${MyBetsLocations.Unmatched}/1`);
        break;
      case BetStatuses.Matched:
        navigate(`${exchangePageType}${MyBetsLocations.Matched}/1`);
        break;
      case BetStatuses.Settled:
        navigate(`${exchangePageType}${MyBetsLocations.Settled}/1`);
        break;
      case BetStatuses.Cancelled:
        navigate(`${exchangePageType}${MyBetsLocations.Cancelled}/1`);
        break;
      case BetStatuses.Lapsed:
        navigate(`${exchangePageType}${MyBetsLocations.Lapsed}/1`);
        break;
      case BetStatuses.Voided:
        navigate(`${exchangePageType}${MyBetsLocations.Voided}/1`);
        break;
      default:
        break;
    }
  };

  const handleFetchDataOnTimeRangeChange = ({
    startDatePl,
    currency,
    eventTypeId
  }: TOnFetchDataProfitLossTimeRangeChangeParams) => {
    const currencyCode = getMultiCurrencyCodeFromArg(
      multiCurrencySupported,
      isMultiCurrencySupported,
      currentCurrency.currencyCode,
      multiCurrencyValue,
      currency
    );

    dispatch(setPageNumber(0));
    const startOfTheDay = isMobile ? startDatePl : getTodayStartDate();

    getPLData({
      refreshedStartDate: applyTimezone(startOfTheDay, timezone, false),
      eventTypeId: eventTypeId || eventType?.id,
      currency: currencyCode,
      number: 0
    });
  };

  const handleSetTimeRange = ({ value, currency, eventTypeId }: TProfitLossTimeRangeChangeParams) => {
    if (value?.name) {
      setCookie(CookieNames.PROFITANDLOSS_TIMERANGE, value?.name);
    }

    const id = eventTypeId || eventType.channelId;

    dispatch(setTimeRange(value));

    switch (value?.name) {
      case PLTimeRanges.LastDay:
        const startOfTheDay = setHoursMinutesToZero(new Date());
        handleFetchDataOnTimeRangeChange({ startDatePl: startOfTheDay, currency, eventTypeId: id });
        break;
      case PLTimeRanges.Yesterday: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getYesterdayDate(), currency, eventTypeId: id });
        break;
      }
      case PLTimeRanges.SevenDays: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getSevenDaysAgo(), currency, eventTypeId: id });
        break;
      }
      case PLTimeRanges.Month: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getThirtyDaysAgo(), currency, eventTypeId: id });
        break;
      }
      case PLTimeRanges.ThreeMonths: {
        handleFetchDataOnTimeRangeChange({ startDatePl: getThreeMonthsAgo(), currency, eventTypeId: id });
        break;
      }
    }
  };

  const handleSetEventType = (value: SelectDropdownValue) => {
    setEventType(value);
    setCookie(CookieNames.PROFITANDLOSS_SPORTNAME, value?.name);

    if (value?.id) {
      setCookie(CookieNames.PROFITANDLOSS_SPORTID, value?.id);
      setCookie(CookieNames.PROFITANDLOSS_SPORTTRANSLATION, '');
    }

    if (value?.translationKey) {
      setCookie(CookieNames.PROFITANDLOSS_SPORTTRANSLATION, value?.translationKey);
      setCookie(CookieNames.PROFITANDLOSS_SPORTID, '');
    }

    getPLData({
      eventTypeId:
        value.name === plDefaultSportTypeValues.sports.name || value.name === plDefaultSportTypeValues.games.name
          ? ''
          : value.id || value.channelId,
      number: 0
    });

    dispatch(setPageNumber(0));
    navigate(`${getMyBetsPagesBaseUrl()}/1`);
  };

  const handleChangeBetType = (betType: BetTypeValue) => {
    dispatch(resetPLTotalForEvent());
    setBetTypeValue(betType);
    setEventType(betType === BetTypeValue.Sports ? plDefaultSportTypeValues.sports : plDefaultSportTypeValues.games);
  };

  useEffect(() => {
    if (betUpdate) {
      navigate(`${SPORT_BASE_URL}/${cancelledBetData?.eventTypeId}/market/${cancelledBetData?.marketId}/cancel`);
    }
  }, [betUpdate]);

  useEffect(() => {
    const pageNumberFromUrl = parseInt(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));
    if (pageNumberFromUrl && pageNumberFromUrl > 1 && isLoggedIn) {
      const urlArr = location.pathname.split('/');
      urlArr[urlArr.length - 1] = '1';
      navigate(urlArr.join('/'));
    }
    if (location.pathname === MyBetsLocations.MyBets && isLoggedIn) {
      navigate(`${exchangePageType}${MyBetsLocations.MyBets}/${isPNCEnabled ? 'matched' : 'unmatched'}/1`, {
        replace: true
      });
    }

    return () => {
      dispatch(resetPageData());
    };
  }, []);

  useEffect(() => {
    if (accountProducts !== null) {
      refreshData();
    }
  }, [
    accountProducts,
    cookies.PROFITANDLOSS_BETTYPE,
    cookies.EXCHANGE_TYPE,
    cookies.BIAB_TIME_FORMAT,
    pageType,
    // Refresh content when unmatched bets were cancelled
    cancelAllUnmatchedBetsActionId
  ]);

  useEffect(() => {
    if (isDetailsOpen) {
      dispatch(hideMobileBetDetails());
    }

    getTabByLocation();
  }, [page, betTypeParam]);

  useEffect(() => {
    if (currentCurrency.currencyCode && accountProducts !== null) {
      const currencyCode = getMultiCurrencyCode(currentCurrency.currencyCode, multiCurrencySupported, pageType);

      if (pageType === MyBetsPage.ProfitLoss) {
        if (activePLTab === PLBetType.Sports || (activePLTab === PLBetType.AsianView && !isAsianViewEnabled)) {
          dispatch(fetchProfitSports(currencyCode));
        } else if (activePLTab === PLBetType.Games) {
          dispatch(fetchProfitGames(currencyCode));
        }
      } else {
        const pageNumberFromUrl = parseInt(location.pathname.substring(location.pathname.lastIndexOf('/') + 1));

        if (pageNumberFromUrl - 1 !== pageNumber) {
          dispatch(setPageNumber(pageNumberFromUrl - 1));
          getPageData({
            number: pageNumberFromUrl - 1,
            currency: searchCurrency || currencyCode
          });
        } else {
          getPageData({ currency: searchCurrency || currencyCode });
        }
      }
    }
  }, [currentCurrency.currencyCode, accountProducts]);

  useEffect(() => {
    if (accountProducts) {
      if (
        isAsianViewEnabled &&
        !isSportsEnabled &&
        !isGamesEnabled &&
        cookies.EXCHANGE_TYPE !== ExchangeTypes.AsianView
      ) {
        setCookie(CookieNames.EXCHANGE_TYPE, ExchangeTypes.AsianView, { path: '/' });
      } else if (
        !isAsianViewEnabled &&
        (isSportsEnabled || isGamesEnabled) &&
        cookies.EXCHANGE_TYPE !== ExchangeTypes.SportsGames
      ) {
        setCookie(CookieNames.EXCHANGE_TYPE, ExchangeTypes.SportsGames, { path: '/' });
      }
    }
  }, [isAsianViewEnabled, isSportsEnabled, isGamesEnabled, cookies.EXCHANGE_TYPE, accountProducts]);

  return {
    timezoneOffset,
    bettingDay,
    dayDifference,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    handleSetEndDate,
    handleSetStartDate,
    handleSetPageNumber,
    handleSetPageSize,
    handleSetPageSizeAndNumber,
    activeStatusTab,
    handleActiveStatusTab,
    activePeriodTab,
    setActivePeriodTab,
    getBetData,
    statementFilterValue,
    handleChangeStatementFilter,
    handlePeriodDropdown,
    handleResetDates,
    timeRange,
    handleSetTimeRange,
    eventType,
    handleSetEventType,
    handleChangeBetType,
    getStatementData,
    getPLData,
    refreshData,
    getExportParams
  };
};
