import { MouseEvent, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { asianViewDropdown } from 'constants/branding';
import { ASIAN_BASE_URL } from 'constants/locations';
import { getAsianViewLayBettingEnabled, getBackendPage } from 'redux/modules/appConfigs/selectors';
import { clearQuickLayBets } from 'redux/modules/asianViewQuickBetting';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { setSettings } from 'redux/modules/user';
import { getAccountSettings, getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { getBooleanValue } from 'utils';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const LayBetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isEnabled = useSelector(getAsianViewLayBettingEnabled);
  const contentLink = useSelector(getBackendPage(PagesFromBackend.LAY_BETTING_RULES_PAGE));
  const accountSettings = useSelector(getAccountSettings);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [cookies, setCookie] = useCookies([CookieNames.ASIAN_VIEW_LAY_BETTING]);

  const isChecked = isAccountSettings
    ? accountSettings?.asianViewLayBetting
    : getBooleanValue(cookies.ASIAN_VIEW_LAY_BETTING);

  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false);

  const onLayBettingHandler = () => {
    const isCheckedValue = !isChecked;

    if (isAccountSettings) {
      dispatch(
        setSettings({
          asianViewLayBetting: isCheckedValue
        })
      );
    } else {
      setCookie(CookieNames.ASIAN_VIEW_LAY_BETTING, isCheckedValue, {
        path: '/'
      });
    }

    if (!isCheckedValue) {
      dispatch(clearQuickLayBets());
    }
  };

  if (!isEnabled) {
    return null;
  }

  return (
    <>
      <div
        className={classNames(styles.placeholder, 'biab_lay-betting-filter', asianViewDropdown.dropdownBtn)}
        onClick={onLayBettingHandler}
      >
        <input type="checkbox" checked={isChecked} />
        <label className={styles.label}>
          <span>{t('asianView.labels.layBetting')}</span>
        </label>
        <i
          className={classNames('av-icon av-icon-info', styles.infoIcon)}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setIsPopupOpened(!isPopupOpened);
          }}
        >
          <span className="path1" />
          <span className="path2" />
        </i>
      </div>
      <Modal
        clickableBackground
        open={isPopupOpened}
        onClose={() => setIsPopupOpened(false)}
        title={t('asianView.labels.layBetting.rulesPopupTitle')}
        customClassNames={{ dialog: styles.modal__dialog }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: t('asianView.labels.layBetting.rulesPopupContent', {
              url: `${location.origin + getEnvironmentRootPath()}${ASIAN_BASE_URL}/pages/${contentLink}`
            })
          }}
        />
      </Modal>
    </>
  );
};

export default LayBetting;
