import { createPortal } from 'react-dom';

interface BetslipOverlayProps {
  hasSpinner?: boolean;
}

const BetslipOverlay = ({ hasSpinner = false }: BetslipOverlayProps) => {
  return createPortal(
    <div className="biab_betslip-overlay biab_betslip-overlay-shown biab_betslip-overlay-loading-spinner">
      {hasSpinner && <div className="biab_loading-spinner" />}
      <div className="biab_overlay-background" />
    </div>,
    document.getElementById('biab_body')!
  );
};

export default BetslipOverlay;
