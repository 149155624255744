import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  BetTypeValue,
  mapAccountPageToBetsType,
  PLBetType,
  plDefaultSportTypeValues,
  PLTabButtons
} from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import useDevice from 'hooks/useDevice';
import { getCurrency, getIsAsianViewEnabled } from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchAccountProducts, resetAccountProducts } from 'redux/modules/myBets';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { TFetchDataWithCurrencyHandlers } from 'types/myBets';

import MyBetsPagesContent from './components/MyBetsPagesContent/MyBetsPagesContent';
import MyBetsPagesHeader from './components/MyBetsPagesHeader/MyBetsPagesHeader';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './MyBetsPages.module.scss';

const MyBetsPages = () => {
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE, CookieNames.PROFITANDLOSS_SPORTNAME]);

  const { exchangePageType, selectedTab } = useAccountUrlParams();
  const betsType = mapAccountPageToBetsType[selectedTab];

  const currentCurrency = useSelector(getCurrency);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const accountProducts = useSelector(getAccountProductsByBetsType(betsType));

  const [headerHeight, setHeaderHeight] = useState(0);

  const isAsianViewEnabled = asianViewEnabled || !!accountProducts?.asianView;

  const onChangeBetType = useRef<(betType: BetTypeValue) => void>();
  const fetchDataWithCurrencyHandlers = useRef<TFetchDataWithCurrencyHandlers>({
    fetchBetDataHandler: undefined,
    fetchPLDataHandler: undefined,
    fetchStatementDataHandler: undefined
  });

  const { isMobile } = useDevice();

  const handleSetActivePLTab = (tabValue: PLBetType) => {
    setCookie(CookieNames.PROFITANDLOSS_BETTYPE, tabValue, { path: '/' });
    setCookie(
      CookieNames.PROFITANDLOSS_SPORTNAME,
      tabValue === PLBetType.Games ? plDefaultSportTypeValues.games.name : plDefaultSportTypeValues.sports.name,
      { path: '/' }
    );
  };

  const activePLTab = (cookies.PROFITANDLOSS_BETTYPE as PLBetType) || PLTabButtons[0];

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (selectedTab && currentCurrency.currencyCode) {
      dispatch(
        fetchAccountProducts({
          currency: currentCurrency.currencyCode,
          betsType: betsType
        })
      );
    }
    return () => {
      dispatch(resetAccountProducts());
    };
  }, [selectedTab, currentCurrency.currencyCode]);

  if (accountProducts === null) {
    return null;
  }

  return (
    <div
      className={classNames('biab_account-page', styles.betsPagesContainer, {
        [styles.betsPagesContainer__mobile]: isMobile
      })}
    >
      <MyBetsPagesHeader
        accountProducts={accountProducts}
        activePLTab={activePLTab}
        onSetActivePLTab={handleSetActivePLTab}
        selectedTab={selectedTab}
        onChangeBetType={onChangeBetType}
        exchangePageType={exchangePageType}
        isAsianViewEnabled={isAsianViewEnabled}
        fetchDataWithCurrencyHandlers={fetchDataWithCurrencyHandlers}
        setHeaderHeight={setHeaderHeight}
      />
      {isLoggedIn && (
        <MyBetsPagesContent
          fetchDataWithCurrencyHandlers={fetchDataWithCurrencyHandlers}
          onChangeBetType={onChangeBetType}
          isAsianViewEnabled={isAsianViewEnabled}
          selectedTab={selectedTab}
          activePLTab={activePLTab}
          headerHeight={headerHeight}
        />
      )}
    </div>
  );
};

export default MyBetsPages;
