import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { sortBy, uniqBy } from 'lodash';
import { useIntersectionObserver } from 'usehooks-ts';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import AsianViewTabs from 'components/AsianViewPageModule/components/AsianViewTabs';
import EmptyMarket from 'components/AsianViewPageModule/components/Event/MarketCell/EmptyMarket';
import AsianViewProfitLossButton from 'components/AsianViewProfitLossButton';
import CashOut from 'components/CashOut';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  AsianViewMarketLink
} from 'constants/asianView';
import { asianViewIcons, asianViewMiddleSection as branding } from 'constants/branding';
import { MARKET_TYPES } from 'constants/marketTypes';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getLanguage, getTranslation } from 'redux/modules/appConfigs/selectors';
import { setScrollUpdate } from 'redux/modules/appSettings';
import { getScrollUpdate } from 'redux/modules/appSettings/selectors';
import { setOpenEventId } from 'redux/modules/asianView';
import { getAsianViewEventIndex, getCountMarketsById, getOpenEventId } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent, TAsianViewMarket } from 'redux/modules/asianView/type';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import {
  getClosedMarketsIds,
  getInPlayMarketsIds,
  getIsMarketPricesKeyLineDefinition,
  getMarketPricesFirstKeyLineHandicap,
  getMarketPricesFirstKeyLineId,
  getMarketPricesRunners,
  getMarketPricesSecondKeyLineId
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { RunnersStatuses, TMarketPricesRunner } from 'types/markets';
import { getHandicapLine } from 'utils/avMarketCell';

import MarketCell from '../Event/MarketCell';

import EmptyMarketCell from './EmptyMarketCell';
import MarketsCell from './MarketsCell';
import SelectionNames from './SelectionNames';

import styles from './styles.module.scss';

const getIsRunnersByIndex = ({
  index,
  market,
  runnerStatuses,
  marketPricesId,
  secondKeyLineId,
  isKeyLine,
  firstKeyLineHandicap,
  firstKeyLineId
}: {
  index: number;
  market?: TAsianViewMarket;
  runnerStatuses: TMarketPricesRunner[];
  isKeyLine: boolean;
  firstKeyLineHandicap?: number;
  firstKeyLineId?: number;
  secondKeyLineId?: number;
  marketPricesId?: string;
}) => {
  const allRunners = market?.runners ? [...market.runners] : [];
  const runners = allRunners.filter(runner => {
    const curRunnerStatus = runnerStatuses.find(runnerStatus => {
      return +runner.id === runnerStatus.id && runner.handicap === runnerStatus.handicap;
    });
    return !curRunnerStatus || curRunnerStatus?.status === RunnersStatuses.ACTIVE;
  });
  const isGoalLines = market?.marketType === MARKET_TYPES.altTotalGoals;
  const isAsianHandicap = market?.marketType === MARKET_TYPES.asianHandicap;

  if (isAsianHandicap || isGoalLines) {
    if (isKeyLine) {
      const homeHandicap = firstKeyLineHandicap || 0;
      const hasNextLine = !!getHandicapLine({
        runners,
        handicap: homeHandicap + 0.25,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;
      const hasPrevLine = !!getHandicapLine({
        runners,
        handicap: homeHandicap - 0.25,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;

      const lineIndex =
        (index === 1 && !hasNextLine) || (index === 2 && hasPrevLine && hasNextLine)
          ? -1
          : index === 2 && !hasNextLine
          ? -2
          : index;

      return !!getHandicapLine({
        runners,
        handicap: homeHandicap + 0.25 * lineIndex,
        isAsianHandicap,
        firstKeyLineId,
        secondKeyLineId
      }).length;
    } else if (marketPricesId) {
      return false;
    }
  }

  return !!runners.length;
};

type EventProps = {
  index: number;
  event: TAsianViewEvent;
  sportId: string;
  competitionId: string;
  isFirst: boolean;
  isSingleLine?: boolean;
  onEventClose: (eventId: string) => void;
};

const Event = ({ event, sportId, competitionId, isFirst, isSingleLine = false, onEventClose }: EventProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { marketLink } = useParams();
  const [searchParams] = useSearchParams();

  const scrollUpdate = useSelector(getScrollUpdate);
  const language = useSelector(getLanguage);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const inPlayMarketsIds = useSelector(getInPlayMarketsIds);
  const marketsCounter = useSelector(getCountMarketsById(event.id));
  const translation = useSelector(getTranslation);
  const openEventId = useSelector(getOpenEventId);
  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, event.id));
  const eventIndex = useSelector(getAsianViewEventIndex(event.id));

  const [isExpanded, setIsExpanded] = useState(false);

  const btnRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { ref, isIntersecting } = useIntersectionObserver({ rootMargin: '400px' });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const showCashOut = useMemo(() => {
    return event.sections.top?.some(({ cashOutEnabled }) => cashOutEnabled);
  }, [event.sections.top]);
  const { markets, matchOdds, halfTime, goalLines, asianHandicap, firstHalfGoalsMarkets, isInPlay } = useMemo(() => {
    const eventMarkets = Object.values(event.sections).flat();
    const activeMarkets = (eventMarkets || []).filter(market => !closedMarketIds.includes(market.id));
    return {
      markets: activeMarkets,
      matchOdds: activeMarkets.find(market => market.marketType === MARKET_TYPES.matchOdds),
      halfTime: activeMarkets.find(market => market.marketType === MARKET_TYPES.halfTime),
      goalLines: activeMarkets.find(market => market.marketType === MARKET_TYPES.altTotalGoals),
      asianHandicap: activeMarkets.find(market => market.marketType === MARKET_TYPES.asianHandicap),
      firstHalfGoalsMarkets: sortBy(
        uniqBy(activeMarkets, market => market.id),
        ['marketType']
      ).filter(market => market.marketType.includes(MARKET_TYPES.firstHalfGoals)),
      isInPlay: activeMarkets.filter(market => inPlayMarketsIds.includes(market.id)).length > 0
    };
  }, [event.sections, closedMarketIds, inPlayMarketsIds]);

  const asianHandicapFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(asianHandicap?.id));
  const asianHandicapFirstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(asianHandicap?.id));
  const asianHandicapSecondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(asianHandicap?.id));
  const isAsianHandicapKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(asianHandicap?.id));
  const goalLinesFirstKeyLineHandicap = useSelector(getMarketPricesFirstKeyLineHandicap(goalLines?.id));
  const goalLinesFirstKeyLineId = useSelector(getMarketPricesFirstKeyLineId(goalLines?.id));
  const goalLinesSecondKeyLineId = useSelector(getMarketPricesSecondKeyLineId(goalLines?.id));
  const isGoalLinesKeyLine = useSelector(getIsMarketPricesKeyLineDefinition(goalLines?.id));
  const asianHandicapRunnerStatuses = useSelector(getMarketPricesRunners(asianHandicap?.id));
  const goalLinesRunnerStatuses = useSelector(getMarketPricesRunners(goalLines?.id));

  const { isAHFirstKLRunners, isAHSecondKLRunners } = useMemo(() => {
    return {
      isAHFirstKLRunners: getIsRunnersByIndex({
        index: 1,
        market: asianHandicap,
        runnerStatuses: asianHandicapRunnerStatuses,
        isKeyLine: isAsianHandicapKeyLine,
        firstKeyLineHandicap: asianHandicapFirstKeyLineHandicap,
        secondKeyLineId: asianHandicapSecondKeyLineId,
        firstKeyLineId: asianHandicapFirstKeyLineId
      }),
      isAHSecondKLRunners: getIsRunnersByIndex({
        index: 2,
        market: asianHandicap,
        runnerStatuses: asianHandicapRunnerStatuses,
        isKeyLine: isAsianHandicapKeyLine,
        firstKeyLineHandicap: asianHandicapFirstKeyLineHandicap,
        secondKeyLineId: asianHandicapSecondKeyLineId,
        firstKeyLineId: asianHandicapFirstKeyLineId
      })
    };
  }, [
    asianHandicapFirstKeyLineHandicap,
    asianHandicapFirstKeyLineId,
    asianHandicapSecondKeyLineId,
    asianHandicap,
    isAsianHandicapKeyLine,
    asianHandicapRunnerStatuses
  ]);

  const { isGLFirstKLRunners, isGLSecondKLRunners } = useMemo(() => {
    return {
      isGLFirstKLRunners: getIsRunnersByIndex({
        index: 1,
        market: goalLines,
        runnerStatuses: goalLinesRunnerStatuses,
        isKeyLine: isGoalLinesKeyLine,
        firstKeyLineHandicap: goalLinesFirstKeyLineHandicap,
        firstKeyLineId: goalLinesFirstKeyLineId,
        secondKeyLineId: goalLinesSecondKeyLineId
      }),
      isGLSecondKLRunners: getIsRunnersByIndex({
        index: 2,
        market: goalLines,
        runnerStatuses: goalLinesRunnerStatuses,
        isKeyLine: isGoalLinesKeyLine,
        firstKeyLineHandicap: goalLinesFirstKeyLineHandicap,
        firstKeyLineId: goalLinesFirstKeyLineId,
        secondKeyLineId: goalLinesSecondKeyLineId
      })
    };
  }, [
    goalLinesFirstKeyLineHandicap,
    goalLinesFirstKeyLineId,
    goalLinesSecondKeyLineId,
    goalLines,
    isGoalLinesKeyLine,
    goalLinesRunnerStatuses
  ]);

  const isShowTab = !!openEventId && openEventId.toString() === event.id;
  const home = event.homeTeamTranslations?.[translation] || '';
  const away = event.awayTeamTranslations?.[translation] || '';
  const draw = isSingleLine ? '' : t('asianView.labels.draw');
  const isVisible = !!home && !!away && !!language;
  const scoreMarketId = isSingleLine
    ? asianHandicap?.id || goalLines?.id || (firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id) || markets[0]?.id
    : matchOdds?.id ||
      asianHandicap?.id ||
      goalLines?.id ||
      halfTime?.id ||
      (firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id) ||
      markets[0]?.id;

  const hasVisibleMarkets = isSingleLine
    ? !!(asianHandicap?.id || goalLines?.id || (firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id))
    : !!(
        matchOdds?.id ||
        asianHandicap?.id ||
        goalLines?.id ||
        halfTime?.id ||
        (firstHalfGoalsMarkets && firstHalfGoalsMarkets[0]?.id)
      );

  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const isSearchQuery = query.length >= SEARCH_QUERY_LENGTH;
  const showExpandButton =
    isAHFirstKLRunners ||
    isAHSecondKLRunners ||
    isGLFirstKLRunners ||
    isGLSecondKLRunners ||
    !!firstHalfGoalsMarkets[1]?.runners.length ||
    !!firstHalfGoalsMarkets[2]?.runners.length;

  const columns = isSingleLine
    ? [
        { market: asianHandicap, class: styles.event__market__twoSelections },
        { market: goalLines, class: styles.event__market__twoSelections },
        { markets: firstHalfGoalsMarkets, class: styles.event__market__twoSelections }
      ]
    : [
        { market: asianHandicap, class: styles.event__market__big },
        { market: goalLines, class: styles.event__market__big },
        { market: matchOdds, class: styles.event__market },
        { markets: firstHalfGoalsMarkets, class: styles.event__market__big },
        { market: halfTime, class: styles.event__market }
      ];

  const handlerOpenTab = () => {
    if (!isShowTab && openEventId && scrollUpdate.top && !scrollUpdate.trigger) {
      if (btnRef.current) {
        const parent = btnRef.current.closest('.biab_middle-section');
        const rect = btnRef.current.getBoundingClientRect();
        const btnTop = rect.top + (parent?.scrollTop || 0);
        if (btnTop > scrollUpdate.top) {
          dispatch(setScrollUpdate({ ...scrollUpdate, ...{ trigger: true } }));
        } else {
          dispatch(setScrollUpdate({ top: 0, offset: 0, trigger: false }));
        }
      }
    }
    dispatch(setOpenEventId(isShowTab ? null : event.id));
  };

  const { ref: eventRef } = useMarketsPricesVisibleSocketParam({
    marketId: hasVisibleMarkets ? undefined : scoreMarketId,
    eventId: hasVisibleMarkets ? undefined : event.id,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection
  });

  useEffect(() => {
    if (markets.length === 0) {
      onEventClose(event.id);
    }
  }, [markets]);

  useEffect(() => {
    ref(containerRef.current);

    return () => {
      dispatch(setOpenEventId(null));
    };
  }, []);

  return (
    <div ref={containerRef}>
      {!isIntersecting && <div style={{ height }} />}
      {isIntersecting && isVisible && (
        <>
          <div
            data-market-id={scoreMarketId}
            data-event-id={event.id}
            data-market-prices={!hasVisibleMarkets}
            ref={!hasVisibleMarkets ? eventRef : undefined}
            className={classNames('biab_asian-view-event', styles.event, branding.MAIN_CONTAINER_BORDER, {
              [styles.event__inPlay]: isInPlay,
              [branding.IN_PLAY_EVENT_1]: isInPlay && eventIndex % 2 === 0,
              [branding.IN_PLAY_EVENT_2]: isInPlay && eventIndex % 2 !== 0,
              [branding.NON_PLAY_EVENT_1]: !isInPlay && eventIndex % 2 === 0,
              [branding.NON_PLAY_EVENT_2]: !isInPlay && eventIndex % 2 !== 0
            })}
          >
            <AsianViewInPlayCell marketStartTime={event.startTime} eventId={event.id} marketId={scoreMarketId} />
            <div className={classNames('biab_asian-view-event-selections-wrapper', styles.event__selections)}>
              <div className={styles.event__rightSection}>
                <SelectionNames
                  home={home}
                  away={away}
                  draw={draw}
                  market={matchOdds || asianHandicap || goalLines}
                  isFirstEvent={isFirst}
                />
                <div className={styles.event__actionsWrapper}>
                  <AsianViewProfitLossButton eventId={event.id} marketId={markets[0]?.id} />
                  <div className={classNames('biab_asian-view-event-actions', styles.event__actions)}>
                    <AsianViewFavouriteStar
                      favourite={{
                        entryType: FAVORITES_TYPES.event,
                        sportId,
                        entryId: event.id,
                        entryName: event.translations[translation]
                      }}
                    />
                    {showCashOut && (
                      <CashOut
                        showLabel={false}
                        showInfoIcon={false}
                        className={{ container: styles.event__cashout, icon: styles.event__cashoutIcon }}
                        disabled={!cashOutCounter}
                        isAvPLTableAvailable
                      />
                    )}
                  </div>
                </div>
              </div>
              {!isSingleLine && showExpandButton && (
                <button
                  onClick={() => setIsExpanded(prevState => !prevState)}
                  className={classNames(
                    'biab_asian-view-event-expand',
                    styles.event__selections__expand,
                    asianViewIcons.SHOW_MORE_LINES_ICON
                  )}
                >
                  <i
                    className={classNames('av-icon', {
                      'av-icon-minus': isExpanded,
                      'av-icon-plus': !isExpanded
                    })}
                  />
                </button>
              )}
            </div>
            {columns.map((column, columnIndex) => (
              <div
                key={columnIndex}
                className={classNames(styles.event__col, column.class, branding.MAIN_CONTAINER_BORDER)}
              >
                {column.market && (
                  <MarketCell
                    competitionId={competitionId}
                    eventId={event.id}
                    market={column.market}
                    isExpanded={isExpanded || isSingleLine}
                    isFirstEvent={isFirst}
                    isSingleLine={isSingleLine}
                  />
                )}
                {!column.market && isFirst && !column.markets && (
                  <EmptyMarketCell isFirstEvent={isFirst} isSingleLine={isSingleLine} />
                )}
                {column.markets && (
                  <MarketsCell
                    competitionId={competitionId}
                    eventId={event.id}
                    markets={column.markets}
                    isExpanded={isExpanded || isSingleLine}
                    isFirstEvent={isFirst}
                    isSingleLine={isSingleLine}
                  />
                )}
                {isSingleLine && column.markets && !column.markets.length && (
                  <>
                    <EmptyMarket isSingleLine isFirst />
                    <EmptyMarket isSingleLine />
                    <EmptyMarket isSingleLine />
                  </>
                )}
              </div>
            ))}
            <div className={classNames(styles.event__col, styles.event__streaming, branding.MAIN_CONTAINER_BORDER)}>
              {event.videoStreamingEnabled && (
                <VideoStream hideLabel visible={event.videoStreamingEnabled} isInHeader eventId={event.id} />
              )}
              {event.matchStatEnabled && (
                <AsianViewMatchStatistics matchStatEnabled={event.matchStatEnabled} eventId={event.id} />
              )}
              {marketsCounter > 0 && (
                <div ref={btnRef} className={styles.event__moreMarketsWrapper} onClick={handlerOpenTab}>
                  <div
                    className={classNames(
                      'biab_asian-view-more-markets',
                      styles.event__moreMarkets,
                      asianViewIcons.EXPAND_EVENT_ICON,
                      {
                        [styles.event__moreMarkets__opened]: isShowTab
                      }
                    )}
                  >
                    {marketsCounter}
                    <i className={classNames(`fa2 fa2-sort-${isShowTab ? 'up' : 'down'}`)} />
                  </div>
                </div>
              )}
            </div>
          </div>
          {isShowTab && (
            <AsianViewTabs
              scoreMarketId={scoreMarketId}
              eventId={event.id}
              marketLink={
                marketLink && !isFavouriteView && !isPopularLinkView && !isSearchQuery
                  ? marketLink
                  : AsianViewMarketLink.HDP_AND_OU
              }
              sportId={sportId}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Event;
