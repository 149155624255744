import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import {
  cancelBet,
  failureAccountProducts,
  failureGetMyBetsData,
  fetchAccountProducts,
  fetchCharges,
  fetchMyBets,
  fetchPL,
  fetchPLTotal,
  fetchPLTotalForEvent,
  fetchProfitGames,
  fetchProfitSports,
  fetchStatement,
  successAccountProducts,
  successCancelBet,
  successCharges,
  successFetchMyBets,
  successPL,
  successPLTotal,
  successPLTotalForEvent,
  successProfitGames,
  successProfitSports,
  successStatement
} from 'redux/modules/myBets';

import {
  SuccessAccountProduct,
  TAccountProduct,
  TBetsType,
  TCancelBetResponse,
  TChargesResponse,
  TMyBetsResponse,
  TPLResponse,
  TPLTotalResponse,
  TProfitGamesResponse,
  TProfitSportsResponse,
  TStatementResponse
} from './type';

export function* getBetsWatcher(action: ReturnType<typeof fetchMyBets>) {
  try {
    const response: TMyBetsResponse = yield call<typeof api.myBets.getBets>(api.myBets.getBets, action.payload);
    yield put(successFetchMyBets(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getStatementWatcher(action: ReturnType<typeof fetchStatement>) {
  try {
    const response: TStatementResponse = yield call<typeof api.myBets.getAccountStatement>(
      api.myBets.getAccountStatement,
      action.payload
    );
    yield put(successStatement(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getPLWatcher(action: ReturnType<typeof fetchPL>) {
  try {
    const response: TPLResponse = yield call<typeof api.myBets.getPL>(api.myBets.getPL, action.payload);
    yield put(successPL(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getPLTotalWatcher(action: ReturnType<typeof fetchPLTotal>) {
  try {
    const response: TPLTotalResponse = yield call<typeof api.myBets.getPLTotal>(api.myBets.getPLTotal, action.payload);
    yield put(successPLTotal(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getPLTotalEventWatcher(action: ReturnType<typeof fetchPLTotalForEvent>) {
  try {
    const response: TPLTotalResponse = yield call<typeof api.myBets.getPLTotal>(api.myBets.getPLTotal, action.payload);
    yield put(successPLTotalForEvent(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getSportsListWatcher(action: ReturnType<typeof fetchProfitSports>) {
  try {
    const response: TProfitSportsResponse = yield call<typeof api.myBets.getSportsList>(
      api.myBets.getSportsList,
      action.payload
    );
    yield put(successProfitSports(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getGamesListWatcher(action: ReturnType<typeof fetchProfitGames>) {
  try {
    const response: TProfitGamesResponse = yield call<typeof api.myBets.getGamesList>(
      api.myBets.getGamesList,
      action.payload
    );
    yield put(successProfitGames(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* cancelUnmatchedBet(action: ReturnType<typeof cancelBet>) {
  try {
    const response: TCancelBetResponse = yield call<typeof api.myBets.cancelBet>(api.myBets.cancelBet, action.payload);
    yield put(successCancelBet(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getChargesWatcher() {
  try {
    const response: TChargesResponse = yield call<typeof api.myBets.getCharges>(api.myBets.getCharges);
    yield put(successCharges(response));
  } catch (error) {
    yield put(failureGetMyBetsData(error));
  }
}

export function* getAccountProductsWatcher(action: ReturnType<typeof fetchAccountProducts>) {
  try {
    const response: TAccountProduct = yield call<typeof api.myBets.accountProducts>(
      api.myBets.accountProducts,
      action.payload
    );
    const betsType = action.payload.betsType as TBetsType;
    yield put(successAccountProducts({ [betsType]: response } as SuccessAccountProduct));
  } catch (error) {
    yield put(failureAccountProducts(error));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchMyBets.type, getBetsWatcher),
    takeLatest(fetchStatement.type, getStatementWatcher),
    takeLatest(fetchPL.type, getPLWatcher),
    takeLatest(fetchPLTotal.type, getPLTotalWatcher),
    takeLatest(fetchPLTotalForEvent.type, getPLTotalEventWatcher),
    takeLatest(fetchProfitSports.type, getSportsListWatcher),
    takeLatest(fetchProfitGames.type, getGamesListWatcher),
    takeLatest(fetchCharges.type, getChargesWatcher),
    takeLatest(cancelBet.type, cancelUnmatchedBet),
    takeLatest(fetchAccountProducts.type, getAccountProductsWatcher)
  ]);
}
