import { createSelector } from '@reduxjs/toolkit';
import { find, reduce, some } from 'lodash';

import { AppState } from 'redux/reducers';
import { BetTypes, THandicap } from 'types/bets';

import { EBetErrors, EBetslipFilters, TSelectedBet } from './type';

const getBetslip = (state: AppState) => state.betslip;

export const getBetslipLoading = createSelector(getBetslip, betslip => betslip.loading);
export const getConsolidatedBets = createSelector(getBetslip, ({ consolidateBets }) => consolidateBets);
export const getBetslipActiveTab = createSelector(getBetslip, ({ activeTab }) => activeTab);
export const getBetslipCollapse = createSelector(getBetslip, ({ collapse }) => collapse);
export const getBetslipKeepSelectedBets = createSelector(getBetslip, ({ keepSelectedBets }) => keepSelectedBets);
export const getBetslipResetBetslipTab = createSelector(getBetslip, ({ resetBetslipTab }) => resetBetslipTab);
export const getBetslipPlaceBetsState = createSelector(getBetslip, ({ placeBetsState }) => placeBetsState);
export const getBetslipPlacementMessage = createSelector(getBetslip, ({ placementMessage }) => placementMessage);
export const getBetslipCurrentMarketId = createSelector(getBetslip, ({ currentMarketId }) => currentMarketId);
export const getBetslipCurrentSportId = createSelector(getBetslip, ({ currentSportId }) => currentSportId);
export const getBetslipPageBlock = createSelector(getBetslip, ({ pageBlock }) => pageBlock);
export const getBetslipFilters = createSelector(getBetslip, ({ filters }) => filters);
export const getIsFirstOpenedBetFocused = createSelector(
  getBetslip,
  ({ isFirstOpenedBetFocused }) => isFirstOpenedBetFocused
);

export const getBetslipMarketFilter = createSelector(getBetslip, betslip => betslip.filters[EBetslipFilters.MARKET]);

export const getBetslipMatchedFilter = createSelector(getBetslip, betslip => betslip.filters[EBetslipFilters.MATCHED]);

export const getBetslipSortingFilter = createSelector(getBetslip, betslip => betslip.filters[EBetslipFilters.SORTING]);

export const getConsolidateBetsFiltersEnabled = createSelector(
  getBetslip,
  betslip => !betslip.filters[EBetslipFilters.MATCHED] && !betslip.filters[EBetslipFilters.SORTING]
);

export const getPlacedBets = createSelector(getBetslip, betslip => betslip.placedBets);

export const getSelectedBetsAmount = createSelector(getBetslip, betslip =>
  reduce(
    betslip.selectedBets[betslip.currentMarketId] ?? {},
    (res, bets) => {
      return res + Object.keys(bets || {}).length;
    },
    0
  )
);

export const getSelectedBets = createSelector(getBetslip, betslip =>
  reduce(
    betslip.selectedBets[betslip.currentMarketId],
    (res: TSelectedBet[], bets) => {
      return [
        ...res,
        ...reduce(
          bets,
          (betsRes: TSelectedBet[], bet: TSelectedBet) => {
            return [...betsRes, { ...bet }];
          },
          []
        )
      ];
    },
    []
  )
);

export const getBackLessLayError = createSelector(getBetslip, betslip =>
  some(betslip.selectedBets[betslip.currentMarketId]?.[BetTypes.BACK] ?? {}, bet => bet.error === EBetErrors.EX015)
);

export const getSelectedBet = (
  marketId: string,
  selectionId: string | number,
  handicap: THandicap | null,
  type: BetTypes
) =>
  createSelector(
    getBetslip,
    betslip => betslip.selectedBets[marketId]?.[type]?.[`${selectionId}_${+(handicap || 0)}`] ?? null
  );

export const getFirstSelectedBet = createSelector(
  getBetslip,
  betslip =>
    find(betslip.selectedBets[betslip.currentMarketId]?.[BetTypes.BACK]) ||
    find(betslip.selectedBets[betslip.currentMarketId]?.[BetTypes.LAY]) ||
    null
);

export const getPlacedBetsToUpdate = createSelector(getBetslip, betslip => betslip.placedBetsToUpdate);

export const getBetslipType = createSelector(getBetslip, betslip => betslip.betslipType);

export const getRGErrorMessage = createSelector(getBetslip, betslip => betslip.rgErrorMessage);

export const getSelectedBetsForWhatIf = createSelector(getBetslip, betslip => betslip.selectedBets);
export const getBetslipErrorMessage = createSelector(getBetslip, betslip => betslip.errorMessage);
