import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBrandingCSSLoaded } from 'redux/modules/appConfigs';
import { getLanguage, getVersion } from 'redux/modules/appConfigs/selectors';

const CssInjection = () => {
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);
  const version = useSelector(getVersion);

  useEffect(() => {
    dispatch(setBrandingCSSLoaded(false));
  }, [language]);

  return version && language ? (
    <link
      rel="stylesheet"
      key={`${version}_${language}`}
      href={`${
        window.environmentConfig?.baseUrl || process.env.REACT_APP_BASE_URL
      }/customer/api/css/branding.min.css?version=${version}&language=${language}`}
      onLoad={() => dispatch(setBrandingCSSLoaded(true))}
    />
  ) : null;
};

export default CssInjection;
