import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import AsianSearch from 'components/Header/components/AsianSearch';
import HeaderAccountNavigation from 'components/HeaderAccountNavigation';
import {
  ACCOUNT_BASE_URL,
  ASIAN_BASE_URL,
  CASH_OUT_BASE_URL,
  CUSTOM_PAGES_BASE_URL,
  GAMES_BASE_URL,
  IN_PLAY_BASE_URL
} from 'constants/locations';
import { getDesktopHeaderNavigation } from 'redux/modules/appConfigs/selectors';
import { NavigationType } from 'redux/modules/appConfigs/type';
import { getSearchExpanded } from 'redux/modules/asianViewSearch/selectors';
import {
  getHeaderAsianSearchWidth,
  getHeaderMoreBtnWidth,
  getLeftMenuItemsWidth,
  getRightMenuWidth
} from 'redux/modules/headerNavigation/selectors';

import DesktopNavigationGames from '../DesktopNavigationGames';
import DesktopNavigationItem from '../DesktopNavigationItem';

import ShowMoreDesktopNavItemsBtn from './components/ShowMoreDesktopNavItemsBtn';

import styles from './styles.module.scss';

const DesktopNavigation = () => {
  const { state, pathname } = useLocation();

  const expanded = useSelector(getSearchExpanded);
  const rightMenuWidth = useSelector(getRightMenuWidth);
  const leftMenuItemsWidth = useSelector(getLeftMenuItemsWidth);
  const headerNavigation = useSelector(getDesktopHeaderNavigation);
  const asianSearchWidth = useSelector(getHeaderAsianSearchWidth);
  const moreBtnWidth = useSelector(getHeaderMoreBtnWidth);

  const isAsianPage = pathname.includes(ASIAN_BASE_URL);
  const isCustomPage = pathname.includes(CUSTOM_PAGES_BASE_URL);
  const isReferredFromAsianPage = !!state?.referer?.includes(ASIAN_BASE_URL) || isAsianPage;
  const isGamesPage = pathname.includes(GAMES_BASE_URL);
  const isAccountPage = pathname.includes(ACCOUNT_BASE_URL);

  const { leftHeaderNavigationItems, hiddenOrderNumbers } = useMemo(() => {
    const hiddenOrderNumbersVal: number[] = [];
    const hideCashOutAndInPlay = isCustomPage ? isReferredFromAsianPage : isAsianPage;
    const leftItems = headerNavigation.filter(({ placeOnTheRight }) => !placeOnTheRight);

    const leftHeaderNavigationItemsVal = hideCashOutAndInPlay
      ? leftItems.filter(({ url, orderNumber }) => {
          if (url === CASH_OUT_BASE_URL || url === IN_PLAY_BASE_URL) {
            hiddenOrderNumbersVal.push(orderNumber);

            return false;
          }

          return true;
        })
      : leftItems;

    return { leftHeaderNavigationItems: leftHeaderNavigationItemsVal, hiddenOrderNumbers: hiddenOrderNumbersVal };
  }, [isAsianPage, headerNavigation, isCustomPage, isReferredFromAsianPage]);

  const [showMore, setShowMore] = useState(false);
  const [navbarItems, setNavbarItems] = useState<NavigationType[]>(leftHeaderNavigationItems);

  useEffect(() => {
    const configNavbar = () => {
      let leftItemsWidthSum: number;
      let leftMenuItemsOrderNumbersAndWidths = Object.entries(leftMenuItemsWidth);

      if (hiddenOrderNumbers.length) {
        leftMenuItemsOrderNumbersAndWidths = leftMenuItemsOrderNumbersAndWidths.filter(
          ([orderNumber]) => !hiddenOrderNumbers.includes(Number(orderNumber))
        );
        leftItemsWidthSum = leftMenuItemsOrderNumbersAndWidths.reduce((acc, [, width]) => acc + width, 0);
      } else {
        leftItemsWidthSum = Object.values(leftMenuItemsWidth).reduce((acc, width) => acc + width, 0);
      }

      const availableWidthForLeftBar = window.innerWidth - rightMenuWidth - asianSearchWidth - moreBtnWidth;
      const elementsThatShouldBeInNavbar: string[] = [];

      if (availableWidthForLeftBar < leftItemsWidthSum) {
        let tmpWidth = 0;

        leftMenuItemsOrderNumbersAndWidths.forEach(([orderNumber, width]) => {
          tmpWidth += width;

          if (tmpWidth < availableWidthForLeftBar) {
            elementsThatShouldBeInNavbar.push(orderNumber);
          }
        });

        const items = leftHeaderNavigationItems.filter(hn =>
          elementsThatShouldBeInNavbar.includes(hn.orderNumber.toString())
        );

        setNavbarItems(items);
        setShowMore(true);
      } else {
        setShowMore(false);
        setNavbarItems(leftHeaderNavigationItems);
      }
    };

    configNavbar();

    window.addEventListener('resize', configNavbar);

    return () => {
      window.removeEventListener('resize', configNavbar);
    };
  }, [
    rightMenuWidth,
    leftMenuItemsWidth,
    leftHeaderNavigationItems,
    asianSearchWidth,
    moreBtnWidth,
    hiddenOrderNumbers
  ]);

  if (isGamesPage && !isAccountPage) {
    return <DesktopNavigationGames />;
  }

  return (
    <div className={classNames(styles.container)}>
      <nav
        className={classNames('biab_navigation-links', styles.headerNavigationLinks, {
          relative: expanded
        })}
      >
        {!isAccountPage && isAsianPage && !isCustomPage && <AsianSearch observeWidth />}
        {navbarItems.map(navigationType => {
          return (
            <DesktopNavigationItem
              key={navigationType.orderNumber}
              navigationType={navigationType}
              pathname={pathname}
            />
          );
        })}
        {showMore && (
          <ShowMoreDesktopNavItemsBtn
            navbarItems={navbarItems.map(n => n.orderNumber)}
            headerNavigation={leftHeaderNavigationItems}
          />
        )}
      </nav>
      <HeaderAccountNavigation />
    </div>
  );
};

export default DesktopNavigation;
