import { Dispatch, SetStateAction, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewBetSlipSettings from 'components/AsianViewBetslip/components/AsianViewBetSlipSettings';
import ToggleSwitch from 'components/ContextualHelpSection/components/ToggleSwitch';
import { ASIAN_VIEW_PAGE_PARAM, AsianViewMarketLink } from 'constants/asianView';
import { asianViewComponents, asianViewMiddleSection, asianViewPopUps } from 'constants/branding';
import { oddsTypeSettingsName } from 'constants/oddsTypes';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import { getAsianViewLayBettingEnabled, getIsMobileInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import { setOddsType } from 'redux/modules/appSettings';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setModalOpen } from 'redux/modules/couponSettings';
import { setSettings } from 'redux/modules/user';
import {
  getAccountSettingsConfirmBetsBeforePlacement,
  getIsUserAccountSettings,
  getUserAccountSettingsDefaultStake,
  getUserAccountSettingsDefaultStakes,
  getUserAsianViewAutoRefresh,
  getUserAsianViewOddsType
} from 'redux/modules/user/selectors';
import { AsianViewBetSlipCheckboxes, Stake } from 'redux/modules/user/type';
import { CookieNames, OddsType } from 'types';
import { ESortingTypes } from 'types/asianView';
import { convertOddsTypeToDropdown, getBooleanValue } from 'utils';
import { convertSortingFilterToDropdown } from 'utils/asianView';

import LayBettingRulesModal from './partials/LayBettingRulesModal';
import ListItem from './partials/ListItem';

import styles from './styles.module.scss';

type CouponSettingsContentProps = {
  isLayBettingRulesModalOpen: boolean;
  setIsLayBettingRulesModalOpen: Dispatch<SetStateAction<boolean>>;
};

const CouponSettingsContent = ({
  isLayBettingRulesModalOpen,
  setIsLayBettingRulesModalOpen
}: CouponSettingsContentProps) => {
  const { marketLink = '' } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [cookies, setCookie] = useCookies([
    CookieNames.ASIAN_VIEW_SORTING_FILTER,
    CookieNames.ODDS_TYPE,
    CookieNames.ASIAN_VIEW_LAY_BETTING
  ]);
  const [searchParams, setSearchParams] = useSearchParams();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const asianViewOddsType = useSelector(getUserAsianViewOddsType);
  const isLayBettingEnabled = useSelector(getAsianViewLayBettingEnabled);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const asianViewAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const confirmBetsBeforePlacement = useSelector(getAccountSettingsConfirmBetsBeforePlacement);
  const defaultStake = useSelector(getUserAccountSettingsDefaultStake);
  const defaultStakes = useSelector(getUserAccountSettingsDefaultStakes);
  const isInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);

  const isLayBetting = useAsianLayBetting();

  const [layBettingChecked, setLayBettingChecked] = useState(isLayBetting);
  const [selectedSort, setSelectedSort] = useState(cookies.ASIAN_VIEW_SORTING_FILTER || ESortingTypes.NORMAL);
  const [selectedOdds, setSelectedOdds] = useState(asianViewOddsType || cookies.BIAB_ODDS_TYPE || OddsType.DEC);
  const [betSlipCheckboxesValues, setBetSlipCheckboxesValues] = useState<AsianViewBetSlipCheckboxes>({
    asianViewAutoRefresh,
    confirmBetsBeforePlacement,
    defaultStake
  });
  const [couponSettingsDefaultStakes, setCouponSettingsDefaultStakes] = useState<(Stake | null)[]>(
    defaultStakes?.slice(0, 3)
  );

  const isOutRight = marketLink === AsianViewMarketLink.OUTRIGHT;
  const sortingFilterData = convertSortingFilterToDropdown(t);
  const oddsFilterData = convertOddsTypeToDropdown(t);
  const isApplyEnabled =
    layBettingChecked !== isLayBetting ||
    selectedSort !== (cookies.ASIAN_VIEW_SORTING_FILTER || ESortingTypes.NORMAL) ||
    selectedOdds !== (asianViewOddsType || cookies.BIAB_ODDS_TYPE || OddsType.DEC) ||
    betSlipCheckboxesValues.defaultStake !== defaultStake ||
    betSlipCheckboxesValues.confirmBetsBeforePlacement !== confirmBetsBeforePlacement ||
    betSlipCheckboxesValues.asianViewAutoRefresh !== asianViewAutoRefresh ||
    couponSettingsDefaultStakes?.[0]?.value !== defaultStakes?.[0]?.value ||
    couponSettingsDefaultStakes?.[0]?.defaultValue !== defaultStakes?.[0]?.defaultValue ||
    couponSettingsDefaultStakes?.[1]?.value !== defaultStakes?.[1]?.value ||
    couponSettingsDefaultStakes?.[1]?.defaultValue !== defaultStakes?.[1]?.defaultValue ||
    couponSettingsDefaultStakes?.[2]?.value !== defaultStakes?.[2]?.value ||
    couponSettingsDefaultStakes?.[2]?.defaultValue !== defaultStakes?.[2]?.defaultValue;

  const applyHandler = () => {
    dispatch(setOddsType(selectedOdds));
    if (isAccountSettings) {
      dispatch(
        setSettings({
          [oddsTypeSettingsName]: selectedOdds,
          asianViewLayBetting: layBettingChecked,
          asianViewAutoRefresh: betSlipCheckboxesValues.asianViewAutoRefresh,
          defaultStake: betSlipCheckboxesValues.defaultStake,
          confirmBetsBeforePlacement: betSlipCheckboxesValues.confirmBetsBeforePlacement,
          defaultStakes: couponSettingsDefaultStakes.concat(defaultStakes.slice(3))
        })
      );
    } else {
      setCookie(CookieNames.ODDS_TYPE, selectedOdds, {
        path: '/'
      });
    }
    setCookie(CookieNames.ASIAN_VIEW_SORTING_FILTER, selectedSort, {
      path: '/'
    });
    setCookie(CookieNames.ASIAN_VIEW_LAY_BETTING, layBettingChecked, {
      path: '/'
    });
    dispatch(setModalOpen(false));

    if (!isInfiniteScrollEnabled && selectedSort !== (cookies.ASIAN_VIEW_SORTING_FILTER || ESortingTypes.NORMAL)) {
      const page = searchParams.get(ASIAN_VIEW_PAGE_PARAM);

      if (page !== '0') {
        searchParams.set(ASIAN_VIEW_PAGE_PARAM, '0');
        setSearchParams(searchParams);
      }
    }
  };

  return (
    <>
      <div className={styles.content}>
        <p className={classNames(styles.header, asianViewPopUps.POP_UP_HEADER)}>{t('sidebar.nav.settings')}</p>
        <div className={classNames(styles.body, asianViewPopUps.POP_UP_BODY)}>
          {!isOutRight && (
            <section className={styles.section}>
              <h6 className={styles.title}>{t('asianView.labels.couponSettings.sorting')}</h6>
              {sortingFilterData.map(sld => (
                <ListItem
                  key={sld.id}
                  value={sld.value}
                  label={sld.label}
                  icon={sld.icon}
                  selected={selectedSort === sld.value}
                  onSelect={setSelectedSort}
                />
              ))}
            </section>
          )}

          <section className={styles.section}>
            <h6 className={styles.title}>{t('asianView.labels.couponSettings.odds')}</h6>
            {oddsFilterData.map(oddFD => (
              <ListItem
                key={oddFD.id}
                value={oddFD.value}
                label={oddFD.label || ''}
                selected={selectedOdds === oddFD.value}
                onSelect={setSelectedOdds}
              />
            ))}
          </section>
          {isLayBettingEnabled && (
            <section className={classNames(styles.layBetting, asianViewMiddleSection.LAY_BETTING_SETTING)}>
              <div>
                <h6 className={styles.title}>
                  {t('asianView.labels.layBetting')}
                  <i className="av-icon av-icon-info" onClick={() => setIsLayBettingRulesModalOpen(true)}>
                    <span className="path1" />
                    <span className="path2" />
                  </i>
                </h6>
                <p className={styles.info}>{t('asianView.labels.layBetting.mobileContent')}</p>
              </div>
              <ToggleSwitch
                containerClassName={classNames(styles.switch, {
                  [styles.checked]: layBettingChecked
                })}
                checked={layBettingChecked}
                onChange={() => setLayBettingChecked(!layBettingChecked)}
              />
            </section>
          )}
          {isLoggedIn && (
            <section>
              <p className={classNames(styles.title, styles.betSlipTitle)}>
                {t('asianView.labels.betslip.tabs.betSlip')}
              </p>
              <AsianViewBetSlipSettings
                isCouponSettings
                betSlipCheckboxesValues={betSlipCheckboxesValues}
                setBetSlipCheckboxesValues={setBetSlipCheckboxesValues}
                couponSettingsDefaultStakes={couponSettingsDefaultStakes}
                setCouponSettingsDefaultStakes={setCouponSettingsDefaultStakes}
              />
            </section>
          )}
        </div>
        <div className={classNames(styles.footer, asianViewPopUps.POP_UP_FOOTER)}>
          <button
            type="button"
            className={classNames(styles.button, styles.button__cancel, asianViewComponents.SECONDARY_BTN)}
            onClick={() => dispatch(setModalOpen(false))}
          >
            {t('asianView.labels.leaguesFilter.cancel')}
          </button>
          <button
            type="button"
            className={classNames(styles.button, styles.button__apply, asianViewComponents.PRIMARY_BTN, {
              [styles.button__disabled]: !isApplyEnabled
            })}
            disabled={!isApplyEnabled}
            onClick={applyHandler}
          >
            {t('asianView.labels.leaguesFilter.apply')}
          </button>
        </div>
      </div>
      <LayBettingRulesModal
        isOpenModal={isLayBettingRulesModalOpen}
        onClose={() => setIsLayBettingRulesModalOpen(false)}
      />
    </>
  );
};

export default CouponSettingsContent;
