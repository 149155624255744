import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME } from 'constants/asianView';
import { TFailureActionPayload } from 'types';

import { TAuth, TLoginPayload } from './type';

const initialState: TAuth = {
  isLoggedIn: false,
  isVerified: false,
  error: null,
  loading: true,
  logoutRedirectUrl: null
};

const slice = createSlice({
  name: SLICES_NAMES.AUTH,
  initialState,
  reducers: {
    loginUser: state => {
      state.loading = true;
    },
    successLoginUser: state => {
      state.isLoggedIn = true;
      state.error = null;
      state.loading = false;
      state.logoutRedirectUrl = null;
    },
    failureLoginUser: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    verifyUser: (state, _: PayloadAction<TLoginPayload>) => {
      state.loading = true;
    },
    successVerifyUser: state => {
      state.isVerified = true;
      state.loading = false;
    },
    failureVerifyUser: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    authenticateUser: (state, _: PayloadAction<TLoginPayload>) => {
      state.loading = true;
    },
    successAuthenticateUser: state => {
      state.loading = false;
    },
    setAuthenticatedUser: (state, _: PayloadAction<boolean>) => {
      state.isVerified = false;
    },
    failureAuthenticateUser: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    logoutUser: (state, _: PayloadAction<string>) => {
      localStorage.removeItem(ASIAN_VIEW_SELECTED_BETS_STORAGE_NAME);
      state.loading = true;
    },
    successLogoutUser: state => {
      state.isLoggedIn = false;
      state.isVerified = false;
      state.error = null;
      state.loading = false;
    },
    failureLogoutUser: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAfterLogOutRedirect: (state, action: PayloadAction<string | null>) => {
      state.logoutRedirectUrl = action.payload;
    },
    proceedLogout: (_, __: PayloadAction<string>) => {}
  }
});

export const {
  setAfterLogOutRedirect,
  setIsLoggedIn,
  setLoading,
  successAuthenticateUser,
  successLoginUser,
  successLogoutUser,
  successVerifyUser,
  failureAuthenticateUser,
  failureLoginUser,
  failureLogoutUser,
  failureVerifyUser,
  loginUser,
  logoutUser,
  setAuthenticatedUser,
  verifyUser,
  authenticateUser,
  proceedLogout
} = slice.actions;

export default slice.reducer;
