import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GameMarketTable from 'components/GameMarketTable';
import GameSection from 'components/GameSection';
import Loader, { CircleColors } from 'components/Loader';
import BackendContentPage from 'pages/BackendContentPage';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchGames } from 'redux/modules/games';
import { getCurrentGameData, getGameList } from 'redux/modules/games/selectors';

const GameScreen = () => {
  const dispatch = useDispatch();

  const gameList = useSelector(getGameList);
  const currentGameData = useSelector(getCurrentGameData);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const isGameAvailable = (gameList && gameList.find(game => game.channelId === currentGameData?.channelId)) || true;

  useEffect(() => {
    dispatch(fetchGames());
  }, [dispatch, isLoggedIn]);

  if (!currentGameData) {
    return <Loader circleColor={CircleColors.BLACK} />;
  }

  if (!isGameAvailable) {
    return <BackendContentPage />;
  }

  return (
    <>
      <GameSection />
      {currentGameData?.markets && <GameMarketTable />}
    </>
  );
};

export default GameScreen;
