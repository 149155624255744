import { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import {
  getAppDevice,
  getDesktopSettingsQuickstakeBetslip,
  getMobileSettingsQuickstakeBetslip
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { isSportPage } from 'utils';

import QuickBet from './components/QuickBet';

import styles from './styles.module.scss';

interface QuickBetsProps {
  handler: (value: string | number) => void;
  byCenter?: boolean;
  isShort?: boolean;
}

const QuickBets = ({ handler, byCenter, isShort }: QuickBetsProps) => {
  const location = useLocation();

  const accountSettings = useSelector(getAccountSettings);
  const device = useSelector(getAppDevice);
  const quickStakeBetslipMobile = useSelector(getMobileSettingsQuickstakeBetslip);
  const quickStakeBetslipDesktop = useSelector(getDesktopSettingsQuickstakeBetslip);

  const isMobile = device === Devices.MOBILE;
  const isTurnOnQuickstakeBetslip = isMobile ? quickStakeBetslipMobile : quickStakeBetslipDesktop;
  const stakes = isSportPage(location.pathname) ? accountSettings?.gameStakes : accountSettings?.stakes;

  if (!isTurnOnQuickstakeBetslip || !stakes?.length) {
    return null;
  }

  return (
    <div
      id="quickBets"
      className={classNames(styles.container, {
        [styles.container__byCenter]: byCenter
      })}
    >
      {stakes
        .filter(stake => stake)
        .slice(0, isShort ? 4 : undefined)
        .map((stake, index) => (
          <QuickBet key={`${index}-${stake}`} value={stake} byCenter={byCenter} handler={handler} />
        ))}
    </div>
  );
};

export default memo(QuickBets);
