import { isNumber, toNumber } from 'lodash';

import { DEFAULT_EACH_WAY_DIVISOR, LINE_MARKET_ODDS } from 'constants/placement';
import { BetTypes, TPrice, TProfit, TSize } from 'types/bets';
import { getEachWayProfit } from 'utils/totalLiability';

import { isEachWayMarketType, isLineBettingType } from './betslip';

export const roundFloat = (value: number | string, precision = 2) => {
  return round(value, precision).toFixed(precision);
};

export const round = (value: number | string, precision = 2) => {
  const roundNumber = parseFloat(toNumber(value).toFixed(10));

  return Math.round(roundNumber * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const calculateLiability = (
  price: TPrice,
  size: TSize,
  options?: {
    /** Market types from market description */
    marketType?: string;
    /** Betting type from market description */
    bettingType?: string;
    /** Each way divider from market description */
    eachWayDivisor?: number | null;
    /** BACK or LAY type */
    betType?: BetTypes;
  }
) => {
  if (price && size) {
    const { marketType = '', bettingType = '', eachWayDivisor, betType = BetTypes.BACK } = options ?? {};
    const isEachWay = isEachWayMarketType(marketType);
    const isLineMarket = isLineBettingType(bettingType);
    const priceVal = isLineMarket ? LINE_MARKET_ODDS : toNumber(price);
    const sizeVal = toNumber(size);

    if (isEachWay) {
      return roundFloat(
        getEachWayProfit(sizeVal, priceVal, toNumber(eachWayDivisor || DEFAULT_EACH_WAY_DIVISOR), betType).win
      );
    }

    let liabilityValue = sizeVal * priceVal - sizeVal;
    liabilityValue = liabilityValue < 0 || isNaN(liabilityValue) ? 0 : liabilityValue;

    return roundFloat(liabilityValue);
  }

  return '';
};

export const calculateSize = (price: TPrice, profit: TProfit) => {
  if (price && profit) {
    const priceVal = toNumber(price);
    const profitVal = toNumber(profit);

    let sizeValue: number | string = profitVal / (priceVal - 1);
    sizeValue = sizeValue < 0 || isNaN(sizeValue) ? '' : sizeValue;

    return sizeValue && isNumber(sizeValue) ? roundFloat(sizeValue) : '';
  }

  return '';
};
