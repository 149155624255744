import { EBetslipFilters } from 'redux/modules/betslip/type';

export const tabulation = {
  // event tab keyCode
  TAB_KEY_CODE: 9,
  PRICE_ORDER: 1,
  SIZE_ORDER: 2,
  PROFIT_ORDER: 3,
  /* Tabulate through ODDS (if not PNC), SIZE, PROFIT on each bet */
  BET_TABS: 3,
  FULL_BET_TABS: 4,
  OPENED_BET_TABS: 6,
  // Place bets buttons
  CANCEL_ALL_BTN_ORDER: 2,
  EDIT_BTN_ORDER: 1,
  CONFIRM_CHECKBOX_ORDER: 3,
  PLACE_BTN_ORDER: 1,
  CONFIRM_BTN_ORDER: 3,
  // open bets buttons
  CANCEL_OPENED_BET_ORDER: 4,
  TAKE_OPENED_BET_ORDER: 5,
  UPDATE_OPENED_BET_ORDER: 6
};

export const KEY_CODES = {
  TAB: 'Tab',
  ENTER: 'Enter',
  ESCAPE: 'Escape'
};

export const EXCHANGE_BETSLIP_FILTERS = [EBetslipFilters.MARKET, EBetslipFilters.MATCHED, EBetslipFilters.SORTING];
export const GAMES_BETSLIP_FILTERS = [EBetslipFilters.MARKET];

export const cancelParam = 'cancel';

export const ASIAN_VIEW_PLACE_BET_ERRORS_IDS = {
  EX001: 'EX001',
  EX002: 'EX002',
  EX003: 'EX003',
  EX004: 'EX004',
  EX007: 'EX007',
  EX009: 'EX009',
  EX010: 'EX010',
  EX011: 'EX011',
  EX013: 'EX013',
  EX014: 'EX014',
  EX016: 'EX016',
  EX020: 'EX020',
  EX026: 'EX026'
} as const;

export type ErrorIdType = typeof ASIAN_VIEW_PLACE_BET_ERRORS_IDS[keyof typeof ASIAN_VIEW_PLACE_BET_ERRORS_IDS];

export const ASIAN_VIEW_PLACE_BET_ERRORS_WITH_INTERVAL: ErrorIdType[] = [
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX001,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX002,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX003,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX004,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX007,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX009,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX010,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX011,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX020,
  ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX026
];

export const ASIAN_VIEW_BETSLIP_VALIDATION_MESSAGE_TIMEOUT = 10000;
