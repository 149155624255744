import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';

import FavoritesMobile from 'components/FavoritesMobile';
import AppLayout from 'components/layouts/AppLayout';
import AsianViewLayout from 'components/layouts/AsianViewLayout';
import { AsianViewMarketLink, AsianViewTimeFilter } from 'constants/asianView';
import {
  ACCOUNT_BASE_URL,
  ACCOUNT_RESPONSIBLE_BETTING_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  ASIAN_BASE_URL,
  ASIAN_MOBILE_BET_LIST_URL,
  ASIAN_MOBILE_FAVOURITES_URL,
  ASIAN_VIEW_CASH_OUT_URL,
  ASIAN_VIEW_MORE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  CASH_OUT_BASE_URL,
  CUSTOM_PAGES_BASE_URL,
  GAMES_BASE_URL,
  HOME_BASE_URL,
  IN_PLAY_BASE_URL,
  MATCH_STATISTIC_SOON,
  MOBILE_ACCOUNT_SETTINGS_BASE_URL,
  MOBILE_FAVOURITES_BASE_URL,
  MOBILE_LOGIN_BASE_URL,
  MY_BETS_BASE_URL,
  SEARCH_BASE_URL,
  SPORT_BASE_URL
} from 'constants/locations';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useDevice from 'hooks/useDevice';
import BackendContentPage from 'pages/BackendContentPage';
import CashOutPages from 'pages/CashOutPages';
import CompetitionSportPage from 'pages/CompetitionSportPage';
import CountrySportPage from 'pages/CountrySportPage';
import EventPage from 'pages/EventPage';
import FancyMarketPage from 'pages/FancyMarketPage';
import GamePage from 'pages/GamePage';
import GamesPage from 'pages/GamesPage';
import GroupPage from 'pages/GroupPage';
import HomePage from 'pages/Home';
import InPlayPage from 'pages/InPlay';
import MarketPage from 'pages/MarketPage';
import MatchStatisticSoon from 'pages/MatchStatisticSoon';
import AccountPage from 'pages/mobile/Account';
import AsianViewBetListMobilePage from 'pages/mobile/asianView/AsianViewBetListMobilePage/AsianViewBetListMobilePage';
import AsianViewFavouritesMobilePage from 'pages/mobile/asianView/AsianViewFavouritesMobilePage';
import AsianViewMobileMorePage from 'pages/mobile/asianView/AsianViewMobileMorePage';
import LoginPage from 'pages/mobile/Login';
import MobileSearchPage from 'pages/mobile/MobileSearchPage/MobileSearchPage';
import SettingsPage from 'pages/mobile/Settings';
import MyBetsPages from 'pages/MyBetsPages/MyBetsPages';
import ResponsibleBetting from 'pages/ResponsibleBetting';
import SportPage from 'pages/SportPage';
import {
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { SportId } from 'types';

import AsianViewPageModule from '../AsianViewPageModule';
import AsianViewNotFoundView from '../AsianViewPageModule/components/AsianViewNotFoundView';
import CashOutSection from '../AsianViewPageModule/components/CashOutSection';
import SportsSection from '../SportsSection';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

interface IWrapper {
  children: JSX.Element;
  asianLayoutProps?: {
    withoutNavigation?: boolean;
    withoutMobileHeader?: boolean;
  };
  appLayoutProps?: {
    hideSideBars?: boolean;
    hideSearch?: boolean;
    noNavigation?: boolean;
  };
}

const useWrapper = ({ children, asianLayoutProps = {}, appLayoutProps = {} }: IWrapper) => {
  const { pathname } = useLocation();
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);

  const isAsianViewPage = pathname.includes(ASIAN_BASE_URL);
  const isAccountViewPage = pathname.includes(ACCOUNT_BASE_URL);

  if (isAsianViewPage && isAsianViewEnabled && !isAccountViewPage) {
    return <AsianViewLayout {...asianLayoutProps}>{children}</AsianViewLayout>;
  }
  return <AppLayout {...appLayoutProps}>{children}</AppLayout>;
};

const BrowserRoutes = () => {
  const [searchParams] = useSearchParams();

  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);

  const { isDesktop, isAsianViewPage } = useDevice();

  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const isAsianSearchView = isAsianViewEnabled && isAsianViewPage && isDesktop && query.length >= SEARCH_QUERY_LENGTH;

  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route
          path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/:betType?/:page?`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/:betType?/:page?`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/:betType/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={`${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/:page`}
          element={useWrapper({
            children: <MyBetsPages />,
            asianLayoutProps: { withoutNavigation: true },
            appLayoutProps: { noNavigation: true }
          })}
        />
        <Route
          path={MOBILE_FAVOURITES_BASE_URL}
          element={useWrapper({ children: <FavoritesMobile />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={`${ASIAN_BASE_URL}${MOBILE_ACCOUNT_SETTINGS_BASE_URL}`}
          element={useWrapper({ children: <SettingsPage />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={`${GAMES_BASE_URL}${MOBILE_ACCOUNT_SETTINGS_BASE_URL}`}
          element={useWrapper({ children: <SettingsPage />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={MOBILE_ACCOUNT_SETTINGS_BASE_URL}
          element={useWrapper({ children: <SettingsPage />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={`${ASIAN_BASE_URL}${ACCOUNT_RESPONSIBLE_BETTING_URL}`}
          element={useWrapper({
            children: <ResponsibleBetting />,
            appLayoutProps: { hideSearch: true, hideSideBars: true, noNavigation: true }
          })}
        />
        <Route
          path={`${GAMES_BASE_URL}${ACCOUNT_RESPONSIBLE_BETTING_URL}`}
          element={useWrapper({
            children: <ResponsibleBetting />,
            appLayoutProps: { hideSearch: true, hideSideBars: true, noNavigation: true }
          })}
        />
        <Route
          path={ACCOUNT_RESPONSIBLE_BETTING_URL}
          element={useWrapper({
            children: <ResponsibleBetting />,
            appLayoutProps: { hideSearch: true, hideSideBars: true, noNavigation: true }
          })}
        />
        <Route
          path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}`}
          element={useWrapper({ children: <AccountPage />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}`}
          element={useWrapper({ children: <AccountPage />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={ACCOUNT_BASE_URL}
          element={useWrapper({ children: <AccountPage />, appLayoutProps: { hideSearch: true } })}
        />
        <Route
          path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`}
          element={useWrapper({ children: <MyBetsPages /> })}
        />
        <Route
          path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`}
          element={useWrapper({ children: <MyBetsPages /> })}
        />
        <Route path={`${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`} element={useWrapper({ children: <MyBetsPages /> })} />
      </Route>

      <Route
        element={
          <PublicRoute
            redirectPath={`${ASIAN_BASE_URL}/sport/${SportId.SOCCER}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${AsianViewMarketLink.HDP_AND_OU}`}
          />
        }
      >
        <Route
          path={`${ASIAN_BASE_URL}${MOBILE_LOGIN_BASE_URL}`}
          element={
            <AppLayout hideSearch>
              <LoginPage />
            </AppLayout>
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path={`${GAMES_BASE_URL}${MOBILE_LOGIN_BASE_URL}`}
          element={
            <AppLayout hideSearch>
              <LoginPage />
            </AppLayout>
          }
        />
      </Route>
      <Route
        path={`${ASIAN_BASE_URL}${CUSTOM_PAGES_BASE_URL}/*`}
        element={useWrapper({
          children: <BackendContentPage />,
          appLayoutProps: { hideSideBars: true, hideSearch: true },
          asianLayoutProps: { withoutNavigation: true, withoutMobileHeader: true }
        })}
      />
      {isAsianViewEnabled && (
        <>
          <Route
            path={ASIAN_BASE_URL}
            element={
              <AsianViewLayout>
                <AsianViewPageModule />
              </AsianViewLayout>
            }
          />
          <Route
            path={`${ASIAN_BASE_URL}/sport/:sportId/timeFilter/:timeFilter/marketLink/:marketLink/timeFrame/:timeFrame`}
            element={
              <AsianViewLayout>
                <AsianViewPageModule />
              </AsianViewLayout>
            }
          />
          <Route
            path={`${ASIAN_BASE_URL}/sport/:sportId/timeFilter/:timeFilter/marketLink/:marketLink`}
            element={
              <AsianViewLayout>
                <AsianViewPageModule />
              </AsianViewLayout>
            }
          />
          <Route
            path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`}
            element={
              <AppLayout noNavigation>
                <MyBetsPages />
              </AppLayout>
            }
          />
          <Route
            path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/:betType/:page`}
            element={
              <AppLayout noNavigation>
                <MyBetsPages />
              </AppLayout>
            }
          />
          <Route element={<PrivateRoute />}>
            <Route
              path={ASIAN_MOBILE_FAVOURITES_URL}
              element={
                <AsianViewLayout withoutMobileHeader>
                  <AsianViewFavouritesMobilePage />
                </AsianViewLayout>
              }
            />
            <Route
              path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/:page`}
              element={
                <AppLayout noNavigation>
                  <MyBetsPages />
                </AppLayout>
              }
            />
            <Route
              path={`${ASIAN_BASE_URL}${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/:page`}
              element={
                <AppLayout noNavigation>
                  <MyBetsPages />
                </AppLayout>
              }
            />
          </Route>
          <Route element={<PrivateRoute page={PagesFromBackend.UNAUTHORIZED} />}>
            <Route
              path={ASIAN_MOBILE_BET_LIST_URL}
              element={
                <AsianViewLayout withoutMobileHeader withoutFooter>
                  <AsianViewBetListMobilePage />
                </AsianViewLayout>
              }
            />
          </Route>
          <Route
            path={`${ASIAN_VIEW_CASH_OUT_URL}/:timeFilter?`}
            element={
              <AsianViewLayout isCashOutPage withoutMobileHeader>
                {isAsianSearchView ? <AsianViewPageModule noRedirect /> : <CashOutSection />}
              </AsianViewLayout>
            }
          />
          <Route
            path={ASIAN_VIEW_MORE_URL}
            element={
              <AsianViewLayout withoutMobileHeader withoutFooter>
                <AsianViewMobileMorePage />
              </AsianViewLayout>
            }
          />
          <Route
            path="/asian-view/*"
            element={
              <AsianViewLayout isNotFoundPage>
                <AsianViewNotFoundView />
              </AsianViewLayout>
            }
          />
        </>
      )}
      <Route
        path={GAMES_BASE_URL}
        element={
          <AppLayout hideSideBars hideSearch>
            <GamesPage />
          </AppLayout>
        }
      />
      <Route
        path={`${GAMES_BASE_URL}/:gameId`}
        element={
          <AppLayout hideNavigation hideSearch>
            <GamePage />
          </AppLayout>
        }
      />
      {isExchangeGamesEnabled && (
        <>
          <Route
            path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`}
            element={
              <AppLayout noNavigation>
                <MyBetsPages />
              </AppLayout>
            }
          />

          <Route
            path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/:betType/:page`}
            element={
              <AppLayout noNavigation>
                <MyBetsPages />
              </AppLayout>
            }
          />
          <Route element={<PrivateRoute />}>
            <Route
              path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/:page`}
              element={
                <AppLayout noNavigation>
                  <MyBetsPages />
                </AppLayout>
              }
            />
            <Route
              path={`${GAMES_BASE_URL}${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/:page`}
              element={
                <AppLayout noNavigation>
                  <MyBetsPages />
                </AppLayout>
              }
            />
          </Route>
        </>
      )}
      {isExchangeSportsEnabled && (
        <>
          <Route
            path={HOME_BASE_URL}
            element={
              <AppLayout showBanners>
                <HomePage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId`}
            element={
              <AppLayout showBanners>
                <SportPage />
              </AppLayout>
            }
          />
          <Route
            path={SPORT_BASE_URL}
            element={
              <AppLayout hideSearch>
                <SportsSection />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/competition/:competitionId`}
            element={
              <AppLayout showBanners>
                <CompetitionSportPage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/competition/:competitionId/:timeFilter`}
            element={
              <AppLayout>
                <CompetitionSportPage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/country/:countryId`}
            element={
              <AppLayout showBanners>
                <CountrySportPage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/event/:eventId`}
            element={
              <AppLayout showBanners>
                <EventPage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/market/:marketId`}
            element={
              <AppLayout showBanners>
                <MarketPage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/group/:groupId`}
            element={
              <AppLayout showBanners>
                <GroupPage />
              </AppLayout>
            }
          />
          <Route
            path={`${SPORT_BASE_URL}/:sportId/fancyEvent/:eventId/:fancyId`}
            element={
              <AppLayout>
                <FancyMarketPage />
              </AppLayout>
            }
          />
          <Route
            path={IN_PLAY_BASE_URL}
            element={
              <AppLayout showBanners>
                <InPlayPage />
              </AppLayout>
            }
          />
          <Route
            path={`${IN_PLAY_BASE_URL}/:filter`}
            element={
              <AppLayout showBanners>
                <InPlayPage />
              </AppLayout>
            }
          />
          <Route
            path={SEARCH_BASE_URL}
            element={
              <AppLayout>
                <MobileSearchPage />
              </AppLayout>
            }
          />

          <Route element={<PrivateRoute />}>
            <Route
              path={CASH_OUT_BASE_URL}
              element={
                <AppLayout hideSearch showBanners>
                  <CashOutPages />
                </AppLayout>
              }
            />
            <Route
              path={`${CASH_OUT_BASE_URL}/:page`}
              element={
                <AppLayout hideSearch>
                  <CashOutPages />
                </AppLayout>
              }
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path={ACCOUNT_BASE_URL}
              element={
                <AppLayout hideSearch noNavigation>
                  <AccountPage />
                </AppLayout>
              }
            />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route
              path={MOBILE_FAVOURITES_BASE_URL}
              element={
                <AppLayout hideSearch noNavigation>
                  <FavoritesMobile />
                </AppLayout>
              }
            />
          </Route>

          <Route path={MATCH_STATISTIC_SOON} element={<MatchStatisticSoon />} />
        </>
      )}
      <Route
        path={`${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}`}
        element={
          <AppLayout noNavigation>
            <MyBetsPages />
          </AppLayout>
        }
      />
      <Route element={<PrivateRoute />}>
        <Route
          path={`${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/:betType/:page`}
          element={
            <AppLayout noNavigation>
              <MyBetsPages />
            </AppLayout>
          }
        />
        <Route
          path={`${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/:page`}
          element={
            <AppLayout noNavigation>
              <MyBetsPages />
            </AppLayout>
          }
        />
        <Route
          path={`${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/:page`}
          element={
            <AppLayout noNavigation>
              <MyBetsPages />
            </AppLayout>
          }
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route
          path={MOBILE_LOGIN_BASE_URL}
          element={
            <AppLayout hideSearch>
              <LoginPage />
            </AppLayout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={MOBILE_ACCOUNT_SETTINGS_BASE_URL}
          element={
            <AppLayout hideSearch noNavigation>
              <SettingsPage />
            </AppLayout>
          }
        />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route
          path={ACCOUNT_RESPONSIBLE_BETTING_URL}
          element={
            <AppLayout hideSearch noNavigation hideSideBars>
              <ResponsibleBetting />
            </AppLayout>
          }
        />
      </Route>
      <Route
        path="*"
        element={useWrapper({
          children: <BackendContentPage />,
          appLayoutProps: { hideSideBars: true, hideSearch: true },
          asianLayoutProps: { withoutNavigation: true }
        })}
      />
    </Routes>
  );
};

export default memo(BrowserRoutes);
