export const STARTING_SOON = 'market.startingSoon';
export const STARTING_ONE_MIN = 'market.startingIn1Min';
export const STARTING_IN_MIN = 'market.startingInMin';
export const STARTING_TODAY = 'dates.today';
export const STARTING_TOMORROW = 'dates.tomorrow';
export const STARTING_DAY = 'dates.day.';
export const STARTING_SHORT_DAY = 'dates.shortDay.';
export const STARTING_MONTH = 'dates.month.';
export const STARTING_SHORT_MONTH = 'dates.shortMonth.';

export const PERIOD_TABS = [
  { title: STARTING_TODAY, id: 'TODAY' },
  { title: STARTING_TOMORROW, id: 'TOMORROW' },
  { title: `${STARTING_DAY}${new Date(Date.now() + 2 * 24 * 60 * 60 * 1000).getDay()}`, id: 'AFTER_TOMORROW' }
];

export const ONE_MINUTE_IN_MS = 60 * 1000;
export const ONE_HOUR_IN_MS = 60 * 60 * 1000;
export const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
