import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getCurrentBetsBySelection } from 'redux/modules/currentBets/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { CookieNames, PageBlocks, PlacementPage } from 'types';
import { MatchTypes } from 'types/bets';
import { getTranslationKeyByCommissionType } from 'utils/tooltip';

import MobileOpenBet from './MobileOpenBet';

import styles from './styles.module.scss';

interface MobilePlacementProps {
  /**
   * Is visible
   */
  isVisible: boolean;

  /**
   * Market id
   */
  marketId: string;

  /**
   * Selection Id
   */
  selectionId?: number;

  /**
   * Handicap
   */
  handicap?: number;

  /**
   * Place where component was added.
   */
  pageBlock?: PageBlocks;

  marketPricesCurrency?: string;
  page?: PlacementPage;
}

function MobileOpenBets({
  isVisible,
  marketId,
  selectionId,
  handicap,
  pageBlock = PageBlocks.HOME,
  marketPricesCurrency = '',
  page
}: MobilePlacementProps) {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const unmatchedBets = useSelector(getCurrentBetsBySelection(MatchTypes.UNMATCHED, marketId, selectionId, handicap));
  const matchedBets = useSelector(getCurrentBetsBySelection(MatchTypes.MATCHED, marketId, selectionId, handicap));

  const [closedUnmatchedBets, setClosedUnmatchedBets] = useState<number[]>([]);
  const [closedMatchedBets, setClosedMatchedBets] = useState<number[]>([]);
  const [isUnmatchedCollapsed, setIsUnmatchedCollapsed] = useState<boolean>(unmatchedBets.length === 0);
  const [isMatchedCollapsed, setIsMatchedCollapsed] = useState<boolean>(
    !(unmatchedBets.length === 0 && matchedBets.length > 0)
  );

  const isOpenBetsEnabled = cookies[CookieNames.MOBILE_OPEN_BETS] === 'true';
  const isGame = pageBlock === PageBlocks.GAME;
  const isSportPageBlock = pageBlock === PageBlocks.SPORT;
  const hasPadding = !(isGame || isSportPageBlock);

  const getFilteredBets = (matchType: MatchTypes): TCurrentBet[] => {
    if (matchType == MatchTypes.MATCHED) {
      if (isOpenBetsEnabled) {
        return matchedBets;
      }
      return matchedBets.filter(bet => !closedMatchedBets.includes(bet.offerId));
    } else {
      if (isOpenBetsEnabled) {
        return unmatchedBets;
      }
      return unmatchedBets.filter(bet => !closedUnmatchedBets.includes(bet.offerId));
    }
  };

  const matchedBetsLength = getFilteredBets(MatchTypes.MATCHED).length;
  const unmatchedBetsLength = getFilteredBets(MatchTypes.UNMATCHED).length;
  const hasUnmatchedHeader = (matchedBetsLength > 0 && unmatchedBetsLength > 0) || unmatchedBetsLength > 1;
  const hasMatchedHeader = (matchedBetsLength > 0 && unmatchedBetsLength > 0) || matchedBetsLength > 1;
  const hasVisibleMatchedBets = !isMatchedCollapsed || (matchedBetsLength === 1 && unmatchedBetsLength === 0);
  const hasVisibleUnmatchedBets = !isUnmatchedCollapsed || (unmatchedBetsLength === 1 && matchedBetsLength === 0);

  const toggleUnmatchedBets = () => {
    if (isUnmatchedCollapsed && getFilteredBets(MatchTypes.MATCHED).length) {
      setIsMatchedCollapsed(true);
    }
    setIsUnmatchedCollapsed(!isUnmatchedCollapsed);
  };

  const toggleMatchedBets = () => {
    if (isMatchedCollapsed && getFilteredBets(MatchTypes.UNMATCHED).length) {
      setIsUnmatchedCollapsed(true);
    }
    setIsMatchedCollapsed(!isMatchedCollapsed);
  };

  useEffect(() => {
    setIsUnmatchedCollapsed(unmatchedBetsLength == 0);
    setIsMatchedCollapsed(!(unmatchedBetsLength == 0 && matchedBetsLength > 0));
  }, [matchedBetsLength, unmatchedBetsLength, isOpenBetsEnabled]);

  const onHideBet = (matchType: MatchTypes, offerId: number) => {
    if (matchType == MatchTypes.MATCHED) {
      setClosedMatchedBets([...closedMatchedBets, offerId]);
    } else {
      setClosedUnmatchedBets([...closedUnmatchedBets, offerId]);
    }
  };

  useEffect(() => {
    if (isOpenBetsEnabled) {
      if (closedMatchedBets.length) {
        setClosedMatchedBets([]);
      }
      if (closedUnmatchedBets.length) {
        setClosedUnmatchedBets([]);
      }
    }
  }, [isOpenBetsEnabled]);

  const getTitle = (type: MatchTypes, betsLength: number) => {
    const numberLabel = betsLength === 1 ? '_1' : '',
      isPNC = isPNCEnabled ? 'PNC' : '';

    return (
      betsLength +
      ' ' +
      t(
        getTranslationKeyByCommissionType(
          isPNCEnabled,
          'market.mobileOpenBetsLabels.' + type.toLocaleLowerCase() + 'BetsTab' + isPNC + numberLabel
        )
      )
    );
  };

  return (
    <>
      {isVisible && (
        <div
          className={classNames(
            'biab_mobile-open-bets-wrapper',
            styles.openBets,
            hasPadding && styles.openBets__hasBets
          )}
        >
          <div className={classNames('biab_mobile-open-bets-by-type', styles.openBets__byType)}>
            {hasUnmatchedHeader && (
              <div
                className={classNames('biab_mobile-open-bets-unmatched-header', styles.unmatchedHeader, {
                  biab_opened: !isUnmatchedCollapsed
                })}
                onClick={toggleUnmatchedBets}
              >
                {getTitle(MatchTypes.UNMATCHED, getFilteredBets(MatchTypes.UNMATCHED).length)}{' '}
                <i
                  className={classNames(
                    'fa biab_expand',
                    styles.expand,
                    !isUnmatchedCollapsed && styles.expand__opened
                  )}
                />
              </div>
            )}
            {hasMatchedHeader && (
              <div
                className={classNames('biab_mobile-open-bets-matched-header', styles.matchedHeader, {
                  biab_opened: !isMatchedCollapsed
                })}
                onClick={toggleMatchedBets}
              >
                {getTitle(MatchTypes.MATCHED, getFilteredBets(MatchTypes.MATCHED).length)}{' '}
                <i
                  className={classNames('fa biab_expand', styles.expand, !isMatchedCollapsed && styles.expand__opened)}
                />
              </div>
            )}
          </div>
          {(hasVisibleMatchedBets || hasVisibleUnmatchedBets) && (
            <div className={classNames('biab_mobile-open-bets-list', styles.openBetsList)}>
              {hasVisibleMatchedBets &&
                getFilteredBets(MatchTypes.MATCHED).map((bet, index) => (
                  <MobileOpenBet
                    bet={bet}
                    pageBlock={pageBlock}
                    matchType={MatchTypes.MATCHED}
                    isPNCEnabled={isPNCEnabled}
                    isOpenBetsEnabled={isOpenBetsEnabled}
                    key={`${bet.offerId}-${index}`}
                    currency={marketPricesCurrency}
                    onHide={onHideBet}
                    page={page}
                  />
                ))}
              {hasVisibleUnmatchedBets &&
                getFilteredBets(MatchTypes.UNMATCHED).map((bet, index) => (
                  <MobileOpenBet
                    bet={bet}
                    pageBlock={pageBlock}
                    matchType={MatchTypes.UNMATCHED}
                    isPNCEnabled={isPNCEnabled}
                    isOpenBetsEnabled={isOpenBetsEnabled}
                    key={`${bet.offerId}-${index}`}
                    currency={marketPricesCurrency}
                    onHide={onHideBet}
                    page={page}
                  />
                ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default MobileOpenBets;
