import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { ResponsibleBettingTimeUnits } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingScaleProps {
  currentLimit: number | null;
  currentTimeUnit: CombinedResponsibleBettingTimeUnit | null;
  spentLimit: number | null;
  remainingPercentage: number;
  spentPercentage: number;
  limitPeriod: string;
  currencySymbol: string;
}

const ResponsibleBettingScale = ({
  currentLimit,
  currentTimeUnit,
  spentLimit,
  remainingPercentage,
  spentPercentage,
  limitPeriod,
  currencySymbol
}: ResponsibleBettingScaleProps) => {
  const { t } = useTranslation();

  const { formattedAmount: formattedCurrentLimit } = useFormatCurrency(currentLimit || 0, currencySymbol, {
    isCheckIndian: true,
    noRounding: true,
    ignoreFlexibleDisplayFormat: true
  });
  const { formattedAmount: formattedSpentLimit } = useFormatCurrency(spentLimit || 0, currencySymbol, {
    isCheckIndian: true,
    noRounding: true,
    ignoreFlexibleDisplayFormat: true
  });

  return (
    <div className={styles.container}>
      <div className={styles.limitContainer}>
        <div className={styles.limitItem}>
          <div className={classNames(styles.badge, styles.badge__remaining)} />
          <span className={styles.limit}>{formattedCurrentLimit}</span>
          <span className={styles.limitLabel}>{t('responsibleBetting.labels.available')}</span>
        </div>
        {spentLimit && spentLimit > 0 ? (
          <div className={styles.limitItem}>
            <div className={classNames(styles.badge, styles.badge__spent)} />
            <span className={classNames(styles.limit, styles.limit__spent)}>{formattedSpentLimit}</span>
            <span className={styles.limitLabel}>{t('responsibleBetting.labels.spent')}</span>
          </div>
        ) : null}
      </div>
      <div className={styles.scaleContainer}>
        <div className={styles.remaining} style={{ width: `${remainingPercentage}%` }} />
        <div className={styles.spent} style={{ width: `${spentPercentage}%` }} />
      </div>
      {currentTimeUnit !== ResponsibleBettingTimeUnits.DAY && (
        <div className={styles.periodContainer}>
          <span className={styles.period}>{t('responsibleBetting.labels.currentPeriod')}:</span>
          <span className={styles.period}>{limitPeriod}</span>
        </div>
      )}
    </div>
  );
};

export default ResponsibleBettingScale;
