import { PageBlocks } from 'types';

export const INLINE_PLACEMENT_AVAILABLE = [
  PageBlocks.HOME,
  PageBlocks.IN_PLAY,
  PageBlocks.POPULAR,
  PageBlocks.FEATURE_MARKET,
  PageBlocks.EVENT,
  PageBlocks.SPORT,
  PageBlocks.SEARCH,
  PageBlocks.MULTI_MARKET_VIEW,
  PageBlocks.TOP_5_VIEW,
  PageBlocks.CASH_OUT
];

export const INLINE_PLACEMENT_SMALL_SIZE = 450;
export const INLINE_PLACEMENT_LARGE_SIZE = 650;

export const INLINE_PLACEMENT_SELECTION = [
  PageBlocks.CASH_OUT,
  PageBlocks.EVENT,
  PageBlocks.FEATURE_MARKET,
  PageBlocks.SEARCH,
  PageBlocks.MULTI_MARKET_VIEW,
  PageBlocks.TOP_5_VIEW
];
