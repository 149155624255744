import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { map, unescape } from 'lodash';

import { betslipBranding as branding } from 'constants/branding';
import { PRICE_MARKET_UNITS } from 'constants/placement';
import {
  BACK_ODDS,
  BACK_PROFIT,
  BACK_SELECTED_UNMATCHED,
  BACK_SIZE,
  LAY_PROFIT,
  LAY_SELECTED_UNMATCHED,
  LAY_SIZE
} from 'constants/tooltip';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useBetslipLabels } from 'hooks/useLabels';
import useTooltip from 'hooks/useTooltip';
import { getPNCEnabledSetting, getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { getSelectedBetsAmount, getSelectedBetsForWhatIf } from 'redux/modules/betslip/selectors';
import { PopularEventType, SportId } from 'types';
import { BetTypes } from 'types/bets';
import { TBetslipMarket, TLabels } from 'types/betslip';
import { BettingType } from 'types/markets';
import { parseMillisecondsToTime } from 'utils/date';

import SelectedBet from '../SelectedBet';

import styles from './styles.module.scss';

const SelectedBets = ({
  type,
  market,
  isSubHeaderEnabled
}: {
  type: BetTypes;
  market: TBetslipMarket;
  isSubHeaderEnabled: boolean;
}) => {
  const { t } = useTranslation();

  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const selectedBets = useSelector(getSelectedBetsForWhatIf);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const selectedBetsAmount = useSelector(getSelectedBetsAmount);

  const labels: TLabels = useBetslipLabels({
    eventTypeId: market.eventType?.id ?? '',
    bettingType: market.description?.bettingType ?? '',
    marketType: market.description?.marketType ?? '',
    marketUnit: market.description?.lineRangeInfo?.marketUnit ?? null,
    fancyView: market.fancyView ?? false
  });

  const selectedBetsByType = selectedBets?.[market.marketId]?.[type];
  const backSelectedBets = selectedBets?.[market.marketId]?.[BetTypes.BACK];
  const selectedBetsLength = Object.keys(selectedBetsByType ?? {}).length;
  const backSelectedBetsLength = Object.keys(backSelectedBets ?? {}).length;

  const isCricket: boolean = market?.eventType?.id === SportId.CRICKET;
  const isLineMarket = market?.description?.bettingType === BettingType.LINE ?? false;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;

  const getTRKeyForOdds = () => {
    if (type === BetTypes.LAY) {
      return LAY_SELECTED_UNMATCHED;
    }
    return isPNCEnabled ? BACK_ODDS : BACK_SELECTED_UNMATCHED;
  };

  const { translationKey: translationKeyProfit, isEnabled: isEnabledProfit } = useTooltip(
    type === BetTypes.LAY ? LAY_PROFIT : BACK_PROFIT
  );
  const { translationKey: translationKeySize, isEnabled: isEnabledSize } = useTooltip(
    type === BetTypes.LAY ? LAY_SIZE : BACK_SIZE
  );
  const { translationKey: translationKeyOdds, isEnabled: isEnabledOdds } = useTooltip(getTRKeyForOdds());

  let selectedBetIndex = type === BetTypes.LAY ? backSelectedBetsLength : 0;
  const race = market?.parents?.find(({ type: eventType }) => eventType === PopularEventType.RACE);
  const startTime =
    race?.marketStartTime && parseMillisecondsToTime(+race.marketStartTime || 0, timezone, timezoneCookieEnabled);
  const raceName = race && race.name ? startTime + ' ' + race.name : '';

  if (!selectedBetsLength) {
    return null;
  }

  return (
    <div className={styles.selectedBets}>
      <div
        className={classNames(styles.selectedBetsHeader, branding.BET_EVENT_NAME, branding[type], {
          [branding.FANCY_SWAP]: isFancySwapColors
        })}
      >
        {market.gameName || (
          <>
            {market.event?.name} {raceName ? '- ' + raceName + ' -' : '-'} {market.marketName}
          </>
        )}
      </div>
      {isSubHeaderEnabled && (
        <div
          className={classNames(
            styles.selectedBetsSubHeader,
            styles[`selectedBetsSubHeader__${type.toLowerCase()}`],
            branding.BET_HEADER,
            branding[type],
            { [branding.FANCY_SWAP]: isFancySwapColors }
          )}
        >
          <div>{labels[type]?.bet}</div>
          <div>
            <div
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isEnabledOdds ? unescape(t(translationKeyOdds, { UNITS: PRICE_MARKET_UNITS })) : ''}
              className={isEnabledOdds ? 'cursor-help' : ''}
            >
              {labels[type]?.odds}
            </div>
            <div
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isEnabledSize ? unescape(t(translationKeySize)) : ''}
              className={isEnabledSize ? 'cursor-help' : ''}
            >
              {labels[type]?.stake}
            </div>

            <div
              data-tooltip-id={'tooltip'}
              data-tooltip-html={isEnabledProfit ? unescape(t(translationKeyProfit)) : ''}
              className={isEnabledProfit ? 'cursor-help' : ''}
            >
              {labels[type]?.profit}
            </div>
          </div>
        </div>
      )}
      <div className={styles.selectedBetsList}>
        {map(selectedBetsByType, (bet, key) => (
          <SelectedBet
            key={key}
            bet={bet}
            market={market}
            betIndex={selectedBetIndex++}
            selectedBetsLength={selectedBetsAmount}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectedBets;
