import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { toNumber } from 'lodash';

import useDeviceSettings from 'hooks/useDeviceSettings';
import { usePlacementInputLabels } from 'hooks/useLabels';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import usePriceValidation from 'hooks/usePriceValidation';
import useSizeValidation from 'hooks/useSizeValidation';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { updateInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { TErrorMessage, TPrice } from 'types/bets';
import { ButtonActionTypes, ESizes, InputTypes, Statuses, TInlinePlacement } from 'types/inlinePlacement';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';

import InlinePlacementButton from '../InlinePlacementButton';
import InlinePlacementInput from '../InlinePlacementInput';

import styles from './styles.module.scss';

interface IInlinePlacedBetMessage {
  bet: TInlineSelectedBet;
  status: Statuses;
  marketType: string;
  pageBlock: string;
  bettingType: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  eachWayDivisor?: number | null;
  priceLadderDescription?: TPriceLadderDescription | null;
  placedBet: TCurrentBet | null;
  currency?: string;
  isLineMarket: boolean;
  isTakeOffer: boolean;
  isPNCEnabled: boolean;
  isInputLabels: boolean;
  isQuickStakes: boolean;
  isErrorMessage: boolean;
  isPristinePrice?: boolean;
  componentSize: ESizes;
  onPlaceCallback: ({ price, size, placedUsingEnterKey }: TInlinePlacement) => void;
  onCancelCallback: () => void;
  setIsErrorMessage: (isErrorMessage: boolean) => void;
  setValidationMessage: (message: TErrorMessage) => void;
}

const InlinePlacementForm = ({
  bet,
  status,
  marketType,
  bettingType,
  lineRangeInfo,
  eachWayDivisor,
  priceLadderDescription,
  placedBet,
  currency,
  isLineMarket,
  isPNCEnabled,
  isTakeOffer,
  isInputLabels,
  isQuickStakes,
  isPristinePrice,
  componentSize,
  onPlaceCallback,
  onCancelCallback,
  setIsErrorMessage,
  setValidationMessage
}: IInlinePlacedBetMessage) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accountSettings = useSelector(getAccountSettings);

  const labels = usePlacementInputLabels(bet.type, isLineMarket, lineRangeInfo?.marketUnit);
  const stakeRegexPattern = useStakeRegexPattern();
  const { placeBetWithEnterKey } = useDeviceSettings();

  const {
    setValue: validateSizeValue,
    isValid: isValidSizeValue,
    validValue: validSizeValue,
    errorMessage: sizeValidationMessage,
    setErrorMessage: updateSizeValidationMessage
  } = useSizeValidation(bet.type, currency);

  const {
    setValue: validatePriceValue,
    isValid: isValidPriceValue,
    validValue: validPriceValue,
    errorMessage: priceValidationMessage,
    setErrorMessage: updatePriceValidationMessage
  } = usePriceValidation({
    price: bet.price,
    betType: bet.type,
    marketType,
    bettingType,
    lineRangeInfo,
    priceLadderDescription
  });

  const [focusedElement, setFocusedElement] = useState<InputTypes | ButtonActionTypes>(InputTypes.ODDS);

  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isPlacementInvalid =
    !isValidSizeValue ||
    !isValidPriceValue ||
    (!!placedBet &&
      toNumber(placedBet.price) === toNumber(bet.price) &&
      toNumber(placedBet.sizeRemaining) === toNumber(bet.size));

  const onTabNavigate = (type: InputTypes | ButtonActionTypes) => {
    switch (type) {
      case InputTypes.ODDS:
        setFocusedElement(InputTypes.STAKE);
        break;
      case InputTypes.STAKE:
        setFocusedElement(ButtonActionTypes.PLACE);
        break;
      case ButtonActionTypes.CANCEL:
      case ButtonActionTypes.PLACE:
        setFocusedElement(isPNCEnabled ? InputTypes.STAKE : InputTypes.ODDS);
        break;
    }
  };

  const onEnterClick = () => {
    if (isPlaceBetsWithEnterKeyEnabled && !isPlacementInvalid) {
      onPlaceCallback({ price: bet.price, size: bet.size, placedUsingEnterKey: true });
    }
  };

  useEffect(() => {
    if (sizeValidationMessage.message) {
      setValidationMessage(sizeValidationMessage);
    } else if (priceValidationMessage.message) {
      setValidationMessage(priceValidationMessage);
    }
  }, [priceValidationMessage, sizeValidationMessage]);

  useEffect(() => {
    validateSizeValue(bet.size);
  }, [bet.size]);

  useEffect(() => {
    validatePriceValue(bet.price);
  }, [bet.price]);

  const resetErrorMessage = () => {
    updatePriceValidationMessage({ message: '' });
    updateSizeValidationMessage({ message: '' });
    setValidationMessage({ message: '' });
    setIsErrorMessage(false);
  };

  const showErrorMessage = () => {
    setIsErrorMessage(true);
  };

  const updateInlineBetPrice = (value: TPrice) =>
    dispatch(updateInlineSelectedBet({ ...bet, price: value, isPristinePrice: false, isDirtyPrice: true }));
  const updateInlineBetSize = (value: TPrice) =>
    dispatch(updateInlineSelectedBet({ ...bet, size: value, isDirtySize: true }));

  const cancelBtn = (
    <InlinePlacementButton
      status={status}
      buttonType={ButtonActionTypes.CANCEL}
      isPlacedBet={!!placedBet}
      componentSize={componentSize}
      onClickCallback={onCancelCallback}
      isFocused={focusedElement === ButtonActionTypes.CANCEL}
      onTabNavigate={onTabNavigate}
    />
  );

  const placeBtn = (
    <InlinePlacementButton
      status={status}
      buttonType={ButtonActionTypes.PLACE}
      price={bet.price}
      size={bet.size}
      betType={bet.type}
      eachWayDivisor={eachWayDivisor}
      marketType={marketType}
      bettingType={bettingType}
      isPlacedBet={!!placedBet}
      isLineMarket={isLineMarket}
      isPNCEnabled={isPNCEnabled}
      isTakeOffer={isTakeOffer}
      isPlacementInvalid={isPlacementInvalid}
      componentSize={componentSize}
      onClickCallback={onPlaceCallback}
      isFocused={focusedElement === ButtonActionTypes.PLACE}
      onTabNavigate={onTabNavigate}
    />
  );

  return (
    <>
      <div
        className={classNames(styles.inlinePlacementForm, {
          [styles.inlinePlacementForm__top]: componentSize === ESizes.SMALL
        })}
      >
        {componentSize !== ESizes.SMALL && cancelBtn}
        <InlinePlacementInput
          value={bet.price}
          validValue={validPriceValue}
          isDisabled={isPNCEnabled}
          isInputLabels={isInputLabels}
          componentSize={componentSize}
          label={labels[InputTypes.ODDS]}
          type={InputTypes.ODDS}
          showErrorMessage={showErrorMessage}
          resetErrorMessage={resetErrorMessage}
          updateInputValue={updateInlineBetPrice}
          isSelected={!isPNCEnabled}
          isFocused={focusedElement === InputTypes.ODDS}
          isPristine={isPristinePrice}
          onTabNavigate={onTabNavigate}
          setFocused={setFocusedElement}
          isDirty={bet.isDirtyPrice}
        />
        <InlinePlacementInput
          value={bet.size}
          validValue={validSizeValue}
          isInputLabels={isInputLabels}
          componentSize={componentSize}
          label={labels[InputTypes.STAKE]}
          type={InputTypes.STAKE}
          inputPattern={stakeRegexPattern}
          showErrorMessage={showErrorMessage}
          resetErrorMessage={resetErrorMessage}
          updateInputValue={updateInlineBetSize}
          isSelected={isPNCEnabled}
          isFocused={focusedElement === InputTypes.STAKE}
          onTabNavigate={onTabNavigate}
          onEnterClick={onEnterClick}
          setFocused={setFocusedElement}
          isDirty={bet.isDirtySize}
        />
        {componentSize !== ESizes.SMALL && placeBtn}
      </div>
      {!isPNCEnabled && isLineMarket && (
        <div className={styles.inlinePlacementForm__lineOdds}>{t('betslip.labels.oddsAreAlways2')}</div>
      )}
      {isQuickStakes && <div />}
      {componentSize === ESizes.SMALL && (
        <div className={classNames(styles.inlinePlacementForm, styles.inlinePlacementForm__xs)}>
          {cancelBtn}
          {placeBtn}
        </div>
      )}
    </>
  );
};

export default InlinePlacementForm;
