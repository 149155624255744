import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { QUICK_BETS_HOVER_MESSAGE } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useTooltip from 'hooks/useTooltip';
import { getStakeAmountLabelInfo } from 'utils/settings';

import styles from '../styles.module.scss';

interface QuickBetProps {
  value: string | number;
  handler: (value: string | number) => void;
  byCenter?: boolean;
}

const QuickBet = ({ value, handler, byCenter }: QuickBetProps) => {
  const { t } = useTranslation();
  const { translationKey: translationKeyBets, isEnabled } = useTooltip(QUICK_BETS_HOVER_MESSAGE);

  const { label, translationKey } = getStakeAmountLabelInfo(+value, undefined, true);
  const stake = useFormatCurrency(label, '', {
    noRounding: true,
    ignorePrecision: true,
    fractionDigits: translationKey ? t(translationKey) : ''
  }).noFormattedAmount.replace('.00', '');

  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-html={isEnabled ? unescape(t(translationKeyBets)) : ''}
      className={classNames(styles.stake, {
        [styles.stake__byCenter]: byCenter,
        'cursor-help': isEnabled
      })}
      data-stake={stake}
      onClick={() => handler(value)}
    >
      {stake}
    </div>
  );
};

export default QuickBet;
