import { useSelector } from 'react-redux';

import Header from 'components/Header';
import HeaderNavigation from 'components/HeaderNavigation';
import { getIsMobileAsianView } from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

const AsianHeader = () => {
  const isMobileAsianView = useSelector(getIsMobileAsianView);

  return (
    <>
      <Header isAsianView isMobile={isMobileAsianView} />
      {!isMobileAsianView && (
        <div className={styles.headerMainWrapper}>
          <div className={styles.navigationWrapper}>
            <HeaderNavigation isMobile={isMobileAsianView} />
          </div>
        </div>
      )}
    </>
  );
};

export default AsianHeader;
