import { TFailureActionPayload } from 'types';
import { TBetslipMarket } from 'types/betslip';

export type TGameDataProperties = {
  name: string;
  value: string;
};

type TGameDataStatusTranslations = {
  [key: string]: string;
};

type TGameBestPrice = {
  amountUnmatched: number;
  value: number;
};

export type TGameData = {
  description: string;
  name: string;
  properties: TGameDataProperties[];
  status: ESelectionStatuses;
  statusTranslations: TGameDataStatusTranslations;
};

type TGameMarketSelection = {
  amountMatched: number;
  bestAvailableToBackPrices: TGameBestPrice[];
  bestAvailableToLayPrices: TGameBestPrice[];
  id: number;
  name: string;
  selectionType: string;
  status: string;
};

export type TGameMarkets = {
  commissionRate: number;
  currency: string;
  gameId: number;
  id: number;
  marketAmountMatched: number;
  marketStatus: string;
  marketType: string;
  nextId: number;
  selections: TGameMarketSelection[];
  selectionsType: string;
  startTime: number;
};

export type TGame = {
  bettingWindowPercentageComplete: number;
  bettingWindowTime: number;
  channelId: number;
  channelStatus: string;
  gameData: TGameData[];
  gameId: number;
  gameType: string;
  markets: TGameMarkets[];
  name: string;
  round: number;
};

export interface TGames {
  gameList: TGame[];
  currentGameData: TCurrentGameData | null;
  currentGameDataLoading: boolean;
  selectedGamePage: EGamePages;
  loading: boolean;
  gamesFetchFinished: boolean;
  error: TFailureActionPayload;
  gameRules: TGameRules | null;
  gameRulesLoading: boolean;
  resultsHistory: THistory | null;
  resultsHistoryLoading: boolean;
  selectedResultIndex: number;
  selectedMarket: number;
  stringifiedCurrentGameData: string;
}

export interface TGameList extends TGame {
  order: number;
  isTurbo: boolean;
}

export type TSerializedGames = {
  gameType: string;
  name: string;
  order: number;
  list: TGameList[];
};

export type TGameNavigation = {
  id: number;
  isActive: boolean;
  name: string;
  url: string;
  gameType: string;
};

export enum EGameTypes {
  POKER = 'POKER',
  BLACKJACK = 'BLACKJACK',
  OMAHA_POKER = 'OMAHA_POKER',
  BACCARAT = 'BACCARAT',
  HILO = 'HILO',
  BULLS_EYE_ROULETTE = 'BULLS_EYE_ROULETTE',
  CARD_RACER = 'CARD_RACER'
}

export enum EGameChannelStatus {
  RUNNING = 'RUNNING'
}

export type TCurrentGameData = {
  channelId: number;
  gameType: EGameTypes;
  channelStatus: EGameChannelStatus;
  name: string;
  gameId: number;
  round: number;
  bettingWindowTime: number;
  bettingWindowPercentageComplete: number;
  gameData: TGameData[];
  markets: TCurrentGameMarket[];
};

export enum ESelectionStatuses {
  IN_PLAY = 'IN_PLAY',
  WINNER = 'WINNER',
  CAN_NOT_LOSE = 'CAN_NOT_LOSE',
  CAN_NOT_WIN = 'CAN_NOT_WIN',
  LOSER = 'LOSER',
  REFUND = 'REFUND',
  TIED_DEAD_HEAT = 'TIED_DEAD_HEAT'
}

export type TAvailablePrice = {
  value: number;
  amountUnmatched: number;
};

export type TSelection = {
  id: number;
  status: ESelectionStatuses;
  name: string;
  selectionType: string;
  amountMatched: number;
  bestAvailableToBackPrices: TAvailablePrice[];
  bestAvailableToLayPrices: TAvailablePrice[];
};

export type TCurrentGameMarket = {
  id: number;
  selections: TSelection[];
  currency: string;
  marketStatus: EMarketStatus;
  marketAmountMatched: number;
  marketType: string;
} & TBetslipMarket;

export enum EMarketStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED_GAME_PLAYING = 'SUSPENDED_GAME_PLAYING',
  SUSPENDED_GAME_SETTLING = 'SUSPENDED_GAME_SETTLING',
  SUSPENDED_GAME_ROUND_OVER = 'SUSPENDED_GAME_ROUND_OVER'
}

export enum EGamePages {
  RESULTS = 'RESULTS',
  GAME = 'GAME'
}

export type TGameRules = {
  marketName: string;
  rules: string;
};

export enum MarketType {
  WIN_ONLY = 'WIN_ONLY',
  ANY_NUMBER_OF_WINNERS = 'ANY_NUMBER_OF_WINNERS',
  SINGLE_WINNER_OR_TIE = 'SINGLE_WINNER_OR_TIE',
  VARIABLE_HANDICAP = 'VARIABLE_HANDICAP'
}

type TContentItemSelection = {
  name: string;
  status: string;
};

export interface TContentItemMarket {
  gameId: number;
  id: number;
  marketStatus: EMarketStatus;
  marketType: MarketType;
  selections: TContentItemSelection[];
  selectionsType: string;
}

export type TContentItem = {
  channelId: number;
  gameId: number;
  gameType: EGameTypes;
  startDate: number;
  gameData: TGameData[];
  markets: TContentItemMarket[];
};

export type THistory = {
  actionType?: ActionsTypes;
  content: TContentItem[];
  last: boolean;
  first: boolean;
  totalElements: number;
  totalPages: number;
  number: number;
  numberOfElements: number;
  size: number;
};

export type TFetchResultHistoryPayload = {
  channelId: string;
  page: number;
  gameId?: string;
  betPlacedOnly?: boolean;
};

export enum ECardExceptionValues {
  NOT_AVAILABLE = 'NOT AVAILABLE'
}

export const enum ActionsTypes {
  NEXT = 'NEXT',
  PREV = 'PREV',
  LAST = 'LAST',
  FIRST = 'FIRST'
}

export interface TFetchResultHistory {
  channelId: string;
  gameId?: string;
  page: number;
  actionType?: ActionsTypes;
  betPlacedOnly?: boolean;
}

export enum DataType {
  SEGMENT = 'Segment',
  PLACE = 'Place'
}
