import { useEffect } from 'react';

import { useStakeRegexPattern } from 'hooks/usePrecision';
import useSizeValidation from 'hooks/useSizeValidation';
import { BetTypes, TSize } from 'types/bets';
import { EFormActions, EInputFieldTypes } from 'types/betslip';

import InputField from '../InputField';

import styles from './styles.module.scss';

const BetSize = ({
  value,
  betType,
  currency,
  formAction,
  onChange,
  onValidate,
  onEnterClick,
  fieldOrder = 0,
  isFocus
}: {
  value: TSize;
  betType: BetTypes;
  currency?: string;
  formAction: EFormActions;
  onChange: (price: TSize) => void;
  onValidate: (isValid: boolean) => void;
  onEnterClick: () => void;
  fieldOrder?: number;
  isFocus?: boolean;
}) => {
  const { setValue: updateValidation, ...sizeValidationProps } = useSizeValidation(betType, currency);
  const sizeRegexPattern = useStakeRegexPattern();

  useEffect(() => {
    updateValidation(value);
  }, [value]);

  return (
    <div className={styles.bet}>
      <InputField
        value={value}
        inputPattern={sizeRegexPattern}
        onChange={onChange}
        onValidate={onValidate}
        onEnterClick={onEnterClick}
        formAction={formAction}
        fieldType={EInputFieldTypes.SIZE}
        fieldTabIndex={fieldOrder}
        isFocus={isFocus}
        updateValidation={updateValidation}
        {...sizeValidationProps}
      />
    </div>
  );
};

export default BetSize;
