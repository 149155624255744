import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import AsianViewProfitLossButton from 'components/AsianViewProfitLossButton';
import CashOut from 'components/CashOut';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useAsianLayBetting from 'hooks/useAsianLayBetting';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getAsianViewEventIndex } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent, TAsianViewRunner } from 'redux/modules/asianView/type';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getIsMarketInPlayByMarketPricesId, getMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';

import AsianViewBackLayLabels from '../Event/MarketCell/AsianViewBackLayLabels';
import SelectionOdds from '../Event/MarketCell/SelectionOdds';
import SelectionOddsSkeleton from '../Event/MarketCell/SelectionOddsSkeleton';

import styles from './styles.module.scss';

type OddEvenViewEventProps = {
  competitionId: string;
  sportId: string;
  runners: TAsianViewRunner[];
  marketId: string;
  isFirst?: boolean;
  event: TAsianViewEvent;
};

const OddEvenViewEvent = ({ competitionId, sportId, marketId, runners, isFirst, event }: OddEvenViewEventProps) => {
  const translation = useSelector(getTranslation);
  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, event.id));
  const marketPricesId = useSelector(getMarketPricesId(marketId));
  const eventIndex = useSelector(getAsianViewEventIndex(event.id));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));

  const containerRef = useRef<HTMLDivElement>(null);

  const isLayBetting = useAsianLayBetting();
  const { ref, isIntersecting } = useMarketsPricesVisibleSocketParam({
    marketId,
    eventId: event.id,
    section: MarketsPricesSocketParamsSections.AsianViewMiddleSection,
    observerOptions: { rootMargin: '200px' }
  });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const showCashOut = useMemo(() => {
    return event.sections.top?.some(({ cashOutEnabled }) => cashOutEnabled);
  }, [event.sections.top]);

  const oddRunner = runners.find(runner => runner.name === 'Odd');
  const evenRunner = runners.find(runner => runner.name === 'Even');
  const eventName = event.translations[translation];

  useEffect(() => {
    ref(containerRef.current);
  }, []);

  return (
    <div
      ref={containerRef}
      data-market-id={marketId}
      data-event-id={event.id}
      data-market-prices="true"
      className={classNames({
        [styles.oddEvenViewEvent__content]: isIntersecting,
        [branding.MAIN_CONTAINER_BORDER]: isIntersecting,
        [styles.oddEvenViewEvent__inPlay]: isInPlay && isIntersecting,
        [branding.IN_PLAY_EVENT_1]: isInPlay && eventIndex % 2 === 0 && isIntersecting,
        [branding.IN_PLAY_EVENT_2]: isInPlay && eventIndex % 2 !== 0 && isIntersecting,
        [branding.NON_PLAY_EVENT_1]: !isInPlay && eventIndex % 2 === 0 && isIntersecting,
        [branding.NON_PLAY_EVENT_2]: !isInPlay && eventIndex % 2 !== 0 && isIntersecting
      })}
    >
      {!isIntersecting && <div style={{ height }} />}
      {isIntersecting && (
        <>
          <AsianViewInPlayCell marketId={marketId} marketStartTime={event.startTime} />
          <div className={styles.oddEvenViewEvent__nameConteiner}>
            <div className={styles.oddEvenViewEvent__firstBlock}>
              <div className={styles.oddEvenViewEvent__name}>
                <AsianViewFavouriteStar
                  favourite={{
                    entryName: event.translations[translation],
                    entryId: event.id,
                    entryType: FAVORITES_TYPES.event,
                    sportId
                  }}
                />
                <div data-tooltip-id="tooltip" data-tooltip-html={eventName}>
                  {eventName}
                </div>
              </div>
              <div className={styles.oddEvenViewEvent__rightSection}>
                <AsianViewProfitLossButton eventId={event.id} marketId={marketId} />
                {showCashOut && (
                  <CashOut
                    showLabel={false}
                    showInfoIcon={false}
                    className={{
                      container: styles.oddEvenViewEvent__cashout,
                      icon: styles.oddEvenViewEvent__cashoutIcon
                    }}
                    disabled={!cashOutCounter}
                    isAvPLTableAvailable
                  />
                )}
              </div>
            </div>

            <div className={styles.oddEvenViewEvent__cells}>
              <div className={classNames(styles.oddEvenViewEvent__cell, branding.MAIN_CONTAINER_BORDER)}>
                {isLayBetting && isFirst && <AsianViewBackLayLabels />}
                {marketPricesId && oddRunner ? (
                  <SelectionOdds
                    competitionId={competitionId}
                    eventId={event.id}
                    marketId={marketId}
                    runner={oddRunner}
                  />
                ) : (
                  <SelectionOddsSkeleton />
                )}
              </div>
              <div className={classNames(styles.oddEvenViewEvent__cell, branding.MAIN_CONTAINER_BORDER)}>
                {isLayBetting && isFirst && <AsianViewBackLayLabels />}
                {marketPricesId && evenRunner ? (
                  <SelectionOdds
                    competitionId={competitionId}
                    eventId={event.id}
                    marketId={marketId}
                    runner={evenRunner}
                  />
                ) : (
                  <SelectionOddsSkeleton />
                )}
              </div>
            </div>
          </div>

          <div className={classNames(styles.oddEvenViewEvent__streaming, branding.MAIN_CONTAINER_BORDER)}>
            {event.videoStreamingEnabled && (
              <VideoStream hideLabel isInHeader visible={event.videoStreamingEnabled} eventId={event.id} />
            )}
            {event.matchStatEnabled && (
              <AsianViewMatchStatistics matchStatEnabled={event.matchStatEnabled} eventId={event.id} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default OddEvenViewEvent;
