import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS } from 'constants/asianView';
import { asianViewComponents } from 'constants/branding';
import useAsianOdds from 'hooks/useAsianOdds';
import useAsianPlacementPage from 'hooks/useAsianPlacementPage';
import useConfirmBets from 'hooks/useConfirmBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { usePlacementParams } from 'hooks/usePlacementParams';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import { getIsLandscapeAsianView } from 'redux/modules/asianView/selectors';
import { placeMobileBet, updateMobileSelectedBet } from 'redux/modules/asianViewMobileBetslip';
import {
  AsianViewMobileBetActions,
  TAsianMobileSelectedBet,
  TAsianPlaceMobileBetPayload
} from 'redux/modules/asianViewMobileBetslip/type';
import { getMarketPricesCurrency } from 'redux/modules/marketsPrices/selectors';
import { EPersistenceTypes } from 'types/bets';
import { calculatePayout } from 'utils/asianView';
import { calculateLiability } from 'utils/liability';

import EditButton from '../EditButton';

import styles from './styles.module.scss';

type MobileButtonsProps = {
  bet: TAsianMobileSelectedBet;
};

const ActionButtons = ({ bet }: MobileButtonsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLandscapeAsianView = useSelector(getIsLandscapeAsianView);
  const currencyCode = useSelector(getMarketPricesCurrency(bet.marketId));
  const oddsType = useSelector(getOddsType);

  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const placementParams = usePlacementParams();
  const betPrice = useAsianOdds({ bet });

  const asianViewPage = useAsianPlacementPage(bet ? bet.marketLink : '');
  const payout = calculatePayout({ price: betPrice, size: bet.size });

  const { noFormattedAmount: payoutFormatted } = useFormatCurrency(payout, currencyCode, {
    isCheckIndian: true,
    noRounding: true
  });

  const isConfirm = bet.action === AsianViewMobileBetActions.CONFIRM;
  const isPlacementEnabled = bet.isValid !== false;

  const placeBet = () => {
    const { selectionId, handicap, size, betType, score, marketId, identifier, adjHandicap, isExpandedCoupon } = bet;
    const adjHandicapParam = !isUndefined(adjHandicap) ? { adjHandicap } : {};
    const payloadData: TAsianPlaceMobileBetPayload = {
      [marketId]: [
        {
          selectionId,
          handicap,
          price: betPrice,
          size,
          score,
          side: betType,
          page: asianViewPage,
          persistenceType: EPersistenceTypes.LAPSE,
          placedUsingEnterKey: false,
          numberOfPlacementAttempts: DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS,
          isAsian: true,
          oddsType,
          placedOnMainCoupon: !isExpandedCoupon,
          placedOnExpandedCoupon: isExpandedCoupon,
          betUuid: identifier ?? '',
          ...adjHandicapParam,
          ...placementParams
        }
      ]
    };

    if (bet.betslipId) {
      dispatch(
        updateMobileSelectedBet({ betslipId: bet.betslipId, data: { action: AsianViewMobileBetActions.PROGRESS } })
      );
    }

    dispatch(placeMobileBet(payloadData));
  };

  const placeBetHandler = () => {
    if (isConfirmBetsBeforePlacement && !isConfirm) {
      if (bet.betslipId) {
        dispatch(
          updateMobileSelectedBet({
            betslipId: bet.betslipId,
            data: {
              profit: calculateLiability(bet.price, bet.size, bet),
              action: bet.isValid ? AsianViewMobileBetActions.CONFIRM : AsianViewMobileBetActions.VALIDATION
            }
          })
        );
      }
    } else if (!bet.isValid) {
      if (bet.betslipId) {
        dispatch(
          updateMobileSelectedBet({ betslipId: bet.betslipId, data: { action: AsianViewMobileBetActions.VALIDATION } })
        );
      }
    } else {
      placeBet();
    }
  };

  return (
    <div className={styles.row}>
      {isConfirm && !isLandscapeAsianView && (
        <div className={styles.row__col}>
          <EditButton bet={bet} />
        </div>
      )}
      <div className={styles.row__col}>
        <button
          type="button"
          className={classNames(styles.btn, styles.btn__place, asianViewComponents.PRIMARY_BTN, {
            [styles.btn__place__disabled]: !isPlacementEnabled,
            [styles.btn__place__confirm]: isConfirm,
            [styles.btn__place__lowerPrice]: bet.isLowerPrice,
            [asianViewComponents.DISABLED]: !isPlacementEnabled
          })}
          onClick={placeBetHandler}
        >
          <span className={styles.btn__label}>
            {isConfirm
              ? t('asianView.labels.placementActions.confirmBet')
              : bet.isLowerPrice
              ? t('asianView.labels.placementActions.acceptLowerPrices')
              : t('asianView.labels.placementActions.placeBet')}
          </span>
          <span className={styles.btn__payout}>
            {t('asianView.labels.betslip.payout')}: {payoutFormatted}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ActionButtons;
