import { useTranslation } from 'react-i18next';

interface ICancelAllBetsMessage {
  numberOfCancelled: number | string;
  numberOfAll: number | string;
}

const CancelAllBetsMessage = ({ numberOfCancelled, numberOfAll }: ICancelAllBetsMessage) => {
  const { t } = useTranslation();

  const detailedText = t('marketBetslip.messages.cancelledAllBets', {
    NUMBER_OF_CANCELLED: numberOfCancelled || '',
    NUMBER_OF_ALL: numberOfAll || ''
  });

  return (
    <div className="biab_bet-matched biab_fixed">
      <div className="biab_matching-bet-msg biab_fixed biab_cancelled-all-bets-message">
        <p className="biab_matched-details js-matched-details" dangerouslySetInnerHTML={{ __html: detailedText }} />
      </div>
    </div>
  );
};

export default CancelAllBetsMessage;
