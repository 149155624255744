import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useIntersectionObserver } from 'usehooks-ts';

import { removeMarketPricesSocketParam, setMarketsPricesSocketParam } from 'redux/modules/marketsPrices';
import { MarketsPricesSocketParamsSectionsKeys } from 'redux/modules/marketsPrices/type';

type Params = {
  marketId?: string | null;
  eventId?: string | null;
  section: MarketsPricesSocketParamsSectionsKeys;
  observerOptions?: {
    root?: Element | Document | null;
    rootMargin?: string;
    threshold?: number | number[];
    freezeOnceVisible?: boolean;
    onChange?: (isIntersecting: boolean, entry: IntersectionObserverEntry) => void;
    initialIsIntersecting?: boolean;
  };
};

const useMarketsPricesVisibleSocketParam = ({ marketId, eventId, observerOptions, section }: Params) => {
  const dispatch = useDispatch();

  const { ref, isIntersecting } = useIntersectionObserver(observerOptions);

  useEffect(() => {
    if (marketId && eventId) {
      dispatch(setMarketsPricesSocketParam({ marketId, eventId, isVisible: isIntersecting, section }));
    }
  }, [isIntersecting, marketId, eventId]);

  useEffect(() => {
    return () => {
      if (marketId) {
        dispatch(removeMarketPricesSocketParam({ marketId, section }));
      }
    };
  }, []);

  return { ref, isIntersecting };
};

export default useMarketsPricesVisibleSocketParam;
