import { useSelector } from 'react-redux';
import classnames from 'classnames';

import GameDerbyRacingFirstSection from 'components/GameDerbyRacingFirstSection';
import GameDerbyRacingSecondSection from 'components/GameDerbyRacingSecondSection';
import GameDerbyRacingThirdSection from 'components/GameDerbyRacingThirdSection';
import GameDerbyRacingTitle from 'components/GameDerbyRacingTitle';
import GameLoader from 'components/GameLoader';
import { getCurrentGameRoundPercent } from 'redux/modules/games/selectors';
import { TGameData } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface IGameCartsDerbyRacing {
  gameData: TGameData[];
}

const GameCartsDerbyRacing = ({ gameData }: IGameCartsDerbyRacing) => {
  const percent = useSelector(getCurrentGameRoundPercent);
  const [cards, spades, hearts, clubs, diamonds] = gameData;

  return (
    <div className={'biab_games-wrapper'}>
      <div className={classnames(styles.game, 'biab_card-racer-game')} id={'biab_card-racer-game'}>
        <div className={styles.section1}>
          <div className={styles.header}>
            <GameDerbyRacingTitle />
            <GameLoader loaderPercentage={percent || 0} />
          </div>
          <GameDerbyRacingFirstSection data={cards} />
          <GameDerbyRacingSecondSection data={cards} segments={[spades, hearts, clubs, diamonds]} />
        </div>
        <GameDerbyRacingThirdSection gameData={gameData} />
      </div>
    </div>
  );
};

export default GameCartsDerbyRacing;
