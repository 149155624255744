import { TFunction } from 'react-i18next';

import { ONE_DAY_IN_MS, ONE_HOUR_IN_MS, ONE_MINUTE_IN_MS } from 'constants/date';
import {
  RESPONSIBLE_BETTING_DEFAULT_SCALE_GAP,
  RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH,
  RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT,
  RESPONSIBLE_BETTING_TIME_UNITS,
  updatedResponsibleBettingTimeUnits
} from 'constants/responsibleBetting';
import { ResponsibleBettingDisplaySettings } from 'redux/modules/appConfigs/type';
import { CombinedResponsibleBettingTimeUnit, ResponsibleBettingSettings } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import {
  ResponsibleBettingDisplayedContent,
  ResponsibleBettingEndDate,
  ResponsibleBettingLimit,
  ResponsibleBettingOperatorLimit,
  ResponsibleBettingStartDate,
  ResponsibleBettingTimeUnitKeys,
  ResponsibleBettingTimeUnits,
  UpdatedResponsibleBettingDisplayedContent
} from 'types/responsibleBetting';

import { applyTimezone, getDaysDifference, getTodayDate } from './date';

export const getFirstEnabledResponsibleBettingTab = (
  {
    exposureLimitEnabled,
    dayLimitEnabled,
    reminderEnabled,
    lossLimitEnabled,
    selfExclusionEnabled
  }: ResponsibleBettingDisplaySettings,
  multiCurrencySupported: boolean
) => {
  if (exposureLimitEnabled && !multiCurrencySupported) {
    return ResponsibleBettingDisplayedContent.ExposureLimit;
  } else if (lossLimitEnabled && !multiCurrencySupported) {
    return ResponsibleBettingDisplayedContent.LossLimit;
  } else if (selfExclusionEnabled) {
    return ResponsibleBettingDisplayedContent.SelfExclusion;
  } else if (reminderEnabled) {
    return ResponsibleBettingDisplayedContent.Reminder;
  } else if (dayLimitEnabled) {
    return ResponsibleBettingDisplayedContent.DayLimit;
  }

  return undefined;
};

export const getResponsibleBettingTabs = (
  {
    exposureLimitEnabled,
    lossLimitEnabled,
    selfExclusionEnabled,
    dayLimitEnabled,
    reminderEnabled
  }: ResponsibleBettingDisplaySettings,
  multiCurrencySupported: boolean
) => {
  return [
    {
      labelTranslationKey: 'responsibleBetting.titles.exposureLimit',
      name: ResponsibleBettingDisplayedContent.ExposureLimit,
      isVisible: exposureLimitEnabled && !multiCurrencySupported
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.lossLimit',
      name: ResponsibleBettingDisplayedContent.LossLimit,
      isVisible: lossLimitEnabled && !multiCurrencySupported
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.selfExclusion',
      name: ResponsibleBettingDisplayedContent.SelfExclusion,
      isVisible: selfExclusionEnabled
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.reminder',
      name: ResponsibleBettingDisplayedContent.Reminder,
      isVisible: reminderEnabled
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.dayLimit',
      name: ResponsibleBettingDisplayedContent.DayLimit,
      isVisible: dayLimitEnabled
    }
  ];
};

export const getUpdatedResponsibleBettingTabs = (
  { lossLimitEnabled, selfExclusionEnabled, timeLimitEnabled, reminderEnabled }: ResponsibleBettingDisplaySettings,
  multiCurrencySupported: boolean
) => {
  const updatedResponsibleBettingTabs = [
    {
      labelTranslationKey: 'responsibleBetting.titles.lossLimit',
      btnLabelTranslationKey: 'responsibleBetting.buttons.setUpLossLimit',
      name: UpdatedResponsibleBettingDisplayedContent.LossLimit,
      isVisible: lossLimitEnabled && !multiCurrencySupported,
      descriptionTranslationKey: 'responsibleBetting.message.LOSS_LIMIT_DEFAULT'
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.selfExclusion',
      btnLabelTranslationKey: 'responsibleBetting.buttons.startSelfExclusion',
      name: UpdatedResponsibleBettingDisplayedContent.SelfExclusion,
      isVisible: selfExclusionEnabled,
      descriptionTranslationKey: 'responsibleBetting.message.SELF_EXCLUSION_DEFAULT'
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.reminder',
      btnLabelTranslationKey: 'responsibleBetting.buttons.setUpReminder',
      name: UpdatedResponsibleBettingDisplayedContent.Reminder,
      isVisible: reminderEnabled,
      descriptionTranslationKey: 'responsibleBetting.message.REMINDER_DEFAULT'
    },
    {
      labelTranslationKey: 'responsibleBetting.titles.timeLimit',
      btnLabelTranslationKey: 'responsibleBetting.buttons.setUpTimeLimit',
      name: UpdatedResponsibleBettingDisplayedContent.TimeLimit,
      isVisible: timeLimitEnabled,
      descriptionTranslationKey: 'responsibleBetting.message.TIME_LIMIT_DEFAULT'
    }
  ];

  return updatedResponsibleBettingTabs;
};

export const getInitDate = ({
  rbSettings,
  dateKey,
  operatorLimitKey
}: {
  rbSettings: ResponsibleBettingSettings | null;
  dateKey: ResponsibleBettingStartDate | ResponsibleBettingEndDate;
  operatorLimitKey: ResponsibleBettingOperatorLimit;
}) => {
  const date = rbSettings?.rgTools?.[dateKey];
  const operatorLimit = rbSettings?.[operatorLimitKey];

  if (operatorLimit) {
    return null;
  }

  if (typeof date === 'number') {
    const currentDate = new Date(date);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    return currentDate;
  }

  return null;
};

export const getInitLimit = (
  rbSettings: ResponsibleBettingSettings | null,
  limitKey: ResponsibleBettingLimit,
  operatorLimitKey?: ResponsibleBettingOperatorLimit
) => {
  const operatorLimit = operatorLimitKey && rbSettings?.[operatorLimitKey];
  const limit = rbSettings?.rgTools?.[limitKey];

  if (operatorLimit) {
    return String(operatorLimit);
  }

  return typeof limit === 'number' ? String(limit) : '';
};

export const parseSessionTime = (sessionStartTime: number, t: TFunction) => {
  const sessionTime = new Date().getTime() - sessionStartTime;

  const sessionMinutes = Math.floor(((sessionTime % ONE_DAY_IN_MS) % ONE_HOUR_IN_MS) / ONE_MINUTE_IN_MS);
  const sessionHours = Math.floor((sessionTime % ONE_DAY_IN_MS) / ONE_HOUR_IN_MS);
  let parsedMinutes = '';
  let parsedHours = '';

  if (sessionMinutes) {
    if (sessionMinutes === 1) {
      parsedMinutes = ` ${sessionMinutes} ${t('responsibleBetting.labels.sessionReminder.minute.1')}`;
    } else {
      parsedMinutes = ` ${sessionMinutes} ${t('responsibleBetting.labels.sessionReminder.minute.plural')}`;
    }
  }

  if (sessionHours) {
    if (sessionHours === 1) {
      parsedHours = ` ${sessionHours} ${t('responsibleBetting.labels.sessionReminder.hour.1')}`;
    } else {
      parsedHours = ` ${sessionHours} ${t('responsibleBetting.labels.sessionReminder.hour.plural')}`;
    }
  }

  return `${parsedHours}${parsedMinutes}`;
};

export const setCalendarSelectSize = (element: Element, size: string) => {
  element.addEventListener('focus', () => {
    element.setAttribute('size', size);
  });

  element.addEventListener('blur', () => {
    element.setAttribute('size', '0');
  });

  element.addEventListener('change', function (this: HTMLSelectElement) {
    this.blur();
  });
};

export const calculateLimitIndexes = (
  currentLimit: number | null,
  initialLimit: number | null,
  newLimit: number | null,
  currentLimitEndDate: Date | null,
  initialPeriod: CombinedResponsibleBettingTimeUnit | null,
  newPeriod: CombinedResponsibleBettingTimeUnit | null
): {
  newLossLimitIndex: number;
  initialLossLimitIndex: number;
  currentLossLimitIndex: number;
} => {
  const indexes = {
    newLossLimitIndex: 0,
    initialLossLimitIndex: 0,
    currentLossLimitIndex: 0
  };

  if (currentLimitEndDate && initialPeriod && newPeriod && currentLimit && initialLimit && newLimit) {
    const currentLimitAvailableDays = getDaysDifference(getTodayDate(), currentLimitEndDate);

    const newLimitAvailableDays = getTimeUnitDaysAmount(newPeriod);
    const initialLimitAvailableDays = getTimeUnitDaysAmount(initialPeriod);

    indexes.newLossLimitIndex = newLimit / newLimitAvailableDays;
    indexes.initialLossLimitIndex = initialLimit / initialLimitAvailableDays;
    indexes.currentLossLimitIndex = currentLimit / currentLimitAvailableDays;
  }

  return indexes;
};

const addMonthsAndAdjust = (date: Date, months: number): Date => {
  const originalDate = date.getDate();
  date.setMonth(date.getMonth() + months);

  if (date.getDate() < originalDate) {
    date.setMonth(date.getMonth() + 1, 0);
  }

  return date;
};

export const getTimeUnitKey = (timeUnit: CombinedResponsibleBettingTimeUnit | null) => {
  let timeUnitKey = '';
  if (timeUnit) {
    timeUnitKey = RESPONSIBLE_BETTING_TIME_UNITS[timeUnit as ResponsibleBettingTimeUnitKeys];
  }
  return timeUnitKey;
};

export const getTimeUnitDaysAmount = (timeUnit: CombinedResponsibleBettingTimeUnit | null) => {
  let daysAmount = 0;
  if (timeUnit) {
    daysAmount = RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT[timeUnit as ResponsibleBettingTimeUnitKeys];
  }
  return daysAmount;
};

export const calculateNewPeriodStartDate = (
  startDateOfCurrentPeriod: number | null,
  timeUnit: CombinedResponsibleBettingTimeUnit | null,
  cooldownDays: number | undefined,
  timezone: string,
  timezoneCookieEnabled: boolean
): Date | undefined => {
  if (startDateOfCurrentPeriod && timeUnit && cooldownDays !== undefined) {
    let periodEndDate = new Date(startDateOfCurrentPeriod);

    if (timeUnit === ResponsibleBettingTimeUnits.MONTH) {
      periodEndDate = addMonthsAndAdjust(periodEndDate, 1);
    } else {
      const periodLength = getTimeUnitDaysAmount(timeUnit);
      periodEndDate.setDate(periodEndDate.getDate() + periodLength);
    }

    const cooldownEndDate = new Date();
    cooldownEndDate.setDate(cooldownEndDate.getDate() + cooldownDays);

    while (periodEndDate <= cooldownEndDate) {
      if (timeUnit === ResponsibleBettingTimeUnits.MONTH) {
        periodEndDate = addMonthsAndAdjust(periodEndDate, 1);
      } else {
        const periodLength = getTimeUnitDaysAmount(timeUnit);
        periodEndDate.setDate(periodEndDate.getDate() + periodLength);
      }
    }

    return applyTimezone(new Date(periodEndDate), timezone, timezoneCookieEnabled);
  }
};

export const calculateResponsibleBettingScaleWidth = (
  currentLimitAmount: number | null,
  spentLimitAmount: number | null
) => {
  const width = { spent: 0, current: RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH };
  if (!currentLimitAmount || !spentLimitAmount) {
    return width;
  }

  if (currentLimitAmount) {
    const spentWidth = (spentLimitAmount / currentLimitAmount) * RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH;
    const remainingWidth = RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH - spentWidth;

    width.spent = Math.round(spentWidth);
    width.current = Math.round(remainingWidth - RESPONSIBLE_BETTING_DEFAULT_SCALE_GAP);

    return width;
  }

  return width;
};

export const convertTimeUnitsToDropdown = (t: TFunction): DropdownItem[] => {
  return updatedResponsibleBettingTimeUnits.map((timeUnit, index) => ({
    id: index,
    value: timeUnit.value,
    label: t(timeUnit.label)
  }));
};
