import { memo, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';

import Modal from 'components/Modal';
import { MARKET_TYPES } from 'constants/marketTypes';
import { getAppDevice, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getConsolidatedBets } from 'redux/modules/betslip/selectors';
import { getCurrentBetsBySideTypeLengthByMarketId } from 'redux/modules/currentBets/selectors';
import { fetchPLTable } from 'redux/modules/plTable';
import { getIsPLTableFirstLoaded, getPLTableCurrentPL, getPLTableWorstCasePL } from 'redux/modules/plTable/selectors';
import { CookieNames } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';
import { BettingType } from 'types/markets';

import { isShowPLTablePopup } from '../MarketSport/helpers';

import AsianHandicapTable from './components/AsianHandicapTable';
import GoalLineTable from './components/GoalLineTable';
import HandicapTable from './components/HandicapTable';
import OverLineTable from './components/OverLineTable';
import PLTableIntervalRequestInjection from './components/PLTableIntervalRequestInjection/PLTableIntervalRequestInjection';
import TotalGoalTable from './components/TotalGoalTable';

import styles from './styles.module.scss';

interface PLTableProps {
  marketId: string;
  bettingType: BettingType;
  marketType: string;
  currencyCode: string;
  units: string;
}

const PLTable = ({ marketId, bettingType, marketType, units, currencyCode }: PLTableProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.PL_TABLE_VISIBLE]);
  const consolidateBets = useSelector(getConsolidatedBets);

  const device = useSelector(getAppDevice);
  const currentPL = useSelector(getPLTableCurrentPL);
  const worstCasePL = useSelector(getPLTableWorstCasePL);
  const isFirstLoaded = useSelector(getIsPLTableFirstLoaded);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const backCurrentBetsUnmatched = useSelector(
    getCurrentBetsBySideTypeLengthByMarketId(marketId, BetTypes.LAY, MatchTypes.UNMATCHED, consolidateBets)
  );
  const LayCurrentBetsUnmatched = useSelector(
    getCurrentBetsBySideTypeLengthByMarketId(marketId, BetTypes.BACK, MatchTypes.UNMATCHED, consolidateBets)
  );
  const backCurrentBetsMatched = useSelector(
    getCurrentBetsBySideTypeLengthByMarketId(marketId, BetTypes.LAY, MatchTypes.MATCHED, consolidateBets)
  );
  const LayCurrentBetsMatched = useSelector(
    getCurrentBetsBySideTypeLengthByMarketId(marketId, BetTypes.BACK, MatchTypes.MATCHED, consolidateBets)
  );

  const isShowPlTablePopup = isShowPLTablePopup(bettingType, marketType);
  const isMobile = device === Devices.MOBILE;
  const marketHasOnlyUnmatchedBets =
    (!!backCurrentBetsUnmatched || !!LayCurrentBetsUnmatched) && !backCurrentBetsMatched && !LayCurrentBetsMatched;
  let content = <div />;

  const handlerPLTable = () => {
    setCookie(CookieNames.PL_TABLE_VISIBLE, cookies.PL_TABLE_VISIBLE ? '' : true);
  };

  useEffect(() => {
    if (marketId && isLoggedIn) {
      dispatch(fetchPLTable({ marketId, isLine: bettingType === BettingType.LINE }));
    }
  }, [isLoggedIn, marketId, language, timezone]);

  if (currentPL.length || worstCasePL.length) {
    if (bettingType === BettingType.ASIAN_HANDICAP_DOUBLE_LINE) {
      if (
        marketType !== MARKET_TYPES.handicap &&
        marketType !== MARKET_TYPES.altTotalGoals &&
        marketType !== MARKET_TYPES.allTotalGoals &&
        marketType !== MARKET_TYPES.combinedTotal
      ) {
        content = <AsianHandicapTable currencyCode={currencyCode} />;
      } else if (marketType === MARKET_TYPES.handicap && currentPL.length) {
        content = <HandicapTable currencyCode={currencyCode} />;
      } else if (
        !marketHasOnlyUnmatchedBets &&
        (marketType === MARKET_TYPES.altTotalGoals || marketType === MARKET_TYPES.combinedTotal)
      ) {
        content = <GoalLineTable units={units} currencyCode={currencyCode} />;
      } else {
        content = (
          <div className={classnames('biab_pl-table-wrapper', styles.container)}>
            <div className={classnames('biab_msg', styles.container__emptyText)}>{t('PLTable.noRecords')}</div>
          </div>
        );
      }
    } else if (bettingType === BettingType.ASIAN_HANDICAP_SINGLE_LINE) {
      content = <TotalGoalTable currencyCode={currencyCode} />;
    } else if (!marketHasOnlyUnmatchedBets && bettingType === BettingType.LINE) {
      content = <OverLineTable units={units} currencyCode={currencyCode} />;
    }
  } else {
    content = (
      <div className={classnames('biab_pl-table-wrapper', styles.container)}>
        <div className={classnames('biab_msg', styles.container__emptyText)}>{t('PLTable.noRecords')}</div>
      </div>
    );
  }

  if (!isFirstLoaded) {
    return null;
  }

  return (
    <>
      <PLTableIntervalRequestInjection marketId={marketId} bettingType={bettingType} />
      {isMobile && isShowPlTablePopup ? (
        <Modal
          customClassNames={{
            header: styles.PLTableTitle,
            closeIcon: styles.closePopupIcon
          }}
          title={t('market.PLTable')}
          open={cookies.PL_TABLE_VISIBLE}
          onClose={handlerPLTable}
          clickableBackground
          isDisableScroll
        >
          {content}
        </Modal>
      ) : (
        content
      )}
    </>
  );
};

export default memo(PLTable);
