import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import InfoCircle from 'components/InfoCircle';
import Modal from 'components/Modal';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useTooltip from 'hooks/useTooltip';
import { getLineMarketsSwitchBackLayOnCricket } from 'redux/modules/appConfigs/selectors';
import { BetTypes } from 'types/bets';
import { getMarketUnitTranslation } from 'utils/market';

import styles from './styles.module.scss';

interface ConfirmationModalProps {
  type: string;
  size: number | string | undefined;
  price: number | string | undefined;
  profit: number | string | undefined;
  isOpen: boolean;
  isLineMarket: boolean;
  isPNCEnabled: boolean;
  isCricket: boolean;
  marketUnit: string;
  isCancelationModal?: boolean;
  onClose: (event?: SyntheticEvent) => void;
  onConfirm: (event: SyntheticEvent) => void;
}

const ConfirmationModal = ({
  type,
  size = 0,
  price,
  profit = 0,
  isOpen,
  isLineMarket,
  isPNCEnabled,
  isCricket,
  marketUnit,
  onClose,
  onConfirm,
  isCancelationModal = false
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const { noFormattedAmount: formattedSize } = useFormatCurrency(size || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });
  const { noFormattedAmount: formattedProfit } = useFormatCurrency(profit || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });
  const { swapColorsFancyMarketsOnCricket, replaceBackLayWithUnderOver } = useDeviceSettings();
  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const className = isCricket && isLineMarket && swapColorsFancyMarketsOnCricket ? 'biab_fancy-swap' : '';
  const tableClass = size.toString().length > 8 || profit.toString().length > 8 ? styles.table__bigNumbers : '';
  const unitsTranslation = t(getMarketUnitTranslation(marketUnit.toLocaleLowerCase()));
  const oddsTooltip =
    type === BetTypes.BACK
      ? isLineMarket
        ? 'BETSLIP_UNITS'
        : 'BETSLIP_ODDS'
      : isLineMarket
      ? 'BETSLIP_BACKERS_UNITS'
      : 'BETSLIP_BACKERS_ODDS';
  const profitTooltip = type === BetTypes.BACK ? 'BETSLIP_PROFIT' : 'BETSLIP_YOUR_LIABILITY';
  const stakeTooltip = type === BetTypes.BACK ? 'BETSLIP_STAKE' : 'BETSLIP_BACKERS_STAKE';

  const { translationKey: translationKeyProfit, isEnabled: isEnabledProfit } = useTooltip(profitTooltip);
  const { translationKey: translationKeyStake, isEnabled: isEnabledStake } = useTooltip(stakeTooltip);
  const { translationKey: translationKeyOdds, isEnabled: isEnabledOdds } = useTooltip(oddsTooltip);

  const getOddsLabel = () => {
    if (isLineMarket) {
      return t('betslip.labels.units.' + type?.toLocaleLowerCase(), {
        UNITS: type === BetTypes.BACK ? unitsTranslation : unitsTranslation.toLowerCase()
      });
    }
    return t('betslip.labels.price.' + type?.toLocaleLowerCase());
  };

  const getTypeLabel = () => {
    if (isCricket) {
      if (lineBackLayToNoYes && isLineMarket) {
        return t('betslip.labels.type.' + (type === BetTypes.BACK ? 'no.bet.against' : 'yes.bet.for'));
      }
    }
    if (!isCricket && isLineMarket && replaceBackLayWithUnderOver) {
      return t('betslip.labels.type.' + (type === BetTypes.BACK ? 'under' : 'over'));
    }
    return t('betslip.labels.type.' + type?.toLocaleLowerCase());
  };

  return (
    <Modal
      clickableBackground={false}
      hasTitle={false}
      open={isOpen}
      onClose={onClose}
      customClassNames={{ container: styles.betConfirmation, dialog: styles.dialog, body: styles.dialog__body }}
    >
      <div className={classNames('biab_place-bets-modal', className)}>
        <div
          className={classNames(
            'biab_bet-confirmation-modal',
            styles.betModal,
            type === BetTypes.BACK ? 'biab_bet-modal' : ''
          )}
        >
          <div className={classNames('biab_confirmation-modal-title', styles.betModal__title)}>
            {t('betslip.messages.' + (isCancelationModal ? 'confirmCancelBetMsg' : 'confirmBetMsg'))}
          </div>
          <div className={classNames('biab_confirmation-modal-body', styles.betModal__body)}>
            <div>
              <div className={classNames('biab_confirmation-title', styles.betModal__body__title)}>
                {getTypeLabel()}
              </div>
              <table className={classNames(tableClass, styles.table)}>
                <tbody>
                  <tr>
                    <td>
                      <span className={classNames('biab_label', styles.label)}>
                        {getOddsLabel()}
                        {isEnabledOdds && (
                          <span data-tooltip-id={t('tooltip')} data-tooltip-html={unescape(t(translationKeyOdds))}>
                            <InfoCircle />
                          </span>
                        )}
                      </span>
                      <span className={classNames('biab_value biab_price', styles.value)}>{price}</span>
                    </td>
                    <td>
                      <span className={classNames('biab_label', styles.label)}>
                        {t('betslip.labels.size.' + type?.toLowerCase())}
                        {isEnabledStake && (
                          <span data-tooltip-id={t('tooltip')} data-tooltip-html={unescape(t(translationKeyStake))}>
                            <InfoCircle />
                          </span>
                        )}
                      </span>
                      <span className={classNames('biab_value biab_size', styles.value)}>{formattedSize}</span>
                    </td>
                    {!isCancelationModal && (
                      <td>
                        <span className={classNames('biab_label', styles.label)}>
                          {t('betslip.labels.profit.' + type.toLowerCase())}

                          {isEnabledProfit && (
                            <span data-tooltip-id={t('tooltip')} data-tooltip-html={unescape(t(translationKeyProfit))}>
                              <InfoCircle />
                            </span>
                          )}
                        </span>
                        <span className={classNames('biab_value biab_profit', styles.value)}>{formattedProfit}</span>
                      </td>
                    )}
                  </tr>
                </tbody>
              </table>
              {isLineMarket && !isPNCEnabled && (
                <div className={classNames('biab_line-odds-info', styles.betModal__body__oddsInfo)}>
                  {t('mobile.betslip.labels.oddsAreAlways2')}
                </div>
              )}
            </div>
            <button
              onClick={onClose}
              type="button"
              className={classNames(
                'biab_cancel-btn biab_modal-btn',
                styles.betModal__btn,
                styles.betModal__btn__cancel
              )}
            >
              {t('betslip.labels.btn.cancel')}
            </button>
            <button
              onClick={onConfirm}
              type="button"
              className={classNames(
                'biab_confirm-btn biab_modal-btn',
                styles.betModal__btn,
                styles.betModal__btn__confirm
              )}
            >
              {t('betslip.labels.btn.confirm')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
