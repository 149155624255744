import { createSelector } from '@reduxjs/toolkit';
import { reduce } from 'lodash';

import { AppState } from 'redux/reducers';
import { PageBlocks } from 'types';
import { BetTypes } from 'types/bets';
import { BettingType } from 'types/markets';

import { TInlineSelectedBet, TInlineSelectedBetByMarket } from './type';

export const getInlineSelected = (state: AppState) => state.inlinePlacement;

export const getInlineSelectedBetByMarket =
  (pageBlock: PageBlocks | null = null, marketId: string) =>
  (state: AppState) =>
    pageBlock && marketId ? state.inlinePlacement.bets[pageBlock]?.[marketId] ?? null : null;

export const getInlineSelectedBetBySelection =
  (pageBlock: PageBlocks | null = null, marketId: string, selectionId: number, handicap: string | number | null) =>
  (state: AppState) => {
    const selectedBet = getInlineSelectedBetByMarket(pageBlock, marketId)(state);
    return selectedBet &&
      selectedBet.selectionId === selectionId &&
      (+(selectedBet.handicap || 0) === +(handicap || 0) || selectedBet.bettingType === BettingType.LINE)
      ? selectedBet
      : null;
  };

export const getInlineSelectedBet =
  (
    pageBlock: PageBlocks | null = null,
    marketId: string,
    selectionId: number,
    handicap: string | number | null,
    type: BetTypes
  ) =>
  (state: AppState) => {
    const selectedBet = getInlineSelectedBetBySelection(pageBlock, marketId, selectionId, handicap)(state);
    return selectedBet && selectedBet.type === type ? selectedBet : null;
  };

/**
 * Collect all inline selected bets into one array
 *
 * @param state
 */
export const getAllInlineSelectedBets = createSelector(getInlineSelected, inlinePlacement =>
  reduce(
    inlinePlacement.bets,
    (resAll: TInlineSelectedBet[], betsByType: TInlineSelectedBetByMarket) => {
      return [
        ...resAll,
        ...reduce(betsByType ?? {}, (res: TInlineSelectedBet[], bet: TInlineSelectedBet) => [...res, bet], [])
      ];
    },
    []
  )
);

export const getInlineSelectedBets = createSelector(getInlineSelected, inlinePlacement => inlinePlacement.bets);
