import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import useLongDateFormat from 'hooks/useLongDateFormat';
import {
  getMarketPricesRunnerAdjustmentFactorBySelectionId,
  getMarketPricesRunnerRemovalDateBySelectionId
} from 'redux/modules/marketsPrices/selectors';

import styles from './styles.module.scss';

interface NonRunnerProps {
  isShowTime?: boolean;
  marketId: string;
  selectionId: number;
  handicap: number;
}

const NonRunner = ({ marketId, selectionId, handicap, isShowTime }: NonRunnerProps) => {
  const { t } = useTranslation();

  const removalDate = useSelector(getMarketPricesRunnerRemovalDateBySelectionId(marketId, selectionId, handicap));
  const adjustmentFactor = useSelector(
    getMarketPricesRunnerAdjustmentFactorBySelectionId(marketId, selectionId, handicap)
  );

  const formattedDate = useLongDateFormat({
    isTimeFirst: false,
    isShortYear: false,
    hasWeekDay: true,
    time: removalDate ?? 0,
    hasYear: false,
    divider: ' ',
    dateDivider: ' '
  });

  const percent = adjustmentFactor?.toFixed(1) || '';

  return (
    <div className={classnames('biab_non_runner nonRunner', styles.container)}>
      <span className={classnames('firstBlock', styles.firstBlock)}>
        <span className={classnames('non-runner-title', styles.container__title)}>
          {t('market.nonRunner.nonRunner')}
        </span>
        <span className={'non-runner-adjustment-factor'}>&nbsp;{`${percent}`}</span>
      </span>
      <span>%{isShowTime ? ',' : ''}</span>
      {isShowTime && <div className={'non-runner-date'}>{formattedDate}</div>}
    </div>
  );
};

export default NonRunner;
