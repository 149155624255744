import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { reduce } from 'lodash';

import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import { useMarketUnits } from 'hooks/useMarketUnits';
import {
  getEventViewSettings,
  getFancyMarketsOnCricket,
  getLineMarketsSwitchBackLayOnCricket,
  getReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { EventViewType, SportId } from 'types';
import { BetTypes } from 'types/bets';
import { InputTypes } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';

export const usePlacementInputLabels = (
  betType: BetTypes,
  isLineMarket: boolean,
  marketUnit: string = DEFAULT_MARKET_UNITS
) => {
  const { t } = useTranslation();

  return {
    [InputTypes.ODDS]: isLineMarket ? marketUnit : t('inlinePlacement.labels.odds'),
    [InputTypes.STAKE]:
      betType === BetTypes.BACK ? t('inlinePlacement.labels.stake') : t('inlinePlacement.labels.stake.lay'),
    liability: t(`inlinePlacement.labels.${betType === BetTypes.BACK ? 'profit' : 'liability'}`)
  };
};

export const useBetslipLabels = ({
  eventTypeId,
  bettingType,
  marketUnit,
  fancyView = false
}: {
  eventTypeId: string;
  bettingType: string | null;
  marketType: string | null;
  marketUnit: string | null;
  fancyView?: boolean;
}) => {
  const { t } = useTranslation();

  const eventViewSettings = useSelector(getEventViewSettings);
  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getReplaceBackLayWithUnderOver);
  const fancyMarketsOnCricket = useSelector(getFancyMarketsOnCricket);

  const marketUnitTranslated = useMarketUnits(marketUnit || 'points');

  const eventSettings = eventViewSettings.find(event => event.eventTypeId === eventTypeId);
  const isLineMarket = bettingType === BettingType.LINE;
  const isCricketMarket = eventTypeId === SportId.CRICKET;
  const isFancyMarketEnabled = fancyMarketsOnCricket && eventSettings?.viewType !== EventViewType.TOP_5_VIEW;

  const getLabelType = (type: string) => {
    return !isCricketMarket && isLineMarket && replaceBackLayWithUnderOver
      ? type === 'back'
        ? 'under'
        : 'over'
      : lineBackLayToNoYes
      ? (fancyView && isFancyMarketEnabled) || (isCricketMarket && isLineMarket)
        ? type === 'back'
          ? 'no'
          : 'yes'
        : type
      : type;
  };

  return useMemo(() => {
    return reduce(
      BetTypes,
      (result, type) => {
        const betType = type.toLowerCase();
        const backBetType = BetTypes.BACK.toLowerCase();

        return {
          ...result,
          [type]: {
            bet: t('betslip.labels.type.' + getLabelType(betType)),
            odds:
              isLineMarket || (fancyView && isFancyMarketEnabled)
                ? /** Use betslip.labels.units.back for both types if market is LINE */
                  t(`betslip.labels.units.${backBetType}`, { UNITS: marketUnitTranslated })
                : t(`betslip.labels.price.${betType}`),
            stake: t(`betslip.labels.size.${betType}`),
            profit: t(`betslip.labels.profit.${betType}`)
          }
        };
      },
      {}
    );
  }, [fancyView, isFancyMarketEnabled, isLineMarket, marketUnitTranslated]);
};
