import { useSelector } from 'react-redux';

import ResponsibleBettingDayLimitModal from 'components/HeaderAccountNavigation/components/DesktopResponsibleBetting/components/ResponsibleBettingDayLimitModal';
import ResponsibleBettingReminderModal from 'components/HeaderAccountNavigation/components/DesktopResponsibleBetting/components/ResponsibleBettingReminderModal';
import { getPropertiesDayLimitEnabled, getPropertiesReminderEnabled } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getIsResponsibleBettingDayLimitEnabled,
  getResponsibleBettingDayLimit,
  getResponsibleBettingDayLimitStartDate,
  getResponsibleBettingReminderEnabled,
  getResponsibleBettingReminderInterval,
  getResponsibleBettingReminderUnit
} from 'redux/modules/responsibleBetting/selectors';
import { getUserSessionInfoDayLimitPopupShown, getUserSessionInfoStartSessionDate } from 'redux/modules/user/selectors';

const ResponsibleBettingModals = () => {
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const startSessionDate = useSelector(getUserSessionInfoStartSessionDate);
  const dayLimitPopupShown = useSelector(getUserSessionInfoDayLimitPopupShown);
  const reminderTabEnabled = useSelector(getPropertiesReminderEnabled);
  const dayLimitTabEnabled = useSelector(getPropertiesDayLimitEnabled);
  const reminderUnit = useSelector(getResponsibleBettingReminderUnit);
  const dayLimitEnabled = useSelector(getIsResponsibleBettingDayLimitEnabled);
  const dayLimit = useSelector(getResponsibleBettingDayLimit);
  const dayLimitStartDate = useSelector(getResponsibleBettingDayLimitStartDate);
  const reminderEnabled = useSelector(getResponsibleBettingReminderEnabled);
  const reminderInterval = useSelector(getResponsibleBettingReminderInterval);

  const showReminderModal = reminderEnabled && reminderUnit && reminderInterval && reminderTabEnabled;
  const dayLimitReminderModal =
    dayLimitEnabled && dayLimit && dayLimitStartDate && dayLimitTabEnabled && !dayLimitPopupShown;

  if (isLoggedIn && startSessionDate) {
    return (
      <>
        {showReminderModal && <ResponsibleBettingReminderModal />}
        {dayLimitReminderModal && <ResponsibleBettingDayLimitModal />}
      </>
    );
  }

  return null;
};

export default ResponsibleBettingModals;
