export const BODY_ID = 'biab_body';
export const GOOGLE_FONTS_API = '//fonts.googleapis.com/css?family=';
export const DEFAULT_GOOGLE_FONT_WEIGHT = 400;

export const WEB_FONTS = {
  'Andale Mono': '"Andale Mono", AndaleMono, monospace',
  Arial: 'Arial, "Helvetica Neue", Helvetica, sans-serif',
  'Arial Black': '"Arial Black", "Arial Bold", Gadget, sans-serif',
  'Arial Narrow': '"Arial Narrow", Arial, sans-serif',
  'Arial Rounded MT Bold': '"Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif',
  'Avant Garde': '"Avant Garde", Avantgarde, "Century Gothic", CenturyGothic, "AppleGothic", sans-serif',
  Baskerville: 'Baskerville, "Baskerville Old Face", "Hoefler Text", Garamond, "Times New Roman", serif',
  'Big Caslon': '"Big Caslon", "Book Antiqua", "Palatino Linotype", Georgia, serif',
  'Bodoni MT': '"Bodoni MT", Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif',
  'Book Antiqua': '"Book Antiqua", Palatino, "Palatino Linotype", "Palatino LT STD", Georgia, serif',
  'Brush Script MT': '"Brush Script MT", cursive',
  Calibri: 'Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif',
  'Calisto MT':
    '"Calisto MT", "Bookman Old Style", Bookman, "Goudy Old Style", Garamond, "Hoefler Text", "Bitstream Charter", Georgia, serif',
  Cambrio: 'Cambria, Georgia, serif',
  Candara: 'Candara, Calibri, Segoe, "Segoe UI", Optima, Arial, sans-serif',
  'Century Gothic': '"Century Gothic", CenturyGothic, AppleGothic, sans-serif',
  Consolas: 'Consolas, monaco, monospace',
  Copperplate: 'Copperplate, "Copperplate Gothic Light", fantasy',
  'Courier New': '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
  Didot: 'Didot, "Didot LT STD", "Hoefler Text", Garamond, "Times New Roman", serif',
  'Franklin Gothic Medium': '"Franklin Gothic Medium", "Franklin Gothic", "ITC Franklin Gothic", Arial, sans-serif',
  Futura: 'Futura, "Trebuchet MS", Arial, sans-serif',
  Garamond: 'Garamond, Baskerville, "Baskerville Old Face", "Hoefler Text", "Times New Roman", serif',
  Geneva: 'Geneva, Tahoma, Verdana, sans-serif',
  Georgia: 'Georgia, Times, "Times New Roman", serif',
  'Gill Sans': '"Gill Sans", "Gill Sans MT", Calibri, sans-serif',
  'Goudy Old Style': '"Goudy Old Style", Garamond, "Big Caslon", "Times New Roman", serif',
  Helvetica: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  'Hoefler Text': '"Hoefler Text", "Baskerville old face", Garamond, "Times New Roman", serif',
  Impact:
    'Impact, Haettenschweiler, "Franklin Gothic Bold", Charcoal, "Helvetica Inserat", "Bitstream Vera Sans Bold", "Arial Black", sans serif',
  'Lucida Bright': '"Lucida Bright", Georgia, serif',
  'Lucida Console': '"Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace',
  'Lucida Sans Typewriter': '"Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace',
  'Lucida Grande': '"Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif',
  Monaco: 'Monaco, Consolas, "Lucida Console", monospace',
  Optima: 'Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif',
  Palatino: 'Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif',
  Papyrus: 'Papyrus, fantasy',
  Perpetua:
    'Perpetua, Baskerville, "Big Caslon", "Palatino Linotype", Palatino, "URW Palladio L", "Nimbus Roman No9 L", serif',
  Rockwell: 'Rockwell, "Courier Bold", Courier, Georgia, Times, "Times New Roman", serif',
  'Rockwell Extra Bold': '"Rockwell Extra Bold", "Rockwell Bold", monospace',
  'Segoe UI': '"Segoe UI", Frutiger, "Frutiger Linotype',
  Tahoma: 'Tahoma, Verdana, Segoe, sans-serif',
  'Times New Roman': 'TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif',
  'Trebuchet MS': '"Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif',
  Verdana: 'Verdana, Geneva, sans-serif'
};

export const GOOGLE_FONTS = [
  'Aclonica',
  'Allan',
  'Annie Use Your Telescope',
  'Anonymous Pro',
  'Allerta Stencil',
  'Allerta',
  'Amaranth',
  'Anton',
  'Architects Daughter',
  'Arimo',
  'Artifika',
  'Arvo',
  'Asset',
  'Astloch',
  'Bangers',
  'Bentham',
  'Bevan',
  'Bigshot One',
  'Bowlby One',
  'Bowlby One SC',
  'Brawler',
  'Buda 300',
  'Cabin',
  'Calligraffitti',
  'Candal',
  'Cantarell',
  'Cardo',
  'Carter One',
  'Caudex',
  'Cedarville Cursive',
  'Cherry Cream Soda',
  'Chewy',
  'Coda',
  'Coming Soon',
  'Copse',
  'Corben 700',
  'Cousine',
  'Covered By Your Grace',
  'Crafty Girls',
  'Crimson Text',
  'Crushed',
  'Cuprum',
  'Damion',
  'Dancing Script',
  'Dawning of a New Day',
  'Didact Gothic',
  'Droid Sans',
  'Droid Sans Mono',
  'Droid Serif',
  'EB Garamond',
  'Exo',
  'Expletus Sans',
  'Fontdiner Swanky',
  'Forum',
  'Francois One',
  'Geo',
  'Give You Glory',
  'Goblin One',
  'Goudy Bookletter 1911',
  'Gravitas One',
  'Gruppo',
  'Hammersmith One',
  'Holtwood One SC',
  'Homemade Apple',
  'Inconsolata',
  'Indie Flower',
  'IM Fell DW Pica',
  'IM Fell DW Pica SC',
  'IM Fell Double Pica',
  'IM Fell Double Pica SC',
  'IM Fell English',
  'IM Fell English SC',
  'IM Fell French Canon',
  'IM Fell French Canon SC',
  'IM Fell Great Primer',
  'IM Fell Great Primer SC',
  'Irish Grover',
  'Irish Growler',
  'Istok Web',
  'Josefin Sans',
  'Josefin Slab',
  'Judson',
  'Jura',
  'Jura 500',
  'Jura 600',
  'Just Another Hand',
  'Just Me Again Down Here',
  'Kameron',
  'Kenia',
  'Kranky',
  'Kreon',
  'Kristi',
  'La Belle Aurore',
  'Lato 100',
  'Lato 100italic',
  'Lato 300',
  'Lato',
  'Lato bold',
  'Lato 900',
  'League Script',
  'Lekton',
  'Limelight',
  'Lobster',
  'Lobster Two',
  'Lora',
  'Love Ya Like A Sister',
  'Loved by the King',
  'Luckiest Guy',
  'Maiden Orange',
  'Mako',
  'Maven Pro',
  'Maven Pro 500',
  'Maven Pro 700',
  'Maven Pro 900',
  'Meddon',
  'MedievalSharp',
  'Megrim',
  'Merriweather',
  'Metrophobic',
  'Michroma',
  'Miltonian Tattoo',
  'Miltonian',
  'Modern Antiqua',
  'Monofett',
  'Molengo',
  'Mountains of Christmas',
  'Muli 300',
  'Muli',
  'Neucha',
  'Neuton',
  'News Cycle',
  'Nixie One',
  'Nobile',
  'Noto Sans',
  'Nova Cut',
  'Nova Flat',
  'Nova Mono',
  'Nova Oval',
  'Nova Round',
  'Nova Script',
  'Nova Slim',
  'Nova Square',
  'Nunito light',
  'Nunito',
  'OFL Sorts Mill Goudy TT',
  'Old Standard TT',
  'Open Sans 300',
  'Open Sans',
  'Open Sans 600',
  'Open Sans 800',
  'Open Sans Condensed 300',
  'Orbitron',
  'Orbitron 500',
  'Orbitron 700',
  'Orbitron 900',
  'Oswald',
  'Over the Rainbow',
  'Reenie Beanie',
  'Pacifico',
  'Patrick Hand',
  'Paytone One',
  'Permanent Marker',
  'Philosopher',
  'Play',
  'Playfair Display',
  'Podkova',
  'PT Sans',
  'PT Sans Narrow',
  'PT Sans Narrow regular,bold',
  'PT Serif',
  'PT Serif Caption',
  'Puritan',
  'Quattrocento',
  'Quattrocento Sans',
  'Radley',
  'Raleway 100',
  'Redressed',
  'Roboto',
  'Rock Salt',
  'Rokkitt',
  'Ruslan Display',
  'Schoolbell',
  'Shadows Into Light',
  'Shanti',
  'Sigmar One',
  'Six Caps',
  'Slackey',
  'Smythe',
  'Sniglet 800',
  'Special Elite',
  'Stardos Stencil',
  'Sue Ellen Francisco',
  'Sunshiney',
  'Swanky and Moo Moo',
  'Syncopate',
  'Sylfaen',
  'Tangerine',
  'Tenor Sans',
  'Terminal Dosis Light',
  'The Girl Next Door',
  'Tinos',
  'Ubuntu',
  'Ultra',
  'Unkempt',
  'UnifrakturCook bold',
  'UnifrakturMaguntia',
  'Varela',
  'Varela Round',
  'Vibur',
  'Vollkorn',
  'VT323',
  'Waiting for the Sunrise',
  'Wallpoet',
  'Walter Turncoat',
  'Wire One',
  'Yanone Kaffeesatz',
  'Yanone Kaffeesatz 300',
  'Yanone Kaffeesatz 400',
  'Yanone Kaffeesatz 700',
  'Yeseva One',
  'Zeyada'
];
