import { useTranslation } from 'react-i18next';

import { useFormatCurrency } from 'hooks/useFormatCurrency';

interface PayoutProps {
  payout: number | string;
}

function Payout({ payout }: PayoutProps) {
  const { t } = useTranslation();

  const { noFormattedAmount: formattedPayout } = useFormatCurrency(payout || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  return (
    <div className="biab_liability ">
      <div className="biab_label">{t('betslip.labels.payout')}</div>
      <div className="biab_value">
        <strong>
          <a className="biab_mobile-tooltip biab_no-styles" />
          <span>{formattedPayout}</span>
        </strong>
      </div>
    </div>
  );
}

export default Payout;
