import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  VALIDATION_ERROR_BET_INVALID_PRICE,
  VALIDATION_ERROR_BET_INVALID_PRICE_LINE_MARKETS,
  VALIDATION_ERROR_BET_MAX_PRICE,
  VALIDATION_ERROR_BET_MAX_PRICE_LINE_MARKETS,
  VALIDATION_ERROR_BET_MIN_PRICE,
  VALIDATION_ERROR_BET_MIN_PRICE_LINE_MARKETS
} from 'constants/placement';
import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { BetTypes, TErrorMessage, TPrice } from 'types/bets';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { isLineBettingType, isTotalPointsLine } from 'utils/betslip';
import { adjustLineMarketValue, adjustValue, isValidValue, isValidValueInRange } from 'utils/betValidation';
import { getPriceMaxByType, getPriceMinByType, getPriceStepByType } from 'utils/price';

const usePriceValidation = ({
  price,
  betType,
  marketType,
  bettingType,
  lineRangeInfo,
  priceLadderDescription
}: {
  price: TPrice;
  betType: BetTypes;
  marketType: string;
  bettingType: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  priceLadderDescription?: TPriceLadderDescription | null;
}) => {
  const { t } = useTranslation();

  const [initValue, setValue] = useState<TPrice>(price);
  const [validValue, setValidValue] = useState<TPrice>();
  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>({ message: '' });
  const marketUnits = useMarketUnits(lineRangeInfo?.marketUnit ?? DEFAULT_MARKET_UNITS);

  const validate = useCallback(() => {
    const value = initValue !== undefined && initValue !== '' ? +initValue : 0;
    const isLineMarket = isLineBettingType(bettingType);
    const step = getPriceStepByType(value, marketType, bettingType, lineRangeInfo, priceLadderDescription);
    const floor = getPriceMinByType(step, marketType, bettingType, lineRangeInfo);
    const max = getPriceMaxByType(step, marketType, bettingType, lineRangeInfo);
    const isValidBet = isLineMarket ? isValidValueInRange(value, floor, step) : isValidValue(value, step, floor);
    const isRound = isLineMarket && !isTotalPointsLine(marketType);
    const isEmptyValue = initValue === '';

    if (isEmptyValue) {
      setIsValid(false);
      setValidValue('');
      setErrorMessage({
        message: ''
      });
    } else if (!isValidBet) {
      if ((value && value < floor) || !value) {
        setIsValid(false);
        setValidValue(floor);
        setErrorMessage({
          message: t(`${isLineMarket ? VALIDATION_ERROR_BET_MIN_PRICE_LINE_MARKETS : VALIDATION_ERROR_BET_MIN_PRICE}`, {
            units: marketUnits,
            min: floor
          })
        });
      } else {
        const adjustedValue = isLineMarket
          ? adjustLineMarketValue({ value, min: floor, max, step, betType, isRound })
          : adjustValue(value, step, floor, betType);

        setIsValid(false);
        setErrorMessage({
          message: t(
            `${isLineMarket ? VALIDATION_ERROR_BET_INVALID_PRICE_LINE_MARKETS : VALIDATION_ERROR_BET_INVALID_PRICE}`,
            {
              step,
              units: marketUnits,
              min: floor,
              max
            }
          )
        });

        if (adjustedValue > max) {
          setValidValue(max);
        } else if (adjustedValue < floor) {
          setValidValue(floor);
        } else {
          setValidValue(adjustedValue);
        }
      }
    } else if (value > max) {
      setIsValid(false);
      setValidValue(max);
      setErrorMessage({
        message: t(`${isLineMarket ? VALIDATION_ERROR_BET_MAX_PRICE_LINE_MARKETS : VALIDATION_ERROR_BET_MAX_PRICE}`, {
          units: marketUnits,
          max
        })
      });
    } else {
      setIsValid(true);
      setValidValue(value);
    }
  }, [
    betType,
    initValue,
    lineRangeInfo?.interval,
    lineRangeInfo?.maxUnitValue,
    lineRangeInfo?.minUnitValue,
    marketUnits
  ]);

  useEffect(() => {
    validate();
  }, [initValue, betType, validate]);

  return {
    setValue,
    validate,
    isValid,
    validValue,
    errorMessage,
    setErrorMessage
  };
};

export default usePriceValidation;
