import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import SettingsBetting from 'components/HeaderNavigation/components/SettingsBetting';
import SettingsFillOrKill from 'components/HeaderNavigation/components/SettingsFillOrKill';
import SettingsQuickBets from 'components/HeaderNavigation/components/SettingsQuickBets';
import useOnClickOutside from 'hooks/useOnClickOutside';
import {
  getDesktopSettingsConfirmBetsBeforePlace,
  getDesktopSettingsDefStake,
  getDesktopSettingsFillOrKill,
  getDesktopSettingsInlinePlacement,
  getDesktopSettingsInlinePlacementMiddleSection,
  getDesktopSettingsPlaceBetWithEnterKey,
  getDesktopSettingsQuickstakeBetslip,
  getDisplayIconsInSubHeader,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';
import { SettingsDisplayedContent } from 'types/navigation';

import styles from './styles.module.scss';

const CONTENT_COMPONENTS = {
  [SettingsDisplayedContent.QUICK_BETS]: () => <SettingsQuickBets />,
  [SettingsDisplayedContent.FILL_OR_KILL]: () => <SettingsFillOrKill />,
  [SettingsDisplayedContent.BETTING]: () => <SettingsBetting />,
  [SettingsDisplayedContent.NO_ITEMS]: () => null
};

const DesktopNavigationSettings = () => {
  const { t } = useTranslation();

  const displayIconsInSubHeader = useSelector(getDisplayIconsInSubHeader);
  const fillOrKill = useSelector(getDesktopSettingsFillOrKill);
  const quickstakeBetslip = useSelector(getDesktopSettingsQuickstakeBetslip);
  const inlinePlacement = useSelector(getDesktopSettingsInlinePlacement);
  const inlinePlacementMiddleSection = useSelector(getDesktopSettingsInlinePlacementMiddleSection);
  const defStake = useSelector(getDesktopSettingsDefStake);
  const placeBetWithEnterKey = useSelector(getDesktopSettingsPlaceBetWithEnterKey);
  const confirmBetsBeforePlace = useSelector(getDesktopSettingsConfirmBetsBeforePlace);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isQuickStakesEnabled = quickstakeBetslip && (exchangeSportsEnabled || exchangeGamesEnabled);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayedContent, setDisplayedContent] = useState<SettingsDisplayedContent>(SettingsDisplayedContent.NO_ITEMS);

  const isShowBetting =
    (inlinePlacement && inlinePlacementMiddleSection) || defStake || confirmBetsBeforePlace || placeBetWithEnterKey;

  useEffect(() => {
    if (isQuickStakesEnabled) {
      setDisplayedContent(SettingsDisplayedContent.QUICK_BETS);
    } else if (fillOrKill) {
      setDisplayedContent(SettingsDisplayedContent.FILL_OR_KILL);
    } else if (isShowBetting) {
      setDisplayedContent(SettingsDisplayedContent.BETTING);
    }
  }, [fillOrKill, isShowBetting, isQuickStakesEnabled]);

  const items = [
    {
      label: 'account.settings.quickBets.title',
      value: SettingsDisplayedContent.QUICK_BETS,
      isVisible: isQuickStakesEnabled
    },
    {
      label: 'account.settings.fillOrKill.title',
      value: SettingsDisplayedContent.FILL_OR_KILL,
      isVisible: fillOrKill
    },
    {
      label: 'account.settings.betting.title',
      value: SettingsDisplayedContent.BETTING,
      isVisible: isShowBetting
    }
  ];

  const rootRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(rootRef, () => setIsOpen(false));

  return (
    <div ref={rootRef} className={classNames('biab_settings-wrapper', styles.settings)}>
      <button
        className={classNames('biab_header-nav-btn', styles.settings__title, {
          biab_active: isOpen
        })}
        onClick={() => setIsOpen(prevState => !prevState)}
      >
        {displayIconsInSubHeader && <i className="biab_navigation-submenu-icon fa2 fa2-cog" aria-hidden="true" />}
        <span>{t('header.settings')}</span>
        <i className={classNames(`fa2 fa2-arrow-${isOpen ? 'up' : 'down'}`)} />
      </button>
      {isOpen && (
        <div className={classNames('biab_nav_tabs', styles.settings__window)}>
          <div className={classNames('biab_nav_tabs-content', styles.settings__window__content)}>
            <aside className={classNames('biab_nav_tabs-list', styles.settings__window__menu)}>
              {items.map(({ label, value, isVisible }) => {
                if (!isVisible) {
                  return null;
                }

                return (
                  <span
                    key={value}
                    onClick={() => setDisplayedContent(value)}
                    className={classNames('biab_nav-tabs-item', styles.settings__window__menu__item, {
                      biab_active: value === displayedContent,
                      [styles.settings__window__menu__item__active]: value === displayedContent
                    })}
                  >
                    {t(label)}
                  </span>
                );
              })}
            </aside>
            <section className={classNames('biab_settings-content', styles.settings__window__displayedContent)}>
              {CONTENT_COMPONENTS[displayedContent]()}
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default DesktopNavigationSettings;
