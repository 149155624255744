import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import TotalLiability from 'components/AsianViewBetslip/components/TotalLiability';
import { DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS } from 'constants/asianView';
import { ASIAN_VIEW_PLACE_BET_ERRORS_IDS } from 'constants/betslip';
import { asianViewComponents as branding } from 'constants/branding';
import { LOADING_TIMEOUT_MESSAGE } from 'constants/placement';
import useAsianPlacementPage from 'hooks/useAsianPlacementPage';
import useConfirmBets from 'hooks/useConfirmBets';
import { usePlacementParams } from 'hooks/usePlacementParams';
import usePostMessage from 'hooks/usePostMessage';
import { usePreviousValue } from 'hooks/usePrevious';
import { getBetslipSpinnerTime } from 'redux/modules/appConfigs/selectors';
import { getOddsType } from 'redux/modules/appSettings/selectors';
import {
  placeAllSelectedBets,
  removeAllSelectedBets,
  setBetSlipValidationMessageForAllSelectedBets,
  setPlaceBetsState,
  setStatusesOfferIds
} from 'redux/modules/asianViewBetslip';
import {
  getAreAsianViewBetsStatusesLoaded,
  getAsianSelectedBetsList,
  getAsianViewActiveSelectedBetsAmount,
  getAsianViewBetsStatusesLoading,
  getAsianViewIsAtLeastOnePendingStatus,
  getPlaceBetsLoading,
  getPlaceBetsState
} from 'redux/modules/asianViewBetslip/selectors';
import { TAsianPlaceBet, TAsianSelectedBet } from 'redux/modules/asianViewBetslip/type';
import { fetchAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { EPlaceBetsStates } from 'redux/modules/betslip/type';
import { getUserAsianViewAutoRefresh } from 'redux/modules/user/selectors';
import { EPersistenceTypes } from 'types/bets';
import { getAsianViewBetUuid } from 'utils/asianView';

import styles from './styles.module.scss';

const AsianBetSlipTotalLiabilityAndActions = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const betsAmount = useSelector(getAsianViewActiveSelectedBetsAmount);
  const placeBetsState = useSelector(getPlaceBetsState);
  const selectedBets = useSelector(getAsianSelectedBetsList);
  const hasAutoRefresh = useSelector(getUserAsianViewAutoRefresh);
  const oddsType = useSelector(getOddsType);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const betStatusesLoading = useSelector(getAsianViewBetsStatusesLoading);
  const isAtLeastOnePendingStatus = useSelector(getAsianViewIsAtLeastOnePendingStatus);
  const areBetsStatusesLoaded = useSelector(getAreAsianViewBetsStatusesLoaded);
  const spinnerTime = useSelector(getBetslipSpinnerTime);
  const placeBetsLoading = useSelector(getPlaceBetsLoading);

  const asianViewPage = useAsianPlacementPage(selectedBets.length ? selectedBets[0].marketLink : '');

  const [betStatusesInProgress, setBetStatusesInProgress] = useState(false);

  const placementParams = usePlacementParams();
  const { isConfirmBetsBeforePlacement } = useConfirmBets();
  const { placeBetLogin } = usePostMessage();
  const prevBetsAmount = usePreviousValue<number>(betsAmount);

  const betStatusesLoadingRef = useRef(betStatusesLoading);
  betStatusesLoadingRef.current = betStatusesLoading;

  const { isPlacementValid, activeSelectedBets, areLowerPrices, availableForPlacementBets } = useMemo(() => {
    let isPlacementValidValue = true;
    const activeSelectedBetsArr: TAsianSelectedBet[] = [];
    const availableForPlacementBetsArr: TAsianSelectedBet[] = [];
    let areLowerPricesValue = false;

    selectedBets.forEach(bet => {
      isPlacementValidValue =
        isPlacementValidValue &&
        (!bet.isDisabled
          ? !!bet.profit &&
            !!bet.size &&
            Number(bet.profit) !== 0 &&
            Number(bet.size) !== 0 &&
            !bet.validationMessage &&
            bet.isValid !== false
          : true);

      if (!bet.isDisabled) {
        activeSelectedBetsArr.push(bet);
      }

      if (!bet.offerId && bet.isLowerPrice && !areLowerPricesValue && hasAutoRefresh) {
        areLowerPricesValue = true;
      }

      if (!bet.bettingAction && !bet.offerId) {
        availableForPlacementBetsArr.push(bet);
      }
    });

    return {
      isPlacementValid: isPlacementValidValue,
      activeSelectedBets: activeSelectedBetsArr,
      areLowerPrices: areLowerPricesValue,
      availableForPlacementBets: availableForPlacementBetsArr
    };
  }, [selectedBets, hasAutoRefresh]);

  const isDisabledPlacingBets = !isPlacementValid || activeSelectedBets.length === 0 || placeBetsLoading;

  const placeSelectedBets = () => {
    const data: { [key: string]: TAsianPlaceBet[] } = {};

    activeSelectedBets.forEach(s => {
      if (!data[s.marketId] && !s.offerId) {
        data[s.marketId] = [];
      }

      const adjHandicapParam = !isUndefined(s.adjHandicap) ? { adjHandicap: s.adjHandicap } : {};

      if (!s.isDisabled && !s.offerId) {
        data[s.marketId].push({
          selectionId: s.selectionId,
          handicap: s.handicap,
          price: hasAutoRefresh ? s.actualPrice || s.price : s.price,
          size: s.size,
          side: s.betType,
          page: asianViewPage,
          persistenceType: EPersistenceTypes.LAPSE,
          placedUsingEnterKey: false,
          isAsian: true,
          oddsType,
          numberOfPlacementAttempts: s.placementAttempt || DEFAULT_NUMBER_OF_PLACEMENT_ATTEMPTS,
          placedOnMainCoupon: !s.isExpandedCoupon,
          placedOnExpandedCoupon: s.isExpandedCoupon,
          score: s.score,
          betUuid: getAsianViewBetUuid({
            marketId: s.marketId,
            selectionId: s.selectionId,
            handicap: s.handicap,
            betType: s.betType
          }),
          ...adjHandicapParam,
          ...placementParams
        });
      }
    });

    dispatch(
      placeAllSelectedBets({
        data,
        onSuccessCallback: (offerIds: number[]) => {
          if (offerIds.length) {
            setBetStatusesInProgress(true);
            dispatch(setStatusesOfferIds(offerIds));
          }
        },
        onErrorCallback: () => {
          dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
        }
      })
    );
  };

  const handlePlaceBet = () => {
    if (!isLoggedIn) {
      placeBetLogin();
      return;
    }
    if (isConfirmBetsBeforePlacement) {
      dispatch(setPlaceBetsState(EPlaceBetsStates.CONFIRM));
      return;
    }
    placeSelectedBets();
  };

  const handleRemoveAll = () => {
    if (!isLoggedIn) {
      onRemoveAll();
      return;
    }

    dispatch(setPlaceBetsState(EPlaceBetsStates.CONFIRM_REMOVING));
  };

  const onRemoveAll = () => {
    dispatch(removeAllSelectedBets());
    dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
  };

  const onEditBets = () => {
    dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
  };

  useEffect(() => {
    if (placeBetsState === EPlaceBetsStates.CONFIRM_REMOVING && betsAmount > 0) {
      onEditBets();
    }

    if ((betsAmount === 0 || prevBetsAmount !== betsAmount) && placeBetsState !== EPlaceBetsStates.SELECT) {
      dispatch(setPlaceBetsState(EPlaceBetsStates.SELECT));
    }
  }, [betsAmount]);

  useEffect(() => {
    if (betStatusesInProgress) {
      const validationTimeout = setTimeout(() => {
        dispatch(
          setBetSlipValidationMessageForAllSelectedBets({
            message: t(LOADING_TIMEOUT_MESSAGE),
            sizeValidationType: null,
            messageId: ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX020
          })
        );
      }, Number(spinnerTime) * 1000);

      return () => {
        clearTimeout(validationTimeout);
      };
    }
  }, [betStatusesInProgress]);

  useEffect(() => {
    if (!isAtLeastOnePendingStatus && areBetsStatusesLoaded) {
      setBetStatusesInProgress(false);
      dispatch(fetchAsianCurrentBets());
    }
  }, [isAtLeastOnePendingStatus, areBetsStatusesLoaded]);

  if (availableForPlacementBets.length === 0 || isAtLeastOnePendingStatus) {
    return null;
  }

  const renderButtons = () => {
    if (placeBetsState === EPlaceBetsStates.SELECT) {
      return (
        <>
          <button
            className={classNames(branding.PRIMARY_BTN, styles.placeButton, {
              [styles.placeButton__lowerPrices]: areLowerPrices
            })}
            disabled={isDisabledPlacingBets}
            onClick={handlePlaceBet}
          >
            {areLowerPrices
              ? t('asianView.labels.placementActions.acceptLowerPrices')
              : betsAmount > 1
              ? t('asianView.labels.placementActions.placeMultiple', { betsAmount })
              : betsAmount
              ? t('asianView.labels.placementActions.placeSingle')
              : t('asianView.labels.placementActions.placeBet')}
          </button>
          <button
            className={classNames(branding.SECONDARY_BTN, styles.buttonGray)}
            onClick={isConfirmBetsBeforePlacement ? handleRemoveAll : onRemoveAll}
          >
            {t('asianView.labels.placementActions.removeAllBets')}
          </button>
        </>
      );
    } else if (placeBetsState === EPlaceBetsStates.CONFIRM_REMOVING) {
      return (
        <>
          <button className={classNames(branding.PRIMARY_BTN, styles.placeButton)} onClick={onEditBets}>
            {t('asianView.labels.placementActions.cancel')}
          </button>
          <button
            className={classNames(branding.SECONDARY_BTN, styles.buttonGray, styles.confirmRemoving)}
            onClick={onRemoveAll}
          >
            {t('asianView.labels.placementActions.confirmRemoving')}
          </button>
        </>
      );
    }

    return (
      <>
        <button
          className={classNames(branding.PRIMARY_BTN, styles.placeButton, styles.confirmBtn)}
          disabled={isDisabledPlacingBets}
          onClick={placeSelectedBets}
        >
          {betsAmount > 1
            ? t('asianView.labels.placementActions.confirmBetsMultiple', { betsAmount })
            : t('asianView.labels.placementActions.confirmBetsSingle')}
        </button>
        <button className={classNames(branding.SECONDARY_BTN, styles.buttonGray)} onClick={onEditBets}>
          {t('asianView.labels.placementActions.editBets')}
        </button>
      </>
    );
  };

  return (
    <>
      <TotalLiability />
      <div className={styles.main}>{renderButtons()}</div>
    </>
  );
};

export default AsianBetSlipTotalLiabilityAndActions;
