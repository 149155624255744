import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { BETTING_TYPES } from 'constants/app';
import { tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { BETSLIP_FULL_AVG_PRICE, BETSLIP_LM_BACKMATCHED, BETSLIP_LM_LAYMATCHED } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrencyPrecisionValue } from 'hooks/usePrecision';
import { usePreviousValue } from 'hooks/usePrevious';
import useTooltip from 'hooks/useTooltip';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getBetslipType } from 'redux/modules/betslip/selectors';
import { EBetErrors, EBetFocusFields, EPlaceBetsStates } from 'redux/modules/betslip/type';
import { getPrecisionType } from 'redux/modules/user/selectors';
import { BetTypes, TPrice, TProfit, TSize } from 'types/bets';
import { EBetslipTypes, EFormActions, EInputFieldTypes } from 'types/betslip';
import { TMarketLineRangeInfo, TMarketPrice, TPriceLadderDescription } from 'types/markets';
import { getBestPriceFromMarketPrices } from 'utils/betslip';
import { calculateLiability, calculateSize, roundFloat } from 'utils/liability';

import BetIndicator from './components/BetIndicator';
import BetPrice from './components/BetPrice';
import BetProfit from './components/BetProfit';
import BetSize from './components/BetSize';

import styles from './styles.module.scss';

interface IBetFormProps {
  mode: EPlaceBetsStates;
  marketId?: string;
  betPrice: TPrice;
  betAveragePrice?: TPrice;
  betSize: TSize;
  betProfit?: number | string | null;
  betType: BetTypes;
  currency?: string;
  bettingType: string;
  marketType: string;
  betError?: EBetErrors | null;
  marketPrice?: TMarketPrice | null;
  lineRangeInfo: TMarketLineRangeInfo | null;
  eachWayDivisor: number | null;
  priceLadderDescription: TPriceLadderDescription | null;
  totalTabFields?: number;
  betIndex?: number;
  focusedField?: EBetFocusFields | null;
  onFormChanged: (price: TPrice, size: TSize, profit: TProfit, isPriceValid: boolean, isSizeValid: boolean) => void;
  onEnterClick: () => void;
}

const BetForm = ({
  mode,
  marketId,
  betPrice,
  betAveragePrice,
  betSize,
  betProfit,
  betType,
  betError,
  currency,
  bettingType,
  marketType,
  marketPrice,
  lineRangeInfo,
  eachWayDivisor,
  priceLadderDescription,
  totalTabFields = 0,
  betIndex = 0,
  focusedField,
  onFormChanged,
  onEnterClick
}: IBetFormProps) => {
  const { t } = useTranslation();

  const precisionType = useSelector(getPrecisionType);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const betslipType = useSelector(getBetslipType);

  const [price, setPrice] = useState(betPrice);
  const [prevPrice, setPrevPrice] = useState(betPrice);
  const [size, setSize] = useState(betSize);
  const [profit, setProfit] = useState<TProfit>(betProfit);
  const [isPriceValid, setIsPriceValid] = useState(false);
  const [isSizeValid, setIsSizeValid] = useState(false);
  const [formAction, setFormAction] = useState(EFormActions.NONE);

  const precision = getCurrencyPrecisionValue(precisionType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const isTakeOffer = isPNCEnabled && mode === EPlaceBetsStates.TAKE_OFFER;
  const bestPrice = getBestPriceFromMarketPrices(marketPrice, marketType, bettingType);
  const isPriceIndicatorEnabled = !isPNCEnabled || isGameBetslip;

  const tooltipText =
    bettingType === BETTING_TYPES.odds
      ? BETSLIP_FULL_AVG_PRICE
      : betType === BetTypes.BACK
      ? BETSLIP_LM_BACKMATCHED
      : BETSLIP_LM_LAYMATCHED;

  const { translationKey } = useTooltip(tooltipText);
  const prevBestPrice = usePreviousValue(bestPrice);

  useEffect(() => {
    setPrice(betPrice);
    setSize(betSize);
  }, [marketId, betSize, betPrice]);

  useEffect(() => {
    if (bestPrice !== prevBestPrice) {
      setPrevPrice(prevBestPrice);
    }
  }, [bestPrice, prevBestPrice]);

  useEffect(() => {
    if (formAction === EFormActions.VALIDATE) {
      setFormAction(EFormActions.NONE);
    }
  }, [formAction]);

  useEffect(() => {
    if ([EPlaceBetsStates.SELECT, EPlaceBetsStates.TAKE_OFFER].includes(mode)) {
      if (price && size && formAction !== EFormActions.PROFIT_EDIT) {
        setProfit(calculateLiability(price, size, { marketType, bettingType, betType, eachWayDivisor }));
      } else if (price === '' || size === '') {
        if (formAction === EFormActions.VALIDATE) {
          setProfit(betProfit);
          setSize(betSize);
        } else {
          setProfit('');
        }
      }
    }
  }, [profit, size, mode, price, marketType, bettingType, betType, eachWayDivisor, formAction]);

  const onPriceValidate = (changedIsValidPrice: boolean) => {
    setIsPriceValid(changedIsValidPrice);
  };

  const onSizeValidate = (changedIsValidSize: boolean) => {
    setIsSizeValid(changedIsValidSize);
  };

  const onProfitChanged = (profitChanged: TProfit) => {
    setFormAction(EFormActions.PROFIT_EDIT);
    setSize(calculateSize(price, profitChanged));
    setProfit(profitChanged);
  };

  useEffect(() => {
    onFormChanged(price, size, profit, isPriceValid, isSizeValid);
  }, [price, size, profit, isPriceValid, isSizeValid]);

  useEffect(() => {
    const newPrice = getBestPriceFromMarketPrices(marketPrice, marketType, bettingType);

    if (isTakeOffer) {
      setPrice(newPrice);
    }
  }, [bettingType, isTakeOffer, marketPrice, marketType]);

  const { noFormattedAmount: sizeFormatted } = useFormatCurrency(roundFloat(size ?? 0, precision), currency ?? '', {
    noCommas: true,
    noRounding: true
  });

  const { noFormattedAmount: profitFormatted } = useFormatCurrency(roundFloat(profit ?? 0, precision), currency ?? '', {
    noCommas: true,
    noRounding: true
  });

  const tooltipParams =
    bettingType === BETTING_TYPES.odds
      ? { N: betAveragePrice || price || '' }
      : {
          R: betAveragePrice || price || '',
          UNITS: 'runs'
        };

  const getFieldOrder = (order = 0) => betIndex * totalTabFields + order;

  return (
    <div
      className={classNames(styles.betForm, {
        [styles.betForm__readOnly]: mode === EPlaceBetsStates.CONFIRM,
        [branding.OPENED_BET_HEADER]: mode === EPlaceBetsStates.CONFIRM
      })}
    >
      {mode === EPlaceBetsStates.SELECT ? (
        <>
          <div className={styles.betPriceWrap}>
            {isPriceIndicatorEnabled && (
              <BetIndicator
                price={price}
                betType={betType}
                bestPrice={bestPrice}
                onChange={() => setPrice(bestPrice)}
              />
            )}
            <BetPrice
              price={price}
              betType={betType}
              betError={betError}
              bettingType={bettingType}
              marketType={marketType}
              bestPrice={bestPrice}
              lineRangeInfo={lineRangeInfo}
              priceLadderDescription={priceLadderDescription}
              onChangePrice={setPrice}
              onValidate={onPriceValidate}
              onEnterClick={onEnterClick}
              fieldOrder={getFieldOrder(tabulation.PRICE_ORDER)}
              isFocus={focusedField === EBetFocusFields.PRICE}
            />
          </div>
          <div>
            <BetSize
              value={size}
              betType={betType}
              currency={currency}
              onChange={setSize}
              onValidate={onSizeValidate}
              onEnterClick={onEnterClick}
              formAction={formAction}
              fieldOrder={getFieldOrder(tabulation.SIZE_ORDER)}
              isFocus={focusedField === EBetFocusFields.SIZE}
            />
          </div>
          <div>
            <BetProfit
              betProfit={profit}
              onChanged={onProfitChanged}
              onEnterClick={onEnterClick}
              onBlur={() => setFormAction(EFormActions.VALIDATE)}
              fieldOrder={getFieldOrder(tabulation.PROFIT_ORDER)}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.betPriceWrap}>
            {isTakeOffer && <BetIndicator price={prevPrice} betType={betType} bestPrice={bestPrice} />}
            <span
              data-tooltip-id="tooltip"
              data-tooltip-html={unescape(t(translationKey, { ...tooltipParams }))}
              data-field-type={EInputFieldTypes.PRICE}
              className="cursor-help"
            >
              {price}
            </span>
          </div>
          <div>
            <span data-field-type={EInputFieldTypes.SIZE}>{sizeFormatted}</span>
          </div>
          <div>
            <span data-field-type={EInputFieldTypes.PROFIT}>{profitFormatted}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(BetForm);
