import { createSelector } from '@reduxjs/toolkit';
import { filter, reduce, reverse, sortBy, toNumber } from 'lodash';

import { EXCHANGE, GAME } from 'constants/app';
import { getBetslipMarketFilter, getBetslipMatchedFilter } from 'redux/modules/betslip/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCurrentGameMarket } from 'redux/modules/games/selectors';
import { getSingleMarketInfo } from 'redux/modules/market/selectors';
import { AppState } from 'redux/reducers';
import { BetTypes, MatchTypes } from 'types/bets';
import { BettingType } from 'types/markets';
import { isCancelled } from 'utils/betslip';
import { isMatchedOffer, isUnmatchedOffer, mapBet } from 'utils/currentBets';

import { ECurrentBetActions, TCurrentBet } from './type';

const getCurrentBets = ({ currentBets }: AppState) => currentBets;

export const getCurrentBetsByExchangeType = (isGameType?: boolean) =>
  createSelector(
    getCurrentBetsByExchangeFiltered(isGameType),
    getBetslipMarketFilter,
    getSingleMarketInfo,
    getCurrentGameMarket,
    (offers, marketFilter, market, gameMarket) =>
      filter(offers, {
        betType: isGameType ? GAME : EXCHANGE,
        ...(!isGameType && !!marketFilter && market ? { marketId: market?.marketId } : {}),
        ...(isGameType && !!marketFilter && gameMarket && gameMarket.id
          ? { marketId: String(gameMarket.id) ?? '' }
          : {})
      })
  );

export const getCurrentBetsByExchangeFiltered = (isGameType?: boolean) =>
  createSelector(getCurrentBets, currentBets => filter(currentBets.offers, { betType: isGameType ? GAME : EXCHANGE }));

export const getLoading = ({ currentBets }: AppState) => currentBets.loading;

export const getOffers = ({ currentBets }: AppState) => currentBets.offers;

export const getCurrentBetsLoading = ({ currentBets }: AppState) => currentBets.loading && currentBets.isFirstLoad;

export const getCurrentBetsAmount = ({
  isGameType,
  isMatchedFilter
}: {
  isGameType?: boolean;
  isMatchedFilter?: boolean;
}) =>
  createSelector(
    getCurrentBetsByType(MatchTypes.UNMATCHED, isGameType),
    getCurrentBetsByType(MatchTypes.MATCHED, isGameType),
    getBetslipMatchedFilter,
    (unmatchedOffers, matchedOffers, isUnmatchedBetsOnly) =>
      Object.keys(unmatchedOffers).length +
      (!isMatchedFilter || !isUnmatchedBetsOnly ? Object.keys(matchedOffers).length : 0)
  );
/**
 * Get current bet by offerId
 *
 * @param offerId
 */
export const getCurrentBetByOfferId =
  (offerId: number) =>
  ({ currentBets }: AppState) =>
    currentBets.offers[offerId];

/**
 * Get current bets by old offerId
 *
 * @param oldOfferId
 */
export const getCurrentBetsByOldOfferId = (oldOfferId: number) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(bet.oldOfferId === oldOfferId ? [mapBet(bet)] : [])];
      },
      []
    )
  );

/**
 * Get MATCHED or UNMATCHED offers list by selection
 *
 * @param marketId
 * @param selectionId
 * @param handicap
 * @param type
 */
export const getCurrentSelectionBetsByType = (
  marketId: string,
  selectionId: number,
  handicap: number,
  type: MatchTypes
) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(bet.marketId === marketId &&
          bet.selectionId === selectionId &&
          bet.handicap == handicap &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED && isUnmatchedOffer(bet)))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

/**
 * Get MATCHED or UNMATCHED offers list by event id
 *
 * @param eventId
 * @param type
 */
export const getCurrentEventBetsByType = (eventId: string, type: MatchTypes) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(bet.eventId === eventId &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED && isUnmatchedOffer(bet)))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

export const getCurrentBetsError = ({ currentBets }: AppState) => currentBets.error;

export const getCurrentBetsString = ({ currentBets }: AppState) => currentBets.currentBetsString;
export const getCurrentBetsList = ({ currentBets }: AppState) => currentBets.currentBetsList;

/**
 * Get MATCHED or UNMATCHED offers list by selection
 *
 * @param type
 * @param marketId
 * @param selectionId
 * @param handicap
 */
export const getCurrentBetsBySelection = (
  type: MatchTypes,
  marketId: string,
  selectionId?: number,
  handicap?: number
) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        const isValidSelection = !selectionId || (selectionId && bet.selectionId === selectionId);
        const isValidHandicap = !handicap || (handicap && bet.handicap == handicap);
        return [
          ...res,
          ...(bet.marketId === marketId &&
          isValidSelection &&
          (isValidHandicap || bet.bettingType == BettingType.LINE) &&
          !(
            (bet.action === ECurrentBetActions.CANCELLING || bet.action === ECurrentBetActions.CANCELLING_ALL) &&
            isCancelled(bet)
          ) &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED && isUnmatchedOffer(bet) && bet.action !== ECurrentBetActions.FULLY_MATCHED))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

/**
 * Get MATCHED or UNMATCHED offers list
 *
 * @param type
 * @param isGameType
 * @param ignoreCancelled
 */
export const getCurrentBetsByType = (type: MatchTypes, isGameType?: boolean, ignoreCancelled?: boolean) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...((!ignoreCancelled || !(ignoreCancelled && bet.offerState === BetsStatusesTypes.CANCELLED)) &&
          ((type === MatchTypes.MATCHED && isMatchedOffer(bet)) ||
            (type === MatchTypes.UNMATCHED && isUnmatchedOffer(bet)))
            ? [mapBet(bet)]
            : [])
        ];
      },
      []
    )
  );

/**
 * Get MATCHED or UNMATCHED asian view offers list
 *
 * @param type
 * @param isGameType
 * @param ignoreCancelled
 */

/**
 * Get offers by BACK/LAY side and MATCHED/UNMATCHED type
 *
 * @param side
 * @param type
 * @param isConsolidateBets
 * @param isGameType
 */
export const getCurrentBetsBySideType = (
  side: BetTypes,
  type: MatchTypes,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers => {
    const openedBets = reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [...res, ...(bet.side === side ? [mapBet(bet)] : [])];
      },
      []
    ).filter(bet => {
      return type === MatchTypes.MATCHED ? isMatchedOffer(bet) : isUnmatchedOffer(bet);
    });

    if (type === MatchTypes.MATCHED && isConsolidateBets && !isGameType) {
      return mapConsolidateBets(openedBets);
    } else {
      return openedBets;
    }
  });

/**
 * Get offers sorted by placedDate
 *
 * @param isGameType
 */
export const getSortedCurrentBets = (isGameType?: boolean) =>
  createSelector(getCurrentBetsByExchangeType(isGameType), offers =>
    reduce(
      reverse(sortBy(offers, 'placedDate')),
      (res: TCurrentBet[], bet: TCurrentBet) => {
        return [
          ...res,
          ...(isUnmatchedOffer(bet) ? [{ ...mapBet(bet), matchType: MatchTypes.UNMATCHED }] : []),
          // if bet is partially matched show both parts
          ...(isMatchedOffer(bet) ? [{ ...mapBet(bet), matchType: MatchTypes.MATCHED }] : [])
        ];
      },
      []
    )
  );

/**
 * Get offers count
 *
 * @param state
 */
export const getCurrentBetsCount = createSelector(getOffers, offers => Object.keys(offers).length);

/**
 * Combine bets by marketId, selectionId, handicap, averagePrice (or price).
 * Calculate sum of size, profit and liability fields.
 *
 * @param openedBets
 */
const mapConsolidateBets = (openedBets: TCurrentBet[]) => {
  const groupedBets = reduce(
    openedBets,
    (res: Record<string, TCurrentBet[]>, item) => {
      const key = `${item.marketId}-${item.selectionId}-${toNumber(item.handicap || 0)}-${item.side}`;
      const avPriceKey = `${key}-${item.averagePrice}`;
      const priceKey = `${key}-${item.price}`;

      return {
        ...res,
        ...(res[avPriceKey]
          ? { [avPriceKey]: [...res[avPriceKey], item] }
          : res[priceKey]
          ? { [priceKey]: [...res[priceKey], item] }
          : { [avPriceKey]: [item] })
      };
    },
    {}
  );

  return reduce(
    groupedBets,
    (res: TCurrentBet[], betsList) => {
      return [
        ...res,
        ...(betsList[0]
          ? [
              {
                ...betsList[0],
                ...{
                  isCombined: true,
                  averagePrice: betsList[0].averagePriceRounded,
                  ...[
                    'liability',
                    'profit',
                    'profitNet',
                    'potentialProfit',
                    'size',
                    'sizeMatched',
                    'sizePlaced',
                    'totalWinnings'
                  ].reduce((calculatedFields, field) => {
                    return {
                      ...calculatedFields,
                      ...{
                        [field]: betsList.reduce((totalValue: number, value: TCurrentBet) => {
                          return totalValue + toNumber(value[field as keyof TCurrentBet] || 0);
                        }, 0)
                      }
                    };
                  }, {})
                }
              }
            ]
          : [])
      ];
    },
    []
  );
};

export const isMarketHasOffers = (marketId?: string) =>
  createSelector(getOffers, offers =>
    marketId ? !!Object.values(offers).find(offer => offer.marketId === marketId) : null
  );

export const getCurrentSelectionBetsByTypeForWhatIf = (marketId: string, handicap: number, type: MatchTypes) =>
  createSelector(getOffers, offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        if (
          bet.marketId === marketId &&
          bet.handicap == handicap &&
          type === MatchTypes.UNMATCHED &&
          isUnmatchedOffer(bet) &&
          bet.changedSize &&
          bet.changedPrice
        ) {
          res.push(bet);
        }

        return res;
      },
      []
    )
  );

export const getUnMatchMarket = createSelector(
  getOffers,
  offers =>
    reduce(
      offers,
      (res: TCurrentBet[], bet: TCurrentBet) => {
        if (isUnmatchedOffer(bet)) {
          res.push(bet);
        }

        return res;
      },
      []
    ).length
);

export const getPlacedCashOut = (offerId: number | null) => (state: AppState) =>
  offerId ? state.currentBets.offers[offerId] : null;

export const getCurrentBetsBySideTypeLengthByMarketId = (
  marketId: string,
  side: BetTypes,
  type: MatchTypes,
  isConsolidateBets = false,
  isGameType?: boolean
) =>
  createSelector(
    getCurrentBetsBySideType(side, type, isConsolidateBets, isGameType),
    bets => bets.filter(bet => bet.marketId === marketId).length
  );

export const getIsAllCurrentBetsCancelling = (curMarketId?: string, isGameType?: boolean) =>
  createSelector(getCurrentBetsByType(MatchTypes.UNMATCHED, isGameType, true), offers =>
    offers.some(
      ({ marketId, action }) =>
        (!curMarketId || marketId === curMarketId) && action === ECurrentBetActions.CANCELLING_ALL
    )
  );
