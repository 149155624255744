import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ASIAN_VIEW_DELETE_MATCHED_BETS_TIMEOUT, DESKTOP_NAVIGATION_HEIGHT } from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import { getElementHeight, getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { removeSelectedBets } from 'redux/modules/asianViewBetslip';
import {
  getAsianSelectedBetsAmount,
  getAsianViewMatchedSelectedBetsIdentifiers
} from 'redux/modules/asianViewBetslip/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';

import AVSelectedBets from '../AVSelectedBets';
import AVTotalLiabilityAndActionsAndSettings from '../AVTotalLiabilityAndActionsAndSettings/AVTotalLiabilityAndActionsAndSettings';

import styles from './styles.module.scss';

const TOP_AND_BOTTOM_PADDINGS = 20;

const BetSlipTabContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const betsAmount = useSelector(getAsianSelectedBetsAmount);
  const matchedSelectedBets = useSelector(getAsianViewMatchedSelectedBetsIdentifiers);
  const scrollContentHeight = useSelector(getScrollContentHeight);
  const favouritesHeight = useSelector(getElementHeight(EElementNames.ASIAN_FAVOURITES_NAVIGATION));
  const sportsNavHeight = useSelector(getElementHeight(EElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT));
  const betSlipSettingsAndTotalLiabilityHeight = useSelector(
    getElementHeight(EElementNames.ASIAN_BET_SLIP_SETTINGS_AND_TOTAL_LIABILITY)
  );
  const timezoneHeight = useSelector(getElementHeight(EElementNames.ASIAN_TIMEZONE));
  const balanceHeight = useSelector(getElementHeight(EElementNames.ASIAN_BALANCE));
  const betSlipCollapseHeight = useSelector(getElementHeight(EElementNames.ASIAN_BET_SLIP_COLLAPSE));
  const betSlipTabsHeight = useSelector(getElementHeight(EElementNames.ASIAN_BET_SLIP_TABS));

  useEffect(() => {
    if (matchedSelectedBets.length) {
      setTimeout(() => {
        dispatch(removeSelectedBets(matchedSelectedBets));
      }, ASIAN_VIEW_DELETE_MATCHED_BETS_TIMEOUT);
    }
  }, [matchedSelectedBets]);

  return (
    <div className={styles.content}>
      <div
        className={classNames(styles.inner, branding.BACKGROUND)}
        style={{
          maxHeight:
            scrollContentHeight -
            DESKTOP_NAVIGATION_HEIGHT -
            favouritesHeight -
            sportsNavHeight -
            betSlipSettingsAndTotalLiabilityHeight -
            TOP_AND_BOTTOM_PADDINGS -
            timezoneHeight -
            balanceHeight -
            betSlipCollapseHeight -
            betSlipTabsHeight
        }}
      >
        {!!betsAmount ? (
          <AVSelectedBets />
        ) : (
          <div className={styles.empty}>{t('asianView.labels.betslip.emptyMessage')}</div>
        )}
      </div>
      {isLoggedIn && <AVTotalLiabilityAndActionsAndSettings />}
    </div>
  );
};

export default BetSlipTabContent;
