import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import QuickBets from 'components/Betslip/components/QuickBets';
import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import VirtualKeyboard from 'components/CashOutButton/components/CashOutState/partials/VirtualKeyboard';
import {
  DEFAULT_MAX_PRICE,
  DEFAULT_MIN_PRICE,
  LOADING_TIMEOUT_MESSAGE,
  VALIDATION_ERROR_BET_OUTDATED_LINE,
  VALIDATION_ERROR_BET_OUTDATED_ODDS,
  VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET
} from 'constants/placement';
import { PARAMS_ACTION_KEY, PARAMS_OFFER_ID_KEY } from 'constants/urlParams';
import useConfirmBets from 'hooks/useConfirmBets';
import { useBetslipLabels } from 'hooks/useLabels';
import { useMarketUnits } from 'hooks/useMarketUnits';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { usePlacementData } from 'hooks/usePlacement';
import usePostMessage from 'hooks/usePostMessage';
import {
  getBalanceWsEnabled,
  getBetslipSpinnerTime,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getMobileSettingsConfirmBetsBeforePlace,
  getMobileSettingsVirtualKeyboardBetslip,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setBetslipLoading, setRGErrorMessage } from 'redux/modules/betslip';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { setCurrentBetAction } from 'redux/modules/currentBets';
import { getCurrentBetByOfferId, getCurrentBetsByOldOfferId } from 'redux/modules/currentBets/selectors';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { getCurrentGameData } from 'redux/modules/games/selectors';
import {
  failureInlinePlacedBet,
  removeInlineSelectedBet,
  setInlinePlacedSize,
  successInlinePlacedBet,
  updateInlineSelectedBet
} from 'redux/modules/inlinePlacement';
import { getInlineSelectedBetByMarket } from 'redux/modules/inlinePlacement/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getMarketDataById, getMarketPricesById } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { setMessageBet } from 'redux/modules/placementMessage';
import { fetchBalance } from 'redux/modules/user';
import { getAccountSettings, getUserCurrency, getUserLoading } from 'redux/modules/user/selectors';
import { MarketStatus, PageBlocks, PlacementPage, SportId } from 'types';
import { BetTypes, EPersistenceTypes, TPrice, TSize } from 'types/bets';
import { EBetslipTypes, TLabels } from 'types/betslip';
import { Actions, Statuses } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { getBestPriceFromMarketPrices, getBestPrices, isResponsibleGamblingError } from 'utils/betslip';
import { calculateLiability } from 'utils/liability';
import { getMarketLiability } from 'utils/totalLiability';

import BetslipOverlay from './BetslipOverlay';
import ConfirmationCheckbox from './ConfirmationCheckbox';
import ConfirmationModal from './ConfirmationModal';
import Liability from './Liability';
import Payout from './Payout';
import PersistenceDropdown from './PersistenceDropdown';
import PriceAndSizeInputs from './PriceAndSizeInputs';

import styles from './styles.module.scss';

interface MobilePlacementProps {
  /**
   * Bet data that consists of the market and selection information, price and size, offerId (if bet is placed)
   */
  bet: TInlineSelectedBet;

  /**
   * Place where component was added.
   */
  pageBlock?: PageBlocks;
  /**
   * On closing the betslip
   */
  onClosingBetslip?: (isVisible: boolean) => void;
  page?: PlacementPage;
}

function MobilePlacement({
  bet,
  pageBlock = PageBlocks.HOME,
  onClosingBetslip = () => {},
  page
}: MobilePlacementProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);
  const confirmBetsBeforePlace = useSelector(getMobileSettingsConfirmBetsBeforePlace);
  const betslipSpinnerTime = useSelector(getBetslipSpinnerTime);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const loadingUser = useSelector(getUserLoading);
  const userCurrency = useSelector(getUserCurrency);
  const market = useSelector(getMarketDataById(pageBlock, bet.marketId, bet.sportId));
  const inlineSelectedBet = useSelector(getInlineSelectedBetByMarket(pageBlock, bet.marketId));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const currentBet = useSelector(getCurrentBetByOfferId(bet?.currentOfferId || 0));
  const currentOldBets = useSelector(getCurrentBetsByOldOfferId(bet?.currentOfferId || 0));
  const currentGameData = useSelector(getCurrentGameData);
  const accountSettings = useSelector(getAccountSettings);
  const marketPrices = useSelector(getMarketPricesById(pageBlock, bet.marketId));
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const offerId = searchParams.get(PARAMS_OFFER_ID_KEY) || '';
  const action = searchParams.get(PARAMS_ACTION_KEY) || '';

  const labels: TLabels = useBetslipLabels({
    eventTypeId: market?.eventType?.id ?? '',
    bettingType: market?.description?.bettingType ?? '',
    marketType: market?.description?.marketType ?? '',
    marketUnit: market?.description?.lineRangeInfo?.marketUnit ?? null,
    fancyView: market?.fancyView ?? false
  });
  const selectedBet =
    inlineSelectedBet && inlineSelectedBet.offers && inlineSelectedBet?.currentOfferId
      ? inlineSelectedBet.offers[inlineSelectedBet?.currentOfferId]
      : null;

  const defaultFocus = virtualKeyboardBetslip ? EBetFocusFields.SIZE : null;
  const marketType = market?.description.marketType;
  const bettingType = market?.description.bettingType;
  const marketPrice = getBestPrices({ marketPrices, ...bet });

  const [dirtyInput, setDirtyInput] = useState<EBetFocusFields | null>(null);
  const [currentFocus, setCurrentFocus] = useState<EBetFocusFields | null>(defaultFocus);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isValidSize, setIsValidSize] = useState<boolean>(false);
  const [priceValue, setPriceValue] = useState<TPrice>(bet.price);
  const [prevPriceValue, setPrevPriceValue] = useState<TPrice>(bet.price);
  const [sizeValue, setSizeValue] = useState<TSize>(bet.size);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hasErrorMessage, setHasErrorMessage] = useState<boolean>(false);
  const [status, setStatus] = useState<Statuses>(Statuses.NEW);
  const [placedBet, setPlacedBet] = useState<TCurrentBet | null>(selectedBet || null);
  const [isTakeOffer, setIsTakeOffer] = useState<boolean>(false);
  const [isCancelation, setIsCancelation] = useState<boolean>(false);
  const [useQuickBets, setUseQuickBets] = useState<boolean>(false);
  const [persistenceType, setPersistenceType] = useState<EPersistenceTypes>(EPersistenceTypes.LAPSE);
  const [bestPrice, setBestPrice] = useState<TPrice>(
    getBestPriceFromMarketPrices(marketPrice, marketType ?? '', bettingType ?? '')
  );

  const isGame = pageBlock === PageBlocks.GAME;
  const marketCurrency = marketPrices?.currency;
  const isCurrencyChanged =
    isLoggedIn && !loadingUser && marketCurrency && userCurrency && userCurrency !== marketCurrency;

  const errorMessageRef = useRef<HTMLDivElement>(null);
  const sizeInputRef = useRef<HTMLInputElement | null>(null);
  const virtualKeyboardRef = useRef<HTMLInputElement | null>(null);
  const prevSizeValue = useRef<TSize>(bet.size);

  const { isConfirmBetsBeforePlacement } = useConfirmBets();

  const lineRangeInfo = market?.description?.lineRangeInfo;
  const marketUnit = lineRangeInfo?.marketUnit || 'runs';
  const isCricket = market?.eventType?.id === SportId.CRICKET;

  const { placeBetLogin } = usePostMessage();

  const marketName = isGame && currentGameData ? currentGameData.name : market?.marketName ?? '';
  const selectionName =
    market?.runners?.find(runner => runner.selectionId === bet.selectionId && runner.handicap === bet.handicap)
      ?.runnerName ?? '';

  const isLineMarket = bettingType === BettingType.LINE;
  const priceLadderDescription = market?.description.priceLadderDescription;
  const isBetslipVisible = !((status === Statuses.PLACED || bet.action !== Actions.EDIT) && placedBet);
  const minValue = isLineMarket ? lineRangeInfo?.minUnitValue ?? DEFAULT_MIN_PRICE : DEFAULT_MIN_PRICE;
  const maxValue = isLineMarket ? lineRangeInfo?.maxUnitValue ?? DEFAULT_MAX_PRICE : DEFAULT_MAX_PRICE;

  const getPayout = useCallback(() => {
    if (isLineMarket) {
      if (typeof sizeValue !== 'undefined') {
        return sizeValue ? 2 * +sizeValue : 0;
      }
    } else {
      if (typeof sizeValue !== 'undefined' && typeof priceValue !== 'undefined') {
        return +sizeValue * +priceValue;
      }
    }
    return 0;
  }, [isLineMarket, sizeValue, priceValue]);

  const payout = getPayout();

  const getLiability = () => {
    const betData = {
      marketId: bet.marketId,
      selectionId: bet.selectionId || 0,
      handicap: bet.handicap,
      type: bet.type,
      price: priceValue,
      size: sizeValue
    };

    return getMarketLiability({
      backs: bet.type === BetTypes.BACK ? [betData] : [],
      lays:
        bet.type !== BetTypes.BACK ? [{ ...betData, ...{ profit: calculateLiability(priceValue, sizeValue) } }] : [],
      market: marketPrices?.marketDefinition
    });
  };

  const liability = getLiability();

  const isPlacementBtnEnabled = () => {
    if (priceValue && +priceValue >= minValue && +priceValue <= maxValue && sizeValue && !isNaN(+sizeValue)) {
      if (bet.currentOfferId) {
        return (
          sizeValue !== prevSizeValue.current ||
          priceValue !== prevPriceValue ||
          (placedBet && placedBet.persistenceType !== persistenceType)
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const hideErrorMessage = () => {
    setErrorMessage('');
    setHasErrorMessage(false);

    if (bet.placementError && !isBetslipVisible) {
      removeBet();
    }
  };

  useOnClickOutside(errorMessageRef, () => {
    if (errorMessage) {
      setTimeout(() => {
        hideErrorMessage();
      }, 100);
    }
  });

  useOnClickOutside(
    virtualKeyboardRef,
    () => {},
    event => {
      const el = virtualKeyboardRef?.current;
      if (!el || el.contains((event?.target as Node) || null)) {
        return;
      }

      if (virtualKeyboardBetslip) {
        setDirtyInput(currentFocus);
      }
    }
  );

  useEffect(() => {
    if (action && offerId && bet?.currentOfferId !== +offerId) {
      searchParams.delete(PARAMS_OFFER_ID_KEY);
      searchParams.delete(PARAMS_ACTION_KEY);
      setSearchParams(searchParams);
    }
  }, [action, offerId, bet?.currentOfferId]);

  useEffect(() => {
    if (priceValue !== bet.price) {
      setPriceValue(bet.price);
      setStatus(Statuses.NEW);
    }
  }, [bet.price]);

  useEffect(() => {
    const isCancelling =
      currentBet &&
      (currentBet.action == ECurrentBetActions.CANCELLING || currentBet.action == ECurrentBetActions.CANCELLING_ALL);
    const isEditing = currentBet && currentBet.action == ECurrentBetActions.EDITING;

    if (
      currentBet &&
      (isCancelling || (isEditing && currentOldBets.length !== 0)) &&
      currentBet.offerState == BetsStatusesTypes.CANCELLED
    ) {
      setPlacedBet(currentBet);
      setStatus(Statuses.PLACED);
    }
  }, [currentBet, currentOldBets]);

  useEffect(() => {
    if (marketPrices?.marketDefinition?.status === MarketStatus.CLOSED) {
      dispatch(removeInlineSelectedBet(bet));
    }
  }, [marketPrices?.marketDefinition?.status]);

  const onSuccessPlacement = (placedBets: TCurrentBet[], cancelledBets: TCurrentBet[]) => {
    if (placedBets.length) {
      dispatch(successInlinePlacedBet({ ...bet, placedBet: placedBets[0] }));
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    }

    if (cancelledBets.length) {
      dispatch(setCurrentBetAction({ offerId: cancelledBets[0].offerId, action: null }));
    }
  };

  const onErrorPlacement = (error: TPlacementError | string) => {
    dispatch(failureInlinePlacedBet({ ...bet, error }));
    setHasErrorMessage(true);

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }

    if (placedBet) {
      setStatus(Statuses.PLACED_EDIT);
    } else {
      setStatus(Statuses.NEW);
    }
  };

  const onCancelledPlacement = () => {
    if (isPNCEnabled) {
      setIsTakeOffer(true);
      setErrorMessage(isLineMarket ? t(VALIDATION_ERROR_BET_OUTDATED_LINE) : t(VALIDATION_ERROR_BET_OUTDATED_ODDS));
      setStatus(Statuses.CONFIRM);
    }
  };

  const placement = usePlacementData({
    eachWayDivisor: market?.description?.eachWayDivisor,
    numberOfWinners: market?.numberOfWinners,
    successPlacement: onSuccessPlacement,
    errorPlacement: onErrorPlacement,
    onCancelledPlacement
  });

  const marketUnitTranslated = useMarketUnits(marketUnit);

  const getPlaceButtonLabel = () => {
    if (isTakeOffer) {
      return isLineMarket
        ? t('betslip.actions.placeBetAtAvailableUnits', { unit: marketUnitTranslated })
        : t('betslip.actions.placeBetAtAvailableOdds');
    } else if (bet.currentOfferId) {
      return t('betslip.actions.updateBet');
    } else {
      return t('betslip.actions.place');
    }
  };

  const confirmBet = () => {
    if (isLoggedIn) {
      const betType = isGame ? EBetslipTypes.GAME : EBetslipTypes.EXCHANGE;
      const options = {
        isTakeOffer: isTakeOffer,
        ...(isGame && market?.round ? { round: market?.round } : {}),
        ...{ betType }
      };
      setStatus(Statuses.PROGRESS);
      setIsOpenModal(false);
      setHasErrorMessage(false);

      if (placedBet) {
        placement.editBetsHandler({
          marketId: bet.marketId,
          bets: [
            {
              price: priceValue,
              size: sizeValue,
              side: placedBet.side,
              selectionId: placedBet.selectionId,
              handicap: placedBet.handicap,
              offerId: placedBet.offerId,
              sizeRemaining: placedBet.sizeRemaining,
              persistenceType,
              page
            }
          ],
          options
        });
      } else {
        placement.placeBetsHandler({
          marketId: bet.marketId,
          bets: [
            {
              price: priceValue,
              size: sizeValue,
              handicap: bet.handicap,
              selectionId: bet.selectionId,
              side: bet.type.toUpperCase(),
              page,
              persistenceType
            }
          ],
          options
        });
      }
    } else {
      placeBetLogin();
    }
  };

  useEffect(() => {
    if (placedBet) {
      setPersistenceType(placedBet.persistenceType);
    }
  }, [placedBet]);

  useEffect(() => {
    const newPrice = getBestPriceFromMarketPrices(marketPrice, marketType ?? '', bettingType ?? '');

    if (newPrice != priceValue && bestPrice != newPrice) {
      if (isTakeOffer) {
        setPrevPriceValue(priceValue);
        setPriceValue(newPrice);
      }

      setBestPrice(newPrice);
    }
  }, [bettingType, isTakeOffer, marketPrice, marketType]);

  useEffect(() => {
    if (bet.placementError && hasErrorMessage) {
      setErrorMessage(bet.placementError);
      setStatus(Statuses.NEW);
    }
  }, [bet.placementError, hasErrorMessage]);

  useEffect(() => {
    const currentOfferId = inlineSelectedBet?.currentOfferId;

    if (
      [Statuses.PROGRESS, Statuses.TIMEOUT_HIDDEN].includes(status) &&
      currentOfferId &&
      inlineSelectedBet.offers &&
      inlineSelectedBet.offers[currentOfferId] &&
      [BetsStatusesTypes.MATCHED, BetsStatusesTypes.PLACED, BetsStatusesTypes.CANCELLED].includes(
        inlineSelectedBet.offers[currentOfferId].offerState
      )
    ) {
      setPlacedBet(inlineSelectedBet.offers[currentOfferId]);
      setStatus(Statuses.PLACED);
    }
  }, [inlineSelectedBet]);

  useEffect(() => {
    let placementTimeout: ReturnType<typeof setTimeout> | null = null;

    if (status === Statuses.PROGRESS) {
      dispatch(setBetslipLoading(true));
      placementTimeout = setTimeout(() => {
        setErrorMessage(t(LOADING_TIMEOUT_MESSAGE));
        setStatus(Statuses.TIMEOUT_HIDDEN);
      }, +betslipSpinnerTime * 1000);
    } else {
      dispatch(setBetslipLoading(false));
      if (placementTimeout) {
        clearTimeout(placementTimeout);
      }
    }

    if (status === Statuses.PLACED && !hasErrorMessage) {
      onClosingBetslip(true);
      if (placedBet) {
        dispatch(setMessageBet(placedBet));
      }
      removeBet();
    }

    return () => {
      dispatch(setBetslipLoading(false));
      if (placementTimeout) {
        clearTimeout(placementTimeout);
      }
    };
  }, [status, bet.action]);

  const onErrorClick = () => {
    hideErrorMessage();
  };

  const onConfirmBtnClick = () => {
    if (isPlacementBtnEnabled() && isValidSize) {
      editBet();
    }
  };

  const editBet = () => {
    if (isConfirmBetsBeforePlacement && (!bet.currentOfferId || bet.action === Actions.TAKE)) {
      setIsOpenModal(true);
    } else {
      confirmBet();
    }
  };

  const onCloseModal = () => {
    if (bet.action === Actions.TAKE || isCancelation || status == Statuses.NEW) {
      removeBet();
    } else {
      setIsCancelation(false);
      setIsOpenModal(false);
    }
  };

  const cancelPlacedBet = () => {
    setIsCancelation(false);
    setIsOpenModal(false);
    setStatus(Statuses.PROGRESS);

    if (placedBet) {
      placement.cancelBetsHandler({
        marketId: bet.marketId,
        bets: [{ ...placedBet }]
      });
    } else if (bet.currentOfferId) {
      placement.cancelBetsHandler({
        marketId: bet.marketId,
        bets: [
          {
            offerId: bet.currentOfferId
          }
        ]
      });
    }
  };

  const onCancel = () => {
    if (bet.currentOfferId || status === Statuses.PLACED_EDIT) {
      if (isConfirmBetsBeforePlacement) {
        setIsOpenModal(true);
        setIsCancelation(true);
      } else {
        cancelPlacedBet();
      }
    } else {
      removeBet();
    }
  };

  const removeBet = () => {
    dispatch(removeInlineSelectedBet(bet));
  };

  useEffect(() => {
    dispatch(
      setInlinePlacedSize({
        price: priceValue || 0,
        size: sizeValue || 0,
        marketId: bet.marketId,
        pageBlock
      })
    );
  }, [priceValue, sizeValue]);

  useEffect(() => {
    setPriceValue(bet.price);
    if (!bet.size && accountSettings?.defaultStake && !isGame) {
      const defStake = accountSettings?.defaultStakes.find(item => item?.defaultValue);
      setSizeValue(defStake?.value ?? '');
    } else {
      setSizeValue(bet.size ?? '');
    }
    if (bet.currentOfferId) {
      if (bet.offers) {
        setPlacedBet(bet.offers[bet.currentOfferId]);
      }
      setStatus(bet.action ? Statuses.PLACED_EDIT : Statuses.PLACED);
    } else {
      setStatus(Statuses.NEW);
      setPlacedBet(null);
    }
  }, [bet.selectionId, bet.type, bet.action]);

  useEffect(() => {
    if (bet.action) {
      if (bet.action == Actions.CANCEL) {
        onCancel();
      }
      if (bet.action == Actions.TAKE) {
        editBet();
      }
    }
  }, [bet.action, isConfirmBetsBeforePlacement]);

  useEffect(() => {
    if (bet?.redirectedBet) {
      dispatch(updateInlineSelectedBet({ ...bet, ...{ redirectedBet: false } }));
    }

    return () => {
      onClosingBetslip(false);
      if (!bet?.redirectedBet) {
        dispatch(removeInlineSelectedBet(bet));
      }
    };
  }, []);

  const handlerQuickBets = useCallback((value: string | number) => {
    setSizeValue(value);
    setUseQuickBets(true);
  }, []);

  const updateCurrentFocus = (newFocus: EBetFocusFields | null) => {
    setCurrentFocus(newFocus);
  };

  const setSizeFocused = useCallback(() => {
    updateCurrentFocus(EBetFocusFields.SIZE);
  }, []);

  const setPriceFocused = useCallback(() => {
    updateCurrentFocus(EBetFocusFields.PRICE);
  }, []);

  const handleKeyClick = (key: string) => {
    const validation = /^\d+\.{0,1}\d{0,2}$/;
    switch (currentFocus) {
      case EBetFocusFields.PRICE:
        const newPriceValue = priceValue === 0 ? key : priceValue + key;
        if (priceValue === '0' && key !== '.') return;
        if (!validation.test(newPriceValue)) return;
        return setPriceValue(newPriceValue);
      case EBetFocusFields.SIZE:
        const newSizeValue = sizeValue + key;
        if (sizeValue === '0' && key !== '.') return;
        if (!validation.test(newSizeValue)) return;
        return setSizeValue(newSizeValue);
      default:
        return;
    }
  };

  const handleBackSpace = () => {
    switch (currentFocus) {
      case EBetFocusFields.PRICE:
        return setPriceValue(priceValue?.toString().slice(0, -1));
      case EBetFocusFields.SIZE:
        return setSizeValue(sizeValue?.toString().slice(0, -1));
      default:
        return;
    }
  };

  return (
    <>
      <ConfirmationModal
        type={bet.type}
        price={isCancelation ? prevPriceValue : priceValue}
        size={sizeValue}
        profit={bet.type === BetTypes.BACK ? payout - liability : liability}
        isOpen={isOpenModal}
        onClose={onCloseModal}
        onConfirm={isCancelation ? cancelPlacedBet : confirmBet}
        isLineMarket={isLineMarket}
        isPNCEnabled={isPNCEnabled}
        isCricket={isCricket}
        isCancelationModal={isCancelation}
        marketUnit={marketUnit}
      />
      {(isOpenModal || status === Statuses.PROGRESS) && <BetslipOverlay hasSpinner={status === Statuses.PROGRESS} />}
      <div
        className={classNames('biab_betslip', bet.type === BetTypes.BACK ? 'biab_back' : 'biab_lay', styles.betslip)}
      >
        <div
          dangerouslySetInnerHTML={{ __html: errorMessage }}
          onClick={onErrorClick}
          ref={errorMessageRef}
          className={classNames('biab_alert-bet-msg biab_error-msg', { biab_hide: !errorMessage })}
        />
        {isBetslipVisible && (
          <div>
            {!isCurrencyChanged && (
              <div className="biab_open-bet-details">
                <div className="biab_mobile-betslip-header">
                  <span>{labels[bet.type]?.bet}</span>:
                  <span className="biab_mobile-betslip-header-market">
                    {selectionName} - {marketName}
                  </span>
                </div>
                <PriceAndSizeInputs
                  setErrorMessage={setErrorMessage}
                  betType={bet.type}
                  setPriceValue={setPriceValue}
                  priceLadderDescription={priceLadderDescription}
                  lineRangeInfo={lineRangeInfo}
                  marketType={marketType || ''}
                  bettingType={bettingType || ''}
                  marketUnit={marketUnit}
                  priceValue={priceValue || ''}
                  bestPrice={bestPrice || ''}
                  prevPriceValue={prevPriceValue}
                  isTakeOffer={isTakeOffer}
                  currentFocus={currentFocus}
                  setPriceFocused={setPriceFocused}
                  setSizeFocused={setSizeFocused}
                  dirtyInput={dirtyInput}
                  setDirtyInput={setDirtyInput}
                  setIsValidSize={setIsValidSize}
                  setSizeValue={setSizeValue}
                  sizeValue={sizeValue || ''}
                  isQuickBets={useQuickBets}
                  setUseQuickBets={setUseQuickBets}
                  sizeInputRef={sizeInputRef}
                />
                {placedBet?.persistenceEnabled && (
                  <PersistenceDropdown persistenceType={persistenceType} setPersistenceType={setPersistenceType} />
                )}
                {isLineMarket && !isPNCEnabled && (
                  <div className={classNames('biab_line-odds-info', styles.lineInfoLabel)}>
                    {t('mobile.betslip.labels.oddsAreAlways2')}
                  </div>
                )}
                <Liability liability={liability} />
                <Payout payout={payout} />
                <QuickBets handler={handlerQuickBets} />
                {virtualKeyboardBetslip && (
                  <div className={classNames(styles.keyboard)} ref={virtualKeyboardRef}>
                    <VirtualKeyboard onKeyClick={handleKeyClick} onBackSpace={handleBackSpace} />
                  </div>
                )}
                {confirmBetsBeforePlace && <ConfirmationCheckbox defaultChecked={isConfirmBetsBeforePlacement} />}
                <button
                  onClick={onCancel}
                  type="button"
                  className={classNames('biab_cancel biab_modal-btn', {
                    'biab_cancel-btn': !bet.currentOfferId,
                    'biab_cancel-bet-btn': !!bet.currentOfferId
                  })}
                >
                  {t('betslip.labels.btn.cancel' + (bet.currentOfferId ? 'Bet' : ''))}
                </button>
                <button
                  onClick={onConfirmBtnClick}
                  type="button"
                  className={classNames('biab_confirm-bet-btn biab_place-bet-btn biab_modal-btn', {
                    biab_disabled: !isPlacementBtnEnabled(),
                    'biab_edit-bet-btn': !!bet.currentOfferId,
                    'biab_place-bet-at-available-units-btn': isTakeOffer
                  })}
                >
                  {getPlaceButtonLabel()}
                </button>
              </div>
            )}
            {isCurrencyChanged && (
              <div className="biab_mobile-betslip-bet-message biab_open-bet-details">
                <div className="biab_mobile-betslip-bet-message-content">
                  {t(VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET, { currency_ISO_code: marketCurrency ?? '' })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <RGModalMessage />
    </>
  );
}

export default MobilePlacement;
