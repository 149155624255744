import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import BannersContainer from 'components/BannersContainer';
import Betslip from 'components/Betslip';
import PopularLinks from 'components/PopularLinks';
import PromotionBanners from 'components/PromotionBanners';
import { BannerNames } from 'constants/app';
import { AB, POKER_STAR } from 'constants/icons';
import { GAMES_BASE_URL } from 'constants/locations';
import useElementSize from 'hooks/useElementSize';
import { getAppDevice, getLanguage, getOperator } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchBanner } from 'redux/modules/banners';
import { getIsDisplayedBannerByName } from 'redux/modules/banners/selectors';
import { BannerSections } from 'redux/modules/banners/type';

import styles from './RightNavigation.module.scss';

const RightNavigation = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const device = useSelector(getAppDevice);
  const language = useSelector(getLanguage);
  const operator = useSelector(getOperator);
  const headerHeight = useSelector(getElementHeightByName(EElementNames.HEADER_HEIGHT));
  const isBadgeBannerDisplayed = useSelector(getIsDisplayedBannerByName(BannerNames.badge));
  const isBadgeSkyscraperBannerDisplayed = useSelector(getIsDisplayedBannerByName(BannerNames.badgeSkyscraper));

  const isGamesPage = location.pathname.startsWith(GAMES_BASE_URL);
  const isPokerstars = operator === POKER_STAR;
  const isAdjaraBet = operator === AB;

  useEffect(() => {
    if (language) {
      if (!isGamesPage) {
        dispatch(
          fetchBanner({
            name: BannerNames.badge,
            platform: Devices.DESKTOP,
            section: BannerSections.BADGE_BANNER,
            locale: language
          })
        );
        dispatch(
          fetchBanner({
            name: BannerNames.badgeSkyscraper,
            platform: Devices.DESKTOP,
            section: BannerSections.BADGE_BANNER_SKYSCRAPER,
            locale: language
          })
        );
      }
      dispatch(
        fetchBanner({
          name: BannerNames.promotion,
          platform: Devices.DESKTOP,
          section: BannerSections.PROMOTION_BANNER,
          locale: language
        })
      );
    }
  }, [language, device]);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.SIDEBAR_HEIGHT, height }));
    }
  });

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.SIDEBAR_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <div
      style={{ height: isPokerstars || isAdjaraBet ? 'none' : `calc(100vh - ${headerHeight}px)` }}
      className={classNames('biab_sidebar-container rightNavigation', styles.rightNav)}
    >
      <div ref={componentRef}>
        <Betslip />
        {!isGamesPage && <PopularLinks />}
        <PromotionBanners />
        {!isGamesPage && (
          <>
            {isBadgeBannerDisplayed && <BannersContainer bannerType={BannerSections.BADGE_BANNER} />}
            {isBadgeSkyscraperBannerDisplayed && (
              <BannersContainer bannerType={BannerSections.BADGE_BANNER_SKYSCRAPER} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RightNavigation;
