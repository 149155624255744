import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_SINGLE_VIEW_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewSection
} from 'constants/asianView';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import { getBackendPages, getTranslation } from 'redux/modules/appConfigs/selectors';
import { removeCompetitionKey, setIsAsianViewNotFoundView } from 'redux/modules/asianView';
import {
  getAsianViewCompetitionEvents,
  getIsAsianViewCompetitionClosed,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';
import { TAsianViewEvent } from 'redux/modules/asianView/type';
import {
  getAsianViewFavouritesMobileEvents,
  getIsAsianViewFavouritesMobileCompetitionClosed
} from 'redux/modules/asianViewFavourites/selectors';
import { AsianViewFavouritePayload } from 'redux/modules/asianViewFavourites/type';
import { getClosedMarketsIds } from 'redux/modules/marketsPrices/selectors';
import { fetchPageData } from 'redux/modules/pages';
import { PagesFromBackend } from 'redux/modules/pages/types';
import { CookieNames, SportId } from 'types';
import { ESortingTypes } from 'types/asianView';
import { getCompetitionIdFromKey } from 'utils/asianView';

import CompetitionTitle from '../CompetitionTitle';
import CorrectScoreEvent from '../CorrectScoreEvent';
import Event from '../Event';
import HalfTimeFullTimeEvent from '../HalfTimeFullTimeEvent';
import MatchOddsHTEvent from '../MatchOddsHTEvent';
import MobileCompetitionTitle from '../MobileCompetitionTitle';
import MobileEvent from '../MobileEvent';
import OddEvenViewEvent from '../OddEvenViewEvent';
import TotalGoalsEvent from '../TotalGoalsEvent';

import styles from './styles.module.scss';

interface CompetitionSectionProps {
  competitionKey: string;
  isLast: boolean;
  isSingleLine?: boolean;
  isFavouritesMobile?: boolean;
  onRemoveFavoriteMobile?: (favourite?: AsianViewFavouritePayload) => void;
}

const CompetitionSection = ({
  competitionKey,
  isLast,
  isSingleLine = false,
  isFavouritesMobile = false,
  onRemoveFavoriteMobile
}: CompetitionSectionProps) => {
  const dispatch = useDispatch();
  const { sportId = SportId.SOCCER, marketLink } = useParams();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_SORTING_FILTER]);

  const translation = useSelector(getTranslation);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const events = useSelector(
    isFavouritesMobile
      ? getAsianViewFavouritesMobileEvents(competitionKey)
      : getAsianViewCompetitionEvents(competitionKey)
  );
  const isClosed = useSelector(
    isFavouritesMobile
      ? getIsAsianViewFavouritesMobileCompetitionClosed(competitionKey, closedMarketIds)
      : getIsAsianViewCompetitionClosed(competitionKey, closedMarketIds)
  );
  const isMobileAsianView = useSelector(getIsMobileAsianView);
  const pages = useSelector(getBackendPages);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [closedEventIds, setClosedEventIds] = useState<string[]>([]);

  const activeEvents = useMemo(
    () => events.filter(event => !closedEventIds.includes(event.id)),
    [events, closedEventIds]
  );
  const sortingFilter = (cookies[CookieNames.ASIAN_VIEW_SORTING_FILTER] as ESortingTypes) || ESortingTypes.NORMAL;
  const isCollapseEnabled = sortingFilter === ESortingTypes.NORMAL;
  const isMatchOddsHT = marketLink === AsianViewMarketLink.MATCH_ODDS_HALF_TIME;
  const isOddEven = marketLink === AsianViewMarketLink.ODD_EVEN;
  const isCorrectScore = marketLink === AsianViewMarketLink.CORRECT_SCORE;
  const isHalfTimeFullTime = marketLink === AsianViewMarketLink.HALF_TIME_FULL_TIME;
  const isTotalGoals = marketLink === AsianViewMarketLink.TOTAL_GOALS;
  const competitionId = getCompetitionIdFromKey(competitionKey);
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) ?? '';
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const singleViewCompetitionId = searchParams.get(ASIAN_SINGLE_VIEW_COMPETITION_ID);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const singleViewSportId = searchParams.get(ASIAN_SINGLE_VIEW_SPORT_ID);
  const isSingleView = (!!singleViewCompetitionId || !!singleViewEventId) && !!singleViewSportId;

  const prevActiveEvents = useRef(activeEvents);

  useEffect(() => {
    if (activeEvents.length === 0 && prevActiveEvents.current.length > 0 && (isSingleView || isPopularLinkView)) {
      dispatch(fetchPageData(pages[PagesFromBackend.PAGE_NOT_FOUND] as string));
      dispatch(setIsAsianViewNotFoundView(true));
    }
  }, [activeEvents]);

  useEffect(() => {
    if (activeEvents.length === 0) {
      dispatch(removeCompetitionKey(competitionKey));
    }
  }, [activeEvents.length]);

  const onEventClose = (eventId: string) => {
    setClosedEventIds(prevValue => [...prevValue, eventId]);
  };

  const getEventProps = (event: TAsianViewEvent) => {
    return {
      event,
      competitionId,
      key: event.id,
      sportId,
      onEventClose,
      isSingleLine
    };
  };

  const renderContent = () => {
    if (isOpen || !isCollapseEnabled) {
      if (
        isFavouriteView ||
        query.length >= SEARCH_QUERY_LENGTH ||
        isPopularLinkView ||
        isSingleView ||
        isFavouritesMobile
      ) {
        return activeEvents.map((event, index) =>
          isMobileAsianView ? (
            <MobileEvent {...getEventProps(event)} onRemoveFavorite={onRemoveFavoriteMobile} />
          ) : (
            <Event isFirst={index === 0} {...getEventProps(event)} index={index} />
          )
        );
      }

      if (isMatchOddsHT) {
        return activeEvents.map((event, index) => (
          <MatchOddsHTEvent
            key={event.id}
            event={event}
            competitionId={competitionId}
            isFirst={index === 0}
            index={index}
          />
        ));
      }

      if (isCorrectScore) {
        return activeEvents.map((event, index) => {
          return <CorrectScoreEvent key={event.id} event={event} competitionId={competitionId} isFirst={index === 0} />;
        });
      }

      if (isTotalGoals) {
        return events.map((event, index) => (
          <TotalGoalsEvent
            competitionId={competitionId}
            key={event.id}
            sportId={sportId}
            event={event}
            isFirst={index === 0}
          />
        ));
      }

      if (isOddEven) {
        return events.map((event, index) => {
          const market = event.sections[AsianViewSection.TOP]?.[0];

          return (
            <OddEvenViewEvent
              key={event.id}
              competitionId={competitionId}
              event={event}
              sportId={sportId}
              runners={market?.runners ?? []}
              marketId={market?.id ?? ''}
              isFirst={index === 0}
            />
          );
        });
      }

      if (isHalfTimeFullTime) {
        return activeEvents.map((event, index) => {
          return (
            <HalfTimeFullTimeEvent key={event.id} event={event} competitionId={competitionId} isFirst={index === 0} />
          );
        });
      }

      return activeEvents.map((event, index) =>
        isMobileAsianView ? (
          <MobileEvent {...getEventProps(event)} />
        ) : (
          <Event isFirst={index === 0} {...getEventProps(event)} index={index} />
        )
      );
    }

    return null;
  };

  if (isClosed) {
    return null;
  }

  return activeEvents.length > 0 ? (
    <>
      <div className={classNames('biab_asian-view-competition-wrapper', styles.wrapper)}>
        {!isMobileAsianView && (
          <CompetitionTitle
            competitionId={competitionId}
            name={activeEvents[0].competitionTranslations?.[translation]}
            events={activeEvents}
            isOpen={isOpen}
            onCollapse={setIsOpen}
            isSingleLine={isSingleLine}
            isCollapseEnabled={isCollapseEnabled}
            competitionTotalMatched={activeEvents[0].competitionTotalMatched}
          />
        )}
        {isMobileAsianView && (
          <MobileCompetitionTitle
            competitionId={competitionId}
            name={activeEvents[0].competitionTranslations?.[translation]}
            isOpen={isOpen}
            onCollapse={setIsOpen}
            isCollapseEnabled={isCollapseEnabled}
            onRemoveFavorite={onRemoveFavoriteMobile}
            competitionTotalMatched={activeEvents[0].competitionTotalMatched}
          />
        )}
        {renderContent()}
      </div>
      {!isLast && !isMobileAsianView && (
        <div className={classNames('biab_asian-view-competition-margin', styles.margin)} />
      )}
    </>
  ) : null;
};

export default memo(CompetitionSection);
