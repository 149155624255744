import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { map, unescape } from 'lodash';

import { TOTAL_LIABILITY_PRECISION } from 'constants/placement';
import { BETSLIP_LIABILITY, WINNERS_DIFFERENT_EXPOSURE } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useTooltip from 'hooks/useTooltip';
import { getSelectedBetsForWhatIf } from 'redux/modules/betslip/selectors';
import { BetTypes } from 'types/bets';
import { TBetslipMarket, TBetslipMarketDefinition } from 'types/betslip';
import { roundFloat } from 'utils/liability';
import { getLiability } from 'utils/totalLiability';

import styles from './styles.module.scss';

interface TotalLiabilityProps {
  market: TBetslipMarket;
  marketDefinition?: TBetslipMarketDefinition | null;
  currency?: string;
}

const TotalLiability = ({ market, marketDefinition, currency }: TotalLiabilityProps) => {
  const { t } = useTranslation();

  const selectedBets = useSelector(getSelectedBetsForWhatIf);

  const { translationKey: translationKeyLiability, isEnabled: isEnabledLiability } = useTooltip(BETSLIP_LIABILITY);
  const { translationKey: translationKeyWinner, isEnabled: isEnabledWinner } = useTooltip(WINNERS_DIFFERENT_EXPOSURE);

  const liability = getLiability({
    backs: map(selectedBets?.[market.marketId]?.[BetTypes.BACK], selection => selection),
    lays: map(selectedBets?.[market.marketId]?.[BetTypes.LAY], selection => selection),
    market: market,
    marketDefinition: marketDefinition
  });

  const { noFormattedAmount: liabilityFormatted } = useFormatCurrency(
    roundFloat(liability || 0, TOTAL_LIABILITY_PRECISION),
    currency ?? '',
    {
      isCheckIndian: true,
      noRounding: true
    }
  );

  return (
    <div className={styles.totalLiability}>
      <span
        data-tooltip-id={'tooltip'}
        data-tooltip-html={isEnabledLiability ? unescape(t(translationKeyLiability)) : ''}
        className={isEnabledLiability ? 'cursor-help' : ''}
      >
        {t('betslip.labels.liability')}:
      </span>
      <div className={styles.totalLiability__value}>
        <span
          data-tooltip-id={'tooltip'}
          data-tooltip-html={isEnabledWinner ? unescape(t(translationKeyWinner)) : ''}
          className={isEnabledWinner ? 'cursor-help' : ''}
        >
          {liabilityFormatted}
        </span>
      </div>
    </div>
  );
};

export default TotalLiability;
