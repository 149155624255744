import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { DEFAULT_CURRENCY } from 'constants/app';
import { VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET } from 'constants/placement';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { removeAllSelectedBets } from 'redux/modules/betslip';

import styles from './styles.module.scss';

const CurrencyMessage = ({ marketCurrency = DEFAULT_CURRENCY }: { marketCurrency?: string }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const errorMessageRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(errorMessageRef, () => onCancel);

  const onCancel = () => {
    dispatch(removeAllSelectedBets());
  };

  return (
    <div className={classNames(styles.multiCurrencyMessage)} ref={errorMessageRef}>
      {t(VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET, { currency_ISO_code: marketCurrency })}
      <button className={classNames(styles.multiCurrencyMessage__btnClose)}>
        <i className="fa fa-times" aria-hidden="true" onClick={onCancel} />
      </button>
    </div>
  );
};

export default CurrencyMessage;
