import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { reduce } from 'lodash';

import QuickBets from 'components/Betslip/components/QuickBets';
import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import Loader, { CircleColors } from 'components/Loader';
import { inlinePlacementBranding as branding } from 'constants/branding';
import {
  INLINE_PLACEMENT_LARGE_SIZE,
  INLINE_PLACEMENT_SELECTION,
  INLINE_PLACEMENT_SMALL_SIZE
} from 'constants/inlinePlacement';
import {
  HIDE_INLINE_PLACEMENT_TIMEOUT,
  LOADING_TIMEOUT_MESSAGE,
  VALIDATION_ERROR_BET_OUTDATED_LINE,
  VALIDATION_ERROR_BET_OUTDATED_ODDS,
  VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET
} from 'constants/placement';
import useConfirmBets from 'hooks/useConfirmBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { usePlacementData } from 'hooks/usePlacement';
import {
  getBalanceWsEnabled,
  getBetslipSpinnerTime,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getLineMarketsSwitchBackLayOnCricket,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { setRGErrorMessage } from 'redux/modules/betslip';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { setCurrentBetActionForAll } from 'redux/modules/currentBets';
import { TCurrentBet, TCurrentBetActionPayload } from 'redux/modules/currentBets/type';
import {
  failureInlinePlacedBet,
  removeInlineSelectedBet,
  successInlinePlacedBet,
  updateInlineSelectedBet
} from 'redux/modules/inlinePlacement';
import { getInlineSelectedBetByMarket } from 'redux/modules/inlinePlacement/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getMarketPricesById } from 'redux/modules/marketsPrices/selectors';
import {
  getMarketDataById,
  getMarketPricesById as getMarketPricesByIdAndBlock
} from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { hideInlinePlacementWhatIf } from 'redux/modules/whatIf';
import { MarketStatus, PageBlocks, PlacementPage, SportId } from 'types';
import { BetTypes, EBetErrorMessageTypes, TErrorMessage, TPrice, TSize } from 'types/bets';
import { ESizes, Statuses, TInlinePlacement } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { isSportPage } from 'utils';
import { getBestPriceFromMarketPrices, getBestPrices, isResponsibleGamblingError } from 'utils/betslip';

import InlinePlacedBetMessage from './components/InlinePlacedBetMessage';
import InlinePlacementConfirm from './components/InlinePlacementConfirm';
import InlinePlacementForm from './components/InlinePlacementForm';

import styles from './styles.module.scss';

interface InlinePlacementProps {
  /**
   * Bet data that consists of the market and selection information, price and size, offerId (if bet is placed)
   */
  bet: TInlineSelectedBet;

  /**
   * Place where component was added.
   */
  pageBlock?: PageBlocks;
  page?: PlacementPage;
}

const InlinePlacement = ({ bet, pageBlock = PageBlocks.HOME, page }: InlinePlacementProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const accountSettings = useSelector(getAccountSettings);
  const inlineSelectedBet = useSelector(getInlineSelectedBetByMarket(pageBlock, bet.marketId));
  const market = useSelector(getMarketDataById(pageBlock, bet.marketId, bet.sportId));
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const marketPricesByBlock = useSelector(getMarketPricesByIdAndBlock(pageBlock, bet.marketId));
  const marketPricesByMarketId = useSelector(getMarketPricesById(bet.marketId));
  const betslipSpinnerTime = useSelector(getBetslipSpinnerTime);
  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const [status, setStatus] = useState<Statuses>(Statuses.NEW);
  const [placedBet, setPlacedBet] = useState<TCurrentBet | null>(null);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>({ message: '' });
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [componentSize, setComponentSize] = useState(ESizes.SMALL);
  const [isTakeOffer, setIsTakeOffer] = useState(false);

  const marketPrices = marketPricesByBlock || marketPricesByMarketId;
  const marketPrice = getBestPrices({ marketPrices, ...bet });
  const bestPrices = getBestPriceFromMarketPrices(marketPrice, bet.marketType ?? '', bet.bettingType ?? '');

  const {
    quickstakeBetslip,
    showLabelsAboveInputFields,
    swapColorsFancyMarketsOnCricket,
    replaceBackLayWithUnderOver
  } = useDeviceSettings();

  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();
  const marketCurrency = marketPrices?.currency;

  const isCurrencyChanged = isMultiCurrencySupported && isMultiCurrencyChanged(marketCurrency);

  const isLineMarket = market?.description?.bettingType === BettingType.LINE ?? false;
  const isFancyMarket = pageBlock === PageBlocks.FANCY_VIEW && isLineMarket;

  const isCricket = market?.eventType?.id === SportId.CRICKET;
  const marketName = market?.marketName ?? '';

  const selectionName =
    market?.runners?.find(
      runner =>
        runner.selectionId === bet.selectionId &&
        (!isLineMarket ? +(runner.handicap || 0) === +(bet.handicap || 0) : true)
    )?.runnerName ?? '';

  const {
    bettingType = '',
    lineRangeInfo,
    marketType = '',
    priceLadderDescription,
    eachWayDivisor
  } = market?.description ?? {};
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && (isLineMarket || isFancyMarket);

  const { isConfirmBetsBeforePlacement } = useConfirmBets();

  const errorMessageRef = useRef<HTMLDivElement>(null);
  const spinnerTimeout = useRef<NodeJS.Timeout | null>(null);
  const componentRef = useRef<HTMLInputElement>(null);

  useOnClickOutside(errorMessageRef, () => {
    if (errorMessage.type === EBetErrorMessageTypes.TAKE_OFFER) {
      setErrorMessage({ message: '' });
    }
  });

  const onSuccessPlacement = (placedBets: TCurrentBet[]) => {
    if (placedBets.length) {
      dispatch(successInlinePlacedBet({ ...bet, placedBet: placedBets[0] }));
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    }
  };

  const onErrorPlacement = (error: TPlacementError | string) => {
    dispatch(failureInlinePlacedBet({ ...bet, error }));

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }

    if (placedBet) {
      setStatus(Statuses.PLACED_EDIT);
    } else {
      setStatus(Statuses.NEW);
    }
  };

  const onCancelledPlacement = () => {
    if (isPNCEnabled) {
      setIsTakeOffer(true);
      setValidationMessage({
        message: isLineMarket ? t(VALIDATION_ERROR_BET_OUTDATED_LINE) : t(VALIDATION_ERROR_BET_OUTDATED_ODDS),
        type: EBetErrorMessageTypes.TAKE_OFFER
      });
      setStatus(Statuses.NEW);
    }
  };

  const { editBetsHandler, cancelBetsHandler, placeBetsHandler } = usePlacementData({
    eachWayDivisor: market?.description?.eachWayDivisor,
    numberOfWinners: market?.numberOfWinners,
    successPlacement: onSuccessPlacement,
    errorPlacement: onErrorPlacement,
    onCancelledPlacement
  });

  useLayoutEffect(() => {
    if (componentRef.current) {
      const width = componentRef.current?.clientWidth;

      if (width < INLINE_PLACEMENT_SMALL_SIZE) {
        setComponentSize(ESizes.SMALL);
      } else if (width >= INLINE_PLACEMENT_LARGE_SIZE) {
        setComponentSize(ESizes.LARGE);
      } else {
        setComponentSize(ESizes.MEDIUM);
      }
    }
  }, [componentRef.current?.clientWidth]);

  useEffect(() => {
    if (bet.isPristinePrice) {
      setErrorMessage({ message: '' });
      setIsErrorMessage(false);
    }
  }, [bet.isPristinePrice]);

  useEffect(() => {
    if (isPNCEnabled && isTakeOffer) {
      dispatch(updateInlineSelectedBet({ ...bet, price: bestPrices }));
    }
  }, [bestPrices, status, isPNCEnabled]);

  useEffect(() => {
    setStatus(Statuses.NEW);
    setPlacedBet(null);
  }, [bet.selectionId, bet.type]);

  useEffect(() => {
    if (bet.size === undefined && accountSettings?.defaultStake && !isSportPage(location.pathname)) {
      const defStake = accountSettings.defaultStakes?.find(i => i?.defaultValue)?.value || '';
      dispatch(updateInlineSelectedBet({ ...bet, size: defStake }));
    }
  }, [accountSettings?.defaultStake, accountSettings?.defaultStakes, bet.size]);

  useEffect(() => {
    if (marketPrices?.marketDefinition?.status === MarketStatus.CLOSED) {
      dispatch(removeInlineSelectedBet(bet));
    }
  }, [marketPrices?.marketDefinition?.status]);

  useEffect(() => {
    let hideTimeout: ReturnType<typeof setTimeout> | null = null;

    if (status !== Statuses.PROGRESS && spinnerTimeout.current) {
      clearTimeout(spinnerTimeout.current);
    }

    if (status === Statuses.EDIT) {
      dispatch(hideInlinePlacementWhatIf(null));
    } else if (status === Statuses.NEW) {
      dispatch(hideInlinePlacementWhatIf(null));
    } else if (status === Statuses.PLACED) {
      dispatch(hideInlinePlacementWhatIf(false));
      hideTimeout = setTimeout(() => hideInlinePlacement(), HIDE_INLINE_PLACEMENT_TIMEOUT);
    } else if (status === Statuses.PLACED_EDIT && hideTimeout) {
      dispatch(hideInlinePlacementWhatIf(null));
      clearTimeout(hideTimeout);
    } else if (status === Statuses.PLACED_EDIT) {
      dispatch(hideInlinePlacementWhatIf(null));
    } else if (status === Statuses.HIDDEN) {
      dispatch(hideInlinePlacementWhatIf(null));
      dispatch(
        setCurrentBetActionForAll(
          reduce(
            inlineSelectedBet?.offers ?? {},
            (res: TCurrentBetActionPayload[], offer) => [...res, { offerId: offer.offerId, action: null }],
            []
          )
        )
      );
      dispatch(removeInlineSelectedBet(bet));
    } else if ([Statuses.CONFIRMED, Statuses.CONFIRMED_ENTER].includes(status)) {
      confirmBetPlacement({ placedUsingEnterKey: status === Statuses.CONFIRMED_ENTER });
    } else if (status === Statuses.PROGRESS) {
      spinnerTimeout.current = setTimeout(() => {
        setErrorMessage({ message: t(LOADING_TIMEOUT_MESSAGE) });
        setStatus(placedBet ? Statuses.PLACED_EDIT : Statuses.NEW);
      }, +betslipSpinnerTime * 1000);
    }

    return () => {
      if (hideTimeout) {
        clearTimeout(hideTimeout);
      }

      if (spinnerTimeout.current) {
        clearTimeout(spinnerTimeout.current);
      }
    };
  }, [status]);

  useEffect(() => {
    if (bet.placementError) {
      setErrorMessage({ message: bet.placementError });
      setIsErrorMessage(true);
      setStatus(Statuses.NEW);
    }
  }, [bet.placementError]);

  useEffect(() => {
    const currentOfferId = inlineSelectedBet?.currentOfferId;
    if (
      status === Statuses.PROGRESS &&
      currentOfferId &&
      inlineSelectedBet.offers &&
      inlineSelectedBet.offers[currentOfferId] &&
      [BetsStatusesTypes.MATCHED, BetsStatusesTypes.PLACED, BetsStatusesTypes.CANCELLED].includes(
        inlineSelectedBet.offers[currentOfferId].offerState
      )
    ) {
      setPlacedBet(inlineSelectedBet.offers[currentOfferId]);
      setStatus(Statuses.PLACED);
    }
  }, [inlineSelectedBet]);

  const setValidationMessage = (message: TErrorMessage) => {
    setErrorMessage(message);
  };

  const placeBet = ({ placedUsingEnterKey = false }: TInlinePlacement) => {
    setErrorMessage({ message: '' });

    if (isConfirmBetsBeforePlacement && !isTakeOffer) {
      setStatus(Statuses.CONFIRM);
    } else {
      setStatus(placedUsingEnterKey ? Statuses.CONFIRMED_ENTER : Statuses.CONFIRMED);
    }
  };

  const onCancel = () => {
    if (status === Statuses.PLACED_EDIT) {
      cancelPlacedBet();
    } else {
      hideInlinePlacement();
    }
  };

  const editBetBeforePlacement = () => {
    setStatus(Statuses.NEW);
  };

  const confirmBetPlacement = ({
    placedUsingEnterKey = false
  }: {
    price?: TPrice;
    size?: TSize;
    placedUsingEnterKey?: boolean;
  }) => {
    setStatus(Statuses.PROGRESS);
    dispatch(hideInlinePlacementWhatIf(true));

    if (placedBet) {
      editBetsHandler({
        marketId: bet.marketId,
        bets: [
          {
            ...placedBet,
            price: bet.price,
            size: bet.size,
            page
          }
        ]
      });
    } else {
      placeBetsHandler({
        marketId: bet.marketId,
        bets: [
          {
            price: bet.price,
            size: bet.size,
            handicap: bet.handicap,
            selectionId: bet.selectionId,
            side: bet.type.toUpperCase(),
            page
          }
        ],
        options: {
          isTakeOffer: isTakeOffer,
          placedUsingEnterKey
        }
      });
    }
  };

  const cancelPlacedBet = () => {
    setStatus(Statuses.PROGRESS);

    if (placedBet) {
      cancelBetsHandler({
        marketId: bet.marketId,
        bets: [{ ...placedBet }]
      });
    }
  };

  const editPlacedBet = () => {
    setStatus(Statuses.PLACED_EDIT);
  };

  const onCloseErrorHandler = () => {
    dispatch(failureInlinePlacedBet({ ...bet, error: '' }));

    setErrorMessage({ message: '' });
  };

  const hideInlinePlacement = () => {
    setStatus(Statuses.HIDDEN);
  };

  const isBetPlacingProcess =
    isCurrencyChanged || [Statuses.NEW, Statuses.EDIT, Statuses.CONFIRM, Statuses.PLACED_EDIT].indexOf(status) !== -1;

  const isBetFormEnabled = [Statuses.NEW, Statuses.EDIT, Statuses.PLACED_EDIT].indexOf(status) !== -1;

  const handlerQuickBets = useCallback(
    (value: string | number) => {
      dispatch(updateInlineSelectedBet({ ...bet, size: value }));
    },
    [bet]
  );

  const getTypeLabel = () => {
    if (isCricket) {
      if (lineBackLayToNoYes && isLineMarket) {
        return t('betslip.labels.type.' + (bet.type === BetTypes.BACK ? 'no' : 'yes'));
      }
    }
    if (!isCricket && isLineMarket && replaceBackLayWithUnderOver) {
      return t('betslip.labels.type.' + (bet.type === BetTypes.BACK ? 'under' : 'over'));
    }
    return t('betslip.labels.type.' + bet.type?.toLocaleLowerCase());
  };

  return (
    <>
      {status !== Statuses.HIDDEN && (
        <div
          className={classNames(styles.inlinePlacementWrapper, branding.WRAPPER, {
            [styles.inlinePlacementWrapper__inputLabels]: showLabelsAboveInputFields,
            [styles.inlinePlacementWrapper__xs]: componentSize === ESizes.SMALL,
            [styles.inlinePlacementWrapper__hiddeMarketName]: componentSize === ESizes.SMALL,
            [styles.inlinePlacementWrapper__selectionRow]: INLINE_PLACEMENT_SELECTION.includes(pageBlock),
            [branding.FANCY_SWAP]: isFancySwapColors
          })}
          ref={componentRef}
        >
          {isErrorMessage && !!errorMessage.message && (
            <div className={classNames(branding.ERROR, styles.inlinePlacementMessage)} ref={errorMessageRef}>
              <div
                className={styles.inlinePlacementMessage__text}
                dangerouslySetInnerHTML={{ __html: errorMessage.message }}
              />
              <button className={styles.inlinePlacementMessage__btnClose} onClick={onCloseErrorHandler}>
                <i className="fa fa-times" aria-hidden="true" />
              </button>
            </div>
          )}
          {isBetPlacingProcess && (
            <div
              className={classNames(
                styles.inlinePlacement,
                styles[`inlinePlacement__${bet.type.toLowerCase()}`],
                branding.COMPONENT,
                branding[bet.type]
              )}
            >
              <div className={styles.inlinePlacementBet}>
                <div className={styles.inlinePlacementMarket}>
                  <p>{getTypeLabel()}</p>
                  <p className={styles.inlinePlacementMarket__name}>
                    {selectionName} - {marketName}
                  </p>
                </div>
                <div className={styles.inlinePlacementEditing}>
                  {isCurrencyChanged ? (
                    <div
                      className={classNames(
                        styles.inlinePlacementMessage,
                        styles.inlinePlacementMessage__multiCurrencyError
                      )}
                    >
                      {t(VALIDATION_ERROR_DIFFERENT_CURRENCY_MARKET, { currency_ISO_code: marketCurrency })}
                      <button
                        className={classNames(
                          styles.inlinePlacementMessage__btnClose,
                          styles.inlinePlacementMessage__btnClose__multiCurrency
                        )}
                      >
                        <i className="fa fa-times" aria-hidden="true" onClick={onCancel} />
                      </button>
                    </div>
                  ) : (
                    <>
                      {isBetFormEnabled && (
                        <div>
                          <InlinePlacementForm
                            bet={bet}
                            status={status}
                            pageBlock={pageBlock}
                            marketType={marketType}
                            bettingType={bettingType}
                            lineRangeInfo={lineRangeInfo}
                            eachWayDivisor={eachWayDivisor}
                            priceLadderDescription={priceLadderDescription}
                            placedBet={placedBet}
                            isLineMarket={isLineMarket}
                            isPNCEnabled={isPNCEnabled}
                            isTakeOffer={isTakeOffer}
                            isInputLabels={showLabelsAboveInputFields}
                            isQuickStakes={quickstakeBetslip}
                            componentSize={componentSize}
                            onPlaceCallback={placeBet}
                            onCancelCallback={onCancel}
                            isErrorMessage={isErrorMessage}
                            setIsErrorMessage={setIsErrorMessage}
                            setValidationMessage={setValidationMessage}
                            currency={marketCurrency}
                          />
                          <QuickBets handler={handlerQuickBets} byCenter isShort />
                        </div>
                      )}
                      {status === Statuses.CONFIRM && (
                        <InlinePlacementConfirm
                          bet={bet}
                          marketType={marketType}
                          bettingType={bettingType}
                          lineRangeInfo={lineRangeInfo}
                          eachWayDivisor={eachWayDivisor}
                          isLineMarket={isLineMarket}
                          isInputLabels={showLabelsAboveInputFields}
                          componentSize={componentSize}
                          onEditBtnHandler={editBetBeforePlacement}
                          onConfirmBtnHandler={confirmBetPlacement}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
          {status === Statuses.PROGRESS && (
            <div className={classNames(branding.COMPONENT, branding[bet.type])}>
              <div className={styles.inlinePlacementLoading}>
                <Loader circleColor={CircleColors.DARK_GRAY} />
              </div>
            </div>
          )}
          {status === Statuses.PLACED && placedBet && (
            <InlinePlacedBetMessage
              bet={placedBet}
              componentSize={componentSize}
              onClose={hideInlinePlacement}
              onCancelPlacedBetBtnHandler={cancelPlacedBet}
              onEditPlacedBetBtnHandler={editPlacedBet}
            />
          )}
        </div>
      )}
      <RGModalMessage />
    </>
  );
};

export default InlinePlacement;
