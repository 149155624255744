import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';

import { TCurrentBet } from '../currentBets/type';

import { TPlacementMessageState } from './type';

const initialState: TPlacementMessageState = {
  bets: {}
};

const slice = createSlice({
  name: SLICES_NAMES.PLACEMENT_MESSAGE,
  initialState,
  reducers: {
    setMessageBet: (state, { payload }: PayloadAction<TCurrentBet>) => {
      state.bets[payload.marketId] = payload;
    },
    removeMessageBet: (state, { payload }: PayloadAction<TCurrentBet>) => {
      const bet = state.bets[payload.marketId];

      if (bet?.offerId === payload.offerId) {
        delete state.bets[payload.marketId];
      }
    }
  }
});

export const { removeMessageBet, setMessageBet } = slice.actions;

export default slice.reducer;
