import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import CashOut from 'components/CashOut';
import FavoriteStar from 'components/FavoriteStar';
import InPlayStatus from 'components/InPlayStatus';
import Loader, { CircleColors } from 'components/Loader';
import Modal from 'components/Modal';
import NetCommission from 'components/NetCommission';
import Rules from 'components/Rules';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { getAppDevice, getIsIframeEnabled, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { TFavorite } from 'redux/modules/favorites/type';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesCurrencyById,
  getMarketPricesTurnInPlayEnabled,
  getMatchedAmountByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { CurrencySettingsPlacement } from 'types';
import { IMarketRules, MobileMarketInfoRowViewOptions } from 'types/markets';
import { changeLinkTargetInString, getEnvironmentRootPath } from 'utils/navigation';

import MobileInfoRow from './components/MobileInfoRow';

import styles from './styles.module.scss';

interface MarketInfoRowProps {
  /**
   * Is cashOut enabled
   */
  isCashOutEnabled: boolean;
  /**
   * Is market in play or not
   */
  marketStartTime: number;
  /**
   * Market id
   */
  marketId: string;
  /**
   * Sport id
   */
  sportId: string;
  /**
   * Market name
   */
  marketName: string;
  /**
   * Markets rules collection
   */
  marketsRules: Record<string, IMarketRules> | null;
  /**
   * Is rules loaded or not
   */
  rulesLoading: boolean;
  /**
   * Callback for fetch market rules
   */
  onFetchMarketRules: () => void;
  /**
   * Callback for market refresh
   */
  onRefreshMarket?: () => void;
  /**
   * Mobile view options
   */
  mobileViewOptions?: MobileMarketInfoRowViewOptions;
  isShowPlTable?: boolean;
  isOpenPLTable?: string;
  handlerPLTable?: () => void;
  marketType?: string;
  isShowFavorites?: boolean;
  favoriteData?: TFavorite;
}

const MarketInfoRow = ({
  isCashOutEnabled,
  marketStartTime,
  marketId,
  marketName,
  sportId,
  marketsRules,
  rulesLoading,
  onFetchMarketRules,
  onRefreshMarket = () => {},
  mobileViewOptions,
  isShowPlTable,
  isOpenPLTable,
  handlerPLTable,
  marketType,
  isShowFavorites,
  favoriteData
}: MarketInfoRowProps) => {
  const { t } = useTranslation();

  const device = useSelector(getAppDevice);
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const totalMatched = useSelector(getMatchedAmountByMarketPricesId(marketId));
  const currency = useSelector(getMarketPricesCurrencyById(marketId));
  const isShowInPlay = useSelector(getMarketPricesTurnInPlayEnabled(marketId));

  const [openRulesModal, setOpenRulesModal] = useState<boolean>(false);

  const { formattedAmount: matched, noFormattedAmount: tooltipMatched } = useFormatCurrency(
    totalMatched || 0,
    currency,
    {
      isCheckIndian: true,
      placement: CurrencySettingsPlacement.TOOLTIP
    }
  );

  const marketTime = useLongDateFormat({
    time: marketStartTime ?? 0,
    hasYear: true,
    divider: ', '
  });

  const handleOpenRulesModal = () => {
    onFetchMarketRules();

    if (!mobileViewOptions?.noRulesModal) {
      setOpenRulesModal(true);
    }
  };

  return (
    <>
      {device === Devices.DESKTOP ? (
        <div className={classNames(styles.marketDetailInfo, 'biab_market-info')}>
          <div className={styles.marketDetailInfo__left}>
            {isShowFavorites && favoriteData && (
              <div className={styles.favouriteIcon}>
                <FavoriteStar entryData={favoriteData} className={styles.eventMarket__starIcon} />
              </div>
            )}
            {isShowInPlay && <InPlayStatus isInPlay={isInPlay} showLabel />}
            {isCashOutEnabled && <CashOut />}
            <div onClick={handleOpenRulesModal}>
              <Rules />
            </div>
            {isShowPlTable && (
              <div className={styles.plTable}>
                <input
                  type="checkbox"
                  id="PLTable"
                  name="PLTable"
                  checked={!!isOpenPLTable}
                  onChange={handlerPLTable}
                />
                <label htmlFor="PLTable" className={styles.plTable__title}>
                  {t('market.PLTable')}
                </label>
              </div>
            )}
            <NetCommission />
          </div>

          <div className={styles.marketDetailInfo__right}>
            <div
              data-tooltip-id="tooltip"
              data-tooltip-html={unescape(
                t(`${isPNCEnabled ? 'pnc.' : ''}tooltip.matchedTotal`, { amount: tooltipMatched })
              )}
              className={classNames('cursor-help biab_market-tv', styles.marketDetailInfo__matched)}
            >
              {t(`${isPNCEnabled ? 'pnc.' : ''}market.matched`)}:
              <span className={styles.marketDetailInfo__matchedTotal}>{matched}</span>
            </div>
            <button
              className={classNames('biab_market-refresh', styles.marketDetailInfo__refresh)}
              onClick={onRefreshMarket}
            >
              {t('market.refresh')}
            </button>
          </div>
        </div>
      ) : (
        <MobileInfoRow
          marketType={marketType}
          marketName={marketName}
          sportId={sportId}
          marketId={marketId}
          isCashOutEnabled={isCashOutEnabled}
          onOpenRules={handleOpenRulesModal}
          onRefreshMarket={onRefreshMarket}
          viewOptions={mobileViewOptions}
        />
      )}

      <Modal
        clickableBackground
        open={openRulesModal}
        onClose={() => setOpenRulesModal(false)}
        title={
          rulesLoading
            ? t('marketRules.loading')
            : `${marketsRules?.[marketId]?.marketName || ''} - ${t('rules.title')}`
        }
      >
        {rulesLoading ? (
          <Loader circleColor={CircleColors.BLACK} />
        ) : (
          <div className={styles.marketDetailInfo__marketModal}>
            <div>
              {t('marketRules.eventStartTime')}:
              <br />
              {marketTime}
            </div>
            <div
              className={styles.marketDetailInfo__modalText}
              dangerouslySetInnerHTML={{
                __html: changeLinkTargetInString({
                  htmlString:
                    marketsRules?.[marketId]?.rules.replace(/CONTEXT_ROOT_PATH/g, getEnvironmentRootPath()) || '',
                  isIframeEnabled
                })
              }}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

export default MarketInfoRow;
