import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';

import { failureGetCancelActionStatus, fetchCancelActionStatus, successGetCancelActionStatus } from './index';
import { TCancelActionData } from './type';

function* getCancelAllActionStatusWorker(action: ReturnType<typeof fetchCancelActionStatus>) {
  try {
    const response: TCancelActionData = yield call<any>(
      api.placement.getCancelActionStatuses,
      action.payload.cancelActionId
    );

    yield put(successGetCancelActionStatus({ cancelActionId: action.payload.cancelActionId, data: response }));
  } catch (error: any) {
    yield put(failureGetCancelActionStatus(error));
  }
}

export default function* saga() {
  yield all([takeLatest(fetchCancelActionStatus.type, getCancelAllActionStatusWorker)]);
}
