import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ExchangeTypes, NavigationButtons, PLBetType } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getAVBalance, getBalance } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';

import styles from '../styles.module.scss';

const Balance = () => {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.PROFITANDLOSS_BETTYPE, CookieNames.EXCHANGE_TYPE]);

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const accountBalance = useSelector(getBalance);
  const accountAVBalance = useSelector(getAVBalance);

  const { selectedTab } = useAccountUrlParams();
  const isAsianView =
    selectedTab === NavigationButtons.ProfitAndLoss
      ? cookies.PROFITANDLOSS_BETTYPE === PLBetType.AsianView
      : cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView;

  const { noFormattedAmount: formattedBalance } = useFormatCurrency(
    (isAsianView ? accountAVBalance || accountBalance : accountBalance || accountAVBalance) || 0,
    '',
    {
      noRounding: true,
      isCheckIndian: true
    }
  );

  const isBalanceVisible = isLoggedIn;
  const fullLabel = t(`account.labels.balance.${isAsianView ? 'asianView' : 'sportsGames'}`);
  const hasFullLabel = accountAVBalance && accountBalance;

  return (
    <>
      {isBalanceVisible && (
        <div className={classNames('biab_info', styles.accountBalance)}>
          <span className={styles.accountBalance__text}>{hasFullLabel ? fullLabel : t('account.labels.balance')}:</span>
          <span id="biab_balance">{formattedBalance}</span>
        </div>
      )}
    </>
  );
};

export default Balance;
