import BetLabelsItemTpl from 'components/Betslip/components/BetLabels/components/BetLabelsItemTpl';

type BetLabelsTplProps = {
  /** Label if Win */
  labelW: string;
  labelS?: string;
  /** Label if Loss */
  labelL: string;
  /** Profit if Win */
  profitLossW?: number | null;
  profitLossS?: number | null;
  /** Liability if Loss */
  profitLossL?: number | null;
  /** Currency */
  currency?: string;
};

const BetLabelsTpl = ({
  labelW,
  labelS,
  labelL,
  profitLossW,
  profitLossS,
  profitLossL,
  currency
}: BetLabelsTplProps) => {
  return (
    <div>
      <BetLabelsItemTpl label={labelW} profitLoss={profitLossW} currency={currency} />
      {labelS && <BetLabelsItemTpl label={labelS} profitLoss={profitLossS} currency={currency} />}
      <BetLabelsItemTpl label={labelL} profitLoss={profitLossL} currency={currency} />
    </div>
  );
};

export default BetLabelsTpl;
