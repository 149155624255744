import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { entries, groupBy, sortBy } from 'lodash';

import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewProfitLossButton from 'components/AsianViewProfitLossButton';
import {
  ASIAN_OUTRIGHT_COMPETITION_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID,
  ASIAN_SINGLE_VIEW_SPORT_ID,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import { AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM } from 'constants/betList';
import { ASIAN_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import AVBetListMobileMarketsContainer from '../AVBetListMobileMarketsContainer/AVBetListMobileMarketsContainer';

import styles from './AVBetListMobileEventCompetitionCard.module.scss';

type AVBetListMobileEventCompetitionCardProps = {
  betsGroup: TCurrentBet[];
};

const AVBetListMobileEventCompetitionCard = ({ betsGroup }: AVBetListMobileEventCompetitionCardProps) => {
  const {
    isOutright,
    eventName,
    competitionName,
    eventTypeId: sportId,
    marketStartDate,
    marketId,
    eventId,
    competitionId,
    eventInPlay
  } = betsGroup[0];

  const marketsContainers = useMemo(() => {
    const matchOddsBets: TCurrentBet[] = [];
    const noMatchOddsBets: TCurrentBet[] = [];

    betsGroup.forEach(bet => {
      if (bet.marketType === MARKET_TYPES.matchOdds) {
        matchOddsBets.push(bet);
      } else {
        noMatchOddsBets.push(bet);
      }
    });
    const marketsContainersVal = entries(
      groupBy(sortBy(noMatchOddsBets, 'marketName'), ({ marketId: id }) => `${id} `)
    );
    const matchOddsBetsGroup: [string, TCurrentBet[]][] = matchOddsBets.length
      ? [[matchOddsBets[0].marketId, matchOddsBets]]
      : [];

    return matchOddsBetsGroup.concat(marketsContainersVal);
  }, [betsGroup]);

  const titleEl = (
    <Link
      to={{
        pathname: `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${AsianViewTimeFilter.Today}/marketLink/${
          isOutright ? AsianViewMarketLink.OUTRIGHT : AsianViewMarketLink.HDP_AND_OU
        }`,
        search: isOutright
          ? `${ASIAN_OUTRIGHT_COMPETITION_ID}=${competitionId}&${AV_OUTRIGHT_FROM_BET_LIST_MOBILE_QUERY_PARAM}=true`
          : `${ASIAN_SINGLE_VIEW_SPORT_ID}=${sportId}&${ASIAN_SINGLE_VIEW_EVENT_ID}=${eventId}`
      }}
      className={classNames(styles.card__header__title, {
        [styles.card__header__title__outright]: isOutright
      })}
    >
      {isOutright ? competitionName : eventName}
    </Link>
  );

  return (
    <div className={styles.card}>
      <hr
        className={classNames(styles.card__topLine, {
          [styles.card__topLine__live]: eventInPlay
        })}
      />
      {isOutright ? (
        titleEl
      ) : (
        <div className={styles.card__header}>
          <div className={styles.card__header__left}>
            {marketStartDate && (
              <AsianViewInPlayCell
                marketStartTime={marketStartDate}
                marketId={marketId}
                isEventUpdatesData
                eventId={eventId}
                isInPlay={eventInPlay}
                highlightGoal
                containerClassName={classNames(styles.inPlayCell, {
                  [styles.inPlayCell__live]: eventInPlay
                })}
                isMobileBetListPage
                noInPlayContainerClassName={styles.inPlayCell__noInPlay}
                periodLabelClassName={styles.inPlayCell__periodLabel}
              />
            )}
            {titleEl}
          </div>
          <AsianViewProfitLossButton
            eventId={eventId}
            marketId={marketId}
            className={styles.card__header__plBtn}
            iconClassName={styles.card__header__plBtn__icon}
            isBrandingStyles={false}
          />
        </div>
      )}
      <section className={styles.card__marketsContainers}>
        {marketsContainers.map(([containerMarketId, containerBetGroup]) => {
          return (
            <AVBetListMobileMarketsContainer
              key={containerMarketId}
              marketId={containerMarketId.trim()}
              betsGroup={containerBetGroup}
            />
          );
        })}
      </section>
    </div>
  );
};

export default AVBetListMobileEventCompetitionCard;
