import { useSelector } from 'react-redux';

import BetsIndicator from 'components/SearchContent/components/BetsIndicator';
import { getCurrentEventBetsByType } from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';

interface BetsIndicatorMarketPageProps {
  eventTypeId: string;
  marketId: string;
  eventId: string;
}

const BetsIndicatorMarketPage = ({ eventTypeId, marketId, eventId }: BetsIndicatorMarketPageProps) => {
  const matchedBets = useSelector(getCurrentEventBetsByType(eventId, MatchTypes.MATCHED));
  const unmatchedBets = useSelector(getCurrentEventBetsByType(eventId, MatchTypes.UNMATCHED));

  return (
    <BetsIndicator
      matchedBets={matchedBets}
      unmatchedBets={unmatchedBets}
      eventTypeId={eventTypeId}
      marketId={marketId}
      inMarketsTableRow
    />
  );
};

export default BetsIndicatorMarketPage;
