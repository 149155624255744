import { ChangeEvent, LegacyRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { add, subtract } from 'lodash';

import { useStakeRegexPattern } from 'hooks/usePrecision';
import useSizeValidation from 'hooks/useSizeValidation';
import {
  getCurrency,
  getCurrencySteps,
  getMobileSettingsVirtualKeyboardBetslip
} from 'redux/modules/appConfigs/selectors';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { BetTypes } from 'types/bets';
import { valueToNumber } from 'utils/betslip';
import { formatFloatNumber } from 'utils/betValidation';

interface SizeInputProps {
  sizeValue: string | number;
  betType: BetTypes;
  setIsValidSize: (isValid: boolean) => void;
  setErrorMessage: (message: string) => void;
  setSizeValue: (sizeValue: string | number) => void;
  isQuickBets?: boolean;
  setUseQuickBets?: (isUseQuickBets: boolean) => void;
  setFocused: () => void;
  sizeInputRef: LegacyRef<HTMLInputElement>;
  currentFocus: EBetFocusFields | null;
  setDirtyInput: (isDirty: EBetFocusFields | null) => void;
  dirtyInput?: EBetFocusFields | null;
}

function SizeInput({
  sizeValue,
  betType,
  setSizeValue,
  setIsValidSize,
  setErrorMessage,
  isQuickBets,
  setUseQuickBets,
  setFocused,
  sizeInputRef,
  currentFocus,
  setDirtyInput,
  dirtyInput
}: SizeInputProps) {
  const { t } = useTranslation();

  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);
  const currencySteps = useSelector(getCurrencySteps);
  const currency = useSelector(getCurrency);

  const [isValidationEnabled, setIsValidationEnabled] = useState<boolean>(false);

  const getStakeRegexPattern = useStakeRegexPattern();
  const minSize = currency.minBetSize;
  const maxSize = currency.maxBetSize;
  const isPlusBtnEnabled = +(sizeValue || 0) < maxSize;
  const isMinusBtnEnabled = +(sizeValue || 0) > minSize;
  const step = currency.step;

  const { minusStep, plusStep } = useMemo(() => {
    let minusSizeStep = 0,
      plusSizeStep = 0;

    currencySteps.forEach(curStep => {
      if (+sizeValue >= curStep.min && (curStep.max == null || +sizeValue < curStep.max)) {
        plusSizeStep = step > curStep.step ? step : curStep.step;
      }
      if (+sizeValue > curStep.min && (curStep.max == null || +sizeValue <= curStep.max)) {
        minusSizeStep = step > curStep.step ? step : curStep.step;
      }
    });

    return { minusStep: minusSizeStep, plusStep: plusSizeStep };
  }, [currencySteps, sizeValue, step]);

  const { setValue, isValid, validValue, errorMessage } = useSizeValidation(betType, currency.currencyCode);

  useEffect(() => {
    if (dirtyInput && dirtyInput === currentFocus) {
      onBlur();
      setDirtyInput(null);
    }
  }, [currentFocus, dirtyInput]);

  useEffect(() => {
    setValue(sizeValue);
  }, [sizeValue]);

  useEffect(() => {
    if (isQuickBets && setUseQuickBets) {
      setTimeout(() => {
        onBlur();
        setUseQuickBets(false);
      }, 100);
    }
  }, [isQuickBets, isValid, setUseQuickBets, validValue]);

  useEffect(() => {
    setIsValidSize(isValid);
    if (!isValid && isValidationEnabled) {
      setErrorMessage(errorMessage.message);
      if (validValue && validValue != sizeValue) {
        setSizeValue(validValue);
      }
      setIsValidationEnabled(false);
    }
  }, [isValid, errorMessage.message, isValidationEnabled]);

  const onSizeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = valueToNumber(event.target.value);

    if (changedValue === '' || getStakeRegexPattern.test(changedValue)) {
      setSizeValue(changedValue);
    }
    setIsValidationEnabled(false);
  };

  const onBlur = () => {
    if (errorMessage && errorMessage.message && sizeValue) {
      if (!isValid) {
        setErrorMessage(errorMessage.message);
        if (typeof validValue !== 'undefined') {
          setSizeValue(validValue.toString());
        }
      }
    }
  };

  const onFocus = () => {
    setFocused();
    onBlur();
  };

  const onAdd = () => {
    setFocused();
    if (isPlusBtnEnabled && plusStep) {
      if (!sizeValue) {
        setSizeValue(minSize);
      } else {
        setSizeValue(formatFloatNumber(add(parseFloat(sizeValue.toString()), plusStep)));
      }
      setIsValidationEnabled(true);
    }
  };

  const onSubtract = () => {
    setFocused();
    if (isMinusBtnEnabled && minusStep) {
      setSizeValue(formatFloatNumber(subtract(parseFloat(sizeValue.toString()), minusStep)));
      setIsValidationEnabled(true);
    }
  };

  return (
    <div className="biab_custom-stake-holder">
      <span className="biab_input-group-btn">
        <button
          disabled={!isMinusBtnEnabled}
          type="button"
          onClick={onSubtract}
          className={classNames('biab_btn biab_btn-danger biab_btn-number', { biab_disabled: !isMinusBtnEnabled })}
          data-type="minus"
        >
          <i className="fa2 fa2-minus" aria-hidden="true" />
        </button>
      </span>
      <input
        className="biab_size biab_error"
        name="size"
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onSizeChange}
        value={sizeValue || ''}
        type="text"
        lang="en-US"
        inputMode="decimal"
        placeholder={t('betslip.labels.size.back')}
        readOnly={virtualKeyboardBetslip}
        ref={sizeInputRef}
      />
      <span className="biab_input-group-btn">
        <button
          disabled={!isPlusBtnEnabled}
          type="button"
          onClick={onAdd}
          className={classNames('biab_btn biab_btn-danger biab_btn-number', { biab_disabled: !isPlusBtnEnabled })}
          data-type="plus"
        >
          <i className="fa2 fa2-plus" aria-hidden="true" />
        </button>
      </span>
    </div>
  );
}

export default SizeInput;
