import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { toNumber } from 'lodash';

import BetslipSkeleton from 'components/Skeleton/BetslipSkeleton';
import { tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { GAMES_BASE_URL, SPORT_BASE_URL } from 'constants/locations';
import { MARKET_TYPES } from 'constants/marketTypes';
import { OPEN_BETS_NOTIFICATION_TIME } from 'constants/placement';
import useBetMessages from 'hooks/useBetMessages';
import useConvertTimeToString from 'hooks/useConvertTimeToString';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import {
  removePlacedBet,
  setErrorMessage,
  setFirstOpenedBetFocused,
  setPlacedBetsToUpdate,
  setPlacementMessage
} from 'redux/modules/betslip';
import {
  getBetslipLoading,
  getBetslipType,
  getIsFirstOpenedBetFocused,
  getPlacedBets
} from 'redux/modules/betslip/selectors';
import { EBetFocusFields, EPlaceBetsStates } from 'redux/modules/betslip/type';
import { cleanCashOutPlacedId } from 'redux/modules/cashOut';
import { getCashOutPlacedId } from 'redux/modules/cashOut/selectors';
import { setCurrentBetAction, updateOfferPriceAndSize } from 'redux/modules/currentBets';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { getCurrentGameData } from 'redux/modules/games/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { SportId } from 'types';
import { BetTypes, MatchTypes, TPrice, TProfit, TSize } from 'types/bets';
import { EBetMessageTypes, EBetslipTypes, EPlacementType } from 'types/betslip';
import { BettingType } from 'types/markets';
import { isCancelled, isEachWayMarketType, isPartiallyMatched } from 'utils/betslip';
import { calculateLiability } from 'utils/liability';

import BetForm from '../BetForm';
import BetLabels from '../BetLabels';

import BetActions from './components/BetActions';
import BetInfo from './components/BetInfo';
import BetMessage from './components/BetMessage';
import BetPersistenceType from './components/BetPersistenceType';

import styles from './styles.module.scss';

const OpenedBet = ({
  bet,
  matchType,
  index,
  betIndex,
  isFirst,
  isLast
}: {
  bet: TCurrentBet;
  matchType: MatchTypes;
  index: number;
  betIndex: number;
  isFirst?: boolean;
  isLast?: boolean;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const placedBets = useSelector(getPlacedBets);
  const isFirstOpenedBetFocused = useSelector(getIsFirstOpenedBetFocused);
  const betslipType = useSelector(getBetslipType);
  const accountSettings = useSelector(getAccountSettings);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const gameData = useSelector(getCurrentGameData);
  const isBetslipLoading = useSelector(getBetslipLoading);
  const cashOutPlacedId = useSelector(getCashOutPlacedId(bet.marketId));

  const [updatedPrice, setUpdatedPrice] = useState<TPrice>(bet.price);
  const [updatedSize, setUpdatedSize] = useState<TSize>(bet.sizeRemaining);
  const [updatedPersistenceType, setUpdatedPersistenceType] = useState(bet.persistenceType);
  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isSizeValid, setIsSizeValid] = useState(true);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [messageType, setMessageType] = useState<EBetMessageTypes | null>(null);

  const { betMessage, betslipMessage } = useBetMessages({ bet });
  const { fillOrKill, placeBetWithEnterKey, swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const { hoursAndMinutes } = useConvertTimeToString({ startDate: toNumber(bet.marketStartDate || 0) });

  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isFillOrKillEnabled = fillOrKill && accountSettings?.fillOrKillEnabled;
  const placedBetStatus = placedBets[bet.offerId];
  const isFirstBetInList = index === 0;
  const isEachWay = isEachWayMarketType(bet.marketType);
  const startTimeFormatted = bet.marketStartDate ? hoursAndMinutes : '';
  const isEventLinkEnabled = bet.eventId && bet.marketType !== MARKET_TYPES.dailyGoals;
  const lowerCaseMatchType = matchType.toLowerCase();
  const lineRangeInfo = useMemo(
    () => ({
      marketUnit: bet.marketUnit || '',
      minUnitValue: bet.minUnitValue,
      maxUnitValue: bet.maxUnitValue,
      interval: bet.interval
    }),
    [bet]
  );
  const isUpdateEnabled =
    (isPriceValid && bet.price !== toNumber(updatedPrice)) ||
    (isSizeValid && bet.sizeRemaining !== toNumber(updatedSize)) ||
    bet.persistenceType !== updatedPersistenceType;
  const eventLink = isGameBetslip
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/event/${bet.eventId}`;

  const marketLink = isGameBetslip
    ? `${GAMES_BASE_URL}/${bet.eventTypeId}`
    : `${SPORT_BASE_URL}/${bet.eventTypeId}/market/${bet.marketId}`;
  let fullMarketName = bet.marketName;
  let focusedField: EBetFocusFields | null = null;

  if (bet.raceName) {
    fullMarketName = `${startTimeFormatted} ${bet.raceName} - ${bet.marketName}`;
  } else if (isGameBetslip && bet.sportName) {
    fullMarketName = bet.sportName;
  }

  if (matchType === MatchTypes.UNMATCHED && isFirst && isFirstOpenedBetFocused) {
    focusedField = !isPNCEnabled || isGameBetslip ? EBetFocusFields.PRICE : EBetFocusFields.SIZE;
  }

  const isCricket = bet.eventTypeId.toString() === SportId.CRICKET;
  const isLineMarket = bet.bettingType === BettingType.LINE;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;

  const hideTimeout = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (betslipMessage.message && matchType === MatchTypes.MATCHED) {
      /** Instantly placed bet message show in the top of Betslip */
      if (placedBets[bet.offerId] === EPlacementType.PLACE) {
        if (!isPartiallyMatched(bet)) {
          dispatch(setPlacementMessage(betslipMessage));
        }
        dispatch(removePlacedBet(bet.offerId));
        if (betslipMessage.isTriggeredByCashOut && bet.offerId === cashOutPlacedId) {
          dispatch(cleanCashOutPlacedId({ marketId: bet.marketId }));
        }
        /** Updated bet message after fully matching */
      } else if (placedBets[bet.oldOfferId] === EPlacementType.UPDATE) {
        dispatch(setPlacementMessage(betslipMessage));
        dispatch(removePlacedBet(bet.oldOfferId));
      }
    }
  }, [betslipMessage.message]);

  useEffect(() => {
    if (placedBetStatus && matchType === MatchTypes.UNMATCHED && !isPartiallyMatched(bet)) {
      dispatch(removePlacedBet(bet.offerId));
    }
  }, [placedBetStatus, matchType, bet]);

  useEffect(() => {
    const isMatched =
      bet.action === ECurrentBetActions.FULLY_MATCHED || bet.action === ECurrentBetActions.PARTIALLY_MATCHED;
    const isPlacedMatched =
      bet.action === ECurrentBetActions.PLACED_PARTIALLY_MATCHED && placedBets[bet.offerId] === EPlacementType.PLACE;

    if (matchType === MatchTypes.UNMATCHED && (isMatched || isPlacedMatched)) {
      /** Fully matched bet message fully covers the bet. Partially matched is shown above the bet */
      setMessageType(
        bet.action === ECurrentBetActions.FULLY_MATCHED
          ? EBetMessageTypes.FULLY_MATCHED
          : EBetMessageTypes.PARTIALLY_MATCHED
      );
      hideOpenedBetMessageAfterTime();
    } else if (bet.action === ECurrentBetActions.CANCELLING && isCancelled(bet)) {
      setIsLoading(false);
      /** Bet was cancelled */
      setMessageType(EBetMessageTypes.CANCELLED);
      hideOpenedBetMessageAfterTime();
    } else if (bet.action === ECurrentBetActions.CANCELLING) {
      setIsLoading(true);
    } else if (isFillOrKillEnabled && bet.action === ECurrentBetActions.EDITING && isCancelled(bet)) {
      dispatch(
        setErrorMessage(
          t('betslip.messages.cancelledPlacedBet', {
            sizePlaced: bet.sizePlaced,
            odds: bet.price
          })
        )
      );
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
    } else if (
      betslipMessage.message &&
      matchType === MatchTypes.MATCHED &&
      bet.action === ECurrentBetActions.FULLY_MATCHED_AFTER_EDITING
    ) {
      /** Bet was fully matched after updating */
      dispatch(setPlacementMessage(betslipMessage));
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
    }

    return () => {
      if (hideTimeout.current) {
        clearTimeout(hideTimeout.current);
      }
    };
  }, [bet.offerState, bet.action, betslipMessage.message]);

  useEffect(() => {
    if (isFirst && isFirstOpenedBetFocused && matchType === MatchTypes.UNMATCHED) {
      dispatch(setFirstOpenedBetFocused(false));
    }
  }, [isFirst, isFirstOpenedBetFocused, matchType]);

  useEffect(() => {
    if (isGameBetslip) {
      dispatch(
        setCurrentBetAction({
          offerId: bet.offerId,
          action: null
        })
      );
    }
  }, [isGameBetslip, gameData?.round, bet.offerId]);

  const hideOpenedBetMessageAfterTime = () => {
    const hideOpenedBet =
      bet.action === ECurrentBetActions.FULLY_MATCHED || bet.action === ECurrentBetActions.CANCELLING;

    hideTimeout.current = setTimeout(() => {
      setMessageType(null);

      if (hideOpenedBet) {
        setIsHidden(true);
      }

      dispatch(
        setCurrentBetAction({
          offerId: bet.offerId,
          action: hideOpenedBet ? ECurrentBetActions.HIDDEN : null
        })
      );
    }, OPEN_BETS_NOTIFICATION_TIME);
  };

  const onFormChanged = useCallback(
    (
      changedPrice: TPrice,
      changedSize: TSize,
      changedProfit: TProfit,
      isChangedPriceValid: boolean,
      isChangedSizeValid: boolean
    ) => {
      dispatch(updateOfferPriceAndSize({ price: changedPrice, size: changedSize, offerId: bet.offerId }));
      setUpdatedPrice(changedPrice);
      setUpdatedSize(changedSize);
      setIsSizeValid(isChangedSizeValid);
      setIsPriceValid(isChangedPriceValid);
    },
    []
  );

  const getProfit = () => {
    if (matchType === MatchTypes.MATCHED) {
      return bet.side === BetTypes.BACK ? bet.profitNet : bet.liability;
    } else {
      const { marketType, bettingType, eachWayDivisor, side: betType } = bet;
      return calculateLiability(bet.price, bet.sizeRemaining, { marketType, bettingType, eachWayDivisor, betType });
    }
  };

  const onEnterClick = useCallback(() => {
    if (matchType === MatchTypes.UNMATCHED && isPlaceBetsWithEnterKeyEnabled && isUpdateEnabled) {
      dispatch(setPlacedBetsToUpdate({ offerId: bet.offerId, placementType: EPlacementType.UPDATE }));
    }
  }, [matchType, bet, isUpdateEnabled, isPlaceBetsWithEnterKeyEnabled]);

  return (
    <div
      className={classNames(styles.openedBetWrap, {
        [styles.openedBetWrap__hidden]: isHidden,
        [styles.openedBetWrap__hasMessage]: messageType,
        [styles.openedBetWrap__combined]: bet.isCombined
      })}
      data-offer-id={`${bet.offerId}${bet.isCombined ? '_combined' : ''}`}
      data-placed-date={bet.placedDate}
      data-event-name={bet.eventName}
      data-market-name={bet.marketName}
    >
      {!isLoading && messageType && matchType === MatchTypes.UNMATCHED && (
        <BetMessage type={messageType} message={betMessage} />
      )}
      <div
        className={classNames(
          styles.openedBet,
          styles[`openedBet__${bet.side?.toLowerCase()}`],
          styles[`openedBet__${lowerCaseMatchType}`],
          branding.OPENED_BET,
          branding.OPENED_BET_FRAME,
          branding[matchType],
          branding[bet.side],
          {
            [branding.FANCY_SWAP]: isFancySwapColors,
            [styles.openedBet__hidden]: messageType && matchType === MatchTypes.UNMATCHED && isCancelled(bet),
            [styles.openedBet__combined]: bet.isCombined,
            [styles.openedBet__combined__first]: bet.isCombined && isFirstBetInList,
            [branding.BET_CASHED_OUT]: isPNCEnabled && bet.triggeredByCashOut,
            [styles.showingSkeleton]: isLoading || isBetslipLoading
          }
        )}
      >
        <div
          className={classNames(
            styles.openedBet__header,
            styles[`openedBet__header__${lowerCaseMatchType}`],
            branding.OPENED_BET_FRAME_HEADER,
            branding[matchType],
            {
              [styles.openedBet__hidden]: bet.isCombined && !isFirstBetInList
            }
          )}
        >
          {matchType === MatchTypes.UNMATCHED && bet.persistenceEnabled ? (
            <BetPersistenceType
              offerId={bet.offerId}
              persistenceType={updatedPersistenceType}
              changePersistenceType={setUpdatedPersistenceType}
            />
          ) : (
            t(`betslip.labels.${lowerCaseMatchType}`)
          )}
        </div>
        <div className={styles.openedBet__content}>
          {isEventLinkEnabled ? (
            <Link
              className={classNames(styles.openedBet__eventNameLink, branding.OPENED_BET_TITLE, branding[bet.side])}
              to={eventLink}
            >
              {bet.eventName}
            </Link>
          ) : (
            <span className={classNames(styles.openedBet__eventName, branding.OPENED_BET_TITLE, branding[bet.side])}>
              {bet.eventName}
            </span>
          )}
          <div className={styles.openedBet__betForm}>
            <div>
              <div
                className={classNames(styles.openedBet__selectionName, branding.OPENED_BET_TITLE, branding[bet.side])}
              >
                {bet.selectionName}
              </div>
              <div className={styles.openedBet__marketNameWrap}>
                {bet.triggeredByCashOut && (
                  <span className={classNames(branding.CASH_OUT_ICON, styles.cashOutIcon)}>
                    <i className={styles.cashOutIcon__inner} />
                  </span>
                )}
                <Link
                  className={classNames(styles.openedBet__marketName, branding.OPENED_BET_HEADER, branding[bet.side])}
                  to={marketLink}
                >
                  {fullMarketName}
                </Link>
              </div>
            </div>
            <BetForm
              betPrice={bet.averagePriceRounded || bet.price}
              betAveragePrice={bet.averagePrice}
              betSize={matchType === MatchTypes.MATCHED ? bet.sizeMatched : bet.sizeRemaining}
              betProfit={getProfit()}
              betType={bet.side}
              currency={bet.currency}
              bettingType={bet.bettingType}
              marketType={bet.marketType}
              lineRangeInfo={lineRangeInfo}
              eachWayDivisor={bet.eachWayDivisor}
              priceLadderDescription={bet.priceLadderDescription}
              mode={matchType === MatchTypes.UNMATCHED ? EPlaceBetsStates.SELECT : EPlaceBetsStates.CONFIRM}
              onFormChanged={onFormChanged}
              onEnterClick={onEnterClick}
              betIndex={betIndex}
              focusedField={focusedField}
              totalTabFields={tabulation.OPENED_BET_TABS}
            />
          </div>
          {isEachWay && bet.eachWayDivisor && (
            <div className={styles.openedBet__eachWayLabel}>
              {t('market.each.way.termsNoPref', { odds: bet.eachWayDivisor, places: bet.numberOfWinners })}
            </div>
          )}
          {!bet.isCombined && bet.bettingType === BettingType.LINE && (
            <div className={styles.betLineInfo}>{t('betslip.labels.oddsAreAlways2')}</div>
          )}
          {matchType === MatchTypes.UNMATCHED && !isEachWay && (
            <div className={styles.openedBet__labels}>
              <BetLabels
                price={updatedPrice}
                size={updatedSize}
                handicap={bet.handicap}
                betType={bet.side}
                marketType={bet.marketType}
                bettingType={bet.bettingType}
                eventTypeId={String(bet.eventTypeId)}
                lineRangeInfo={{
                  marketUnit: bet.marketUnit || '',
                  minUnitValue: bet.minUnitValue,
                  maxUnitValue: bet.maxUnitValue,
                  interval: bet.interval
                }}
                eachWayDivisor={bet.eachWayDivisor}
                handicapType={bet.lineSide}
                selectionName={bet.selectionName}
                eventName={bet.eventName}
                currency={bet.currency}
                placementType={bet.betType}
              />
            </div>
          )}
          {matchType === MatchTypes.UNMATCHED && (
            <BetActions
              bet={bet}
              updatedPrice={updatedPrice}
              updatedSize={updatedSize}
              updatedPersistenceType={updatedPersistenceType}
              isSizeValid={isSizeValid}
              isUpdateEnabled={isUpdateEnabled}
              toggleLoading={setIsLoading}
              currency={bet.currency}
              betIndex={betIndex}
              isLast={isLast}
            />
          )}
        </div>
        {!bet.isCombined && <BetInfo bet={bet} />}
      </div>
      {(isLoading || isBetslipLoading) && <BetslipSkeleton itemsCount={1} />}
    </div>
  );
};

export default memo(OpenedBet);
