import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getBetslipType } from 'redux/modules/betslip/selectors';
import { getCurrentBetsByExchangeFiltered } from 'redux/modules/currentBets/selectors';
import { EBetslipTypes } from 'types/betslip';
import { getFilteredCurrentBets } from 'utils/market';

import MarketRow from '../MarketRow';

import styles from './styles.module.scss';

const Content = () => {
  const { t } = useTranslation();

  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const sortedCurrentBets = useSelector(getCurrentBetsByExchangeFiltered(isGameBetslip));
  const filteredCurrentBets = getFilteredCurrentBets(sortedCurrentBets);

  return (
    <div className={classNames('biab_active-markets-content', styles.activeMarkets__content)}>
      {filteredCurrentBets.length ? (
        <ul className={classNames(styles.activeMarkets__list)}>
          {filteredCurrentBets.map((bet, index) => (
            <MarketRow activeMarket={bet} key={`${bet.marketId}-${index}`} />
          ))}
        </ul>
      ) : (
        <p className={classNames('biab_active-markets-message', styles.activeMarkets__empty)}>
          {t('navigation.noActiveMarkets')}
        </p>
      )}
    </div>
  );
};

export default Content;
