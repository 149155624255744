import { createSelector } from 'reselect';

import { AppState } from 'redux/reducers';

const getCashOut = (state: AppState) => state.cashOut;

export const getIsCashOutQuoteFirstLoaded = ({ cashOut }: AppState) => cashOut.isFirstLoaded;
export const getCashOutByMarketId =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.quotes[marketId];

export const getAutoCashOutProfitDataByMarketId =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.possibleProfits[marketId];

export const getAutoCashOutStatus =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.autoCashOuts[marketId]?.status;
export const getSuccessFullCreatedAutoCashOut =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.successFullCreatedAutoCashOuts[marketId];
export const getSuccessFullDeletedAutoCashOuts =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.successFullDeletedAutoCashOuts[marketId];

export const getCashedValueByMarket =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.cashedValues[marketId];

export const getAutoCashOut =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.autoCashOuts[marketId];

export const getCashOutPlacedIds = createSelector(getCashOut, cashOut => Object.values(cashOut.placedIds));

export const getCashOutPlacedId =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.placedIds[marketId];

export const getPaginationLoader = ({ cashOut }: AppState) => cashOut.paginationLoader;

export const getWhatIfCashOut = (marketId: string, selectionId: number) =>
  createSelector(getCashOut, ({ whatIf, whatIfMarketId }) =>
    whatIfMarketId === marketId ? whatIf[selectionId] : null
  );

export const getMarketCashOut = ({ cashOut }: AppState) => cashOut.marketCashOut;
export const getCashOutContent = ({ cashOut }: AppState) => cashOut.marketCashOut.content;
export const getCashOutTotalPages = ({ cashOut }: AppState) => cashOut.marketCashOut.totalPages;

export const getCashOutStatus =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.cashOutStatuses[marketId];

export const getPendingCashOut =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.pendingCashOuts[marketId];
export const getSettingTabStatus =
  (marketId: string) =>
  ({ cashOut }: AppState) =>
    cashOut.settingTabsStates[marketId];
export const getPendingCashOuts = ({ cashOut }: AppState) => cashOut.pendingCashOuts;

export const getMarketCashOutById = (findMarketId: string) =>
  createSelector(getMarketCashOut, ({ content }) => content.find(({ marketId }) => marketId === findMarketId));

export const getCashOutMarketRules = ({ cashOut }: AppState) => cashOut.rules;

export const getCashOutMarketRulesLoading = ({ cashOut }: AppState) => cashOut.rulesLoading;
export const getFetchCashOutLoading = ({ cashOut }: AppState) => cashOut.fetchCashOutLoading;
export const getFetchAutoCashOutLoading = ({ cashOut }: AppState) => cashOut.fetchAutoCashOutLoading;
export const getFetchCashOutMarketsLoading = ({ cashOut }: AppState) => cashOut.fetchCashOutMarketsLoading;
export const getCashOutStringifiedAutoCashOuts = ({ cashOut }: AppState) => cashOut.stringifiedAutoCashOuts;
