import { all, call, put, takeLatest } from 'redux-saga/effects';

import { BroadCastChannels, BroadCastHandledTypes, PopupTypes, PostMessageTypes } from 'constants/app';
import api from 'redux/api/methods';
import { getAppConfigsWorker } from 'redux/modules/appConfigs/saga';
import { closeAsianViewProfitLossTablePopUp } from 'redux/modules/asianView';
import { resetAsianViewBetSlipCashOut } from 'redux/modules/asianViewBetSlipCashOut';
import { resetAsianViewCashOut } from 'redux/modules/asianViewCashOut';
import { resetCashOutCounters } from 'redux/modules/asianViewCashOutCounter';
import { resetCashOut } from 'redux/modules/cashOut';
import { resetEventCashOut } from 'redux/modules/eventCashOut';
import { setLoginPopup } from 'redux/modules/popup';
import { resetOperatorCurrency, resetUserState } from 'redux/modules/user';
import { fetchBalanceWorker, fetchUserInfoWorker } from 'redux/modules/user/saga';
import { resetWhatIf } from 'redux/modules/whatIf';
import { dispatchEvent, sendIframeMessages } from 'utils/iframe';

import {
  authenticateUser,
  failureAuthenticateUser,
  failureLoginUser,
  failureLogoutUser,
  failureVerifyUser,
  loginUser,
  logoutUser,
  proceedLogout,
  setAfterLogOutRedirect,
  successAuthenticateUser,
  successLoginUser,
  successLogoutUser,
  successVerifyUser,
  verifyUser
} from './index';

function* getLoginWorker() {
  try {
    yield put(successLoginUser());
    yield call<any>(fetchUserInfoWorker);
    yield call<any>(getAppConfigsWorker);
    yield call<typeof fetchBalanceWorker>(fetchBalanceWorker);

    sendIframeMessages({ type: PostMessageTypes.LOGIN });
    dispatchEvent({ type: PostMessageTypes.LOGIN });
  } catch (error: any) {
    yield put(failureLoginUser(error.data));
  }
}

function* getAuthenticatedUserWorker(action: ReturnType<typeof authenticateUser>) {
  try {
    yield call(api.auth.login, action.payload.username, action.payload.password);

    if (action.payload.cookieName && document.cookie.includes(action.payload.cookieName)) {
      yield put(
        setLoginPopup({
          type: PopupTypes.TWO_FACTOR_AUTH,
          params: {
            username: action.payload.username
          }
        })
      );
    } else {
      yield put(loginUser());
    }

    yield put(successAuthenticateUser());
  } catch (error: any) {
    yield put(failureAuthenticateUser(error.data));
  }
}

function* getVerifiedUserWorker(action: ReturnType<typeof verifyUser>) {
  try {
    yield call(api.auth.verify, action.payload.username, action.payload.password);

    yield put(successVerifyUser());
  } catch (error: any) {
    yield put(failureVerifyUser(error.response));
  }
}

function* getLogoutWorker(action: ReturnType<typeof logoutUser>) {
  const channel = new BroadcastChannel(BroadCastChannels.USER_ACTIONS);
  channel.postMessage({ type: BroadCastHandledTypes.LOGOUT, payload: action.payload });
}

function* getProceedLogoutWorker(action: ReturnType<typeof proceedLogout>) {
  try {
    yield call(api.auth.logout);
    yield put(successLogoutUser());
    yield put(resetWhatIf());
    yield put(resetCashOut());
    yield put(resetAsianViewBetSlipCashOut());
    yield put(resetAsianViewCashOut());
    yield put(resetEventCashOut());
    yield put(resetCashOutCounters());
    yield put(setAfterLogOutRedirect(action.payload));
    yield put(resetUserState());
    yield put(resetOperatorCurrency());
    yield put(closeAsianViewProfitLossTablePopUp());

    yield call<any>(fetchUserInfoWorker);
    yield call<any>(getAppConfigsWorker);

    sendIframeMessages({ type: PostMessageTypes.LOGOUT });
    dispatchEvent({ type: PostMessageTypes.LOGOUT });
  } catch (error: any) {
    yield put(failureLogoutUser(error.data));
  }
}

export default function* saga() {
  yield all([
    takeLatest(loginUser.type, getLoginWorker),
    takeLatest(logoutUser.type, getLogoutWorker),
    takeLatest(proceedLogout.type, getProceedLogoutWorker),
    takeLatest(authenticateUser.type, getAuthenticatedUserWorker),
    takeLatest(verifyUser.type, getVerifiedUserWorker)
  ]);
}
