import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import {
  BETSLIP_LABEL_MAX,
  BETSLIP_LABEL_MIN,
  VALIDATION_ERROR_BET_INVALID_SIZE,
  VALIDATION_ERROR_BET_MAX_STAKE,
  VALIDATION_ERROR_BET_MIN_STAKE
} from 'constants/placement';
import { useCurrency } from 'hooks/useCurrency';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrency, getIsOperatorBettingLimitsEnabled } from 'redux/modules/appConfigs/selectors';
import { BetTypes, TErrorMessage, TSize } from 'types/bets';
import { adjustValue, isValidValue } from 'utils/betValidation';

import { getBetslipType } from '../redux/modules/betslip/selectors';
import { EBetslipTypes } from '../types/betslip';

const useSizeValidation = (betType: BetTypes, currencyCode?: string) => {
  const { t } = useTranslation();

  const defaultCurrency = useSelector(getCurrency);
  const betslipType = useSelector(getBetslipType);
  const isOperatorBettingLimitsEnabled = useSelector(getIsOperatorBettingLimitsEnabled);

  const currency = useCurrency(currencyCode);

  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const minBetSizeField = isGameBetslip ? 'gamesMinBetSize' : 'minBetSize';
  const maxBetSizeField = isGameBetslip ? 'gamesMaxBetSize' : 'maxBetSize';
  const stepField = isGameBetslip ? 'gamesStep' : 'step';

  const { formattedAmount: minFormatted } = useFormatCurrency(
    currency?.[minBetSizeField] || defaultCurrency[minBetSizeField],
    currency?.symbol || currencyCode,
    { noRounding: true, noCommas: true, ignorePrecision: true }
  );
  const { formattedAmount: maxFormatted } = useFormatCurrency(
    currency?.[maxBetSizeField] || defaultCurrency[maxBetSizeField],
    currency?.symbol || currencyCode,
    { noRounding: true, noCommas: true, ignorePrecision: true }
  );

  const [initValue, setValue] = useState<TSize>();
  const [validValue, setValidValue] = useState<TSize>();
  const [isValid, setIsValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>({ message: '' });

  const precisionFormat = (value: TSize, precision = 2) => parseFloat(value as string).toFixed(precision);

  const validate = useCallback(() => {
    const min = currency?.[minBetSizeField] || defaultCurrency[minBetSizeField];
    const max = currency?.[maxBetSizeField] || defaultCurrency[maxBetSizeField];
    const step = currency?.[stepField] || defaultCurrency?.[stepField];
    const value: TSize = initValue !== '' && initValue !== undefined ? parseFloat(initValue as string) : initValue;
    const isEmptyValue = initValue === '';

    if (isEmptyValue) {
      setIsValid(false);
      setValidValue('');
      setErrorMessage({
        message: ''
      });
    } else if (!isUndefined(value)) {
      if (+value < min) {
        setIsValid(false);
        setValidValue(min);
        setErrorMessage({
          message: t(VALIDATION_ERROR_BET_MIN_STAKE, {
            currency_ISO_code: currency?.symbol || currencyCode,
            min: precisionFormat(min)
          }),
          label: `${t(BETSLIP_LABEL_MIN)} ${minFormatted}`
        });
      } else if (+value > max) {
        if (isGameBetslip || !isOperatorBettingLimitsEnabled) {
          setIsValid(false);
          setValidValue(max);
          setErrorMessage({
            message: t(VALIDATION_ERROR_BET_MAX_STAKE, {
              currency_ISO_code: currency?.symbol || currencyCode,
              max: precisionFormat(max)
            }),
            label: `${t(BETSLIP_LABEL_MAX)} ${maxFormatted}`
          });
        } else {
          setValidValue(value);
          setIsValid(true);
        }
      } else if (!isValidValue(value, step, min)) {
        const adjustedValue = adjustValue(value, step, 0, betType);
        setIsValid(false);
        setValidValue(adjustedValue);
        setErrorMessage({
          message: t(VALIDATION_ERROR_BET_INVALID_SIZE, { number: step })
        });
      } else {
        setIsValid(true);
        setValidValue(value);
      }
    } else {
      setIsValid(false);
      setValidValue(value);
    }
  }, [initValue]);

  useEffect(() => {
    validate();
  }, [initValue]);

  return {
    setValue,
    validate,
    isValid,
    validValue,
    errorMessage,
    setErrorMessage
  };
};

export default useSizeValidation;
