import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getBetslipMatchedFilter, getBetslipType } from 'redux/modules/betslip/selectors';
import { getCurrentBetsAmount, getCurrentBetsByType } from 'redux/modules/currentBets/selectors';
import { BetTypes, MatchTypes } from 'types/bets';
import { EBetslipTypes } from 'types/betslip';
import { getEnvironmentRootPath } from 'utils/navigation';

import CancelAllButton from '../CancelAllButton';
import ConsolidateBets from '../ConsolidateBets';
import OpenedBetsBySide from '../OpenedBetsBySide';

import styles from './styles.module.scss';

const OpenedBetsByType = ({ type }: { type: MatchTypes }) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const isUnmatchedBetsOnly = useSelector(getBetslipMatchedFilter);
  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const openedBetsByType = useSelector(getCurrentBetsByType(type, isGameBetslip));
  const withMatchedFilterBetsAmount = useSelector(
    getCurrentBetsAmount({ isGameType: isGameBetslip, isMatchedFilter: true })
  );

  const isMatchedType = type === MatchTypes.MATCHED;

  if (!openedBetsByType.length || !withMatchedFilterBetsAmount) {
    return null;
  }

  return (
    <>
      <h3 className={classNames(styles.openedBetsByType__title, branding.OPENED_BETS_SIDE_HEADER)}>
        {t(`${isPNCEnabled && isMatchedType ? 'pnc.' : ''}betslip.labels.openBetsHeader.${type.toLowerCase()}`)}
        {!isMatchedType && <CancelAllButton />}
        {isMatchedType && !isGameBetslip && <ConsolidateBets />}
      </h3>
      {(!isMatchedType || !isUnmatchedBetsOnly) && (
        <>
          <OpenedBetsBySide side={BetTypes.BACK} type={type} />
          <OpenedBetsBySide side={BetTypes.LAY} type={type} />
        </>
      )}
      {isMatchedType && isUnmatchedBetsOnly && (
        <div
          className={styles.openedBetsByType__showMatchedMsg}
          dangerouslySetInnerHTML={{
            __html: t(`${isPNCEnabled && isMatchedType ? 'pnc.' : ''}betslip.labels.showMatchedInfo`, {
              rootPath: getEnvironmentRootPath()
            })
          }}
        />
      )}
    </>
  );
};

export default memo(OpenedBetsByType);
