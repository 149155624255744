import { BetsType, TBetsType } from 'redux/modules/myBets/type';
import { TMobileAccountDropdownValue } from 'types';

import {
  ACCOUNT_BASE_URL,
  ACCOUNT_STATEMENT_BASE_URL,
  BETTING_PROFIT_LOSS_BASE_URL,
  MY_BETS_BASE_URL
} from './locations';

export const pastTabButtons = ['Settled', 'Cancelled', 'Lapsed', 'Voided'];

export const currentTabButtons = ['Unmatched', 'Matched'];

export const periodTabButtons = ['Current', 'Past'];

export enum PLBetType {
  AsianView = 'AsianView',
  Games = 'Games',
  Sports = 'Sports'
}
export enum BetTypeValue {
  Games = 'GAME',
  Sports = 'EXCHANGE'
}

export const PLTabButtons = [PLBetType.AsianView, PLBetType.Sports, PLBetType.Games];

export const mapPLTabButtonsToAccountProductKey = {
  [PLBetType.AsianView]: 'asianView',
  [PLBetType.Sports]: 'sports',
  [PLBetType.Games]: 'games'
};

export const ExchangeTypes = {
  AsianView: 'asianView',
  SportsGames: 'sportsGames'
} as const;

export type TExchangeTypes = typeof ExchangeTypes[keyof typeof ExchangeTypes];

export const ExchangeTypeTabButtons = [ExchangeTypes.AsianView, ExchangeTypes.SportsGames];

export const plDefaultSportTypeValues = {
  sports: { translationKey: 'account.profitandloss.labels.allSports', name: 'allSports' },
  games: { translationKey: 'account.profitandloss.labels.allGames', name: 'allGames' }
};

export const defaultTimeRange = {
  translationKey: 'account.profitandloss.ranges.lastDay',
  name: 'lastDay'
};

export const timeRanges = [
  { translationKey: 'account.profitandloss.ranges.lastDay', name: 'lastDay' },
  { translationKey: 'account.profitandloss.ranges.fromYesterday', name: 'yesterday' },
  { translationKey: 'account.profitandloss.ranges.sevenDays', name: 'sevenDays' },
  { translationKey: 'account.profitandloss.ranges.month', name: 'month' },
  { translationKey: 'account.profitandloss.ranges.threeMonths', name: 'threeMonths' }
];

export const SEPARATE_WALLET = 'SEPARATE_WALLET';
export const SINGLE_WALLET = 'SINGLE_WALLET';

export enum NavigationButtons {
  Back = 'back',
  MyBets = 'mybets',
  ProfitAndLoss = 'profitandloss',
  Statement = 'statement',
  Settings = 'settings',
  ResponsibleBetting = 'responsibleBetting'
}

export const mapAccountPageToBetsType: Record<string, TBetsType> = {
  mybets: BetsType.All_MY_BETS,
  profitandloss: BetsType.ALL_PROFIT_AND_LOSS,
  statement: BetsType.ALL_ACCOUNT_STATEMENT
};

export enum Period {
  Current = 'Current',
  Past = 'Past'
}

export enum BetStatuses {
  Settled = 'Settled',
  Cancelled = 'Cancelled',
  Voided = 'Voided',
  Lapsed = 'Lapsed',
  Matched = 'Matched',
  Unmatched = 'Unmatched',
  Removed = 'REMOVED',
  Pending = 'Pending'
}

export enum BetTypes {
  Settled = 'settled',
  Cancelled = 'cancelled',
  Voided = 'voided',
  Lapsed = 'lapsed',
  Matched = 'matched',
  Unmatched = 'unmatched'
}

export enum MyBetsLocations {
  MyBets = '/account/mybets',
  Settled = '/account/mybets/settled',
  Cancelled = '/account/mybets/cancelled',
  Voided = '/account/mybets/voided',
  Lapsed = '/account/mybets/lapsed',
  Matched = '/account/mybets/matched',
  Unmatched = '/account/mybets/unmatched',
  Statement = '/account/statement',
  ProfitAndLoss = '/account/profitandloss',
  Settings = '/account/settings',
  ResponsibleBetting = '/account/responsible-betting'
}

export const BetStatusesMap: Record<string, BetStatuses> = {
  [MyBetsLocations.MyBets]: BetStatuses.Unmatched,
  [MyBetsLocations.Matched]: BetStatuses.Matched,
  [MyBetsLocations.Unmatched]: BetStatuses.Unmatched,
  [MyBetsLocations.Cancelled]: BetStatuses.Cancelled,
  [MyBetsLocations.Settled]: BetStatuses.Settled,
  [MyBetsLocations.Lapsed]: BetStatuses.Lapsed,
  [MyBetsLocations.Voided]: BetStatuses.Voided
};

export const betTypesPayload = { betTypes: ['GAME', 'EXCHANGE'] };

export const myBetsDateFormat = 'dd/MM/yyyy';

export const myBetsDateFormatUS = 'MM/dd/yyyy';

export const statementFilterValues: { [key: string]: { value: string; translationKey: string } } = {
  all: { translationKey: 'account.statement.labels.all', value: 'ALL' },
  withdrawal: { translationKey: 'account.statement.labels.depositAndWithdrawal', value: 'DEPOSIT_WITHDRAWAL' }
};

export enum PLTimeRanges {
  LastDay = 'lastDay',
  Yesterday = 'yesterday',
  SevenDays = 'sevenDays',
  Month = 'month',
  ThreeMonths = 'threeMonths'
}

export const betSideYes = 'yes';
export const betSideNo = 'no';
export const betSideUnder = 'under';
export const betSideOver = 'over';

export const lineBettingType = 'LINE';

export const lineAvgPriceFlatValue = '2.00';

export const pageSizes = [10, 20, 50, 100];

export const firstPageIndex = '1';

export const GET_ACCOUNT_NAVIGATION_BUTTONS = (isPNCEnabled: boolean) => {
  return [
    {
      name: NavigationButtons.Back,
      url: '/',
      translationKey: 'accountNavigation.back',
      holderClassName: 'biab_btn-back-holder',
      iconName: 'fa2 fa2-arrow-left'
    },
    {
      name: NavigationButtons.MyBets,
      url: `${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${isPNCEnabled ? 'matched' : 'unmatched'}/${firstPageIndex}`,
      translationKey: 'accountNavigation.mybets'
    },
    {
      name: NavigationButtons.ProfitAndLoss,
      url: `${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/${firstPageIndex}`,
      translationKey: 'accountNavigation.profitandloss'
    },
    {
      name: NavigationButtons.Statement,
      url: `${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/${firstPageIndex}`,
      translationKey: 'accountNavigation.statement'
    }
  ];
};

export const GET_ACCOUNT_NAVIGATION_BUTTONS_MOBILE = (
  isPNCEnabled: boolean,
  isResponsibleBettingMenuEnabled: boolean
) => {
  return [
    {
      name: NavigationButtons.MyBets,
      url: `${ACCOUNT_BASE_URL}${MY_BETS_BASE_URL}/${isPNCEnabled ? 'matched' : 'unmatched'}/${firstPageIndex}`,
      labelTranslationKey: 'accountNavigation.mybets'
    },
    {
      name: NavigationButtons.ProfitAndLoss,
      url: `${ACCOUNT_BASE_URL}${BETTING_PROFIT_LOSS_BASE_URL}/1`,
      labelTranslationKey: 'accountNavigation.profitandloss'
    },
    {
      name: NavigationButtons.Statement,
      url: `${ACCOUNT_BASE_URL}${ACCOUNT_STATEMENT_BASE_URL}/1`,
      labelTranslationKey: 'accountNavigation.statement'
    },
    {
      name: NavigationButtons.ResponsibleBetting,
      url: `${ACCOUNT_BASE_URL}/responsible-betting`,
      labelTranslationKey: 'responsibleBetting.labels.responsibleBetting',
      isVisible: isResponsibleBettingMenuEnabled
    },
    {
      name: NavigationButtons.Settings,
      url: `${ACCOUNT_BASE_URL}/settings`,
      labelTranslationKey: 'account.settings.title'
    }
  ];
};

export enum MyBetsPage {
  ExchangeBets = 'exchange-bets',
  ProfitLoss = 'profit-loss',
  AccountStatement = 'account-statement'
}

export const mapMyBetsPageToBetsType: Record<MyBetsPage, TBetsType> = {
  [MyBetsPage.ExchangeBets]: BetsType.All_MY_BETS,
  [MyBetsPage.ProfitLoss]: BetsType.ALL_PROFIT_AND_LOSS,
  [MyBetsPage.AccountStatement]: BetsType.ALL_ACCOUNT_STATEMENT
};

export const getCurrentAccountNavigationButtonMobile = (
  pathname: string,
  navigationButtons: TMobileAccountDropdownValue[]
) => {
  const currentMyBetsPage = pathname.split('/', 3).join('/');

  switch (currentMyBetsPage) {
    case MyBetsLocations.MyBets:
      return navigationButtons[0];
    case MyBetsLocations.ProfitAndLoss:
      return navigationButtons[1];
    case MyBetsLocations.Statement:
      return navigationButtons[2];
    case MyBetsLocations.ResponsibleBetting:
      return navigationButtons[3];
    case MyBetsLocations.Settings:
      return navigationButtons[4];
  }

  return navigationButtons[0];
};

export const SEARCH_CURRENCY_KEY = 'currency';

export const MY_BETS_IFRAME_ADDITIONAL_HARDCODED_HEIGHT = 200;
export const PL_STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT = 50;
export const PL_DESKTOP_IFRAME_ADDITIONAL_HARDCODED_HEIGHT = 150;
export const STATEMENT_MOBILE_IFRAME_ADDITIONAL_HARDCODED_HEIGHT = 100;
export const MIN_AVAILABLE_ITEMS_COUNT_WITHOUT_ADDITIONAL_HEIGHT = 3;

export const MAX_DESKTOP_PAGES_TO_SHOW = 11;
export const MAX_MOBILE_PAGES_TO_SHOW = 6;

export const HEADER_BOTTOM_MARGIN = 20;

export const EXPORT_DELAY = 5000;

export const EXPORT_FILENAME = {
  ExchangeBets: 'My_Bets',
  ProfitLoss: 'Betting_Profit_and_Loss',
  AccountStatement: 'Account_Statement'
};
