import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';

import { LANDSCAPE_MIN_WIDTH } from 'constants/app';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';

type WindowSize = {
  windowWidth: number;
  windowHeight: number;
};

const useWindowSize = (delay = 100) => {
  const iframeEnabled = useSelector(getIsIframeEnabled);

  const [sizes, setSizes] = useState<WindowSize>({
    windowWidth: 0,
    windowHeight: 0
  });

  const isLandscape = iframeEnabled
    ? sizes.windowWidth > LANDSCAPE_MIN_WIDTH
    : sizes.windowHeight < sizes.windowWidth && sizes.windowWidth > LANDSCAPE_MIN_WIDTH;

  const handleResize = () => {
    setSizes({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
  };

  useEffect(() => {
    const debouncedHandleResize = debounce(handleResize, delay);

    window.addEventListener('resize', debouncedHandleResize);

    debouncedHandleResize();

    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, []);

  return { ...sizes, isLandscape };
};

export default useWindowSize;
