import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getBetslipMarketFilter, getBetslipType } from 'redux/modules/betslip/selectors';
import { getCurrentBetsAmount } from 'redux/modules/currentBets/selectors';
import { EBetslipTypes } from 'types/betslip';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const OpenedBetsEmpty = () => {
  const { t } = useTranslation();

  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const marketFilter = useSelector(getBetslipMarketFilter);
  const currentBetsAmount = useSelector(getCurrentBetsAmount({ isGameType: isGameBetslip }));
  const withMatchedFilterBetsAmount = useSelector(
    getCurrentBetsAmount({ isGameType: isGameBetslip, isMatchedFilter: true })
  );
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  return (
    <div className={styles.openBetsEmpty}>
      {!isLoggedIn && <span>{t('betslip.messages.logInToViewBets')}</span>}
      {isLoggedIn && !currentBetsAmount && marketFilter && <span>{t('betslip.messages.noOpenBetsOnMarket')}</span>}
      {isLoggedIn && !currentBetsAmount && !marketFilter && <span>{t('betslip.messages.noOpenBets')}</span>}
      {isLoggedIn && !!currentBetsAmount && !withMatchedFilterBetsAmount && (
        <span
          dangerouslySetInnerHTML={{
            __html: t(`${isPNCEnabled ? 'pnc.' : ''}betslip.labels.showMatchedInfo`, {
              rootPath: getEnvironmentRootPath()
            })
          }}
        />
      )}
    </div>
  );
};

export default OpenedBetsEmpty;
