import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import InfoCircle from 'components/InfoCircle';
import { PricesCellsType } from 'constants/games';
import { MarketContext } from 'contexts/gamesMarletContext';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setActiveTab, setSelectedBets } from 'redux/modules/betslip';
import { getSelectedBet } from 'redux/modules/betslip/selectors';
import { EBetslipTabs } from 'redux/modules/betslip/type';
import { getCurrentGameMarket, getCurrentGameMarketCurrency } from 'redux/modules/games/selectors';
import { setInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { getInlineSelectedBet } from 'redux/modules/inlinePlacement/selectors';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { BetTypes } from 'types/bets';
import { PageBlocks, PlacementPage } from 'types/index';

import styles from '../../styles.module.scss';

interface ICellBox {
  selectionId: number;
  value: number;
  unmatched: number;
  index: number;
  type: PricesCellsType;
  name: string;
  isFirstSelection?: boolean;
}

const CellBox = ({ selectionId, value, unmatched, index, type, name, isFirstSelection = false }: ICellBox) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const currency = useSelector(getCurrentGameMarketCurrency);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const gameMarket = useSelector(getCurrentGameMarket);
  const device = useSelector(getAppDevice);

  const isFirst = index === 0;
  const isEmptyCell = !unmatched && !value;
  const { isBestOddTooltipEnabled } = useContext(MarketContext);
  const showBestBetCellTooltip =
    index === 1 && type === PricesCellsType.BACK && isBestOddTooltipEnabled && isFirstSelection;
  const isDesktop = device === Devices.DESKTOP;
  const betType = type === PricesCellsType.BACK ? BetTypes.BACK : BetTypes.LAY;

  const { formattedAmount: unmatchedAmount, noFormattedAmount: tooltipUnmatchedAmount } = useFormatCurrency(
    unmatched,
    currency,
    {
      noCommas: true
    }
  );

  const inlineSelectedBet = useSelector(
    getInlineSelectedBet(PageBlocks.GAME, String(gameMarket?.id), selectionId ?? 0, 0, betType)
  );

  const betslipSelectedBet = useSelector(getSelectedBet(String(gameMarket?.id), selectionId ?? 0, 0, betType));

  const isSelected =
    (isDesktop && inlineSelectedBet) ||
    (!isDesktop && inlineSelectedBet && (!inlineSelectedBet.currentOfferId || inlineSelectedBet.action)) ||
    betslipSelectedBet;

  const onBetCellClickHandler = () => {
    if (gameMarket) {
      const bet: TInlineSelectedBet = {
        marketId: String(gameMarket.id),
        sportId: String(gameMarket.gameId),
        selectionId: selectionId,
        handicap: 0,
        marketType: gameMarket.marketType,
        numberOfWinners: 1,
        type: betType,
        price: value,
        pageBlock: PageBlocks.GAME,
        currency: currency ?? '',
        page: PlacementPage.GAME
      };

      if (isDesktop) {
        dispatch(setSelectedBets([bet]));
        dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));
      } else {
        dispatch(setInlineSelectedBet(bet));
      }
    }
  };

  return (
    <div
      className={classNames(styles.box, {
        'biab_green-cell': isFirst && type === PricesCellsType.LAY,
        'biab_blue-cell': isFirst && type === PricesCellsType.BACK,
        'biab_bet-back back-cell': type === PricesCellsType.BACK,
        'biab_bet-lay lay-cell': type === PricesCellsType.LAY,
        'biab_empty-cell': isEmptyCell,
        'biab_grey-cell': !isFirst,
        biab_selected: isSelected
      })}
      data-cell-id={selectionId + '_0'}
      onClick={onBetCellClickHandler}
    >
      <div
        data-tooltip-id={'tooltip'}
        data-tooltip-html={unescape(
          t(`${isPNCEnabled ? 'pnc.' : ''}tooltip.${type === PricesCellsType.BACK ? 'back' : 'lay'}MarketCell`, {
            amount: tooltipUnmatchedAmount,
            selection: name,
            odds: value
          })
        )}
        className={classNames(styles.boxContent, 'biab_bet-content cursor-help')}
      >
        <span className={classNames('biab_odds', styles.value)}>{value}</span>
        <span className={classNames('biab_bet-amount', styles.unmatched)}>{unmatched ? unmatchedAmount : ''}</span>
      </div>
      {showBestBetCellTooltip && (
        <div
          data-tooltip-id={'tooltip'}
          data-tooltip-html={unescape(t('tooltip.all.pages.cells'))}
          className={classNames('cursor-help', styles.bestBetIcon)}
        >
          <InfoCircle />
        </div>
      )}
    </div>
  );
};

export default CellBox;
