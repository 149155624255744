import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { betslipBranding as branding } from 'constants/branding';
import { getBetslipFilterTooltip } from 'constants/tooltip';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setFilterState } from 'redux/modules/betslip';
import { getBetslipFilters } from 'redux/modules/betslip/selectors';
import { EBetslipFilters } from 'redux/modules/betslip/type';
import { CookieNames } from 'types';

import styles from './styles.module.scss';

interface IFilterIconProps {
  filter: EBetslipFilters;
}

const BetslipFilterIcon = ({ filter }: IFilterIconProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([
    CookieNames.BETSLIP_FILTER_MARKET,
    CookieNames.BETSLIP_FILTER_MATCHED,
    CookieNames.BETSLIP_FILTER_SORTING
  ]);

  const betslipFilters = useSelector(getBetslipFilters);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const setFilterCookie = (value: boolean) => {
    setCookie(CookieNames[`BETSLIP_FILTER_${filter}`], value);
  };

  const onFilterClick = () => {
    const changedFilter = !betslipFilters[filter];

    setFilterCookie(changedFilter);

    dispatch(
      setFilterState({
        [filter]: changedFilter
      })
    );
  };

  useEffect(() => {
    dispatch(
      setFilterState({
        [filter]: cookies[CookieNames[`BETSLIP_FILTER_${filter}`]] === 'true'
      })
    );
  }, [filter, cookies, dispatch]);

  const isActive = !!betslipFilters[filter];

  return (
    <>
      {(!isPNCEnabled || filter !== EBetslipFilters.MATCHED) && (
        <li className={styles.filterIcon} onClick={onFilterClick}>
          <span
            data-tooltip-id={'tooltip'}
            data-tooltip-html={unescape(
              t(getBetslipFilterTooltip(filter, filter !== EBetslipFilters.MATCHED ? isActive : !isActive))
            )}
            className={classNames('cursor-help', styles.filterIcon__inner, branding.HEADER_FILTER_ICON, {
              [branding.ACTIVE]: isActive
            })}
          >
            <i
              className={classNames('fa2', {
                'fa2-filter': filter === EBetslipFilters.MARKET,
                'fa2-sort': filter === EBetslipFilters.SORTING,
                [`fa2-eye-open ${[styles.matchedIcon]}`]: filter === EBetslipFilters.MATCHED
              })}
            />
          </span>
        </li>
      )}
    </>
  );
};

export default BetslipFilterIcon;
