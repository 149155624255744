import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Modal from 'components/Modal';
import { GAMES_BASE_URL, HOME_BASE_URL } from 'constants/locations';
import useDevice from 'hooks/useDevice';
import { getIsExchangeSportsEnabled } from 'redux/modules/appConfigs/selectors';
import { logoutUser } from 'redux/modules/auth';

import styles from './styles.module.scss';

interface ReminderBettingModalTemplateProps {
  title: string;
  open: boolean;
  onClose: () => void;
  message: string;
}

const ReminderBettingModalTemplate = ({ title, onClose, open, message }: ReminderBettingModalTemplateProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);

  const { isMobile } = useDevice();

  return (
    <Modal
      open={open}
      title={title}
      customClassNames={{ dialog: styles.rbSessionModal__dialog, content: styles.rbSessionModal__content }}
      onClose={onClose}
      clickableBackground
    >
      <p className={styles.rbSessionModal__description}>{message}</p>
      <div
        className={classNames('biab_btns', styles.rbSessionModal__actionsContainer, {
          [styles.rbSessionModal__actionsContainer__mobile]: isMobile
        })}
      >
        <button
          onClick={() => dispatch(logoutUser(isExchangeSportsEnabled ? HOME_BASE_URL : GAMES_BASE_URL))}
          className={classNames('biab_btn-continue', styles.rbSessionModal__actionBtn)}
        >
          {t('responsibleBetting.labels.sessionReminder.logOut')}
        </button>
        <button onClick={onClose} className={classNames('biab_btn-skip', styles.rbSessionModal__actionBtn)}>
          {t('responsibleBetting.labels.sessionReminder.noted')}
        </button>
      </div>
    </Modal>
  );
};

export default ReminderBettingModalTemplate;
