import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { last } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import {
  ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH,
  ASIAN_VIEW_MOBILE_MIN_WIDTH,
  ASIAN_VIEW_MOBILE_WIDTH,
  AsianViewSection,
  AsianViewTimeFrame,
  COMPETITION_KEY_DIVIDER
} from 'constants/asianView';
import { TFailureActionPayload } from 'types';
import { IMarketRules } from 'types/markets';
import { getCompetitionIdFromKey } from 'utils/asianView';

import {
  AsianMarket,
  AsianViewProfitLossMarketNames,
  AsianViewProfitLossTableData,
  IFetchSearch,
  ISelectionCoupon,
  SetAsianViewListPayload,
  TAsianView,
  TAsianViewEvent,
  TFetchAsianViewCountMarketsPayload,
  TFetchAsianViewListPayload
} from './type';

export const DEFAULT_OPEN_COLLAPSE = 4;

const initialState: TAsianView = {
  response: {
    content: {},
    competitionIds: [],
    resultsByCompetition: {},
    first: true,
    last: false,
    totalElements: 0,
    totalPages: 0,
    number: 0,
    sort: null,
    size: 0,
    numberOfElements: 0
  },
  markets: [],
  events: [],
  selectedTab: AsianViewSection.MAIN,
  isCashOutTabOpened: false,
  openEventId: null,
  countMarkets: {},
  stringifiedCountMarkets: '',
  collapse: {},
  loading: false,
  tabLoading: false,
  error: null,
  rules: null,
  rulesLoading: false,
  rulesError: null,
  daysValue: AsianViewTimeFrame.ALL,
  isSearchResults: false,
  isAutoCollapsedRightSide: window.innerWidth < ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH,
  isCollapsedRightSide: window.innerWidth < ASIAN_VIEW_AUTO_COLLAPSED_RIGHT_SIDE_WIDTH,
  isMobileAsianView: window.innerWidth <= ASIAN_VIEW_MOBILE_WIDTH,
  isLandscapeAsianView:
    window.innerWidth >= ASIAN_VIEW_MOBILE_MIN_WIDTH && window.innerWidth <= ASIAN_VIEW_MOBILE_WIDTH,
  areEventCouponsLoaded: false,
  isAsianViewNotFoundView: false,
  profitLossTableData: null,
  profitLossTableDataLoading: false,
  profitLossTableDataError: null,
  profitLossTablePopUpEventId: null,
  stringifiedProfitLossTableData: '',
  profitLossTableDataFirstLoaded: false,
  profitLossTablePopUpMarketId: null,
  asianViewPLFTMarkets: [],
  asianViewPLHTMarkets: [],
  plMarketNamesLoading: false,
  plMarketNamesError: null
};

const slice = createSlice({
  name: SLICES_NAMES.ASIAN_VIEW,
  initialState,
  reducers: {
    fetchAsianViewList: (state, { payload }: PayloadAction<TFetchAsianViewListPayload>) => {
      if (payload.resetPrev || (!payload.isInfiniteScrollEnabled && !!Object.keys(state.response.content).length)) {
        state.response = initialState.response;
        state.areEventCouponsLoaded = false;
        state.events = initialState.events;
      }

      if (payload.isInfiniteScrollEnabled && payload.page === 0) {
        state.areEventCouponsLoaded = false;
      }

      state.loading = true;
    },
    successFetchAsianViewList: (state, { payload }: PayloadAction<SetAsianViewListPayload>) => {
      const isFirstPage = payload.first;
      const content: Record<string, TAsianViewEvent[]> =
        (!isFirstPage && payload.isInfiniteScrollEnabled && { ...state.response.content }) || {};
      const competitionIds: Array<string> =
        (!isFirstPage && payload.isInfiniteScrollEnabled && [...state.response.competitionIds]) || [];
      const competitionKeysByOrder = Object.keys(state.response.content) ?? [];
      const competitionIdsByOrder =
        competitionKeysByOrder.map(competitionIdByOrder => getCompetitionIdFromKey(competitionIdByOrder)) ?? [];

      let prevCompetitionId = last(competitionIdsByOrder) ?? '';
      let prevCompetitionKey = last(competitionKeysByOrder) ?? '';

      payload.content.forEach((event: TAsianViewEvent, index) => {
        const competitionKey =
          event.competitionId !== prevCompetitionId
            ? `${event.competitionId}${COMPETITION_KEY_DIVIDER}${index}`
            : prevCompetitionKey;

        prevCompetitionId = event.competitionId;
        prevCompetitionKey = competitionKey;

        if (!content[competitionKey]) {
          content[competitionKey] = [];
        }
        if (!competitionIds.includes(event.competitionId)) {
          competitionIds.push(event.competitionId);
        }
        content[competitionKey].push(event);
      });

      if (state.isAsianViewNotFoundView && payload.content.length) {
        state.isAsianViewNotFoundView = false;
      }

      state.response = { ...payload, content, competitionIds };
      state.events =
        !isFirstPage && payload.isInfiniteScrollEnabled ? [...state.events, ...payload.content] : payload.content;
      state.loading = false;
      state.isSearchResults = !!payload.isSearchResults;
      state.error = null;
      state.areEventCouponsLoaded = true;
    },
    failureFetchAsianViewList: (state, action) => {
      state.error = action.payload;
      state.loading = false;
      state.areEventCouponsLoaded = true;
      state.response = initialState.response;
    },
    setOpenEventId: (state, { payload }) => {
      state.openEventId = payload;
      state.collapse = {};
    },
    handlerCollapse: (state, { payload }) => {
      state.collapse[payload] = !state.collapse[payload];
    },
    setCollapses: (state, { payload }: { payload: { collapse: string[]; section?: string } }) => {
      const { collapse, section = '' } = payload;

      collapse.forEach((item, currentIndex) => {
        state.collapse[`${item}-${section}`] =
          state.collapse[`${item}-${section}`] ?? currentIndex < DEFAULT_OPEN_COLLAPSE;
      });
    },
    handlerAllCollapses: (state, { payload }) => {
      state.collapse = Object.keys(state.collapse).reduce((acc, item) => ({ ...acc, [item]: payload }), {});
    },
    fetchTab: (state, { payload }: PayloadAction<ISelectionCoupon>) => {
      state.tabLoading = true;
      state.selectedTab = payload.sections[0];
    },
    successFetchTab: (state, { payload }: PayloadAction<AsianMarket[]>) => {
      state.tabLoading = false;
      state.markets = payload;
    },
    failureFetchTab: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.tabLoading = false;
      state.error = payload;
    },
    fetchMarketRules: (state, __: PayloadAction<string>) => {
      state.rulesLoading = true;
    },
    successFetchMarketRules: (state, { payload }: PayloadAction<{ marketId: string; rules: IMarketRules }>) => {
      state.rulesLoading = false;
      state.rules = { ...state.rules, [payload.marketId]: payload.rules };
    },
    failureFetchMarketRules: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.rulesLoading = false;
      state.rulesError = payload;
    },
    fetchCountMarkets: (_, __: PayloadAction<TFetchAsianViewCountMarketsPayload>) => {},
    successFetchCountMarkets: (
      state,
      { payload }: PayloadAction<{ countMarkets: Record<string, number>; stringifiedCountMarkets: string }>
    ) => {
      state.countMarkets = payload.countMarkets;
      state.stringifiedCountMarkets = payload.stringifiedCountMarkets;
    },
    fetchSearchResults: (state, { payload }: PayloadAction<IFetchSearch>) => {
      if (payload.resetPrev || (!payload.isInfiniteScrollEnabled && !!Object.keys(state.response.content).length)) {
        state.response.content = {};
      }

      state.loading = true;
    },
    setDaysValue: (state, { payload }: PayloadAction<AsianViewTimeFrame>) => {
      state.daysValue = payload;
    },
    setIsAutoCollapsedRightSide: (state, { payload }: PayloadAction<boolean>) => {
      state.isAutoCollapsedRightSide = payload;
    },
    setIsCollapsedRightSide: (state, { payload }: PayloadAction<boolean>) => {
      state.isCollapsedRightSide = payload;
    },
    setIsMobileAsianView: (state, { payload }: PayloadAction<boolean>) => {
      state.isMobileAsianView = payload;
    },
    setIsLandscapeAsianView: (state, { payload }: PayloadAction<boolean>) => {
      state.isLandscapeAsianView = payload;
    },
    resetContent: state => {
      state.response = initialState.response;
      state.events = [];
      state.loading = true;
    },
    setSectionSize: (state, { payload }: PayloadAction<DOMRect | undefined>) => {
      if (payload) {
        state.sectionSize = payload;
      }
    },
    setIsAsianViewNotFoundView: (state, { payload }: PayloadAction<boolean>) => {
      state.isAsianViewNotFoundView = payload;
    },
    fetchAsianViewProfitLossTableData: (state, { payload }: PayloadAction<{ eventId: string; marketId: string }>) => {
      state.profitLossTableDataLoading = true;
      state.profitLossTablePopUpEventId = payload.eventId;
      state.profitLossTablePopUpMarketId = payload.marketId;
    },
    successFetchAsianViewProfitLossTableData: (state, { payload }: PayloadAction<AsianViewProfitLossTableData>) => {
      state.profitLossTableDataLoading = false;
      state.profitLossTableData = payload;
      state.stringifiedProfitLossTableData = JSON.stringify(payload);
      state.profitLossTableDataError = null;

      if (!state.profitLossTableDataFirstLoaded) {
        state.profitLossTableDataFirstLoaded = true;
      }
    },
    failureFetchAsianViewProfitLossTableData: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.profitLossTableDataLoading = false;
      state.profitLossTableDataError = payload;
      state.profitLossTableData = null;

      if (!state.profitLossTableDataFirstLoaded) {
        state.profitLossTableDataFirstLoaded = true;
      }
    },
    closeAsianViewProfitLossTablePopUp: state => {
      state.profitLossTablePopUpEventId = null;
      state.profitLossTableData = null;
      state.stringifiedProfitLossTableData = '';
      state.profitLossTableDataError = null;
      state.profitLossTableDataLoading = false;
      state.profitLossTableDataFirstLoaded = false;
      state.profitLossTablePopUpMarketId = null;
      state.asianViewPLFTMarkets = [];
      state.asianViewPLHTMarkets = [];
      state.plMarketNamesLoading = false;
      state.plMarketNamesError = null;
    },
    setAsianViewProfitLossTableLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.profitLossTableDataLoading = payload;
    },
    removeCompetitionKey: (state, { payload }: PayloadAction<string>) => {
      delete state.response.content[payload];
    },
    fetchAVProfitLossMarketNames: (state, _: PayloadAction<string>) => {
      state.plMarketNamesLoading = true;
    },
    successFetchAVProfitLossMarketNames: (state, { payload }: PayloadAction<AsianViewProfitLossMarketNames>) => {
      state.plMarketNamesLoading = false;
      state.plMarketNamesError = null;
      state.asianViewPLHTMarkets = payload.asianViewPLHTMarkets;
      state.asianViewPLFTMarkets = payload.asianViewPLFTMarkets;
    },
    failureFetchAVProfitLossMarketNames: (state, { payload }) => {
      state.plMarketNamesLoading = false;
      state.plMarketNamesError = payload;
    },
    setIsCashOutTabOpened: (state, { payload }: PayloadAction<boolean>) => {
      state.isCashOutTabOpened = payload;
    }
  }
});

export const {
  handlerCollapse,
  setCollapses,
  setOpenEventId,
  handlerAllCollapses,
  fetchTab,
  failureFetchTab,
  successFetchTab,
  fetchAsianViewList,
  successFetchAsianViewList,
  failureFetchAsianViewList,
  fetchMarketRules,
  successFetchMarketRules,
  failureFetchMarketRules,
  fetchCountMarkets,
  successFetchCountMarkets,
  fetchSearchResults,
  setDaysValue,
  setIsAutoCollapsedRightSide,
  setIsCollapsedRightSide,
  setIsMobileAsianView,
  setIsLandscapeAsianView,
  setSectionSize,
  resetContent,
  setIsAsianViewNotFoundView,
  fetchAsianViewProfitLossTableData,
  successFetchAsianViewProfitLossTableData,
  failureFetchAsianViewProfitLossTableData,
  closeAsianViewProfitLossTablePopUp,
  setAsianViewProfitLossTableLoading,
  removeCompetitionKey,
  fetchAVProfitLossMarketNames,
  successFetchAVProfitLossMarketNames,
  failureFetchAVProfitLossMarketNames,
  setIsCashOutTabOpened
} = slice.actions;

export default slice.reducer;
