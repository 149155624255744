import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { IMarketRules } from 'types/markets';

import {
  createAutoCashOut,
  deleteAutoCashOut,
  errorFetchSetCashOutMarket,
  failAutoCashOutMarkets,
  failCreateAutoCashOut,
  failFetchCashOutMarkets,
  failFetchCashOutQuotes,
  failFetchCashOutStatus,
  failFetchSetCashOutMarket,
  failureDeleteAutoCashOut,
  failureFetchCashOutMarketRules,
  fetchAutoCashOutMarkets,
  fetchCashOutMarketRules,
  fetchCashOutMarkets,
  fetchCashOutQuotes,
  fetchCashOutStatus,
  fetchSetCashOutMarket,
  successAutoCashOutMarkets,
  successCreateAutoCashOut,
  successDeleteAutoCashOut,
  successFetchCashOutMarketRules,
  successFetchCashOutMarkets,
  successFetchCashOutQuotes,
  successFetchCashOutStatus,
  successFetchSetCashOutMarket
} from './index';
import { getCashOutStringifiedAutoCashOuts } from './selectors';
import { AutoCashOut, CashOutMarket, CashOutQuote, MarketCashOutResponse, TResponseCashOutStatus } from './type';

function* fetchCashOutQuotesWorker(action: ReturnType<typeof fetchCashOutQuotes>) {
  try {
    const response: CashOutQuote[] = yield call(api.cashOut.getCashOut, { ids: action.payload.ids });

    yield put(
      successFetchCashOutQuotes({
        quotes: response,
        firstLoaded: action.payload.firstLoading,
        isWebSocketResponse: false
      })
    );
  } catch (error: any) {
    yield put(failFetchCashOutQuotes(error));
  }
}

function* fetchCashOutMarketsWorker(action: ReturnType<typeof fetchCashOutMarkets>) {
  try {
    let response: MarketCashOutResponse = yield call(api.cashOut.getMarkets, action.payload);
    // This condition needs if page from payload is bigger than last available page (totalPages) in response, so we need to do one more request with valid page
    if (
      action.payload.isPaginationEnabled &&
      action.payload.page > 0 &&
      action.payload.changePage &&
      action.payload.page + 1 > response.markets.totalPages
    ) {
      response = yield call(api.cashOut.getMarkets, { ...action.payload, page: response.markets.totalPages - 1 });
      action.payload.changePage(response.markets.totalPages - 1);
    }

    yield put(
      successFetchCashOutMarkets({
        ...response,
        isUpdated: !!action.payload.isUpdated || !action.payload.isPaginationEnabled
      })
    );
  } catch (error: any) {
    yield put(
      failFetchCashOutMarkets({
        error,
        withLoader: action.payload.withLoader
      })
    );
  }
}

function* createAutoCashOutWorker(action: ReturnType<typeof createAutoCashOut>) {
  try {
    const response: AutoCashOut = yield call(api.cashOut.createAutoCashOut, action.payload);
    yield put(successCreateAutoCashOut(response));
  } catch (error: any) {
    yield put(failCreateAutoCashOut(error));
  }
}

function* deleteAutoCashOutWorker(action: ReturnType<typeof deleteAutoCashOut>) {
  try {
    yield call(api.cashOut.deleteAutoCashOut, action.payload.id);
    yield put(successDeleteAutoCashOut(action.payload.marketId));
  } catch (error: any) {
    yield put(failureDeleteAutoCashOut(error));
  }
}

function* getAutoCashOutMarketsWorker(action: ReturnType<typeof fetchAutoCashOutMarkets>) {
  try {
    const response: AutoCashOut[] = yield call(api.cashOut.getAutoCashOutMarkets, action.payload);
    const stringifiedResponse = JSON.stringify(response);
    const stringifiedPrevAutoCashOuts: string = yield select(getCashOutStringifiedAutoCashOuts);

    if (stringifiedResponse !== stringifiedPrevAutoCashOuts) {
      yield put(
        successAutoCashOutMarkets({
          autoCashOuts: response,
          stringifiedAutoCashOuts: stringifiedResponse,
          isWebSocketResponse: false
        })
      );
    }
  } catch (error: any) {
    yield put(failAutoCashOutMarkets(error));
  }
}

function* fetchSetCashOutMarketsWorker(action: ReturnType<typeof fetchSetCashOutMarket>) {
  try {
    const response: CashOutMarket = yield call(api.cashOut.setCashOut, action.payload);
    if (response.offers?.length && response.id) {
      yield put(successFetchSetCashOutMarket({ ...response, marketId: action.payload.marketId }));
    } else {
      yield put(
        failFetchSetCashOutMarket({
          marketId: action.payload.marketId,
          status: response.status
        })
      );
    }
  } catch (error: any) {
    yield put(errorFetchSetCashOutMarket(error));
  }
}

function* fetchCashOutStatusWorker(action: ReturnType<typeof fetchCashOutStatus>) {
  try {
    const response: TResponseCashOutStatus = yield call(api.cashOut.getCashOutStatus, action.payload.id);
    yield put(
      successFetchCashOutStatus({
        statusId: action.payload.id,
        status: response.status,
        marketId: response.marketId
      })
    );
  } catch (error: any) {
    yield put(failFetchCashOutStatus({ error, statusId: action.payload.id }));
  }
}

function* fetchCashOutMarketRulesWorker(action: ReturnType<typeof fetchCashOutMarketRules>) {
  try {
    const response: IMarketRules = yield call(api.app.marketRules, action.payload);
    yield put(successFetchCashOutMarketRules({ marketId: action.payload, rules: response }));
  } catch (error: any) {
    yield put(failureFetchCashOutMarketRules(error.data));
  }
}

export default function* saga() {
  yield all([
    takeEvery(fetchCashOutQuotes.type, fetchCashOutQuotesWorker),
    takeEvery(createAutoCashOut.type, createAutoCashOutWorker),
    takeEvery(deleteAutoCashOut.type, deleteAutoCashOutWorker),
    takeEvery(fetchAutoCashOutMarkets.type, getAutoCashOutMarketsWorker),
    takeEvery(fetchCashOutMarkets.type, fetchCashOutMarketsWorker),
    takeEvery(fetchSetCashOutMarket.type, fetchSetCashOutMarketsWorker),
    takeEvery(fetchCashOutStatus.type, fetchCashOutStatusWorker),
    takeLatest(fetchCashOutMarketRules.type, fetchCashOutMarketRulesWorker)
  ]);
}
