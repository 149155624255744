import { PRECISE_LEN } from 'constants/app';
import { TSuccessFetchCurrency } from 'redux/modules/appConfigs/type';

export const isStepValid = (min: number, step: number, value: string | number) => {
  return parseFloat(String(Math.round(((Number(value) - min) / step) * PRECISE_LEN) / PRECISE_LEN)) % 1 === 0;
};

export const validateStakeValue = (
  value: string | number,
  currency: TSuccessFetchCurrency,
  origin: string,
  activeTab?: string
) => {
  const { minBetSize, maxBetSize, step, gamesMinBetSize, gamesMaxBetSize, gamesStep } = currency;

  const isGamesTab = activeTab && activeTab === 'games';
  const minBet = isGamesTab ? gamesMinBetSize : minBetSize;
  const maxBet = isGamesTab ? gamesMaxBetSize : maxBetSize;
  const stepValue = isGamesTab ? gamesStep : step;

  if (!/^[+-]?\d+(\.)?(\d+)?$/.test(String(value))) {
    return { translationKey: `account.settings.${origin}.validation.invalidNumber` };
  } else if (Number(value) < minBet) {
    return {
      translationKey: `account.settings.${origin}.validation.minBetSize`,
      options: { N: minBet }
    };
  } else if (Number(value) > maxBet) {
    return {
      translationKey: `account.settings.${origin}.validation.maxBetSize`,
      options: { N: maxBet }
    };
  } else if (!isStepValid(minBet, stepValue, value)) {
    return {
      translationKey: `account.settings.${origin}.validation.step`,
      options: { N: stepValue }
    };
  }

  return { translationKey: '' };
};
