import { createSelector } from '@reduxjs/toolkit';
import { entries, groupBy, reverse, sortBy } from 'lodash';

import { ASIAN_VIEW_PL_TABLE_AVAILABLE_MARKETS } from 'constants/asianView';
import { AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH } from 'constants/betList';
import { getAsianViewMobileBetListPaginationSize } from 'redux/modules/appConfigs/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { AppState } from 'redux/reducers';
import { AvBetListMobileSorting } from 'types/asianView';
import { getPaginatedGroupedEventsAndCompetitions } from 'utils/betList';
import { isMatchedOffer, isUnmatchedOffer } from 'utils/currentBets';

const getAVCurrentBets = ({ asianViewCurrentBets }: AppState) => asianViewCurrentBets.currentBets;
const getAVCurrentBetsOffers = ({ asianViewCurrentBets }: AppState) => asianViewCurrentBets.offers;

export const getAsianCurrentBetByOfferId = (offerId: number | null) => (state: AppState) =>
  offerId ? state.asianViewCurrentBets.offers[offerId] : null;

export const getAsianCurrentBetsLoading = ({ asianViewCurrentBets }: AppState) => asianViewCurrentBets.loading;
export const getAreAsianCurrentBetsLoaded = ({ asianViewCurrentBets }: AppState) =>
  asianViewCurrentBets.areCurrentBetsLoaded;
export const getAsianMatchedCurrentBets = createSelector(getAVCurrentBets, bets =>
  bets.filter(bet => bet.offerState !== BetsStatusesTypes.CANCELLED && isMatchedOffer(bet))
);
export const getAsianCurrentBetsGroupedByEventOrCompetitionId = (
  sorting: AvBetListMobileSorting,
  searchValue: string,
  filterValue: string,
  page: number,
  displayPagination: boolean
) =>
  createSelector(getAsianMatchedCurrentBets, getAsianViewMobileBetListPaginationSize, (bets, itemsCountOnPage) => {
    const filteredValues =
      filterValue === 'live'
        ? bets.filter(({ eventInPlay }) => eventInPlay)
        : filterValue === 'future'
        ? bets.filter(({ eventInPlay }) => !eventInPlay)
        : bets;
    const searchedValues =
      searchValue.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH
        ? bets.filter(
            ({ eventName, competitionName, selectionName, marketName }) =>
              eventName?.toLowerCase().includes(searchValue.toLowerCase()) ||
              competitionName?.toLowerCase().includes(searchValue.toLowerCase()) ||
              selectionName?.toLowerCase().includes(searchValue.toLowerCase()) ||
              marketName?.toLowerCase().includes(searchValue.toLowerCase())
          )
        : filteredValues;

    if (searchValue.length >= AV_MOBILE_BET_LIST_SEARCH_PARAM_MIN_LENGTH || sorting === 'eventStartTime') {
      const competitions: TCurrentBet[] = [];
      const events: TCurrentBet[] = [];

      searchedValues.forEach(bet => {
        if (bet.isOutright) {
          competitions.push(bet);
        } else {
          events.push(bet);
        }
      });

      const sortedEvents = groupBy(sortBy(events, ['marketStartDate', 'eventName']), ({ eventId }) => `${eventId} `);
      const sortedCompetitions = groupBy(
        sortBy(competitions, 'placedDate').reverse(),
        ({ competitionId }) => `${competitionId} `
      );
      const allGrouped = entries({
        ...sortedEvents,
        ...sortedCompetitions
      });

      return displayPagination
        ? getPaginatedGroupedEventsAndCompetitions({ groupedBets: allGrouped, page, itemsCountOnPage })
        : { groupedCurrentBets: allGrouped, totalPages: 0 };
    }

    const groupedAndSortedByPlacedDate = entries(
      groupBy(sortBy(searchedValues, 'placedDate').reverse(), ({ isOutright, competitionId, eventId }) =>
        isOutright ? `${competitionId} ` : `${eventId} `
      )
    );

    return displayPagination
      ? getPaginatedGroupedEventsAndCompetitions({
          groupedBets: groupedAndSortedByPlacedDate,
          page,
          itemsCountOnPage
        })
      : { groupedCurrentBets: groupedAndSortedByPlacedDate, totalPages: 0 };
  });

export const getAVMatchedCurrentBetsSortedByPlacedDate = createSelector(getAsianMatchedCurrentBets, bets =>
  reverse(sortBy(bets, 'placedDate'))
);

export const getAVMatchedCurrentBetsCount = createSelector(getAsianMatchedCurrentBets, bets => bets.length);

export const getAVCurrentBetByOfferId = (offerId: number) =>
  createSelector(getAVCurrentBetsOffers, offers => offers[offerId]);

export const getAVBetsPlacedCounter = (marketId: string) =>
  createSelector(getAVCurrentBets, offers => offers.filter(item => item.marketId === marketId).length);

export const getIsAVCurrentBetByEventId =
  (eventId: string) =>
  ({ asianViewCurrentBets }: AppState) =>
    !!asianViewCurrentBets.currentBets.find(bet => bet.eventId === eventId);

export const getIsAVActiveProfitLossTableForCurrentBet = (eventId?: string) =>
  createSelector(getAVCurrentBets, bets => {
    return eventId
      ? !!bets.find(bet => eventId === bet.eventId && ASIAN_VIEW_PL_TABLE_AVAILABLE_MARKETS.includes(bet.marketType))
      : false;
  });

export const getAVAllCurrentBetsByEventId = (eventId: string | null) =>
  createSelector(getAVCurrentBets, bets => {
    return eventId ? bets.filter(bet => bet.eventId === eventId).length : 0;
  });

export const getAVPlacedCashOut =
  (offerId: number | null) =>
  ({ asianViewCurrentBets }: AppState) =>
    offerId ? asianViewCurrentBets.offers[offerId] : null;

export const getAVCurrentSelectionBetsByTypeForWhatIf = (marketId: string, handicap: number) =>
  createSelector(getAVCurrentBets, bets =>
    bets.filter(
      bet =>
        bet.marketId === marketId &&
        bet.handicap == handicap &&
        isUnmatchedOffer(bet) &&
        bet.changedSize &&
        bet.changedPrice
    )
  );

export const getAVMatchedCurrentBetsCountByFilter = (filterValue: string) =>
  createSelector(getAsianMatchedCurrentBets, bets => {
    if (filterValue === 'live') {
      return bets.filter(({ eventInPlay }) => eventInPlay).length;
    }

    if (filterValue === 'future') {
      return bets.filter(({ eventInPlay }) => !eventInPlay).length;
    }

    return bets.length;
  });
