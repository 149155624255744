import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';

import { EPersistenceTypes } from 'types/bets';

import styles from './styles.module.scss';

const BetPersistenceType = ({
  offerId,
  persistenceType,
  changePersistenceType
}: {
  offerId: number;
  persistenceType: EPersistenceTypes;
  changePersistenceType: Dispatch<SetStateAction<EPersistenceTypes>>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {t('betslip.labels.atInPlay')}:
      <span className={styles.persistenceRadio}>
        {map(EPersistenceTypes, typeItem => (
          <label key={typeItem}>
            <input
              name={`persistenceType_${offerId}`}
              type="radio"
              value={typeItem}
              checked={persistenceType === typeItem}
              onChange={() => {
                changePersistenceType(typeItem);
              }}
            />
            {t(`betslip.labels.${typeItem === EPersistenceTypes.LAPSE ? 'cancel' : 'keep'}`)}
          </label>
        ))}
      </span>
    </>
  );
};

export default BetPersistenceType;
