import { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { setSettings } from 'redux/modules/user';
import {
  getAccountSettingsConfirmBetsBeforePlacement,
  getIsUserAccountSettings,
  getUserAccountSettingsAsianViewAutoRefresh,
  getUserAccountSettingsDefaultStake,
  getUserAccountSettingsPlaceBetWithEnterKey
} from 'redux/modules/user/selectors';
import { AsianViewBetSlipCheckboxes } from 'redux/modules/user/type';
import { SettingsBettingCheckbox } from 'types/navigation';

import styles from './styles.module.scss';

type SettingsCheckboxesProps = {
  isAsianView?: boolean;
  isPopUpMode?: boolean;
  isEditMode: boolean;
  setIsEditMode: Dispatch<SetStateAction<boolean>>;
  isCouponSettings?: boolean;
  betSlipCheckboxesValues?: AsianViewBetSlipCheckboxes;
  setBetSlipCheckboxesValues?: Dispatch<SetStateAction<AsianViewBetSlipCheckboxes>>;
};

const SettingsCheckboxes = ({
  isAsianView = false,
  isPopUpMode = false,
  isEditMode,
  setIsEditMode,
  isCouponSettings,
  setBetSlipCheckboxesValues,
  betSlipCheckboxesValues
}: SettingsCheckboxesProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const confirmBetsBeforePlacement = useSelector(getAccountSettingsConfirmBetsBeforePlacement);
  const placeBetWithEnterKeySetting = useSelector(getUserAccountSettingsPlaceBetWithEnterKey);
  const defaultStake = useSelector(getUserAccountSettingsDefaultStake);
  const asianViewAutoRefresh = useSelector(getUserAccountSettingsAsianViewAutoRefresh);

  const [checkboxesValues, setCheckboxesValues] = useState<Record<SettingsBettingCheckbox, boolean | undefined> | null>(
    null
  );

  const { confirmBetsBeforePlace, placeBetWithEnterKey, defStake } = useDeviceSettings();
  const { isMobile } = useDevice();

  const checkboxes = useMemo(() => {
    const checkboxesList = [];

    if (isAsianView) {
      checkboxesList.push({
        isVisible: true,
        label: 'asianView.labels.betslip.autoRefresh',
        name: SettingsBettingCheckbox.AUTO_REFRESH,
        id: 'autoRefreshCheckbox',
        checked: isCouponSettings
          ? !!betSlipCheckboxesValues?.asianViewAutoRefresh
          : !!checkboxesValues?.asianViewAutoRefresh
      });
    }

    checkboxesList.push({
      isVisible: confirmBetsBeforePlace,
      label: isAsianView ? 'asianView.labels.betslip.confirmBets' : 'betslip.actions.confirmBets',
      name: SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT,
      id: 'betslipConfirmCheckbox',
      checked: isCouponSettings
        ? !!betSlipCheckboxesValues?.confirmBetsBeforePlacement
        : !!checkboxesValues?.confirmBetsBeforePlacement
    });

    if (!isAsianView) {
      checkboxesList.push({
        isVisible: placeBetWithEnterKey,
        label: 'betslip.actions.placeBetWithEnter',
        name: SettingsBettingCheckbox.PLACE_BET_WITH_ENTER_KEY,
        id: 'placeBetWithEnterKeyCheckbox',
        checked: !!checkboxesValues?.placeBetWithEnterKey
      });
    }

    checkboxesList.push({
      isVisible: defStake,
      label: 'account.settings.betting.defaultStake',
      name: SettingsBettingCheckbox.DEFAULT_STAKE,
      id: 'defaultStake',
      checked: isCouponSettings ? !!betSlipCheckboxesValues?.defaultStake : !!checkboxesValues?.defaultStake
    });

    return checkboxesList;
  }, [isAsianView, placeBetWithEnterKey, defStake, confirmBetsBeforePlace, checkboxesValues, betSlipCheckboxesValues]);

  useEffect(() => {
    if (isAccountSettings) {
      if (checkboxesValues === null && !isCouponSettings) {
        setCheckboxesValues({
          [SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT]: confirmBetsBeforePlacement,
          [SettingsBettingCheckbox.PLACE_BET_WITH_ENTER_KEY]: placeBetWithEnterKeySetting,
          [SettingsBettingCheckbox.DEFAULT_STAKE]: defaultStake,
          [SettingsBettingCheckbox.AUTO_REFRESH]: asianViewAutoRefresh
        });
      }
    }
  }, [isAccountSettings]);

  const handleChangeCheckbox = (name: SettingsBettingCheckbox) => (event: ChangeEvent<HTMLInputElement>) => {
    if (isCouponSettings && setBetSlipCheckboxesValues) {
      setBetSlipCheckboxesValues(prevState => ({
        ...prevState,
        [name]: event.target.checked
      }));
    } else {
      setCheckboxesValues(prevState => {
        if (prevState) {
          return {
            ...prevState,
            [name]: event.target.checked
          };
        }

        return null;
      });

      if (name === SettingsBettingCheckbox.DEFAULT_STAKE && isEditMode) {
        setIsEditMode(false);
      }

      if (isAccountSettings) {
        dispatch(
          setSettings({
            [name]: event.target.checked
          })
        );
      }
    }
  };

  return (
    <>
      {checkboxes.map(({ isVisible, name, id, label, checked }) => {
        if (!isVisible) {
          return null;
        }

        return (
          <div
            key={id}
            className={classNames(styles.checkbox, {
              [styles.checkbox__asianView]: isAsianView,
              [styles.checkbox__mobile]: isMobile,
              [styles.checkbox__mobile__popUp]: isMobile && isPopUpMode
            })}
          >
            <input
              type="checkbox"
              id={id}
              name={name}
              className={classNames(styles.checkbox__input, {
                [styles.checkbox__input__popUpMobile]: isMobile && isPopUpMode
              })}
              onChange={handleChangeCheckbox(name)}
              checked={checked}
            />
            <label
              htmlFor={id}
              className={classNames(styles.checkbox__label, {
                [styles.checkbox__label__asianView]: isAsianView,
                [styles.checkbox__label__mobile]: isMobile,
                [styles.checkbox__label__popUp__mobile]: isMobile && isPopUpMode
              })}
            >
              {t(label)}
            </label>
          </div>
        );
      })}
    </>
  );
};

export default SettingsCheckboxes;
