import { KeyboardEvent, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import { KEY_CODES, tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { LOADING_TIMEOUT_MESSAGE } from 'constants/placement';
import { BETSLIP_CANCEL_BET, BETSLIP_IM_UPDATE_BET, BETSLIP_UPDATE_BET } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { usePlacementData } from 'hooks/usePlacement';
import useTooltip from 'hooks/useTooltip';
import {
  getBalanceWsEnabled,
  getBetslipSpinnerTime,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled
} from 'redux/modules/appConfigs/selectors';
import {
  removePlacedBetsToUpdate,
  setErrorMessage,
  setFirstOpenedBetFocused,
  setRGErrorMessage
} from 'redux/modules/betslip';
import { getBetslipType, getPlacedBetsToUpdate } from 'redux/modules/betslip/selectors';
import { setCurrentBetAction } from 'redux/modules/currentBets';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getMarketPricesById } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getMarketPricesById as placementById } from 'redux/modules/placement/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { PageBlocks } from 'types';
import { BetTypes, EPersistenceTypes, TPrice, TSize } from 'types/bets';
import { EBetActions, EBetslipTypes } from 'types/betslip';
import { BettingType } from 'types/markets';
import { isResponsibleGamblingError } from 'utils/betslip';
import { calculateLiability } from 'utils/liability';
import { getPricesByMarketType } from 'utils/price';

import styles from './styles.module.scss';

const BetActions = ({
  bet,
  updatedPrice,
  updatedSize,
  updatedPersistenceType,
  currency,
  isSizeValid,
  isUpdateEnabled,
  betIndex = 0,
  isLast,
  toggleLoading
}: {
  bet: TCurrentBet;
  updatedPrice: TPrice;
  updatedSize: TSize;
  updatedPersistenceType: EPersistenceTypes;
  currency?: string;
  isSizeValid: boolean;
  isUpdateEnabled: boolean;
  betIndex?: number;
  isLast?: boolean;
  toggleLoading: (isLoading: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const betslipType = useSelector(getBetslipType);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const marketPrices = useSelector(getMarketPricesById(bet.marketId));
  const gameMarketPrices = useSelector(placementById(PageBlocks.GAME, bet.marketId));
  const betslipSpinnerTime = useSelector(getBetslipSpinnerTime);
  const betsToUpdate = useSelector(getPlacedBetsToUpdate);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const [betAction, setBetAction] = useState<EBetActions>(EBetActions.NONE);

  const spinnerTimeout = useRef<NodeJS.Timeout | null>(null);

  const { translationKey, isEnabled } = useTooltip(BETSLIP_CANCEL_BET);
  const { translationKey: translationKeyBetslipUpdate, isEnabled: isEnabledBetslipUpdate } = useTooltip(
    bet.betType === BettingType.LINE ? BETSLIP_IM_UPDATE_BET : BETSLIP_UPDATE_BET
  );

  const isGameBetslip = betslipType === EBetslipTypes.GAME;

  const onErrorPlacement = (error: TPlacementError | string) => {
    setBetAction(EBetActions.NONE);
    dispatch(setErrorMessage(error));

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }
  };

  const { cancelBetsHandler, editBetsHandler } = usePlacementData({
    eachWayDivisor: bet.eachWayDivisor,
    numberOfWinners: bet.numberOfWinners,
    successPlacement: () => {
      if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
        dispatch(fetchBalance());
      }
    },
    errorPlacement: onErrorPlacement
  });

  const selectionPrices = !isGameBetslip
    ? marketPrices?.rc?.find(
        rc => rc.id === bet.selectionId && ((rc.hc || 0) === +(bet.handicap || 0) || bet.handicap === 0)
      )
    : gameMarketPrices?.rc?.find(rc => rc.id === bet.selectionId);

  const takeOdds = getPricesByMarketType(
    selectionPrices?.[bet.side === BetTypes.BACK ? 'bdatb' : 'bdatl']?.[0]?.odds,
    bet.marketType,
    bet.bettingType
  );

  const { marketType, bettingType, eachWayDivisor, side: betType } = bet;
  const takeProfit = calculateLiability(takeOdds, updatedSize, { marketType, bettingType, eachWayDivisor, betType });
  const { noFormattedAmount: profit } = useFormatCurrency(takeProfit || 0, currency, {
    isCheckIndian: true,
    noRounding: true
  });
  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: bet?.marketId,
    eventId: bet?.eventId,
    observerOptions: { rootMargin: '200px' },
    section: MarketsPricesSocketParamsSections.SportsDesktopBetSlip
  });

  const getFieldOrder = (order = 0) => betIndex * tabulation.OPENED_BET_TABS + order;

  useEffect(() => {
    return () => {
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));

      if (spinnerTimeout.current) {
        clearTimeout(spinnerTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (betsToUpdate[bet.offerId]) {
      dispatch(removePlacedBetsToUpdate(bet.offerId));

      if (isUpdateEnabled) {
        updateBetHandler(updatedPrice);
      }
    }
  }, [betsToUpdate]);

  useEffect(() => {
    if (betAction === EBetActions.PROGRESS) {
      toggleLoading(true);

      spinnerTimeout.current = setTimeout(() => {
        setBetAction(EBetActions.NONE);
        dispatch(setErrorMessage(t(LOADING_TIMEOUT_MESSAGE)));
        dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
      }, +betslipSpinnerTime * 1000);
    } else {
      if (spinnerTimeout.current) {
        clearTimeout(spinnerTimeout.current);
      }

      toggleLoading(false);
    }
  }, [betAction]);

  const cancelBetHandler = () => {
    setBetAction(EBetActions.PROGRESS);

    cancelBetsHandler({
      marketId: bet.marketId,
      bets: [{ ...bet }]
    });
  };

  const updateBetHandler = (priceValue: TPrice) => {
    setBetAction(EBetActions.PROGRESS);

    editBetsHandler({
      marketId: bet.marketId,
      bets: [
        {
          price: priceValue,
          size: updatedSize,
          side: bet.side,
          selectionId: bet.selectionId,
          handicap: bet.handicap,
          offerId: bet.offerId,
          sizeRemaining: bet.sizeRemaining,
          persistenceType: updatedPersistenceType
        }
      ],
      options: {
        betType: bet.betType
      }
    });
  };

  const keyDownHandler =
    (isRestartTabNavigation = false) =>
    (event: KeyboardEvent) => {
      if (event.key === KEY_CODES.TAB && isLast && isRestartTabNavigation) {
        event.preventDefault();
        dispatch(setFirstOpenedBetFocused(true));
      }
    };

  return (
    <div className={styles.openBetButtonsWrap}>
      <div>
        <button
          data-tooltip-id="tooltip"
          data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
          className={classNames(
            styles.openBetButton,
            styles.openBetButton__cancel,
            branding.OPENED_BET_BTN,
            branding.OPENED_BET_CANCEL_BTN,
            {
              'cursor-help': isEnabled
            }
          )}
          type="button"
          tabIndex={getFieldOrder(tabulation.CANCEL_OPENED_BET_ORDER)}
          onClick={cancelBetHandler}
          onKeyDown={keyDownHandler((!takeOdds || !isSizeValid) && !isUpdateEnabled)}
        >
          {t('betslip.labels.btn.cancelBet')}
        </button>
      </div>
      <div ref={ref} data-market-id={bet?.marketId} data-event-id={bet?.eventId} data-market-prices={true}>
        <button
          className={classNames(
            styles.openBetButton,
            styles.openBetButton__update,
            branding[bet.side],
            branding.OPENED_BET_BTN,
            branding.OPENED_BET_TAKE_BTN,
            {
              [styles.openBetButton__disabled]: !takeOdds || !isSizeValid || takeOdds === bet.price,
              [branding.DISABLED]: !takeOdds || !isSizeValid || takeOdds === bet.price
            }
          )}
          type="button"
          tabIndex={getFieldOrder(tabulation.TAKE_OPENED_BET_ORDER)}
          disabled={!takeOdds || !isSizeValid || takeOdds === bet.price}
          onClick={() => updateBetHandler(takeOdds)}
          onKeyDown={keyDownHandler(!isUpdateEnabled)}
        >
          {t('betslip.labels.btn.take', { odds: takeOdds || 0 })}
        </button>
        <div className={styles.openBetTakeProfit}>
          {t('betslip.labels.take.profit.' + bet.side.toLowerCase())}: {profit}
        </div>
      </div>
      <div>
        <button
          data-tooltip-html={isEnabledBetslipUpdate ? unescape(t(translationKeyBetslipUpdate)) : ''}
          className={classNames(
            styles.openBetButton,
            styles.openBetButton__update,
            branding[bet.side],
            branding.OPENED_BET_BTN,
            branding.OPENED_BET_UPDATE_BTN,
            {
              [styles.openBetButton__disabled]: !isUpdateEnabled,
              [branding.DISABLED]: !isUpdateEnabled
            }
          )}
          type="button"
          tabIndex={getFieldOrder(tabulation.UPDATE_OPENED_BET_ORDER)}
          disabled={!isUpdateEnabled}
          onClick={() => updateBetHandler(updatedPrice)}
          onKeyDown={keyDownHandler(isUpdateEnabled)}
        >
          {t('betslip.labels.update')}
        </button>
      </div>
      <RGModalMessage />
    </div>
  );
};

export default memo(BetActions);
