import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { MyBetsLocations } from 'constants/myBets';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getSelectedGamePage } from 'redux/modules/games/selectors';
import { ECardExceptionValues, EGamePages, EGameTypes, TGameDataProperties } from 'redux/modules/games/type';

import styles from './styles.module.scss';

interface GamesCardItemProps {
  /**
   * Selected game properties
   */
  properties: TGameDataProperties[];
  /**
   * Selected game type
   */
  type: string;
  /**
   * Current game round
   */
  currentRound?: number;
  /**
   * Is Banker entity
   */
  isBanker?: boolean;
  /**
   * Card external classes
   */
  classes?: {
    cardItem?: string;
    cardImage?: string;
    cardUnavailable?: string;
  };
}

const GamesCardItem = ({ properties, type, currentRound, isBanker, classes }: GamesCardItemProps) => {
  const location = useLocation();

  const device = useSelector(getAppDevice);
  const selectedGamePage = useSelector(getSelectedGamePage);

  const isMobile = device === Devices.MOBILE;
  const isResultPage = selectedGamePage === EGamePages.RESULTS || location.pathname.startsWith(MyBetsLocations.MyBets);

  const getCardImage = (value: string) => {
    if ((type === EGameTypes.POKER || type === EGameTypes.OMAHA_POKER || type === EGameTypes.BACCARAT) && !isMobile) {
      return require(`assets/images/games/cardsBig/card${value}.gif`);
    }

    return require(`assets/images/games/cards/card${value}.gif`);
  };

  const unavailableCard = () => (
    <div
      className={classNames(
        'biab_not-available-card',
        `biab_not-available-card${isMobile ? '-small' : ''}`,
        styles.card__notAvailable__item,
        classes?.cardUnavailable ?? '',
        {
          [styles.baccaratUnavalaible]: type === EGameTypes.BACCARAT
        }
      )}
    />
  );

  const showAdditionalCard =
    type === EGameTypes.BACCARAT && !isResultPage && (currentRound === 2 || (currentRound === 3 && isBanker));

  return (
    <>
      {properties.map(card => (
        <div
          className={classNames('biab_games-player-card-item', styles.card__item, classes?.cardItem ?? '', {
            [styles[`${type.toLowerCase()}-card`]]: type
          })}
          key={`${card.value}-${card.name}`}
        >
          {card.value !== ECardExceptionValues.NOT_AVAILABLE ? (
            <img key={card.name} src={getCardImage(card.value)} alt="card image" />
          ) : (
            <>{unavailableCard()}</>
          )}
        </div>
      ))}
      {showAdditionalCard && (
        <div
          className={classNames(
            `biab_games-player-card-item ${styles[`${type.toLowerCase()}-card`]}`,
            styles.card__item
          )}
        >
          {unavailableCard()}
        </div>
      )}
    </>
  );
};

export default GamesCardItem;
