import { useSelector } from 'react-redux';
import classNames from 'classnames';

import BetslipSkeleton from 'components/Skeleton/BetslipSkeleton';
import { getBetslipLoading, getBetslipSortingFilter } from 'redux/modules/betslip/selectors';
import { getCurrentBetsLoading, getUnMatchMarket } from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';

import BetslipError from '../BetslipError';
import OpenedBetsByType from '../OpenedBetsByType';
import OpenedBetsEmpty from '../OpenedBetsEmpty';
import OpenedBetsMessage from '../OpenedBetsMessage';
import OpenedBetsSorted from '../OpenedBetsSorted';

import styles from './styles.module.scss';

const OpenedBets = () => {
  const isLoading = useSelector(getCurrentBetsLoading);
  const isBetslipLoading = useSelector(getBetslipLoading);
  const countUnMatchBets = useSelector(getUnMatchMarket);
  const sortingFilter = useSelector(getBetslipSortingFilter);

  return (
    <div
      className={classNames('skeleton_page_wrapper', styles.openedBetsWrap, {
        [styles.disableScroll]: isLoading
      })}
    >
      {isLoading && <BetslipSkeleton isAbsolute itemsCount={countUnMatchBets ?? 4} />}
      <BetslipError />
      <OpenedBetsMessage />
      {sortingFilter ? (
        <OpenedBetsSorted />
      ) : (
        <>
          <OpenedBetsByType type={MatchTypes.UNMATCHED} />
          {!isBetslipLoading && <OpenedBetsByType type={MatchTypes.MATCHED} />}
        </>
      )}
      <OpenedBetsEmpty />
    </div>
  );
};

export default OpenedBets;
