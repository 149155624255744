import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { GeneralWebSocketSubscriptionTypes, GeneralWebSocketSubscriptionTypesKeys } from 'constants/app';
import {
  getEventUpdatesWsEnabled,
  getGeneralWsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getRunningBallInterval,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { fetchEventsUpdatedData } from 'redux/modules/marketsPrices';
import { getIsConnectedGeneral, getSubscribeToGeneralMessages } from 'redux/modules/webSocket/selectors';

type EventsUpdatesInjectionProps = {
  eventIds: string[];
  subscriptionType?: GeneralWebSocketSubscriptionTypesKeys;
};

const EventsUpdatesInjection = ({
  eventIds,
  subscriptionType = GeneralWebSocketSubscriptionTypes.eventUpdates
}: EventsUpdatesInjectionProps) => {
  const dispatch = useDispatch();
  const { sportId } = useParams();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const runningBallInterval = useSelector(getRunningBallInterval);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const eventUpdatesWsEnabled = useSelector(getEventUpdatesWsEnabled);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const eventUpdatesInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const generalWsDataRef = useRef<{
    subscribeGeneralWebSocketMessages: (<F>(params: F) => void) | null;
    isEventUpdatesSubscriptionAvailable: boolean;
  }>({
    subscribeGeneralWebSocketMessages,
    isEventUpdatesSubscriptionAvailable: false
  });

  const isEventUpdatesAvailable = arePropertiesLoaded && !!eventIds.length;
  const isEventUpdatesIntervalAvailable = isEventUpdatesAvailable && (!generalWsEnabled || !eventUpdatesWsEnabled);
  const isEventUpdatesSubscriptionAvailable =
    isEventUpdatesAvailable &&
    eventUpdatesWsEnabled &&
    generalWsEnabled &&
    isConnectedGeneralWebSocket &&
    !!subscribeGeneralWebSocketMessages;

  generalWsDataRef.current = {
    subscribeGeneralWebSocketMessages,
    isEventUpdatesSubscriptionAvailable
  };

  useEffect(() => {
    if (isEventUpdatesIntervalAvailable) {
      dispatch(fetchEventsUpdatedData(eventIds));

      eventUpdatesInterval.current = setInterval(() => {
        dispatch(fetchEventsUpdatedData(eventIds));
      }, runningBallInterval);
    }
    return () => {
      if (eventUpdatesInterval.current) {
        clearInterval(eventUpdatesInterval.current);
      }
    };
  }, [eventIds, language, sportId, timezone, isEventUpdatesIntervalAvailable, runningBallInterval]);

  useEffect(() => {
    return () => {
      const {
        subscribeGeneralWebSocketMessages: subscribeFunc,
        isEventUpdatesSubscriptionAvailable: isEventUpdatesAvailableWS
      } = generalWsDataRef.current;

      if (subscribeFunc && isEventUpdatesAvailableWS) {
        subscribeFunc({
          [subscriptionType]: { subscribe: false }
        });
      }
    };
  }, [eventIds]);

  useEffect(() => {
    if (isEventUpdatesSubscriptionAvailable) {
      setTimeout(() => {
        subscribeGeneralWebSocketMessages({
          [subscriptionType]: {
            subscribe: true,
            eventIds
          }
        });
      }, 500);
    }
  }, [isEventUpdatesSubscriptionAvailable, eventIds]);

  return null;
};

export default EventsUpdatesInjection;
