import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import useContextualHelp from 'hooks/useContextualHelp';
import { getIsShowTooltipHelper } from 'redux/modules/tooltip/selectors';
import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings, getUserContextualHelpMobile } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const SettingsContextualHelp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const contextualHelpValue = useSelector(getUserContextualHelpMobile);
  const isShowTooltipHelper = useSelector(getIsShowTooltipHelper);

  const { handleToggleSwitch } = useContextualHelp();

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isAccountSettings) {
      handleToggleSwitch(e.target.checked);
      dispatch(
        setSettings({
          contextualHelpEnabledMobile: e.target.checked
        })
      );
    }
  };

  if (!isShowTooltipHelper) {
    return null;
  }

  return (
    <div className={styles.contextualHelp}>
      <h4 className={styles.contextualHelp__title}>{t('account.settings.contextualHelp.title')}</h4>
      <div>
        <input type="checkbox" id="contextualHelp" checked={contextualHelpValue} onChange={onChangeInput} />
        <label htmlFor="contextualHelp">{t('account.settings.contextualHelp.enable')}</label>
      </div>
    </div>
  );
};

export default SettingsContextualHelp;
