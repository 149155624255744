import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

import { ExchangeTypes } from 'constants/myBets';
import {
  getDisplayCustomerCommissionRange,
  getIsAsianViewEnabled,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { charges } from 'redux/modules/myBets/selectors';
import { CookieNames } from 'types';
import { commission } from 'utils/myBetsValues';

const useCommissionRange = () => {
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE]);

  const displayCustomerCommissionRange = useSelector(getDisplayCustomerCommissionRange);
  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const chargesCommission = useSelector(charges);
  const commissionRange = commission(chargesCommission);

  const isAsianView = asianViewEnabled && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView;

  return {
    showCommissionRange: !isAsianView && !isPNCEnabled && displayCustomerCommissionRange && commissionRange !== '',
    commissionRange
  };
};

export default useCommissionRange;
