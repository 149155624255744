import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import { GAME_TYPES } from 'constants/games';
import useConfirmBets from 'hooks/useConfirmBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { removeSelectedBet, setPlaceBetsState, updateSelectedBet } from 'redux/modules/betslip';
import { getBetslipPlaceBetsState, getSelectedBets } from 'redux/modules/betslip/selectors';
import { EBetFocusFields, EPlaceBetsStates, TSelectedBet } from 'redux/modules/betslip/type';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getMarketPricesById } from 'redux/modules/placement/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { MarketStatus, PageBlocks, SportId } from 'types';
import { TPrice, TProfit, TSize } from 'types/bets';
import { TBetslipMarket } from 'types/betslip';
import { isSportPage } from 'utils';
import { getBestPrices, isEachWayMarketType, parseHandicap } from 'utils/betslip';
import { getLineRangeParams } from 'utils/betValidation';

import BetForm from '../BetForm';
import BetLabels from '../BetLabels';
import CurrencyMessage from '../CurrencyMessage';
import QuickBets from '../QuickBets';

import styles from './styles.module.scss';

const SelectedBet = ({
  bet,
  market,
  betIndex,
  selectedBetsLength
}: {
  bet: TSelectedBet;
  market: TBetslipMarket;
  betIndex: number;
  selectedBetsLength: number;
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const accountSettings = useSelector(getAccountSettings);
  const selectedBetsList = useSelector(getSelectedBets);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const marketPrices = useSelector(getMarketPricesById(bet.pageBlock, bet.marketId));

  const { isConfirmBetsEnabled } = useConfirmBets();
  const { placeBetWithEnterKey, swapColorsFancyMarketsOnCricket } = useDeviceSettings();
  const { isMultiCurrencySupported, isMultiCurrencyChanged } = useMultiCurrencySupporting();

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId: market?.marketId,
    eventId: market?.event?.id,
    section: MarketsPricesSocketParamsSections.SportsDesktopBetSlip,
    observerOptions: { rootMargin: '200px' }
  });

  const isEachWay = isEachWayMarketType(market.description?.marketType ?? '');
  const marketPrice = getBestPrices({ marketPrices, ...bet });
  const marketCurrency = marketPrices?.currency;
  const isCurrencyChanged = isMultiCurrencyChanged(marketCurrency);
  const isMultiCurrencyMessage = isMultiCurrencySupported && isCurrencyChanged;
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isPlacementValid = selectedBetsList.every(i => i.isValid !== false);
  const isPlacementEnabled = isLoggedIn && isPlacementValid;
  const { min, isLineMarket } = getLineRangeParams({
    lineRangeInfo: market.description?.lineRangeInfo ?? null,
    bettingType: market.description?.bettingType
  });
  const selection = market.runners?.find(
    runner => runner.selectionId === bet.selectionId && parseHandicap(runner.handicap) === parseHandicap(bet.handicap)
  );
  const isCricket = market?.eventType?.id === SportId.CRICKET;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;

  const selectionName = useMemo(() => {
    if (bet.pageBlock === PageBlocks.FANCY_VIEW) {
      return market.marketName;
    } else if (market.gameType === GAME_TYPES.BACCARAT) {
      return (
        <>
          {selection?.runnerName}
          <span>{`${t(`games.betslip.labels.baccarat.${bet.type.toLowerCase()}`)} ${selection?.runnerName}`}</span>
        </>
      );
    } else {
      return selection?.runnerName;
    }
  }, [bet.pageBlock, bet.type, market.gameType, market.marketName, selection?.runnerName]);

  useEffect(() => {
    if (bet.size === undefined && accountSettings?.defaultStake && !isSportPage(location.pathname)) {
      dispatch(
        updateSelectedBet({
          ...bet,
          size: accountSettings.defaultStakes?.find(i => i?.defaultValue)?.value || ''
        })
      );
    }
  }, [accountSettings?.defaultStake, bet.size, location.pathname]);

  useEffect(() => {
    dispatch(
      updateSelectedBet({
        ...bet,
        focusedField: null
      })
    );
  }, [bet.focusedField]);

  useEffect(() => {
    if (marketPrices?.marketDefinition?.status === MarketStatus.CLOSED) {
      dispatch(removeSelectedBet(bet));
    }
  }, [marketPrices?.marketDefinition?.status]);

  useEffect(() => {
    if (!bet.price && !bet.isPriceDirty && isLineMarket) {
      dispatch(
        updateSelectedBet({
          ...bet,
          initPrice: bet.price,
          price: min
        })
      );
    }
  }, [bet.price, isLineMarket, min]);

  const onRemoveBetClick = () => {
    dispatch(removeSelectedBet(bet));
  };

  const onFormChanged = useCallback(
    (changedPrice: TPrice, changedSize: TSize, changedProfit: TProfit, isPriceValid: boolean, isSizeValid: boolean) => {
      const isPriceDirtyProp = bet.price !== changedPrice ? { isPriceDirty: true } : {};

      dispatch(
        updateSelectedBet({
          ...bet,
          price: changedPrice,
          size: changedSize,
          profit: changedProfit,
          isValid: isPriceValid && isSizeValid,
          ...isPriceDirtyProp
        })
      );
    },
    [bet]
  );

  const onEnterClick = useCallback(() => {
    if (isPlaceBetsWithEnterKeyEnabled && isPlacementEnabled) {
      if (placeBetsState === EPlaceBetsStates.SELECT) {
        if (isConfirmBetsEnabled) {
          dispatch(setPlaceBetsState(EPlaceBetsStates.CONFIRM));
        } else {
          dispatch(setPlaceBetsState(EPlaceBetsStates.PLACE));
        }
      }
    }
  }, []);

  const cancelBtnTabIndex = selectedBetsLength * tabulation.BET_TABS + betIndex + tabulation.PLACE_BTN_ORDER + 1;

  const handlerQuickBets = useCallback(
    (value: string | number) => {
      dispatch(
        updateSelectedBet({
          ...bet,
          size: value,
          focusedField: EBetFocusFields.SIZE
        })
      );
    },
    [bet]
  );

  return (
    <div
      className={classNames(
        styles.bet,
        styles[`bet__${bet.type.toLowerCase()}`],
        branding.BET_CONTENT,
        branding[bet.type],
        { [branding.FANCY_SWAP]: isFancySwapColors }
      )}
      data-bet-type={bet.type.toLowerCase()}
      data-selection-id={bet.selectionId}
      data-handicap={bet.handicap}
      data-market-id={market?.marketId}
      data-event-id={market?.event?.id}
      data-market-prices={true}
      ref={ref}
    >
      {isMultiCurrencyMessage ? (
        <CurrencyMessage marketCurrency={marketCurrency} />
      ) : (
        <>
          <div className={styles.bet__inner}>
            <div className={styles.betSelectionName}>
              {placeBetsState === EPlaceBetsStates.SELECT && (
                <button
                  type="button"
                  className={classNames(styles.betRemoveIcon, branding.REMOVE_SELECTED_BET_ICON)}
                  onClick={onRemoveBetClick}
                  tabIndex={cancelBtnTabIndex}
                >
                  <i className="fa2 fa2-removed " aria-hidden="true">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                  </i>
                </button>
              )}
              {selectionName}
            </div>
            <BetForm
              marketId={bet.marketId}
              betPrice={bet.price}
              betSize={bet.size}
              betType={bet.type}
              betError={bet.error}
              marketPrice={marketPrice}
              bettingType={market.description?.bettingType ?? ''}
              marketType={market.description?.marketType ?? ''}
              lineRangeInfo={market.description?.lineRangeInfo ?? null}
              eachWayDivisor={market.description?.eachWayDivisor ?? null}
              priceLadderDescription={market.description?.priceLadderDescription ?? null}
              mode={placeBetsState}
              onFormChanged={onFormChanged}
              currency={marketCurrency}
              betIndex={betIndex}
              totalTabFields={tabulation.BET_TABS}
              focusedField={bet.focusedField}
              onEnterClick={onEnterClick}
            />
          </div>
          <QuickBets handler={handlerQuickBets} />
          {isEachWay && market.description?.eachWayDivisor && (
            <div className={styles.bet__eachWayLabel}>
              {t('market.each.way.termsNoPref', {
                odds: market.description?.eachWayDivisor,
                places: market.numberOfWinners
              })}
            </div>
          )}
          {isLineMarket && <div className={styles.bet__lineInfo}>{t('betslip.labels.oddsAreAlways2')}</div>}
          <div className={styles.bet__labels}>
            <BetLabels
              price={bet.price}
              size={bet.size}
              handicap={bet.handicap}
              betType={bet.type}
              gameType={market.gameType}
              eventTypeId={market.eventType?.id ?? ''}
              bettingType={market.description?.bettingType ?? ''}
              marketType={market.description?.marketType ?? ''}
              lineRangeInfo={market.description?.lineRangeInfo ?? null}
              eachWayDivisor={market.description?.eachWayDivisor ?? null}
              handicapType={selection?.lineSide}
              selectionName={selection?.runnerName}
              runners={market.runners}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SelectedBet;
