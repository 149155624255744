import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { HIDE_MOBILE_CANCEL_ALL_BETS_TIMEOUT } from 'constants/placement';
import useDevice from 'hooks/useDevice';

import styles from './styles.module.scss';

interface BetUpdateAlertProps {
  reason: boolean;
  notificationMessage: string;
}

const BetUpdateAlert = ({ reason, notificationMessage }: BetUpdateAlertProps) => {
  const { isMobile } = useDevice();

  const [showCancelBetsMobileAlert, setShowCancelBetsMobileAlert] = useState(false);

  useEffect(() => {
    if (reason && isMobile) {
      setShowCancelBetsMobileAlert(true);

      const timer = setTimeout(() => setShowCancelBetsMobileAlert(false), HIDE_MOBILE_CANCEL_ALL_BETS_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [reason, isMobile]);

  if (!showCancelBetsMobileAlert) {
    return null;
  }

  return (
    <div className="biab_bet-matched">
      <div
        className={classNames('biab_matching-bet-msg biab_visible-xs biab_cancelled-all-bets-message', {
          [styles.cancelledAllMessageMobile]: isMobile
        })}
      >
        <p className="biab_matched-details">{notificationMessage}</p>
      </div>
    </div>
  );
};

export default BetUpdateAlert;
