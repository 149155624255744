import { TPlacementError } from 'redux/modules/placement/type';
import { PageBlocks, PlacementPage } from 'types';
import { BetTypes, EPersistenceTypes, THandicap, TPrice, TProfit, TSize } from 'types/bets';
import { EBetslipTypes, EPlacementType, TBetslipMessage } from 'types/betslip';

export enum EBetslipFilters {
  MARKET = 'MARKET',
  MATCHED = 'MATCHED',
  SORTING = 'SORTING'
}

export enum EBetslipTabs {
  PLACE_BETS = 'PLACE_BETS',
  OPEN_BETS = 'OPEN_BETS'
}

export enum EPlaceBetsStates {
  SELECT = 'SELECT',
  CONFIRM = 'CONFIRM',
  CONFIRM_REMOVING = 'CONFIRM_REMOVING',
  TAKE_OFFER = 'TAKE_OFFER',
  PLACE = 'PLACE',
  PLACE_ENTER = 'PLACE_ENTER'
}

export enum EBetErrors {
  EX015 = 'EX015'
}

export enum EKeepSelectedBets {
  KEEP = 'KEEP',
  REMOVE = 'REMOVE',
  NONE = 'NONE'
}

export type TSelectedBet = {
  currency: string;
  marketId: string;
  sportId?: string;
  selectionId: number;
  handicap?: THandicap | null;
  marketType?: string;
  bettingType?: string;
  type: BetTypes;
  price: TPrice | undefined;
  size?: TSize;
  profit?: TProfit;
  pageBlock?: PageBlocks;
  isValid?: boolean;
  numberOfWinners?: number;
  error?: EBetErrors | null;
  focusedField?: EBetFocusFields | null;
  side?: string;
  /** Is selected via Bet All button */
  isBetAll?: boolean;
  /** Default price from market prices, before min valid price value is set up */
  initPrice?: TPrice;
  page?: PlacementPage;
  persistenceType?: EPersistenceTypes;
  redirectedTo?: PageBlocks;
  isPriceDirty?: boolean;
};

export enum EBetFocusFields {
  PRICE = 'PRICE',
  SIZE = 'SIZE'
}

export type TBetslipFilter = {
  [filter in EBetslipFilters]?: boolean;
};

export type TSelectedBetsBySelection = {
  [key: string]: TSelectedBet;
};

export type TSelectedBetsByTypes = {
  [betType in BetTypes]?: TSelectedBetsBySelection;
};

export type TSelectedBetsByMarket = {
  [marketId: string]: TSelectedBetsByTypes;
};

export type TBetslipFilterPayload = {
  [filter in EBetslipFilters]?: boolean;
};

export type TPlacedBets = {
  [offerId: number]: EPlacementType;
};

export type TPlacedBetsToUpdate = {
  [offerId: number]: EPlacementType;
};

export type TBetslipState = {
  /**
   * Is Betslip collapse
   */
  collapse: boolean;
  /**
   * Place bets or Opened bets active tab
   */
  activeTab: EBetslipTabs;
  /**
   * Filters in Betslip header
   */
  filters: TBetslipFilter;
  /**
   * Selected market to place bets
   */
  currentMarketId: string;
  /**
   * Sport id of selected market to place bets
   */
  currentSportId: string;
  /**
   * Block from where selected bet was added (via click on the cell)
   */
  pageBlock: PageBlocks;
  /**
   * List of selected bets
   * */
  selectedBets: TSelectedBetsByMarket;
  /**
   * list of bets that were placed on Place bets tab
   * */
  placedBets: TPlacedBets;
  /**
   * List of bets that have to be updated
   */
  placedBetsToUpdate: TPlacedBetsToUpdate;
  /**
   * Message that will be shown on Opened bets tab if bets are matched
   * */
  placementMessage: TBetslipMessage;
  /**
   * Are bets in the edit or confirm mode on Place bets tab
   */
  placeBetsState: EPlaceBetsStates;
  /**
   * Error message to show in the top
   */
  errorMessage: string;
  /**
   * Show loading spinner
   */
  loading: boolean;
  /**
   * Consolidate bets checkbox value
   */
  consolidateBets: boolean;
  /**
   * Indicator that is set to true when betslip tab need to be reset (For Opened bets if there are current bets)
   */
  resetBetslipTab: boolean;
  /**
   * Betslip for Exchange or Games
   */
  betslipType: EBetslipTypes;
  /**
   * Indicator that first unmatched opened bet must be focused
   */
  isFirstOpenedBetFocused?: boolean;
  /**
   * Disable only ONE next betslitTab reset
   */
  disableNextResetBetslipTab?: boolean;
  /**
   * Responsible error message
   */
  rgErrorMessage: TPlacementError | null | string;
  /**
   * Keep selected bets when navigate from other pages to market page via cell
   */
  keepSelectedBets: EKeepSelectedBets;
};
