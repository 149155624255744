import { useSelector } from 'react-redux';

import useDeviceSettings from 'hooks/useDeviceSettings';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';

const useLayColumn = (sportId: string) => {
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const { sportsWithoutLayColumn, showLayOddsSports } = useDeviceSettings();

  const isNotEnabledByEventId = sportsWithoutLayColumn?.includes(sportId);

  return {
    isLayColumnEnabled: (showLayOddsSports && !isNotEnabledByEventId) || (!isPNCEnabled && !isNotEnabledByEventId)
  };
};

export default useLayColumn;
