import { createSelector } from '@reduxjs/toolkit';

import { AsianViewSection } from 'constants/asianView';
import { AppState } from 'redux/reducers';
import { THandicap } from 'types/bets';

const getAsianViewData = (state: AppState) => state.asianView;

export const getAsianViewLoading = (state: AppState) => state.asianView.loading;

export const getIsSearchResults = (state: AppState) => state.asianView.isSearchResults;

export const getOpenEventId = (state: AppState) => state.asianView.openEventId;
export const getAsianViewIsCashOutTabOpened = (state: AppState) => state.asianView.isCashOutTabOpened;

export const getAsianViewLast = (state: AppState) => state.asianView.response.last;
export const getAsianViewTotalPages = (state: AppState) => state.asianView.response.totalPages;

export const getAsianViewContent = (state: AppState) => state.asianView.response.content;

export const getAsianViewEventIds = createSelector(getAsianViewContent, content =>
  Object.values(content).flatMap(events => events.map(event => event.id))
);

export const getAsianViewCompetitionKeys = createSelector(getAsianViewData, ({ response }) =>
  Object.keys(response.content)
);

export const getAsianViewCompetitionEvents = (competitionKey: string) => (state: AppState) =>
  state.asianView.response.content[competitionKey] ?? [];

export const getAsianViewTotalElements = (state: AppState) => state.asianView.response.totalElements;

export const getAsianViewEvents = (state: AppState) => state.asianView.events;

export const getAsianViewEventByEventId = (eventId: string) =>
  createSelector(getAsianViewEvents, events => events.filter(event => event.id === eventId)?.[0]);

export const getAsianViewFirstMarketId = (state: AppState) =>
  state.asianView.events[0]?.sections[AsianViewSection.TOP]?.[0]?.id ?? '';
export const getAsianViewFirstHalfMarketId = (state: AppState) =>
  state.asianView.events[0]?.sections[AsianViewSection.TOP]?.[1]?.id ?? '';
export const getAsianViewMarkets = (state: AppState) => state.asianView.markets;

export const getSelectedTabLoading = (state: AppState) => state.asianView.tabLoading;

export const getIsCollapseOpenById = (id: number | string) => (state: AppState) => state.asianView.collapse[id];

export const getMarketRules = (marketId: string) =>
  createSelector(getAsianViewData, ({ rules }) => (rules ? rules[marketId] : null));
export const getMarketRulesLoading = (state: AppState) => state.asianView.rulesLoading;

export const getCountMarketsById = (id: number | string) => (state: AppState) =>
  state.asianView.countMarkets?.[id] || 0;

export const getAsianViewDaysValue = (state: AppState) => state.asianView.daysValue;
export const getAsianViewAutoCollapsedRightSide = (state: AppState) => state.asianView.isAutoCollapsedRightSide;

export const getAsianViewIsCollapsedRightSide = (state: AppState) => state.asianView.isCollapsedRightSide;

export const getIsMobileAsianView = (state: AppState) => state.asianView.isMobileAsianView;

export const getIsLandscapeAsianView = (state: AppState) => state.asianView.isLandscapeAsianView;

export const getIsAllAsianViewCompetitionsClosed = (closedMarketsIds: string[] = []) =>
  createSelector(getAsianViewData, ({ response }) => {
    const competitions = Object.values(response.content ?? {});
    return !!(
      competitions.length &&
      competitions.every(eventsList => {
        return (
          eventsList &&
          eventsList.every(
            ({ sections }) =>
              sections[AsianViewSection.TOP] &&
              sections[AsianViewSection.TOP].every(({ id }) => closedMarketsIds.includes(id))
          )
        );
      })
    );
  });

export const getIsAsianViewCompetitionClosed = (competitionKey: string, closedMarketsIds: string[] = []) =>
  createSelector(getAsianViewData, ({ response }) => {
    const competitions = response.content[competitionKey] ?? [];
    return !!(
      competitions &&
      competitions.length &&
      competitions.every(
        ({ sections }) =>
          sections[AsianViewSection.TOP] &&
          sections[AsianViewSection.TOP].every(({ id }) => closedMarketsIds.includes(id))
      )
    );
  });

export const getAsianEvent = (eventId: string) =>
  createSelector(getAsianViewEvents, events => {
    return events.find(event => event.id === eventId);
  });

export const getAsianMarket = (eventId: string, marketId: string) =>
  createSelector(getAsianEvent(eventId), getAsianViewMarkets, (event, markets) => {
    return (
      event?.sections[AsianViewSection.TOP]?.find(market => market.id === marketId) ||
      markets.find(market => market.id === marketId)
    );
  });

export const getAsianRunner = (eventId: string, marketId: string, runnerId: number, handicap?: THandicap | null) =>
  createSelector(getAsianMarket(eventId, marketId), market =>
    market?.runners.find(
      runner => Number(runner.id) === Number(runnerId) && +(runner.handicap || 0) === +(handicap || 0)
    )
  );

export const getAsianSectionSize = (state: AppState) => state.asianView.sectionSize;
export const getAreEventCouponsLoaded = ({ asianView }: AppState) => asianView.areEventCouponsLoaded;
export const getIsAsianViewNotFoundView = ({ asianView }: AppState) => asianView.isAsianViewNotFoundView;
export const getAVProfitLossHalfTimeTableData = ({ asianView }: AppState) => asianView.profitLossTableData?.htpl;
export const getAVProfitLossFullTimeTableData = ({ asianView }: AppState) => asianView.profitLossTableData?.ftpl;
export const getAVProfitLossTablePopUpEventId = ({ asianView }: AppState) => asianView.profitLossTablePopUpEventId;
export const getAVProfitLossTablePopUpMarketId = ({ asianView }: AppState) => asianView.profitLossTablePopUpMarketId;
export const getAVProfitLossTableEventName = ({ asianView }: AppState) => asianView.profitLossTableData?.eventName;
export const getAVProfitLossTableDataError = ({ asianView }: AppState) => asianView.profitLossTableDataError;
export const getAVProfitLossTableDataLoading = ({ asianView }: AppState) => asianView.profitLossTableDataLoading;
export const getIsAVProfitLossTableDataFirstLoaded = ({ asianView }: AppState) =>
  asianView.profitLossTableDataFirstLoaded;
export const getAVStringifiedProfitLossTableData = ({ asianView }: AppState) =>
  asianView.stringifiedProfitLossTableData;
export const getAsianViewPLHTMarkets = ({ asianView }: AppState) => asianView.asianViewPLHTMarkets;
export const getAsianViewPLFTMarkets = ({ asianView }: AppState) => asianView.asianViewPLFTMarkets;
export const getAsianViewStringifiedCountMarkets = ({ asianView }: AppState) => asianView.stringifiedCountMarkets;

export const getAsianViewEventIndex =
  (eventId: string) =>
  ({ asianView }: AppState) =>
    asianView.events.findIndex(event => event.id === eventId);

export const getAsianViewError = ({ asianView }: AppState) => asianView.error;
