import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FAVORITES_TYPES } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  ASIAN_OUTRIGHT_MARKET_ID,
  ASIAN_SINGLE_VIEW_EVENT_ID
} from 'constants/asianView';
import useAsianSingleView from 'hooks/useAsianSingleView';
import useLeaguesParam from 'hooks/useLeaguesParam';

const useLeaguesFilters = () => {
  const [searchParams] = useSearchParams();

  const competitions = useLeaguesParam();
  const { isAsianSingleView } = useAsianSingleView();

  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const singleViewEventId = searchParams.get(ASIAN_SINGLE_VIEW_EVENT_ID);
  const marketId = searchParams.get(ASIAN_OUTRIGHT_MARKET_ID);

  return useMemo(() => {
    if (!isAsianSingleView) {
      return {};
    }

    if (marketId && !competitions.length) {
      return { markets: [marketId] };
    }

    if (singleViewEventId) {
      return { coupons: [singleViewEventId] };
    }

    if (isFavouriteView && favouriteType === FAVORITES_TYPES.event) {
      return { coupons: [favouriteId] };
    }

    return competitions.length ? { competitions } : {};
  }, [isAsianSingleView, marketId, competitions, singleViewEventId, isFavouriteView, favouriteType, favouriteId]);
};

export default useLeaguesFilters;
