import { useSelector } from 'react-redux';

import useDeviceSettings from 'hooks/useDeviceSettings';
import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { getAccountSettings } from 'redux/modules/user/selectors';

const useInlinePlacement = () => {
  const device = useSelector(getAppDevice);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const accountSettings = useSelector(getAccountSettings);

  const { inlinePlacementMiddleSection, inlinePlacement } = useDeviceSettings();

  if (device === Devices.DESKTOP) {
    if (!isLoggedIn) {
      return inlinePlacementMiddleSection;
    } else if (inlinePlacementMiddleSection) {
      if (inlinePlacement) {
        return !accountSettings?.betslipPlacement;
      } else {
        return true;
      }
    }
  } else {
    return true;
  }
};

export default useInlinePlacement;
