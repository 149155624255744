import { all } from 'redux-saga/effects';

import appSaga from 'redux/modules/appConfigs/saga';
import appSettingsSaga from 'redux/modules/appSettings/saga';
import asianSportsNavigationSaga from 'redux/modules/asianSportsNavigation/saga';
import asianViewSaga from 'redux/modules/asianView/saga';
import asianViewBetSlipSaga from 'redux/modules/asianViewBetslip/saga';
import asianViewBetSlipCashOutSaga from 'redux/modules/asianViewBetSlipCashOut/saga';
import asianViewCashOutSaga from 'redux/modules/asianViewCashOut/saga';
import asianViewCurrentBetsSaga from 'redux/modules/asianViewCurrentBets/saga';
import asianViewFavouritesSaga from 'redux/modules/asianViewFavourites/saga';
import asianViewLeaguesFilterSaga from 'redux/modules/asianViewLeaguesFilter/saga';
import asianViewMobileBetslipSaga from 'redux/modules/asianViewMobileBetslip/saga';
import asianViewPopularLinksSaga from 'redux/modules/asianViewPopularLinks/saga';
import asianViewQuickBettingSaga from 'redux/modules/asianViewQuickBetting/saga';
import authSaga from 'redux/modules/auth/saga';
import bannersSaga from 'redux/modules/banners/saga';
import betsStatusesSaga from 'redux/modules/betsStatuses/saga';
import cancelActions from 'redux/modules/cancelActions/saga';
import cashOutSaga from 'redux/modules/cashOut/saga';
import competitionsSaga from 'redux/modules/competitions/saga';
import currentBetsSaga from 'redux/modules/currentBets/saga';
import eventDetails from 'redux/modules/event/saga';
import eventCashOutSaga from 'redux/modules/eventCashOut/saga';
import fancyMarketSaga from 'redux/modules/fancyMarket/saga';
import favoritesSaga from 'redux/modules/favorites/saga';
import featuredMarkets from 'redux/modules/featuredMarkets/saga';
import gamesSaga from 'redux/modules/games/saga';
import homeSaga from 'redux/modules/home/saga';
import inPlaySaga from 'redux/modules/inPlay/saga';
import listViewTabsSaga from 'redux/modules/listViewTabs/saga';
import marketSaga from 'redux/modules/market/saga';
import marketPricesSaga from 'redux/modules/marketsPrices/saga';
import metaSaga from 'redux/modules/meta/saga';
import multiMarketSaga from 'redux/modules/multiMarket/saga';
import myBetsSaga from 'redux/modules/myBets/saga';
import backendPageSaga from 'redux/modules/pages/saga';
import placementSaga from 'redux/modules/placement/saga';
import plTableSaga from 'redux/modules/plTable/saga';
import pokerstarSaga from 'redux/modules/pokerstar/saga';
import popularSaga from 'redux/modules/popular/saga';
import marketOdds from 'redux/modules/popularMarket/saga';
import nextRaces from 'redux/modules/races/saga';
import racingSaga from 'redux/modules/racing/saga';
import responsibleBettingSaga from 'redux/modules/responsibleBetting/saga';
import searchSaga from 'redux/modules/search/saga';
import sportPageSaga from 'redux/modules/sportPage/saga';
import sportsSaga from 'redux/modules/sports/saga';
import tooltipsSaga from 'redux/modules/tooltip/saga';
import top5ViewSaga from 'redux/modules/top5View/saga';
import toursSaga from 'redux/modules/tours/saga';
import userSaga from 'redux/modules/user/saga';
import videoStreamSaga from 'redux/modules/videoStream/saga';
import { Generator } from 'types';

export function* rootSaga(): Generator {
  yield all([
    authSaga(),
    asianViewSaga(),
    asianViewCashOutSaga(),
    asianViewLeaguesFilterSaga(),
    asianSportsNavigationSaga(),
    asianViewFavouritesSaga(),
    asianViewMobileBetslipSaga(),
    asianViewPopularLinksSaga(),
    asianViewQuickBettingSaga(),
    asianViewBetSlipCashOutSaga(),
    eventCashOutSaga(),
    appSaga(),
    userSaga(),
    sportsSaga(),
    gamesSaga(),
    nextRaces(),
    competitionsSaga(),
    myBetsSaga(),
    homeSaga(),
    searchSaga(),
    marketOdds(),
    racingSaga(),
    marketOdds(),
    placementSaga(),
    currentBetsSaga(),
    betsStatusesSaga(),
    featuredMarkets(),
    appSettingsSaga(),
    inPlaySaga(),
    eventDetails(),
    marketSaga(),
    sportPageSaga(),
    listViewTabsSaga(),
    fancyMarketSaga(),
    top5ViewSaga(),
    pokerstarSaga(),
    popularSaga(),
    cancelActions(),
    multiMarketSaga(),
    plTableSaga(),
    favoritesSaga(),
    toursSaga(),
    responsibleBettingSaga(),
    cashOutSaga(),
    videoStreamSaga(),
    metaSaga(),
    backendPageSaga(),
    bannersSaga(),
    tooltipsSaga(),
    marketPricesSaga(),
    asianViewBetSlipSaga(),
    asianViewCurrentBetsSaga()
  ]);
}
