import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import MyBetsHeaderCell from 'components/MyBetsHeaderCell';
import { ExchangeTypes, SEPARATE_WALLET } from 'constants/myBets';
import useCommissionRange from 'hooks/useCommissionRange';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import MultiCurrencyDropdown from 'pages/MyBetsPages/components/MultiCurrencyDropdown';
import {
  getAppDevice,
  getDesktopSettingsAlternativeLayOdds,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { BetsType, TStatementContentItem } from 'redux/modules/myBets/type';
import { CookieNames } from 'types';
import { TGetStatementData } from 'types/myBets';

import ContentItem from './components/ContentItem';
import MobileAccountBalance from './components/MobileAccountBalance/MobileAccountBalance';
import MobileDescription from './components/MobileDescription';

import styles from './styles.module.scss';

interface StatementTableProps {
  showBetDetails: boolean;
  content: TStatementContentItem[];
  openMobileStatementDetails: (item: TStatementContentItem, index: number) => void;
  rowHighlighted?: number;
  fetchStatementData: (params: TGetStatementData) => void;
}
const StatementTable = ({
  content,
  openMobileStatementDetails,
  showBetDetails,
  rowHighlighted,
  fetchStatementData
}: StatementTableProps) => {
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE]);

  const { isDesktop } = useDevice();
  const device = useSelector(getAppDevice);
  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.ALL_ACCOUNT_STATEMENT));
  const { commissionRange, showCommissionRange } = useCommissionRange();
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const isAsianViewTable = accountProducts?.asianView && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView;

  const handleOpenDetails = (item: TStatementContentItem, index: number) => {
    window.scrollTo(0, 0);
    openMobileStatementDetails(item, index);
  };

  return (
    <div className="biab_table-holder">
      {isDesktop ? (
        <>
          {content?.length ? (
            <>
              {!isAsianViewTable && alternativeLayOdds && device === Devices.DESKTOP && (
                <div className={classNames(styles.infoMsg, 'biab_info-msg')}>
                  {t('account.mybets.messages.alternativeLayOdds')}
                </div>
              )}
              <div className={classNames('biab_table biab_mybets', styles.table)}>
                <div className={classNames('biab_my-bets-table-header', styles.tableHeader)} role="rowheader">
                  <div className={styles.AccountStatementTableLeftSide}>
                    <MyBetsHeaderCell
                      className={styles.AccountStatementHeader}
                      value={t('account.statement.labels.settled')}
                    />
                    <MyBetsHeaderCell
                      className={styles.AccountStatementHeader}
                      value={t('account.statement.labels.id')}
                    />
                    <MyBetsHeaderCell
                      className={styles.AccountStatementHeader}
                      value={t('account.statement.labels.placed')}
                    />
                  </div>
                  <div className={styles.AccountStatementDescription}>
                    <MyBetsHeaderCell
                      className={styles.AccountStatementDescriptionContent}
                      value={t('account.statement.labels.description')}
                    />
                  </div>
                  <div className={styles.AccountStatementRightSide}>
                    {!isAsianViewTable && alternativeLayOdds ? (
                      <>
                        <MyBetsHeaderCell
                          className={styles.AccountStatementRightSideContent}
                          value={t('account.statement.labels.backOdds')}
                        />
                        <MyBetsHeaderCell
                          className={styles.AccountStatementRightSideContent}
                          value={t('account.statement.labels.layOdds')}
                        />
                      </>
                    ) : (
                      <MyBetsHeaderCell
                        className={styles.AccountStatementRightSideContent}
                        value={t('account.statement.labels.odds')}
                      />
                    )}
                    {isAsianViewTable && (
                      <MyBetsHeaderCell
                        className={styles.AccountStatementRightSideContent}
                        value={t('account.statement.labels.oddsType')}
                      />
                    )}
                    <MyBetsHeaderCell
                      className={styles.AccountStatementRightSideContent}
                      value={t('account.statement.labels.stake')}
                    />
                    {isAsianViewTable && (
                      <MyBetsHeaderCell
                        className={styles.AccountStatementRightSideContent}
                        value={t('account.statement.labels.risk')}
                      />
                    )}
                    <MyBetsHeaderCell
                      className={styles.AccountStatementRightSideContent}
                      value={t('account.statement.labels.status')}
                    />
                    <MyBetsHeaderCell
                      className={styles.AccountStatementRightSideContent}
                      value={t('account.statement.labels.debit')}
                    />
                    <MyBetsHeaderCell
                      className={styles.AccountStatementRightSideContent}
                      value={t('account.statement.labels.credit')}
                    />
                    {walletIntegrationType === SEPARATE_WALLET && (
                      <MyBetsHeaderCell
                        className={styles.AccountStatementRightSideContent}
                        value={t('account.statement.labels.balance')}
                      />
                    )}
                  </div>
                </div>
              </div>

              {content?.map((el: TStatementContentItem, index: number) => (
                <ContentItem key={index + el.refId} item={el} />
              ))}
            </>
          ) : (
            <div className={styles.noData}>{t('account.mybets.messages.noData')}</div>
          )}
        </>
      ) : (
        <>
          <div
            className={classNames('biab_info-msg', styles.commissionRangeMobile, {
              [styles.commissionRangeMobile__noCommissionRange]: !showCommissionRange
            })}
          >
            {showCommissionRange && (
              <span className="biab_commission-msg">
                {t('accountStatement.commissionRange')}:{commissionRange}
              </span>
            )}
            {isMultiCurrencySupported && (
              <MultiCurrencyDropdown
                fetchDataWithCurrencyHandlers={{ fetchStatementDataHandler: fetchStatementData }}
              />
            )}
          </div>
          {content?.length ? (
            <>
              {!showBetDetails && (
                <div className={classNames(styles.mobileTableHeader, 'biab_account-table-header')}>
                  <span>{t('account.statement.labels.description')}</span>
                  {walletIntegrationType === SEPARATE_WALLET && <span>{t('account.statement.labels.balance')}</span>}
                </div>
              )}
              <div className="biab_table">
                {!showBetDetails &&
                  content?.map((el: TStatementContentItem, index: number) => (
                    <div
                      key={el.refId}
                      onClick={() => handleOpenDetails(el, index)}
                      className={classNames('biab_text-left biab_mobile-account-item', styles.mobileAccountItem, {
                        [styles.highlighted]: index === rowHighlighted
                      })}
                    >
                      <MobileDescription item={el} />
                      {walletIntegrationType === SEPARATE_WALLET && (
                        <MobileAccountBalance balance={el.balance} currency={el.currency} />
                      )}
                      <i className="biab_arrow-icon fa2 fa2-arrow-right" />
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <div className={styles.noData}>{t('account.statement.messages.noData')}</div>
          )}
        </>
      )}
    </div>
  );
};

export default StatementTable;
