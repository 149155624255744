import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';
import { ResponsibleBettingDisplayedContent } from 'types/responsibleBetting';

const getResponsibleBetting = (state: AppState) => state.responsibleBetting;
export const getResponsibleBettingSettings = createSelector(
  getResponsibleBetting,
  responsibleBetting => responsibleBetting.settings
);
export const getResponsibleBettingLoading = ({ responsibleBetting }: AppState) => responsibleBetting.loading;
export const getResponsibleBettingReminderUnit = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.reminderUnit;
export const getIsResponsibleBettingDayLimitEnabled = ({ responsibleBetting }: AppState) =>
  !!responsibleBetting.settings?.rgTools?.dayLimitEnabled;
export const getResponsibleBettingDayLimit = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.dayLimit;
export const getResponsibleBettingDayLimitStartDate = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.dayLimitStartDate;
export const getResponsibleBettingReminderEnabled = ({ responsibleBetting }: AppState) =>
  !!responsibleBetting.settings?.rgTools?.reminderEnabled;
export const getResponsibleBettingReminderInterval = ({ responsibleBetting }: AppState) =>
  responsibleBetting.settings?.rgTools?.reminderInterval;
export const getResponsibleBettingLoadingByType =
  (type: ResponsibleBettingDisplayedContent) =>
  ({ responsibleBetting }: AppState) =>
    responsibleBetting.loadingByType[type];
