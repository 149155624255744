import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { SPORT_BASE_URL } from 'constants/locations';

import styles from '../../styles.module.scss';

interface InlinePlacementTooltipWrapperProps {
  length: number;
  classname: string;
  tooltipText: string;
  linkClasses: string;
  marketId: string;
  eventTypeId: string;
  inMarketsTableRow?: boolean;
}

const InlinePlacementTooltipWrapper = ({
  classname,
  length,
  tooltipText,
  eventTypeId,
  marketId,
  linkClasses
}: InlinePlacementTooltipWrapperProps) => {
  return (
    <div
      className={classNames(
        styles.tooltip,
        'biab_inline-placement-tooltip biab_inline-placement-tooltip-wrap biab_inline-placement-label',
        classname
      )}
    >
      <span className={classNames('biab_inline-placement-tooltip-content-wrap', styles.tooltipHolder)}>
        <span className={classNames('biab_inline-placement-tooltip-content', styles.tooltipContent)}>
          <span
            className={classNames('biab_inline-placement-tooltip-text', styles.tooltipString)}
          >{`${length} ${tooltipText}`}</span>
        </span>
      </span>
      <NavLink to={`${SPORT_BASE_URL}/${eventTypeId}/market/${marketId}`} className={linkClasses}>
        {length}
      </NavLink>
    </div>
  );
};

export default InlinePlacementTooltipWrapper;
