import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import ResponsibleBettingDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingDropdown';
import ResponsibleBettingInputField from 'components/ResponsibleBetting/components/ResponsibleBettingInputField';
import ResponsibleBettingMobileDropdown from 'components/ResponsibleBetting/components/ResponsibleBettingMobileDropdown';
import commonStyles from 'components/ResponsibleBetting/styles.module.scss';
import { getCurrency, getDisplayCurrencySymbol } from 'redux/modules/appConfigs/selectors';
import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import { ResponsibleBettingButtonTypes } from 'types/responsibleBetting';
import { convertTimeUnitsToDropdown } from 'utils/responsibleBetting';

import ResponsibleBettingBtn from '../ResponsibleBettingBtn';
import ResponsibleBettingValidationMessage from '../ResponsibleBettingInputValidation';

import styles from './styles.module.scss';

interface ResponsibleBettingCreateLimitFormProps {
  isMobile: boolean;
  error: { message: string; value: number } | null;
  selectedTimeUnit: CombinedResponsibleBettingTimeUnit | null;
  selectedLimit: number | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onCancel: () => void;
  onApply: () => void;
  onSelectOption: (option: DropdownItem) => void;
}

const ResponsibleBettingCreateLimitForm = ({
  isMobile,
  error,
  selectedLimit,
  selectedTimeUnit,
  onChange,
  onCancel,
  onApply,
  onSelectOption
}: ResponsibleBettingCreateLimitFormProps) => {
  const { t } = useTranslation();

  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const defaultCurrency = useSelector(getCurrency);

  const lossLimitTimeUnits = convertTimeUnitsToDropdown(t);
  const limitAmountLabel = t('responsibleBetting.labels.limitAmount');
  const limitPeriodLabel = t('responsibleBetting.labels.limitPeriod');
  const inputLabel = displayCurrencySymbol ? `${limitAmountLabel}, ${defaultCurrency?.symbol}` : limitAmountLabel;

  return (
    <div
      className={classNames(commonStyles.content, {
        [commonStyles.content__mobile]: isMobile
      })}
    >
      <div
        className={classNames(commonStyles.inputs, {
          [commonStyles.inputs__with_error]: !!error,
          [commonStyles.inputs__mobile]: isMobile
        })}
      >
        <ResponsibleBettingInputField
          value={selectedLimit}
          label={inputLabel}
          placeholder={t('responsibleBetting.placeholders.enterAmount')}
          onChange={onChange}
          isMobile={isMobile}
          error={!!error}
        />
        {isMobile && <ResponsibleBettingValidationMessage error={error} />}
        {isMobile ? (
          <ResponsibleBettingMobileDropdown
            data={lossLimitTimeUnits}
            onSelectOption={onSelectOption}
            selectedValue={selectedTimeUnit}
            label={limitPeriodLabel}
          />
        ) : (
          <div className={commonStyles.input}>
            <label className={commonStyles.label}>{limitPeriodLabel}</label>
            <ResponsibleBettingDropdown
              onChangeOption={onSelectOption}
              data={lossLimitTimeUnits}
              value={selectedTimeUnit}
            />
          </div>
        )}
      </div>
      {!isMobile && <ResponsibleBettingValidationMessage error={error} />}

      <div className={commonStyles.divider} />

      <div
        className={classNames(styles.controllersContainer, {
          [styles.container__mobile]: isMobile
        })}
      >
        <div
          className={classNames(styles.controllers, {
            [styles.controllers__mobile]: isMobile
          })}
        >
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.cancel')}
            onClick={onCancel}
            isMobile={isMobile}
            type={ResponsibleBettingButtonTypes.Secondary}
          />
          <ResponsibleBettingBtn
            label={t('responsibleBetting.buttons.apply')}
            onClick={onApply}
            isMobile={isMobile}
            disabled={!selectedLimit || !selectedTimeUnit || !!error}
            type={ResponsibleBettingButtonTypes.Primary}
          />
        </div>
      </div>
    </div>
  );
};

export default ResponsibleBettingCreateLimitForm;
