import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isNaN, toNumber } from 'lodash';

import { KEY_CODES } from 'constants/betslip';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setErrorMessage } from 'redux/modules/betslip';
import { getBetslipErrorMessage, getBetslipType } from 'redux/modules/betslip/selectors';
import { TErrorMessage, TPrice, TSize } from 'types/bets';
import { EBetslipTypes, EFormActions, EInputFieldTypes } from 'types/betslip';

import formStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

interface IInputFieldProps {
  value: TPrice | TSize;
  validValue: TPrice | TSize;
  inputPattern: RegExp;
  isValid: boolean;
  isError?: boolean;
  fieldTabIndex?: number;
  isFocus?: boolean;
  formAction?: EFormActions;
  fieldType?: EInputFieldTypes;
  errorMessage: TErrorMessage;
  updateValidation: (value: TPrice | TSize) => void;
  onChange: (value: TPrice | TSize) => void;
  onValidate: (isValid: boolean) => void;
  onEnterClick: () => void;
}

const InputField = ({
  value = '',
  validValue,
  inputPattern,
  isValid,
  isError,
  errorMessage,
  updateValidation,
  onChange,
  onValidate,
  onEnterClick,
  formAction = EFormActions.NONE,
  fieldType = EInputFieldTypes.NONE,
  fieldTabIndex = 0,
  isFocus
}: IInputFieldProps) => {
  const dispatch = useDispatch();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const betslipType = useSelector(getBetslipType);
  const betslipErrorMessage = useSelector(getBetslipErrorMessage);

  const [isValidField, setIsValidField] = useState(true);
  const [isDirtyField, setIsDirtyField] = useState(false);

  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const isDisabled = isPNCEnabled && !isGameBetslip && fieldType === EInputFieldTypes.PRICE;
  const inputRef = useRef<HTMLInputElement>(null);
  const isMountedRef = useRef<boolean>(true);

  useEffect(() => {
    if (
      inputRef.current !== null &&
      ((isPNCEnabled && !isGameBetslip && fieldType === EInputFieldTypes.SIZE) ||
        ((!isPNCEnabled || isGameBetslip) && fieldType === EInputFieldTypes.PRICE))
    ) {
      inputRef.current.select();
    }
  }, [fieldType, isGameBetslip, isPNCEnabled, updateValidation]);

  useEffect(() => {
    if (isFocus && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [isFocus]);

  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!betslipErrorMessage) {
      setIsValidField(true);
    }
  }, [betslipErrorMessage]);

  useEffect(() => {
    if (formAction === EFormActions.VALIDATE) {
      blurHandler();
    }
  }, [formAction]);

  useEffect(() => {
    if (toNumber(value) !== toNumber(value)) {
      if (!isNaN(toNumber(value))) {
        setIsDirtyField(true);
      }
    }
  }, [value]);

  useEffect(() => {
    onValidate(isValid);
  }, [isValid]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value
      .trim()
      .replace(/[^0-9.]/g, '')
      .replace(/\.\./g, '.');

    if (changedValue === '' || inputPattern.test(changedValue)) {
      onChange(changedValue);
      updateValidation(changedValue);
    }

    if (betslipErrorMessage) {
      dispatch(setErrorMessage(''));
    }

    setIsValidField(true);
    setIsDirtyField(true);
  };

  const blurHandler = () => {
    if (!isValid && isDirtyField && value !== validValue) {
      onChange(validValue);
      updateValidation(validValue);

      setIsValidField(false);

      if (isMountedRef.current) {
        dispatch(setErrorMessage(errorMessage.message));
      }
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER) {
      e.preventDefault();
      onEnterClick();
    }
  };

  return (
    <>
      <input
        className={classNames(styles.input, formStyles.inputField, styles[`input__${fieldType?.toLowerCase()}`], {
          [styles.input__error]: (!isValidField && betslipErrorMessage) || isError,
          [styles.input__disabled]: isDisabled
        })}
        type="text"
        value={value || ''}
        ref={inputRef}
        tabIndex={fieldTabIndex}
        onChange={changeHandler}
        onBlur={blurHandler}
        onKeyDown={keyDownHandler}
        disabled={isDisabled}
        data-field-type={fieldType}
      />
      {!isValidField && betslipErrorMessage && !!errorMessage.label && (
        <span className={styles.input__errorLabel}>{errorMessage.label}</span>
      )}
    </>
  );
};

export default InputField;
