import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ODDS_REGEX_PATTERN } from 'constants/placement';
import usePriceValidation from 'hooks/usePriceValidation';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { setErrorMessage } from 'redux/modules/betslip';
import { getBetslipErrorMessage, getBetslipPlaceBetsState, getBetslipType } from 'redux/modules/betslip/selectors';
import { EBetErrors, EPlaceBetsStates } from 'redux/modules/betslip/type';
import { BetTypes, TPrice } from 'types/bets';
import { EActionTypes, EBetslipTypes, EInputFieldTypes } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { formatFloatNumber, getLineRangeParams } from 'utils/betValidation';
import { recalculatePriceByStep } from 'utils/price';

import ButtonArrow from '../ButtonArrow';
import InputField from '../InputField';

import styles from './styles.module.scss';

const BetPrice = ({
  price,
  betType,
  bettingType,
  marketType,
  bestPrice,
  betError,
  lineRangeInfo,
  priceLadderDescription,
  onChangePrice,
  onValidate,
  onEnterClick,
  fieldOrder,
  isFocus
}: {
  price: TPrice;
  betType: BetTypes;
  bettingType: string;
  marketType: string;
  bestPrice?: TPrice | null;
  betError?: EBetErrors | null;
  lineRangeInfo: TMarketLineRangeInfo | null;
  priceLadderDescription: TPriceLadderDescription | null;
  onChangePrice: (price: TPrice) => void;
  onValidate: (isValid: boolean) => void;
  onEnterClick: () => void;
  fieldOrder?: number;
  isFocus?: boolean;
}) => {
  const dispatch = useDispatch();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const betslipType = useSelector(getBetslipType);
  const betslipErrorMessage = useSelector(getBetslipErrorMessage);

  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const { min: minValue, max: maxValue } = getLineRangeParams({ lineRangeInfo, bettingType });
  const isTakeOffer = isPNCEnabled && placeBetsState === EPlaceBetsStates.TAKE_OFFER;
  const priceFieldOrder = !isPNCEnabled || isGameBetslip ? fieldOrder : -1;
  const isUpArrowEnabled = +(price || 0) < maxValue;
  const isDownArrowEnabled = +(price || 0) > minValue;
  const isButtonArrowsEnabled = !isPNCEnabled || isGameBetslip;

  const { setValue: updateValidation, ...priceValidationProps } = usePriceValidation({
    price,
    betType,
    bettingType,
    marketType,
    lineRangeInfo,
    priceLadderDescription
  });

  useEffect(() => {
    if (isTakeOffer && bestPrice) {
      onChangePrice(bestPrice);
    }
  }, [bestPrice, isTakeOffer]);

  useEffect(() => {
    updateValidation(price);
  }, [price]);

  /**
   * Change price and check if new price meets the new step and min/max values.
   * @param type
   */
  const changePriceValue = (type: EActionTypes) => {
    const updatedPrice = recalculatePriceByStep({
      type,
      price,
      marketType,
      bettingType,
      lineRangeInfo,
      priceLadderDescription
    });

    onChangePrice(formatFloatNumber(updatedPrice));

    if (betslipErrorMessage) {
      dispatch(setErrorMessage(''));
    }
  };

  return (
    <div className={styles.bet}>
      <InputField
        value={price}
        inputPattern={ODDS_REGEX_PATTERN}
        onChange={onChangePrice}
        onValidate={onValidate}
        onEnterClick={onEnterClick}
        isError={betError === EBetErrors.EX015}
        fieldType={EInputFieldTypes.PRICE}
        fieldTabIndex={priceFieldOrder}
        isFocus={isFocus}
        updateValidation={updateValidation}
        {...priceValidationProps}
      />
      {isButtonArrowsEnabled && (
        <>
          <ButtonArrow type={EActionTypes.ADD} onClickHandler={changePriceValue} isArrowEnabled={isUpArrowEnabled} />
          <ButtonArrow
            type={EActionTypes.SUBSTR}
            onClickHandler={changePriceValue}
            isArrowEnabled={isDownArrowEnabled}
          />
        </>
      )}
    </div>
  );
};

export default BetPrice;
