import { MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react';
import BodyClassName from 'react-body-classname';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';
import classNames from 'classnames';

import Skeleton from 'components/Skeleton';
import tooltipStyles from 'components/Tooltip/styles.module.scss';
import { asianViewMiddleSection as branding, asianViewPopUps } from 'constants/branding';
import { BODY_ID } from 'constants/customFonts';
import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { closeAsianViewProfitLossTablePopUp } from 'redux/modules/asianView';
import {
  getAVProfitLossFullTimeTableData,
  getAVProfitLossHalfTimeTableData,
  getAVProfitLossTableDataError,
  getAVProfitLossTableEventName,
  getAVProfitLossTablePopUpEventId,
  getAVProfitLossTablePopUpMarketId,
  getIsAVProfitLossTableDataFirstLoaded,
  getIsLandscapeAsianView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';
import { getAVAllCurrentBetsByEventId } from 'redux/modules/asianViewCurrentBets/selectors';
import { getMarketPricesStatusById } from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getUserAccountSettingsAsianViewShowPLTableSwipeMessage } from 'redux/modules/user/selectors';
import { MarketStatus } from 'types';

import AVMobilePLTableSwipeMessage from '../AVMobilePLTableSwipeMessage';
import AVProfitLossTable from '../AVProfitLossTable';
import AVProfitLossTablePopUpMarkets from '../AVProfitLossTablePopUpMarkets';
import AVProfitLossTablePopUpRefreshBtn from '../AVProfitLossTablePopUpRefreshBtn';

import styles from './styles.module.scss';

const AsianViewProfitLossTablePopUp = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const halfTimeData = useSelector(getAVProfitLossHalfTimeTableData);
  const fullTimeData = useSelector(getAVProfitLossFullTimeTableData);
  const eventName = useSelector(getAVProfitLossTableEventName);
  const error = useSelector(getAVProfitLossTableDataError);
  const isMobile = useSelector(getIsMobileAsianView);
  const eventId = useSelector(getAVProfitLossTablePopUpEventId);
  const eventsCount = useSelector(getAVAllCurrentBetsByEventId(eventId));
  const marketId = useSelector(getAVProfitLossTablePopUpMarketId);
  const isFirstLoaded = useSelector(getIsAVProfitLossTableDataFirstLoaded);
  const status = useSelector(getMarketPricesStatusById(marketId));
  const isLandscapeView = useSelector(getIsLandscapeAsianView);
  const showSwipeMessage = useSelector(getUserAccountSettingsAsianViewShowPLTableSwipeMessage);
  const isIframeEnabled = useSelector(getIsIframeEnabled);

  const [displayedAllScores, setDisplayedAllScores] = useState(false);
  const [popUpPosition, setPopUpPosition] = useState({
    top: window.innerHeight / 2,
    left: window.innerWidth / 2
  });
  const [isMoving, setIsMoving] = useState(false);

  const popUpRef = useRef<HTMLDivElement>(null);
  const mouseMoveHandler = useRef<((e: MouseEvent) => void) | null>(null);
  const tooltipRef = useRef<TooltipRefProps>(null);

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId,
    eventId,
    section: MarketsPricesSocketParamsSections.AsianViewPLTablePopUp
  });

  const isHalfTimeData = !!halfTimeData?.length;
  const isFullTimeData = !!fullTimeData?.length;
  const isPLTableData = isHalfTimeData || isFullTimeData;
  const isClosedEvent = status === MarketStatus.CLOSED;

  const handleMouseDown = (event: ReactMouseEvent<HTMLDivElement>) => {
    const popUp = popUpRef.current;

    if (!popUp) return;

    setIsMoving(true);
    const { left, top } = popUp.getBoundingClientRect();
    const shiftX = event.clientX - left;
    const shiftY = event.clientY - top;

    const moveAt = (pageX: number, pageY: number) => {
      setPopUpPosition({
        left: pageX - shiftX,
        top: pageY - shiftY
      });
    };

    moveAt(event.pageX, event.pageY);

    const handleMouseMove = (moveEvent: MouseEvent) => {
      moveAt(moveEvent.pageX, moveEvent.pageY);
    };
    mouseMoveHandler.current = handleMouseMove;

    window.addEventListener('mousemove', handleMouseMove);
  };

  const removeMouseMoveHandler = () => {
    if (mouseMoveHandler.current) {
      setIsMoving(false);
      window.removeEventListener('mousemove', mouseMoveHandler.current);
      mouseMoveHandler.current = null;
    }
  };

  const closePopUp = () => {
    dispatch(closeAsianViewProfitLossTablePopUp());
  };

  const handleCloseTooltip = () => {
    if (tooltipRef.current?.isOpen) {
      tooltipRef.current?.close();
    }
  };

  useOnClickOutside(popUpRef, () => {
    if (isMobile) {
      closePopUp();
    }
  });

  useEffect(() => {
    if (popUpRef.current) {
      setPopUpPosition({
        left: window.innerWidth / 2 - popUpRef.current.clientWidth / 2,
        top: window.innerHeight / 2 - popUpRef.current.clientHeight / 2
      });
    }
  }, [isPLTableData, eventId]);

  useEffect(() => {
    if (isClosedEvent) {
      closePopUp();
    }
  }, [isClosedEvent]);

  useEffect(() => {
    // TODO need to use event-updates socket instead of market prices, remove this useEffect and useMarketsPricesVisibleSocketParam unsage once it will be replaced
    ref(popUpRef.current);
  }, []);

  return createPortal(
    <>
      {isMobile && (
        <>
          <div className={styles.popUp__backdrop} />
          {!isIframeEnabled && <BodyClassName className="disableScroll" />}
        </>
      )}
      <div
        className={classNames('biab_modal', styles.popUp__wrapper, asianViewPopUps.POP_UP, branding.PL_TABLE_POP_UP, {
          [styles.popUp__wrapper__mobile]: isMobile
        })}
      >
        <div
          ref={popUpRef}
          className={classNames(styles.popUp, {
            [styles.popUp__expandedTables]: displayedAllScores && !isMobile,
            [styles.popUp__desktop]: !isMobile,
            [styles.popUp__invisible]: !popUpRef.current,
            [styles.popUp__desktop__grabbing]: isMoving,
            [styles.popUp__mobile]: isMobile
          })}
          onMouseDown={isMobile ? undefined : handleMouseDown}
          onMouseUp={isMobile ? undefined : removeMouseMoveHandler}
          onMouseLeave={isMobile ? undefined : removeMouseMoveHandler}
          onDragStart={() => false}
          style={isMobile ? undefined : popUpPosition}
          data-market-id={marketId}
          data-event-id={eventId}
          data-market-prices={true}
          onTouchStart={isMobile ? handleCloseTooltip : undefined}
          onPointerDown={isMobile ? handleCloseTooltip : undefined}
        >
          <div
            className={classNames(styles.popUp__header, asianViewPopUps.POP_UP_HEADER, {
              [styles.popUp__header__mobile]: isMobile
            })}
          >
            {!isFirstLoaded ? (
              <Skeleton className={styles.popUp__skeleton__eventName} />
            ) : (
              <p
                className={classNames(styles.popUp__teamNames, {
                  [styles.popUp__teamNames__mobile]: isMobile
                })}
              >
                {eventName}
              </p>
            )}
            <button
              className={styles.popUp__closeIcon__btn}
              onClick={() => dispatch(closeAsianViewProfitLossTablePopUp())}
            >
              <i
                className={classNames('biab_tour-icon-close', styles.popUp__closeIcon, {
                  [styles.popUp__closeIcon__mobile]: isMobile
                })}
              />
            </button>
          </div>
          <div
            className={classNames(styles.popUp__body, asianViewPopUps.POP_UP_BODY, {
              [styles.popUp__body__mobile]: isMobile
            })}
          >
            <div className={styles.popUp__betsCountWrapper}>
              <p className={styles.popUp__betsCountWrapper__count}>
                {t('asianView.labels.plTable.unsettledBets')}{' '}
                <span className={styles.popUp__medium}>{eventsCount}</span>
              </p>
              <AVProfitLossTablePopUpRefreshBtn />
            </div>
            {isMobile && !isLandscapeView && showSwipeMessage && <AVMobilePLTableSwipeMessage />}
            {!isFirstLoaded ? (
              <Skeleton className={styles.popUp__skeleton__data} />
            ) : isPLTableData && !error ? (
              <>
                {isFullTimeData && (
                  <AVProfitLossTable
                    data={fullTimeData}
                    type="fullTime"
                    displayedAllScores={displayedAllScores}
                    marketId={marketId}
                    onCloseTooltip={handleCloseTooltip}
                  />
                )}
                {isHalfTimeData && (
                  <AVProfitLossTable
                    data={halfTimeData}
                    type="halfTime"
                    displayedAllScores={displayedAllScores}
                    marketId={marketId}
                    onCloseTooltip={handleCloseTooltip}
                  />
                )}
                {!isMobile && (
                  <button
                    className={styles.popUp__toggleShowScore}
                    onClick={() => setDisplayedAllScores(prevState => !prevState)}
                  >
                    <span className={styles.popUp__toggleShowScore__text}>
                      {t(`asianView.labels.plTable.showScores.${displayedAllScores ? 'less' : 'more'}`)}
                    </span>
                    <i
                      className={classNames('av-icon', styles.popUp__toggleShowScore__icon, {
                        'av-icon-arrows-down': !displayedAllScores,
                        'av-icon-arrows-up': displayedAllScores
                      })}
                    />
                  </button>
                )}
              </>
            ) : (
              <div className={classNames(styles.popUp__emptyData, branding.PL_TABLE_NO_DATA)}>
                {t('asianView.labels.plTable.noData')}
              </div>
            )}
            <AVProfitLossTablePopUpMarkets />
          </div>
        </div>
        {isMobile && <div className={styles.popUp__emptySpace} />}
      </div>
      <ReactTooltip
        id="av-pl-table-cell-tooltip"
        place="top"
        className={tooltipStyles.tooltip}
        classNameArrow={tooltipStyles.arrow}
        openOnClick={isMobile}
        delayShow={200}
        ref={tooltipRef}
      />
    </>,
    document.getElementById(BODY_ID)!
  );
};

export default AsianViewProfitLossTablePopUp;
