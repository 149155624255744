import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CombinedResponsibleBettingTimeUnit } from 'redux/modules/responsibleBetting/type';
import { DropdownItem } from 'types';
import {
  ResponsibleBettingMobileDropdownTypeKeys,
  ResponsibleBettingMobileDropdownTypes
} from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingMobileDropdownProps {
  data: DropdownItem[];
  onSelectOption: (option: DropdownItem) => void;
  selectedValue: CombinedResponsibleBettingTimeUnit | null;
  label: string;
  type?: ResponsibleBettingMobileDropdownTypeKeys;
}

const ResponsibleBettingMobileDropdown = ({
  data,
  onSelectOption,
  selectedValue,
  label,
  type = ResponsibleBettingMobileDropdownTypes.Horizontal
}: ResponsibleBettingMobileDropdownProps) => {
  const { t } = useTranslation();
  const isVertical = type === ResponsibleBettingMobileDropdownTypes.Vertical;
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <div
        className={classNames(styles.optionsContainer, {
          [styles.optionsContainer__vertical]: isVertical
        })}
      >
        {data.map(option => (
          <button
            key={option.id}
            onClick={() => onSelectOption(option)}
            className={classNames(styles.option, {
              [styles.option__selected]: selectedValue === option.value,
              [styles.optionsContainer__vertical]: isVertical
            })}
          >
            {t(option.label || '')}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ResponsibleBettingMobileDropdown;
