import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import InfoCircle from 'components/InfoCircle';
import { NET_OF_COMMISSION } from 'constants/tooltip';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useNetOfCommission from 'hooks/useNetOfCommission';
import useTooltip from 'hooks/useTooltip';
import { getAppDevice, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { isSportPage } from 'utils';

import styles from './styles.module.scss';

const NetCommission = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const [, setCookie] = useCookies([CookieNames.BIAB_MARKET_NET_COMMISSION]);

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const device = useSelector(getAppDevice);
  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const isProfitNetOfCommissionChecked = useNetOfCommission();
  const { translationKey, isEnabled, isShowIcon } = useTooltip(NET_OF_COMMISSION);

  const { netOfCommission } = useDeviceSettings();

  const isMobile = device === Devices.MOBILE;

  const isGamesSport = isSportPage(location.pathname);
  const isNetCommissionEnabled = (!isPNCEnabled || isGamesSport) && isLoggedIn && netOfCommission;

  if (!isNetCommissionEnabled) {
    return null;
  }

  const onChangeNetCommission = () => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          netOfCommissionEnabledState: !isProfitNetOfCommissionChecked
        })
      );
    }
    setCookie(CookieNames.BIAB_MARKET_NET_COMMISSION, !isProfitNetOfCommissionChecked, { path: '/' });
  };

  return (
    <div className={styles.netCommission__container}>
      <label
        onClick={onChangeNetCommission}
        data-tooltip-id="tooltip"
        data-tooltip-html={isEnabled && !isMobile ? unescape(t(translationKey)) : ''}
        className={classNames('biab_market-net-of-commission', styles.netCommission, {
          'cursor-help': isEnabled
        })}
      >
        <i
          className={classNames(
            'fa2 biab_checkbox-icon',
            styles.netCommission__icon,
            isProfitNetOfCommissionChecked ? 'fa2-confirmed-yes' : 'fa2-confirmed-no'
          )}
        >
          <span className="path1" />
          <span className="path2 biab_betslip-confirm-bets-checkbox" />
          <span className="path3" />
        </i>
        <span className={styles.netCommission__label}>{t('market.profitNetOfCommission')}</span>
      </label>
      {isShowIcon && isEnabled && (
        <span data-tooltip-id="tooltip" data-tooltip-html={unescape(t(translationKey))}>
          <InfoCircle />
        </span>
      )}
    </div>
  );
};

export default NetCommission;
