import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import { setActiveTab } from 'redux/modules/betslip';
import { getBetslipActiveTab } from 'redux/modules/betslip/selectors';
import { EBetslipTabs } from 'redux/modules/betslip/type';

import styles from './styles.module.scss';

const BetslipTab = ({ tab }: { tab: EBetslipTabs }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const activeTab = useSelector(getBetslipActiveTab);

  const onTabClick = () => {
    if (tab !== activeTab) {
      dispatch(setActiveTab(tab));
    }
  };

  return (
    <li
      className={classNames(styles.tab, branding.BETSLIP_TAB, {
        [styles.tab__active]: activeTab === tab,
        [branding.SELECTED]: activeTab === tab
      })}
      onClick={onTabClick}
    >
      {tab === EBetslipTabs.PLACE_BETS ? t('betslip.labels.placeBets') : t('betslip.labels.openBets')}
    </li>
  );
};

export default BetslipTab;
