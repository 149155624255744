import {
  betSideNo,
  betSideOver,
  betSideUnder,
  betSideYes,
  lineAvgPriceFlatValue,
  lineBettingType,
  MyBetsPage
} from 'constants/myBets';
import { EBetSide, TMyBetsContentItem, TStatementContentItem } from 'redux/modules/myBets/type';
import { OddsType, SportId } from 'types';
import { isLineBettingType } from 'utils/betslip';
import { roundPriceForLineBet } from 'utils/betValidation';
import convertOdds from 'utils/oddsConverter';

export const alternativeBackOddsValue = (bet: TMyBetsContentItem, { isRounded } = { isRounded: true }) => {
  if (bet.side === EBetSide.Back) {
    if (bet.bettingType === lineBettingType) {
      return lineAvgPriceFlatValue;
    }
    if (bet.averagePrice) {
      return isRounded ? bet.averagePriceRounded : bet.averagePrice;
    } else {
      return bet.price || '--';
    }
  }
  return bet.alternativeBackOddsRounded || '--';
};

export const alternativeLayOddsValue = (bet: TMyBetsContentItem, { isRounded } = { isRounded: true }) => {
  if (bet.side === EBetSide.Back) {
    return '--';
  } else {
    if (bet.bettingType === lineBettingType) {
      return lineAvgPriceFlatValue;
    } else {
      if (bet.averagePrice) {
        return isRounded ? bet.averagePriceRounded : bet.averagePrice;
      }
      return bet.price || '--';
    }
  }
};

export const statementBackOddsValue = (item: TStatementContentItem, { isRounded } = { isRounded: true }) => {
  if (item.side === EBetSide.Back) {
    return isRounded ? (item.avgPriceRounded === null ? '--' : +item.avgPriceRounded) : item.avgPrice ?? '--';
  } else {
    return isRounded
      ? item.alternativeBackOddsRounded === null
        ? '--'
        : +item.alternativeBackOddsRounded
      : item.alternativeBackOdds === null
      ? '--'
      : +item.alternativeBackOdds;
  }
};

export const statementLayOddsValue = (item: TStatementContentItem, { isRounded } = { isRounded: true }) => {
  if (item.side === EBetSide.Lay) {
    return isRounded ? (item.avgPriceRounded === null ? '--' : +item.avgPriceRounded) : item.avgPrice ?? '--';
  } else {
    return '--';
  }
};

export const statementOddsValue = (
  item: TStatementContentItem,
  { isRounded, isAsianView }: { isRounded?: boolean; isAsianView?: boolean } = { isRounded: true, isAsianView: false }
) => {
  if (isAsianView) {
    return item.avgPriceRounded ? convertOdds(+(item.avgPriceRounded || 0), item.oddsType ?? OddsType.DEC) : '--';
  } else {
    return isRounded ? (item.avgPriceRounded === null ? '--' : +item.avgPriceRounded) : item.avgPrice ?? '--';
  }
};

export const oddsValue = (bet: TMyBetsContentItem, { isRounded } = { isRounded: true }) => {
  if (bet.bettingType === lineBettingType) {
    return lineAvgPriceFlatValue;
  } else if (bet.averagePrice) {
    return isRounded ? bet.averagePriceRounded : bet.averagePrice;
  } else {
    return bet.price;
  }
};

export const shouldSwapColors = (item: TMyBetsContentItem, swapColorsFancyMarketsOnCricket: boolean) => {
  const isCricket = item.eventTypeId.toString() === SportId.CRICKET;
  return swapColorsFancyMarketsOnCricket && item.bettingType === lineBettingType && (isCricket || item.fancyView);
};

export const getRaceStartTime = (date: number) =>
  `${new Date(date).getHours()}:${
    new Date(date).getMinutes() < 10 ? `0${new Date(date).getMinutes()}` : `${new Date(date).getMinutes()}`
  }`;

export const commission = (chargesCommission: string[]) => {
  if (chargesCommission.length === 0) {
    return '';
  }
  return chargesCommission.length === 1
    ? parseFloat(chargesCommission[0])
    : ` ${parseFloat(chargesCommission[0])}% - ${parseFloat(chargesCommission[1])}%`;
};

export const betsSideValue = (
  item: TMyBetsContentItem,
  lineMarketsSwitchBackLayOnCricket: boolean,
  replaceBackLayWithUnderOver: boolean
) => {
  const isCricket = item?.eventTypeId.toString() === SportId.CRICKET;
  if (item.bettingType === lineBettingType) {
    if (isCricket && lineMarketsSwitchBackLayOnCricket) {
      return `account.mybets.labels.${item.side === EBetSide.Back ? 'no' : 'yes'}`;
    }
    if (!isCricket && replaceBackLayWithUnderOver) {
      return `account.mybets.labels.${item.side === EBetSide.Back ? 'under' : 'over'}`;
    }
  }
  if (item.disabledLayOdds && item.triggeredByCashOut) {
    return 'account.mybets.labels.cashedOut';
  }
  return `account.mybets.labels.${item.side.toLowerCase()}`;
};

export const statementSideValue = (
  item: TStatementContentItem,
  lineMarketsSwitchBackLayOnCricket: boolean,
  replaceBackLayWithUnderOver: boolean
) => {
  const isCricket = item?.eventTypeId?.toString() === SportId.CRICKET;
  if (item.bettingType === lineBettingType) {
    if (isCricket && lineMarketsSwitchBackLayOnCricket) {
      return `account.statement.labels.${item?.side === EBetSide.Back ? betSideNo : betSideYes}`;
    }
    if (!isCricket && replaceBackLayWithUnderOver) {
      return `account.statement.labels.${item?.side === EBetSide.Back ? betSideUnder : betSideOver}`;
    }
  }
  if (item?.side === EBetSide.Lay && item?.disabledLayOdds && item.triggeredByCashOut) {
    return 'account.statement.labels.cashedOut';
  }
  return `account.statement.labels.${item.side.toLowerCase()}`;
};

export const getMultiCurrencyCode = (userCurrencyCode: string, isMultiCurrencySupported: boolean, page: MyBetsPage) => {
  if (isMultiCurrencySupported) {
    switch (page) {
      case MyBetsPage.ExchangeBets:
        return undefined;
      case MyBetsPage.ProfitLoss:
      case MyBetsPage.AccountStatement:
        return userCurrencyCode;
    }
  }

  return undefined;
};

export const getMultiCurrencyCodeFromArg = (
  multiCurrencySupported: boolean,
  userHasMultiCurrency: boolean,
  currentCurrency: string,
  multiCurrencyValue: string | null,
  currencyArg?: string
) => {
  if (currencyArg) {
    return currencyArg;
  }
  if (multiCurrencySupported) {
    if (userHasMultiCurrency) {
      return multiCurrencyValue || undefined;
    }
    return currentCurrency;
  }
  return undefined;
};

export const getIsDateSelected = (date: Date, selectedDate: Date) => {
  return (
    date.getDate() === selectedDate.getDate() &&
    date.getMonth() === selectedDate.getMonth() &&
    date.getFullYear() === selectedDate.getFullYear()
  );
};

export const roundedPriceLineBet = ({
  price,
  averagePriceRounded,
  bettingType,
  eventTypeId,
  interval
}: {
  price: number;
  averagePriceRounded: number;
  bettingType: string;
  eventTypeId: number;
  interval: number;
}) =>
  isLineBettingType(bettingType) && +eventTypeId === +SportId.CRICKET
    ? roundPriceForLineBet({
        value: averagePriceRounded || price,
        interval: interval ?? null
      })
    : averagePriceRounded || price;
