import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { ACCOUNT_BASE_URL } from 'constants/locations';

import styles from './styles.module.scss';

interface UpdatedMobileAccountBackBtnProps {
  classes?: {
    container?: string;
    icon?: string;
  };
}

const UpdatedMobileAccountBackBtn = ({ classes }: UpdatedMobileAccountBackBtnProps) => (
  <Link className={classNames(styles.goBack__button, classes?.container ?? '')} to={ACCOUNT_BASE_URL}>
    <i className={classNames('biab_left-arrow fa2 fa2-arrow-left', styles.goBack__icon, classes?.icon ?? '')} />
  </Link>
);

export default UpdatedMobileAccountBackBtn;
