import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import QuickBetsValue from '../QuickBetsValue';

import styles from '../../styles.module.scss';

interface QuickBetsTableProps {
  activeTab: {
    translationKey: string;
    value: string;
  };
  editMode: { [key: string]: boolean };
  index: number;
  formState: {
    [key: string]: {
      values: (number | string)[];
      errors: {
        translationKey: string;
        options?: {
          N: number;
        };
      }[];
      valuesChanged: boolean;
    };
  };
  handleSetInputValue: (evt: ChangeEvent<HTMLInputElement>) => void;
}

const QuickBetsTable = ({ activeTab, editMode, index, formState, handleSetInputValue }: QuickBetsTableProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.quickBets__table__column}>
      <span className={styles.quickBets__table__column__cell}>
        {t(`account.settings.quickBets.columns.button${index + 1}`)}
      </span>

      <div
        className={classNames(
          {
            [styles.quickBets__table__inputWithError]: formState[activeTab.value].errors[index]?.translationKey,
            'group-input error': formState[activeTab.value].errors[index]?.translationKey
          },
          styles.quickBets__table__inputContainer
        )}
      >
        {editMode[activeTab.value] ? (
          <input
            name={index.toString()}
            value={formState[activeTab.value].values[index]}
            onChange={handleSetInputValue}
            className={styles.quickBets__table__column__cell__input}
            type="text"
          />
        ) : (
          <QuickBetsValue value={formState[activeTab.value].values[index]} />
        )}
        {formState[activeTab.value].errors[index]?.translationKey && (
          <p>
            {formState[activeTab.value].errors[index].options
              ? t(
                  formState[activeTab.value].errors[index].translationKey,
                  '',
                  formState[activeTab.value].errors[index].options
                )
              : t(formState[activeTab.value].errors[index].translationKey)}
          </p>
        )}
      </div>
    </div>
  );
};

export default QuickBetsTable;
