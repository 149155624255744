export const addZero = (value: number) => (value < 10 ? '0' : '');

export const getParsedSessionTime = (startSessionDate: number) => {
  const total = Date.now() - startSessionDate;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / 1000 / 60 / 60) % 24);

  return `${addZero(hours)}${hours}:${addZero(minutes)}${minutes}:${addZero(seconds)}${seconds}`;
};
