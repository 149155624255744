import { memo } from 'react';

import { BETTING_TYPES, GAME } from 'constants/app';
import { GAME_TYPES } from 'constants/games';
import { MARKET_TYPES } from 'constants/marketTypes';
import { BetTypes, THandicap, TPrice, TSize } from 'types/bets';
import { ELineSide, TBetslipMarketRunner } from 'types/betslip';
import { BettingType, TMarketLineRangeInfo } from 'types/markets';

import AHDoubleLineLabel from './components/AHDoubleLineLabel';
import AHSingleLineLabel from './components/AHSingleLineLabel';
import ALtTotalGoalsLabel from './components/AltTotalGoalsLabel';
import EachWayLabel from './components/EachWayLabel';
import LineLabel from './components/LineLabel';
import SpreadsAndTotalsLabel from './components/SpreadsAndTotalsLabel';
import VariableHandicap from './components/VariableHandicapLabel';

type BetLabelsProps = {
  offerId?: number | string;
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetTypes;
  handicapType?: ELineSide | null;
  selectionName?: string;
  eventName?: string;
  eventTypeId: string;
  marketType: string;
  bettingType: string;
  gameType?: string;
  eachWayDivisor: number | null;
  lineRangeInfo: TMarketLineRangeInfo | null;
  runners?: TBetslipMarketRunner[];
  currency?: string;
  placementType?: string;
};

const BetLabels = ({ bettingType, marketType, eachWayDivisor, gameType, placementType, ...props }: BetLabelsProps) => {
  const isAHSingleLine = bettingType === BETTING_TYPES.asianHandicapSingleLine;
  const isAHDoubleLine = bettingType === BETTING_TYPES.asianHandicapDoubleLine;
  const isCombinedTotal = marketType === MARKET_TYPES.combinedTotal;
  const isATG = marketType === MARKET_TYPES.altTotalGoals;
  const isLine = bettingType === BettingType.LINE;
  const isEachWay = !!eachWayDivisor;
  /** Label is used for Games */
  const isVariableHandicap =
    marketType === MARKET_TYPES.variableHandicap && (gameType === GAME_TYPES.HILO || placementType === GAME);

  switch (true) {
    case isVariableHandicap:
      return <VariableHandicap {...props} />;
    case isAHSingleLine:
      return <AHSingleLineLabel {...props} />;
    case isAHDoubleLine && isCombinedTotal:
      return <SpreadsAndTotalsLabel {...props} />;
    case isAHDoubleLine && isATG:
      return <ALtTotalGoalsLabel {...props} />;
    case isAHDoubleLine:
      return <AHDoubleLineLabel {...props} />;
    case isLine:
      return <LineLabel {...props} />;
    case isEachWay:
      return <EachWayLabel {...props} eachWayDivisor={eachWayDivisor} />;
  }

  return null;
};

export default memo(BetLabels);
