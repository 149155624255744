import { Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { AV_PL_TABLE_POP_UP_MARKETS_ONE_COLUMN_SIZE } from 'constants/asianView';
import {
  getAsianViewPLFTMarkets,
  getAsianViewPLHTMarkets,
  getIsLandscapeAsianView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

const AVProfitLossTablePopUpMarkets = () => {
  const { t } = useTranslation();

  const halfTimeMarkets = useSelector(getAsianViewPLHTMarkets);
  const fullTimeMarkets = useSelector(getAsianViewPLFTMarkets);
  const isMobile = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const [isOpened, setIsOpened] = useState(false);

  const markets = [
    { list: fullTimeMarkets, title: t('asianView.labels.column.fullTime') },
    { list: halfTimeMarkets, title: t('asianView.labels.column.firstHalf') }
  ];

  return (
    <>
      <button className={styles.toggleBtn} onClick={() => setIsOpened(prevState => !prevState)}>
        <span className={styles.toggleBtn__text}>{t('asianView.labels.plTable.marketsTitle')}</span>
        <i
          className={classNames('av-icon', styles.toggleBtn__icon, {
            'av-icon-arrow-down': !isOpened,
            'av-icon-arrow-up': isOpened
          })}
        />
      </button>
      {isOpened &&
        markets.map(({ list, title }) => {
          if (!list.length) {
            return null;
          }

          return (
            <Fragment key={title}>
              <p className={styles.marketsTitle}>{title}</p>
              <ul
                className={classNames(styles.marketsList, {
                  [styles.marketsList__2columns]: list.length > AV_PL_TABLE_POP_UP_MARKETS_ONE_COLUMN_SIZE,
                  [styles.marketsList__mobile]: isMobile && !isLandscapeView
                })}
              >
                {list.map(market => {
                  return (
                    <li key={market} className={styles.market}>
                      {market}
                    </li>
                  );
                })}
              </ul>
            </Fragment>
          );
        })}
    </>
  );
};

export default memo(AVProfitLossTablePopUpMarkets);
