import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import MobileDetailsField from 'components/BetsTable/components/MobileBetDetail/components/MobileDetailsField';
import MobileStatementDescription from 'components/BetsTable/components/MobileBetDetail/components/MobileStatementDescription';
import { ExchangeTypes, SEPARATE_WALLET } from 'constants/myBets';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getIsAmericanDateFormatEnabled,
  getMobileSettingsAlternativeLayOdds,
  getWalletIntegrationType
} from 'redux/modules/appConfigs/selectors';
import { hideMobileStatementDetails } from 'redux/modules/myBets';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { BetsType, TStatementContentItem } from 'redux/modules/myBets/type';
import { CookieNames, OddsType } from 'types';
import { statementBackOddsValue, statementLayOddsValue, statementOddsValue } from 'utils/myBetsValues';

import styles from './styles.module.scss';

interface AccountStatementDetailsProps {
  item?: TStatementContentItem;
}

const AccountStatementDetails = ({ item }: AccountStatementDetailsProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE]);

  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);
  const walletIntegrationType = useSelector(getWalletIntegrationType);
  const alternativeLayOdds = useSelector(getMobileSettingsAlternativeLayOdds);
  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.ALL_ACCOUNT_STATEMENT));

  const isAsianViewTable = accountProducts?.asianView && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView;
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { date } = useParseMyBetsDates({
    dateToParse: item!.settledDate,
    yearHyphen: americanDateFormatEnabled
  });
  const { date: placedDate } = useParseMyBetsDates({
    dateToParse: item!.placedDate,
    yearHyphen: americanDateFormatEnabled
  });

  const currencySettings = { noRounding: true, isCheckIndian: true, noSymbol: !isMultiCurrencySupported };

  const { noFormattedAmount: stake } = useFormatCurrency(item?.size || 0, item?.currency, currencySettings);
  const { noFormattedAmount: debit } = useFormatCurrency(item?.debit || 0, item?.currency, currencySettings);
  const { noFormattedAmount: credit } = useFormatCurrency(item?.credit || 0, item?.currency, currencySettings);
  const { noFormattedAmount: balance } = useFormatCurrency(item?.balance || 0, item?.currency, currencySettings);
  const { noFormattedAmount: liability } = useFormatCurrency(item?.liability || 0, item?.currency, currencySettings);

  const oddsTypeLabel = item?.oddsType && t(`asianView.labels.oddsType.short.${item?.oddsType}`);

  useEffect(() => {
    return () => {
      dispatch(hideMobileStatementDetails());
    };
  }, []);

  return (
    <div className="biab_table-holder">
      {!item?.asian && alternativeLayOdds && (
        <div className={classNames(styles.infoMsg, 'biab_info-msg')}>
          {t('account.mybets.messages.alternativeLayOdds')}
        </div>
      )}
      <table>
        <tbody>
          <tr>
            <td className={classNames(styles.betDetail__betIndicator)}>
              <div className={classNames(styles.betDetail, 'biab_statement-detail')}>
                <table>
                  <tbody>
                    <MobileDetailsField name={t('account.statement.labels.settled')} value={date} />
                    <MobileDetailsField name={t('account.statement.labels.id')} value={item?.refId} />
                    <MobileDetailsField
                      name={t('account.statement.labels.placed')}
                      value={item!.placedDate ? placedDate : '--'}
                    />
                    <MobileDetailsField statementDetails isSingleRow>
                      <MobileStatementDescription item={item!} />
                    </MobileDetailsField>
                    {!isAsianViewTable && alternativeLayOdds ? (
                      <>
                        <MobileDetailsField
                          isWithTooltip={!!item?.avgPriceRounded || !!item?.alternativeBackOddsRounded}
                          tooltipValue={statementBackOddsValue(item!, { isRounded: false })}
                          name={t('account.statement.labels.backOdds')}
                          value={statementBackOddsValue(item!)}
                        />
                        <MobileDetailsField
                          isWithTooltip={!!item?.avgPriceRounded}
                          tooltipValue={statementLayOddsValue(item!, { isRounded: false })}
                          name={t(
                            `account.statement.labels.${
                              item?.triggeredByCashOut && item?.disabledLayOdds ? 'cashedOutOdds' : 'layOdds'
                            }`
                          )}
                          value={statementLayOddsValue(item!)}
                        />
                      </>
                    ) : (
                      <MobileDetailsField
                        isWithTooltip={!!item?.avgPriceRounded}
                        tooltipValue={statementOddsValue(item!, { isRounded: false, isAsianView: isAsianViewTable })}
                        name={t('account.statement.labels.odds')}
                        value={statementOddsValue(item!, { isAsianView: isAsianViewTable })}
                        tooltipHTMLValue={
                          isAsianViewTable
                            ? `${item?.avgPriceRounded} ${t(`asianView.labels.oddsType.short.${OddsType.DEC}`)}`
                            : ''
                        }
                      />
                    )}
                    {isAsianViewTable && (
                      <MobileDetailsField name={t('account.statement.labels.oddsType')} value={oddsTypeLabel} />
                    )}
                    <MobileDetailsField name={t('account.statement.labels.stake')} value={item?.size && stake} />
                    {isAsianViewTable && (
                      <MobileDetailsField
                        name={t('account.statement.labels.risk')}
                        value={item?.liability && liability}
                      />
                    )}
                    <MobileDetailsField
                      name={t('account.statement.labels.status')}
                      capitalise
                      value={item?.status ? t(`account.mybets.labels.${item?.status?.toLowerCase()}`) : ''}
                    />
                    <MobileDetailsField name={t('account.statement.labels.debit')} value={debit} />
                    <MobileDetailsField name={t('account.statement.labels.credit')} value={credit} />
                    {walletIntegrationType === SEPARATE_WALLET && (
                      <MobileDetailsField name={t('account.statement.labels.balance')} value={balance} />
                    )}
                  </tbody>
                </table>
                <div className={classNames('biab_account-control-holder', styles.betDetail__controlHolder)}>
                  <button
                    onClick={() => {
                      dispatch(hideMobileStatementDetails());
                    }}
                    type="button"
                    className={classNames(styles.betDetail__controlHolder__goBackBtn, 'biab_account-go-back-bnt')}
                  >
                    {t('account.mybets.labels.goBack')}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AccountStatementDetails;
