import { Link } from 'react-router-dom';

import { ROOT_URL } from 'constants/locations';
import { BannerImage } from 'redux/modules/banners/type';
import { getDefaultLinkTarget } from 'utils/navigation';

import BannerImageComponent from '../BannerImage';

interface BannerComponentProps {
  banner: BannerImage;
  isIframeEnabled: boolean;
}

const BannerComponent = ({ banner, isIframeEnabled }: BannerComponentProps) => {
  if (!banner) {
    return null;
  }

  const bannerLink = !banner.absolutePathEnabled ? banner.link.replace(ROOT_URL, '') : banner.link;

  const target = getDefaultLinkTarget({
    url: bannerLink,
    isIframeEnabled,
    absolutePath: banner.absolutePathEnabled,
    currentTarget: banner.openInNewTab ? '_blank' : '_self',
    openInNewTab: banner.openInNewTab
  });

  return (
    <li>
      {banner.absolutePathEnabled ? (
        <a href={bannerLink} target={target}>
          <BannerImageComponent banner={banner} />
        </a>
      ) : (
        <Link to={bannerLink} target={target}>
          <BannerImageComponent banner={banner} />
        </Link>
      )}
    </li>
  );
};

export default BannerComponent;
