import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ReactComponent as Reload } from 'assets/icons/reload.svg';
import {
  PROFIT_LOSS_TABLE_POP_UP_COUNT_DOWN_INTERVAL,
  PROFIT_LOSS_TABLE_POP_UP_FETCH_DATA_INTERVAL
} from 'constants/asianView';
import { fetchAsianViewProfitLossTableData } from 'redux/modules/asianView';
import {
  getAVProfitLossTableDataLoading,
  getAVProfitLossTablePopUpEventId,
  getAVProfitLossTablePopUpMarketId,
  getIsAVProfitLossTableDataFirstLoaded,
  getIsLandscapeAsianView,
  getIsMobileAsianView
} from 'redux/modules/asianView/selectors';

import styles from './styles.module.scss';

const AVProfitLossTablePopUpRefreshBtn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const eventId = useSelector(getAVProfitLossTablePopUpEventId);
  const loading = useSelector(getAVProfitLossTableDataLoading);
  const isFirstLoaded = useSelector(getIsAVProfitLossTableDataFirstLoaded);
  const marketId = useSelector(getAVProfitLossTablePopUpMarketId);
  const isMobile = useSelector(getIsMobileAsianView);
  const isLandscapeView = useSelector(getIsLandscapeAsianView);

  const [refreshCountDown, setRefreshCountDown] = useState(15);

  const refetchTableDataInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const reduceCountDownInterval = useRef<ReturnType<typeof setInterval> | null>(null);

  const clearIntervals = () => {
    setRefreshCountDown(15);

    if (refetchTableDataInterval.current) {
      clearInterval(refetchTableDataInterval.current);
      refetchTableDataInterval.current = null;
    }

    if (reduceCountDownInterval.current) {
      clearInterval(reduceCountDownInterval.current);
      reduceCountDownInterval.current = null;
    }
  };

  const createIntervals = () => {
    refetchTableDataInterval.current = setInterval(() => {
      if (eventId && marketId) {
        dispatch(fetchAsianViewProfitLossTableData({ eventId, marketId }));
      }
      setRefreshCountDown(15);
    }, PROFIT_LOSS_TABLE_POP_UP_FETCH_DATA_INTERVAL);

    reduceCountDownInterval.current = setInterval(() => {
      setRefreshCountDown(prevState => prevState - 1);
    }, PROFIT_LOSS_TABLE_POP_UP_COUNT_DOWN_INTERVAL);
  };

  const handleRefresh = () => {
    if (eventId && marketId && !loading) {
      dispatch(fetchAsianViewProfitLossTableData({ eventId, marketId }));
    }
  };

  useEffect(() => {
    return () => {
      clearIntervals();
    };
  }, []);

  useEffect(() => {
    if (isFirstLoaded) {
      if (loading) {
        clearIntervals();
      } else {
        createIntervals();
      }
    }
  }, [loading, isFirstLoaded]);

  return (
    <button
      onClick={handleRefresh}
      className={classNames(styles.refresh, {
        [styles.refresh__mobile]: isMobile,
        [styles.refresh__noFirstLoaded]: !isFirstLoaded
      })}
      disabled={loading}
    >
      <span
        className={classNames(styles.refresh__text, {
          [styles.refresh__text__mobile]: isMobile,
          [styles.refresh__noFirstLoaded__label]: !isFirstLoaded
        })}
      >
        {(!isMobile || isLandscapeView) && t('asianView.labels.plTable.refresh')} ({refreshCountDown})
      </span>
      <Reload
        className={classNames(styles.refresh__icon, {
          [styles.refresh__icon__animation]: loading,
          [styles.refresh__icon__mobile]: isMobile,
          [styles.refresh__noFirstLoaded__icon]: !isFirstLoaded
        })}
      />
    </button>
  );
};

export default memo(AVProfitLossTablePopUpRefreshBtn);
