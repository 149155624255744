import { ResponsibleBettingTimeUnits } from 'types/responsibleBetting';

export const TODAY_BUTTON_DETECTION_CLASS_NAME = 'today-button';

export const TIME_TO_SHOW_DAY_LIMIT_REMINDER_IN_MINUTES = 10;
export const CHECK_LIMIT_INTERVAL = 60000;

export const RESPONSIBLE_BETTING_TIME_UNITS = {
  [ResponsibleBettingTimeUnits.DAY]: 'responsibleBetting.labels.perDay',
  [ResponsibleBettingTimeUnits.WEEK]: 'responsibleBetting.labels.perWeek',
  [ResponsibleBettingTimeUnits.MONTH]: 'responsibleBetting.labels.perMonth'
};

export const RESPONSIBLE_BETTING_TIME_UNIT_DAYS_AMOUNT = {
  [ResponsibleBettingTimeUnits.DAY]: 1,
  [ResponsibleBettingTimeUnits.WEEK]: 7,
  [ResponsibleBettingTimeUnits.MONTH]: 30
};

export const RESPONSIBLE_BETTING_DEFAULT_SCALE_WIDTH = 100;
export const RESPONSIBLE_BETTING_DEFAULT_SCALE_GAP = 1;

export const updatedResponsibleBettingTimeUnits = [
  { value: ResponsibleBettingTimeUnits.DAY, label: 'responsibleBetting.labels.perDay' },
  { value: ResponsibleBettingTimeUnits.WEEK, label: 'responsibleBetting.labels.perWeek' },
  { value: ResponsibleBettingTimeUnits.MONTH, label: 'responsibleBetting.labels.perMonth' }
];

export const RESPONSIBLE_BETTING_SETTINGS_REQUEST_INTERVAL = 60000;
