import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { removeAllSelectedBets } from 'redux/modules/betslip';
import { getCurrentGameMarketStatus } from 'redux/modules/games/selectors';
import { EMarketStatus } from 'redux/modules/games/type';
import { cleanInlineSelectedBets } from 'redux/modules/inlinePlacement';

import styles from '../../styles.module.scss';

const MarketStatusOverlay = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const status = useSelector(getCurrentGameMarketStatus);
  const statusInLowerCase = status?.toLowerCase();

  useEffect(() => {
    if (
      status === EMarketStatus.SUSPENDED_GAME_PLAYING ||
      status === EMarketStatus.SUSPENDED_GAME_ROUND_OVER ||
      status === EMarketStatus.SUSPENDED_GAME_SETTLING
    ) {
      dispatch(removeAllSelectedBets());
      dispatch(cleanInlineSelectedBets());
    }
  }, [status]);

  return <div className={styles.marketStatusOverlay}>{t(`market.status.${statusInLowerCase}`)}</div>;
};

export default MarketStatusOverlay;
