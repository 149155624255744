import { toNumber } from 'lodash';

import useBetLabels from 'hooks/useBetLabels';
import { BetTypes, THandicap, TPrice, TSize } from 'types/bets';
import { TBetslipMarketRunner } from 'types/betslip';
import { getAHDoubleLineProfitLoss, getTeamsNamesFromEventName, getTeamsNamesFromRunners } from 'utils/betLabels';

import BetLabelsTpl from '../BetLabelsTpl';

type AHDoubleLineLabelProps = {
  price: TPrice;
  size: TSize;
  handicap?: THandicap | null;
  betType: BetTypes;
  eventTypeId: string;
  selectionName?: string;
  eventName?: string;
  runners?: TBetslipMarketRunner[];
  currency?: string;
};

const AHDoubleLineLabel = ({
  price,
  size,
  handicap,
  betType,
  runners = [],
  selectionName = '',
  eventName = '',
  currency
}: AHDoubleLineLabelProps) => {
  const { getAHDoubleLineLabels } = useBetLabels();

  const trim = (str: string) => str.replace(/\s/g, '');

  const runnersNames: string[] = eventName ? getTeamsNamesFromEventName(eventName) : getTeamsNamesFromRunners(runners);

  const team1 = runnersNames.find(runner => trim(selectionName).indexOf(trim(runner)) !== -1) ?? '';
  const team2 = runnersNames.find(runner => trim(selectionName).indexOf(trim(runner)) === -1) ?? '';

  const handicapValue = toNumber(handicap || 0);

  const { labelW, labelS, labelL } = getAHDoubleLineLabels(handicapValue, team1, team2);
  const { profitLossW, profitLossS, profitLossL } = getAHDoubleLineProfitLoss({
    price,
    size,
    betType,
    handicap
  });

  return (
    <BetLabelsTpl
      labelW={labelW}
      labelS={labelS}
      labelL={labelL}
      profitLossW={profitLossW}
      profitLossS={profitLossS}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default AHDoubleLineLabel;
