import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Dropdown from 'components/Dropdown';
import { ASIAN_VIEW_PAGE_PARAM, AsianViewTimeFrame } from 'constants/asianView';
import { asianViewDropdown } from 'constants/branding';
import {
  getIsDesktopInfiniteScrollEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { closeAsianViewProfitLossTablePopUp, setDaysValue } from 'redux/modules/asianView';
import { getAsianViewDaysValue } from 'redux/modules/asianView/selectors';
import { DropdownItem } from 'types';
import { addDays, applyTimezone } from 'utils/date';

import filterCommonStyles from '../filter.module.scss';
import styles from './styles.module.scss';

const getDayLabel = (day: Date, t: TFunction) => {
  const month = t(`dates.month.${day.getMonth()}`);
  const weekDay = t(`dates.shortDay.${day.getDay()}`);

  return `${day.getDate()} ${month} (${weekDay})`;
};

const createDayOptions = (date: Date, t: TFunction): DropdownItem[] => {
  const day1 = addDays(date, 1);
  const day2 = addDays(date, 2);
  const day3 = addDays(date, 3);
  const day4 = addDays(date, 4);
  const day5 = addDays(date, 5);
  const day6 = addDays(date, 6);
  const day7 = addDays(date, 7);

  return [
    { id: 'all', value: AsianViewTimeFrame.ALL, label: t('asianView.labels.timeFilter.allDates') },
    { id: 'day1', value: AsianViewTimeFrame.DAY_1, label: getDayLabel(day1, t) },
    { id: 'day2', value: AsianViewTimeFrame.DAY_2, label: getDayLabel(day2, t) },
    { id: 'day3', value: AsianViewTimeFrame.DAY_3, label: getDayLabel(day3, t) },
    { id: 'day4', value: AsianViewTimeFrame.DAY_4, label: getDayLabel(day4, t) },
    { id: 'day5', value: AsianViewTimeFrame.DAY_5, label: getDayLabel(day5, t) },
    { id: 'day6', value: AsianViewTimeFrame.DAY_6, label: getDayLabel(day6, t) },
    { id: 'day7', value: AsianViewTimeFrame.DAY_7, label: getDayLabel(day7, t) }
  ];
};

const DaysFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const daysValue = useSelector(getAsianViewDaysValue);
  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);

  const date = applyTimezone(new Date(), timezone, timezoneCookieEnabled);

  const daysOptions = createDayOptions(date, t);

  const defaultValue = daysOptions.find(({ value }) => value === daysValue);

  const handleSelectDaysValue = ({ value }: DropdownItem) => {
    if (!isInfiniteScrollEnabled) {
      const page = searchParams.get(ASIAN_VIEW_PAGE_PARAM);

      if (page !== '0') {
        searchParams.set(ASIAN_VIEW_PAGE_PARAM, '0');
        setSearchParams(searchParams);
      }
    }

    dispatch(setDaysValue(value as AsianViewTimeFrame));
    dispatch(closeAsianViewProfitLossTablePopUp());
  };

  return (
    <Dropdown
      classes={{
        ...filterCommonStyles,
        placeholder: classNames(filterCommonStyles.placeholder, styles.placeholder, 'biab_days-filter'),
        container: 'biab_days-filter-wrapper'
      }}
      brandingClasses={asianViewDropdown}
      data={daysOptions}
      onSelectOption={handleSelectDaysValue}
      defaultValue={defaultValue}
      icon={() => <i className="fa2 fa2-calendar" />}
      iconPosition="left"
    />
  );
};

export default DaysFilter;
