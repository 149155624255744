import { KeyboardEvent, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { KEY_CODES } from 'constants/betslip';
import { inlinePlacementBranding as branding } from 'constants/branding';
import { LINE_MARKET_ODDS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import usePostMessage from 'hooks/usePostMessage';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { BetTypes, TPrice, TSize } from 'types/bets';
import { ButtonActionTypes, ESizes, Statuses, TInlinePlacement } from 'types/inlinePlacement';
import { calculateLiability } from 'utils/liability';

import styles from './styles.module.scss';

interface IInlinePlacementButton {
  status?: Statuses;
  buttonType: ButtonActionTypes;
  price?: TPrice;
  size?: TSize;
  betType?: BetTypes;
  marketType?: string;
  bettingType?: string;
  eachWayDivisor?: number | null;
  isPlacedBet?: boolean;
  isLineMarket?: boolean;
  isPNCEnabled?: boolean;
  isTakeOffer?: boolean;
  isPlacementInvalid?: boolean;
  componentSize?: ESizes;
  isFocused?: boolean;
  onTabNavigate?: (type: ButtonActionTypes) => void;
  onClickCallback: ({ price, size, placedUsingEnterKey }: TInlinePlacement) => void;
}

const InlinePlacementButton = ({
  status,
  buttonType,
  price,
  size,
  betType,
  marketType,
  bettingType,
  eachWayDivisor,
  isPlacedBet,
  isLineMarket,
  isPNCEnabled,
  isTakeOffer,
  isPlacementInvalid,
  isFocused,
  componentSize,
  onClickCallback,
  onTabNavigate = () => {}
}: IInlinePlacementButton) => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const { placeBetLogin } = usePostMessage();

  const liability = calculateLiability(isLineMarket ? LINE_MARKET_ODDS : price, size, {
    marketType,
    bettingType,
    eachWayDivisor,
    betType
  });

  let buttonText: string;

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (status === Statuses.CONFIRM && buttonRef.current !== null) {
      buttonRef.current.focus();
    }
  }, [status]);

  useEffect(() => {
    if (isFocused && buttonRef.current !== null) {
      buttonRef.current.focus();
    }
  }, [isFocused]);

  switch (buttonType) {
    case ButtonActionTypes.PLACE:
      buttonText = isPlacedBet
        ? t('inlinePlacement.labels.updateBet')
        : isPNCEnabled && isTakeOffer
        ? isLineMarket
          ? t('betslip.actions.placeBetAtAvailableUnits')
          : t('betslip.actions.placeBetAtAvailableOdds')
        : t('inlinePlacement.labels.placeBet');
      break;
    case ButtonActionTypes.EDIT:
      buttonText = t('inlinePlacement.labels.editBet');
      break;
    case ButtonActionTypes.CONFIRM:
      buttonText = t('inlinePlacement.labels.confirmBet');
      break;
    case ButtonActionTypes.CANCEL:
      buttonText = t('inlinePlacement.labels.cancel');
      break;
    case ButtonActionTypes.CANCEL_UNMATCHED:
      buttonText = t('inlinePlacement.labels.cancelBet');
      break;
    default:
      buttonText = '';
      break;
  }

  const { noFormattedAmount: formattedLiability } = useFormatCurrency(liability || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  const placementHandler = (placedUsingEnterKey = false) => {
    if (!isLoggedIn && buttonType === ButtonActionTypes.PLACE) {
      placeBetLogin();
    } else {
      onClickCallback({ price, size, placedUsingEnterKey });
    }
  };

  const onClickBtnHandler = () => {
    placementHandler();
  };

  const onKeyDownBtnHandler = (e: KeyboardEvent) => {
    e.preventDefault();

    if (e.key === KEY_CODES.TAB) {
      onTabNavigate(buttonType);
    } else if (e.key === KEY_CODES.ENTER) {
      placementHandler(true);
    }
  };

  return (
    <div>
      <button
        type="button"
        ref={buttonRef}
        className={classNames(
          `biab_btn-${buttonType.toLowerCase()}`,
          styles.placementButton,
          styles[`placementButton__${buttonType.toLowerCase()}`],
          branding.PLACEMENT_BUTTON,
          {
            [styles.placementButton__xs]: componentSize === ESizes.SMALL,
            [styles.placementButton__md]: componentSize === ESizes.MEDIUM,
            [branding.PLACE_BTN]: buttonType === ButtonActionTypes.PLACE,
            [branding.CANCEL_BTN]: buttonType === ButtonActionTypes.CANCEL,
            [branding.CONFIRM_BTN]: buttonType === ButtonActionTypes.CONFIRM,
            [branding.DISABLED_BTN]:
              (buttonType === ButtonActionTypes.PLACE || buttonType === ButtonActionTypes.CONFIRM) &&
              isPlacementInvalid,
            [branding.EDIT_BTN]:
              buttonType === ButtonActionTypes.EDIT || buttonType === ButtonActionTypes.CANCEL_UNMATCHED
          }
        )}
        onClick={onClickBtnHandler}
        onKeyDown={onKeyDownBtnHandler}
        disabled={buttonType === ButtonActionTypes.PLACE && isPlacementInvalid}
      >
        {buttonText}
        {(buttonType === ButtonActionTypes.PLACE || buttonType === ButtonActionTypes.CONFIRM) && (
          <span
            className={classNames(styles.placementButton__profit, {
              biab_hide: !liability
            })}
          >
            {t(`inlinePlacement.labels.${betType === BetTypes.BACK ? 'profit' : 'liability'}`)} {formattedLiability}
          </span>
        )}
      </button>
    </div>
  );
};

export default InlinePlacementButton;
