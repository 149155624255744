import { memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import {
  BACK_ODDS,
  BACK_ODDS_MATCHED,
  BACK_PROFIT,
  BACK_SIZE,
  LAY_ODDS,
  LAY_ODDS_MATCHED,
  LAY_PROFIT,
  LAY_SIZE,
  LAY_SIZE_MATCHED
} from 'constants/tooltip';
import useTooltip from 'hooks/useTooltip';
import { BetTypes, MatchTypes } from 'types/bets';
import { EBetTooltips } from 'types/betslip';

import styles from './styles.module.scss';

const BetTooltip = ({
  matchType,
  side,
  tooltipType,
  children
}: {
  side: BetTypes;
  matchType?: MatchTypes;
  tooltipType: EBetTooltips;
  children?: ReactNode;
}) => {
  const { t } = useTranslation();

  let key = '';
  const isBack = side === BetTypes.BACK;
  const isMatched = matchType === MatchTypes.MATCHED;

  if (tooltipType === EBetTooltips.ODDS) {
    if (isMatched) {
      key = isBack ? BACK_ODDS_MATCHED : LAY_ODDS_MATCHED;
    } else {
      key = isBack ? BACK_ODDS : LAY_ODDS;
    }
  } else if (tooltipType === EBetTooltips.SIZE) {
    if (isMatched) {
      key = isBack ? BACK_SIZE : LAY_SIZE_MATCHED;
    } else {
      key = isBack ? BACK_SIZE : LAY_SIZE;
    }
  } else if (tooltipType === EBetTooltips.PROFIT) {
    key = isBack ? BACK_PROFIT : LAY_PROFIT;
  }

  const { translationKey, isEnabled, isShowIcon } = useTooltip(key);

  return (
    <>
      {isEnabled ? (
        <span
          data-tooltip-id={'tooltip'}
          data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
          className={classNames(styles.tooltip, {
            'cursor-help': isEnabled
          })}
        >
          {children}
          {isShowIcon && (
            <i className={'fa2 fa2-info-circle tooltip-icon'}>
              <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
              <span className="path2" />
            </i>
          )}
        </span>
      ) : (
        children
      )}
    </>
  );
};

export default memo(BetTooltip);
