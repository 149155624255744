import { useTranslation } from 'react-i18next';

import { COMBINED_DEFAULT_UNIT } from 'constants/marketTypes';
import { IMarketDescription } from 'types/markets';
import { getMarketTypes, getMarketUnitTranslation, roundRangeParams } from 'utils/market';

const useLineMarket = (marketDescription: IMarketDescription) => {
  const {
    lineRangeInfo: { minUnitValue, maxUnitValue, interval, marketUnit }
  } = marketDescription;

  const { t } = useTranslation();

  const { isCombinedTotal } = getMarketTypes(marketDescription.marketType, marketDescription.bettingType);
  const marketUnitDefault = isCombinedTotal && !marketUnit ? COMBINED_DEFAULT_UNIT : marketUnit;
  const unitsTranslation = t(getMarketUnitTranslation(marketUnitDefault)).toLowerCase();

  const rangeInfo = {
    min: roundRangeParams(minUnitValue, interval),
    max: roundRangeParams(maxUnitValue, interval),
    step: interval,
    unit: marketUnitDefault
  };

  const lineRangeInfo = t('market.rangeInfo', {
    min: rangeInfo.min,
    max: rangeInfo.max,
    UNITS: unitsTranslation
  });

  const lineRangeTooltip = t('tooltip.market.rangeInfo', {
    runs: unitsTranslation
  });

  return { lineRangeInfo, lineRangeTooltip };
};

export default useLineMarket;
