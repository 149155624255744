import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { betslipBranding as branding } from 'constants/branding';
import { GAMES_BASE_URL } from 'constants/locations';
import useInlinePlacement from 'hooks/useInlinePlacement';
import { usePreviousValue } from 'hooks/usePrevious';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  removeAllSelectedBets,
  setActiveTab,
  setBetslipLoading,
  setBetslipType,
  setKeepSelectedBets,
  setPlacedBets,
  setResetBetslipTab,
  setSelectedBets
} from 'redux/modules/betslip';
import {
  getBetslipActiveTab,
  getBetslipCollapse,
  getBetslipKeepSelectedBets,
  getBetslipResetBetslipTab,
  getBetslipType,
  getSelectedBetsAmount
} from 'redux/modules/betslip/selectors';
import { EBetslipTabs, EKeepSelectedBets } from 'redux/modules/betslip/type';
import { getCashOutPlacedIds } from 'redux/modules/cashOut/selectors';
import { getCurrentBetsAmount } from 'redux/modules/currentBets/selectors';
import { cleanInlineSelectedBets } from 'redux/modules/inlinePlacement';
import { getAllInlineSelectedBets } from 'redux/modules/inlinePlacement/selectors';
import { EBetslipTypes } from 'types/betslip';

import BetslipHeader from './components/BetslipHeader';
import BetslipTab from './components/BetslipTab';
import OpenedBetsTab from './components/OpenedBetsTab';
import PlaceBetsTab from './components/PlaceBetsTab';

import styles from './styles.module.scss';

const contentComponents = {
  [EBetslipTabs.PLACE_BETS]: { component: <PlaceBetsTab /> },
  [EBetslipTabs.OPEN_BETS]: { component: <OpenedBetsTab /> }
};

const Betslip = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const activeTab = useSelector(getBetslipActiveTab);
  const collapse = useSelector(getBetslipCollapse);
  const keepSelectedBets = useSelector(getBetslipKeepSelectedBets);
  const resetBetslipTab = useSelector(getBetslipResetBetslipTab);
  const currentBetsAmount = useSelector(getCurrentBetsAmount({ isGameType: isGameBetslip }));
  const selectedBetsAmount = useSelector(getSelectedBetsAmount);
  const cashOutPlacedIds = useSelector(getCashOutPlacedIds);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const inlineSelectedBets = useSelector(getAllInlineSelectedBets);

  const isInlinePlacement = useInlinePlacement();

  useEffect(() => {
    if (isLoggedIn && !selectedBetsAmount && !inlineSelectedBets.length) {
      dispatch(setResetBetslipTab());
    }

    dispatch(
      setBetslipType(location.pathname.startsWith(`${GAMES_BASE_URL}`) ? EBetslipTypes.GAME : EBetslipTypes.EXCHANGE)
    );
    return () => {
      dispatch(setBetslipType(EBetslipTypes.EXCHANGE));
    };
  }, [isLoggedIn, location.pathname, selectedBetsAmount, inlineSelectedBets.length]);

  useEffect(() => {
    if (isLoggedIn && !isInlinePlacement && inlineSelectedBets.length) {
      /** Move first bet from the inline selected bets to Betslip */
      dispatch(setSelectedBets([inlineSelectedBets[0]]));
      dispatch(cleanInlineSelectedBets());
      dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));
    }
  }, [isInlinePlacement, inlineSelectedBets, isLoggedIn]);

  useEffect(() => {
    /** Select Opened bets tab if there are current bets and route is changed */
    if (resetBetslipTab && isLoggedIn && currentBetsAmount && activeTab === EBetslipTabs.PLACE_BETS) {
      dispatch(setActiveTab(EBetslipTabs.OPEN_BETS));
    }
  }, [activeTab, currentBetsAmount, resetBetslipTab]);

  useEffect(() => {
    /** Select Opened bets tab if there are current bets and Selected bets are cancelled */
    if (isLoggedIn && currentBetsAmount && !selectedBetsAmount) {
      dispatch(setActiveTab(EBetslipTabs.OPEN_BETS));
    }
  }, [isLoggedIn, currentBetsAmount, selectedBetsAmount]);

  useEffect(() => {
    if (cashOutPlacedIds.length) {
      dispatch(setPlacedBets(cashOutPlacedIds));
    }
  }, [cashOutPlacedIds]);

  const prevLocationPathName = usePreviousValue(location.pathname);

  useEffect(() => {
    if (keepSelectedBets === EKeepSelectedBets.KEEP) {
      dispatch(setKeepSelectedBets(EKeepSelectedBets.REMOVE));
    } else if (keepSelectedBets === EKeepSelectedBets.REMOVE && prevLocationPathName !== location.pathname) {
      dispatch(removeAllSelectedBets());
    }

    dispatch(setBetslipLoading(false));
  }, [keepSelectedBets, location.pathname, prevLocationPathName]);

  useEffect(() => {
    return () => {
      dispatch(removeAllSelectedBets());
    };
  }, []);

  return (
    <div className={styles.betslipWrap}>
      <BetslipHeader isGameBetslip={isGameBetslip} />
      {!collapse && (
        <div className={branding.BETSLIP_WRAP}>
          <ul className={styles.tabs}>
            {[EBetslipTabs.PLACE_BETS, EBetslipTabs.OPEN_BETS].map(tab => (
              <BetslipTab key={tab} tab={tab} />
            ))}
          </ul>
          {contentComponents[activeTab].component}
        </div>
      )}
    </div>
  );
};

export default Betslip;
