import { useSelector } from 'react-redux';

import BetsIndicator from 'components/SearchContent/components/BetsIndicator';
import { getCurrentSelectionBetsByType } from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';

interface BetsIndicatorSearchPageProps {
  eventTypeId: string;
  marketId: string;
  selectionId: number;
  handicap: number;
}

const BetsIndicatorSearchPage = ({ eventTypeId, marketId, selectionId, handicap }: BetsIndicatorSearchPageProps) => {
  const unmatchedBets = useSelector(
    getCurrentSelectionBetsByType(marketId, selectionId, handicap, MatchTypes.UNMATCHED)
  );
  const matchedBets = useSelector(getCurrentSelectionBetsByType(marketId, selectionId, handicap, MatchTypes.MATCHED));

  return (
    <BetsIndicator
      matchedBets={matchedBets}
      unmatchedBets={unmatchedBets}
      eventTypeId={eventTypeId}
      marketId={marketId}
    />
  );
};

export default BetsIndicatorSearchPage;
