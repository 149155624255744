import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useIntersectionObserver } from 'usehooks-ts';

import AsianViewFavouriteStar from 'components/AsianViewFavouriteStar';
import AsianViewInPlayCell from 'components/AsianViewInPlayCell';
import AsianViewInPlayTime from 'components/AsianViewInPlayTime';
import AsianViewMatchStatistics from 'components/AsianViewMatchStatistics';
import CorrectScoreMarket from 'components/AsianViewPageModule/components/CorrectScoreMarket';
import AsianViewProfitLossButton from 'components/AsianViewProfitLossButton';
import CashOut from 'components/CashOut';
import VideoStream from 'components/VideoStream';
import { FAVORITES_TYPES } from 'constants/app';
import { AsianViewSection } from 'constants/asianView';
import { asianViewMiddleSection as branding } from 'constants/branding';
import useCustomResizeObserver from 'hooks/useCustomResizeObserver';
import { getTranslation } from 'redux/modules/appConfigs/selectors';
import { getAsianViewEventIndex } from 'redux/modules/asianView/selectors';
import { TAsianViewEvent } from 'redux/modules/asianView/type';
import { getAsianViewCashOutCounterByEventId } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getClosedMarketsIds, getIsMarketInPlayByMarketPricesId } from 'redux/modules/marketsPrices/selectors';
import { SportId } from 'types';
import { sortCorrectScoreMarkets } from 'utils/asianView';

import styles from './styles.module.scss';

interface CorrectScoreEventProps {
  event: TAsianViewEvent;
  competitionId: string;
  isFirst: boolean;
}

const CorrectScoreEvent = ({ event, competitionId, isFirst }: CorrectScoreEventProps) => {
  const { sportId = SportId.SOCCER } = useParams();

  const translation = useSelector(getTranslation);
  const closedMarketIds = useSelector(getClosedMarketsIds);
  const cashOutCounter = useSelector(getAsianViewCashOutCounterByEventId(sportId, event.id));
  const eventIndex = useSelector(getAsianViewEventIndex(event.id));

  const market = event.sections[AsianViewSection.TOP]?.[0];
  const markets = (event.sections[AsianViewSection.TOP] || []).filter(item => !closedMarketIds.includes(item.id));

  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(market?.id ?? ''));

  const showCashOut = useMemo(() => {
    return event.sections.top?.some(({ cashOutEnabled }) => cashOutEnabled);
  }, [event.sections.top]);

  const containerRef = useRef<HTMLDivElement>(null);

  const { ref, isIntersecting } = useIntersectionObserver({ rootMargin: '400px' });
  const { height } = useCustomResizeObserver({ ref: containerRef });

  const eventName = event.translations[translation];

  useEffect(() => {
    ref(containerRef.current);
  }, []);

  return (
    <div ref={containerRef} className="biab_asian-view-event">
      {!isIntersecting && <div style={{ height }} />}
      {!!market && isIntersecting && (
        <>
          <div
            className={classNames(styles.eventHeader, branding.MAIN_CONTAINER_BORDER, {
              [branding.IN_PLAY_EVENT_1]: isInPlay && eventIndex % 2 === 0,
              [branding.IN_PLAY_EVENT_2]: isInPlay && eventIndex % 2 !== 0,
              [branding.NON_PLAY_EVENT_1]: !isInPlay && eventIndex % 2 === 0,
              [branding.NON_PLAY_EVENT_2]: !isInPlay && eventIndex % 2 !== 0
            })}
          >
            <AsianViewInPlayCell
              marketId={market.id}
              marketStartTime={market.startTime}
              hideTime
              containerClassName={isInPlay ? styles.eventHeader__inPlayCell : undefined}
            />
            <AsianViewInPlayTime marketId={market.id} />
            <AsianViewFavouriteStar
              favourite={{
                entryName: event.translations[translation],
                entryId: event.id,
                entryType: FAVORITES_TYPES.event,
                sportId
              }}
            />
            <div className={styles.eventHeader__name} data-tooltip-id={'tooltip'} data-tooltip-html={eventName}>
              {eventName}
            </div>
            <div className={styles.eventHeader__statistics}>
              <AsianViewProfitLossButton
                eventId={event.id}
                className={classNames({ [styles.eventHeader__statistics__leftBlockMargin]: !showCashOut })}
                marketId={market.id}
              />
              {showCashOut && (
                <CashOut
                  showLabel={false}
                  showInfoIcon={false}
                  className={{
                    container: classNames(styles.eventHeader__cashout, styles.eventHeader__statistics__leftBlockMargin),
                    icon: styles.eventHeader__cashoutIcon
                  }}
                  disabled={!cashOutCounter}
                  isAvPLTableAvailable
                />
              )}
              {event.videoStreamingEnabled && (
                <VideoStream hideLabel visible={event.videoStreamingEnabled} isInHeader eventId={event.id} />
              )}
              {event.matchStatEnabled && (
                <AsianViewMatchStatistics matchStatEnabled={event.matchStatEnabled} eventId={event.id} />
              )}
            </div>
          </div>
          {sortCorrectScoreMarkets(markets).map((marketItem, index) => (
            <CorrectScoreMarket
              market={marketItem}
              competitionId={competitionId}
              eventId={event.id}
              showBackLayLabels={isFirst && index === 0}
              key={`${marketItem.id}_${index}`}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CorrectScoreEvent;
