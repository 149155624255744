import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { setErrorMessage } from 'redux/modules/betslip';
import { getBetslipErrorMessage } from 'redux/modules/betslip/selectors';

import styles from './styles.module.scss';

const BetslipError = () => {
  const errorMessage = useSelector(getBetslipErrorMessage);

  return errorMessage ? <BetSlipErrorContent errorMessage={errorMessage} /> : null;
};

export default BetslipError;

function BetSlipErrorContent({ errorMessage }: { errorMessage: string }) {
  const dispatch = useDispatch();

  const errorMessageRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(errorMessageRef, () => {
    if (errorMessage) {
      dispatch(setErrorMessage(''));
    }
  });

  return (
    <div
      ref={errorMessageRef}
      className={classNames(styles.betslipError, branding.ERROR_MSG)}
      dangerouslySetInnerHTML={{ __html: errorMessage }}
    />
  );
}
