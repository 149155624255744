import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import HeaderNavigation from 'components/HeaderNavigation';
import AccountPagesSubHeader from 'components/HeaderNavigation/components/AccountPagesSubheader';
import SessionTime from 'components/SessionTime';
import { RESPONSIBLE_BETTING_BASE_URL } from 'constants/locations';
import {
  getDesktopHeaderHidden,
  getIsAsianViewEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsHeadsUpOverHeader,
  getIsIframeEnabled,
  getIsPassiveSessionEnabled
} from 'redux/modules/appConfigs/selectors';
import { getUserSessionInfo } from 'redux/modules/user/selectors';

import HeaderButtons from './components/HeaderButtons';
import HeaderResize from './components/HeaderResize';
import HeaderTop from './components/HeaderTop';

import styles from './styles.module.scss';

interface HeaderProps {
  isAsianView?: boolean;
  isMobile: boolean;
}

const Header = ({ isAsianView = false, isMobile }: HeaderProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const asianViewEnabled = useSelector(getIsAsianViewEnabled);
  const iframeEnabled = useSelector(getIsIframeEnabled);
  const desktopHeaderHidden = useSelector(getDesktopHeaderHidden);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const passiveSessionEnabled = useSelector(getIsPassiveSessionEnabled);
  const headsUpOverHeader = useSelector(getIsHeadsUpOverHeader);
  const sessionInfo = useSelector(getUserSessionInfo);

  const headerWarnMessage = t('responsibleBetting.message.HEAD_UP_OVER_HEADER');

  const isSessionCounterEnabled = passiveSessionEnabled && sessionInfo?.startSessionDate;

  const isShowHeaderBottom = !isMobile && (exchangeGamesEnabled || asianViewEnabled || exchangeSportsEnabled);

  const isResponsibleBettingPage = location.pathname.includes(RESPONSIBLE_BETTING_BASE_URL);

  const renderDesktopHeader = () => {
    if (!isMobile && !isAsianView && isResponsibleBettingPage) {
      return <AccountPagesSubHeader />;
    }

    if (!isMobile && !isAsianView) {
      return <HeaderNavigation isMobile={isMobile} />;
    }

    return null;
  };

  return (
    <>
      <HeaderResize isMobile={isMobile}>
        {!(iframeEnabled && desktopHeaderHidden) && (
          <header id="biab_header" className={styles.header}>
            {headsUpOverHeader && (
              <div
                id="biab_headsUpOver"
                className={styles.header__headsUpOver}
                dangerouslySetInnerHTML={{ __html: headerWarnMessage }}
              />
            )}
            <HeaderTop isMobile={isMobile} />
            {isShowHeaderBottom && (
              <HeaderButtons
                isGames={exchangeGamesEnabled}
                isAsianView={asianViewEnabled}
                sportEnabled={exchangeSportsEnabled}
              />
            )}
            {isMobile && isSessionCounterEnabled && <SessionTime isMobile={isMobile} />}
          </header>
        )}
        {renderDesktopHeader()}
      </HeaderResize>
      {isMobile && !isAsianView && <HeaderNavigation isMobile={isMobile} />}
    </>
  );
};

export default Header;
