import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { EPersistenceTypes } from 'types/bets';

import styles from './styles.module.scss';

interface PersistenceDropdownProps {
  persistenceType: EPersistenceTypes;
  setPersistenceType: (value: EPersistenceTypes) => void;
}

function PersistenceDropdown({ persistenceType, setPersistenceType }: PersistenceDropdownProps) {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onToggle = () => setIsOpen(prevState => !prevState);

  const handleClickOption = (value: EPersistenceTypes) => {
    setPersistenceType(value);
    setIsOpen(false);
  };

  return (
    <div className={classNames('biab_at-in-play-wrapper', styles.atInPlay)}>
      <span className={classNames('biab_at-in-play-span', styles.atInPlay__title)}>{t('betslip.labels.atInPlay')}</span>
      <div
        onClick={onToggle}
        className={classNames('biab_select', styles.atInPlay__select, isOpen && styles.atInPlay__select__open)}
      >
        <span className={classNames('biab_placeholder', styles.atInPlay__placeholder)}>
          {persistenceType === EPersistenceTypes.LAPSE ? t('betslip.labels.cancel') : t('betslip.labels.keep')}
        </span>
        {isOpen && (
          <ul>
            <li onClick={() => handleClickOption(EPersistenceTypes.LAPSE)}>{t('betslip.labels.cancel')}</li>
            <li onClick={() => handleClickOption(EPersistenceTypes.PERSIST)}>{t('betslip.labels.keep')}</li>
          </ul>
        )}
      </div>
    </div>
  );
}

export default PersistenceDropdown;
