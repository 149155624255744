import { ChangeEvent } from 'react';
import InputMask from 'react-input-mask';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface ResponsibleBettingInputFieldProps {
  value: number | null;
  placeholder: string;
  label: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  isMobile: boolean;
  error?: boolean;
  disabled?: boolean;
}

const ResponsibleBettingInputField = ({
  value,
  label,
  placeholder,
  onChange,
  onFocus,
  isMobile,
  error = false,
  disabled = false
}: ResponsibleBettingInputFieldProps) => (
  <div
    className={classNames(styles.container, {
      [styles.container__mobile]: isMobile
    })}
  >
    <label className={styles.label}>{label}</label>
    <InputMask
      mask="9999999999"
      maskChar=""
      className={classNames(styles.input, {
        [styles.input__with_error]: error,
        [styles.input__mobile]: isMobile
      })}
      placeholder={placeholder}
      disabled={disabled}
      value={value || ''}
      onChange={onChange}
      onFocus={onFocus}
    />
  </div>
);
export default ResponsibleBettingInputField;
