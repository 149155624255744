import { memo } from 'react';
import { useSelector } from 'react-redux';

import { getBetslipType } from 'redux/modules/betslip/selectors';
import { getSortedCurrentBets } from 'redux/modules/currentBets/selectors';
import { MatchTypes } from 'types/bets';
import { EBetslipTypes } from 'types/betslip';

import OpenedBet from '../OpenedBet';
import OpenedBetsHeader from '../OpenedBetsHeader';

const OpenedBetsSorted = () => {
  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const sortedCurrentBets = useSelector(getSortedCurrentBets(isGameBetslip));

  return (
    <>
      {sortedCurrentBets.map((bet, index) => (
        <div key={`${bet.offerId}_${bet.matchType ?? ''}`}>
          <OpenedBetsHeader
            bet={bet}
            prevBet={sortedCurrentBets[index - 1] ?? null}
            side={bet.side}
            matchType={bet.matchType}
          />
          <OpenedBet bet={bet} matchType={bet.matchType || MatchTypes.MATCHED} index={index} betIndex={index} />
        </div>
      ))}
    </>
  );
};

export default memo(OpenedBetsSorted);
