import { memo } from 'react';
import classnames from 'classnames';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { calculateCommission } from 'utils';

import styles from '../../styles.module.scss';

interface WhatIfValueProps {
  item: string;
  isProfitNetOfCommissionChecked?: boolean;
  commission?: number;
  currency?: string;
  isFinish?: boolean;
  isFutureProfit?: boolean;
  index?: number;
}

export const WhatIfValue = ({
  item,
  commission,
  currency,
  isFinish = false,
  isProfitNetOfCommissionChecked,
  isFutureProfit,
  index
}: WhatIfValueProps) => {
  let value = item;
  if (isProfitNetOfCommissionChecked && item[0] !== '-') {
    value = calculateCommission(value, commission);
  }

  // TODO call useless rerender
  // Need to optimize this hook
  value = useFormatCurrency(value, currency, {
    isCheckIndian: true,
    noRounding: true,
    ignoreFlexibleDisplayFormat: true
  }).formattedAmount;

  return (
    <div
      data-what-if-type-value={item[0] !== '-' ? 'positive' : 'negative'}
      data-what-if-type={isFutureProfit ? 'future' : 'current'}
      data-what-if-index={index}
      className={classnames(styles.item, {
        [styles.positive]: item[0] !== '-',
        [styles.negative]: item[0] === '-'
      })}
    >
      {value}
      {isFinish && ','}
    </div>
  );
};

export default memo(WhatIfValue);
