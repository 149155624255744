import { PropsWithChildren, RefObject } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import styles from './styles.module.scss';

type NavigationLinkProps = NavLinkProps & {
  absolutePath: boolean;
  isActiveLink: boolean;
  isActiveFullUrl: boolean;
  linkRef: RefObject<HTMLAnchorElement>;
  customClassName: string;
  isMoreDropdownItem?: boolean;
};

const NavigationLink = ({
  absolutePath,
  to,
  title,
  target,
  isActiveLink,
  isActiveFullUrl,
  children,
  linkRef,
  customClassName,
  isMoreDropdownItem = false
}: PropsWithChildren<NavigationLinkProps>) => {
  const location = useLocation();

  if (absolutePath) {
    return (
      <a
        ref={linkRef}
        target={target}
        href={typeof to === 'string' ? to : to.pathname}
        className={classNames(
          styles.link,
          {
            'biab_navigation-link': !isMoreDropdownItem,
            biab_active: isActiveLink,
            [styles.active]: isActiveLink
          },
          customClassName
        )}
        title={title}
      >
        {children}
      </a>
    );
  }

  return (
    <NavLink
      ref={linkRef}
      className={({ isActive }) => {
        return classNames(
          styles.link,
          {
            'biab_navigation-link': !isMoreDropdownItem,
            biab_active: (isActive && isActiveFullUrl) || isActiveLink,
            [styles.active]: (isActive && isActiveFullUrl) || isActiveLink
          },
          customClassName
        );
      }}
      end
      to={to}
      target={target}
      title={title}
      state={{ referer: location.pathname }}
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
