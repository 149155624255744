import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isString, toNumber } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { TPlacementError } from 'redux/modules/placement/type';
import { PageBlocks } from 'types';
import { TPrice, TSize } from 'types/bets';

import { TInlinePlacementState, TInlineSelectedBet } from './type';

const initialState: TInlinePlacementState = {
  bets: {}
};

const slice = createSlice({
  name: SLICES_NAMES.INLINE_PLACEMENT,
  initialState,
  reducers: {
    setInlineSelectedBet: (state, { payload }: PayloadAction<TInlineSelectedBet>) => {
      const isFancyBlock = payload.pageBlock === PageBlocks.FANCY_VIEW;
      const inlineSelectedBet = state.bets[payload.pageBlock]?.[payload.marketId] ?? {};
      const isInlineSelectedBet =
        inlineSelectedBet.selectionId === payload.selectionId &&
        toNumber(inlineSelectedBet.handicap) === toNumber(payload.handicap) &&
        inlineSelectedBet.type === payload.type;
      const bet = { ...payload, isPristinePrice: true };

      if (!state.bets[payload.pageBlock]) {
        state.bets[payload.pageBlock] = {};
      }

      /** Hide component when click cell second time */
      if (isInlineSelectedBet) {
        delete state.bets[payload.pageBlock][payload.marketId];
      } else if (isFancyBlock) {
        state.bets[payload.pageBlock] = { [payload.marketId]: bet };
      } else {
        state.bets[payload.pageBlock][payload.marketId] = bet;
      }
    },
    updateInlineSelectedBet: (state, { payload }: PayloadAction<TInlineSelectedBet>) => {
      if (!state.bets[payload.pageBlock]) {
        state.bets[payload.pageBlock] = {};
      }

      state.bets[payload.pageBlock][payload.marketId] = payload;
    },
    removeInlineSelectedBet: (state, { payload }: PayloadAction<TInlineSelectedBet>) => {
      const { [payload.marketId]: removedMarket } = state.bets[payload.pageBlock] ?? {};
      const isSelectedBet =
        removedMarket?.selectionId === payload.selectionId &&
        toNumber(removedMarket?.handicap) === toNumber(payload.handicap);

      if (isSelectedBet) {
        delete state.bets[payload.pageBlock][payload.marketId];
      }
    },
    cleanInlineSelectedBets: state => {
      state.bets = {};
    },
    successInlinePlacedBet: (state, { payload }: PayloadAction<TInlineSelectedBet & { placedBet: TCurrentBet }>) => {
      if (!state.bets[payload.pageBlock]) {
        state.bets[payload.pageBlock] = {};
      }

      if (!state.bets[payload.pageBlock][payload.marketId]) {
        state.bets[payload.pageBlock][payload.marketId] = {} as TInlineSelectedBet;
      }

      if (!state.bets[payload.pageBlock][payload.marketId].offers) {
        state.bets[payload.pageBlock][payload.marketId].offers = {};
      }
      /* TODO should be rewritten to state.bets[payload.pageBlock][payload.marketId].offers[payload.placedBet.offerId] = payload.placedBet,
        for now error typescript complains on error for expression above, need to upgrade typescript and related issues
       */
      state.bets[payload.pageBlock][payload.marketId].offers = {
        ...state.bets[payload.pageBlock][payload.marketId].offers,
        [payload.placedBet.offerId]: payload.placedBet
      };

      state.bets[payload.pageBlock][payload.marketId].currentOfferId = payload.placedBet.offerId;
    },
    failureInlinePlacedBet: (
      state,
      { payload }: PayloadAction<{ pageBlock: PageBlocks; marketId: string; error: TPlacementError | string }>
    ) => {
      if (!state.bets[payload.pageBlock]) {
        state.bets[payload.pageBlock] = {};
      }

      if (!state.bets[payload.pageBlock][payload.marketId]) {
        state.bets[payload.pageBlock][payload.marketId] = {} as TInlineSelectedBet;
      }

      state.bets[payload.pageBlock][payload.marketId].placementError = isString(payload.error)
        ? payload.error
        : payload.error?.response?.data?.message ?? '';
    },
    setInlinePlacedSize: (
      state,
      { payload }: PayloadAction<{ price: TPrice; size: TSize; marketId: string; pageBlock: PageBlocks }>
    ) => {
      if (!state.bets[payload.pageBlock]) {
        state.bets[payload.pageBlock] = {};
      }

      if (!state.bets[payload.pageBlock][payload.marketId]) {
        state.bets[payload.pageBlock][payload.marketId] = {} as TInlineSelectedBet;
      }

      state.bets[payload.pageBlock][payload.marketId].size = payload.size;
      state.bets[payload.pageBlock][payload.marketId].price = payload.price;
      state.bets[payload.pageBlock][payload.marketId].isPristinePrice = false;
    }
  }
});

export const {
  setInlineSelectedBet,
  successInlinePlacedBet,
  removeInlineSelectedBet,
  failureInlinePlacedBet,
  updateInlineSelectedBet,
  setInlinePlacedSize,
  cleanInlineSelectedBets
} = slice.actions;

export default slice.reducer;
