import { Platform } from 'redux/modules/appConfigs/type';
import { TFailureActionPayload } from 'types';
import { IMarket, IMarketRules } from 'types/markets';

export type CashOutQuote = {
  algorithm: string;
  currency: string;
  currentLiability: number;
  marketId: string;
  maxPartialPercentage: number;
  minPartialPercentage: number;
  profit: number;
  possibleProfit: number;
  profitPerSelection: ProfitSelection[];
  status: string;
  value: number;
};

export type ProfitSelection = {
  profit: number;
  selectionId: number;
  ratio?: number;
};

export type MarketCashOut = {
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  size: number;
  sort: null;
  totalElements: number;
  totalPages: number;
  content: IMarket[];
};

export type MarketCashOutResponse = {
  markets: MarketCashOut;
};

export type ProfitPayload = {
  marketId: string;
  error?: boolean;
  value?: string;
  hideError?: boolean;
};

export type CreateAutoCashOutPayload = {
  marketId: string;
  profit: number;
};

export type AutoCashOut = {
  currency: string;
  id: number;
  marketId: string;
  profit: number;
  status: string;
};

export type TCashOutInitValue = {
  loading: boolean;
  statusLoading: Record<number, boolean>;
  isFirstLoaded: boolean;
  error: TFailureActionPayload | string[] | string;
  marketCashOut: MarketCashOut;
  stringifiedMarkets: string;
  quotes: Record<string, CashOutQuote>;
  stringifiedQuotes: string;
  whatIf: Record<number, { profit: number; ratio: number }>;
  whatIfMarketId: string | null;
  possibleProfits: {
    [key: string]: ProfitPayload;
  };
  cashedValues: { [key: string]: number };
  autoCashOuts: { [key: string]: AutoCashOut };
  stringifiedAutoCashOuts: string;
  successFullCreatedAutoCashOuts: {
    [key: string]: boolean;
  };
  successFullDeletedAutoCashOuts: {
    [key: string]: boolean;
  };
  pendingCashOuts: {
    // marketId - statusId
    [key: string]: number;
  };
  cashOutStatuses: {
    [key: string]: string;
  };
  settingTabsStates: {
    [key: string]: boolean;
  };
  placedIds: {
    // marketId - statusId
    [key: string]: number;
  };
  paginationLoader: boolean;
  rules: Record<string, IMarketRules> | null;
  rulesLoading: boolean;
  rulesError: TFailureActionPayload;
  fetchCashOutLoading: boolean;
  fetchAutoCashOutLoading: boolean;
  fetchCashOutMarketsLoading: boolean;
};

export enum AutoCashOutStatuses {
  NEW = 'NEW',
  NEW_CREATED = 'NEW_CREATED',
  ACTIVE = 'ACTIVE',
  EXISTS_REMOVED = 'EXISTS_REMOVED',
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum CashOutStatuses {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_BETTER_VALUE = 'SUCCESS_WITH_BETTER_VALUE',
  UNSUCCESSFUL_BAD_RE_QUOTE = 'UNSUCCESSFUL_BAD_RE_QUOTE',
  UNSUCCESSFUL_EXPOSURE_LIMITS = 'UNSUCCESSFUL_EXPOSURE_LIMITS',
  UNSUCCESSFUL_PLACEMENT_FAILURE = 'UNSUCCESSFUL_PLACEMENT_FAILURE'
}

export type TSetCashOut = {
  marketId: string;
  profit: number;
  quote: number;
  quotePercentage?: number;
  statistics: { mobile: boolean; applicationType: Platform; page: string };
};

export type CashOutMarket = {
  id: number;
  offers: number[];
  status: string;
  marketId: string | number;
};

export type TResponseCashOutStatus = {
  status: string;
  marketId: string;
};
