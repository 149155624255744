import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { ExchangeTypes } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { getMobileSettingsActiveTab } from 'redux/modules/appSettings/selectors';

import DefaultStakeForm from './components/DefaultStakeForm';
import PlacementSetting from './components/PlacementSetting';
import SettingsCheckboxes from './components/SettingsCheckboxes';

import styles from './styles.module.scss';

const SettingsBetting = () => {
  const { t } = useTranslation();

  const { isMobile } = useDevice();
  const mobileSettingsActiveTab = useSelector(getMobileSettingsActiveTab);

  const { inlinePlacement, defStake, inlinePlacementMiddleSection } = useDeviceSettings();

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const isMobileAsianViewSettings = isMobile && mobileSettingsActiveTab === ExchangeTypes.AsianView;

  return (
    <>
      <h4
        className={classNames(styles.settingsBetting__title, {
          [styles.settingsBetting__title__mobile]: isMobile
        })}
      >
        {t('account.settings.betting.title')}
      </h4>
      {inlinePlacement && inlinePlacementMiddleSection && !isMobile && <PlacementSetting />}
      <SettingsCheckboxes
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        isAsianView={isMobileAsianViewSettings}
      />
      {defStake && <DefaultStakeForm isEditMode={isEditMode} setIsEditMode={setIsEditMode} />}
    </>
  );
};

export default SettingsBetting;
