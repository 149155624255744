import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { betslipBranding as branding } from 'constants/branding';
import { EBetMessageTypes } from 'types/betslip';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const BetMessage = ({ type, message }: { type: EBetMessageTypes | null; message: string }) => {
  const { t } = useTranslation();

  const moreInformationText = t('openBets.messages.moreInformation', { rootPath: getEnvironmentRootPath() });

  return (
    <>
      {message && (
        <div
          className={classNames(styles.betMessage, styles.betMessage__success, {
            [styles.betMessage__cover]: type === EBetMessageTypes.FULLY_MATCHED || type === EBetMessageTypes.CANCELLED,
            [styles.betMessage__icon]:
              type === EBetMessageTypes.FULLY_MATCHED || type === EBetMessageTypes.PARTIALLY_MATCHED,
            [branding.OPENED_BET_PLACEMENT_MSG]: type !== EBetMessageTypes.CANCELLED,
            [branding.OPENED_BET_CANCELLED_MSG]: type === EBetMessageTypes.CANCELLED
          })}
        >
          {type !== EBetMessageTypes.CANCELLED && (
            <i className={classNames('fa2 fa2-check', branding.MATCHED_MSG_ICON)} aria-hidden="true" />
          )}
          <div className={styles.betMessage__text}>
            <div dangerouslySetInnerHTML={{ __html: unescape(message) }} />
            {type !== EBetMessageTypes.CANCELLED && (
              <div dangerouslySetInnerHTML={{ __html: unescape(moreInformationText) }} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BetMessage;
