import { TPrice, TSize } from 'types/bets';

export enum Statuses {
  NEW = 'NEW',
  EDIT = 'EDIT',
  PLACED = 'PLACED',
  CONFIRM = 'CONFIRM',
  CONFIRMED = 'CONFIRMED',
  CONFIRMED_ENTER = 'CONFIRMED_ENTER',
  HIDDEN = 'HIDDEN',
  TIMEOUT_HIDDEN = 'TIMEOUT_HIDDEN',
  PROGRESS = 'PROGRESS',
  PLACED_EDIT = 'PLACED_EDIT'
}

export enum Actions {
  EDIT = 'EDIT',
  CANCEL = 'CANCEL',
  TAKE = 'TAKE'
}

export enum InputTypes {
  ODDS = 'ODDS',
  STAKE = 'STAKE'
}

export enum ButtonActionTypes {
  PLACE = 'PLACE',
  CONFIRM = 'CONFIRM',
  EDIT = 'EDIT',
  CANCEL = 'CANCEL',
  CANCEL_UNMATCHED = 'CANCEL_UNMATCHED',
  CANCEL_EDIT = 'CANCEL_EDIT'
}

export enum ESizes {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export type TInlinePlacement = {
  price?: TPrice;
  size?: TSize;
  placedUsingEnterKey?: boolean;
};
