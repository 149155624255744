import classNames from 'classnames';

import { generateArray } from 'utils/general';

import Skeleton from '../index';

import styles from './styles.module.scss';

interface BetslipSkeletonProps {
  itemsCount?: number;
  withDelay?: number;
  isAbsolute?: boolean;
  isShowHeader?: boolean;
}
export const BetslipSkeleton = ({ itemsCount = 6, isAbsolute = false }: BetslipSkeletonProps) => {
  const items = generateArray(itemsCount);

  return (
    <div
      data-skeleton={'betslipSkeleton'}
      className={classNames(styles.container, {
        [styles.isAbsolute]: isAbsolute
      })}
    >
      {items.map((item, index) => (
        <div key={index}>
          <Skeleton className={styles.item} />
        </div>
      ))}
    </div>
  );
};

export default BetslipSkeleton;
