export const tabs = {
  sports: { translationKey: 'header.navigation.sports', value: 'sports' },
  games: { translationKey: 'header.navigation.games', value: 'games' }
};

export const quickBetsTableHeader = [
  'account.settings.quickBets.columns.minBet',
  'account.settings.quickBets.columns.maxBet',
  'account.settings.quickBets.columns.step'
];

export const numberOfButtons = 8;
