import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { KEY_CODES } from 'constants/betslip';
import { ODDS_REGEX_PATTERN } from 'constants/placement';
import { TPrice, TSize } from 'types/bets';
import { ESizes, InputTypes } from 'types/inlinePlacement';

import styles from './styles.module.scss';

interface IInlinePlacementInput {
  value: TPrice | TSize;
  validValue?: TPrice | TSize;
  label: string;
  type: InputTypes;
  isInputLabels: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  isFocused?: boolean;
  isPristine?: boolean;
  isDirty?: boolean;
  componentSize?: ESizes;
  isReadOnly?: boolean;
  inputPattern?: RegExp;
  updateInputValue?: (value: TPrice | TSize) => void;
  showErrorMessage?: () => void;
  resetErrorMessage?: () => void;
  onTabNavigate?: (type: InputTypes) => void;
  onEnterClick?: () => void;
  setFocused?: (type: InputTypes) => void;
}

const InlinePlacementInput = ({
  value,
  validValue,
  label,
  type,
  isInputLabels,
  isDisabled,
  isSelected,
  isFocused,
  isDirty,
  componentSize,
  isReadOnly,
  inputPattern = ODDS_REGEX_PATTERN,
  updateInputValue = () => {},
  showErrorMessage = () => {},
  resetErrorMessage = () => {},
  onTabNavigate = () => {},
  onEnterClick = () => {},
  setFocused = () => {}
}: IInlinePlacementInput) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSelected && inputRef.current !== null) {
      inputRef.current.select();
    }
  }, [isSelected]);

  useEffect(() => {
    if (isFocused && inputRef.current !== null) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value
      .trim()
      .replace(/[^0-9.]/g, '')
      .replace(/\.\./g, '.');

    if (changedValue === '' || inputPattern.test(changedValue)) {
      updateInputValue(changedValue);
    }

    resetErrorMessage();
  };

  const blurHandler = () => {
    if (isDirty && validValue) {
      updateInputValue(validValue);
      showErrorMessage();
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.TAB) {
      e.preventDefault();
      onTabNavigate(type);
    } else if (e.key === KEY_CODES.ENTER) {
      e.preventDefault();
      onEnterClick();
    }
  };

  const focusHandler = () => {
    setFocused(type);
  };

  return (
    <div
      className={classNames(styles.inputControl, {
        [styles.inputControl__xs]: componentSize !== ESizes.LARGE,
        [styles.inputControl__hasLabel]: isInputLabels
      })}
    >
      {isInputLabels && <label>{label}</label>}
      {isReadOnly ? (
        <input
          type="text"
          value={value}
          data-input={type}
          className={classNames(styles.inputControl__field, styles.inputControl__field__disabled, {
            [styles.inputControl__field__xs]: componentSize === ESizes.SMALL,
            [styles.inputControl__field__md]: componentSize === ESizes.MEDIUM
          })}
          readOnly
        />
      ) : (
        <input
          type="text"
          ref={inputRef}
          data-input={type}
          value={!isUndefined(value) && value !== 0 ? value : ''}
          placeholder={isInputLabels ? '' : label}
          className={classNames(styles.inputControl__field, {
            [styles.inputControl__field__xs]: componentSize === ESizes.SMALL,
            [styles.inputControl__field__md]: componentSize === ESizes.MEDIUM,
            [styles.inputControl__field__disabled]: isDisabled
          })}
          disabled={isDisabled}
          onChange={changeHandler}
          onBlur={blurHandler}
          onFocus={focusHandler}
          onKeyDown={keyDownHandler}
        />
      )}
    </div>
  );
};

export default InlinePlacementInput;
