import { TFailureActionPayload } from 'types';

export interface PageContentType {
  content: string;
  name: string;
}

export type TPage = {
  loading: boolean;
  error: TFailureActionPayload;
  data: null | PageContentType;
};

export enum PagesFromBackend {
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
  AUTO_CASH_OUT = 'AUTO_CASH_OUT',
  PARTIAL_CASH_OUT_RULES = 'PARTIAL_CASH_OUT_RULES',
  CASH_OUT_RULES = 'CASH_OUT_RULES',
  LAY_BETTING_RULES_PAGE = 'LAY_BETTING_RULES_PAGE'
}

export interface Pages {
  [PagesFromBackend.PAGE_NOT_FOUND]: string;
  [PagesFromBackend.FORBIDDEN]: string;
  [PagesFromBackend.UNAUTHORIZED]: string;
  [PagesFromBackend.AUTO_CASH_OUT]: string;
  [PagesFromBackend.LAY_BETTING_RULES_PAGE]: string;
  GENERAL_ERROR?: string;
  COMMISSION?: string;
  GAME_RULES?: string;
  [PagesFromBackend.CASH_OUT_RULES]: string;
  CASH_OUT_POP_UP?: string;
  [PagesFromBackend.PARTIAL_CASH_OUT_RULES]: string;
  RULES?: string;
}
