import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getAppDevice } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings, getUserAccountSettingsFillOrKillEnabled } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const SettingsFillOrKill = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const device = useSelector(getAppDevice);
  const fillOrKillEnabled = useSelector(getUserAccountSettingsFillOrKillEnabled);

  const onChangeFillOrKill = (event: ChangeEvent<HTMLInputElement>) => {
    if (isAccountSettings) {
      dispatch(
        setSettings({
          fillOrKillEnabled: event.target.checked
        })
      );
    }
  };

  return (
    <div
      className={classNames({
        [styles.fillOrKill__wrapper__mobile]: device === Devices.MOBILE
      })}
    >
      <h4 className={classNames('biab_subpage-heading', styles.fillOrKill__title)}>
        {t('account.settings.fillOrKill.title')}
      </h4>
      <div className={styles.fillOrKill__content}>
        <input
          type="checkbox"
          id="fillOrKillInput"
          className={styles.fillOrKill__checkBox}
          defaultChecked={fillOrKillEnabled}
          onChange={onChangeFillOrKill}
        />
        <label htmlFor="fillOrKillInput" className={styles.fillOrKill__label}>
          {t('account.settings.fillOrKill.enable')}
        </label>
      </div>
    </div>
  );
};

export default SettingsFillOrKill;
