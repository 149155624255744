import { ChangeEvent } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { CookieNames } from 'types';

import styles from './styles.module.scss';

const OpenBetsSetting = () => {
  const { t } = useTranslation();
  const [cookies, setCookie] = useCookies([CookieNames.MOBILE_OPEN_BETS]);

  const isLoggedIn = useSelector(getLoggedInStatusState);

  const isChecked = cookies[CookieNames.MOBILE_OPEN_BETS] == 'true';

  const onSetOpenBetsSetting = (event: ChangeEvent<HTMLInputElement>) => {
    setCookie(CookieNames.MOBILE_OPEN_BETS, event.target.checked, { path: '/' });
  };

  return (
    <>
      {isLoggedIn && (
        <div className={styles.openBets}>
          <input id="openBets" type="checkbox" checked={isChecked} onChange={onSetOpenBetsSetting} />
          <label htmlFor="openBets" title={t('market.mobileOpenBets')}>
            {t('market.mobileOpenBets')}
          </label>
        </div>
      )}
    </>
  );
};

export default OpenBetsSetting;
