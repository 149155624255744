import { memo } from 'react';
import { useSelector } from 'react-redux';

import { getBetslipType, getConsolidatedBets } from 'redux/modules/betslip/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCurrentBetsBySideType } from 'redux/modules/currentBets/selectors';
import { ECurrentBetActions } from 'redux/modules/currentBets/type';
import { BetTypes, MatchTypes } from 'types/bets';
import { EBetslipTypes } from 'types/betslip';

import OpenedBet from '../OpenedBet';
import OpenedBetsHeader from '../OpenedBetsHeader';

const OpenedBetsBySide = ({ side, type }: { side: BetTypes; type: MatchTypes }) => {
  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const consolidateBets = useSelector(getConsolidatedBets);
  const currentBets = useSelector(getCurrentBetsBySideType(side, type, consolidateBets, isGameBetslip));
  const currentBackBets = useSelector(getCurrentBetsBySideType(BetTypes.BACK, type, consolidateBets, isGameBetslip));

  if (!currentBets.length) {
    return null;
  }

  const openedBetIndex = type === MatchTypes.UNMATCHED && side === BetTypes.LAY ? currentBackBets.length : 0;
  const hideOpenedBetsHeader = currentBets.every(
    bet => bet.action === ECurrentBetActions.CANCELLING && bet.offerState === BetsStatusesTypes.CANCELLED
  );

  return (
    <>
      {currentBets.map((bet, index) => (
        <div key={`${bet.offerId}${bet.isCombined ? '_combined' : ''}`}>
          {!hideOpenedBetsHeader && (
            <OpenedBetsHeader bet={bet} prevBet={currentBets[index - 1] ?? null} side={side} matchType={type} />
          )}
          <OpenedBet
            bet={bet}
            matchType={type}
            index={index}
            betIndex={type === MatchTypes.UNMATCHED ? openedBetIndex + index : 0}
            isFirst={index === 0}
            isLast={currentBets.length === index + 1}
          />
        </div>
      ))}
    </>
  );
};

export default memo(OpenedBetsBySide);
