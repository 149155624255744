import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import {
  ASIAN_POPULAR_LINK_COMPETITION_ID,
  ASIAN_POPULAR_LINK_SPORT_ID,
  ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE,
  ASIAN_VIEW_PAGE_PARAM,
  AsianViewMarketLink,
  AsianViewTimeFilter
} from 'constants/asianView';
import { asianViewNavigation as branding } from 'constants/branding';
import { ASIAN_BASE_URL, ASIAN_VIEW_CASH_OUT_URL } from 'constants/locations';
import useDeleteAsianViewSearchParams from 'hooks/useDeleteAsianViewSearchParams';
import { getIsDesktopInfiniteScrollEnabled } from 'redux/modules/appConfigs/selectors';
import { closeAsianViewProfitLossTablePopUp, resetContent } from 'redux/modules/asianView';

import styles from '../../styles.module.scss';

interface IListItem {
  name: string;
  count: number;
  sportId?: string;
  marketLink?: AsianViewMarketLink;
  isCashOutLink?: boolean;
}

const ListItem = ({ name, count, sportId, marketLink, isCashOutLink }: IListItem) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { timeFilter = AsianViewTimeFilter.Today, marketLink: paramsMarketLink } = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const isInfiniteScrollEnabled = useSelector(getIsDesktopInfiniteScrollEnabled);

  const { removeAsianViewSearchParams } = useDeleteAsianViewSearchParams();

  const popularLinkCompetitionId = searchParams.get(ASIAN_POPULAR_LINK_COMPETITION_ID);
  const popularLinkSportId = searchParams.get(ASIAN_POPULAR_LINK_SPORT_ID);
  const isPopularLinkView = !!popularLinkCompetitionId && !!popularLinkSportId;
  const pageParam = isInfiniteScrollEnabled ? '' : `${ASIAN_VIEW_PAGE_PARAM}=0`;
  const betSlipCashOutPage = searchParams.get(ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE);
  const betSlipCashOutPageParam = betSlipCashOutPage
    ? `${pageParam ? '&' : ''}${ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE}=${betSlipCashOutPage}`
    : '';
  const linkPathname = isCashOutLink
    ? `${ASIAN_VIEW_CASH_OUT_URL}/${timeFilter}`
    : `${ASIAN_BASE_URL}/sport/${sportId}/timeFilter/${timeFilter}/marketLink/${marketLink}`;
  const isActive = isCashOutLink
    ? location.pathname.includes(ASIAN_VIEW_CASH_OUT_URL)
    : marketLink === paramsMarketLink && (marketLink !== AsianViewMarketLink.OUTRIGHT || !isPopularLinkView);

  const handleClickItem = (event: SyntheticEvent<HTMLAnchorElement>) => {
    if (isActive) {
      event.preventDefault();
      removeAsianViewSearchParams();
    } else {
      dispatch(resetContent());
    }

    if (paramsMarketLink !== marketLink) {
      dispatch(closeAsianViewProfitLossTablePopUp());
    }
  };

  return (
    <Link
      to={{
        pathname: linkPathname,
        search: `${pageParam}${betSlipCashOutPageParam}`
      }}
      className={classNames(styles.listItem, branding.NAVIGATION_LINK, {
        [styles.active]: isActive,
        [branding.SELECTED]: isActive
      })}
      onClick={handleClickItem}
    >
      <p className={styles.listItemLeft}>{t(`asianView.labels.marketLinks.${name}`)}</p>
      <p className={styles.listItemRight}>{count}</p>
    </Link>
  );
};

export default ListItem;
