import { useSelector } from 'react-redux';

import { EXCHANGE_BETSLIP_FILTERS, GAMES_BETSLIP_FILTERS } from 'constants/betslip';
import { getBetslipType } from 'redux/modules/betslip/selectors';
import { EBetslipTypes } from 'types/betslip';

import BetslipFilterIcon from './components/BetslipFilterIcon';

import styles from './styles.module.scss';

const BetslipFilters = () => {
  const betslipType = useSelector(getBetslipType);
  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const betslipFilters = isGameBetslip ? GAMES_BETSLIP_FILTERS : EXCHANGE_BETSLIP_FILTERS;

  return (
    <ul className={styles.betslipFilters}>
      {betslipFilters.map(filter => (
        <BetslipFilterIcon key={filter} filter={filter} />
      ))}
    </ul>
  );
};

export default BetslipFilters;
