import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Modal from 'components/Modal';
import { EXPOSURE_LIMIT_ERROR_CODE, EXPOSURE_LIMIT_ERROR_TITLE, LOSS_LIMIT_ERROR_TITLE } from 'constants/placement';
import { setRGErrorMessage } from 'redux/modules/betslip';
import { getRGErrorMessage } from 'redux/modules/betslip/selectors';

const RGModalMessage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const rgErrorMessage = useSelector(getRGErrorMessage);

  const title =
    typeof rgErrorMessage === 'object' && rgErrorMessage?.response?.data?.id === EXPOSURE_LIMIT_ERROR_CODE
      ? t(EXPOSURE_LIMIT_ERROR_TITLE)
      : t(LOSS_LIMIT_ERROR_TITLE);

  if (!rgErrorMessage) {
    return null;
  }

  return (
    <Modal title={title} open={!!rgErrorMessage} onClose={() => dispatch(setRGErrorMessage(null))}>
      {rgErrorMessage && (
        <div
          dangerouslySetInnerHTML={{
            __html: typeof rgErrorMessage === 'object' ? rgErrorMessage?.data?.message ?? '' : ''
          }}
        />
      )}
    </Modal>
  );
};

export default RGModalMessage;
