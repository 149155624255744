import { ChangeEvent, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getConsolidateBets } from 'redux/modules/appConfigs/selectors';
import { setConsolidateBets } from 'redux/modules/betslip';
import { getConsolidateBetsFiltersEnabled } from 'redux/modules/betslip/selectors';
import { setSettings } from 'redux/modules/user';
import { getUserAccountSettingsConsolidateBets } from 'redux/modules/user/selectors';

import styles from './styles.module.scss';

const ConsolidateBets = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isConsolidateBetsEnabled = useSelector(getConsolidateBets);
  const isConsolidateBetsFiltersEnabled = useSelector(getConsolidateBetsFiltersEnabled);
  const consolidateBets = useSelector(getUserAccountSettingsConsolidateBets);

  const isConsolidateBetsDisplayed = isConsolidateBetsEnabled && isConsolidateBetsFiltersEnabled;

  useEffect(() => {
    dispatch(setConsolidateBets(consolidateBets && isConsolidateBetsEnabled && isConsolidateBetsFiltersEnabled));
  }, [consolidateBets, isConsolidateBetsEnabled, isConsolidateBetsFiltersEnabled]);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSettings({ consolidateBets: e.target.checked }));
  };

  return (
    <div className={styles.consolidateBets}>
      {isConsolidateBetsDisplayed && (
        <label>
          <input type="checkbox" checked={consolidateBets} onChange={onChangeHandler} />
          {t('betslip.labels.openBetsHeader.consolidateBets')}
        </label>
      )}
    </div>
  );
};

export default memo(ConsolidateBets);
