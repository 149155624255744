import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { NavigationItemType, PostMessageTypes } from 'constants/app';
import { ASIAN_BASE_URL, GAMES_BASE_URL } from 'constants/locations';
import useTooltip from 'hooks/useTooltip';
import { getIsIframeEnabled } from 'redux/modules/appConfigs/selectors';
import { TAccountNavigation } from 'types/navigation';
import { dispatchEvent, sendIframeMessages } from 'utils/iframe';

import styles from './styles.module.scss';

type DesktopNavigationMyAccountItemProps = {
  item: TAccountNavigation;
  onClose: () => void;
};

const DesktopNavigationMyAccountItem = ({ item, onClose }: DesktopNavigationMyAccountItemProps) => {
  const location = useLocation();
  const { t } = useTranslation();

  const iframeEnabled = useSelector(getIsIframeEnabled);

  const { translationKey, isShowIcon, isEnabled } = useTooltip(item.code);

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);
  const exchangePage = (isAsianViewPage && ASIAN_BASE_URL) || (isGamesPage && GAMES_BASE_URL) || '';

  const onClickNavigationItem = () => {
    if (item.type === NavigationItemType.MY_BETS) {
      if (iframeEnabled) {
        sendIframeMessages({ type: PostMessageTypes.MY_BETS });
      } else {
        dispatchEvent({ type: PostMessageTypes.MY_BETS });
      }
    }

    onClose();
  };

  return (
    <li key={item.link}>
      <NavLink
        onClick={onClickNavigationItem}
        to={`${exchangePage}${item.link}`}
        state={{ referer: location.pathname }}
      >
        <div className={styles.item}>
          <span>{t(item.title)}</span>
          {isEnabled && isShowIcon && (
            <i
              className={classNames('fa2 fa2-info-circle tooltip-icon cursor-help')}
              data-tooltip-id={'tooltip'}
              data-tooltip-html={unescape(t(translationKey))}
            >
              <span className="path1 biab_promoted-tooltip-bg biab_mobile-tooltip-bg biab_tooltip-bg" />
              <span className="path2" />
            </i>
          )}
        </div>
      </NavLink>
    </li>
  );
};

export default DesktopNavigationMyAccountItem;
