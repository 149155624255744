import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ONE_MINUTE_IN_MS } from 'constants/date';
import { useAppThunkDispatch } from 'redux/configureStore';
import { getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { updateResponsibleBettingReminderLastDate } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { fetchUserInfo } from 'redux/modules/user';
import { getUserSessionInfo } from 'redux/modules/user/selectors';
import { ResponsibleBettingReminderTimeUnit } from 'types/responsibleBetting';
import { parseSessionTime } from 'utils/responsibleBetting';

import ReminderBettingModalTemplate from '../ReminderBettingModalTemplate';

const ResponsibleBettingReminderModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const sessionInfo = useSelector(getUserSessionInfo);

  const [open, setOpen] = useState<boolean>(false);

  const thunkDispatch = useAppThunkDispatch();

  const isReminderEnabled = rbSettings?.rgTools?.reminderEnabled;
  const reminderTimeUnit = rbSettings?.rgTools?.reminderUnit;
  const reminderInterval = rbSettings?.rgTools?.reminderInterval;

  const parsedSessionTime = sessionInfo?.startSessionDate ? parseSessionTime(sessionInfo.startSessionDate, t) : 0;

  const closeReminder = () => {
    setOpen(false);
    thunkDispatch(updateResponsibleBettingReminderLastDate());
  };

  useEffect(() => {
    if (
      sessionInfo?.reminderLastUpdated &&
      sessionInfo?.startSessionDate &&
      isReminderEnabled &&
      reminderTimeUnit &&
      reminderInterval
    ) {
      const dateToShowReminder = new Date(sessionInfo.reminderLastUpdated);

      if (reminderTimeUnit === ResponsibleBettingReminderTimeUnit.Minute) {
        dateToShowReminder.setMinutes(dateToShowReminder.getMinutes() + reminderInterval);
      } else {
        dateToShowReminder.setHours(dateToShowReminder.getHours() + reminderInterval);
      }

      const timeToShowReminder = dateToShowReminder.getTime() - new Date().getTime();

      const timeout = setTimeout(() => {
        setOpen(true);
      }, timeToShowReminder);

      return () => {
        if (timeout) {
          clearTimeout(timeout);
        }
      };
    }
  }, [
    sessionInfo?.reminderLastUpdated,
    sessionInfo?.startSessionDate,
    isReminderEnabled,
    reminderTimeUnit,
    reminderInterval
  ]);

  useEffect(() => {
    if (open) {
      const timer = setInterval(() => {
        dispatch(fetchUserInfo());
      }, ONE_MINUTE_IN_MS);

      return () => {
        clearInterval(timer);
      };
    }
  }, [open, language, timezone]);

  return (
    <ReminderBettingModalTemplate
      title={t('responsibleBetting.labels.sessionReminder.title')}
      open={open}
      onClose={closeReminder}
      message={t('responsibleBetting.labels.sessionReminder.content', { N: parsedSessionTime })}
    />
  );
};

export default ResponsibleBettingReminderModal;
