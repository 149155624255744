import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useMarketsPricesVisibleSocketParam from 'hooks/useMarketsPricesVisibleSocketParam';
import { setLiabilityByMarket } from 'redux/modules/asianViewBetslip';
import { getAsianSelectedBetsList } from 'redux/modules/asianViewBetslip/selectors';
import {
  getMarketPricesBettingType,
  getMarketPricesComplete,
  getMarketPricesMarketType,
  getMarketPricesRunners
} from 'redux/modules/marketsPrices/selectors';
import { MarketsPricesSocketParamsSections } from 'redux/modules/marketsPrices/type';
import { getAsianLiabilityByMarket } from 'utils/totalLiability';

type TLiabilityByMarketProps = {
  marketId: string;
  eventId: string;
};

const LiabilityByMarket = ({ marketId, eventId }: TLiabilityByMarketProps) => {
  const dispatch = useDispatch();

  const marketType = useSelector(getMarketPricesMarketType(marketId));
  const bettingType = useSelector(getMarketPricesBettingType(marketId));
  const complete = useSelector(getMarketPricesComplete(marketId));
  const runners = useSelector(getMarketPricesRunners(marketId));
  const selectedBets = useSelector(getAsianSelectedBetsList);

  const { ref } = useMarketsPricesVisibleSocketParam({
    marketId,
    eventId,
    section: MarketsPricesSocketParamsSections.AsianViewDesktopBetSlip
  });

  const bets = selectedBets.filter(({ marketId: betMarketId, isDisabled }) => !isDisabled && betMarketId === marketId);

  const liability = getAsianLiabilityByMarket({
    bets,
    market: { marketId, marketType, bettingType, complete, runners }
  });

  useEffect(() => {
    dispatch(setLiabilityByMarket({ marketId, liability }));
  }, [liability]);

  return <div ref={ref} data-market-id={marketId} data-event-id={eventId} data-market-prices="true" />;
};

export default LiabilityByMarket;
