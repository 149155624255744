import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';

const getBetsStatuses = (state: AppState) => state.betsStatuses.statuses;

export const getBetsStatusesByOfferIds = (offerIds: number[]) =>
  createSelector(getBetsStatuses, statuses =>
    offerIds.map((offerId: number) => {
      return statuses[offerId];
    })
  );

export const getIsLoadingBetsStatusesByOfferIds = (offerIds: number[]) =>
  createSelector(getBetsStatusesByOfferIds(offerIds), statuses => statuses?.some(status => status?.loading));

export const getBetsStatusesValuesByOffersIds = (offerIds: number[]) =>
  createSelector(getBetsStatusesByOfferIds(offerIds), statuses =>
    statuses?.map(status => status?.status).filter(status => status)
  );

export const getBetsStatusesErrorByOffersIds = (offerIds: number[]) =>
  createSelector(getBetsStatusesByOfferIds(offerIds), statuses => statuses?.find(status => status?.error));
