import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getDisplayCurrencySymbol,
  getIndianNumberSystemEnabled,
  getIsCurrencySymbolAfterAmount,
  getReplaceCurrencySymbolForMatchedAndTooltips
} from 'redux/modules/appConfigs/selectors';
import { getPrecisionType, getUserCurrency } from 'redux/modules/user/selectors';
import { CurrencySettingsPlacement, PrecisionType } from 'types';
import { addCommasToNumber } from 'utils';
import { getDisplayFormatPrice } from 'utils/price';

import { useCurrency } from './useCurrency';
import { getCurrencyPrecisionValue } from './usePrecision';

const formatIndianNumber = (num: number | string) => {
  let number = num.toString().replace(/,/g, '');
  const fraction = number.toString().split('.');
  number = fraction[0].toString().replace(/(\d)(?=(\d{2})+\d$)/g, '$1,');
  if (fraction.length > 1) {
    number += '.' + fraction[1];
  }
  return number;
};

const getRounded = ({
  noRounding,
  ignorePrecision,
  precisionType,
  price
}: {
  noRounding?: boolean;
  price: string | number;
  ignorePrecision?: boolean;
  precisionType?: PrecisionType;
}) => {
  let result = price;

  if (noRounding) {
    result = Math.abs(parseFloat(String(price)));
    if (!ignorePrecision) {
      result = isNaN(result) ? '' : result.toFixed(getCurrencyPrecisionValue(precisionType));
    }
  } else {
    result = Math.abs(parseInt(String(price)));
  }

  return result;
};

interface ISettings {
  noCommas?: boolean;
  noRounding?: boolean;
  isCheckIndian?: boolean;
  noSymbol?: boolean;
  ignorePrecision?: boolean;
  placement?: CurrencySettingsPlacement;
  fractionDigits?: string;
  hideCurrencySymbol?: boolean;
  ignoreFlexibleDisplayFormat?: boolean;
}

export const useFormatCurrency = (
  amount: number | string,
  currencyCode?: string,
  settings: ISettings = {
    noCommas: false,
    noRounding: false,
    isCheckIndian: false,
    noSymbol: false,
    ignorePrecision: false,
    placement: undefined,
    fractionDigits: '',
    hideCurrencySymbol: false,
    ignoreFlexibleDisplayFormat: false
  }
) => {
  const { t } = useTranslation();

  const displayCurrencySymbol = useSelector(getDisplayCurrencySymbol);
  const currencySymbolAfterAmount = useSelector(getIsCurrencySymbolAfterAmount);
  const indianNumberSystemEnabled = useSelector(getIndianNumberSystemEnabled);
  const replaceCurrencySymbolForMatchedAndTooltips = useSelector(getReplaceCurrencySymbolForMatchedAndTooltips);
  const userCurrency = useSelector(getUserCurrency);
  const precisionType = useSelector(getPrecisionType);

  const {
    noCommas,
    noRounding,
    isCheckIndian,
    noSymbol,
    ignorePrecision,
    placement,
    fractionDigits = '',
    hideCurrencySymbol,
    ignoreFlexibleDisplayFormat = false
  } = settings;
  const isToolTipOrMatchedCase =
    placement && [CurrencySettingsPlacement.TOOLTIP, CurrencySettingsPlacement.MATCHED_AMOUNT].includes(placement);

  const currency = useCurrency(currencyCode || userCurrency);

  if (amount === '') {
    return { formattedAmount: '', noFormattedAmount: '' };
  }

  let symbol = isToolTipOrMatchedCase
    ? `${currencySymbolAfterAmount ? ' ' : ''}${currency?.currencyCode ?? ''}${!currencySymbolAfterAmount ? ' ' : ''}`
    : currency?.symbol ?? '';
  if (replaceCurrencySymbolForMatchedAndTooltips) {
    symbol = currency?.symbol ?? '';
  }

  const negativeSign = parseFloat(String(amount || 0)) < 0 ? '-' : '';
  const { price, formatLabelKey, isDivided } = getDisplayFormatPrice({
    price: parseFloat(String(amount)),
    displayFormat: currency?.displayFormat,
    indianNumberSystemEnabled,
    ignoreFlexibleDisplayFormat
  });
  let roundedWithoutDisplayFormatting = getRounded({ noRounding, precisionType, ignorePrecision, price: amount });
  let rounded: string | number = price;

  if (!isDivided) {
    rounded = getRounded({ noRounding, precisionType, ignorePrecision, price });
  }

  if (indianNumberSystemEnabled && isCheckIndian) {
    rounded = formatIndianNumber(rounded);
    roundedWithoutDisplayFormatting = formatIndianNumber(roundedWithoutDisplayFormatting);
  }

  const roundedWithCommas = addCommasToNumber(rounded);
  const roundedWithoutDisplayFormattingWithCommas = addCommasToNumber(roundedWithoutDisplayFormatting);

  const isBeforeAmount = !currencySymbolAfterAmount && displayCurrencySymbol && !hideCurrencySymbol && !noSymbol;
  const beforeAmount = isBeforeAmount && (rounded || typeof rounded === 'number') ? symbol : '';
  const beforeAmountWithoutDisplayFormatting =
    isBeforeAmount && (roundedWithoutDisplayFormatting || typeof roundedWithoutDisplayFormatting === 'number')
      ? symbol
      : '';

  const isAfterAmount = currencySymbolAfterAmount && displayCurrencySymbol && !hideCurrencySymbol && !noSymbol;
  const afterAmount = isAfterAmount && (rounded || typeof rounded === 'number') ? symbol : '';
  const afterAmountWithoutDisplayFormatting =
    isAfterAmount && (roundedWithoutDisplayFormatting || typeof roundedWithoutDisplayFormatting === 'number')
      ? symbol
      : '';

  return {
    // Value formatted by displayFormat in currency (Admin console -> Finance -> Currencies)
    formattedAmount: `${negativeSign}${beforeAmount}${noCommas ? rounded : roundedWithCommas}${fractionDigits}${
      formatLabelKey ? t(formatLabelKey) : ''
    }${afterAmount}`,
    // Value without formatting above
    noFormattedAmount: `${negativeSign}${beforeAmountWithoutDisplayFormatting}${
      noCommas ? roundedWithoutDisplayFormatting : roundedWithoutDisplayFormattingWithCommas
    }${fractionDigits}${afterAmountWithoutDisplayFormatting}`
  };
};
