import { isNaN, isUndefined, max, min, reduce, sum, toNumber, union, uniq } from 'lodash';

import { BETTING_TYPES } from 'constants/app';
import { MARKET_TYPES } from 'constants/marketTypes';
import { DEFAULT_EACH_WAY_DIVISOR } from 'constants/placement';
import { TAsianSelectedBet } from 'redux/modules/asianViewBetslip/type';
import { BetTypes } from 'types/bets';
import {
  TBetLiability,
  TBetslipMarket,
  TBetslipMarketDefinition,
  TBetslipMarketPricesRunner,
  TLiabilityBySelection,
  TLineMarketPL,
  TSelectionLiability
} from 'types/betslip';
import { BettingType, ERunnerStatuses, TMarketPricesRunner } from 'types/markets';
import {
  getLiabilityByAsianHandicapMarket,
  getLiabilityByATGMarket,
  getLiabilityByCombinedMarket
} from 'utils/AHLiability';
import { round } from 'utils/liability';

export const getLiability = ({
  backs,
  lays,
  market,
  marketDefinition
}: {
  backs: TBetLiability[];
  lays: TBetLiability[];
  market?: TBetslipMarket;
  marketDefinition?: TBetslipMarketDefinition | null;
}) => {
  const marketsIds = union(
    backs.map(({ marketId }) => marketId),
    lays.map(({ marketId }) => marketId)
  );

  const { marketType, bettingType } = marketDefinition ?? {};
  const isAltTotalGoals = marketType === MARKET_TYPES.altTotalGoals;
  const isAHSingleLine = bettingType === BETTING_TYPES.asianHandicapSingleLine;
  const isAHDoubleLine = bettingType === BETTING_TYPES.asianHandicapDoubleLine;
  const isLineMarket = bettingType === BETTING_TYPES.line;
  const isSpreadAndTotals = marketType === MARKET_TYPES.combinedTotal;
  const isVariableHandicap = marketType === MARKET_TYPES.variableHandicap;
  const eachWayDivisor = market?.description?.eachWayDivisor ?? DEFAULT_EACH_WAY_DIVISOR;

  return marketsIds.reduce((totalLiability: number, marketId: string) => {
    if (isAltTotalGoals || isSpreadAndTotals || isAHSingleLine || isAHDoubleLine || isVariableHandicap) {
      const curBacks = backs.filter(backBet => backBet.marketId === marketId) ?? [];
      const curLays = lays.filter(layBet => layBet.marketId === marketId) ?? [];
      const totalBets = curBacks.concat(curLays);

      if (isAltTotalGoals) {
        totalLiability += getLiabilityByATGMarket(totalBets, market, marketDefinition);
      } else if (isSpreadAndTotals) {
        totalLiability += getLiabilityByCombinedMarket(totalBets, market);
      } else if (isAHSingleLine || isAHDoubleLine || isVariableHandicap) {
        totalLiability += getLiabilityByAsianHandicapMarket(totalBets, market);
      }
    } else if (isLineMarket) {
      totalLiability += getLineMarketLiability(backs, lays);
    } else {
      totalLiability += getMarketLiability({ backs, lays, market: marketDefinition, eachWayDivisor });
    }

    return totalLiability;
  }, 0);
};

export const getMarketLiability = ({
  backs,
  lays,
  market,
  eachWayDivisor = DEFAULT_EACH_WAY_DIVISOR
}: {
  backs: TBetLiability[];
  lays: TBetLiability[];
  market?: TBetslipMarketDefinition | null;
  eachWayDivisor?: number | null;
}) => {
  if (!backs.length && !lays.length) {
    return 0;
  }

  const runners =
    market?.runners?.filter((runner: TBetslipMarketPricesRunner) => {
      return runner.status === ERunnerStatuses.ACTIVE || runner.status === ERunnerStatuses.IN_PLAY;
    }) ?? [];

  const isMarketComplete = market?.complete ?? false;
  const marketBettingType = market?.bettingType || BETTING_TYPES.odds;
  const isAHDoubleLine = marketBettingType === BETTING_TYPES.asianHandicapDoubleLine;
  const isEachWay = market?.marketType === MARKET_TYPES.eachWay;
  // const numberOfActiveRunners = market.numberOfActiveRunners || runners.length;

  let numberOfWinners: number | undefined;

  if (market?.marketType === MARKET_TYPES.altTotalGoals) {
    numberOfWinners = 1;
  } else if (market?.marketType === MARKET_TYPES.anyNumberOfWinners) {
    numberOfWinners = 0;
  } else {
    numberOfWinners = isAHDoubleLine ? market?.numberOfWinners : market?.numberOfWinners || 1;
  }

  if (market?.bettingType === BETTING_TYPES.asianHandicapSingleLine) {
    return getAsianHandicapMarketLiability({ backs, lays });
  }

  const filterBetsBySelection = (bet: TBetLiability, selection: TBetslipMarketPricesRunner) =>
    bet.selectionId === selection.selectionId && toNumber(bet.handicap || 0) === toNumber(selection.handicap || 0);

  const getBetLiability = (res: number, bet: TBetLiability) => {
    if (isEachWay) {
      return (
        res +
        Math.abs(
          getEachWayProfit(
            toNumber(bet.size || 0),
            toNumber(bet.price || 0),
            eachWayDivisor || DEFAULT_EACH_WAY_DIVISOR,
            bet.type
          )[bet.type === BetTypes.BACK ? 'lose' : 'win']
        )
      );
    } else {
      return res + toNumber(bet[bet.type === BetTypes.BACK ? 'size' : 'profit'] || 0);
    }
  };

  const selections: TSelectionLiability[] = runners.map((selection: TBetslipMarketPricesRunner) => {
    return {
      ...selection,
      backLiability: backs
        .filter((bet: TBetLiability) => filterBetsBySelection(bet, selection))
        .reduce((res: number, bet: TBetLiability) => getBetLiability(res, bet), 0),
      layLiability: lays
        .filter((bet: TBetLiability) => filterBetsBySelection(bet, selection))
        .reduce((res: number, bet: TBetLiability) => getBetLiability(res, bet), 0)
    };
  });

  const liabilityBySelection: TLiabilityBySelection[] = selections.map(selection => {
    const otherSelectionsPL = selections.filter(otherSelection => {
      if (marketBettingType === BettingType.ASIAN_HANDICAP_DOUBLE_LINE) {
        return otherSelection.selectionId !== selection.selectionId && otherSelection.handicap !== selection.handicap;
      } else {
        return otherSelection.selectionId !== selection.selectionId;
      }
    });

    const otherSelectionsBackLiabilities = otherSelectionsPL.map(otherSelection => otherSelection.backLiability);
    const worstCaseLiability = selection.layLiability + sum(otherSelectionsBackLiabilities);

    return {
      selectionId: selection.selectionId,
      liability: worstCaseLiability,
      handicap: selection.handicap
    };
  });

  liabilityBySelection.sort((a, b) => (a.liability > b.liability ? -1 : 1));

  const getLiabilitySummary = (
    selectionsList: TSelectionLiability[],
    maxLiabilitySelections: TLiabilityBySelection[],
    isMarketCompleteParam = false
  ) => {
    return selectionsList.reduce((result: number, selection: TSelectionLiability) => {
      const match = maxLiabilitySelections.find(maxSelection => {
        return (
          maxSelection.selectionId === selection.selectionId &&
          toNumber(maxSelection.handicap || 0) === toNumber(selection.handicap || 0)
        );
      });

      // If market is complete exposure is the sum of Profit-If-Win of the first W selections
      // and the profit-if-lose for the rest of the selections.
      // If market is incomplete exposure is the sum(min(Profit-If-Win, Profit-If-Lose)) of the first W selections
      // and the profit-if-lose for the rest of the selections.

      if (match) {
        result += isMarketCompleteParam
          ? selection.layLiability
          : max([selection.layLiability, selection.backLiability]) || 0;
      } else {
        result += selection.backLiability;
      }

      return result;
    }, 0);
  };

  let liabilitySum;

  // If number of winners = 0 each selection should be treated individually.
  // Exposure is sum(min(Profit-If-Win, Profit-If-Lose)) across all selections
  if (numberOfWinners === 0) {
    liabilitySum = selections.reduce((prev: number, selection: TSelectionLiability) => {
      return prev + (max([selection.layLiability, selection.backLiability]) ?? 0);
    }, 0);
  } else {
    liabilitySum = getLiabilitySummary(selections, liabilityBySelection.slice(0, numberOfWinners), isMarketComplete);
  }

  return round(liabilitySum);
};

export const getAsianHandicapMarketLiability = ({ backs, lays }: { backs: TBetLiability[]; lays: TBetLiability[] }) => {
  const backsLiability: Record<string, number> = backs.reduce((res: Record<string, number>, bet: TBetLiability) => {
    return { ...res, [bet.selectionId]: toNumber(bet.size || 0) };
  }, {});

  const totalLiability = lays.reduce((res: Record<string, number>, bet: TBetLiability) => {
    return {
      ...res,
      [bet.selectionId]: Math.max(
        backsLiability[bet.selectionId] || 0,
        toNumber(bet.size || 0) * (toNumber(bet.price || 0) - 1) || 0
      )
    };
  }, backsLiability);

  return reduce(totalLiability, (res: number, value: number) => res + value, 0);
};

export const getEachWayProfit = (size = 0, price = 0, divisor = DEFAULT_EACH_WAY_DIVISOR, type: BetTypes) => {
  const ewPrice = 1 + (price - 1) / divisor;

  return type === BetTypes.BACK
    ? {
        win: size * (price - 1) + size * (ewPrice - 1),
        place: -size + size * (ewPrice - 1),
        lose: -2 * size
      }
    : {
        win: -size * (price - 1) - size * (ewPrice - 1),
        place: size - size * (ewPrice - 1),
        lose: 2 * size
      };
};

export const getLineMarketLiability = (backOffers: TBetLiability[], layOffers: TBetLiability[]) => {
  const offers = backOffers.concat(layOffers);
  let linesPL: number[] = [];

  offers.forEach(offer => {
    const price = toNumber(offer.price || 0);

    if (!isNaN(price) && !isUndefined(price)) {
      if (!(price % 1)) {
        linesPL.push(price);
      } else {
        linesPL.push(Math.floor(price));
        linesPL.push(Math.ceil(price));
      }
    }
  });

  linesPL = uniq(linesPL);
  linesPL = linesPL.sort((a, b) => {
    return a - b;
  });

  if (linesPL.length) {
    linesPL.unshift((min(linesPL) ?? 0) - 1);
    linesPL.push((max(linesPL) ?? 0) + 1);
  }

  const linesPLMap: TLineMarketPL[] = linesPL.map(line => {
    const lineItem: TLineMarketPL = { line: line };

    lineItem.backProfit = offers.reduce((total, bet) => {
      return bet.type === BetTypes.BACK && toNumber(bet.price || 0) > lineItem.line
        ? total + toNumber(bet.size || 0)
        : total;
    }, 0);

    lineItem.backLoss = offers.reduce((total, bet) => {
      return bet.type === BetTypes.BACK && toNumber(bet.price || 0) <= lineItem.line
        ? total + toNumber(bet.size || 0)
        : total;
    }, 0);

    lineItem.layProfit = offers.reduce((total, bet) => {
      return bet.type === BetTypes.LAY && toNumber(bet.price) <= lineItem.line
        ? total + toNumber(bet.size || 0)
        : total;
    }, 0);

    lineItem.layLoss = offers.reduce((total, bet) => {
      return bet.type === BetTypes.LAY && toNumber(bet.price || 0) > lineItem.line
        ? total + toNumber(bet.size || 0)
        : total;
    }, 0);

    lineItem.total = round(lineItem.backProfit - lineItem.backLoss + lineItem.layProfit - lineItem.layLoss);

    return lineItem;
  });

  const liability = min(linesPLMap.map(lineItem => lineItem.total)) ?? 0;

  return Math.abs(liability);
};

export const getAsianLiabilityByMarket = ({
  bets,
  market
}: {
  bets: TAsianSelectedBet[];
  market: {
    marketId: string;
    marketType: string;
    bettingType: string;
    complete: boolean;
    runners: TMarketPricesRunner[];
  };
}) => {
  const isAltTotalGoals = market?.marketType === MARKET_TYPES.altTotalGoals;
  const isAHSingleLine = market?.bettingType === BETTING_TYPES.asianHandicapSingleLine;
  const isAHDoubleLine = market?.bettingType === BETTING_TYPES.asianHandicapDoubleLine;
  const isSpreadAndTotals = market?.marketType === MARKET_TYPES.combinedTotal;
  const isVariableHandicap = market?.marketType === MARKET_TYPES.variableHandicap;

  const totalBets = bets.map(bet => {
    return {
      marketId: bet.marketId,
      selectionId: bet.selectionId,
      handicap: bet.handicap,
      type: bet.betType,
      price: bet.price,
      size: bet.size,
      profit: bet.profit,
      runners: market?.runners ?? [],
      complete: market?.complete
    };
  });

  const backs = totalBets.filter(({ marketId, type }) => market.marketId === marketId && type === BetTypes.BACK);

  const lays = totalBets.filter(({ marketId, type }) => market.marketId === marketId && type === BetTypes.LAY);

  const marketData = {
    marketId: market.marketId ?? '',
    description: {
      marketType: market?.marketType ?? '',
      bettingType: market?.bettingType ?? ''
    },
    runners: market?.runners ?? []
  };

  const marketDefinition = { marketType: market?.marketType ?? '' };

  if (isAltTotalGoals || isSpreadAndTotals || isAHSingleLine || isAHDoubleLine || isVariableHandicap) {
    if (isAltTotalGoals) {
      return getLiabilityByATGMarket(totalBets, marketData, marketDefinition);
    } else if (isSpreadAndTotals) {
      return getLiabilityByCombinedMarket(totalBets, marketData);
    } else if (isAHSingleLine || isAHDoubleLine || isVariableHandicap) {
      return getLiabilityByAsianHandicapMarket(totalBets, marketData);
    }
  } else {
    return getMarketLiability({ backs, lays, market });
  }

  return 0;
};
