import { KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import NetProfitLoss from 'components/Betslip/components/NetProfitLoss';
import RGModalMessage from 'components/Betslip/components/RGModalMessage';
import { KEY_CODES, tabulation } from 'constants/betslip';
import { betslipBranding as branding } from 'constants/branding';
import {
  LOADING_TIMEOUT_MESSAGE,
  OPEN_BETS_NOTIFICATION_TIME,
  VALIDATION_ERROR_BET_OUTDATED_LINE,
  VALIDATION_ERROR_BET_OUTDATED_ODDS
} from 'constants/placement';
import { BETSLIP_CONFIRM_CHECKBOX } from 'constants/tooltip';
import useConfirmBets from 'hooks/useConfirmBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useMarketUnits } from 'hooks/useMarketUnits';
import { usePlacementData } from 'hooks/usePlacement';
import usePostMessage from 'hooks/usePostMessage';
import useTooltip from 'hooks/useTooltip';
import {
  getBalanceWsEnabled,
  getBetslipSpinnerTime,
  getDesktopSettingsNetOfCommissionBetslip,
  getGeneralWsEnabled,
  getIsOperatorBalanceEnabled,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  removeAllSelectedBets,
  setActiveTab,
  setBetslipLoading,
  setErrorMessage,
  setPlaceBetsState,
  setRGErrorMessage,
  updateSelectedBet
} from 'redux/modules/betslip';
import {
  getBetslipPlaceBetsState,
  getBetslipType,
  getFirstSelectedBet,
  getSelectedBets
} from 'redux/modules/betslip/selectors';
import { EBetFocusFields, EBetslipTabs, EPlaceBetsStates } from 'redux/modules/betslip/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { getCurrentGameData } from 'redux/modules/games/selectors';
import { TPlacementError } from 'redux/modules/placement/type';
import { fetchBalance } from 'redux/modules/user';
import { getAccountSettings } from 'redux/modules/user/selectors';
import { EBetActions, EBetslipTypes, TBetslipMarket } from 'types/betslip';
import { isLineBettingType, isResponsibleGamblingError } from 'utils/betslip';

import styles from './styles.module.scss';

const PlaceBetsActions = ({ market }: { market: TBetslipMarket }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const betslipSpinnerTime = useSelector(getBetslipSpinnerTime);
  const netOfCommissionBetslip = useSelector(getDesktopSettingsNetOfCommissionBetslip);
  const gameData = useSelector(getCurrentGameData);
  const placeBetsState = useSelector(getBetslipPlaceBetsState);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const firstSelectedBet = useSelector(getFirstSelectedBet);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const betslipType = useSelector(getBetslipType);
  const selectedBetsList = useSelector(getSelectedBets);
  const accountSettings = useSelector(getAccountSettings);
  const isOperatorBalanceEnabled = useSelector(getIsOperatorBalanceEnabled);
  const balanceWsEnabled = useSelector(getBalanceWsEnabled);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const confirmBtnRef = useRef<HTMLButtonElement>(null);
  const spinnerTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const errorMessageTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { isConfirmBetsBeforePlacement, isConfirmBetsEnabled, saveConfirmBetsBeforePlacement } = useConfirmBets();
  const { placeBetWithEnterKey } = useDeviceSettings();
  const { placeBetLogin } = usePostMessage();
  const { translationKey, isEnabled } = useTooltip(BETSLIP_CONFIRM_CHECKBOX);
  const marketUnitTranslated = useMarketUnits(market.description?.lineRangeInfo?.marketUnit ?? 'points');

  const [betAction, setBetAction] = useState<EBetActions>(EBetActions.NONE);

  const isGameBetslip = betslipType === EBetslipTypes.GAME;
  const isPlacementValid = selectedBetsList.every(bet => bet.isValid !== false);
  const isPlacementEnabled = isLoggedIn && isPlacementValid;
  const isPlaceBetsWithEnterKeyEnabled = placeBetWithEnterKey && accountSettings?.placeBetWithEnterKey;
  const isTakeOffer = isPNCEnabled && placeBetsState === EPlaceBetsStates.TAKE_OFFER;
  const isLineMarket = isLineBettingType(market.description?.bettingType ?? '');

  const onSuccessPlacement = (_: TCurrentBet[], cancelledBets: TCurrentBet[], error?: string) => {
    setBetAction(EBetActions.NONE);
    dispatch(setBetslipLoading(false));
    dispatch(setActiveTab(EBetslipTabs.OPEN_BETS));
    dispatch(removeAllSelectedBets());
    setCancelledBetsError(cancelledBets);
    if (!isOperatorBalanceEnabled && (!generalWsEnabled || !balanceWsEnabled)) {
      dispatch(fetchBalance());
    }

    if (error) {
      dispatch(setErrorMessage(error));
    }
  };

  const onErrorPlacement = (error: TPlacementError | string) => {
    setBetAction(EBetActions.NONE);
    dispatch(setErrorMessage(error));
    dispatch(setBetslipLoading(false));

    if (isResponsibleGamblingError(error)) {
      dispatch(setRGErrorMessage(error));
    }
  };

  const onCancelledPlacement = () => {
    setBetAction(EBetActions.NONE);
    dispatch(setBetslipLoading(false));
    dispatch(setActiveTab(EBetslipTabs.PLACE_BETS));

    if (isPNCEnabled && !isGameBetslip) {
      dispatch(
        setErrorMessage(isLineMarket ? t(VALIDATION_ERROR_BET_OUTDATED_LINE) : t(VALIDATION_ERROR_BET_OUTDATED_ODDS))
      );

      errorMessageTimeout.current = setTimeout(() => {
        dispatch(setErrorMessage(''));
      }, OPEN_BETS_NOTIFICATION_TIME);

      changePlaceBetsState(EPlaceBetsStates.TAKE_OFFER);
    }
  };

  const placement = usePlacementData({
    eachWayDivisor: market?.description?.eachWayDivisor,
    numberOfWinners: market?.numberOfWinners,
    successPlacement: onSuccessPlacement,
    errorPlacement: onErrorPlacement,
    onCancelledPlacement
  });

  const setCancelledBetsError = (cancelledBets: TCurrentBet[]) => {
    if (cancelledBets.length) {
      const errorMessage = cancelledBets
        .map(bet =>
          t('betslip.messages.cancelledPlacedBet', {
            sizePlaced: bet.sizePlaced,
            odds: bet.price
          })
        )
        .join(`<br>`);
      dispatch(setErrorMessage(errorMessage));
    }
  };

  useEffect(() => {
    if ([EPlaceBetsStates.PLACE, EPlaceBetsStates.PLACE_ENTER].includes(placeBetsState)) {
      placeAllSelectedBets({ placedUsingEnterKey: placeBetsState === EPlaceBetsStates.PLACE_ENTER });
    } else if (placeBetsState === EPlaceBetsStates.CONFIRM && confirmBtnRef.current !== null) {
      confirmBtnRef.current.focus();
    }
  }, [placeBetsState]);

  useEffect(() => {
    if (betAction === EBetActions.PROGRESS) {
      spinnerTimeout.current = setTimeout(() => {
        setBetAction(EBetActions.NONE);
        dispatch(setBetslipLoading(false));
        dispatch(setErrorMessage(t(LOADING_TIMEOUT_MESSAGE)));
      }, +betslipSpinnerTime * 1000);
    } else {
      if (spinnerTimeout.current) {
        clearTimeout(spinnerTimeout.current);
      }
    }

    return () => {
      if (spinnerTimeout.current) {
        clearTimeout(spinnerTimeout.current);
      }
    };
  }, [betAction]);

  useEffect(() => {
    return () => {
      if (errorMessageTimeout.current) {
        clearTimeout(errorMessageTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isGameBetslip) {
      dispatch(setBetslipLoading(false));
    }
  }, [isGameBetslip, gameData?.round]);

  const getConfirmButtonLabel = () => {
    if (isTakeOffer) {
      return isLineMarket
        ? t('betslip.actions.placeBetAtAvailableUnits', { unit: marketUnitTranslated })
        : t('betslip.actions.placeBetAtAvailableOdds');
    } else {
      return t('betslip.actions.confirm');
    }
  };

  const cancelAllSelections = () => {
    dispatch(removeAllSelectedBets());
  };

  const onPlaceBetsClick = () => {
    if (isPlacementValid) {
      if (!isLoggedIn) {
        placeBetLogin();
      } else if (isPlacementEnabled) {
        if (isConfirmBetsBeforePlacement) {
          changePlaceBetsState(EPlaceBetsStates.CONFIRM);
        } else {
          placeAllSelectedBets();
        }
      }
    }
  };

  const onConfirmBetsClick = () => {
    placeAllSelectedBets();
  };

  const changePlaceBetsState = (state: EPlaceBetsStates) => {
    dispatch(setPlaceBetsState(state));
  };

  const changeConfirm = () => {
    saveConfirmBetsBeforePlacement(!isConfirmBetsBeforePlacement);
  };

  const placeAllSelectedBets = (
    { placedUsingEnterKey = false }: { placedUsingEnterKey?: boolean } = { placedUsingEnterKey: false }
  ) => {
    setBetAction(EBetActions.PROGRESS);
    dispatch(setBetslipLoading(true));

    placement.placeBetsHandler({
      marketId: market.marketId,
      bets: selectedBetsList.map(bet => {
        const { isValid, ...betData } = bet;
        return { ...betData, ...{ side: bet.type } };
      }),
      options: {
        isTakeOffer: isPNCEnabled && placeBetsState === EPlaceBetsStates.TAKE_OFFER,
        placedUsingEnterKey,
        round: market.round
      }
    });
  };

  const onPressButton = (event: KeyboardEvent) => {
    event.preventDefault();

    if (event.key === KEY_CODES.ENTER && isPlaceBetsWithEnterKeyEnabled && isPlacementEnabled) {
      if (placeBetsState === EPlaceBetsStates.SELECT) {
        if (isConfirmBetsEnabled) {
          changePlaceBetsState(EPlaceBetsStates.CONFIRM);
        } else {
          placeAllSelectedBets({ placedUsingEnterKey: true });
        }
      } else if (placeBetsState === EPlaceBetsStates.CONFIRM) {
        placeAllSelectedBets({ placedUsingEnterKey: true });
      }
    }
  };

  const onKeyDownButton = (event: KeyboardEvent, isRestartTabNavigation: boolean) => {
    if (event.key === KEY_CODES.TAB && isRestartTabNavigation && firstSelectedBet) {
      event.preventDefault();

      dispatch(
        updateSelectedBet({
          ...firstSelectedBet,
          focusedField: !isPNCEnabled || isGameBetslip ? EBetFocusFields.PRICE : EBetFocusFields.SIZE
        })
      );
    }
  };

  const getButtonTabIndex = (index: number) => {
    return !isPlacementValid && index === tabulation.PLACE_BTN_ORDER
      ? -1
      : (selectedBetsList.length || 0) *
          (index === tabulation.PLACE_BTN_ORDER ? tabulation.BET_TABS : tabulation.FULL_BET_TABS) +
          index;
  };

  return (
    <div>
      <hr className={styles.hr} />
      <div className={styles.buttonsWrap}>
        {placeBetsState === EPlaceBetsStates.SELECT ? (
          <>
            <button
              className={classNames(styles.cancelBtn, branding.BET_BTN, branding.CANCEL_ALL_BTN)}
              onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isConfirmBetsEnabled)}
              onClick={cancelAllSelections}
              tabIndex={getButtonTabIndex(tabulation.CANCEL_ALL_BTN_ORDER)}
            >
              {t('betslip.actions.cancelSelections')}
            </button>
            <button
              className={classNames(styles.placeBtn, branding.BET_BTN, branding.PLACE_BETS_BTN, {
                [styles.placeBtn__disabled]: !isPlacementEnabled,
                [branding.DISABLED]: !isPlacementValid
              })}
              onClick={onPlaceBetsClick}
              onKeyPress={onPressButton}
              onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isPlacementEnabled)}
              tabIndex={getButtonTabIndex(tabulation.PLACE_BTN_ORDER)}
            >
              {t('betslip.actions.place')}
            </button>
          </>
        ) : (
          <>
            <button
              className={classNames(styles.editBtn, branding.BET_BTN, branding.EDIT_BETS_BTN)}
              onClick={() => changePlaceBetsState(EPlaceBetsStates.SELECT)}
              tabIndex={getButtonTabIndex(tabulation.EDIT_BTN_ORDER)}
            >
              {t('betslip.actions.edit')}
            </button>
            <button
              className={classNames(styles.placeBtn, branding.BET_BTN, branding.CONFIRM_BETS_BTN, {
                [styles.placeBtn__disabled]: !isPlacementEnabled,
                [branding.DISABLED]: !isPlacementValid
              })}
              disabled={!isPlacementValid}
              onClick={onConfirmBetsClick}
              onKeyPress={onPressButton}
              onKeyDown={(e: KeyboardEvent) => onKeyDownButton(e, !isPlacementEnabled)}
              tabIndex={getButtonTabIndex(tabulation.CONFIRM_BTN_ORDER)}
              ref={confirmBtnRef}
            >
              {getConfirmButtonLabel()}
            </button>
          </>
        )}
      </div>
      {isConfirmBetsEnabled && (
        <div className={styles.confirmCheckbox}>
          <button
            data-tooltip-id="tooltip"
            data-tooltip-html={isEnabled ? unescape(t(translationKey)) : ''}
            onClick={changeConfirm}
            className={classNames(branding.CONFIRM_BETS_CHECKBOX, {
              'cursor-help': isEnabled
            })}
            tabIndex={getButtonTabIndex(tabulation.CONFIRM_CHECKBOX_ORDER)}
            onKeyDown={e => onKeyDownButton(e, true)}
          >
            <i
              className={classNames(
                'fa2 biab_checkbox-icon',
                isConfirmBetsBeforePlacement ? 'fa2-confirmed-yes' : 'fa2-confirmed-no'
              )}
            >
              <span className="path1" />
              <span className="path2 biab_betslip-confirm-bets-checkbox" />
              <span className="path3" />
            </i>
          </button>

          <label className={branding.CONFIRM_NOTIFICATION}>{t('betslip.actions.confirmBets')}</label>
        </div>
      )}
      {isLoggedIn && netOfCommissionBetslip && <NetProfitLoss market={market} />}
      <RGModalMessage />
    </div>
  );
};

export default PlaceBetsActions;
