import { LegacyRef, memo } from 'react';

import { EBetFocusFields } from 'redux/modules/betslip/type';
import { BetTypes, TPrice } from 'types/bets';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';

import PriceInput from '../PriceInput';
import SizeInput from '../SizeInput';

interface PriceAndSizeInputsProps {
  priceValue: string | number | TPrice;
  bestPrice: string | number | TPrice;
  prevPriceValue: string | number | TPrice;
  setPriceValue: (sizeValue: string | number) => void;
  bettingType: string;
  marketType: string;
  marketUnit: string;
  isTakeOffer: boolean;
  lineRangeInfo: TMarketLineRangeInfo | undefined | null;
  priceLadderDescription: TPriceLadderDescription | undefined | null;
  setSizeFocused: () => void;
  setPriceFocused: () => void;
  sizeValue: string | number;
  betType: BetTypes;
  setIsValidSize: (isValid: boolean) => void;
  setErrorMessage: (message: string) => void;
  setSizeValue: (sizeValue: string | number) => void;
  isQuickBets?: boolean;
  setUseQuickBets?: (isUseQuickBets: boolean) => void;
  sizeInputRef: LegacyRef<HTMLInputElement>;
  currentFocus: EBetFocusFields | null;
  setDirtyInput: (isDirty: EBetFocusFields | null) => void;
  dirtyInput?: EBetFocusFields | null;
}

const PriceAndSizeInputs = ({
  priceValue,
  setSizeFocused,
  setPriceFocused,
  sizeValue,
  prevPriceValue,
  dirtyInput,
  setDirtyInput,
  sizeInputRef,
  currentFocus,
  setPriceValue,
  setErrorMessage,
  setSizeValue,
  setIsValidSize,
  bestPrice,
  priceLadderDescription,
  betType,
  bettingType,
  marketType,
  marketUnit,
  isTakeOffer,
  setUseQuickBets,
  isQuickBets,
  lineRangeInfo
}: PriceAndSizeInputsProps) => {
  return (
    <>
      <PriceInput
        setErrorMessage={setErrorMessage}
        betType={betType}
        setPriceValue={setPriceValue}
        priceLadderDescription={priceLadderDescription}
        lineRangeInfo={lineRangeInfo}
        marketType={marketType || ''}
        bettingType={bettingType || ''}
        marketUnit={marketUnit}
        priceValue={priceValue || ''}
        bestPrice={bestPrice || ''}
        prevPriceValue={prevPriceValue}
        isTakeOffer={isTakeOffer}
        currentFocus={currentFocus}
        setFocused={setPriceFocused}
        dirtyInput={dirtyInput}
        setDirtyInput={setDirtyInput}
      />
      <SizeInput
        setErrorMessage={setErrorMessage}
        setIsValidSize={setIsValidSize}
        betType={betType}
        setSizeValue={setSizeValue}
        sizeValue={sizeValue || ''}
        isQuickBets={isQuickBets}
        setUseQuickBets={setUseQuickBets}
        currentFocus={currentFocus}
        setFocused={setSizeFocused}
        sizeInputRef={sizeInputRef}
        dirtyInput={dirtyInput}
        setDirtyInput={setDirtyInput}
      />
    </>
  );
};

export default memo(PriceAndSizeInputs);
