import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { toNumber } from 'lodash';

import { KEY_CODES } from 'constants/betslip';
import { useStakeRegexPattern } from 'hooks/usePrecision';
import { TProfit } from 'types/bets';
import { EInputFieldTypes } from 'types/betslip';

import formStyles from '../../styles.module.scss';

const BetProfit = ({
  betProfit,
  fieldOrder = 0,
  onChanged,
  onBlur,
  onEnterClick
}: {
  betProfit: TProfit;
  fieldOrder?: number;
  onChanged: (profit: TProfit) => void;
  onBlur: () => void;
  onEnterClick: () => void;
}) => {
  const newProfit = (betProfit ? Math.abs(toNumber(betProfit)) : betProfit) as TProfit;
  const [profit, setProfit] = useState<TProfit>(newProfit);

  const pattern = useStakeRegexPattern();

  useEffect(() => {
    if (toNumber(profit) !== toNumber(newProfit)) {
      setProfit(newProfit);
    }
  }, [newProfit]);

  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value
      .trim()
      .replace(/[^0-9.]/g, '')
      .replace(/\.\./g, '.');

    if (changedValue === '' || pattern.test(changedValue)) {
      setProfit(changedValue);
      onChanged(changedValue);
    }
  };

  const keyDownHandler = (e: KeyboardEvent) => {
    if (e.key === KEY_CODES.ENTER) {
      e.preventDefault();
      onEnterClick();
    }
  };

  return (
    <div>
      <input
        className={formStyles.inputField}
        type="text"
        value={profit ?? ''}
        onChange={changeHandler}
        onKeyDown={keyDownHandler}
        onBlur={onBlur}
        data-field-type={EInputFieldTypes.PROFIT}
        tabIndex={fieldOrder}
      />
    </div>
  );
};

export default BetProfit;
