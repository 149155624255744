import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings, getUserAccountSettingsBetSlipPlacement } from 'redux/modules/user/selectors';
import { SettingsBettingInlinePlacementType } from 'types/navigation';

import styles from './styles.module.scss';

const PlacementSetting = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isAccountSettings = useSelector(getIsUserAccountSettings);
  const settingsBetSlipPlacement = useSelector(getUserAccountSettingsBetSlipPlacement);

  const [inlinePlacementType, setInlinePlacementType] = useState<SettingsBettingInlinePlacementType>(
    SettingsBettingInlinePlacementType.DEFAULT
  );

  useEffect(() => {
    if (isAccountSettings) {
      if (inlinePlacementType === SettingsBettingInlinePlacementType.DEFAULT) {
        if (settingsBetSlipPlacement) {
          setInlinePlacementType(SettingsBettingInlinePlacementType.BETSLIP);
        } else {
          setInlinePlacementType(SettingsBettingInlinePlacementType.MIDDLE_SECTION);
        }
      }
    }
  }, [isAccountSettings]);

  const handleChangePlacementType = (newType: SettingsBettingInlinePlacementType) => {
    setInlinePlacementType(newType);

    if (isAccountSettings) {
      dispatch(
        setSettings({
          betslipPlacement: newType === SettingsBettingInlinePlacementType.BETSLIP
        })
      );
    }
  };

  return (
    <div className={styles.inlinePlacement}>
      <span>{t('account.settings.betting.inlinePlacement')}</span>
      <div className={styles.inlinePlacement__item}>
        <input
          type="radio"
          id="usingBetslip"
          name="inlinePlacement"
          value="betslip"
          className={styles.inlinePlacement__radio}
          checked={inlinePlacementType === SettingsBettingInlinePlacementType.BETSLIP}
          onChange={() => handleChangePlacementType(SettingsBettingInlinePlacementType.BETSLIP)}
        />
        <label htmlFor="usingBetslip" className={styles.inlinePlacement__label}>
          {t('account.settings.betting.usingBetslip')}
        </label>
      </div>
      <div className={styles.inlinePlacement__item}>
        <input
          type="radio"
          id="middleSection"
          name="inlinePlacement"
          value="middleSection"
          className={styles.inlinePlacement__radio}
          checked={inlinePlacementType === SettingsBettingInlinePlacementType.MIDDLE_SECTION}
          onChange={() => handleChangePlacementType(SettingsBettingInlinePlacementType.MIDDLE_SECTION)}
        />
        <label htmlFor="middleSection" className={styles.inlinePlacement__label}>
          {t('account.settings.betting.middleSection')}
        </label>
      </div>
    </div>
  );
};

export default PlacementSetting;
