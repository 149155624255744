import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { GeneralWebSocketSubscriptionTypes } from 'constants/app';
import { ASIAN_BASE_URL } from 'constants/locations';
import { useDocumentVisible } from 'hooks/useDocumentVisible';
import {
  getCurrentBetsRequestInterval,
  getGeneralWsEnabled,
  getIsAsianViewEnabled,
  getIsCurrentBetsWebSocketEnabled,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { fetchAsianCurrentBets, resetAsianCurrentBets } from 'redux/modules/asianViewCurrentBets';
import { getAsianCurrentBetsLoading } from 'redux/modules/asianViewCurrentBets/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { removeAllSelectedBets } from 'redux/modules/betslip';
import { cleanCurrentBets, fetchCurrentBets } from 'redux/modules/currentBets';
import { getLoading } from 'redux/modules/currentBets/selectors';
import { cleanInlineSelectedBets } from 'redux/modules/inlinePlacement';
import {
  getIsConnectedAsianViewGeneral,
  getIsConnectedGeneral,
  getSubscribeToAsianViewGeneralMessages,
  getSubscribeToGeneralMessages
} from 'redux/modules/webSocket/selectors';

const CurrentBetsInjection = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const currentBetsRequestInterval = useSelector(getCurrentBetsRequestInterval) || '3000';
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const arePropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const currentBetsLoading = useSelector(getLoading);
  const asianCurrentBetsLoading = useSelector(getAsianCurrentBetsLoading);
  const isCurrentBetsWebSocketsEnabled = useSelector(getIsCurrentBetsWebSocketEnabled);
  const timezone = useSelector(getTimezone);
  const language = useSelector(getLanguage);
  const isAsianViewEnabled = useSelector(getIsAsianViewEnabled);
  const isExchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isExchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isConnectedGeneralWebSocket = useSelector(getIsConnectedGeneral);
  const subscribeGeneralWebSocketMessages = useSelector(getSubscribeToGeneralMessages);
  const isConnectedAsianViewGeneralWebSocket = useSelector(getIsConnectedAsianViewGeneral);
  const subscribeAsianViewGeneralWebSocketMessages = useSelector(getSubscribeToAsianViewGeneralMessages);
  const generalWsEnabled = useSelector(getGeneralWsEnabled);

  const currentBetsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const currentBetsLoaderRef = useRef(currentBetsLoading);
  const asianCurrentBetsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const asianCurrentBetsLoaderRef = useRef(asianCurrentBetsLoading);

  const isActiveBrowserTab = useDocumentVisible();

  const isAsianViewPage = location.pathname.includes(ASIAN_BASE_URL);
  const areSportGamesCurrentBetsAvailable = isExchangeSportsEnabled || isExchangeGamesEnabled;
  const isSubscriptionAvailable =
    isLoggedIn && isCurrentBetsWebSocketsEnabled && arePropertiesLoaded && generalWsEnabled;
  const isSportCurrentBetsSubscriptionAvailable =
    isSubscriptionAvailable && !isAsianViewPage && !!subscribeGeneralWebSocketMessages && isConnectedGeneralWebSocket;
  const isAsianViewCurrentBetsSubscriptionAvailable =
    isSubscriptionAvailable &&
    isAsianViewPage &&
    !!subscribeAsianViewGeneralWebSocketMessages &&
    isConnectedAsianViewGeneralWebSocket;
  const isIntervalAvailable =
    isActiveBrowserTab && arePropertiesLoaded && (!generalWsEnabled || !isCurrentBetsWebSocketsEnabled);
  const isSportsGamesIntervalAvailable = isIntervalAvailable && !isAsianViewPage;
  const isAsianViewIntervalAvailable = isIntervalAvailable && isAsianViewPage && isAsianViewEnabled;

  currentBetsLoaderRef.current = currentBetsLoading;
  asianCurrentBetsLoaderRef.current = asianCurrentBetsLoading;

  useEffect(() => {
    if (isSportCurrentBetsSubscriptionAvailable) {
      subscribeGeneralWebSocketMessages({ [GeneralWebSocketSubscriptionTypes.currentBets]: { subscribe: true } });
    }
  }, [isSportCurrentBetsSubscriptionAvailable]);

  useEffect(() => {
    if (isAsianViewCurrentBetsSubscriptionAvailable) {
      subscribeAsianViewGeneralWebSocketMessages({
        [GeneralWebSocketSubscriptionTypes.currentBets]: { subscribe: true }
      });
    }
  }, [isAsianViewCurrentBetsSubscriptionAvailable]);

  useEffect(() => {
    return () => {
      if (isLoggedIn) {
        if (isAsianViewPage) {
          dispatch(resetAsianCurrentBets());
        } else {
          dispatch(cleanCurrentBets());
        }
      }
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      if (isSportsGamesIntervalAvailable && areSportGamesCurrentBetsAvailable) {
        dispatch(fetchCurrentBets());

        currentBetsInterval.current = setInterval(() => {
          if (!currentBetsLoaderRef.current) {
            dispatch(fetchCurrentBets());
          }
        }, parseInt(currentBetsRequestInterval));
      }
    } else {
      dispatch(cleanInlineSelectedBets());
      dispatch(removeAllSelectedBets());
      dispatch(cleanCurrentBets());
    }

    return () => {
      if (currentBetsInterval.current) {
        clearInterval(currentBetsInterval.current);
      }
    };
  }, [
    isLoggedIn,
    timezone,
    language,
    isSportsGamesIntervalAvailable,
    currentBetsRequestInterval,
    areSportGamesCurrentBetsAvailable
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      if (isAsianViewIntervalAvailable) {
        dispatch(fetchAsianCurrentBets());

        asianCurrentBetsInterval.current = setInterval(() => {
          if (!asianCurrentBetsLoaderRef.current) {
            dispatch(fetchAsianCurrentBets());
          }
        }, parseInt(currentBetsRequestInterval));
      }
    } else {
      dispatch(resetAsianCurrentBets());
    }

    return () => {
      if (asianCurrentBetsInterval.current) {
        clearInterval(asianCurrentBetsInterval.current);
      }
    };
  }, [isLoggedIn, timezone, language, isAsianViewIntervalAvailable, currentBetsRequestInterval]);

  return null;
};

export default CurrentBetsInjection;
