import { ReactNode, useEffect } from 'react';
import BodyClassName from 'react-body-classname';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import AsianViewMobileBetsPlaced from 'components/AsianViewMobileBetsPlaced';
import AsianViewQuickBetsPlaced from 'components/AsianViewQuickBetsPlaced';
import BannersContainer from 'components/BannersContainer';
import CashOutHeader from 'components/CashOutHeader';
import Footer from 'components/Footer';
import Header from 'components/Header';
import LeftNavigation from 'components/LeftNavigation';
import { COLLAPSE_NAVIGATION_COOKIE_KEY } from 'components/LeftNavigation/HideMenuButton';
import LoadingWithOverlay from 'components/LoadingWithOverlay';
import LogInPopup from 'components/LogInPopup';
import MobileToolbar from 'components/MobileToolbar';
import PokerStarsFooter from 'components/PokerStarsFooter';
import RightNavigation from 'components/RightNavigation/RightNavigation';
import ScrollableContent from 'components/ScrollableContent';
import Search from 'components/Search';
import SearchInput from 'components/SearchInput';
import SplashScreen from 'components/SplashScreen';
import WelcomeNewUserComponent from 'components/WelcomeNewUserComponent';
import { BannerNames } from 'constants/app';
import { POKER_STAR } from 'constants/icons';
import { ACCOUNT_BASE_URL, CASH_OUT_BASE_URL, GAMES_BASE_URL, MOBILE_LOGIN_BASE_URL } from 'constants/locations';
import { PARAMS_SEARCH_KEY } from 'constants/urlParams';
import useContextualHelp from 'hooks/useContextualHelp';
import useDevice from 'hooks/useDevice';
import BackendContentPage from 'pages/BackendContentPage';
import { setBackendContent } from 'redux/modules/appConfigs';
import {
  getBackendContentPage,
  getBrandingCSSLoaded,
  getDesktopSettingsCollapseSportsBar,
  getIsExchangeSportsEnabled,
  getLanguage,
  getMinSearchQueryLength,
  getMobileSettingsClassicNavigation,
  getNewCustomerCookieName,
  getOperator,
  getPostLoginNotificationEnabled,
  getPropertiesLoading,
  getPropertiesTours,
  getSplashScreenEnabled
} from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { fetchBanner } from 'redux/modules/banners';
import { getIsDisplayedBannerByName } from 'redux/modules/banners/selectors';
import { BannerSections } from 'redux/modules/banners/type';
import { cleanFavorites, fetchFavorites } from 'redux/modules/favorites';
import { CookieNames } from 'types';
import { getBooleanValue, iosBundleRedirect } from 'utils';

import styles from './styles.module.scss';

interface AppLayoutProps {
  /**
   * Layout children
   */
  children: ReactNode;

  /**
   * Should Search component be rendered or not?
   */
  hideSearch?: boolean;

  /**
   * Show or hide sidebars
   */
  hideSideBars?: boolean;

  /**
   * Show or hide banners on mobile
   */
  showBanners?: boolean;

  /***
   * hide navigation
   * ***/
  hideNavigation?: boolean;

  /***
   * hide navigation and sidebar
   * ***/
  noNavigation?: boolean;
}

const AppLayout = ({
  children,
  hideSearch = false,
  hideSideBars = false,
  showBanners = false,
  hideNavigation = false,
  noNavigation = false
}: AppLayoutProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { sportId } = useParams();

  const splashScreenEnabled = useSelector(getSplashScreenEnabled);
  const propertiesLoading = useSelector(getPropertiesLoading);
  const backendContentPage = useSelector(getBackendContentPage);
  const collapseSportsBar = useSelector(getDesktopSettingsCollapseSportsBar);
  const language = useSelector(getLanguage);
  const newCustomerCookieName = useSelector(getNewCustomerCookieName);
  const tours = useSelector(getPropertiesTours);
  const postLoginNotificationEnabled = useSelector(getPostLoginNotificationEnabled);
  const minSearchQueryLength = useSelector(getMinSearchQueryLength);
  const operator = useSelector(getOperator);
  const classicNavigation = useSelector(getMobileSettingsClassicNavigation);
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isBrandingCSSLoaded = useSelector(getBrandingCSSLoaded);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const isBannerDisplayed = useSelector(
    getIsDisplayedBannerByName(
      location.pathname === '/' || location.pathname === '/login' ? BannerNames.homePage : BannerNames.subPageContent
    )
  );

  const [cookies] = useCookies([
    CookieNames.BIAB_LOGIN_POP_UP_SHOWN,
    COLLAPSE_NAVIGATION_COOKIE_KEY,
    newCustomerCookieName
  ]);

  const { isMobile, isDesktop, currentDevice } = useDevice();

  const isLoginPopupDisabled = cookies[CookieNames.BIAB_LOGIN_POP_UP_SHOWN] !== 'true';
  const isLoginPopupEnabled = isLoggedIn && postLoginNotificationEnabled && isLoginPopupDisabled;

  const bannerType =
    location.pathname === '/' || location.pathname === '/login'
      ? BannerSections.HOME_PAGE_BANNER
      : BannerSections.SUB_PAGE_CONTENT_BANNER;
  const isGamesPage = location.pathname.startsWith(GAMES_BASE_URL);
  const isLeftPanelClosed = cookies[COLLAPSE_NAVIGATION_COOKIE_KEY] === 'true' && collapseSportsBar;
  const query = searchParams.get(PARAMS_SEARCH_KEY);
  const showSideBars = isDesktop && !hideSideBars;
  const isSearchVisible =
    !hideSearch &&
    isDesktop &&
    location.search.includes(PARAMS_SEARCH_KEY) &&
    !!query &&
    query.length >= minSearchQueryLength;
  const isTodayCardMobileView = sportId?.startsWith('tc') && !isDesktop;
  const showBanner =
    (isDesktop || showBanners) && !backendContentPage && !isGamesPage && !isTodayCardMobileView && isBannerDisplayed;
  const showChildren = !isDesktop || !query || query.length < minSearchQueryLength;
  const showPokerstarFooter = operator === POKER_STAR && iosBundleRedirect;
  const isDisableScroll = isDesktop && (showBanners || isGamesPage) && !showPokerstarFooter;
  const newUserCookie = cookies[newCustomerCookieName];
  const hasWelcomePopUp = newUserCookie && getBooleanValue(newUserCookie) && isLoggedIn && !!tours.length;
  const isAccountPage = location.pathname.includes(ACCOUNT_BASE_URL);
  const isLoginPage = location.pathname.includes(MOBILE_LOGIN_BASE_URL);
  const hasClassicNavigation = classicNavigation && !isAccountPage && !isLoginPage;

  useContextualHelp();

  useEffect(() => {
    if (language) {
      dispatch(
        fetchBanner({
          name: BannerNames.homePage,
          platform: currentDevice,
          section: BannerSections.HOME_PAGE_BANNER,
          locale: language
        })
      );
      dispatch(
        fetchBanner({
          name: BannerNames.subPageContent,
          platform: currentDevice,
          section: BannerSections.SUB_PAGE_CONTENT_BANNER,
          locale: language
        })
      );
      if (!isDesktop) {
        dispatch(
          fetchBanner({
            name: BannerNames.badge,
            platform: Devices.MOBILE,
            section: BannerSections.BADGE_BANNER,
            locale: language
          })
        );
      }
    }
  }, [language, currentDevice]);

  useEffect(() => {
    if (backendContentPage) {
      dispatch(setBackendContent(''));
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(isLoggedIn && exchangeSportsEnabled ? fetchFavorites() : cleanFavorites());
  }, [dispatch, isLoggedIn, language, exchangeSportsEnabled]);

  if (splashScreenEnabled) {
    return <SplashScreen />;
  }

  return (
    <>
      {propertiesLoading || !isBrandingCSSLoaded ? (
        <LoadingWithOverlay />
      ) : (
        <>
          <Header isMobile={isMobile} />
          {backendContentPage ? (
            <div className={styles.contentWrapScroll}>
              <BackendContentPage />
              {showPokerstarFooter ? <PokerStarsFooter /> : <Footer isSearchVisible={isSearchVisible} />}
            </div>
          ) : (
            <>
              {!noNavigation && (
                <>
                  <div
                    className={classNames(styles.wrapper, {
                      [styles.noBottomPadding]: !hasClassicNavigation && !isDesktop,
                      [styles.wrapper__withoutSideBars]: !showSideBars,
                      [styles.wrapper__classicNavigation]: hasClassicNavigation,
                      [styles.wrapper__withoutNavigation]: showSideBars && hideNavigation,
                      [styles.wrapperWithCollapsedLeftBar]:
                        isLeftPanelClosed && isDesktop && !location.pathname.startsWith(GAMES_BASE_URL)
                    })}
                  >
                    {showSideBars && !hideNavigation && <LeftNavigation />}
                    <main className={styles.content}>
                      <BodyClassName className={classNames({ disableScroll: isDisableScroll })} />
                      <div className={classNames('biab_body', styles.fullHeight)}>
                        {showBanner && <BannersContainer bannerType={bannerType} />}
                        {!hideSearch && isDesktop && <SearchInput />}
                        {location.pathname.includes(CASH_OUT_BASE_URL) && <CashOutHeader />}
                        <ScrollableContent isSearchVisible={isSearchVisible}>
                          <div className={styles.contentWrapScroll}>
                            <div>
                              {isSearchVisible && <Search />}
                              {showChildren && children}
                              {!showPokerstarFooter && <Footer isSearchVisible={isSearchVisible} />}
                            </div>
                          </div>
                        </ScrollableContent>
                      </div>
                    </main>
                    {showSideBars && <RightNavigation />}
                  </div>
                  {showPokerstarFooter && <PokerStarsFooter />}
                </>
              )}
            </>
          )}

          {noNavigation && !backendContentPage && (
            <div
              className={classNames(styles.wrapperNoNav, { [styles.wrapper__classicNavigation]: hasClassicNavigation })}
            >
              <BodyClassName className={classNames({ disableScroll: isDesktop })} />
              <div className={classNames('biab_body', styles.fullHeight, styles.contentWrapWithoutScroll__content)}>
                {children}
              </div>
            </div>
          )}
          {hasClassicNavigation && !isDesktop && <MobileToolbar />}
          {!isLoginPopupEnabled && hasWelcomePopUp && <WelcomeNewUserComponent />}
          <LogInPopup />
          <AsianViewQuickBetsPlaced />
          <AsianViewMobileBetsPlaced />
        </>
      )}
    </>
  );
};

export default AppLayout;
