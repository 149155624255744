import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { BETSLIP_LIABILITY } from 'constants/tooltip';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useTooltip from 'hooks/useTooltip';

import styles from './styles.module.scss';

interface LiabilityProps {
  liability: number | string;
}

function Liability({ liability }: LiabilityProps) {
  const { t } = useTranslation();
  const { translationKey, isEnabled } = useTooltip(BETSLIP_LIABILITY);

  const { noFormattedAmount: formattedLiability } = useFormatCurrency(liability || 0, '', {
    noRounding: true,
    isCheckIndian: true
  });

  return (
    <div className="biab_liability">
      <div className={'biab_label'}>
        {t('betslip.labels.liability')}
        {isEnabled && (
          <i
            className={classNames('fa2 fa2-info-circle tooltip-icon cursor-help', styles.icon)}
            data-tooltip-id={'tooltip'}
            data-tooltip-html={unescape(t(translationKey))}
          >
            <span className="path1 " />
            <span className="path2" />
          </i>
        )}
      </div>
      <div className="biab_value">
        <strong>
          <a className="biab_mobile-tooltip biab_no-styles" />
          <span>{formattedLiability}</span>
        </strong>
      </div>
    </div>
  );
}

export default Liability;
