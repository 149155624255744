import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  getIsAmericanDateFormatEnabled,
  getTimezone,
  getTimezoneCookieEnabled
} from 'redux/modules/appConfigs/selectors';
import { applyTimezone, parseDateToTime } from 'utils/date';

type GetLongDateFormatParams = {
  time: number;
  divider?: string;
  dateDivider?: string;
  hasWeekDay?: boolean;
  hasYear?: boolean;
  hasTime?: boolean;
  /** Display time then date */
  isTimeFirst?: boolean;
  isShortYear?: boolean;
  isMonthNumeric?: boolean;
  isAsian?: boolean;
  isAsianYearLast?: boolean;
  applyAsianAmericanFormat?: boolean;
  /** Add coma in american date format for updated Responsible Betting Tools */
  hasComma?: boolean;
};

export const getShortMonthKey = (date: Date) => {
  const month = date.getMonth();

  return `dates.shortMonth.${month}`;
};

export const getShortDayKey = (date: Date) => {
  const day = date.getDay();

  return `dates.shortDay.${day}`;
};

const formatDate = (value: number) => {
  return value < 10 ? '0' + value : value;
};

export default function useLongDateFormat({
  time,
  divider = ' ',
  dateDivider = '-',
  hasYear = false,
  hasWeekDay = false,
  hasTime = true,
  isTimeFirst = false,
  isShortYear = false,
  isMonthNumeric = false,
  isAsian = false,
  isAsianYearLast = false,
  applyAsianAmericanFormat = false,
  hasComma = false
}: GetLongDateFormatParams) {
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const americanDateFormatEnabled = useSelector(getIsAmericanDateFormatEnabled);

  const currentDate = applyTimezone(new Date(time), timezone, timezoneCookieEnabled);

  const shortDay = hasWeekDay ? t(getShortDayKey(currentDate)) : '';
  const shortMonthKey = getShortMonthKey(currentDate);
  const shortMonth = isMonthNumeric ? formatDate(currentDate.getMonth() + 1) : t(shortMonthKey);
  const date = currentDate.getDate();
  const hoursAndMinutes = hasTime ? parseDateToTime(currentDate) : '';
  let year;

  if (isShortYear) {
    year = hasYear ? new Date(time).getFullYear().toString().substring(2, 4) : '';
  } else {
    year = hasYear ? new Date(time).getFullYear() : '';
  }

  if (isAsian) {
    if (applyAsianAmericanFormat) {
      if (isTimeFirst) {
        if (isAsianYearLast) {
          return americanDateFormatEnabled
            ? `${hoursAndMinutes}${divider}${shortMonth}${dateDivider}${formatDate(date)}${dateDivider}${year}`
            : `${hoursAndMinutes}${divider}${formatDate(date)}${dateDivider}${shortMonth}${dateDivider}${year}`;
        }

        return americanDateFormatEnabled
          ? `${hoursAndMinutes}${divider}${year}${dateDivider}${shortMonth}${dateDivider}${formatDate(date)}`
          : `${hoursAndMinutes}${divider}${year}${dateDivider}${formatDate(date)}${dateDivider}${shortMonth}`;
      }

      if (isAsianYearLast) {
        return americanDateFormatEnabled
          ? `${shortMonth}${dateDivider}${formatDate(date)}${dateDivider}${year}${divider}${hoursAndMinutes}`
          : `${formatDate(date)}${dateDivider}${shortMonth}${dateDivider}${year}${divider}${hoursAndMinutes}`;
      }

      return americanDateFormatEnabled
        ? `${year}${dateDivider}${shortMonth}${dateDivider}${formatDate(date)}${divider}${hoursAndMinutes}`
        : `${year}${dateDivider}${formatDate(date)}${dateDivider}${shortMonth}${divider}${hoursAndMinutes}`;
    }

    const beforeMonth = isAsianYearLast ? formatDate(date) : year;
    const afterMonth = isAsianYearLast ? year : formatDate(date);

    if (isTimeFirst) {
      return `${hoursAndMinutes}${divider}${beforeMonth}${dateDivider}${shortMonth}${dateDivider}${afterMonth}`;
    }

    return `${beforeMonth}${dateDivider}${shortMonth}${dateDivider}${afterMonth}${divider}${hoursAndMinutes}`;
  }

  if (isTimeFirst) {
    /**
     *          US format        hh:mm MMM-DD-YY
     *          European         hh:mm DD-MMM-YY
     */
    return americanDateFormatEnabled
      ? `${hoursAndMinutes}${divider}${shortMonth}${dateDivider}${formatDate(date)}${dateDivider}${year}`
      : `${hoursAndMinutes}${divider}${formatDate(date)}${dateDivider}${shortMonth}${dateDivider}${year}`;
  }

  return americanDateFormatEnabled
    ? `${shortDay} ${shortMonth} ${date}${
        americanDateFormatEnabled && hasComma && ','
      } ${year}${divider}${hoursAndMinutes}`
    : `${shortDay} ${date} ${shortMonth} ${year}${divider}${hoursAndMinutes}`;
}
