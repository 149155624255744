import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { inlinePlacementBranding as branding } from 'constants/branding';
import { LINE_MARKET_ODDS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { BetTypes } from 'types/bets';
import { ButtonActionTypes, ESizes, InputTypes } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { isPartiallyMatched } from 'utils/betslip';

import InlinePlacementButton from '../InlinePlacementButton';

import styles from './styles.module.scss';

interface IInlinePlacedBetMessage {
  bet: TCurrentBet;
  componentSize: ESizes;
  onClose: () => void;
  onCancelPlacedBetBtnHandler: () => void;
  onEditPlacedBetBtnHandler: () => void;
}

const InlinePlacedBetMessage = ({
  bet,
  componentSize,
  onClose,
  onCancelPlacedBetBtnHandler,
  onEditPlacedBetBtnHandler
}: IInlinePlacedBetMessage) => {
  const { t } = useTranslation();

  const isPNCEnabled: boolean = useSelector(getPNCEnabledSetting);
  const labelPrefix = isPNCEnabled ? 'pnc.' : '';

  let betStateDetails = '';
  let betStatus = '';
  let betCssClass = '';
  let odds;

  const sizeMatched = +(bet.sizeMatched || 0);
  const sizeRemaining = +(bet.sizeRemaining || 0);
  const sizeCancelled = +(bet.sizeCancelled || 0);
  const sizePlaced = bet && +(bet.sizePlaced || 0);
  const currency = (bet && bet.currency) || '';
  const isLineMarket = bet && bet.bettingType === BettingType.LINE;
  const side = (bet.side || bet.type || '').toLowerCase();

  switch (true) {
    case sizeMatched === 0 && sizeRemaining > 0:
      betStateDetails = 'inlinePlacement.messages.betUnmatched';
      betStatus = BetsStatusesTypes.UNMATCHED;
      betCssClass = branding.UNMATCHED;
      odds = bet.price;
      break;
    case sizeMatched > 0 && sizeRemaining > 0:
      betStateDetails = `inlinePlacement.messages.${side}BetPartiallyMatched`;
      odds = bet.averagePriceRounded;
      betStatus = BetsStatusesTypes.MATCHED;
      betCssClass = branding.PARTIALLY_MATCHED;
      break;
    case sizeMatched > 0 && sizeRemaining === 0:
      betStateDetails = `${labelPrefix}inlinePlacement.messages.${side}BetMatched`;
      betStatus = BetsStatusesTypes.MATCHED;
      betCssClass = branding.MATCHED;
      odds = bet.averagePriceRounded;
      break;
    case sizeMatched === 0 && sizeRemaining === 0 && sizeCancelled > 0:
      betStateDetails = 'inlinePlacement.messages.betCancelled';
      betStatus = BetsStatusesTypes.CANCELLED;
      betCssClass = branding.CANCELLED;
      odds = bet.price;
      break;
    case sizeMatched === 0 &&
      sizeRemaining === 0 &&
      sizeCancelled === 0 &&
      bet.offerState === BetsStatusesTypes.MATCHED:
      betStateDetails = `${labelPrefix}inlinePlacement.messages.${side}BetMatched`;
      betStatus = BetsStatusesTypes.MATCHED;
      betCssClass = branding.MATCHED;
      odds = bet.averagePriceRounded;
      break;
    case sizeMatched === 0 && sizeRemaining === 0 && sizeCancelled === 0 && bet.offerState === BetsStatusesTypes.PLACED:
      betStateDetails = `inlinePlacement.messages.${side}BetUnmatched`;
      betStatus = BetsStatusesTypes.UNMATCHED;
      betCssClass = branding.UNMATCHED;
      odds = bet.price;
      break;
  }

  const { noFormattedAmount: liabilityFormatted } = useFormatCurrency(bet.liability ?? 0, currency, {
    noCommas: true,
    noRounding: true
  });
  const { noFormattedAmount: profitFormatted } = useFormatCurrency(bet.profitNet ?? 0, currency, {
    noCommas: true,
    noRounding: true
  });

  const detailedText = t(betStateDetails, {
    sizePlaced: useFormatCurrency(sizePlaced, currency, { noCommas: true, isCheckIndian: true, noRounding: true })
      .noFormattedAmount,
    sizeMatched: useFormatCurrency(sizeMatched, currency, { noCommas: true, isCheckIndian: true, noRounding: true })
      .noFormattedAmount,
    remaining: useFormatCurrency(sizeRemaining, currency, { noCommas: true, isCheckIndian: true, noRounding: true })
      .noFormattedAmount,
    sizeRemaining: useFormatCurrency(sizeRemaining, currency, {
      noCommas: true,
      isCheckIndian: true,
      noRounding: true
    }).noFormattedAmount,
    odds: isLineMarket ? LINE_MARKET_ODDS : odds,
    profit: side.toUpperCase() === BetTypes.LAY ? liabilityFormatted : profitFormatted,
    liability: liabilityFormatted
  });

  const [focusedElement, setFocusedElement] = useState<ButtonActionTypes>(ButtonActionTypes.EDIT);

  const onTabNavigate = (type: InputTypes | ButtonActionTypes) => {
    switch (type) {
      case ButtonActionTypes.EDIT:
        setFocusedElement(ButtonActionTypes.CANCEL_UNMATCHED);
        break;
      case ButtonActionTypes.CANCEL_UNMATCHED:
        setFocusedElement(ButtonActionTypes.EDIT);
        break;
    }
  };

  const isUnmatchedBet = betStatus === BetsStatusesTypes.UNMATCHED;
  const isPartiallyMatchedBet = betStatus === BetsStatusesTypes.MATCHED && isPartiallyMatched(bet);
  const isMatchedBet = betStatus === BetsStatusesTypes.MATCHED && !isPartiallyMatchedBet;
  const isCancelledBet = betStatus === BetsStatusesTypes.CANCELLED;

  return (
    <div className={classNames(branding.PLACED_BET, betCssClass)}>
      {(isMatchedBet || isCancelledBet) && (
        <div className={styles.placedBet}>
          <span dangerouslySetInnerHTML={{ __html: detailedText }} />
          <button className={styles.placedBet__closeBtn} onClick={onClose}>
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>
      )}
      {(isUnmatchedBet || isPartiallyMatchedBet) && (
        <div className={styles.placedBet}>
          <div dangerouslySetInnerHTML={{ __html: detailedText }} />
          <div className={styles.placedBet__buttons}>
            <InlinePlacementButton
              buttonType={ButtonActionTypes.CANCEL_UNMATCHED}
              componentSize={componentSize}
              onClickCallback={onCancelPlacedBetBtnHandler}
              onTabNavigate={onTabNavigate}
              isFocused={focusedElement === ButtonActionTypes.CANCEL_UNMATCHED}
            />
            <InlinePlacementButton
              buttonType={ButtonActionTypes.EDIT}
              componentSize={componentSize}
              onClickCallback={onEditPlacedBetBtnHandler}
              onTabNavigate={onTabNavigate}
              isFocused={focusedElement === ButtonActionTypes.EDIT}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InlinePlacedBetMessage;
