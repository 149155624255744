import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { getBalanceWsEnabled, getGeneralWsEnabled, getIsAsianViewEnabled } from 'redux/modules/appConfigs/selectors';
import { setIsLoggedIn, setLoading as setAuthLoading } from 'redux/modules/auth';

import {
  failureFetchBalance,
  failureFetchUserInfo,
  failureSetSettings,
  fetchBalance,
  fetchUserInfo,
  setSettings,
  successFetchBalance,
  successFetchUserInfo,
  successSetSettings
} from './index';
import { getAccountSettings } from './selectors';
import { BalanceResponse, TSetSettingsResponse, TUserAccountSettings, TUserInfo } from './type';

export function* fetchUserInfoWorker(action: ReturnType<typeof fetchUserInfo>) {
  try {
    const asianViewEnabled: boolean = yield select(getIsAsianViewEnabled);
    const balanceWsEnabled: boolean = yield select(getBalanceWsEnabled);
    const generalWsEnabled: boolean = yield select(getGeneralWsEnabled);
    const userInfo: TUserInfo = yield call(api.user.info, asianViewEnabled);
    yield put(successFetchUserInfo(userInfo));
    yield put(setAuthLoading(false));

    if (action?.payload) {
      if (userInfo?.username) {
        yield put(setIsLoggedIn(true));

        if (!generalWsEnabled || !balanceWsEnabled) {
          yield call(fetchBalanceWorker);
        }
      } else {
        yield put(setIsLoggedIn(false));
      }
    }
  } catch (error: any) {
    yield put(setAuthLoading(false));
    yield put(failureFetchUserInfo(error.message));
  }
}

export function* fetchBalanceWorker() {
  try {
    const asianViewEnabled: boolean = yield select(getIsAsianViewEnabled);
    const result: BalanceResponse = yield call(api.user.balance, asianViewEnabled);
    yield put(successFetchBalance({ balances: result, isWebSocketResponse: false }));
  } catch (error: any) {
    yield put(failureFetchBalance(error.message));
  }
}

export function* setSettingsWorker(action: ReturnType<typeof setSettings>) {
  try {
    const currentAccountSettings: TUserAccountSettings = yield select(getAccountSettings);
    const response: TSetSettingsResponse = yield call(api.user.settings, {
      ...currentAccountSettings,
      ...action.payload
    });
    yield put(successSetSettings());

    if (response?.status === 'OK') {
      yield call(fetchUserInfoWorker, { type: fetchUserInfo.type, payload: false });
    }
  } catch (error: any) {
    yield put(failureSetSettings(error.message));
  }
}

export default function* saga() {
  yield all([
    takeLatest(fetchUserInfo.type, fetchUserInfoWorker),
    takeLatest(fetchBalance.type, fetchBalanceWorker),
    takeLatest(setSettings.type, setSettingsWorker)
  ]);
}
