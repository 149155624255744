import { useState } from 'react';

import { ResponsibleBettingSection } from 'components/ResponsibleBetting/components/ResponsibleBettingSection';
import { ResponsibleBettingTab } from 'types/responsibleBetting';

import styles from './styles.module.scss';

interface ResponsibleBettingTimeLimitProps {
  tab: ResponsibleBettingTab;
}

const ResponsibleBettingTimeLimit = ({ tab }: ResponsibleBettingTimeLimitProps) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <ResponsibleBettingSection tab={tab} onClick={() => setIsOpened(true)} isOpened={isOpened}>
      {/* Here will be the component responsible for the tool logic */}
    </ResponsibleBettingSection>
  );
};

export default ResponsibleBettingTimeLimit;
