import { reduce } from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import api from 'redux/api/methods';
import { setPlacedBets, setUpdatedBets } from 'redux/modules/betslip';
import { TPlacedBetsByMarket } from 'redux/modules/placement/type';

import {
  cancelAllBets,
  cancelBets,
  editBets,
  failureCancelAllBets,
  failureCancelBets,
  failureEditBets,
  failurePlaceBets,
  placeBets,
  successCancelAllBets,
  successCancelBets,
  successEditBets,
  successPlaceBets
} from './index';

function* getPlaceBetsWorker(action: ReturnType<typeof placeBets>) {
  try {
    const response: TPlacedBetsByMarket = yield call<any>(api.placement.placeBets, action.payload.data);

    const offerIds = reduce(response, (result: number[], marketData) => result.concat(marketData.offerIds || []), []);

    yield put(successPlaceBets(response));

    yield put(setPlacedBets(offerIds));

    if (action.payload.successCallback) {
      action.payload.successCallback(offerIds);
    }
  } catch (error: any) {
    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }

    yield put(failurePlaceBets(error));
  }
}

function* getCancelBetsWorker(action: ReturnType<typeof cancelBets>) {
  try {
    const response: TPlacedBetsByMarket = yield call<any>(api.placement.cancelBets, action.payload.data);

    yield put(successCancelBets(response));

    if (action.payload.successCallback) {
      action.payload.successCallback();
    }
  } catch (error: any) {
    yield put(failureCancelBets(error));

    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }
  }
}

function* getEditBetsWorker(action: ReturnType<typeof editBets>) {
  try {
    const response: TPlacedBetsByMarket = yield call<any>(api.placement.editBets, action.payload.data);

    yield put(successEditBets(response));

    const isSuccessUpdate = Object.entries(response)[0][1].status === 'OK';

    if (isSuccessUpdate) {
      yield put(setUpdatedBets(action.payload.offersIds ?? []));

      if (action.payload.successCallback) {
        action.payload.successCallback();
      }
    }
  } catch (error: any) {
    yield put(failureEditBets(error));

    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }
  }
}

function* getCancelAllBetsWorker(action: ReturnType<typeof cancelAllBets>) {
  try {
    const response: number = yield call(api.placement.cancelAllBets, action.payload.data);

    yield put(successCancelAllBets(response));

    if (action.payload.successCallback) {
      action.payload.successCallback(response);
    }
  } catch (error: any) {
    yield put(failureCancelAllBets(error));

    if (action.payload.errorCallback) {
      action.payload.errorCallback(error);
    }
  }
}

export default function* saga() {
  yield all([
    takeEvery(placeBets.type, getPlaceBetsWorker),
    takeEvery(editBets.type, getEditBetsWorker),
    takeEvery(cancelBets.type, getCancelBetsWorker),
    takeLatest(cancelAllBets.type, getCancelAllBetsWorker)
  ]);
}
