import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import GameScreen from 'components/GameScreen';
import ResultsScreen from 'components/ResultsScreen';
import RotateDevice from 'components/RotateDevice';
import SingleGamePageHeader from 'components/SingleGamePageHeader';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import useWindowSize from 'hooks/useWindowSize';
import { getAppDevice, getLanguage, getTimezone } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchCurrentGameData, resetGameData } from 'redux/modules/games';
import { getSelectedGamePage } from 'redux/modules/games/selectors';
import { EGamePages } from 'redux/modules/games/type';

import GamePageRequestIntervalInjection from './components/GamePageRequestIntervalInjection/GamePageRequestIntervalInjection';

const GamePage = () => {
  const dispatch = useDispatch();
  const { gameId = '' } = useParams();

  const selectedGamePage = useSelector(getSelectedGamePage);
  const language = useSelector(getLanguage);
  const device = useSelector(getAppDevice);
  const timezone = useSelector(getTimezone);

  const { isLandscape } = useWindowSize(100);
  const { target, targetHeight, addedHeight } = useAddHeightForFooter();

  const isDesktop = device === Devices.DESKTOP;

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (gameId && (isLandscape || isDesktop)) {
      dispatch(fetchCurrentGameData(gameId));

      return () => {
        dispatch(resetGameData());
      };
    }
  }, [gameId, language, timezone, isDesktop, isLandscape]);

  useEffect(() => {
    dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight }));
  }, [targetHeight]);

  return (
    <>
      <GamePageRequestIntervalInjection isDesktop={isDesktop} isLandscape={isLandscape} />
      <div ref={target}>
        {!isLandscape && !isDesktop && <RotateDevice />}
        {(isLandscape || isDesktop) && (
          <>
            <SingleGamePageHeader />
            {selectedGamePage === EGamePages.GAME ? <GameScreen /> : <ResultsScreen />}
          </>
        )}
      </div>
      {addedHeight > 0 && <div style={{ height: addedHeight }} />}
    </>
  );
};

export default GamePage;
