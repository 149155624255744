import { ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { GAMES_BASE_URL } from 'constants/locations';
import { DEFAULT_MAX_PRICE, DEFAULT_MIN_PRICE, ODDS_REGEX_PATTERN } from 'constants/placement';
import usePriceValidation from 'hooks/usePriceValidation';
import { getMobileSettingsVirtualKeyboardBetslip, getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { EBetFocusFields } from 'redux/modules/betslip/type';
import { BetTypes, TPrice } from 'types/bets';
import { EActionTypes } from 'types/betslip';
import { TMarketLineRangeInfo, TPriceLadderDescription } from 'types/markets';
import { isLineBettingType, valueToNumber } from 'utils/betslip';
import { formatFloatNumber } from 'utils/betValidation';
import { getMarketUnitTranslation } from 'utils/market';
import { recalculatePriceByStep } from 'utils/price';

import styles from './styles.module.scss';

interface PriceInputProps {
  priceValue: string | number | TPrice;
  bestPrice: string | number | TPrice;
  prevPriceValue: string | number | TPrice;
  betType: BetTypes;
  setErrorMessage: (message: string) => void;
  setPriceValue: (sizeValue: string | number) => void;
  bettingType: string;
  marketType: string;
  marketUnit: string;
  isTakeOffer: boolean;
  lineRangeInfo: TMarketLineRangeInfo | undefined | null;
  priceLadderDescription: TPriceLadderDescription | undefined | null;
  setFocused: () => void;
  currentFocus: EBetFocusFields | null;
  setDirtyInput: (dirtyInput: EBetFocusFields | null) => void;
  dirtyInput?: EBetFocusFields | null;
}

function PriceInput({
  priceValue,
  bestPrice,
  prevPriceValue,
  betType,
  setPriceValue,
  setErrorMessage,
  lineRangeInfo,
  bettingType,
  marketType,
  marketUnit,
  isTakeOffer,
  priceLadderDescription,
  setFocused,
  currentFocus,
  setDirtyInput,
  dirtyInput
}: PriceInputProps) {
  const { t } = useTranslation();
  const location = useLocation();

  const virtualKeyboardBetslip = useSelector(getMobileSettingsVirtualKeyboardBetslip);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);

  const isGamesPage = location.pathname.includes(GAMES_BASE_URL);
  const isReadOnly = isPNCEnabled && !isGamesPage;

  const isPriceUp = prevPriceValue && bestPrice && +bestPrice > 0 && bestPrice > prevPriceValue;
  const isPriceDown = prevPriceValue && bestPrice && +bestPrice > 0 && bestPrice < prevPriceValue;
  const isLineMarket = isLineBettingType(bettingType);
  const minValue = isLineMarket ? lineRangeInfo?.minUnitValue ?? DEFAULT_MIN_PRICE : DEFAULT_MIN_PRICE;
  const maxValue = isLineMarket ? lineRangeInfo?.maxUnitValue ?? DEFAULT_MAX_PRICE : DEFAULT_MAX_PRICE;
  const isMinusBtnEnabled = +(priceValue || 0) > minValue;
  const isPlusBtnEnabled = +(priceValue || 0) < maxValue;
  const unitsTranslation = t(getMarketUnitTranslation(marketUnit.toLocaleLowerCase()));
  const placeholder = isLineMarket
    ? t('betslip.labels.units.back', { UNITS: unitsTranslation })
    : t('betslip.labels.price.back');

  const { setValue, isValid, validValue, errorMessage } = usePriceValidation({
    price: priceValue,
    betType,
    bettingType,
    marketType,
    lineRangeInfo,
    priceLadderDescription
  });

  const changePriceValue = (type: EActionTypes) => {
    const updatedPrice = recalculatePriceByStep({
      type,
      price: priceValue,
      marketType,
      bettingType,
      lineRangeInfo,
      priceLadderDescription
    });

    setPriceValue(formatFloatNumber(updatedPrice));
    setFocused();
    setErrorMessage('');
  };

  useEffect(() => {
    if (dirtyInput && dirtyInput === currentFocus) {
      onBlur();
      setDirtyInput(null);
    }
  }, [currentFocus, dirtyInput]);

  useEffect(() => {
    setValue(priceValue);
  }, [priceValue]);

  const onBlur = () => {
    if (errorMessage && errorMessage.message && priceValue) {
      if (!isValid) {
        setErrorMessage(errorMessage.message);
        if (typeof validValue !== 'undefined') {
          setPriceValue(validValue.toString());
        }
      }
    }
  };

  const onFocus = () => {
    setFocused();
    onBlur();
  };

  const onPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const changedValue = valueToNumber(event.target.value);

    if (changedValue === '' || ODDS_REGEX_PATTERN.test(changedValue)) {
      setPriceValue(changedValue);
    }
    setValue(priceValue);
  };

  return (
    <div className={classNames('biab_custom-number-holder biab_price-holder', { 'biab_pnc-price-holder': isReadOnly })}>
      {isReadOnly ? (
        <span className={classNames('biab_pnc-price', styles.pncPrice)}>
          {isTakeOffer && isPriceUp && <i className="fa2 biab_odds-arrow fa2-sort-up" aria-hidden="true" />}
          {isTakeOffer && isPriceDown && <i className="fa2 biab_odds-arrow fa2-sort-down" aria-hidden="true" />}
          <span className="js-odds">{+(priceValue || '')} </span>
        </span>
      ) : (
        <>
          <span className="biab_input-group-btn">
            <button
              disabled={!isMinusBtnEnabled}
              onClick={() => changePriceValue(EActionTypes.SUBSTR)}
              type="button"
              className={classNames('biab_btn biab_btn-danger biab_btn-number', { biab_disabled: !isMinusBtnEnabled })}
              data-type="minus"
            >
              <i className="fa2 fa2-minus" aria-hidden="true" />
            </button>
          </span>
          <input
            className="biab_custom-number biab_price"
            name="price"
            onBlur={onBlur}
            onChange={onPriceChange}
            onFocus={onFocus}
            value={priceValue || ''}
            type="text"
            lang="en-US"
            inputMode="decimal"
            data-min={minValue}
            data-max={maxValue}
            autoComplete="false"
            placeholder={placeholder}
            readOnly={virtualKeyboardBetslip}
          />
          <span className="biab_input-group-btn">
            <button
              disabled={!isPlusBtnEnabled}
              onClick={() => changePriceValue(EActionTypes.ADD)}
              type="button"
              className={classNames('biab_btn biab_btn-danger biab_btn-number', { biab_disabled: !isPlusBtnEnabled })}
              data-type="plus"
            >
              <i className="fa2 fa2-plus" aria-hidden="true" />
            </button>
          </span>
        </>
      )}
    </div>
  );
}

export default PriceInput;
