import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import ArrowScroll from 'components/ArrowScroll';
import BetUpdateAlert from 'components/BetUpdateAlert/BetUpdateAlert';
import CashOutButton from 'components/CashOutButton';
import EventHeaderHandler from 'components/EventHeaderHandler';
import ListViewTabs from 'components/ListViewTabs';
import MobileListHidden from 'components/ListViewTabs/components/MobileListHidden';
import MarketInfoRow from 'components/MarketInfoRow';
import StatusOverlay from 'components/MarketsTable/components/MarketsTableRow/components/StatusOverlay';
import MobileAllMarketsLink from 'components/MobileAllMarketsLink';
import PLTable from 'components/PLTable';
import TimeForm from 'components/TimeForm';
import { FAVORITES_TYPES, MOBILE_LIST_VIEW_MAX_TABS, RACING_SPORT_WITH_TODAY, tooltipStatus } from 'constants/app';
import { SPORT_BASE_URL } from 'constants/locations';
import { PLTableType } from 'constants/marketsTable';
import { dontRaceRequest } from 'constants/marketTypes';
import { DEFAULT_MARKET_UNITS } from 'constants/placement';
import { CELLS_ON_MARKET_PAGE, TOOLTIP_COMPONENT } from 'constants/tooltip';
import { PARAMS_ACTION_KEY, PARAMS_OFFER_ID_KEY } from 'constants/urlParams';
import { TooltipProvider } from 'contexts/tooltipContext';
import useAddHeightForFooter from 'hooks/useAddHeightForFooter';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useEnabledView } from 'hooks/useEnabledView';
import useMarketDepth from 'hooks/useMarketDepth';
import useMarketStatusAndLockIcon from 'hooks/useMarketStatusAndLockIcon';
import useTooltip from 'hooks/useTooltip';
import { getAppDevice, getIsIframeEnabled, getTimeformStatisticsEnabled } from 'redux/modules/appConfigs/selectors';
import { Devices } from 'redux/modules/appConfigs/type';
import { setElementHeight } from 'redux/modules/appSettings';
import { getElementHeightByName } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { ENavigationType } from 'redux/modules/competitions/type';
import { getIsAllCurrentBetsCancelling } from 'redux/modules/currentBets/selectors';
import { getFavoriteById } from 'redux/modules/favorites/selectors';
import { getListViewMarkets } from 'redux/modules/listViewTabs/selectors';
import {
  cleanRaces,
  fetchMarket,
  fetchMarketRules,
  fetchRaces,
  setMarketLines,
  setMarketLinesText,
  setMarketReset,
  setMarketShowAll
} from 'redux/modules/market';
import {
  getMarketLines,
  getMarketLinesText,
  getMarketReset,
  getMarketShowAll,
  getSingleMarketRules,
  getSingleMarketRulesLoading
} from 'redux/modules/market/selectors';
import {
  getIsMarketInPlayByMarketPricesId,
  getMarketPricesCurrencyById,
  getMarketPricesId,
  getMarketPricesIsBettingEnabled,
  getMarketPricesStringifiedRunners,
  getStatusByMarketPricesId
} from 'redux/modules/marketsPrices/selectors';
import { isBetUpdated } from 'redux/modules/myBets/selectors';
import { CookieNames, LiveWidgetHeaderType, MarketStatus, PageBlocks, PlacementPage, SportId } from 'types';
import { EMarketDepthView, IMarket, TMarketPricesRunner } from 'types/markets';
import { getFilteredMarket, getMarketTypes } from 'utils/market';

import MarketLineRangeInfoRow from './components/MarketLineRangeInfoRow';
import MarketSelectionsRow from './components/MarketSelectionsRow';
import MarketTitleRow from './components/MarketTitleRow';

import styles from './styles.module.scss';

interface MarketSportProps {
  /**
   * Sport or racing markets
   * @return object
   */
  market: IMarket;
}

const MarketSport = ({ market }: MarketSportProps) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies([CookieNames.PL_TABLE_VISIBLE]);
  const [searchParams] = useSearchParams();

  const offerId = searchParams.get(PARAMS_OFFER_ID_KEY) || '';
  const action = searchParams.get(PARAMS_ACTION_KEY) || '';

  const {
    event,
    competition,
    marketId,
    eventType,
    cashOutEnabled,
    outright,
    description,
    timeformStatistics,
    closedDate,
    parents,
    runners,
    marketName,
    partialCashOutEnabled
  } = market;
  const isSportPage = Object.keys(params).length === 1 && !!params.sportId;

  const { swapColorsFancyMarketsOnCricket, listViewSports } = useDeviceSettings();
  const { target: marketWrapperRef, addedHeight, targetHeight } = useAddHeightForFooter();

  const stringifiedMarketPricesRunners = useSelector(getMarketPricesStringifiedRunners(marketId));
  const marketPricesId = useSelector(getMarketPricesId(marketId));
  const bettingEnabled = useSelector(getMarketPricesIsBettingEnabled(marketId));
  const marketStatus = useSelector(getStatusByMarketPricesId(marketId));
  const marketPricesCurrency = useSelector(getMarketPricesCurrencyById(marketId));
  const isInPlay = useSelector(getIsMarketInPlayByMarketPricesId(marketId));
  const device = useSelector(getAppDevice);
  const timeformStatisticsEnabled = useSelector(getTimeformStatisticsEnabled);
  const updatedBet = useSelector(isBetUpdated);
  const reset = useSelector(getMarketReset);
  const rulesLoading = useSelector(getSingleMarketRulesLoading);
  const rules = useSelector(getSingleMarketRules);
  const lines = useSelector(getMarketLines);
  const showAll = useSelector(getMarketShowAll);
  const linesText = useSelector(getMarketLinesText);
  const marketCatalogues = useSelector(getListViewMarkets);
  const popularMarketsHeight = useSelector(getElementHeightByName(EElementNames.POPULAR_MARKETS_HEIGHT));
  const isIframeEnabled = useSelector(getIsIframeEnabled);
  const isCancelActionsInProgress = useSelector(getIsAllCurrentBetsCancelling(marketId));

  const [isMobileAllMarketsTab, setIsMobileAllMarketTab] = useState(false);
  const [mobileListViewTabs, setMobileListViewTabs] = useState<IMarket[]>([]);

  const { isDepthEnabled, isLandscape } = useMarketDepth(EMarketDepthView.SINGLE_MARKET);
  const { isListViewEnabled, isMultiMarketView } = useEnabledView(isSportPage);
  const { status, showClosedStatus, showStatus } = useMarketStatusAndLockIcon(bettingEnabled, marketStatus);
  const { translationKey, isEnabled } = useTooltip(CELLS_ON_MARKET_PAGE);

  const marketPricesRunners: TMarketPricesRunner[] = useMemo(
    () => (stringifiedMarketPricesRunners ? JSON.parse(stringifiedMarketPricesRunners) : []),
    [stringifiedMarketPricesRunners]
  );

  const { isLineMarket } = getMarketTypes(description.marketType, description.bettingType);
  const isRacing = RACING_SPORT_WITH_TODAY[eventType.id];
  const isMobile = device === Devices.MOBILE;
  const isMarketClosed = !!(marketStatus === MarketStatus.CLOSED || closedDate);
  const isShowAllMarketsLink = isMobile && marketStatus && !isMarketClosed && !outright;
  const isShowMarketInfoRow = !!marketPricesId && !isMarketClosed;
  const isShowLineRangeInfo = isLineMarket && (!isMobile || (isMobile && !isMultiMarketView));
  const isShowPlTable = PLTableType.includes(description?.bettingType) && !isMarketClosed;
  const isFancySwapEnabled = eventType.id === SportId.CRICKET && isLineMarket && swapColorsFancyMarketsOnCricket;
  const isTimeFormEnabled =
    timeformStatisticsEnabled && timeformStatistics && !isMarketClosed && eventType.id === SportId.HORSE_RACING;
  const isShowRaceTab = isRacing && (!closedDate || status !== MarketStatus.CLOSED);

  const parentType = parents?.at(-1)?.type;
  const isParentCompetition = !listViewSports.includes(eventType.id) && parentType === FAVORITES_TYPES.competition;

  const isFavorite = useSelector(getFavoriteById(isParentCompetition ? competition.id : '' || marketId));
  const filteredRunners = useMemo(
    () => getFilteredMarket(runners, marketPricesRunners),
    [runners, marketPricesRunners]
  );

  const hasMinHeightStyle = !(device === Devices.MOBILE && isIframeEnabled);

  // TODO need to add logic for adding favorite star for market in list view
  const favoriteData = {
    entryId: marketId,
    entryName: marketName,
    entryType: FAVORITES_TYPES.market,
    starred: !isFavorite,
    sportId: eventType.id
  };

  if (isRacing) {
    market = {
      ...market,
      runners: filteredRunners
    };
  }

  const handleFetchMarket = () => {
    dispatch(fetchMarket({ marketId }));
  };

  const handleFetchMarketRules = () => {
    dispatch(fetchMarketRules(marketId));
  };

  const handleShowAllRunners = useCallback(() => {
    dispatch(setMarketShowAll(true));
  }, [dispatch]);

  const handleMinimize = useCallback(() => {
    dispatch(setMarketShowAll(false));
  }, [dispatch]);

  const handleResetView = useCallback(() => {
    if (!showAll) {
      dispatch(setMarketReset(true));
    }
  }, [showAll, dispatch]);

  const handlerPLTable = () => {
    setCookie(CookieNames.PL_TABLE_VISIBLE, cookies.PL_TABLE_VISIBLE ? '' : true);
  };

  const handleSetGroupByIndex = (
    index: number,
    step: number,
    defaultLine: number,
    totalLines: number,
    newLinesText: string
  ) => {
    dispatch(
      setMarketLines({
        from: index,
        to: index + step,
        currentLine: index,
        defaultLine,
        totalLines
      })
    );
    dispatch(setMarketReset(false));
    dispatch(setMarketLinesText(newLinesText));
  };

  const onSelectMobileAllMarketsTabLink = (tabMarketId: string) => {
    const findSelectedIndex = marketCatalogues.findIndex(marketItem => marketItem.marketId === tabMarketId);
    const serializedMarkets = marketCatalogues.slice(0, MOBILE_LIST_VIEW_MAX_TABS);

    if (findSelectedIndex > MOBILE_LIST_VIEW_MAX_TABS - 1) {
      serializedMarkets.push(marketCatalogues[findSelectedIndex]);
    }

    if (action && offerId) {
      navigate(`${SPORT_BASE_URL}/${params.sportId}/market/${tabMarketId}?action=${action}&offerId=${offerId}`);
    } else {
      navigate(`${SPORT_BASE_URL}/${params.sportId}/market/${tabMarketId}`);
    }

    if (isMobileAllMarketsTab && tabMarketId === params.marketId) {
      setIsMobileAllMarketTab(false);
    }
    setMobileListViewTabs(serializedMarkets);
  };

  const padding = isMobile ? 10 : 0;

  useEffect(() => {
    dispatch(
      setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: targetHeight + popularMarketsHeight + padding })
    );
  }, [targetHeight, popularMarketsHeight, padding]);

  useEffect(() => {
    if (parents && !isMarketClosed) {
      const race = parents.at(-1);

      if (
        isRacing &&
        race?.id &&
        race.type === ENavigationType.RACE &&
        !closedDate &&
        !dontRaceRequest.includes(description?.marketType as typeof dontRaceRequest[number])
      ) {
        dispatch(fetchRaces(race.id));
      }
    }
  }, [dispatch, isMarketClosed, isRacing, closedDate, parents, description?.marketType]);

  useEffect(() => {
    dispatch(setMarketShowAll(false));

    if (marketCatalogues?.length && isMobile) {
      const marketIndex = marketCatalogues.findIndex(marketItem => marketItem.marketId === params.marketId);

      if (params.marketId && marketIndex > MOBILE_LIST_VIEW_MAX_TABS - 1) {
        onSelectMobileAllMarketsTabLink(params.marketId);
      } else {
        setMobileListViewTabs(marketCatalogues.slice(0, MOBILE_LIST_VIEW_MAX_TABS));
      }
    }

    return () => {
      dispatch(setMarketShowAll(false));
    };
  }, [marketCatalogues, params.marketId, isMobile]);

  useEffect(() => {
    if (isMarketClosed) {
      setCookie(CookieNames.PL_TABLE_VISIBLE, '');
    }

    return () => {
      dispatch(cleanRaces());
      dispatch(setElementHeight({ name: EElementNames.CONTENT_HEIGHT, height: 0 }));
    };
  }, []);

  return (
    <>
      <div
        ref={marketWrapperRef}
        className={classNames('biab_market biab_handicap-market marketWrapper', styles.marketWrapper)}
      >
        <BetUpdateAlert reason={updatedBet} notificationMessage={t('marketBetslip.messages.cancelledBet')} />
        <EventHeaderHandler
          type={LiveWidgetHeaderType.MARKET}
          eventId={event.id}
          competitionId={isParentCompetition ? competition.id : ''}
          startDate={description.marketTime}
          title={event.name}
          inPlay={isInPlay}
          sportId={eventType.id}
          status={status}
          videoStreamingEnabled={event.videoStreamingEnabled}
          matchStatEnabled={event.matchStatEnabled}
          parentType={parentType}
        />
        {isTimeFormEnabled && !isMobile && !isMarketClosed && <TimeForm market={market} />}
        {((!isMobileAllMarketsTab && isListViewEnabled) || isRacing) && !isMarketClosed && (
          <ListViewTabs
            marketId={marketId}
            isHorseTab={isShowRaceTab}
            sportId={params.sportId!}
            onSelectTabMobile={setIsMobileAllMarketTab}
            mobileListViewTabs={mobileListViewTabs}
          />
        )}
        {!isMobileAllMarketsTab && !isListViewEnabled && (
          <MarketTitleRow
            market={market}
            marketId={marketId}
            sportId={eventType.id}
            isMarketClosed={isMarketClosed}
            isRacing={isRacing}
          />
        )}
        {!isMobileAllMarketsTab && (
          <>
            <div className="biab_market-info">
              {isShowMarketInfoRow && (
                <MarketInfoRow
                  marketType={description.marketType}
                  marketName={marketName}
                  sportId={eventType.id}
                  marketId={marketId}
                  marketStartTime={event.openDate}
                  isCashOutEnabled={cashOutEnabled}
                  rulesLoading={rulesLoading}
                  marketsRules={rules}
                  onFetchMarketRules={handleFetchMarketRules}
                  onRefreshMarket={handleFetchMarket}
                  isShowPlTable={isShowPlTable}
                  isOpenPLTable={cookies.PL_TABLE_VISIBLE}
                  handlerPLTable={handlerPLTable}
                  isShowFavorites={isShowRaceTab}
                  favoriteData={favoriteData}
                />
              )}
            </div>
            {isShowLineRangeInfo && <MarketLineRangeInfoRow market={market} />}
            {cookies.PL_TABLE_VISIBLE && isShowPlTable && marketPricesCurrency && !isMobile && (
              <PLTable
                marketId={marketId}
                bettingType={description.bettingType}
                marketType={description.marketType}
                currencyCode={marketPricesCurrency}
                units={description?.lineRangeInfo?.marketUnit || DEFAULT_MARKET_UNITS}
              />
            )}
            {cashOutEnabled && !tooltipStatus[status || ''] && (
              <CashOutButton marketId={marketId} partialCashOutEnabled={partialCashOutEnabled} />
            )}
            <MarketSelectionsRow
              market={market}
              lines={lines}
              linesText={linesText}
              showAll={showAll}
              onShowAllRunners={handleShowAllRunners}
              onResetView={handleResetView}
              onMinimize={handleMinimize}
              showDeepPrices
              isDepthEnabled={isDepthEnabled}
              page={isListViewEnabled ? PlacementPage.LIST_VIEW : PlacementPage.MARKET}
            />

            <div className={classNames('runnersStatusWrapper', styles.runnersStatusWrapper)}>
              {isMobile && isCancelActionsInProgress && (
                <div className="biab_market-loading">
                  <i className="fa fa-spinner fa-pulse fa-2x fa-fw" />
                </div>
              )}
              <div
                className={classNames('runnersWrapper', styles.runnersWrapper, {
                  'biab_fancy-swap': isFancySwapEnabled
                })}
              >
                <TooltipProvider value={{ isEnabled, translationKey, component: TOOLTIP_COMPONENT.MARKET_IN_PLAY }}>
                  <ArrowScroll
                    market={market}
                    showAll={showAll}
                    reset={reset}
                    onSetGroupByIndex={handleSetGroupByIndex}
                    pageBlock={PageBlocks.MARKET_ODDS}
                    showDeepPrices
                    isLandscape={isLandscape}
                    isDepthEnabled={isDepthEnabled}
                    page={isListViewEnabled ? PlacementPage.LIST_VIEW : PlacementPage.MARKET}
                  />
                </TooltipProvider>
              </div>
              {!closedDate && (showClosedStatus || showStatus) && <StatusOverlay status={status} inMiddle />}
            </div>

            {isShowAllMarketsLink && (
              <MobileAllMarketsLink to={`${SPORT_BASE_URL}/${eventType.id}/event/${event.id}?showEvent=true`} />
            )}
            {isTimeFormEnabled && isMobile && !isMarketClosed && <TimeForm market={market} />}
          </>
        )}
        {isMobileAllMarketsTab && (
          <MobileListHidden markets={marketCatalogues} onClick={onSelectMobileAllMarketsTabLink} />
        )}
      </div>
      {hasMinHeightStyle && addedHeight > 0 && <div style={{ height: addedHeight }} />}
    </>
  );
};

export default MarketSport;
