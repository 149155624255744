import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import { EActionTypes } from 'types/betslip';

import styles from './styles.module.scss';

const ButtonArrow = ({
  type,
  isArrowEnabled,
  onClickHandler
}: {
  type: EActionTypes;
  isArrowEnabled: boolean;
  onClickHandler: (type: EActionTypes) => void;
}) => {
  return (
    <button
      className={classNames(styles.arrow, branding.ODDS_ARROW, {
        [styles.arrow__up]: type === EActionTypes.ADD,
        [styles.arrow__down]: type === EActionTypes.SUBSTR,
        [styles.arrow__disabled]: !isArrowEnabled,
        [branding.DISABLED]: !isArrowEnabled
      })}
      disabled={!isArrowEnabled}
      onClick={() => onClickHandler(type)}
      tabIndex={-1}
    >
      <i
        className={classNames('fa2', {
          'fa2-sort-up': type === EActionTypes.ADD,
          'fa2-sort-down': type === EActionTypes.SUBSTR
        })}
        aria-hidden="true"
      />
    </button>
  );
};

export default ButtonArrow;
