import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { difference } from 'lodash';

import BetSlipCashOutItem from 'components/AsianViewPageModule/components/CashOutSection/components/Content/BetSlipCashOutItem';
import Pagination from 'components/Pagination';
import ReloadBtn from 'components/ReloadBtn';
import { ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE, DESKTOP_NAVIGATION_HEIGHT } from 'constants/asianView';
import { asianViewBetslipBranding as branding } from 'constants/branding';
import useDeviceSettings from 'hooks/useDeviceSettings';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import usePagination from 'hooks/usePagination';
import BetSlipCashOutIntervalInjection from 'injections/BetSlipCashOutIntervalInjection';
import {
  getDesktopCashoutPageSize,
  getIsPropertiesLoaded,
  getLanguage,
  getTimezone
} from 'redux/modules/appConfigs/selectors';
import { getElementHeight, getScrollContentHeight } from 'redux/modules/appSettings/selectors';
import { EElementNames } from 'redux/modules/appSettings/type';
import { setIsCashOutTabOpened } from 'redux/modules/asianView';
import { fetchAsianBetSlipCashOutMarkets } from 'redux/modules/asianViewBetSlipCashOut';
import {
  getAsianBetSlipCashOutIsFirstMarketsLoaded,
  getAsianBetSlipCashOutMarketsContent,
  getAsianBetSlipCashOutMarketsContentMarketIds,
  getAsianBetSlipCashOutMarketsLoading,
  getAsianBetSlipCashOutMarketsTotalElements,
  getAsianBetSlipCashOutMarketsTotalPages,
  getAsianBetSlipCashOutPaginationLoading,
  getAsianBetSlipCashOutQuotesMarketsIds,
  getIsAsianBetSlipCashOutFirstQuotesLoaded,
  getIsAsianBetSlipCashOutMarketsLastPage
} from 'redux/modules/asianViewBetSlipCashOut/selectors';
import { getAsianViewCashOutMarketIds } from 'redux/modules/asianViewCashOutCounter/selectors';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { SportId } from 'types';

import SkeletonBetslip from '../SkeletonBetslip';

import styles from './styles.module.scss';

const TOP_AND_BOTTOM_PADDINGS = 20;

const CashOutTabContent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sportId = SportId.SOCCER } = useParams();
  const isLoggedIn = useSelector(getLoggedInStatusState);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const desktopCashOutPageSize = useSelector(getDesktopCashoutPageSize);
  const totalElements = useSelector(getAsianBetSlipCashOutMarketsTotalElements);
  const paginationLoader = useSelector(getAsianBetSlipCashOutPaginationLoading);
  const language = useSelector(getLanguage);
  const timezone = useSelector(getTimezone);
  const isFirstLoaded = useSelector(getAsianBetSlipCashOutIsFirstMarketsLoaded);
  const totalPages = useSelector(getAsianBetSlipCashOutMarketsTotalPages);
  const content = useSelector(getAsianBetSlipCashOutMarketsContent);
  const isLast = useSelector(getIsAsianBetSlipCashOutMarketsLastPage);
  const isMarketsLoading = useSelector(getAsianBetSlipCashOutMarketsLoading);
  const quotesMarketsIds = useSelector(getAsianBetSlipCashOutQuotesMarketsIds);
  const curMarketIds = useSelector(getAsianBetSlipCashOutMarketsContentMarketIds);
  const isFirstQuotesLoaded = useSelector(getIsAsianBetSlipCashOutFirstQuotesLoaded);
  const scrollContentHeight = useSelector(getScrollContentHeight);
  const favouritesHeight = useSelector(getElementHeight(EElementNames.ASIAN_FAVOURITES_NAVIGATION));
  const sportsNavHeight = useSelector(getElementHeight(EElementNames.ASIAN_NAVIGATION_SPORTS_HEIGHT));
  const timezoneHeight = useSelector(getElementHeight(EElementNames.ASIAN_TIMEZONE));
  const balanceHeight = useSelector(getElementHeight(EElementNames.ASIAN_BALANCE));
  const betSlipCollapseHeight = useSelector(getElementHeight(EElementNames.ASIAN_BET_SLIP_COLLAPSE));
  const betSlipTabsHeight = useSelector(getElementHeight(EElementNames.ASIAN_BET_SLIP_TABS));
  const cashOutMarketIds = useSelector(getAsianViewCashOutMarketIds(sportId));

  const { asianViewCashOutPaginationButtons } = useDeviceSettings();

  const [page, setPage] = useState(0);

  const displayedContent = useMemo(() => {
    return content.filter(({ marketId }) => quotesMarketsIds.includes(marketId));
  }, [content, quotesMarketsIds]);

  const handleFetchCashOutMarkets = (nextPage: number, keepPrev?: boolean) => {
    dispatch(
      fetchAsianBetSlipCashOutMarkets({
        page: nextPage,
        size: desktopCashOutPageSize,
        withLoader: true,
        resetPrev: !keepPrev,
        isPaginationEnabled: true,
        resetSettingsTabs: true
      })
    );
  };

  const {
    page: paginationPage,
    onClickNextPage,
    onClickPrevPage,
    onClickPage
  } = usePagination({
    onChangePage: handleFetchCashOutMarkets,
    totalPages,
    isPaginationEnabled: asianViewCashOutPaginationButtons,
    pageParamName: ASIAN_VIEW_BET_SLIP_CASH_OUT_PAGE
  });

  useEffect(() => {
    const diff = difference(cashOutMarketIds, curMarketIds);
    if (diff.length > 0) {
      handleFetchCashOutMarkets(0, true);
    }
  }, [cashOutMarketIds]);

  useEffect(() => {
    if (isLoggedIn && isPropertiesLoaded) {
      dispatch(
        fetchAsianBetSlipCashOutMarkets({
          page: asianViewCashOutPaginationButtons ? paginationPage : 0,
          size: desktopCashOutPageSize,
          withLoader: true,
          resetPrev: true,
          resetSettingsTabs: true
        })
      );
    }
  }, [isPropertiesLoaded, isLoggedIn, dispatch, language, timezone, desktopCashOutPageSize]);

  useEffect(() => {
    if (page !== 0 && !isLast && !asianViewCashOutPaginationButtons) {
      dispatch(
        fetchAsianBetSlipCashOutMarkets({
          page,
          size: desktopCashOutPageSize,
          withLoader: true,
          isPaginationEnabled: false
        })
      );
    }
  }, [page, isLast]);

  useEffect(() => {
    return () => {
      dispatch(setIsCashOutTabOpened(false));
    };
  }, []);

  const infiniteScrollCallback = useCallback(() => {
    if (!isMarketsLoading && !isLast && !asianViewCashOutPaginationButtons) {
      setPage(prevPage => prevPage + 1);
    }
  }, [isMarketsLoading, isLast, asianViewCashOutPaginationButtons]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  return (
    <div
      className={classNames(styles.cashOut__container, branding.BACKGROUND)}
      style={{
        maxHeight:
          scrollContentHeight -
          DESKTOP_NAVIGATION_HEIGHT -
          favouritesHeight -
          sportsNavHeight -
          TOP_AND_BOTTOM_PADDINGS -
          timezoneHeight -
          balanceHeight -
          betSlipCollapseHeight -
          betSlipTabsHeight
      }}
    >
      <BetSlipCashOutIntervalInjection />
      <div className={styles.cashOut__content}>
        {isLoggedIn && (
          <ReloadBtn
            isShowTitle
            containerClassName={styles.cashOut__reloadBtn}
            isAsianViewBetSlip
            onReload={() => setPage(0)}
          />
        )}

        {isLoggedIn && !isFirstLoaded ? (
          <SkeletonBetslip />
        ) : (
          <>
            {!isLoggedIn || !content.length || (isFirstQuotesLoaded && !quotesMarketsIds.length) ? (
              <div className={styles.cashOut__emptyMessage}>{t('cashout.page.labels.empty')}</div>
            ) : (
              <div className={styles.cashOut__list}>
                {displayedContent.map(market => (
                  <BetSlipCashOutItem key={market.marketId} market={market} />
                ))}
              </div>
            )}
          </>
        )}
        {isLoggedIn && !asianViewCashOutPaginationButtons && paginationLoader && <SkeletonBetslip />}
      </div>
      {!asianViewCashOutPaginationButtons && !!content.length && !paginationLoader && <div ref={lastElementRef} />}

      {asianViewCashOutPaginationButtons && totalElements > desktopCashOutPageSize && !paginationLoader && (
        <Pagination
          page={paginationPage}
          totalPages={totalPages}
          onClickNextPage={onClickNextPage}
          onClickPage={onClickPage}
          onClickPrevPage={onClickPrevPage}
          mobileMode
          paginationClassName={styles.cashOut__pagination}
          leftIcon="av-icon av-icon-chevron-left"
          rightIcon="av-icon av-icon-chevron-right"
          leftIconClassName={styles.cashOut__pagination__chevron}
          rightIconClassName={styles.cashOut__pagination__chevron}
          maxPages={4}
        />
      )}
    </div>
  );
};

export default CashOutTabContent;
