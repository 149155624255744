import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import useLongDateFormat from 'hooks/useLongDateFormat';
import { TCurrentBet } from 'redux/modules/currentBets/type';

import styles from './styles.module.scss';

const BetInfo = ({ bet }: { bet: TCurrentBet }) => {
  const { t } = useTranslation();

  const formattedDate = useLongDateFormat({
    isTimeFirst: true,
    isShortYear: true,
    time: bet.placedDate ?? 0,
    hasYear: true,
    divider: ' ',
    dateDivider: '-'
  });

  return (
    <div className={classNames(styles.betInfoContent, branding.OPENED_BET_PLACED_TIME, branding[bet.side])}>
      {`${t('betslip.labels.reference')}: ${bet.offerId} ${t('betslip.labels.submitted')}: ${formattedDate}`}
    </div>
  );
};

export default BetInfo;
