import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEqual, reduce, uniqBy } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { TFailureActionPayload, TSocketMarketParams } from 'types';
import { TPrice, TSize } from 'types/bets';
import { isPartiallyMatched, parseCurrentBet } from 'utils/betslip';

import {
  ECurrentBetActions,
  TCurrentBet,
  TCurrentBetActionPayload,
  TCurrentBetsMap,
  TCurrentBetsPayload,
  TCurrentBetsState
} from './type';

const initialState: TCurrentBetsState = {
  offers: {},
  updatedOffers: {},
  placedBets: {},
  marketsToSubscribe: [],
  placementMessage: '',
  loading: false,
  error: null,
  isFirstLoad: true,
  currentBetsString: '',
  currentBetsList: []
};

const slice = createSlice({
  name: SLICES_NAMES.CURRENT_BETS,
  initialState,
  reducers: {
    fetchCurrentBets: (state, _: PayloadAction<TCurrentBetsPayload | undefined>) => {
      state.loading = true;
    },
    successGetCurrentBets: (state, { payload }: PayloadAction<{ bets: TCurrentBet[]; stringifiedBets?: string }>) => {
      /**
       * Markets with unmatched bets. Will be subscribed to market prices in order to set take odds.
       */
      const marketsToSubscribe = payload.bets.reduce((result: TSocketMarketParams[], bet: TCurrentBet) => {
        return [
          ...result,
          ...(bet.offerState === BetsStatusesTypes.PLACED ? [{ marketId: bet.marketId, eventId: bet.eventId }] : [])
        ];
      }, []);

      const uniqMarketsToSubscribe = uniqBy(marketsToSubscribe, 'marketId');

      const receivedOldOffers = payload.bets.reduce((result: TCurrentBetsMap, bet: TCurrentBet) => {
        return {
          ...result,
          ...(bet.oldOfferId ? { [bet.oldOfferId]: parseCurrentBet(bet) } : {})
        };
      }, {});

      const isBetInProcess = (bet: TCurrentBet) =>
        !receivedOldOffers[bet.offerId] ||
        (receivedOldOffers[bet.offerId] && receivedOldOffers[bet.offerId].offerState === BetsStatusesTypes.CANCELLED);

      /**
       * Offers that are in updating or cancelling process do not remove from the list.
       */
      const processingOffers = reduce(
        state.offers,
        (result: TCurrentBetsMap, bet: TCurrentBet) => {
          return {
            ...result,
            ...(bet.action && isBetInProcess(bet)
              ? {
                  [bet.offerId]: {
                    ...state.offers[bet.offerId],
                    ...bet
                  }
                }
              : {})
          };
        },
        {}
      );

      const receivedOffers = payload.bets.reduce((result: TCurrentBetsMap, bet: TCurrentBet) => {
        bet = parseCurrentBet(bet);

        const prevBet = state.offers[bet.offerId];
        const findBet = state.offers[bet.oldOfferId];

        const isMatchedOuter =
          bet.offerState === BetsStatusesTypes.MATCHED &&
          prevBet &&
          !prevBet.action &&
          prevBet.sizeRemaining !== bet.sizeRemaining;

        const isPlacedPartiallyMatched =
          bet.offerState === BetsStatusesTypes.MATCHED && isPartiallyMatched(bet) && !prevBet;

        const isMatched = !!(
          findBet &&
          (findBet.offerState === BetsStatusesTypes.PLACED || findBet.offerState === BetsStatusesTypes.CANCELLED) &&
          bet.offerState === BetsStatusesTypes.MATCHED &&
          (findBet.sizeMatched !== bet.sizeMatched ||
            findBet.sizePlaced !== bet.sizePlaced ||
            findBet.sizeCancelled !== bet.sizeCancelled)
        );

        if (isMatched && bet.sizeRemaining === 0) {
          bet.action =
            findBet.action === ECurrentBetActions.EDITING
              ? ECurrentBetActions.FULLY_MATCHED_AFTER_EDITING
              : ECurrentBetActions.FULLY_MATCHED;
        } else if (isMatched && bet.sizeRemaining > 0) {
          bet.action = ECurrentBetActions.PARTIALLY_MATCHED;
        } else if (isMatchedOuter) {
          bet.action = bet.sizeRemaining > 0 ? ECurrentBetActions.PARTIALLY_MATCHED : ECurrentBetActions.FULLY_MATCHED;
        } else if (isPlacedPartiallyMatched) {
          bet.action = ECurrentBetActions.PLACED_PARTIALLY_MATCHED;
        }

        if ((isMatched || isMatchedOuter) && bet.action) {
          processingOffers[bet.offerId] = bet;
        }

        return {
          ...result,
          /**
           * Offer is in canceling or editing processes. Need to stay in the list in order to show information about
           * placement result.
           */
          ...(processingOffers[bet.offerId] && isBetInProcess(bet)
            ? {
                [bet.offerId]: {
                  ...state.offers[bet.offerId],
                  ...processingOffers[bet.offerId],
                  ...bet
                }
              }
            : /**
             * Updated offer with new offer ID is received. Old offer need to be replaced with the new one (if exists).
             * If offer is partially matched and then updated - keep matched part in offers list (it has oldOfferId).
             */
            !receivedOldOffers[bet.offerId] || bet.offerState === BetsStatusesTypes.MATCHED
            ? {
                [bet.offerId]: {
                  ...state.offers[bet.offerId],
                  ...bet
                }
              }
            : {})
        };
      }, {});

      state.offers = { ...processingOffers, ...receivedOffers };
      state.updatedOffers = { ...state.updatedOffers, ...receivedOldOffers };
      state.loading = false;
      state.isFirstLoad = false;
      state.currentBetsList = payload.bets;

      if (payload.stringifiedBets) {
        state.currentBetsString = payload.stringifiedBets;
      }

      if (!isEqual(state.marketsToSubscribe, uniqMarketsToSubscribe)) {
        state.marketsToSubscribe = marketsToSubscribe;
      }
    },
    failureGetCurrentBets: (state, action: PayloadAction<TFailureActionPayload>) => {
      state.error = action.payload;
      state.loading = false;
    },
    cleanCurrentBets: state => {
      state.offers = {};
      state.currentBetsString = '';
    },
    setCurrentBetAction: (state, action: PayloadAction<TCurrentBetActionPayload>) => {
      if (state.offers[action.payload.offerId]) {
        state.offers[action.payload.offerId].action = action.payload.action;
      }
    },
    updateOfferPriceAndSize: (state, action: PayloadAction<{ price: TPrice; offerId: number; size: TSize }>) => {
      if (state.offers[action.payload.offerId]) {
        state.offers[action.payload.offerId].changedPrice = action.payload.price;
        state.offers[action.payload.offerId].changedSize = action.payload.size;
      }
    },
    setCurrentBetActionForAll: (state, payloadAction: PayloadAction<TCurrentBetActionPayload[]>) => {
      payloadAction.payload.forEach(({ offerId, action }) => {
        if (state.offers[offerId]) {
          state.offers[offerId].action = action;
        }
      });
    },
    setCurrentBetsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    }
  }
});

export const {
  failureGetCurrentBets,
  setCurrentBetAction,
  successGetCurrentBets,
  setCurrentBetActionForAll,
  cleanCurrentBets,
  fetchCurrentBets,
  updateOfferPriceAndSize,
  setCurrentBetsLoading
} = slice.actions;

export default slice.reducer;
