import { PointerEvent, TouchEvent, useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useResizeObserver } from 'usehooks-ts';

import { RACE_CARD_SWIPE_THRESH_HOLD } from 'constants/raceCard';
import { RACE_INDEX_SEARCH_PARAM, RACE_SEARCH_PARAM } from 'pages/mobile/MobileTodayCardPage';
import { getSportData } from 'redux/modules/competitions/selectors';
import { TNavigationResponse } from 'redux/modules/competitions/type';
import { getSingleMarketInfo, getSingleMarketLoading } from 'redux/modules/market/selectors';

import RaceCardContent from '../RaceCardContent';
import RaceCardHeaderInfo from '../RaceCardHeaderInfo';
import RaceCardSkeleton from '../RaceCardSkeleton';

import styles from './styles.module.scss';

interface RaceCardProps {
  raceData: TNavigationResponse | null;
}

const RaceCard = ({ raceData }: RaceCardProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sportData = useSelector(getSportData);
  const loading = useSelector(getSingleMarketLoading);
  const market = useSelector(getSingleMarketInfo);

  const dragging = useRef(false);
  const startPos = useRef(0);
  const currentTranslate = useRef(0);
  const prevTranslate = useRef(0);
  const currentIndex = useRef(0);
  const sliderRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<number | null>(null);

  const { width: sliderWidth = 0 } = useResizeObserver({ ref: sliderRef, box: 'border-box' });

  const selectedRaceId = searchParams.get(RACE_SEARCH_PARAM);
  const selectedRaceIndex = searchParams.get(RACE_INDEX_SEARCH_PARAM) ?? '';
  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  const activeIndex = parseInt(selectedRaceIndex);

  const setPositionByIndex = useCallback(() => {
    const isFirstSelectedRace = currentIndex.current === 0;
    const isLastSelectedRace = currentIndex.current === sportData.length - 1;
    const shift = isFirstSelectedRace ? 0 : isLastSelectedRace ? 24 : 12;

    currentTranslate.current = -(currentIndex.current * (sliderWidth - 18) - shift);
    prevTranslate.current = currentTranslate.current;
    setSliderPosition();
  }, [sliderWidth, sportData.length]);

  const transitionOn = useCallback(() => {
    if (sliderRef.current) sliderRef.current.style.transition = `transform 0.15s ease-out`;
  }, []);

  const transitionOff = () => {
    if (sliderRef.current) sliderRef.current.style.transition = 'none';
  };

  // watch for a change in activeIndex prop
  useEffect(() => {
    if (activeIndex !== currentIndex.current) {
      transitionOn();
      currentIndex.current = activeIndex;
      setPositionByIndex();
    }
  }, [activeIndex, setPositionByIndex, transitionOn]);

  useLayoutEffect(() => {
    if (sliderRef.current) {
      // no animation on startIndex
      transitionOff();

      // set position by startIndex
      setPositionByIndex();
    }
  }, [setPositionByIndex]);

  const pointerStart = (event: PointerEvent) => {
    transitionOn();
    startPos.current = event.pageX;
    dragging.current = true;
    animationRef.current = requestAnimationFrame(animation);
  };

  const pointerMove = (event: PointerEvent) => {
    if (dragging.current) {
      const currentPosition = event.pageX;
      currentTranslate.current = prevTranslate.current + currentPosition - startPos.current;
    }
  };

  const touchStart = (event: TouchEvent<HTMLDivElement>) => {
    transitionOn();
    startPos.current = event.touches[0].clientX;
    dragging.current = true;
    animationRef.current = requestAnimationFrame(animation);
  };

  const touchMove = (event: TouchEvent<HTMLDivElement>) => {
    if (dragging.current) {
      const currentPosition = event.touches[0].clientX;
      currentTranslate.current = prevTranslate.current + currentPosition - startPos.current;
    }
  };

  const pointerEnd = () => {
    transitionOn();
    cancelAnimationFrame(animationRef.current!);
    dragging.current = false;
    const movedBy = currentTranslate.current - prevTranslate.current;

    // if moved enough negative then snap to next slide if there is one
    if (movedBy < -RACE_CARD_SWIPE_THRESH_HOLD && currentIndex.current < sportData.length - 1) {
      currentIndex.current += 1;
      searchParams.set(RACE_SEARCH_PARAM, sportData[currentIndex.current]?.id);
      searchParams.set(RACE_INDEX_SEARCH_PARAM, String(currentIndex.current));
      setSearchParams(searchParams);
    }

    // if moved enough positive then snap to previous slide if there is one
    if (movedBy > RACE_CARD_SWIPE_THRESH_HOLD && currentIndex.current > 0) {
      currentIndex.current -= 1;
      searchParams.set(RACE_SEARCH_PARAM, sportData[currentIndex.current]?.id);
      searchParams.set(RACE_INDEX_SEARCH_PARAM, String(currentIndex.current));
      setSearchParams(searchParams);
    }

    transitionOn();

    setPositionByIndex();
    sliderRef.current!.style.cursor = 'grab';
  };

  function animation() {
    setSliderPosition();
    if (dragging.current) requestAnimationFrame(animation);
  }

  function setSliderPosition() {
    if (!sliderRef.current) return;
    sliderRef.current.style.transform = `translateX(${currentTranslate.current}px)`;
  }

  const renderContent = (id: string, index: number) => {
    if (index - activeIndex >= 3 || activeIndex - index >= 3) {
      return <div className={styles.raceCard__emptyCard} />;
    }

    if (loading || id !== selectedRaceId || `tc-${market?.parents[market?.parents.length - 1].id}` !== selectedRaceId) {
      return <RaceCardSkeleton />;
    }

    return <RaceCardContent raceData={raceData} />;
  };

  return (
    <div className={styles.raceCard__carousel}>
      <div ref={sliderRef} className={styles.raceCard__container}>
        {sportData.map((item, index) => {
          return (
            <div
              key={item.id}
              className={styles.raceCard}
              onPointerDown={!isTouchDevice ? pointerStart : () => {}}
              onPointerMove={!isTouchDevice ? pointerMove : () => {}}
              onPointerUp={!isTouchDevice ? pointerEnd : () => {}}
              onPointerLeave={
                !isTouchDevice
                  ? () => {
                      if (dragging.current) pointerEnd();
                    }
                  : () => {}
              }
              onTouchStart={isTouchDevice ? touchStart : () => {}}
              onTouchMove={isTouchDevice ? touchMove : () => {}}
              onTouchEnd={isTouchDevice ? pointerEnd : () => {}}
            >
              <RaceCardHeaderInfo race={item} />
              {renderContent(item.id, index)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RaceCard;
