import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { times } from 'lodash';

import { numberOfButtons, quickBetsTableHeader } from 'constants/quickBets';
import useDevice from 'hooks/useDevice';
import { useQuickBets } from 'hooks/useQuickBets';
import {
  getCurrency,
  getDesktopSettingsInlinePlacementMiddleSection,
  getIsExchangeGamesEnabled,
  getIsExchangeSportsEnabled
} from 'redux/modules/appConfigs/selectors';

import ActionButtons from './components/ActionButtons';
import CurrenciesCellValue from './components/CurrenciesCellValue';
import QuickBetsTable from './components/QuickBetsTable';
import TableWrapper from './components/TableWrapper';

import styles from './styles.module.scss';

const SettingsQuickBets = () => {
  const { t } = useTranslation();

  const { isMobile } = useDevice();
  const { gamesMaxBetSize, gamesMinBetSize, gamesStep, step, minBetSize, maxBetSize } = useSelector(getCurrency);
  const inlinePlacementMiddleSection = useSelector(getDesktopSettingsInlinePlacementMiddleSection);
  const exchangeSportsEnabled = useSelector(getIsExchangeSportsEnabled);
  const exchangeGamesEnabled = useSelector(getIsExchangeGamesEnabled);
  const isTabsEnabled = exchangeSportsEnabled && exchangeGamesEnabled;

  const {
    tabs,
    activeTab,
    setActiveTab,
    editMode,
    setEditMode,
    formState,
    handleSetInputValue,
    handleSaveQuickBets,
    handleResetQuickBets,
    handleCancelQuickBets
  } = useQuickBets();

  const isWithErrors = [...formState[activeTab.value].errors].some(el => el.translationKey);
  const showNotification = !isMobile && inlinePlacementMiddleSection;

  return (
    <div className={classNames(styles.quickBets, 'biab_quick-bets-wrapper')}>
      <h4 className={styles.quickBets__title}>{t('account.settings.quickBets.title')}</h4>
      <TableWrapper classname={styles.quickBets__tabsWrapper}>
        {isTabsEnabled &&
          Object.values(tabs).map(tab => (
            <div
              key={tab.value}
              className={classNames('biab_tab', styles.quickBets__tab, {
                ['biab_active']: tab.value === activeTab.value,
                [styles.quickBets__tabActive]: tab.value === activeTab.value
              })}
              onClick={() => setActiveTab(tab)}
            >
              {t(tab.translationKey)}
            </div>
          ))}
      </TableWrapper>
      <div className={styles.quickBets__table}>
        <div className={styles.quickBets__table__column}>
          {quickBetsTableHeader.map(cell => (
            <span
              key={cell}
              className={classNames(styles.quickBets__table__column__cell, {
                [styles.normalLineHeight]: isMobile
              })}
            >
              {t(cell)}
            </span>
          ))}
        </div>
        <div className={styles.quickBets__table__column}>
          {activeTab.value === tabs.sports?.value ? (
            <>
              <CurrenciesCellValue value={minBetSize} />
              <CurrenciesCellValue value={maxBetSize} />
              <CurrenciesCellValue value={step} />
            </>
          ) : (
            <>
              <CurrenciesCellValue value={gamesMinBetSize} />
              <CurrenciesCellValue value={gamesMaxBetSize} />
              <CurrenciesCellValue value={gamesStep} />
            </>
          )}
        </div>
        <TableWrapper>
          {times(numberOfButtons).map((quickBet, index) => (
            <QuickBetsTable
              key={quickBet}
              activeTab={activeTab}
              editMode={editMode}
              index={index}
              formState={formState}
              handleSetInputValue={handleSetInputValue}
            />
          ))}
        </TableWrapper>
      </div>
      <TableWrapper classname={styles.quickBets__actionBtnsWrapper} tdClassname="biab_quick-bets-actions">
        <ActionButtons
          activeTab={activeTab}
          editMode={editMode}
          setEditMode={setEditMode}
          handleSaveQuickBets={handleSaveQuickBets}
          handleResetQuickBets={handleResetQuickBets}
          handleCancelQuickBets={handleCancelQuickBets}
          isWithErrors={isWithErrors}
          isValueChanged={formState[activeTab.value].valuesChanged}
        />
      </TableWrapper>
      {showNotification && <span>{t('account.settings.quickBets.note')}</span>}
    </div>
  );
};

export default SettingsQuickBets;
