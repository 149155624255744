import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { keys } from 'lodash';

import AHNetPLValue from '../AHNetPLValue';

interface AHNetPLValueRowsProps {
  isGoalLine: boolean;
  isPL: boolean;
  isWhatIf: boolean;
  currency: string;
  AHDoubleLineNetProfitTable: { [key: string]: any };
}

const AHNetPLValueRows = ({
  isGoalLine,
  isPL,
  isWhatIf,
  currency,
  AHDoubleLineNetProfitTable
}: AHNetPLValueRowsProps) => {
  const { t } = useTranslation();
  const AHDoubleLineNetProfitTableKeys = keys(AHDoubleLineNetProfitTable);

  const getEmptyAHRows = () => {
    const lines = [t('betslip.netOfCommission.AHDoubleLine.draw'), '1', '2', '3', '4', '4+'];
    return lines.map((line, index) => (
      <tr key={index}>
        <td>{line}</td>
        <td />
        <td />
        <td />
      </tr>
    ));
  };

  return (
    <>
      {AHDoubleLineNetProfitTableKeys.length == 0 && isGoalLine && (
        <tr>
          <td colSpan={2}>0+</td>
          <td colSpan={2} />
        </tr>
      )}
      {AHDoubleLineNetProfitTableKeys.length == 0 && !isGoalLine && getEmptyAHRows()}
      {AHDoubleLineNetProfitTableKeys.length > 0 &&
        AHDoubleLineNetProfitTableKeys.map((key: any, line: any) => (
          <tr key={line}>
            {isGoalLine ? (
              <>
                <td colSpan={2}>{AHDoubleLineNetProfitTable[key].title}</td>
                <td colSpan={2}>
                  <AHNetPLValue
                    isPL={isPL}
                    isWhatIf={isWhatIf}
                    currency={currency}
                    plValue={AHDoubleLineNetProfitTable[key].pl.win_value}
                    whatIfValue={AHDoubleLineNetProfitTable[key].whatIf.win_value}
                    plStyle={AHDoubleLineNetProfitTable[key].pl.win_style}
                    whatIfStyle={AHDoubleLineNetProfitTable[key].whatIf.win_style}
                  />
                </td>
              </>
            ) : (
              <>
                <td>
                  {line == 0 ? t('betslip.netOfCommission.AHDoubleLine.draw') : AHDoubleLineNetProfitTable[key].title}
                </td>
                <td>
                  {line != 0 && (
                    <AHNetPLValue
                      isPL={isPL}
                      isWhatIf={isWhatIf}
                      currency={currency}
                      plValue={AHDoubleLineNetProfitTable[key].pl.win_value}
                      whatIfValue={AHDoubleLineNetProfitTable[key].whatIf.win_value}
                      plStyle={AHDoubleLineNetProfitTable[key].pl.win_style}
                      whatIfStyle={AHDoubleLineNetProfitTable[key].whatIf.win_style}
                    />
                  )}
                </td>
                <td>
                  {line == 0 && (
                    <AHNetPLValue
                      isPL={isPL}
                      isWhatIf={isWhatIf}
                      currency={currency}
                      plValue={AHDoubleLineNetProfitTable[key].pl.draw_value}
                      whatIfValue={AHDoubleLineNetProfitTable[key].whatIf.draw_value}
                      plStyle={AHDoubleLineNetProfitTable[key].pl.draw_style}
                      whatIfStyle={AHDoubleLineNetProfitTable[key].whatIf.draw_style}
                    />
                  )}
                </td>
                <td>
                  {line != 0 && (
                    <AHNetPLValue
                      isPL={isPL}
                      isWhatIf={isWhatIf}
                      currency={currency}
                      plValue={AHDoubleLineNetProfitTable[key].pl.loss_value}
                      whatIfValue={AHDoubleLineNetProfitTable[key].whatIf.loss_value}
                      plStyle={AHDoubleLineNetProfitTable[key].pl.loss_style}
                      whatIfStyle={AHDoubleLineNetProfitTable[key].whatIf.loss_style}
                    />
                  )}
                </td>
              </>
            )}
          </tr>
        ))}
    </>
  );
};

export default memo(AHNetPLValueRows);
