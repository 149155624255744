import { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import { setCollapseState } from 'redux/modules/betslip';
import { getBetslipCollapse } from 'redux/modules/betslip/selectors';

import Filters from '../BetslipFilters';

import styles from './styles.module.scss';

interface BetslipHeaderProps {
  isGameBetslip: boolean;
}

const BetslipHeader = ({ isGameBetslip }: BetslipHeaderProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const collapse = useSelector(getBetslipCollapse);
  const isLoggedIn = useSelector(getLoggedInStatusState);

  const onToggleIconClick = (event: SyntheticEvent) => {
    event.preventDefault();

    dispatch(setCollapseState(!collapse));
  };

  return (
    <div className={classNames(styles.betslipHeader, branding.HEADER)}>
      <span className={styles.betslipTitle}>{t(`betslip.labels.betslip${isGameBetslip ? 'Games' : ''}`)}</span>
      {isLoggedIn && !collapse && <Filters />}
      <button
        className={classNames(styles.betslipToggle, branding.BETSLIP_COLLAPSE, {
          [styles.betslipToggle__active]: !collapse,
          [branding.UP]: !collapse,
          [branding.DOWN]: collapse
        })}
        onClick={onToggleIconClick}
      >
        <i className="fa2 biab_expand fa2-arrow-down" />
      </button>
    </div>
  );
};

export default BetslipHeader;
