import classNames from 'classnames';

import styles from './styles.module.scss';

export enum CircleColors {
  WHITE = 'circle-white',
  BLACK = 'circle-black',
  DARK_GRAY = 'circle-dark-gray',
  BLUE = 'circle-blue',
  RED = 'circle-red'
}

interface ILoader {
  circleColor?: CircleColors;
  className?: string;
}

const Loader = ({ circleColor = CircleColors.WHITE, className = '' }: ILoader) => {
  return (
    <div className={classNames('biab_loader', styles.loader, circleColor, className)}>
      <div className={classNames(styles.circle, styles.circle1)} />
      <div className={classNames(styles.circle, styles.circle2)} />
      <div className={classNames(styles.circle, styles.circle3)} />
      <div className={classNames(styles.circle, styles.circle4)} />
      <div className={classNames(styles.circle, styles.circle5)} />
      <div className={classNames(styles.circle, styles.circle6)} />
      <div className={classNames(styles.circle, styles.circle7)} />
      <div className={classNames(styles.circle, styles.circle8)} />
    </div>
  );
};

export default Loader;
