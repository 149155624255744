import { memo } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { betslipBranding as branding } from 'constants/branding';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useBetslipLabels } from 'hooks/useLabels';
import {
  getEventViewSettings,
  getLineMarketsSwitchBackLayOnCricket,
  getReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { TCurrentBet } from 'redux/modules/currentBets/type';
import { SportId } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';
import { EBetTooltips, TLabels } from 'types/betslip';
import { BettingType } from 'types/markets';
import { isFancyMarket, isUnderOverLabel, isYesNoLabel } from 'utils/openedBets';

import BetTooltip from '../BetTooltip';

import styles from './styles.module.scss';

const OpenedBetsHeader = ({
  bet,
  prevBet,
  side,
  matchType
}: {
  bet: TCurrentBet;
  prevBet: TCurrentBet | null;
  side: BetTypes;
  matchType?: MatchTypes;
}) => {
  const eventViewSettings = useSelector(getEventViewSettings);
  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getReplaceBackLayWithUnderOver);

  const { fancyMarketsOnCricket, swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const labels: TLabels = useBetslipLabels({
    eventTypeId: bet.eventTypeId.toString(),
    bettingType: bet.bettingType,
    marketType: bet.marketType,
    marketUnit: bet.marketUnit,
    fancyView: bet.fancyView
  });

  const isHeader =
    !prevBet ||
    bet.side !== prevBet?.side ||
    bet.matchType !== prevBet?.matchType ||
    isFancyMarket(bet, eventViewSettings, fancyMarketsOnCricket) !==
      isFancyMarket(prevBet, eventViewSettings, fancyMarketsOnCricket) ||
    isUnderOverLabel(bet, replaceBackLayWithUnderOver) !== isUnderOverLabel(prevBet, replaceBackLayWithUnderOver) ||
    isYesNoLabel(bet, lineBackLayToNoYes, eventViewSettings, fancyMarketsOnCricket) !==
      isYesNoLabel(prevBet, lineBackLayToNoYes, eventViewSettings, fancyMarketsOnCricket);

  const isCricket = bet.eventTypeId.toString() === SportId.CRICKET;
  const isLineMarket = bet.bettingType === BettingType.LINE ?? false;
  const isFancySwapColors = isCricket && swapColorsFancyMarketsOnCricket && isLineMarket;

  return (
    <>
      {isHeader && (
        <div
          className={classNames(
            styles.openedBetsBySideHeader,
            styles[`openedBetsBySideHeader__${side.toLowerCase()}`],
            branding.BET_HEADER,
            branding[side],
            { [branding.FANCY_SWAP]: isFancySwapColors }
          )}
        >
          <div>{labels[side]?.bet}</div>
          <div>
            <div>
              {labels[side]?.odds && (
                <BetTooltip matchType={matchType} side={side} tooltipType={EBetTooltips.ODDS}>
                  {labels[side]?.odds}
                </BetTooltip>
              )}
            </div>
            <div>
              {labels[side]?.stake && (
                <BetTooltip matchType={matchType} side={side} tooltipType={EBetTooltips.SIZE}>
                  {labels[side]?.stake}
                </BetTooltip>
              )}
            </div>
            <div>
              {labels[side]?.profit && (
                <BetTooltip matchType={matchType} side={side} tooltipType={EBetTooltips.PROFIT}>
                  {labels[side]?.profit}
                </BetTooltip>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(OpenedBetsHeader);
