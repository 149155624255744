import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import MobileAccountBackBtn from 'components/MobileAccountBackBtn';
import MobileAccountBalance from 'components/MobileAccountBalance';
import { MOBILE_MAX_NAV_MENU_LENGTH } from 'constants/app';
import { asianViewMiddleSection } from 'constants/branding';
import { ACCOUNT_BASE_URL } from 'constants/locations';
import { GET_ACCOUNT_NAVIGATION_BUTTONS_MOBILE, getCurrentAccountNavigationButtonMobile } from 'constants/myBets';
import useAccountUrlParams from 'hooks/useAccountUrlParams';
import {
  getIsResponsibleBettingMenuEnabled,
  getMobileSettingsBalanceToggle,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { TMobileAccountDropdownValue } from 'types';

import MobileAccountDropdown from '../MobileAccountDropdown';

import styles from './styles.module.scss';

interface MobileAccountNavigationProps {
  hasDropdownMenu?: boolean;
  hasBalance?: boolean;
}

const MobileAccountNavigation = ({ hasDropdownMenu = true, hasBalance = true }: MobileAccountNavigationProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isResponsibleBettingMenuEnabled = useSelector(getIsResponsibleBettingMenuEnabled);

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const balanceToggle = useSelector(getMobileSettingsBalanceToggle);

  const accountNavigationButtons: TMobileAccountDropdownValue[] = GET_ACCOUNT_NAVIGATION_BUTTONS_MOBILE(
    isPNCEnabled,
    isResponsibleBettingMenuEnabled
  );

  const { selectedTab } = useAccountUrlParams();
  const currentTab = `${ACCOUNT_BASE_URL}/${selectedTab}`;

  const currentPageValue = getCurrentAccountNavigationButtonMobile(
    currentTab || location.pathname,
    accountNavigationButtons
  );

  const isWideAccountMenu = accountNavigationButtons.some(
    ({ labelTranslationKey, isVisible }) =>
      isVisible !== false && t(labelTranslationKey).length > MOBILE_MAX_NAV_MENU_LENGTH
  );

  return (
    <div className={asianViewMiddleSection.ACCOUNT_SUB_HEADER}>
      <div className={styles.mobileAccountNav}>
        <MobileAccountBackBtn className={hasDropdownMenu && !isWideAccountMenu ? '' : styles.backBtn} />
        {hasDropdownMenu && !isWideAccountMenu && (
          <MobileAccountDropdown value={currentPageValue} options={accountNavigationButtons} />
        )}
        {hasBalance && balanceToggle && hasDropdownMenu && (
          <MobileAccountBalance className={isWideAccountMenu ? styles.balanceBtn : ''} />
        )}
      </div>
      {isWideAccountMenu && hasDropdownMenu && (
        <div className={styles.mobileAccountNav}>
          <MobileAccountDropdown value={currentPageValue} options={accountNavigationButtons} />
        </div>
      )}
    </div>
  );
};

export default MobileAccountNavigation;
