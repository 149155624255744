import { useCallback, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import GameResultPopup from 'components/GameResultPopup';
import Modal from 'components/Modal';
import MyBetsStandardCell from 'components/MyBetsStandardCell';
import { BetStatuses, ExchangeTypes, lineBettingType } from 'constants/myBets';
import useDeviceSettings from 'hooks/useDeviceSettings';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useParseMyBetsDates } from 'hooks/useParseMyBetsDates';
import {
  getDesktopSettingsAlternativeLayOdds,
  getDesktopSettingsLineMarketsSwitchBackLayOnCricket,
  getDesktopSettingsReplaceBackLayWithUnderOver,
  getDesktopSettingsTotalWinnings,
  getPNCEnabledSetting
} from 'redux/modules/appConfigs/selectors';
import { getGameType } from 'redux/modules/games/selectors';
import { EGameTypes } from 'redux/modules/games/type';
import { getAccountProductsByBetsType } from 'redux/modules/myBets/selectors';
import { BetsType, EBetSide, TMyBetsContentItem } from 'redux/modules/myBets/type';
import { CookieNames, OddsType } from 'types';
import {
  alternativeBackOddsValue,
  alternativeLayOddsValue,
  betsSideValue,
  oddsValue,
  shouldSwapColors
} from 'utils/myBetsValues';
import convertOdds from 'utils/oddsConverter';

import DescriptionCell from '../DescriptionCell';

import styles from './styles.module.scss';

interface ContentItemProps {
  item: TMyBetsContentItem;
  currentStatus?: BetStatuses;
}

const ContentItem = ({ item, currentStatus }: ContentItemProps) => {
  const { t } = useTranslation();

  const alternativeLayOdds = useSelector(getDesktopSettingsAlternativeLayOdds);
  const totalWinnings = useSelector(getDesktopSettingsTotalWinnings);
  const lineMarketsSwitchBackLayOnCricket = useSelector(getDesktopSettingsLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getDesktopSettingsReplaceBackLayWithUnderOver);
  const gameType = useSelector(getGameType);
  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const accountProducts = useSelector(getAccountProductsByBetsType(BetsType.All_MY_BETS));

  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE]);
  const { swapColorsFancyMarketsOnCricket } = useDeviceSettings();

  const [openGameResultModal, setOpenGameResultModal] = useState(false);

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const currencySettings = {
    noRounding: true,
    isCheckIndian: true,
    noSymbol: !isMultiCurrencySupported
  };

  const isAsianView = accountProducts?.asianView && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView;
  const { noFormattedAmount: winnings } = useFormatCurrency(item.totalWinnings || 0, item.currency, currencySettings);

  const profitValue = currentStatus === BetStatuses.Settled ? item.profit : item.profitNet;
  const { noFormattedAmount: profit } = useFormatCurrency(profitValue || 0, item.currency, currencySettings);

  const { noFormattedAmount: liability } = useFormatCurrency(
    currentStatus === BetStatuses.Settled ? item.pastTotalLiability : item.liability,
    item.currency,
    currencySettings
  );

  const { noFormattedAmount: stake } = useFormatCurrency(item.size || 0, item.currency, currencySettings);

  const dateToShow =
    currentStatus === BetStatuses.Cancelled
      ? item.cancelledDate
      : currentStatus !== BetStatuses.Unmatched && currentStatus !== BetStatuses.Matched
      ? item.settledDate
      : item.placedDate;

  const { date } = useParseMyBetsDates({
    dateToParse: dateToShow
  });

  const isMatchedOrSettled = currentStatus === BetStatuses.Matched || currentStatus === BetStatuses.Settled;

  const backColors = shouldSwapColors(item, swapColorsFancyMarketsOnCricket)
    ? item.side === EBetSide.Lay
    : item.side === EBetSide.Back;
  const layColors = shouldSwapColors(item, swapColorsFancyMarketsOnCricket)
    ? item.side === EBetSide.Back
    : item.side === EBetSide.Lay;

  const getStatusValue = useCallback(() => {
    if (isPNCEnabled && currentStatus === BetStatuses.Matched) {
      return t('pnc.account.mybets.labels.types.matched');
    }
    if (currentStatus === BetStatuses.Settled) {
      return t(`account.mybets.labels.${item.offerState.toLowerCase()}`);
    } else if (currentStatus) {
      return t(`account.mybets.labels.types.${currentStatus.toLowerCase()}`) as string;
    }
    return '';
  }, [currentStatus, isPNCEnabled, item.offerState]);

  const getOddsTooltipValue = () => {
    const avgOdds = oddsValue(item, { isRounded: false });

    if (isAsianView) {
      return `${avgOdds} ${t(`asianView.labels.oddsType.short.${OddsType.DEC}`)}`;
    }

    return unescape(t('betslip.tooltip.avgPrice', { N: alternativeLayOdds ? item.averagePrice : avgOdds }));
  };

  const oddsTooltipValue = getOddsTooltipValue();

  return (
    <div
      key={item.offerId}
      className={classNames('biab_my-bets-table-body', styles.tableBody, {
        [styles.back]: backColors,
        'biab_bet-indicator biab_bet-side-back betSideBack': backColors,
        [styles.lay]: layColors,
        'biab_bet-indicator biab_bet-side-lay betSideBack': layColors
      })}
    >
      <MyBetsStandardCell entity="date" classname={styles.type} value={date} />
      <div className={classNames(styles.contentItemWrapperHover, 'contentItemWrapperHover')}>
        <DescriptionCell bet={item} currentStatus={currentStatus} onClickEventName={setOpenGameResultModal} />
        <div className={styles.myBetsDescriptionColumnWrap}>
          <MyBetsStandardCell
            entity="side"
            value={t(betsSideValue(item, lineMarketsSwitchBackLayOnCricket, replaceBackLayWithUnderOver))}
            capitalise
            classname={styles.type}
          />
          {isAsianView && (
            <MyBetsStandardCell
              entity="oddsType"
              value={t(`asianView.labels.oddsType.short.${item.oddsType}`)}
              classname={styles.type}
            />
          )}
          {!isAsianView && alternativeLayOdds ? (
            <>
              <MyBetsStandardCell
                entity="alternativeBackOdds"
                isWithTooltip={item.side === EBetSide.Back && isMatchedOrSettled}
                tooltipValue={oddsTooltipValue}
                value={alternativeBackOddsValue(item)}
                capitalise
                classname={styles.odds}
              />
              <MyBetsStandardCell
                entity="alternativeLayOdds"
                isWithTooltip={item.side !== EBetSide.Back && isMatchedOrSettled}
                tooltipValue={oddsTooltipValue}
                value={alternativeLayOddsValue(item)}
                capitalise
                classname={styles.odds}
              />
            </>
          ) : (
            <MyBetsStandardCell
              entity="odds"
              isWithTooltip={(item.bettingType !== lineBettingType && isMatchedOrSettled) || item.asian}
              tooltipValue={oddsTooltipValue}
              value={isAsianView ? convertOdds(oddsValue(item) || '', item.oddsType) : oddsValue(item)}
              capitalise
              classname={styles.odds}
            />
          )}
          <MyBetsStandardCell entity="size" value={stake} classname={styles.odds} />
          {isAsianView && (
            <MyBetsStandardCell entity="liability" value={liability || '--'} classname={styles.liability} />
          )}
          {!isAsianView && totalWinnings && (
            <>
              <MyBetsStandardCell entity="liability" value={liability || '--'} classname={styles.liability} />
              <MyBetsStandardCell
                entity="winnings"
                value={item.totalWinnings ? winnings : ''}
                classname={styles.winnings}
              />
            </>
          )}
          <MyBetsStandardCell entity="profit" value={profitValue ? profit : ''} classname={styles.profit} />
          <MyBetsStandardCell entity="status" value={getStatusValue()} capitalise classname={styles.status} />
        </div>
      </div>

      <Modal
        clickableBackground
        open={openGameResultModal}
        onClose={() => setOpenGameResultModal(false)}
        title={`${item.sportName} ${t('games.gameId')}: ${item.marketId}`}
        customClassNames={{
          header: styles.modal__header,
          content: classNames(styles.modal__content, {
            [styles.modal__baccaratContent]: gameType === EGameTypes.BACCARAT,
            [styles.modal__hiLoContent]: gameType === EGameTypes.HILO,
            [styles.modal__blackJackContent]: gameType === EGameTypes.BLACKJACK
          }),
          dialog: styles.modal__gamesModalDialog
        }}
        isDisableScroll
      >
        <GameResultPopup channelId={item.eventTypeId} gameId={item.eventId} />
      </Modal>
    </div>
  );
};

export default ContentItem;
