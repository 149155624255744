import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isNaN, isNumber } from 'lodash';

import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getCurrencyPrecisionValue } from 'hooks/usePrecision';
import { getPrecisionType } from 'redux/modules/user/selectors';
import { TBetLabelDataItem } from 'types/betslip';
import { round } from 'utils/liability';

import styles from './styles.module.scss';

const BetLabelsItemTpl = ({ label, profitLoss, currency }: TBetLabelDataItem) => {
  const precisionType = useSelector(getPrecisionType);

  const profitLossRounded = round(profitLoss || 0, getCurrencyPrecisionValue(precisionType));
  const { noFormattedAmount: plFormatted } = useFormatCurrency(profitLossRounded, currency, {
    isCheckIndian: true,
    noRounding: true
  });

  return (
    <div className={styles.betLabel}>
      <span>{label ?? ''}</span>
      <span
        className={classNames({
          [styles.betLabel__positive]: profitLossRounded >= 0,
          [styles.betLabel__negative]: profitLossRounded < 0
        })}
      >
        {isNumber(profitLoss) && !isNaN(profitLoss) ? plFormatted : ''}
      </span>
    </div>
  );
};

export default BetLabelsItemTpl;
