import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { TIME_TO_SHOW_DAY_LIMIT_REMINDER_IN_MINUTES } from 'constants/responsibleBetting';
import { useAppThunkDispatch } from 'redux/configureStore';
import { getTimezone, getTimezoneCookieEnabled } from 'redux/modules/appConfigs/selectors';
import { updateResponsibleBettingDayLimitReminder } from 'redux/modules/responsibleBetting';
import { getResponsibleBettingSettings } from 'redux/modules/responsibleBetting/selectors';
import { fetchUserInfo } from 'redux/modules/user';
import { getUserSessionInfo } from 'redux/modules/user/selectors';
import { applySelectedTimezoneToDate, applySelectedTimezoneToUTCDate } from 'utils/date';

import ReminderBettingModalTemplate from '../ReminderBettingModalTemplate';

const ResponsibleBettingDayLimitModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const timezone = useSelector(getTimezone);
  const timezoneCookieEnabled = useSelector(getTimezoneCookieEnabled);
  const rbSettings = useSelector(getResponsibleBettingSettings);
  const sessionInfo = useSelector(getUserSessionInfo);

  const [open, setOpen] = useState<boolean>(false);

  const thunkDispatch = useAppThunkDispatch();

  const { dayLimitStartDate, dayLimitEnabled, dayLimit } = rbSettings?.rgTools ?? {};

  const closeReminder = () => {
    setOpen(false);
    thunkDispatch(updateResponsibleBettingDayLimitReminder());
    dispatch(fetchUserInfo());
  };

  useEffect(() => {
    if (sessionInfo?.startSessionDate && dayLimitEnabled && dayLimit && dayLimitStartDate) {
      const dayLimitEndDate = applySelectedTimezoneToUTCDate(dayLimitStartDate, timezone, timezoneCookieEnabled);
      dayLimitEndDate.setHours(dayLimitEndDate.getHours() + dayLimit);
      const showDayLimitReminderDate = new Date(dayLimitEndDate);
      const currentTime = applySelectedTimezoneToDate(new Date(), timezone, timezoneCookieEnabled).getTime();

      showDayLimitReminderDate.setMinutes(
        showDayLimitReminderDate.getMinutes() - TIME_TO_SHOW_DAY_LIMIT_REMINDER_IN_MINUTES
      );
      const timeToShowDayLimitReminder = showDayLimitReminderDate.getTime() - currentTime;

      const reminderTimeout = setTimeout(() => {
        setOpen(true);
      }, timeToShowDayLimitReminder);

      return () => {
        clearTimeout(reminderTimeout);
      };
    }
  }, [sessionInfo?.startSessionDate, dayLimitEnabled, dayLimit, dayLimitStartDate, timezone, timezoneCookieEnabled]);

  return (
    <ReminderBettingModalTemplate
      title={t('responsibleBetting.labels.dayLimitReminder.title')}
      open={open}
      onClose={closeReminder}
      message={t('responsibleBetting.labels.dayLimitReminder.content')}
    />
  );
};

export default ResponsibleBettingDayLimitModal;
