import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import BetslipSkeleton from 'components/Skeleton/BetslipSkeleton';
import { VALIDATION_ERROR_BET_BACK_LESS_LAY } from 'constants/placement';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { getLoggedInStatusState } from 'redux/modules/auth/selectors';
import {
  getBackLessLayError,
  getBetslipCurrentMarketId,
  getBetslipCurrentSportId,
  getBetslipLoading,
  getBetslipPageBlock,
  getSelectedBetsAmount
} from 'redux/modules/betslip/selectors';
import { getPlacement } from 'redux/modules/placement/selectors';
import { getMarketDataById, getMarketPricesById } from 'redux/modules/placement/selectors';
import { getUserCurrency } from 'redux/modules/user/selectors';
import { BetTypes } from 'types/bets';

import BetslipError from '../BetslipError';
import PlaceBetsActions from '../PlaceBetsActions';
import SelectedBets from '../SelectedBets';
import TotalLiability from '../TotalLiability';

import styles from './styles.module.scss';

const PlaceBetsTab = () => {
  const { t } = useTranslation();

  const isLoggedIn = useSelector(getLoggedInStatusState);
  const userCurrency = useSelector(getUserCurrency);
  const { error: placementError } = useSelector(getPlacement);
  const currentMarketId = useSelector(getBetslipCurrentMarketId);
  const currentSportId = useSelector(getBetslipCurrentSportId);
  const pageBlock = useSelector(getBetslipPageBlock);
  const isLoading = useSelector(getBetslipLoading);
  const selectedBetsAmount = useSelector(getSelectedBetsAmount);
  const backLessLayError = useSelector(getBackLessLayError);
  const market = useSelector(getMarketDataById(pageBlock, currentMarketId, currentSportId));
  const marketPrices = useSelector(getMarketPricesById(pageBlock, currentMarketId));

  const { isMultiCurrencySupported } = useMultiCurrencySupporting();

  const marketCurrency = marketPrices?.currency;
  const isCurrencyChanged =
    isMultiCurrencySupported && isLoggedIn && marketCurrency && userCurrency && userCurrency !== marketCurrency;
  const isSelectedBetsEnabled = !isMultiCurrencySupported || !isCurrencyChanged;

  return (
    <div
      className={classNames(styles.placeBetsWrap, {
        [styles.disableScroll]: isLoading
      })}
    >
      {isLoading && <BetslipSkeleton isAbsolute itemsCount={selectedBetsAmount ?? 6} />}
      {market && selectedBetsAmount > 0 && <BetslipError />}
      {backLessLayError && <div className={styles.errorMessage}>{t(VALIDATION_ERROR_BET_BACK_LESS_LAY)}</div>}
      {!!placementError?.message && <div className={styles.errorMessage}>{placementError.message}</div>}
      {market &&
        [BetTypes.BACK, BetTypes.LAY].map(type => (
          <SelectedBets key={type} type={type} market={market} isSubHeaderEnabled={!isCurrencyChanged} />
        ))}
      {isSelectedBetsEnabled && (
        <>
          {market && selectedBetsAmount > 0 && (
            <TotalLiability
              market={market}
              marketDefinition={marketPrices?.marketDefinition}
              currency={marketPrices?.currency}
            />
          )}
          {market && selectedBetsAmount > 0 ? (
            <PlaceBetsActions market={market} />
          ) : (
            <div className={styles.emptyMessage}>{t('betslip.messages.noBets')}</div>
          )}
        </>
      )}
    </div>
  );
};

export default PlaceBetsTab;
