import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isString } from 'lodash';

import { SLICES_NAMES } from 'constants/app';
import { TPlacedBets } from 'redux/modules/currentBets/type';
import { TPlacementError } from 'redux/modules/placement/type';
import { PageBlocks } from 'types';
import { BetTypes, THandicap } from 'types/bets';
import { EBetslipTypes, EPlacementType, TBetslipMessage } from 'types/betslip';

import {
  EBetErrors,
  EBetslipFilters,
  EBetslipTabs,
  EKeepSelectedBets,
  EPlaceBetsStates,
  TBetslipFilterPayload,
  TBetslipState,
  TSelectedBet,
  TSelectedBetsByMarket,
  TSelectedBetsBySelection,
  TSelectedBetsByTypes
} from './type';

const initialState: TBetslipState = {
  collapse: false,
  activeTab: EBetslipTabs.PLACE_BETS,
  filters: {
    [EBetslipFilters.MARKET]: false,
    [EBetslipFilters.MATCHED]: false,
    [EBetslipFilters.SORTING]: false
  },
  currentSportId: '',
  currentMarketId: '',
  pageBlock: PageBlocks.HOME,
  selectedBets: {},
  placedBets: {},
  placedBetsToUpdate: {},
  placementMessage: { message: '' },
  placeBetsState: EPlaceBetsStates.SELECT,
  loading: false,
  errorMessage: '',
  consolidateBets: false,
  resetBetslipTab: false,
  betslipType: EBetslipTypes.EXCHANGE,
  isFirstOpenedBetFocused: false,
  disableNextResetBetslipTab: false,
  rgErrorMessage: null,
  keepSelectedBets: EKeepSelectedBets.NONE
};

const slice = createSlice({
  name: SLICES_NAMES.BET_SLIP,
  initialState,
  reducers: {
    setCollapseState: (state, { payload }: PayloadAction<boolean>) => {
      state.collapse = payload;
    },
    setActiveTab: (state, { payload }: PayloadAction<EBetslipTabs>) => {
      state.activeTab = payload;
      state.loading = false;
      state.resetBetslipTab = false;

      if (payload === EBetslipTabs.PLACE_BETS) {
        state.placedBets = {};
        state.placementMessage = { message: '' };
      }
    },
    setFilterState: (state, { payload }: PayloadAction<TBetslipFilterPayload>) => {
      state.filters = {
        ...state.filters,
        ...payload
      };
    },
    setSelectedBets: (state, { payload }: PayloadAction<TSelectedBet[]>) => {
      const { pageBlock, marketId, sportId, type } = payload[0] ?? {};
      const isBetAllSelected = payload.every(({ isBetAll }: { isBetAll?: boolean }) => isBetAll);
      const selectedTypesByMarket: TSelectedBetsByMarket = state.selectedBets[marketId] ?? {};

      const isAllBetsSelectedInStorage =
        isBetAllSelected &&
        payload.every(({ selectionId, handicap }: { selectionId: number; handicap?: THandicap | null }) =>
          Object.keys(selectedTypesByMarket[type] ?? {}).includes(`${selectionId}_${+(handicap ?? 0)}`)
        );

      state.placeBetsState = EPlaceBetsStates.SELECT;
      state.pageBlock = pageBlock as PageBlocks;
      state.currentMarketId = marketId;
      state.currentSportId = sportId as string;
      state.collapse = false;
      /** If market changed clean selected bets for previous market. Keep bets only for one market. */
      state.selectedBets = payload.reduce(
        (res: TSelectedBetsByMarket, bet: TSelectedBet) => {
          /**
           * Map selected bets by selection id and handicap values.
           * This combination is uniq for handicap double line markets.
           */
          const betKey = `${bet.selectionId}_${+(bet.handicap ?? 0)}`;
          const selectedBetsByMarket: TSelectedBetsByTypes = res[marketId] ?? {};

          const selectedBetsByType: TSelectedBetsBySelection = selectedBetsByMarket[bet.type as BetTypes] ?? {};
          const { [betKey]: selectedBet, ...restSelectedBets } = selectedBetsByType;

          return {
            ...res,
            [bet.marketId]: {
              ...selectedBetsByMarket,
              [bet.type]: {
                /**
                 * Remove bet if user selects bet when it is already in the selected bets list
                 * Or add bet if it is not in the list
                 */
                ...(!selectedBet ||
                (selectedBet &&
                  (!bet.isBetAll || !isAllBetsSelectedInStorage) &&
                  /** Compare bets with initial prices before min valid value is set for LINE market */
                  (selectedBet.hasOwnProperty('initPrice')
                    ? selectedBet.initPrice !== bet.price
                    : selectedBet.price !== bet.price)) ||
                (bet.isBetAll && !isAllBetsSelectedInStorage)
                  ? {
                      ...(selectedBetsByMarket[bet.type as BetTypes] ?? {}),
                      [betKey]: {
                        ...((selectedBetsByMarket[bet.type as BetTypes] ?? {})[betKey] ?? {}),
                        ...bet,
                        ...{ isBetAll: false }
                      }
                    }
                  : { ...restSelectedBets })
              }
            }
          };
        },
        { ...(state.selectedBets.hasOwnProperty(marketId) ? state.selectedBets : {}) }
      );
    },
    updateSelectedBet: (state, { payload }: PayloadAction<TSelectedBet>) => {
      const betKey = `${payload.selectionId}_${Number(payload.handicap)}`;
      const selectedBetsByMarket = state.selectedBets[payload.marketId] ?? {};
      const oppositeType = payload.type === BetTypes.BACK ? BetTypes.LAY : BetTypes.BACK;
      const oppositeBet = state.selectedBets[state.currentMarketId]?.[oppositeType]?.[betKey];

      /**
       * Error occurs when back and a lay for the same selection at overlapping prices.
       */
      const isBackLessLayError = () => {
        if (payload.price && oppositeBet && oppositeBet.price) {
          if (payload.type === BetTypes.BACK) {
            return payload.price <= oppositeBet.price;
          } else {
            return oppositeBet.price <= payload.price;
          }
        } else {
          return false;
        }
      };

      state.selectedBets[payload.marketId] = {
        ...selectedBetsByMarket,
        [payload.type]: {
          ...(selectedBetsByMarket[payload.type as BetTypes] ?? {}),
          [betKey]: {
            ...((selectedBetsByMarket[payload.type as BetTypes] ?? {})[betKey] ?? {}),
            ...payload,
            ...(isBackLessLayError() ? { error: EBetErrors.EX015 } : { error: null })
          }
        },
        ...(oppositeBet
          ? {
              [oppositeType]: {
                ...(selectedBetsByMarket[oppositeType] ?? {}),
                [betKey]: {
                  ...oppositeBet,
                  ...(isBackLessLayError() ? { error: EBetErrors.EX015 } : { error: null })
                }
              }
            }
          : {})
      };
    },
    removeSelectedBet: (state, { payload }: PayloadAction<TSelectedBet>) => {
      const betKey = `${payload.selectionId}_${+Number(payload.handicap)}`;

      const selectedBetsByMarket = state.selectedBets[payload.marketId] ?? {};
      const selectedBetsByType = selectedBetsByMarket[payload.type as BetTypes] ?? {};
      const oppositeType = payload.type === BetTypes.BACK ? BetTypes.LAY : BetTypes.BACK;
      const oppositeBet = state.selectedBets[state.currentMarketId]?.[oppositeType]?.[betKey];
      const { [betKey]: removedSelectedBet, ...restSelectedBets } = selectedBetsByType;

      state.errorMessage = '';
      state.selectedBets[payload.marketId] = {
        ...selectedBetsByMarket,
        [payload.type]: {
          ...restSelectedBets
        },
        ...(oppositeBet
          ? {
              [oppositeType]: {
                ...(selectedBetsByMarket[oppositeType] ?? {}),
                [betKey]: {
                  ...oppositeBet,
                  ...{ error: null }
                }
              }
            }
          : {})
      };
    },
    removeAllSelectedBets: state => {
      state.pageBlock = '' as PageBlocks;
      state.currentMarketId = '';
      state.currentSportId = '';
      state.selectedBets = {};
      state.errorMessage = '';
    },
    setPlaceBetsState: (state, { payload }: PayloadAction<EPlaceBetsStates>) => {
      state.placeBetsState = payload;
    },
    setErrorMessage: (state, { payload }: PayloadAction<TPlacementError | string>) => {
      state.errorMessage = isString(payload) ? payload : payload?.response?.data?.message ?? '';
    },
    setBetslipLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    setPlacedBets: (state, { payload }: PayloadAction<number[]>) => {
      state.placedBets = {
        ...state.placedBets,
        ...payload.reduce((result: TPlacedBets, offerId: number) => {
          return { ...result, ...{ [offerId]: EPlacementType.PLACE } };
        }, {})
      };
    },
    setUpdatedBets: (state, { payload }: PayloadAction<number[]>) => {
      state.placedBets = {
        ...state.placedBets,
        ...payload.reduce((result: TPlacedBets, offerId: number) => {
          return { ...result, ...{ [offerId]: EPlacementType.UPDATE } };
        }, {})
      };
    },
    removePlacedBet: (state, { payload }: PayloadAction<number>) => {
      delete state.placedBets[payload];
    },
    setPlacementMessage: (state, { payload }: PayloadAction<TBetslipMessage>) => {
      state.placementMessage = payload;
    },
    setConsolidateBets: (state, { payload }: PayloadAction<boolean>) => {
      state.consolidateBets = payload;
    },
    setResetBetslipTab: state => {
      if (state.disableNextResetBetslipTab) {
        state.disableNextResetBetslipTab = true;
      } else {
        state.resetBetslipTab = false;
      }
    },
    setDisableNextResetBetslipTab: state => {
      state.disableNextResetBetslipTab = true;
    },
    setPlacedBetsToUpdate: (state, { payload }: PayloadAction<{ offerId: number; placementType: EPlacementType }>) => {
      state.placedBetsToUpdate[payload.offerId] = payload.placementType;
    },
    removePlacedBetsToUpdate: (state, { payload }: PayloadAction<number>) => {
      delete state.placedBetsToUpdate[payload];
    },
    setBetslipType: (state, { payload }: PayloadAction<EBetslipTypes>) => {
      state.betslipType = payload;
    },
    setFirstOpenedBetFocused: (state, { payload }: PayloadAction<boolean>) => {
      state.isFirstOpenedBetFocused = payload;
    },
    setRGErrorMessage: (state, { payload }: PayloadAction<TPlacementError | null | string>) => {
      state.rgErrorMessage = payload;
    },
    setKeepSelectedBets: (state, { payload }: PayloadAction<EKeepSelectedBets>) => {
      state.keepSelectedBets = payload;
    }
  }
});

export const {
  setFirstOpenedBetFocused,
  setKeepSelectedBets,
  setPlaceBetsState,
  setPlacedBets,
  setPlacedBetsToUpdate,
  removePlacedBetsToUpdate,
  removeSelectedBet,
  removeAllSelectedBets,
  removePlacedBet,
  updateSelectedBet,
  setConsolidateBets,
  setSelectedBets,
  setUpdatedBets,
  setBetslipType,
  setCollapseState,
  setDisableNextResetBetslipTab,
  setResetBetslipTab,
  setRGErrorMessage,
  setErrorMessage,
  setFilterState,
  setBetslipLoading,
  setPlacementMessage,
  setActiveTab
} = slice.actions;

export default slice.reducer;
