import { createSelector } from '@reduxjs/toolkit';

import { AppState } from 'redux/reducers';
import { BettingType } from 'types/markets';

export const getPlacementMessage = (state: AppState) => state.placementMessage;

export const getMessageBetByMarket = (marketId: string) =>
  createSelector(getPlacementMessage, placementMessage => (marketId ? placementMessage.bets[marketId] ?? null : null));

// TODO - need to write
export const getMessageBetByMarketOLD = (marketId: string) => (state: AppState) =>
  marketId ? state.placementMessage.bets[marketId] ?? null : null;

export const getMessageBetBySelection =
  (marketId: string, selectionId: number, handicap: string | number | null) => (state: AppState) => {
    const messageBet = getMessageBetByMarketOLD(marketId)(state);
    return messageBet &&
      messageBet.selectionId === selectionId &&
      (+(messageBet.handicap || 0) === +(handicap || 0) || messageBet.bettingType === BettingType.LINE)
      ? messageBet
      : null;
  };
