import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SLICES_NAMES } from 'constants/app';
import { TFailureActionPayload } from 'types';

import {
  TCancelAllBetsPayload,
  TCancelBetsPayload,
  TEditBetsPayload,
  TPlaceBetsPayload,
  TPlacedBetsByMarket,
  TPlacedBetsState
} from './type';

const initialState: TPlacedBetsState = {
  loading: false,
  error: null,
  cancelledBetsStatusId: null
};

const slice = createSlice({
  name: SLICES_NAMES.PLACEMENT,
  initialState,
  reducers: {
    placeBets: (state, _: PayloadAction<TPlaceBetsPayload>) => {
      state.loading = true;
    },
    successPlaceBets: (state, _: PayloadAction<TPlacedBetsByMarket>) => {
      state.loading = false;
    },
    failurePlaceBets: (state, { payload }: PayloadAction<TFailureActionPayload>) => {
      state.error = payload;
      state.loading = false;
    },
    editBets: (_, __: PayloadAction<TEditBetsPayload>) => {},
    successEditBets: (_, __: PayloadAction<TPlacedBetsByMarket>) => {},
    failureEditBets: (_, __: PayloadAction<TFailureActionPayload>) => {},
    cancelBets: (_, __: PayloadAction<TCancelBetsPayload>) => {},
    successCancelBets: (_, __: PayloadAction<TPlacedBetsByMarket>) => {},
    failureCancelBets: (_, __: PayloadAction<TFailureActionPayload>) => {},
    cancelAllBets: (_, __: PayloadAction<TCancelAllBetsPayload>) => {},
    successCancelAllBets: (state, { payload }: PayloadAction<number | null>) => {
      state.cancelledBetsStatusId = payload;
    },
    failureCancelAllBets: (_, __: PayloadAction<TFailureActionPayload>) => {}
  }
});

export const {
  placeBets,
  successPlaceBets,
  failurePlaceBets,
  cancelBets,
  editBets,
  failureCancelAllBets,
  failureCancelBets,
  failureEditBets,
  successCancelAllBets,
  successCancelBets,
  successEditBets,
  cancelAllBets
} = slice.actions;

export default slice.reducer;
