import { toNumber } from 'lodash';

import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { SportId } from 'types';

import { isLineBettingType } from './betslip';
import { roundPriceForLineBet } from './betValidation';

/**
 * Check if offer is MATCHED
 *
 * @param bet
 */
export const isMatchedOffer = (bet: TCurrentBet) =>
  /** if precision of '1' is set for virtual currency */
  (toNumber(bet.sizeMatched) === 0 &&
    toNumber(bet.sizeRemaining) === 0 &&
    toNumber(bet.sizeCancelled) === 0 &&
    bet.offerState === BetsStatusesTypes.MATCHED) ||
  /** if it is fully matched */
  (toNumber(bet.sizeMatched) > 0 && toNumber(bet.sizeRemaining) === 0) ||
  /** if it is partially matched */
  (toNumber(bet.sizeMatched) > 0 && toNumber(bet.sizeRemaining) > 0);

/**
 * Check if offer is UNMATCHED or PARTIALLY MATCHED
 *
 * @param bet
 */
export const isUnmatchedOffer = (bet: TCurrentBet) => {
  return (
    bet.action !== ECurrentBetActions.HIDDEN &&
    (bet.offerState === BetsStatusesTypes.PLACED ||
      bet.action === ECurrentBetActions.FULLY_MATCHED ||
      (bet.offerState === BetsStatusesTypes.CANCELLED && bet.action === ECurrentBetActions.CANCELLING) ||
      (bet.offerState === BetsStatusesTypes.CANCELLED && bet.action === ECurrentBetActions.EDITING) ||
      (bet.offerState === BetsStatusesTypes.MATCHED && +(bet.sizeRemaining || 0) > 0) ||
      (bet.offerState === BetsStatusesTypes.MATCHED &&
        (bet.action === ECurrentBetActions.CANCELLING || bet.action === ECurrentBetActions.CANCELLING_ALL)))
  );
};

const roundPriceLineBet = (bet: TCurrentBet, priceField: 'price' | 'averagePriceRounded') =>
  isLineBettingType(bet.bettingType) && +bet.eventTypeId === +SportId.CRICKET
    ? roundPriceForLineBet({
        value: bet[priceField] ?? 0,
        interval: bet.interval ?? null
      })
    : bet[priceField] ?? 0;

export const mapBet = (bet: TCurrentBet) => {
  return {
    ...bet,
    ...{
      averagePriceRounded: roundPriceLineBet(bet, 'averagePriceRounded'),
      price: roundPriceLineBet(bet, 'price')
    }
  };
};
