import { isUndefined } from 'lodash';

import { ASIAN_VIEW_PLACE_BET_ERRORS_IDS } from 'constants/betslip';
import {
  VALIDATION_ERROR_BET_INVALID_SIZE,
  VALIDATION_ERROR_BET_MAX_STAKE,
  VALIDATION_ERROR_BET_MIN_STAKE
} from 'constants/placement';
import { TSuccessFetchCurrency } from 'redux/modules/appConfigs/type';
import { AsianViewSizeValidationType, TAsianViewPlaceBetErrorMessageId } from 'redux/modules/asianViewBetslip/type';
import { BetTypes, TSize } from 'types/bets';
import { adjustValue, isValidValue } from 'utils/betValidation';

const precisionFormat = (value: TSize, precision = 2) => parseFloat(value as string).toFixed(precision);

export const validateSize = ({
  size,
  betType,
  currency,
  defaultCurrency,
  currencyCode,
  isOperatorBettingLimitsEnabled
}: {
  size: TSize;
  betType: BetTypes;
  currency?: TSuccessFetchCurrency;
  defaultCurrency?: TSuccessFetchCurrency;
  currencyCode?: string;
  isOperatorBettingLimitsEnabled: boolean;
}): {
  validValue?: string | number;
  errorMessage: { text: string; params: Record<string, number | string | undefined> } | null;
  isValid: boolean;
  type: AsianViewSizeValidationType;
  messageId: null | TAsianViewPlaceBetErrorMessageId;
} => {
  const minBetSizeField = 'minBetSize';
  const maxBetSizeField = 'maxBetSize';
  const stepField = 'step';
  const min = currency?.[minBetSizeField] || defaultCurrency?.[minBetSizeField] || 0;
  const max = currency?.[maxBetSizeField] || defaultCurrency?.[maxBetSizeField] || 0;
  const step = currency?.[stepField] || defaultCurrency?.[stepField] || 0;
  const value: TSize = size !== '' && size !== undefined ? parseFloat(size as string) : size;
  const isEmptyValue = size === '';

  if (isEmptyValue) {
    return { validValue: '', errorMessage: null, isValid: false, type: null, messageId: null };
  } else if (!isUndefined(value)) {
    if (+value < min) {
      return {
        validValue: min,
        errorMessage: {
          text: VALIDATION_ERROR_BET_MIN_STAKE,
          params: { currency_ISO_code: currency?.symbol || currencyCode, min: precisionFormat(min) }
        },
        isValid: false,
        type: 'min',
        messageId: ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX013
      };
    } else if (+value > max) {
      if (!isOperatorBettingLimitsEnabled) {
        return {
          validValue: max,
          errorMessage: {
            text: VALIDATION_ERROR_BET_MAX_STAKE,
            params: { currency_ISO_code: currency?.symbol || currencyCode, max: precisionFormat(max) }
          },
          isValid: false,
          type: 'max',
          messageId: ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX014
        };
      }

      return {
        validValue: value,
        errorMessage: null,
        isValid: true,
        type: null,
        messageId: null
      };
    } else if (!isValidValue(value, step, min)) {
      const adjustedValue = adjustValue(value, step, 0, betType);
      return {
        validValue: adjustedValue,
        errorMessage: { text: VALIDATION_ERROR_BET_INVALID_SIZE, params: { number: step } },
        isValid: false,
        type: 'invalid',
        messageId: ASIAN_VIEW_PLACE_BET_ERRORS_IDS.EX016
      };
    } else {
      return { validValue: value, errorMessage: null, isValid: true, type: null, messageId: null };
    }
  }

  return { validValue: value, errorMessage: null, isValid: false, type: null, messageId: null }; // TODO check if isValid should be false in this case
};
