import { useTranslation } from 'react-i18next';

import { BetTypes, TPrice, TSize } from 'types/bets';
import { getCalculatedParams } from 'utils/betLabels';
import { getEachWayProfit } from 'utils/totalLiability';

import BetLabelsTpl from '../BetLabelsTpl';

type EachWayLabelProps = {
  price: TPrice;
  size: TSize;
  betType: BetTypes;
  eachWayDivisor: number | null;
  currency?: string;
};

const EachWayLabel = ({ price, size, betType, eachWayDivisor, currency }: EachWayLabelProps) => {
  const { t } = useTranslation();

  const { odds, stake } = getCalculatedParams({ price, size, betType });
  const eachWayPL = getEachWayProfit(stake, odds, eachWayDivisor ?? 1, betType);

  const profitLossW = odds && stake ? eachWayPL.win : null;
  const profitLossS = odds && stake ? eachWayPL.place : null;
  const profitLossL = odds && stake ? eachWayPL.lose : null;

  return (
    <BetLabelsTpl
      labelW={t('betslip.labels.EWIfWin')}
      labelS={t('betslip.labels.EWIfPlaced')}
      labelL={t('betslip.labels.EWIfUnplaced')}
      profitLossW={profitLossW}
      profitLossS={profitLossS}
      profitLossL={profitLossL}
      currency={currency}
    />
  );
};

export default EachWayLabel;
