import { useState } from 'react';
import classNames from 'classnames';

import { usePlacementInputLabels } from 'hooks/useLabels';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { ButtonActionTypes, ESizes, InputTypes, TInlinePlacement } from 'types/inlinePlacement';
import { TMarketLineRangeInfo } from 'types/markets';

import InlinePlacementButton from '../InlinePlacementButton';
import InlinePlacementInput from '../InlinePlacementInput';

import styles from './styles.module.scss';

interface IInlinePlacedConfirm {
  bet: TInlineSelectedBet;
  marketType?: string;
  bettingType?: string;
  lineRangeInfo?: TMarketLineRangeInfo | null;
  eachWayDivisor?: number | null;
  isLineMarket: boolean;
  isInputLabels: boolean;
  componentSize: ESizes;
  onEditBtnHandler: () => void;
  onConfirmBtnHandler: (val: TInlinePlacement) => void;
}

const InlinePlacementConfirm = ({
  bet,
  marketType,
  bettingType,
  lineRangeInfo,
  eachWayDivisor,
  isLineMarket,
  isInputLabels,
  componentSize,
  onEditBtnHandler,
  onConfirmBtnHandler
}: IInlinePlacedConfirm) => {
  const labels = usePlacementInputLabels(bet.type, isLineMarket, lineRangeInfo?.marketUnit);

  const [focusedElement, setFocusedElement] = useState<ButtonActionTypes>(ButtonActionTypes.CONFIRM);

  const onTabNavigate = (type: InputTypes | ButtonActionTypes) => {
    switch (type) {
      case ButtonActionTypes.CONFIRM:
        setFocusedElement(ButtonActionTypes.EDIT);
        break;
      case ButtonActionTypes.EDIT:
        setFocusedElement(ButtonActionTypes.CONFIRM);
        break;
    }
  };

  const editBtn = (
    <InlinePlacementButton
      buttonType={ButtonActionTypes.EDIT}
      onClickCallback={onEditBtnHandler}
      componentSize={componentSize}
      onTabNavigate={onTabNavigate}
      isFocused={focusedElement === ButtonActionTypes.EDIT}
    />
  );

  const confirmBtn = (
    <InlinePlacementButton
      buttonType={ButtonActionTypes.CONFIRM}
      price={bet.price}
      size={bet.size}
      marketType={marketType}
      betType={bet.type}
      bettingType={bettingType}
      eachWayDivisor={eachWayDivisor}
      isLineMarket={isLineMarket}
      componentSize={componentSize}
      onClickCallback={onConfirmBtnHandler}
      onTabNavigate={onTabNavigate}
      isFocused={focusedElement === ButtonActionTypes.CONFIRM}
    />
  );

  return (
    <>
      <div
        className={classNames(styles.inlinePlacementConfirmForm, {
          [styles.inlinePlacementConfirmForm__top]: componentSize === ESizes.SMALL
        })}
      >
        {componentSize !== ESizes.SMALL && editBtn}
        <InlinePlacementInput
          value={bet.price}
          isReadOnly
          isInputLabels={isInputLabels}
          componentSize={componentSize}
          label={labels[InputTypes.ODDS]}
          type={InputTypes.ODDS}
        />
        <InlinePlacementInput
          value={bet.size}
          isReadOnly
          isInputLabels={isInputLabels}
          componentSize={componentSize}
          label={labels[InputTypes.STAKE]}
          type={InputTypes.STAKE}
        />
        {componentSize !== ESizes.SMALL && confirmBtn}
      </div>
      {componentSize === ESizes.SMALL && (
        <div className={classNames(styles.inlinePlacementConfirmForm, styles.inlinePlacementConfirmForm__xs)}>
          {editBtn}
          {confirmBtn}
        </div>
      )}
    </>
  );
};

export default InlinePlacementConfirm;
