import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import LeaguesFilter from 'components/AsianViewPageModule/components/LeaguesFilter';
import { ASIAN_TABS_EARLY_PREFIX } from 'constants/app';
import {
  ASIAN_FAVOURITE_ID_PARAM,
  ASIAN_FAVOURITE_SPORT_ID_PARAM,
  ASIAN_FAVOURITE_TYPE_PARAM,
  AsianViewMarketLink
} from 'constants/asianView';
import { SEARCH_QUERY_LENGTH } from 'constants/search';
import { PARAMS_ASIAN_SEARCH_KEY } from 'constants/urlParams';
import useAsianSingleView from 'hooks/useAsianSingleView';

import DaysFilter from './DaysFilter';
import LayBetting from './LayBetting';
import LineViewFilter from './LineViewFilter';
import OddsTypeDropdown from './OddsTypeDropdown';
import QuickBetting from './QuickBetting';
import SortingFilter from './SortingFilter';

import styles from './styles.module.scss';

const Filters = () => {
  const { marketLink, timeFilter } = useParams();
  const [searchParams] = useSearchParams();

  const { isAsianSingleOutrightView, isAsianSingleEventView } = useAsianSingleView();

  const isEarlyTab = timeFilter === ASIAN_TABS_EARLY_PREFIX;
  const isOutRight = marketLink === AsianViewMarketLink.OUTRIGHT;
  const favouriteType = searchParams.get(ASIAN_FAVOURITE_TYPE_PARAM);
  const favouriteId = searchParams.get(ASIAN_FAVOURITE_ID_PARAM);
  const query = searchParams.get(PARAMS_ASIAN_SEARCH_KEY) || '';
  const favouriteSportId = searchParams.get(ASIAN_FAVOURITE_SPORT_ID_PARAM);
  const isFavouriteView = !!(favouriteType && favouriteId && favouriteSportId);
  const showLineViewFilter = isFavouriteView || query || marketLink == AsianViewMarketLink.HDP_AND_OU;
  const isOutRightView = isOutRight || isAsianSingleOutrightView;
  const isSearchView = query.length >= SEARCH_QUERY_LENGTH;

  return (
    <div className={classNames('biab_asian-view-filters', styles.filters)}>
      <div className={styles.filters__typeWrapper}>
        {isEarlyTab && !isFavouriteView && !isSearchView && !isOutRightView && !isAsianSingleEventView && (
          <DaysFilter />
        )}
        <LayBetting />
        {!isSearchView && !isOutRightView && <SortingFilter />}
        {!isSearchView && <LeaguesFilter />}
        {showLineViewFilter && (!isOutRightView || isSearchView) && <LineViewFilter />}
        <OddsTypeDropdown />
        <QuickBetting />
      </div>
    </div>
  );
};

export default Filters;
