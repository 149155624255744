import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import AsianViewMobileMenuButton from 'components/AsianViewMobileHeader/partials/AsianViewMobileMenuButton';
import CompetitionSection from 'components/AsianViewPageModule/components/CompetitionSection';
import CouponSettings from 'components/AsianViewPageModule/components/CouponSettings';
import LoadingSkeleton from 'components/AsianViewPageModule/components/LoadingSkeleton';
import AsianToolbarWrapper from 'components/MobileToolbar/components/AsianToolbarWrapper';
import Pagination from 'components/Pagination';
import { FAVORITES_TYPES } from 'constants/app';
import {
  ASIAN_VIEW_LIST_SIZE,
  AsianViewMarketLink,
  AsianViewSection,
  AsianViewSorting,
  AsianViewTimeFilter,
  FETCH_COUNT_MARKETS_INTERVAL
} from 'constants/asianView';
import { asianViewIcons, asianViewMiddleSection } from 'constants/branding';
import useAsianViewLineType from 'hooks/useAsianViewLineType';
import useElementSize from 'hooks/useElementSize';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import usePagination from 'hooks/usePagination';
import {
  getAsianViewMiddleSectionPageSize,
  getIsMobileInfiniteScrollEnabled,
  getIsPropertiesLoaded
} from 'redux/modules/appConfigs/selectors';
import { setElementHeight } from 'redux/modules/appSettings';
import { EElementNames } from 'redux/modules/appSettings/type';
import { fetchCountMarkets } from 'redux/modules/asianView';
import { fetchFavouritesMobileEventsList } from 'redux/modules/asianViewFavourites';
import {
  getAsianViewFavouritesList,
  getAsianViewFavouritesLoading,
  getAsianViewFavouritesMobileEventsCompetitionKeys,
  getAsianViewFavouritesMobileEventsIds,
  getAsianViewFavouritesMobileEventsLast,
  getAsianViewFavouritesMobileEventsLoading,
  getAsianViewFavouritesMobileEventsTotalElements,
  getAsianViewFavouritesMobileEventsTotalPages,
  getAsianViewIsFavouritesLoaded,
  getIsAsianViewFavourites
} from 'redux/modules/asianViewFavourites/selectors';
import { AsianViewFavouritePayload } from 'redux/modules/asianViewFavourites/type';
import { CookieNames } from 'types';
import { ELineViewTypes, ESortingTypes } from 'types/asianView';

import styles from './styles.module.scss';

const AsianViewFavouritesMobilePage = () => {
  const dispatch = useDispatch();
  const [cookies] = useCookies([CookieNames.ASIAN_VIEW_SORTING_FILTER]);
  const { t } = useTranslation();

  const favorites = useSelector(getAsianViewFavouritesList);
  const favouritesLoading = useSelector(getAsianViewFavouritesLoading);
  const isFavouritesLoaded = useSelector(getAsianViewIsFavouritesLoaded);
  const isFavourites = useSelector(getIsAsianViewFavourites);
  const isInfiniteScrollEnabled = useSelector(getIsMobileInfiniteScrollEnabled);
  const isPropertiesLoaded = useSelector(getIsPropertiesLoaded);
  const itemsCountOnPage = useSelector(getAsianViewMiddleSectionPageSize);
  const totalPages = useSelector(getAsianViewFavouritesMobileEventsTotalPages);
  const eventsLoading = useSelector(getAsianViewFavouritesMobileEventsLoading);
  const isLast = useSelector(getAsianViewFavouritesMobileEventsLast);
  const totalElements = useSelector(getAsianViewFavouritesMobileEventsTotalElements);
  const competitionKeys = useSelector(getAsianViewFavouritesMobileEventsCompetitionKeys);
  const eventsIds = useSelector(getAsianViewFavouritesMobileEventsIds);

  const [componentRef] = useElementSize<HTMLDivElement>({
    onChangeSizesHandler: ({ height }) => {
      dispatch(setElementHeight({ name: EElementNames.MOBILE_ASIAN_SUB_HEADER, height }));
    }
  });

  const [page, setPage] = useState(0);

  const fetchCountMarketsInterval = useRef<ReturnType<typeof setInterval> | null>(null);
  const competitionsAndCoupons = useRef<{ coupons: string[]; competitions: string[] }>({
    coupons: [],
    competitions: []
  });

  const sortingFilter = (cookies[CookieNames.ASIAN_VIEW_SORTING_FILTER] as ESortingTypes) || ESortingTypes.NORMAL;
  const sorting = AsianViewSorting[sortingFilter];
  const viewType = useAsianViewLineType();

  const { coupons, competitions } = useMemo(() => {
    if (favorites.length) {
      const couponsArr: string[] = [];
      const competitionsArr: string[] = [];

      favorites.forEach(({ entryType, entryId }) => {
        if (entryType === FAVORITES_TYPES.event) {
          couponsArr.push(entryId);
        } else if (entryType === FAVORITES_TYPES.competition) {
          competitionsArr.push(entryId);
        }
      });

      return { coupons: couponsArr, competitions: competitionsArr };
    }

    return { coupons: [], competitions: [] };
  }, [favorites]);

  competitionsAndCoupons.current.competitions = competitions;
  competitionsAndCoupons.current.coupons = coupons;

  const handleFetchAsianViewListPagination = (nextPage: number) => {
    dispatch(
      fetchFavouritesMobileEventsList({
        page: nextPage,
        size: itemsCountOnPage,
        payload: {
          marketLink: AsianViewMarketLink.HDP_AND_OU,
          timeFilter: AsianViewTimeFilter.ALL,
          competitions,
          coupons,
          viewType,
          sections: [],
          sorting
        },
        isInfiniteScrollEnabled: false
      })
    );
  };

  const {
    page: paginationPage,
    onClickNextPage,
    onClickPrevPage,
    onClickPage,
    changePage
  } = usePagination({
    onChangePage: handleFetchAsianViewListPagination,
    totalPages,
    isPaginationEnabled: !isInfiniteScrollEnabled
  });

  const infiniteScrollCallback = useCallback(() => {
    if (!eventsLoading && !isLast && isInfiniteScrollEnabled) {
      setPage(prevPage => prevPage + 1);
    }
  }, [eventsLoading, isLast, isInfiniteScrollEnabled]);

  const { lastElementRef } = useInfiniteScroll<HTMLDivElement>({ callback: infiniteScrollCallback });

  const handleFetchFavouritesList = (favourite?: AsianViewFavouritePayload) => {
    const offsetOrPage = isInfiniteScrollEnabled ? { offset: 0 } : { page: paginationPage };
    const isCompetition = favourite?.entryType === FAVORITES_TYPES.competition;
    const isEventCoupon = favourite?.entryType === FAVORITES_TYPES.event;

    if (isCompetition) {
      competitionsAndCoupons.current.competitions = competitionsAndCoupons.current.competitions.filter(
        competitionId => competitionId !== favourite?.entryId
      );
    }

    if (isEventCoupon) {
      competitionsAndCoupons.current.coupons = competitionsAndCoupons.current.coupons.filter(
        eventId => eventId !== favourite?.entryId
      );
    }

    dispatch(
      fetchFavouritesMobileEventsList({
        ...offsetOrPage,
        size: isInfiniteScrollEnabled ? ASIAN_VIEW_LIST_SIZE : itemsCountOnPage,
        payload: {
          competitions: isCompetition ? competitionsAndCoupons.current.competitions : competitions,
          coupons: isEventCoupon ? competitionsAndCoupons.current.coupons : coupons,
          marketLink: AsianViewMarketLink.HDP_AND_OU,
          timeFilter: AsianViewTimeFilter.ALL,
          sorting,
          sections: [],
          viewType
        },
        isInfiniteScrollEnabled,
        changePage
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(setElementHeight({ name: EElementNames.MOBILE_ASIAN_SUB_HEADER, height: 0 }));
    };
  }, []);

  useEffect(() => {
    if (eventsIds.length) {
      dispatch(
        fetchCountMarkets({
          id: '1', // TODO replace with sportId once we have multiple sports for Asian View
          payload: {
            marketLink: AsianViewMarketLink.HDP_AND_OU,
            viewType,
            coupons: eventsIds
          }
        })
      );

      fetchCountMarketsInterval.current = setInterval(() => {
        dispatch(
          fetchCountMarkets({
            id: '1', // TODO replace with sportId once we have multiple sports for Asian View
            payload: {
              marketLink: AsianViewMarketLink.HDP_AND_OU,
              viewType,
              coupons: eventsIds
            }
          })
        );
      }, FETCH_COUNT_MARKETS_INTERVAL);
    } else if (fetchCountMarketsInterval.current) {
      clearInterval(fetchCountMarketsInterval.current);
    }

    return () => {
      if (fetchCountMarketsInterval.current) {
        clearInterval(fetchCountMarketsInterval.current);
      }
    };
  }, [eventsIds, viewType, dispatch]);

  useEffect(() => {
    if (isPropertiesLoaded && isFavouritesLoaded && isFavourites) {
      handleFetchFavouritesList();
    }
  }, [isFavouritesLoaded, isFavourites, isPropertiesLoaded, sorting, viewType]);

  useEffect(() => {
    if (page !== 0 && !isLast && isInfiniteScrollEnabled) {
      dispatch(
        fetchFavouritesMobileEventsList({
          offset: page * ASIAN_VIEW_LIST_SIZE,
          size: ASIAN_VIEW_LIST_SIZE,
          payload: {
            competitions,
            coupons,
            marketLink: AsianViewMarketLink.HDP_AND_OU,
            timeFilter: AsianViewTimeFilter.ALL,
            sorting,
            sections: [AsianViewSection.TOP],
            viewType
          },
          isInfiniteScrollEnabled: true
        })
      );
    }
  }, [page, isLast]);

  return (
    <div>
      <AsianToolbarWrapper>
        <div
          ref={componentRef}
          className={classNames(styles.mobileFavs__header, asianViewMiddleSection.MOBILE_SUB_HEADER)}
        >
          <AsianViewMobileMenuButton />
          <div className={styles.mobileFavs__header__left}>
            <i
              className={classNames(
                'biab_fav-icons-active biab_active',
                asianViewIcons.FAVOURITE_ICON,
                asianViewIcons.ENABLED,
                styles.mobileFavs__header__favIcon
              )}
            />
            <h3 className={styles.mobileFavs__header__title}>{t('navigation.favouritesTitle')}</h3>
          </div>
          {isFavourites && <CouponSettings />}
        </div>
      </AsianToolbarWrapper>
      {isFavourites &&
        isFavouritesLoaded &&
        !favouritesLoading &&
        competitionKeys.map((competitionKey, index) => {
          return (
            <CompetitionSection
              key={`${competitionKey}_${index}`}
              competitionKey={competitionKey}
              isLast={index === competitionKeys.length - 1}
              isSingleLine={viewType === ELineViewTypes.SINGLE}
              isFavouritesMobile
              onRemoveFavoriteMobile={handleFetchFavouritesList}
            />
          );
        })}
      {!isInfiniteScrollEnabled &&
        totalElements > itemsCountOnPage &&
        !eventsLoading &&
        totalElements > 0 &&
        isFavouritesLoaded && (
          <Pagination
            page={paginationPage}
            totalPages={totalPages}
            onClickNextPage={onClickNextPage}
            onClickPage={onClickPage}
            onClickPrevPage={onClickPrevPage}
          />
        )}
      {eventsLoading || favouritesLoading ? (
        <LoadingSkeleton />
      ) : (
        isInfiniteScrollEnabled && isFavouritesLoaded && isFavourites && <div ref={lastElementRef} />
      )}
      {!isFavourites && isFavouritesLoaded && (
        <div className={styles.mobileFavs__noFavs}>
          <p className={styles.mobileFavs__noFavs__label}>{t('asianView.labels.favourites.noFavourites')}</p>
          <p className={styles.mobileFavs__noFavs__label}>{t('asianView.labels.favourites.addFavourites')}</p>
        </div>
      )}
    </div>
  );
};

export default AsianViewFavouritesMobilePage;
