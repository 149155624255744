import { ChangeEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setSettings } from 'redux/modules/user';
import { getIsUserAccountSettings } from 'redux/modules/user/selectors';
import { CookieNames } from 'types';
import { SettingsBettingCheckbox } from 'types/navigation';

interface ConfirmationCheckboxProps {
  defaultChecked: boolean;
}

function ConfirmationCheckbox({ defaultChecked }: ConfirmationCheckboxProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setCookie] = useCookies([CookieNames.CONFIRM_BETS]);

  const isAccountSettings = useSelector(getIsUserAccountSettings);

  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
    if (isAccountSettings) {
      dispatch(
        setSettings({
          [SettingsBettingCheckbox.CONFIRM_BETS_FOR_PLACEMENT]: e.target.checked
        })
      );
    } else {
      setCookie(CookieNames.CONFIRM_BETS, e.target.checked);
    }
  };

  useEffect(() => {
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  return (
    <div className="biab_mobile-betslip-confirmation-checkbox">
      <label>
        <input checked={isChecked} onChange={onChange} name="confirmation-checkbox" type="checkbox" />
        <span>{t('betslip.actions.confirmBets')}</span>
      </label>
    </div>
  );
}

export default ConfirmationCheckbox;
