import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { LINE_MARKET_ODDS } from 'constants/placement';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import { getPNCEnabledSetting } from 'redux/modules/appConfigs/selectors';
import { BetsStatusesTypes } from 'redux/modules/betsStatuses/type';
import { getCashOutPlacedId } from 'redux/modules/cashOut/selectors';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { TBetslipMessage } from 'types/betslip';
import { BettingType } from 'types/markets';

/**
 * Get placed message after bet was instantly placed, matched (fully or partially) or cancelled.
 *
 * @param bet
 */
const useBetMessages = ({ bet }: { bet: TCurrentBet }) => {
  const { t } = useTranslation();

  const isPNCEnabled = useSelector(getPNCEnabledSetting);
  const cashOutPlacedId = useSelector(getCashOutPlacedId(bet.marketId));

  const { formattedAmount: sizeMatchedFormatted } = useFormatCurrency(bet.sizeMatched, bet.currency ?? '', {
    noCommas: true,
    noRounding: true
  });
  const { formattedAmount: sizePlacedFormatted } = useFormatCurrency(bet.sizePlaced, bet.currency ?? '', {
    noCommas: true,
    noRounding: true
  });

  const messageParams = {
    sizeMatched: sizeMatchedFormatted,
    sizePlaced: sizePlacedFormatted,
    odds: bet.bettingType === BettingType.LINE ? LINE_MARKET_ODDS : bet.averagePriceRounded || bet.price
  };

  let betslipMessage: TBetslipMessage = { message: '' };
  let betMessage = '';

  /**
   * Bet is instantly fully matched
   */
  if (
    (bet.sizeMatched > 0 && bet.sizeRemaining === 0) ||
    (bet.offerState === BetsStatusesTypes.MATCHED && bet.sizeRemaining === 0)
  ) {
    betslipMessage = {
      message: t(`${isPNCEnabled ? 'pnc.' : ''}openBets.messages.matchedInstantlyPlacedBet`, messageParams),
      isTriggeredByCashOut: bet.triggeredByCashOut && +(cashOutPlacedId || 0) === +bet.offerId
    };
  } else if (bet.sizeMatched > 0 && bet.sizeRemaining > 0) {
    /**
     * Unmatched bet was partially matched
     */
    if (bet.action === ECurrentBetActions.PARTIALLY_MATCHED) {
      betslipMessage = { message: t('openBets.messages.matchedPartiallyUpdatedBet', messageParams) };
      /**
       * Bet is instantly partially matched
       */
    } else {
      betslipMessage = { message: t('openBets.messages.matchedPartiallyInstantlyPlacedBet', messageParams) };
    }
  }

  /**
   * Unmatched bet was matched
   */
  if (bet.sizeMatched > 0 && bet.sizeRemaining === 0 && bet.sizeLapsed === 0 && bet.sizeCancelled === 0) {
    betMessage = t('openBets.messages.matchedUnmatchedBet', messageParams);

    /**
     * Unmatched bet was partially matched
     */
  } else if (bet.sizeMatched > 0 && bet.sizeRemaining > 0 && bet.sizeCancelled === 0) {
    if (bet.action === ECurrentBetActions.PLACED_PARTIALLY_MATCHED) {
      betMessage = t('openBets.messages.matchedPartiallyInstantlyPlacedBet', messageParams);
    } else {
      betMessage = t('openBets.messages.matchedPartiallyUnmatchedBet', messageParams);
    }

    /**
     * Bet was cancelled
     */
  } else if (bet.sizeRemaining === 0 && bet.sizeCancelled > 0) {
    betMessage = t('betslip.messages.cancelledPlacedBet', messageParams);
  }

  return {
    betMessage,
    betslipMessage
  };
};

export default useBetMessages;
