import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { unescape } from 'lodash';

import { betslipBranding as branding } from 'constants/branding';
import { OPEN_BETS_NOTIFICATION_TIME } from 'constants/placement';
import { setPlacementMessage } from 'redux/modules/betslip';
import { getBetslipPlacementMessage } from 'redux/modules/betslip/selectors';
import { getEnvironmentRootPath } from 'utils/navigation';

import styles from './styles.module.scss';

const OpenedBetsMessage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const placementMessage = useSelector(getBetslipPlacementMessage);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const moreInformationText = t('openBets.messages.moreInformation', { rootPath: getEnvironmentRootPath() });

  useEffect(() => {
    if (placementMessage.message) {
      timeoutRef.current = setTimeout(() => {
        dispatch(setPlacementMessage({ message: '' }));
      }, OPEN_BETS_NOTIFICATION_TIME);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [placementMessage.message]);

  if (!placementMessage?.message) {
    return null;
  }

  return (
    <div
      className={classNames(styles.messageWrap, branding.OPENED_BET_PLACEMENT_MSG, {
        'biab_cashout-bet': placementMessage?.isTriggeredByCashOut
      })}
    >
      <i
        className={classNames('fa2 fa2-check', styles.messageWrapIcon, branding.MATCHED_MSG_ICON, {
          [styles.messageWrapIcon__cashOut]: placementMessage?.isTriggeredByCashOut
        })}
        aria-hidden="true"
      />
      {placementMessage?.isTriggeredByCashOut && (
        <span className={classNames(branding.CASH_OUT_ICON, styles.cashOutIcon)}>
          <i className={styles.cashOutIcon__inner} />
        </span>
      )}
      <div>
        <div dangerouslySetInnerHTML={{ __html: unescape(placementMessage?.message ?? '') }} />
        <div dangerouslySetInnerHTML={{ __html: unescape(moreInformationText) }} />
      </div>
    </div>
  );
};

export default OpenedBetsMessage;
