import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { PARAMS_ACTION_KEY, PARAMS_OFFER_ID_KEY } from 'constants/urlParams';
import { useFormatCurrency } from 'hooks/useFormatCurrency';
import useLayColumn from 'hooks/useLayColumn';
import {
  getLineMarketsSwitchBackLayOnCricket,
  getReplaceBackLayWithUnderOver
} from 'redux/modules/appConfigs/selectors';
import { setCurrentBetAction } from 'redux/modules/currentBets';
import { ECurrentBetActions, TCurrentBet } from 'redux/modules/currentBets/type';
import { setInlineSelectedBet } from 'redux/modules/inlinePlacement';
import { TInlineSelectedBet } from 'redux/modules/inlinePlacement/type';
import { getMarketPricesById } from 'redux/modules/placement/selectors';
import { setMessageBet } from 'redux/modules/placementMessage';
import { PageBlocks, PlacementPage, SportId } from 'types';
import { BetTypes, MatchTypes } from 'types/bets';
import { Actions } from 'types/inlinePlacement';
import { BettingType } from 'types/markets';
import { calculateLiability } from 'utils/liability';
import { getPricesByMarketType } from 'utils/price';

import styles from './styles.module.scss';

interface MobileOpenBetProps {
  /**
   * Bet
   */
  bet: TCurrentBet;

  /**
   * Match type
   */
  matchType: MatchTypes;

  /**
   * Is Open bets enabled flag
   */
  isOpenBetsEnabled: boolean;

  /**
   * Is PNC enabled flag
   */
  isPNCEnabled: boolean;

  /**
   * Place where component was added.
   */
  pageBlock: PageBlocks;

  /**
   * Currency
   */
  currency: string;

  /**
   * Hide function
   */
  onHide: (matchType: MatchTypes, offerId: number) => void;
  page?: PlacementPage;
}

function MobileOpenBet({
  bet,
  matchType,
  isOpenBetsEnabled,
  isPNCEnabled,
  pageBlock,
  currency,
  onHide,
  page
}: MobileOpenBetProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const offerId = searchParams.get(PARAMS_OFFER_ID_KEY) || '';
  const action = searchParams.get(PARAMS_ACTION_KEY) || '';

  const marketPrices = useSelector(getMarketPricesById(pageBlock, bet.marketId));

  const lineBackLayToNoYes = useSelector(getLineMarketsSwitchBackLayOnCricket);
  const replaceBackLayWithUnderOver = useSelector(getReplaceBackLayWithUnderOver);

  const { isLayColumnEnabled } = useLayColumn(bet.eventTypeId.toString());
  const isCricket = bet.eventTypeId.toString() === SportId.CRICKET;
  const isLineMarket = bet.bettingType === BettingType.LINE;
  const { marketType, bettingType, eachWayDivisor, side: betType } = bet;

  const selectionPrices = marketPrices?.rc?.find(({ id }: { id: number }) => id === bet.selectionId) ?? null;

  const takeOdds = getPricesByMarketType(
    selectionPrices?.[bet.side === BetTypes.BACK ? 'bdatb' : 'bdatl']?.[0]?.odds,
    bet.marketType,
    bet.bettingType
  );

  const takeProfit = calculateLiability(takeOdds, bet.sizeRemaining, {
    marketType,
    bettingType,
    eachWayDivisor,
    betType
  });
  const { noFormattedAmount: profit } = useFormatCurrency(takeProfit || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });
  const { noFormattedAmount: formattedSize } = useFormatCurrency(bet.sizeRemaining || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });
  const { noFormattedAmount: formattedMatchedSize } = useFormatCurrency(bet.sizeMatched || 0, bet.currency, {
    isCheckIndian: true,
    noRounding: true
  });

  useEffect(() => {
    if (action && offerId && bet.offerId === +offerId) {
      if (action.toUpperCase() === Actions.EDIT) {
        onEditBet();
      }
      if (action.toUpperCase() === Actions.CANCEL) {
        onCancelBet();
      }
    }
  }, [action, offerId, bet.offerId]);

  useEffect(() => {
    if (bet.action === ECurrentBetActions.FULLY_MATCHED || bet.action === ECurrentBetActions.PARTIALLY_MATCHED) {
      dispatch(setMessageBet(bet));
      dispatch(setCurrentBetAction({ offerId: bet.offerId, action: null }));
    }
  }, [bet.action]);

  const getTitle = () => {
    let side = t('market.' + bet.side.toLowerCase()),
      label;

    if (isLineMarket) {
      if (lineBackLayToNoYes && isCricket) {
        side = t('market.column.' + (bet.side === BetTypes.BACK ? 'no' : 'yes'));
      }
      if (!isCricket && replaceBackLayWithUnderOver) {
        side = t('market.column.' + (bet.side === BetTypes.BACK ? 'under' : 'over'));
      }
    }

    if (bet.triggeredByCashOut && !isLayColumnEnabled) {
      label = 'market.mobileOpenBetsLabels.cashedOutBetInfo';
    } else if (isPNCEnabled) {
      label = 'pnc.market.mobileOpenBetsLabels.' + matchType.toLowerCase() + 'BetInfoPNC';
    } else {
      label = 'market.mobileOpenBetsLabels.' + matchType.toLowerCase() + 'BetInfo';
    }

    return t(label, {
      side: side,
      size: matchType === MatchTypes.UNMATCHED ? formattedSize : formattedMatchedSize,
      price: bet.averagePriceRounded ? bet.averagePriceRounded : bet.price
    });
  };

  const betData = {
    marketId: bet.marketId,
    sportId: bet.eventTypeId.toString(),
    selectionId: bet.selectionId || 0,
    handicap: pageBlock === PageBlocks.GAME ? 0 : +(bet.handicap || 0),
    marketType: bet.marketType,
    bettingType: bet.bettingType,
    numberOfWinners: bet.numberOfWinners || 0,
    type: betType,
    size: bet.sizeRemaining,
    price: bet.price,
    pageBlock,
    currentOfferId: bet.offerId,
    currency,
    offers: { [bet.offerId]: bet },
    page
  };

  const onEditBet = () => {
    const editBet: TInlineSelectedBet = {
      ...betData,
      ...{
        action: Actions.EDIT
      }
    };
    dispatch(setInlineSelectedBet(editBet));
  };

  const onCancelBet = () => {
    const editBet: TInlineSelectedBet = {
      ...betData,
      ...{
        action: Actions.CANCEL
      }
    };
    dispatch(setInlineSelectedBet(editBet));
  };

  const onTakeBet = () => {
    if (takeOdds) {
      const editBet: TInlineSelectedBet = {
        ...betData,
        ...{
          price: takeOdds,
          action: Actions.TAKE
        }
      };
      dispatch(setInlineSelectedBet(editBet));
    }
  };

  return (
    <div
      className={classNames(
        'biab_mobile-open-bets-item',
        matchType.toLocaleLowerCase(),
        'biab_' + bet.side.toLocaleLowerCase(),
        styles.openBet,
        matchType === MatchTypes.UNMATCHED ? styles.openBet__unmatched : styles.openBet__matched
      )}
    >
      {!isOpenBetsEnabled && (
        <i
          onClick={() => onHide(matchType, bet.offerId)}
          className={classNames('fa fa-times biab_mobile-open-bets-close-icon', styles.closeIcon)}
        />
      )}
      <div className={classNames('biab_mobile-open-bets-item-label', styles.openBet__label)}>{getTitle()}</div>
      {matchType === MatchTypes.UNMATCHED && (
        <div className={classNames('biab_mobile-open-bets-item-actions', styles.itemActions)}>
          <button type="button" onClick={onCancelBet} className="biab_mobile-open-bets-cancel-btn">
            {t('market.mobileOpenBetsLabels.cancelBet')}
          </button>
          <button
            type="button"
            onClick={onTakeBet}
            className={classNames('biab_mobile-open-bets-take-btn', styles.takeBtn, {
              biab_disabled: !takeOdds || takeOdds === bet.price
            })}
            disabled={!takeOdds || takeOdds === bet.price}
          >
            <span>
              {t('market.mobileOpenBetsLabels.takeBtn.take')} @{takeOdds}
            </span>
            <span className={classNames('profit-value', styles.profitValue)}>
              {t(
                'market.mobileOpenBetsLabels.takeBtn.' +
                  (bet.side && bet.side.toUpperCase() == 'BACK' ? 'profit' : 'liability')
              )}{' '}
              {profit}
            </span>
          </button>
          <button onClick={onEditBet} type="button" className="biab_mobile-open-bets-edit-bet">
            {t('market.mobileOpenBetsLabels.editBet')}
          </button>
        </div>
      )}
    </div>
  );
}

export default MobileOpenBet;
