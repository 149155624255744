import { memo, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import Loader, { CircleColors } from 'components/Loader';
import MyBetsSkeleton from 'components/Skeleton/MyBetsSkeleton';
import StatementTable from 'components/StatementTable';
import AccountStatementDetails from 'components/StatementTable/components/AccountStatementDetails';
import { ExchangeTypes, MyBetsLocations, MyBetsPage, NavigationButtons, SEARCH_CURRENCY_KEY } from 'constants/myBets';
import useDevice from 'hooks/useDevice';
import useMultiCurrencySupporting from 'hooks/useMultiCurrencySupporting';
import { useMyBetsPagesNavigation } from 'hooks/useMyBetsPagesNavigation';
import { useSetHighlightedRow } from 'hooks/useSetHighlightedRow';
import DatePickers from 'pages/MyBetsPages/components/DatePickers/MyBetsDatePickers';
import ExportButton from 'pages/MyBetsPages/components/ExportButton';
import Pagination from 'pages/MyBetsPages/components/Pagination';
import RefreshButton from 'pages/MyBetsPages/components/RefreshButton';
import TimeFormatDropdown from 'pages/MyExchangeBetsPage/components/TimeFormatDropdown';
import { getCurrency } from 'redux/modules/appConfigs/selectors';
import { setMultiCurrencyValue, showMobileStatementDetails } from 'redux/modules/myBets';
import {
  getStatementData,
  isShowMobileStatementDetails,
  myBetsLoading,
  statementDetails
} from 'redux/modules/myBets/selectors';
import { TStatementContentItem } from 'redux/modules/myBets/type';
import { CookieNames } from 'types';
import { TGetStatementData } from 'types/myBets';
import { iosBundleRedirect } from 'utils';
import { getThreeMonthsAgo } from 'utils/date';

import styles from './AccountStatementPage.module.scss';

interface AccountStatementPageProps {
  asianViewEnabled: boolean;
  collectHandlers: (name: 'fetchStatementDataHandler', handler: (params: TGetStatementData) => void) => void;
}

const AccountStatementPage = ({ asianViewEnabled, collectHandlers }: AccountStatementPageProps) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies([CookieNames.EXCHANGE_TYPE]);

  const statementData = useSelector(getStatementData);
  const { content, totalElements } = statementData;
  const isLoading = useSelector(myBetsLoading);
  const isDetailsOpen = useSelector(isShowMobileStatementDetails);
  const mobileStatementDetails = useSelector(statementDetails);
  const currency = useSelector(getCurrency);

  const {
    timezoneOffset,
    bettingDay,
    startDate,
    endDate,
    pageSize,
    pageNumber,
    handleSetEndDate,
    handleSetStartDate,
    handleSetPageNumber,
    handleSetPageSize,
    handleSetPageSizeAndNumber,
    statementFilterValue,
    handleChangeStatementFilter,
    handlePeriodDropdown,
    handleResetDates,
    getStatementData: fetchStatementData,
    getExportParams,
    refreshData
  } = useMyBetsPagesNavigation(MyBetsPage.AccountStatement);
  const { isMultiCurrencySupported } = useMultiCurrencySupporting();
  const { isMobile } = useDevice();

  collectHandlers('fetchStatementDataHandler', fetchStatementData);
  const { rowHighlighted, setRowHighLighted } = useSetHighlightedRow(pageNumber, pageSize, { startDate, endDate });

  const searchCurrency = searchParams.get(SEARCH_CURRENCY_KEY);

  const showTableBlock = !isDetailsOpen || !isMobile;
  const showBetDetails = isDetailsOpen && isMobile;
  const minDate = getThreeMonthsAgo();
  const exportData = getExportParams(searchCurrency || currency.currencyCode);

  const openMobileStatementDetails = (item: TStatementContentItem, index: number) => {
    dispatch(showMobileStatementDetails(item));
    setRowHighLighted(index);
  };

  useEffect(() => {
    if (isMultiCurrencySupported && currency.currencyCode) {
      dispatch(setMultiCurrencyValue(searchCurrency || currency.currencyCode));
    }
  }, [dispatch, currency.currencyCode, isMultiCurrencySupported]);

  return (
    <>
      {showBetDetails && <AccountStatementDetails item={mobileStatementDetails} />}
      {!isMobile && (
        <div className={styles.rightContainer}>
          {asianViewEnabled && cookies.EXCHANGE_TYPE === ExchangeTypes.AsianView && <TimeFormatDropdown />}
          <div>
            <div className={styles.refreshWrapper}>
              <RefreshButton onRefresh={refreshData} />
            </div>
            <ExportButton data={exportData} isDisabled={totalElements === 0} />
          </div>
        </div>
      )}
      {showTableBlock && (
        <>
          <DatePickers
            timezoneOffset={timezoneOffset}
            bettingDay={bettingDay}
            startDate={startDate}
            endDate={endDate}
            minDate={minDate}
            setStartDate={handleSetStartDate}
            setEndDate={handleSetEndDate}
            handlePeriodDropdown={handlePeriodDropdown}
            handleResetDates={handleResetDates}
            selectedTab={NavigationButtons.Statement}
            statementFilterValue={statementFilterValue}
            setStatementFilterValue={handleChangeStatementFilter}
            onRefreshTableData={fetchStatementData}
          />
          <div>
            {isMobile && (
              <>
                {isLoading ? (
                  <Loader circleColor={CircleColors.BLACK} />
                ) : (
                  <div
                    className={classNames('biab_my-bets-table-header', styles.tableContainer, {
                      [styles.tableContainer__mobile]: isMobile && !iosBundleRedirect
                    })}
                  >
                    <StatementTable
                      showBetDetails={showBetDetails}
                      openMobileStatementDetails={openMobileStatementDetails}
                      content={content}
                      rowHighlighted={rowHighlighted}
                      fetchStatementData={fetchStatementData}
                    />
                  </div>
                )}
              </>
            )}

            {!isMobile && (
              <>
                <div
                  className={classNames(styles.skeletonContainer, {
                    [styles.skeletonContainer__active]: isLoading
                  })}
                >
                  {isLoading && <MyBetsSkeleton isShowTable />}
                  <div
                    className={classNames('biab_my-bets-table-header', styles.tableContainer, {
                      [styles.tableContainer__mobile]: isMobile
                    })}
                  >
                    <StatementTable
                      showBetDetails={showBetDetails}
                      openMobileStatementDetails={openMobileStatementDetails}
                      content={content}
                      rowHighlighted={rowHighlighted}
                      fetchStatementData={fetchStatementData}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          {!isLoading && totalElements > 10 && (
            <Pagination
              source={statementData}
              pageSize={pageSize}
              setPageSize={handleSetPageSize}
              pageNumber={pageNumber}
              setPageNumber={handleSetPageNumber}
              pageLink={MyBetsLocations.Statement}
              setPageSizeAndNumber={handleSetPageSizeAndNumber}
            />
          )}
        </>
      )}
    </>
  );
};

export default memo(AccountStatementPage);
